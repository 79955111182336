import React, { useState, useEffect } from "react";

import Measure from "./Measure";
import Mecanisme from "./Mecanisme";
import Explication from "./Explication";
import Doc from "./Doc";
import Recap from "./Recap";
import Compatible from "../onyx/Compatible";
import Contact from "../onyx/Contact";
import Footer from "../section/FooterSection";
import How from "../mbox/TextComponent";

import ScrollSequence from "../scroll/ScrollSequence";
import images from "../../_resources/ccSynchro";
import Children from "../mbox/VideoAccueil";

import { withTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";

import ImageSelector from "../responsive/ImageSelector";
import Fleche from "../../_svg/Fleche_telephone"

import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';

const App = (props) => {
  const { t } = props

  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  })

  const content = {
    description: t('produits.dld.installation')
  }

  let isTablet = useMediaQuery({ query: "(max-width: 1040px)" });
  /**
   * <Helmet>
              <meta
        name="description"
        content="Module radio, solution pour toute relève de compteur compatible wmbus, pour relever vos compteurs d'aujourd'hui et de demain"
      />
      <meta name="keywords" content="Mbox, boîtier, autonome, trames, wireless, radio-relève, divre by, walk by, geolocalisation, 2G, 3G, batterie, format compact, antennes, bluetooth, développement d'applications, protocole SDI, protocole, crystalcloud, multi-fluides" />
      <meta property="og:title" content="DLD"/>
      <meta property="og:type" content="Produits - data logger"/>
      <meta property="og:url" content="https://www.dioptase.fr/produits/dld"/>
      <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
      <meta property="og:description" content="Solution pour tout gestionnaire de parc compteurs"/>
                  </Helmet>
   */
  return (
    <div className={"dld-container responsiv"} itemScope itemType="https://schema.org/WebPage">
      <MetaTags>
        <title>DLD</title>
        <meta
          name="description"
          content="Module radio, solution pour toute relève de compteur compatible wmbus, pour relever vos compteurs d'aujourd'hui et de demain"
        />
        <meta name="keywords" content="Mbox, boîtier, autonome, trames, wireless, radio-relève, divre by, walk by, geolocalisation, 2G, 3G, batterie, format compact, antennes, bluetooth, développement d'applications, protocole SDI, protocole, crystalcloud, multi-fluides" />
        <meta property="og:title" content="DLD" />
        <meta property="og:type" content="Produits - data logger" />
        <meta property="og:url" content="https://www.dioptase.fr/produits/dld" />
        <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
        <meta property="og:description" content="Solution pour tout gestionnaire de parc compteurs" />
      </MetaTags>
      <Measure t={t} />
      <Mecanisme />
      <How t={t} content={content} />
      <Explication />
      <div className="svg-fleche" style={{ display: isTablet ? "none" : "block" }}>
        <Fleche height="100px" />
      </div>
      <ImageSelector src={images("", "fr")[images("", "fr").length - 1]} children={<Children t={t} />} className="filter" />
      <Recap t={t} />
      <Compatible section="Logiciels" parentColor="#D8A202" parent={"DLD"} t={t} />
      <Contact objet="DLD" t={t} />
      <Footer t={t} />
    </div>
  );
};


const tr = withTranslation()(App);
export default tr;
