import React from 'react';

const SvgGeoguidage = (props: any) => (
	<svg viewBox="0 0 24 12.11" {...props}>
		<path fill={props.fill}
			  d="m17.35.48c-.14-.3-.49-.38-.78-.22l-4.15,2.26s-.01.02-.02.02c-1.18-.27-2.29.11-3.14,1.03-.83.96-1.18,2.29-.92,3.55l-2.03,4.27c-.14.15-.14.38,0,.6.07.15.28.3.49.3h.35l4.09-2.22c.21.03.42.04.62.04.98,0,1.83-.38,2.6-1.13.84-.98,1.2-2.33.91-3.61,0-.03-.02-.05-.03-.08l2-4.21c.14-.15.14-.38,0-.6M7.01,11.38l1.62-3.38c.21.38.35.75.63.98s.56.53.91.68l-3.16,1.73Zm6.89-2.93c-.77.75-1.76,1.05-2.74.83-.04,0-.08,0-.11.01-.48-.16-.91-.43-1.22-.76-.35-.45-.63-.9-.77-1.51,0-.09-.05-.17-.11-.23-.12-.96.12-1.95.82-2.63.77-.75,1.76-1.05,2.74-.83.1,0,.19-.02.26-.06.46.16.85.42,1.15.74.35.45.63.9.77,1.5.21,1.05,0,2.18-.77,2.93m1.12-3.99c-.14-.38-.35-.68-.63-.98-.28-.23-.56-.53-.92-.68l3.17-1.8-1.62,3.46Z"/>
		<path fill={props.fill}
			  d="m11.16,5.52c-.21.23-.28.45-.28.75s.07.53.28.75c.21.23.42.3.7.3s.49-.08.7-.3c.21-.23.28-.45.28-.75s-.07-.53-.28-.75c-.35-.38-1.05-.38-1.41,0m.91.98c-.14.15-.28.15-.42,0-.07-.08-.07-.15-.07-.23s0-.15.07-.22c.07-.08.14-.08.21-.08s.14,0,.21.08c.07.08.07.15.07.22,0,.08,0,.15-.07.23"/>
	</svg>
)

export default SvgGeoguidage;
