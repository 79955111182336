import React from 'react';

const SvgMateriel = (props: any) => (
    <svg viewBox="0 0 14.17 19.21" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M14.17,17.8V1.41A1.41,1.41,0,0,0,12.76,0H1.41A1.41,1.41,0,0,0,0,1.41V17.8a1.41,1.41,0,0,0,1.41,1.41H12.76A1.41,1.41,0,0,0,14.17,17.8Zm-12.76.69a.69.69,0,0,1-.69-.69V1.41A.69.69,0,0,1,1.41.72H12.76a.69.69,0,0,1,.69.69V17.8a.69.69,0,0,1-.69.69H1.41Z"/>
			<path fill={props.fill} d="M6.38,1.46s0,0,0-.05,0,0,0,0a.11.11,0,0,0-.11-.07.11.11,0,0,0-.11.11.11.11,0,0,0,.11.12A.12.12,0,0,0,6.38,1.46Z"/>
			<path fill={props.fill} d="M8.12,1.41A.11.11,0,0,0,8,1.3H6.85a.11.11,0,0,0-.11.07s0,0,0,0,0,0,0,.05a.12.12,0,0,0,.11.07H8A.12.12,0,0,0,8.12,1.41Z"/>
			<path fill={props.fill} d="M7.38,17.9a.3.3,0,0,0-.59,0,.3.3,0,1,0,.59,0Z"/>
			<path fill={props.fill} d="M13.12,17V2.25a.36.36,0,0,0-.36-.36H1.41a.36.36,0,0,0-.36.36V17a.36.36,0,0,0,.36.36H12.76A.36.36,0,0,0,13.12,17ZM1.77,16.6v-14H12.4v14Z"/>
		</g>
	</g>
</svg>
);

export default SvgMateriel;
