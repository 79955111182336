import React from 'react';

const SvgKamstrup = (props: any) => (
	<svg viewBox="0 0 156.11 35.6" {...props}>
		<path d="M31.77,21.7c-.45,.57-1.82,1.61-3.18,1.61-2.88,0-3.48-2.83-3.48-5.2,0-3.55,1.74-5.08,3.6-5.08,1.14,0,2.39,.69,3.07,1.3v7.38Zm.4-11.25s-.04-.05-.04-.06c-.8-1.13-2.5-2.11-4.6-2.11-4.17,0-8.33,3.44-8.33,10.2,0,5.08,2.54,9.44,7.99,9.44,1.69,0,3.83-.88,4.98-1.93v1.44h4.37c.53,0,.91-.31,.91-.92v-3.58h0V9.74c0-.61-.38-.92-.91-.92h-4.37v1.63Z" />
		<path d="M4.92,0c-.11,0-.26,0-.42,.04L.91,.54l-.91,.13V27.43H4.69c.68,0,.98-.42,.98-1.07V.99c0-.76-.26-.99-.76-.99" />
		<path d="M18.62,26.09l-5.6-9.36,4.92-6.88c.15-.19,.19-.38,.19-.54,0-.3-.23-.5-.64-.5h-4.97l-1.42,2.33c-.33,.54-.66,1.07-.99,1.61-.33,.54-.67,1.08-1,1.63-.26,.43-.53,.86-.79,1.29-.38,.61-.76,1.22-1.15,1.84,.44,.8,.88,1.59,1.32,2.38,.29,.52,.58,1.04,.86,1.56,.34,.61,.68,1.22,1.01,1.84,.28,.51,.56,1.02,.85,1.53l1.43,2.61h5.48c.3,0,.8-.08,.8-.5,0-.31-.15-.57-.3-.84" />
		<path d="M68.32,11.54c-.68-1.57-2.54-3.25-5.49-3.25-2.38,0-4.88,.84-6.59,2.56-.8-1.3-2.19-2.56-4.73-2.56-1.93,0-3.9,.76-5.41,2.06l-.08-.73c-.08-.57-.45-.8-1.1-.8h-4.16V27.43h4.58c.76,0,1.1-.31,1.1-1.11V14.94c1.06-.95,2.35-1.6,3.59-1.6,1.7,0,2.04,1.22,2.04,2.75v11.34h4.28c.76,0,1.1-.31,1.1-1.11V14.94c1.02-.88,2.57-1.6,3.9-1.6,1.7,0,2.04,1.22,2.04,2.75v11.34h4.58c.76,0,1.1-.31,1.1-1.11v-8.82c0-2.71-.08-4.43-.76-5.96" />
		<path d="M88.85,19.94c0,3.13,.08,4.7,1.29,6.15,1.02,1.22,2.73,1.72,4.85,1.72,1.74,0,3.41-.34,4.84-.99,.42-.19,.61-.38,.61-.8,0-.11,0-.23-.04-.34l-.45-2.44c-.08-.34-.23-.53-.53-.53-.15,0-.3,.04-.53,.11-.76,.23-1.48,.46-2.42,.46-1.66,0-1.93-1.22-1.93-3.21v-7.45h4.12c.53,0,.91-.31,.91-.88v-2.22c0-.57-.38-.8-.91-.8h-4.09V4.24c0-.69-.3-.99-.87-.99h-.23l-3.41,.46-1.21,.16V19.94Z" />
		<path d="M114.42,8.33c-.34-.08-.76-.15-1.06-.15-2.23,0-3.37,.8-4.73,2.44l-.08-1.15c-.04-.57-.38-.76-1.1-.76h-4.16V27.31h4.62c.76,0,1.06-.38,1.06-1.07V14.82c.95-.95,2.23-1.49,3.48-1.49,.34,0,.72,.04,1.02,.11h.19c.34,0,.53-.19,.61-.61l.57-3.59c.04-.15,.04-.23,.04-.34v-.08c-.04-.27-.15-.42-.45-.5" />
		<path d="M149.11,22c-.49,.69-1.44,1.26-2.54,1.26s-2.31-.57-2.99-1.22v-7.79c.6-.65,2.01-1.37,3.1-1.37,1.02,0,2.01,.38,2.65,1.37,.53,.8,.87,2.06,.87,3.78s-.34,2.94-1.1,3.97m4.58-11.46c-1.21-1.3-2.91-2.33-5.52-2.33-1.97,0-4.01,.96-5,2.06l-.08-.76c-.08-.61-.45-.8-1.17-.8h-4.01v26.89h4.5c.76,0,1.17-.3,1.17-1.18v-8.06c1.02,.95,2.57,1.53,4.24,1.53,2.46,0,4.47-.99,5.87-2.67,1.59-1.87,2.42-4.55,2.42-7.53,0-2.64-.76-5.38-2.42-7.14" />
		<path d="M82.91,16.78l-3.9-1.56c-1.31-.51-1.47-1.01-1.47-1.48,0-.54,.58-1.25,1.85-1.25s2.82,.39,4.28,.94h.01l.09,.03s.08,.03,.12,.04c.04,.01,.08,.03,.12,.04,.04,.01,.07,.02,.11,.03l.1,.03s.05,.02,.08,.02c.02,0,.04,.01,.06,.02,.03,0,.05,.01,.07,.01,.03,0,.06,0,.08,0h.04s.05,0,.08,0c.03,0,.06-.01,.1-.02,.03,0,.06-.02,.09-.03,.03-.01,.05-.03,.08-.04,.02-.02,.05-.04,.07-.06,.02-.02,.04-.05,.06-.07,.02-.03,.04-.06,.05-.09,.02-.03,.03-.06,.04-.1,.01-.04,.02-.08,.03-.11,0-.03,.02-.06,.02-.09,0,0,.03-.21,.04-.27l.38-2.37c.03-.18,.06-.39,.06-.51,0-.31-.19-.51-.54-.66-2.05-.94-4.05-1.25-6.06-1.25-3.44,0-7.37,1.67-7.37,5.84,0,2.81,1.85,4.67,3.94,5.45l3.44,1.29c1.43,.55,1.81,.94,1.81,1.67,0,.86-.85,1.36-2.08,1.36-1.35,0-3.11-.41-4.95-1.15h0l-.1-.04-.11-.04s-.08-.03-.11-.04c-.04-.01-.08-.03-.12-.04-.04-.01-.08-.03-.11-.04-.04-.01-.07-.02-.11-.04l-.1-.03s-.05-.02-.08-.02c-.02,0-.04-.01-.06-.02-.02,0-.05-.01-.07-.01-.03,0-.05,0-.08,0h-.04s-.05,0-.08,0c-.03,0-.06,.01-.1,.02-.03,0-.06,.02-.09,.03-.03,.01-.05,.03-.08,.04-.02,.02-.05,.04-.07,.06-.02,.02-.04,.05-.06,.07-.02,.03-.04,.06-.05,.09-.02,.03-.03,.06-.04,.1-.01,.04-.02,.08-.03,.11,0,.03-.01,.06-.02,.09,0,0-.17,1.05-.27,1.7h0l-.15,.94c-.04,.16-.06,.3-.06,.46,0,.27,.12,.55,.54,.7,2.12,.89,4.4,1.44,6.45,1.44,3.82,0,7.76-1.71,7.76-6.19,0-2.61-1.51-4.25-3.47-5.03" />
		<path d="M133.49,8.7h-4.58v12.5c-1.06,.92-2.61,1.49-3.86,1.49-1.7,0-2.16-1.11-2.16-2.68V9.7c0-.8-.38-.99-1.1-.99h-4.58v12.35c0,4.13,1.86,6.8,5.98,6.8,2.23,0,4.32-.73,6.06-2.1v1.57h4.16c.91,0,1.17-.31,1.17-1.26V9.7c0-.8-.38-.99-1.1-.99" />
	</svg>
);

export default SvgKamstrup;
