import React from "react";
import { Button } from "reactstrap";
import { useMediaQuery } from "react-responsive";

const InBuilding = (props) => {
  const content = {
    big: "Oups",
    title: "Page en construction",
    description: "La page que vous souhaitez consulter est en maintenance,",
    retry: "merci de réessayer plus tard.",
  };

  const isSmart = useMediaQuery({ query: "(max-width: 1040px)" });

  return (
    <div className="error-container">
      <div className="wrapper" style={{width: isSmart ? "100%" : "40%"}}>
        <div className="center">
          <h1>{content.big}</h1>
          <h3>{content.title}</h3>
          <div className="span">
            <span>{content.description}</span>
            <br />
            <span>{content.retry}</span>
          </div>
          <div className="btn-container">
              <a href="/">
              <Button className="primary">Accueil</Button>
              </a>
          </div>
        </div>
      </div>
      <div className="image-background construction" style={{display: isSmart ? "none" : "block"}}/>
    </div>
  );
};

export default InBuilding;
