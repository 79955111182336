import debitConstants from './debit.constants';
export default function debit(state = {}, action) {
    switch (action.type) {
        case debitConstants.SET_DEBIT_SUCCESS:
            return {
                highSpeed: action.isGood,
            };
        case debitConstants.CLEAR:
            return {};
        default:
            return state;
    }
}
