import React, { useRef } from "react";
import SvgConcat from "../../_svg/Concat";
import { withTranslation } from 'react-i18next';

const App = (props) => {
  const { t } = props
  const content = {
    title: t('applications.saphir.concat'),
    description: t('applications.saphir.concat.description')
  }
  return (
              <div className={"svg-container"} id={"svg-concat-saphir"} itemScope itemType="https://schema.org/WebPage">
                <div>
                  <SvgConcat fill={"#b77830"} />
                <h3 itemProp="alternativeHeadline">{content.title}</h3>
                  <span itemProp="description">
                    {content.description}
                  </span>
                </div>
              </div>
  );
};

const tr = withTranslation()(App);
export default tr;