import React from "react";

const ConsoInterface = (props) => {
	return (
		<svg id="Calque_1" viewBox="0 0 70.2 75.5" {...props}>
			<g>
				<path fill={props.fill} d="M65.8,49.9c-2.9-0.2-5.9-0.1-8.8-0.3c-3.1-0.1-6.2-0.4-9.3-0.5c-6.2-0.4-12.5-0.7-18.8-0.3
		c-4.8,0.4-9.6,1.1-14.4,1.4c-4.2,0.3-8.8,0.2-12.8-1.3c0,0,0.1,0,0.1-0.1c0.9-1.3,0.7-2.8,0.7-4.3c0.1-1.9,0.1-3.9,0.2-5.8
		c0.2-4.1,0.4-8.3,0.7-12.4C3.9,17.9,4.7,9.3,3.9,1c3.2,2.5,7.6,3,11.5,3.4c5.2,0.4,10.6,0,15.8-0.4C37,3.5,42.6,3.4,48.4,3.6
		c3.5,0.1,6.9,0.3,10.4,0.4c1.6,0,3.2,0,4.8,0c1.6-0.1,3.3,0,4.8-0.6c0.8-0.3,0.6-1.3-0.2-1.4c-1.5-0.2-3.1,0.1-4.6,0.1
		c-1.6,0.1-3.2,0.1-4.8,0.1c-3.3,0-6.6-0.2-9.9-0.3c-5.6-0.2-11-0.2-16.6,0.3c-5,0.4-9.9,0.8-14.9,0.7C12.9,2.7,7.9,2.4,3.9,0
		C3.6-0.2,3.2,0.3,3.5,0.6c0,0,0,0,0.1,0.1C2.8,9.2,2.4,17.7,1.7,26.3c-0.3,4.1-0.6,8.3-0.9,12.4c-0.1,1.8-0.2,3.6-0.3,5.4
		c-0.1,1.5-0.3,3.2,0.4,4.5c-0.2-0.1-0.4-0.2-0.5-0.2c-0.4-0.2-0.6,0.3-0.3,0.5c3.7,2.7,8.3,3.1,12.7,3.2c5,0,10-0.7,15-1.2
		c6.2-0.6,12.6-0.2,18.8,0.2c0.2,0,0.3,0,0.4,0c-0.2,1.6-0.3,3.1-0.3,4.7c-0.1,1.6-0.2,3.4-0.1,5c-0.8,0.1-1.5,0.4-2.3,0.6
		c-1.6,0.4-3.3,0.5-4.9,0.6c-1.6,0.1-3.3,0-4.9-0.2c-0.3,0-0.7-0.1-1-0.1c1.2-0.1,2.3-0.5,3.2-1.4c0.1-0.1-0.1-0.3-0.2-0.2
		c-1.4,1.1-3.3,1.6-5,1.2c-0.1,0-0.1,0-0.1,0c-0.3-0.1-0.6-0.2-0.9-0.3c-0.4-0.2-0.9,0.3-0.5,0.6c2.5,2.2,6.3,2.4,9.5,2.5
		c1.8,0,3.7-0.1,5.5-0.5c0.2,0,0.3-0.1,0.5-0.1c-0.5,1-0.9,2-1,3.1c-0.2,1.3-0.6,3.5,0.6,4.3c0.2,0.1,0.4,0,0.6-0.1
		c0.2-0.3,0.4-0.5,0.3-0.9c-0.1-0.9-0.1-1.9-0.1-2.8c0.1-1.3,0.3-2.7,0.7-3.9c0.5-0.2,0.9-0.5,1.2-1c0.3-0.1,0.5-0.3,0.6-0.7
		c0-0.1,0-0.2,0-0.3c1.2-0.5,2.4-1,3.9-1c1.9,0,3.8,0.7,5.4,1.6c1.2,0.7,2.3,1.5,3.3,2.6c-0.1,0.7-0.2,1.4-0.2,2.2
		c0,1.1,0,2.3,0,3.4c0,0.6-0.2,1.5,0.1,2.1c-0.8,0.4-1.7,0.7-2.6,0.9c-0.7,0.2-1.4,0.3-2.1,0.3c0.5-1.2,0.3-2.6,0.2-3.8
		c0-1.5-0.1-3-0.3-4.5c-0.1-0.6-0.9-0.6-1,0c-0.2,1.5-0.3,3-0.3,4.5c0,1.3-0.3,2.7,0.2,3.9c0,0,0,0,0,0.1c-0.8,0-1.7,0.1-2.5,0
		c-0.4,0-0.8,0-1.2,0c0-0.3,0-0.6,0-0.9c-0.1-1.2-0.1-2.4,0-3.7c0.1-2.5,0.3-4.9-0.1-7.4c0-0.2-0.4-0.2-0.4,0
		c-0.4,2.6-0.8,5.2-1,7.8c-0.1,1.3-0.3,2.8,0,4.1c-3.1-0.2-6.3-0.6-9.4-1c-0.6-0.1-1.1-0.1-1.7-0.2c0-0.1,0.1-0.1,0.1-0.2
		c0.7-2.2,1.1-4.7,0.9-7c0-0.3-0.4-0.3-0.5,0c-0.3,2.3-1,4.5-1.4,6.8c0,0.2,0,0.3,0.1,0.4c-1-0.1-2.1-0.2-3.1-0.2
		c-0.5-2.2-0.2-4.7,0.2-6.9c0-0.2-0.2-0.3-0.3-0.1c-1.1,2-1.7,4.8-0.9,7c-1.4-0.1-2.8-0.1-4.2-0.2c0.4-1.4,0.2-3,0.4-4.4
		c0.2-1.5,0.4-3.1,0.6-4.6c0-0.3-0.5-0.4-0.6-0.1c-0.4,1.5-0.8,3-1,4.6c-0.2,1.4-0.6,3.1-0.3,4.5c-1.1,0-2.2,0-3.2,0
		c-0.1,0-0.2,0-0.4,0c0.2-1.2,0.2-2.6,0.3-3.8c0.2-1.7,0.4-3.4,0.6-5c0.1-0.4-0.5-0.5-0.6-0.2c-0.4,1.5-0.8,3-1.1,4.6
		c-0.2,1.4-0.5,3-0.3,4.5c-1.2,0-2.5,0-3.7,0.1c0.2-1-0.2-2.2-0.3-3.2c-0.1-1.5,0-3.1,0.2-4.7c0-0.3-0.5-0.4-0.5-0.1
		c-0.4,1.6-0.7,3.1-0.7,4.8c0,0.9-0.1,2.4,0.4,3.2c-1,0-1.9,0.1-2.8,0.1c-2.5,0.1-5.1,0-7.5,0.8c0.2-0.5,0.1-1.1,0.1-1.7
		c0.1-1.1,0.4-2.1,0.9-3c0.3-0.5,0.6-0.9,0.9-1.3c-0.1,2,0.2,4.1,1.9,4.8c0.3,0.1,0.5-0.3,0.3-0.5c-0.6-0.5-0.7-1.9-0.8-2.6
		c-0.2-0.9-0.2-1.8-0.2-2.8c0.8-0.6,1.7-1.1,2.5-1.6c0.7-0.4,1.4-0.7,2.2-0.9c-0.2,0.2-0.3,0.4-0.4,0.6c0,0,0,0.1,0,0
		c0.2-0.2,0.4-0.4,0.6-0.6c-0.1,0.2-0.2,0.3-0.4,0.5c-0.4,0.6-0.7,1.3-0.8,2.1c-0.3,1.6-0.1,3.3-0.1,4.9c0,1,1.5,1,1.5,0
		c-0.1-2.5-0.5-5,0.8-7.2c0.1-0.2,0.1-0.4-0.1-0.6c0,0,0,0,0,0c3.5-1,7.3-1.2,10.9-1.2c0.1,0,0.1,0.1,0.2,0.1
		c0.3,0.2,0.6-0.1,0.5-0.4c-0.3-1.5-0.9-2.8-1-4.3c-0.1-1.2,0-2.4,0.4-3.5c0,0.1,0.1,0.2,0.3,0.2c1.2,0,2.5,0.1,3.7,0.1
		c1.1,0,2.3,0.2,3.3-0.2c0.3-0.1,0.3-0.5,0-0.6c-1-0.4-2.2-0.2-3.3-0.2c-1.2,0-2.5,0.1-3.7,0.1c0,0-0.1,0-0.1,0
		c0.1-0.2,0.2-0.5,0.4-0.7c0.2-0.3-0.3-0.6-0.5-0.4c-1.1,1.4-1.6,3.2-1.7,5c0,1.3,0.1,3,1,4c-5-0.8-10.3-0.3-14.9,2
		c-0.4,0.2-0.8,0.4-1.2,0.7c0-0.3-0.4-0.3-0.5,0c-0.1,0.2-0.1,0.3-0.2,0.5c-1.1,0.9-2.1,1.9-2.8,3.2c-0.7,1.3-2,4.9-0.4,5.9
		c0.2,0.1,0.4,0.1,0.5-0.1c0,0,0.1-0.1,0.1-0.1c2.5,0.6,5.2,0.3,7.7,0.3c3.1,0,6.3,0.1,9.4,0.1c5.1,0.1,10.3,0.3,15.4,0.9
		c3.1,0.4,6.1,0.7,9.2,0.9c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.7,0.2,0.7-0.1c1,0,2,0,3,0c2.8-0.1,7.5-0.3,8.7-3.4
		c0.2-0.5-0.4-0.7-0.7-0.6c-0.1,0-0.1,0.1-0.2,0.1c0.1-0.5,0-1.1,0-1.5c0-1.1,0-2.3,0-3.4c0-0.3,0-0.7-0.1-1
		c0.6,0.8,1.2,1.7,1.5,2.6c-0.1,0.5-0.1,1-0.1,1.5c0,0.7-0.1,1.5,0.1,2.2c0.1,0.2,0.3,0.2,0.4,0c0.2-0.7,0.1-1.5,0.1-2.2
		c0.1,0.3,0.1,0.6,0.2,1c0.1,0.9,1.4,0.9,1.4,0c-0.2-2.9-1.8-5.4-3.9-7.4c-0.1-0.4-0.1-0.8-0.1-1.3c0-0.2-0.2-0.2-0.3,0
		c0,0.3-0.1,0.5-0.1,0.8c-1-0.9-2.1-1.6-3.2-2.2c-2.5-1.3-7.1-2.5-9.5-0.3c0-1.2-0.1-2.5-0.1-3.7c-0.1-1.8-0.1-3.5-0.3-5.2
		c2.7,0.2,5.4,0.4,8.2,0.5c3.3,0.2,6.6,0.5,9.9,0.3C67.1,51.9,67.1,50,65.8,49.9z"/>
				<path fill={props.fill} d="M70,27.9c-0.2-4-0.5-8.1-0.8-12.1c-0.3-4-0.4-8-0.8-11.9c0-0.4-0.6-0.4-0.6,0c-0.3,3.8-0.1,7.6,0,11.4
		c0.2,4,0.4,8.1,0.5,12.1c0.2,3.8,0.2,7.7,0.1,11.5c-0.1,4-0.7,7.9-1.2,11.9c-0.1,1.1,1.4,1.6,1.6,0.5c0.9-3.7,1.1-7.6,1.2-11.3
		C70.3,35.8,70.2,31.8,70,27.9z"/>
				<path fill={props.fill} d="M61.2,40.7c-0.1,0-0.2,0-0.4,0c0-5-0.3-10-0.4-15c-0.1-2.6-0.2-5.3-0.2-7.9c0-2.7,0.2-5.5,0-8.2
		c0-0.1-0.1-0.1-0.1,0c-1.5,4.9-1.1,10.5-1.1,15.6c0,5.2,0.4,10.4,0.4,15.6c-8.3-0.1-16.7-0.7-25-0.8c-4.5,0-9,0.1-13.5,0.7
		c-3.6,0.5-7.2,1.1-10.5,2.6c0.1-2.5-0.1-5.1-0.1-7.7c0.1-3.1,0.2-6.1,0.4-9.2c0.3-5.4,0.7-10.9,0.3-16.3c2.3,1.7,5.1,1.9,7.8,2
		c3.8,0.1,7.5-0.6,11.2-1.1c3.5-0.5,7-0.7,10.4-0.7c2.4,0.1,4.8,0.2,7.2,0.1c0.5,0,1,0,1.5-0.1c0.7,0,1.3,0,2,0
		c2.6,0.1,5.3,0.3,7.9,0.1c1.3-0.1,1.3-1.8,0-2c-1.1-0.1-2.2-0.1-3.3-0.1c0-0.1-0.1-0.3-0.3-0.2c-0.2,0.1-0.4,0.2-0.6,0.2
		c-0.9,0-1.8,0-2.7,0c-2.5-0.1-5-0.1-7.5-0.1c-4.5,0-9-0.1-13.4,0.5c-0.4,0.1-0.8,0.1-1.2,0.2c-1.8-0.1-3.7-0.2-5.5-0.4
		c-4.6-0.5-9.2-1.4-13.8-1.4c-0.1,0-0.1,0.1,0,0.1c4.6,0.8,9.2,1.4,13.8,2c0.6,0.1,1.2,0.1,1.9,0.2c-1.9,0.3-3.7,0.5-5.6,0.7
		c-3.1,0.3-6.9,0.4-9.9-0.9c0-0.3-0.1-0.6-0.1-0.9c0-0.2-0.2-0.2-0.3,0c0,0.3-0.1,0.6-0.1,1c-0.1,0.1-0.1,0.2,0,0.3
		c-0.6,5.6-0.9,11.2-1.3,16.7c-0.2,3-0.4,6.1-0.5,9.2c-0.1,2.8-0.3,5.8,0.1,8.7c0.1,0.8,1.2,0.7,1.5,0c16.8-3.8,34-1.5,51-1.5
		C62.6,42.8,62.6,40.7,61.2,40.7z"/>
				<path fill={props.fill} d="M36,56.5c-1-0.4-2.2-0.2-3.3-0.1c-1.2,0-2.5,0.1-3.7,0.2c-0.2,0-0.2,0.3,0,0.3c1.2,0.1,2.5,0.2,3.7,0.2
		c1.1,0,2.2,0.2,3.3-0.1C36.2,56.9,36.2,56.5,36,56.5z"/>
				<path fill={props.fill} d="M35.9,58.8c-0.9,0.1-1.8,0.5-2.7,0.7c-1.4,0.3-2.9,0.1-4.1-0.6c-0.2-0.1-0.4,0.2-0.2,0.3
		c1.8,1.2,5.4,1.7,7.1,0C36.2,59,36.1,58.8,35.9,58.8z"/>
				<path fill={props.fill} d="M28.8,46.6c8.1,0,16.3,0.4,24.4-0.1c0.2,0,0.2-0.3,0-0.4c-7.9-0.5-15.9-0.1-23.9-0.1c-8.1,0-16.3-0.1-24.4,0.3
		c0,0,0,0,0,0C12.8,46.8,20.8,46.6,28.8,46.6z"/>
				<path fill={props.fill} d="M62.4,7.1c-2.6-0.4-5.4-0.1-8-0.1C51,7.1,47.7,7.3,44.3,7.3c-5.9,0-12-0.2-17.6-2.3c0,0,0,0,0,0
		c2.3,1.4,4.8,1.9,7.3,2.3c3.1,0.5,6.3,0.6,9.4,0.7c3.4,0.1,6.8,0,10.1-0.1c2.9-0.1,5.9,0,8.8-0.5C62.5,7.4,62.5,7.1,62.4,7.1z"/>
				<path fill={props.fill} d="M64.6,7.2c-1.3,3-1.9,6.4-2.2,9.7c-0.3,3.5-0.2,7.2,0.9,10.5c0.1,0.2,0.3,0.1,0.3-0.1
		C62,21,62.2,13.4,64.6,7.2C64.8,7.2,64.7,7.2,64.6,7.2z"/>
				<path fill={props.fill} d="M66.9,9.6c-0.1,5.1-0.2,10.1-0.6,15.2c-0.4,5.1-1.2,10.2-1.7,15.3c0,0.2,0.3,0.2,0.3,0
		c1.2-4.8,1.5-9.9,1.9-14.8C67,20,67.1,14.8,67.1,9.6C67.1,9.5,66.9,9.5,66.9,9.6z"/>
				<path fill={props.fill} d="M3.6,32c0,0.2,0.3,0.1,0.3,0c0.2-4.1,0.6-8.1,1.1-12.2C5.5,16,6,12.1,5,8.4c0-0.1-0.2-0.1-0.2,0.1
		C6.7,16.3,2.2,24.2,3.6,32z"/>
				<path fill={props.fill} d="M9.5,14.2C9.5,14.1,9.5,14.1,9.5,14.2c-1.2,3.7-1.9,7.4-2.3,11.3c-0.4,4-0.7,8.3-0.3,12.3c0,0.3,0.4,0.3,0.4,0
		c0.4-3.9,0.1-7.8,0.3-11.7C7.9,22,8.4,18,9.5,14.2z"/>
			</g>
		</svg>
	)
}

export default ConsoInterface;