import React from 'react';

const SvgFlecheDouble = (props: any) => (
<svg viewBox="0 0 297.7 73.6" {...props}>
<polygon points="258.1,0 252,6.6 279.6,32.3 18.1,32.3 45.7,6.6 39.6,0 0,36.8 39.6,73.6 45.7,67 18,41.3 279.6,41.3 252,67 
	258.1,73.6 297.7,36.8 "/>
</svg>
);

export default SvgFlecheDouble;
