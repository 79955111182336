import React, { useRef, useState, useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { useMatomo } from '@datapunt/matomo-tracker-react'

import LogoOnyx from "../../_resources/image/onyx_logo.png";
import Android from "../../_svg/Android";

import OnyxFiltres from "./OnyxFiltres";
import OnyxExport from "./OnyxExport";
import SvgAccueil from "./SvgAccueil";

import Doc from "./Doc";
import Compatible from "./Compatible";
import Contact from "./Contact";

import BarIndicator from "../../sidebars/IndicatorBar";
import VisibilitySensor from "react-visibility-sensor";
import ScrollSequence from "../scroll/ScrollSequence";

import Recap from "./Footer";
import FooterSection from "../section/FooterSection";

import images from "../../_resources/videoOnyx";
import images2 from "../../_resources/videoOnyx2";
import images3 from "../../_resources/videoOnyx3";

import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import ImageSelector from "../responsive/ImageSelector";
import PopUp from "../responsive/PopUp";

import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import docActions from "../../redux/doc/doc.action";

const App = (props) => {
  const { t, dispatch } = props;
  const { trackLink } = useMatomo()
  const ref = useRef();
  const [visible, setVisible] = useState("");

  const [left, setLeft] = useState(105);
  const [modal, setModal] = useState(false)
  const [isTablet, setIsTablet] = useState(false);
  const [init, setInit] = useState(false);
  const tablet = useMediaQuery({ query: "(max-width: 1040px)" })

  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  })

  if (!init) {
    setIsTablet(tablet)
    setInit(true)
  }

  function timeOutFunction() {
    toggle()
  }

  const toggle = () => {
    setModal(!modal)
  }

  const callBackModal = (result) => {
    if (result) {
      window.localStorage.setItem('lowDebit', true)
      setIsTablet(true)
      toggle()
    }
    else {
      window.localStorage.setItem('lowDebit', false)
      toggle()
    }
  }

  window.addEventListener("scroll", () => {
    const svg = document.getElementById("svg-onyx");
    if (svg) {
      svg.style.left = `${0 - window.scrollY * 2}px`;
    }
  });

  const localItem = window.localStorage.getItem('lowDebit')
  if (localItem === "true" && !isTablet) {
    setIsTablet(true)
  }

  function downloadFile() {
    dispatch(docActions.getDoc("onyx", "fr"));
    trackLink({ href: 'Onyx', linkType: 'download' })
  }

  const content = {
    button: t("all.button.download"),
  };

  return (
    <div className={"onyx-container responsiv"} itemScope itemType="https://schema.org/WebPage">
      {modal && <PopUp callBack={callBackModal} />}
      <MetaTags>
        <title>Onyx</title>
        <meta
          name="description"
          content="Application android, détecteur radio unidirectionelle, pour relever vos compteurs d'aujourd'hui et de demain"
        />
        <meta name="keywords" content="application, android, radio, radio unidirectionelle, vapeur, air comprimé, eau, air conditionné, eau chaude, eau froide, électricité, gaz, dual water, thermique, carburant, pression, vitesse, convertisseur, répartiteur de frais de chauffage, fuite, fraude, alarmes, bluetooth, CSV" />
        <meta property="og:title" content="Onyx" />
        <meta property="og:type" content="Application - solution de releve radios" />
        <meta property="og:url" content="https://www.dioptase.fr/applications/onyx" />
        <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
        <meta property="og:description" content="Solution de relève radios unidirectionnelles installées sur les compteurs de tout type d'énergies" />
      </MetaTags>
      <BarIndicator isVisible={visible} parent="onyx" onyx />
      <div className={"image-onyx"} id={"svg-onyx"} style={(!tablet && isTablet) ? { position: "relative", height: "calc(100vh - 52px)", width: "100%", marginTop: "52px" } : {}}>
        <img itemProp="headline" src={LogoOnyx} alt="onyx" />
        <div className="android-container">
          <div className="text">
            <h3 itemProp="alternativeHeadline">Snifer</h3>
            <h3 itemProp="alternativeHeadline">radio Mbus</h3>
          </div>
          <div className="svg-box">
            <Android height="5em" fill={"#1F8491"} />
          </div>
        </div>
        <div className={"download-documentation"}>
          <div className={"button-download"} itemProp="subjectOf">
            <button style={{ borderColor: "#31c3b6" }} onClick={downloadFile}>
              {content.button}
            </button>
          </div>
        </div>
      </div>
      {isTablet ? (
        <ImageSelector
          src={images("", "fr")[images("", "fr").length - 1]}
          children={<SvgAccueil lowDebit={isTablet} />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={0}
          className="onyx-accueil"
          images={images("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          y={78}
          stretch
          fixed
          zoom={0.7}
          precipitate
          children={<SvgAccueil t={t} left={left} />}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images2("", "fr")[images2("", "fr").length - 1]}
          children={<OnyxFiltres t={t} />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={1}
          className="onyx-filtre"
          images={images2("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          start={4000 + window.innerHeight}
          y={0}
          sTop
          children={<OnyxFiltres t={t} />}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images3("", "fr")[images3("", "fr").length - 1]}
          children={<OnyxExport t={t} />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={2}
          className="onyx-export"
          images={images3("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          start={8000 + window.innerHeight}
          y={52}
          sTop
          zoom={0.7}
          stretch
          children={<OnyxExport t={t} />}
          callBack={timeOutFunction}
        />
      )}
      <Recap t={t} />
      <Compatible
        section={"Logiciels"}
        parentColor="#06d0e7"
        parent="onyx"
        t={t}
      />
      <Contact t={t} />
      <FooterSection t={t} />
    </div>
  );
};

function mapStateToProps(state) {
  const { indicator } = state;

  return {
    indicator,
  };
}

const connectedDoc = connect(mapStateToProps)(App);
const tr = withTranslation()(connectedDoc);
export default tr;
