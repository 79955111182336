import React, { Component } from "react";

import SvgDiehl from "../../_svg/Diehl";
import SvgKamstrup from "../../_svg/Kamstrup";
import SvgIntegra from "../../_svg/Integra";
import SvgItron from "../../_svg/Itron";
import SvgMaddalena from "../../_svg/Maddalena";
import SvgSensus from "../../_svg/Sensus";
import SvgZenner from "../../_svg/Zenner";

export default class Partenaire extends Component<any, any> {
    render(){
        const { t } = this.props
        const content = {
            raise: t('produits.mbox.raise'),
            compatible: t('produits.mbox.compatible.wmbus')
        }
        return (
            <div className="partenaire-container">
                <div className="text-center">
                <h2>{content.raise}</h2>
                <h1>{content.compatible}</h1>
                </div>
                <div className="logo-container">
                    <div className="logo-svg-container">
                        <SvgDiehl height="2em" fill="white"/>
                        <SvgKamstrup height="2em" fill="white"/>
                        <SvgIntegra height="2em" fill="white"/>
                        <SvgItron height="2em" fill="white"/>
                    </div>
                    <div className="logo-svg-container">
                        <SvgMaddalena height="2em" fill="white"/>
                        <SvgSensus height="2em" fill="white"/>
                        <SvgZenner height="2em" fill="white"/>
                    </div>
                </div>
            </div>
        )
    }
}