import React from 'react';

const SvgWifi = (props: any) => (
    <svg viewBox="0 0 61.94 65.76" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M6.54,50.67c-5,.31-7.92,3.53-5.9,7.91a11.9,11.9,0,0,0,3.44,4.18A4.68,4.68,0,0,0,9.29,65.3a2.44,2.44,0,0,0,.26.06c4.27.83,7.45-2.16,7.38-5.81a7.82,7.82,0,0,0,.82-2.69C18.31,51.46,10.8,50.41,6.54,50.67Zm.34,10.66-.15-.09a10.83,10.83,0,0,1-1.27-2.49,6.62,6.62,0,0,1,.42-4c0-.1-.15-.18-.2-.07A7.26,7.26,0,0,0,5,59.71C3.18,57.83,1.73,55.17,4.39,54a12.28,12.28,0,0,1,5.31-.69,10.85,10.85,0,0,0-2.41,5.86,3.52,3.52,0,0,0,1.15,3.09A12.07,12.07,0,0,1,6.88,61.33ZM11,62.94A5.18,5.18,0,0,1,9.3,62.6a2.53,2.53,0,0,0,1.23-.1c.27-.08.4-.48.09-.61-3.31-1.44-1.71-5.87-.47-8.57,2.3.24,4.39,1.07,4.83,2.76l0,1.58a9.66,9.66,0,0,1-2.6,4.19,5.12,5.12,0,0,1-.75-2.46,9.17,9.17,0,0,1,1.46-4.55c.19-.35-.34-.63-.56-.31-1.44,2-3.26,6.07-1.2,8.19Z"/>
			<path fill={props.fill} d="M35.81,51.47c-1.45-4-4.47-7.48-7.7-10.42A59,59,0,0,0,16,32.91c-3.33-1.75-7.45-4-11.38-4.14a.23.23,0,0,0-.13.43l.24.14a.8.8,0,0,0-.91.93c.53,3.54-.44,7-.8,10.5,0,.46.74.53.86.11,1-3.56,2-7.1,1.58-10.81a1,1,0,0,0-.12-.36c2.78,1.63,6,2.81,8.79,4.37a61.68,61.68,0,0,1,11.06,7.69c3.14,2.75,6.08,5.87,7.71,9.61a13.13,13.13,0,0,1,.92,3.16.19.19,0,0,0-.23.06c-.86,1.61-1.75,3.2-2.48,4.87-.4.92-.84,2.16.08,2.92.16.13.51.11.54-.13a43.18,43.18,0,0,1,2.14-7.36,14.52,14.52,0,0,1,.1,1.47c0,2-.84,3.64-1.51,5.48a1,1,0,0,0,0,.9c-.94.13-1.87.27-2.84.32a25.52,25.52,0,0,1-3.8-.14c.23-3.45-1.28-7-3.38-10.11.24-.59.45-1.2.69-1.78a23.73,23.73,0,0,0,1.56-5.37.32.32,0,0,0-.62-.16,47.4,47.4,0,0,1-2,4.57c-.2.43-.42.87-.64,1.31A31.17,31.17,0,0,0,19,48.64c-.54-.55-1.12-1.08-1.72-1.58.44-1.05.77-2.15,1.17-3.19A27.89,27.89,0,0,0,20,38.68c0-.24-.36-.36-.42-.1A26.72,26.72,0,0,1,18,43c-.46,1.07-1.1,2.19-1.56,3.32a24.34,24.34,0,0,0-2.54-1.69C14,44,14,43.19,14,42.49c.11-1.64,0-3.29.09-4.93,0-.2-.29-.21-.35,0a20.85,20.85,0,0,0-1.17,4.4c-.09.6-.25,1.23-.37,1.85a22.81,22.81,0,0,0-4-1.49,11.36,11.36,0,0,1,.21-4.65,32.61,32.61,0,0,0,1.12-5.42c0-.27-.43-.31-.5-.06-.67,2.36-3.1,6.66-2.58,9.74a18.84,18.84,0,0,0-3-.31.26.26,0,0,0-.08.52A27.16,27.16,0,0,1,6.81,43a3.43,3.43,0,0,0,1,1.21.39.39,0,0,0,.66-.35c0-.08,0-.16,0-.24a25.52,25.52,0,0,1,3.67,1.91,2.71,2.71,0,0,0,.24.78.5.5,0,0,0,.74.09l.13-.14a26.81,26.81,0,0,1,3.91,3.33,22.23,22.23,0,0,1,3.39,4.59,3.84,3.84,0,0,0,0,.48.41.41,0,0,0,.44.36c.15.3.3.6.43.91A24.82,24.82,0,0,1,23,63.25a1.45,1.45,0,0,0,2.36.92c3.16,1.19,7,1.38,10-.26a.76.76,0,0,0-.23-1.41C37.27,59.32,37,54.87,35.81,51.47Z"/>
			<path fill={props.fill} d="M61.5,63.36a.43.43,0,0,0,0-.11,91,91,0,0,0-3.58-27.53,51.07,51.07,0,0,0-5.43-12.16,36.12,36.12,0,0,0-6.27-7.06A69.7,69.7,0,0,0,26.3,3.59C21.53,1.59,15.82-.2,10.55.72c0-.21,0-.42,0-.62s-.19-.14-.22,0l-.12.71A14.11,14.11,0,0,0,6.74,1.92a.37.37,0,0,0,.31.67A15.3,15.3,0,0,1,10,2a18.18,18.18,0,0,0,.09,5.1c.37,2.19.65,5.53,2.36,7.21a.41.41,0,0,0,.68-.26c.09-1.89-1.2-4-1.67-5.9a30.73,30.73,0,0,1-.92-6.2,24.64,24.64,0,0,1,8.85,1.14l-.05.06a16.68,16.68,0,0,0-1.65,6.74c-.08,2,.23,5,1.75,6.56a.41.41,0,0,0,.68-.26,8.59,8.59,0,0,0-.51-2.67,17.13,17.13,0,0,1-.48-3.63,15.35,15.35,0,0,1,1-6.43.32.32,0,0,0,0-.13,52.09,52.09,0,0,1,6,2.36l1.64.79a11.72,11.72,0,0,0-3,6.28A14.34,14.34,0,0,0,24.65,19,30.81,30.81,0,0,0,9.18,16.57,22.63,22.63,0,0,0,8.3,11.8C7.65,8.57,6.83,5.38,5.92,2.2a.15.15,0,0,0-.29,0,42.62,42.62,0,0,0,.82,8.57c.24,1.56.42,3.12.54,4.69,0,.5,0,.95,0,1.38l-.17,0a.33.33,0,0,0,.09.66H7.1a2.3,2.3,0,0,0,.62,1.09.58.58,0,0,0,.77,0,2.33,2.33,0,0,0,.56-1,38.28,38.28,0,0,1,15.83,3.7A33.54,33.54,0,0,1,37.07,32.18c6.34,9,9.18,20.27,9.27,30.9a.25.25,0,0,0,0,.36h0a1.34,1.34,0,0,0,1.62,1,12.76,12.76,0,0,0,4,.89c1.44.13,2.89.18,4.34.27a15.68,15.68,0,0,0,4.59,0A1.28,1.28,0,0,0,61.5,63.36Zm-7.68-.46c-1.48.12-3.13.32-4.72.32,0-11.5-3.22-23.37-10.36-32.93A35.47,35.47,0,0,0,26,19.59c0-4.31-.41-9.16,2.09-13v0A73.41,73.41,0,0,1,45,18.3l.21.21a18.82,18.82,0,0,0-3.76,4.74c-.94,1.8-2.45,5.19-1.07,7a.5.5,0,0,0,.81,0,9,9,0,0,0,.49-3A13.26,13.26,0,0,1,43,23.39a45.29,45.29,0,0,1,2.51-4.57c5.29,5.12,8.29,11.23,10.34,18,.74,2.44,1.34,4.92,1.84,7.42A22.46,22.46,0,0,0,54,48.47c-1,1.53-2.69,4.5-2,6.3a.38.38,0,0,0,.67.08,5.07,5.07,0,0,0,.79-2.18A18,18,0,0,1,55,49a22.91,22.91,0,0,1,2.79-4.36c.6,3.11,1,6.27,1.33,9.42-1.5,2.37-3.93,3.73-5.75,5.8-.24.27,0,.61.39.47a12.41,12.41,0,0,0,5.46-5.13c.21,2.51.33,5,.38,7.52A25.87,25.87,0,0,0,53.82,62.9Z"/>
			<path fill={props.fill} d="M36.6,11.83a13,13,0,0,0-2,4.17c-.71,1.86-2.17,4.73-1.28,6.69.14.31.66.48.86.1a11.22,11.22,0,0,0,.76-2.92A31,31,0,0,1,36,16.36c.4-1.15,1.55-3.37.72-4.52C36.69,11.81,36.63,11.79,36.6,11.83Z"/>
			<path fill={props.fill} d="M50.16,25.81a.11.11,0,0,0-.21,0A48.27,48.27,0,0,1,46,32.51c-1,1.65-2.75,4.27-2.17,6.22a.42.42,0,0,0,.73.09,11.88,11.88,0,0,0,1-2.82,24,24,0,0,1,2-4A20.74,20.74,0,0,0,50.16,25.81Z"/>
			<path fill={props.fill} d="M53.42,35.35c-1.33,1.79-2.55,3.58-3.69,5.48s-2.26,3.7-2.44,5.83c0,.4.61.53.81.2,1.17-1.92,1.94-4,3-6a48.38,48.38,0,0,0,2.6-5.38C53.75,35.3,53.51,35.23,53.42,35.35Z"/>
			<path fill={props.fill} d="M25.89,58.18c.84-.86.88-2.28,1.26-3.37a13.59,13.59,0,0,1,2.12-4.3c.16-.2-.17-.39-.34-.25a9.16,9.16,0,0,0-2.64,3.34c-.53,1.22-1.6,3.09-1.12,4.4A.45.45,0,0,0,25.89,58.18Z"/>
		</g>
	</g>
</svg>
);

export default SvgWifi;
