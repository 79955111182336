import React from 'react';

const SvgSaphir = (props: any) => (
    <svg viewBox="0 0 48.41 48.41" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<circle fill="#bd782f" cx="24.2" cy="24.2" r="24.2"/>
			<path fill="#fff" d="M33.35,29.52a5.94,5.94,0,0,1-2.51,4.85,10,10,0,0,1-6.34,2,10,10,0,0,1-5.85-1.73,9.51,9.51,0,0,1-3.6-4.56l3.69-1.58a6.84,6.84,0,0,0,2.36,3.08,5.74,5.74,0,0,0,3.4,1.11,6,6,0,0,0,3.3-.86,2.41,2.41,0,0,0,1.33-2q0-2.12-3.24-3.1l-3.78-1q-6.42-1.62-6.43-6.2a5.76,5.76,0,0,1,2.45-4.84,10.3,10.3,0,0,1,6.28-1.82,10.17,10.17,0,0,1,5.28,1.4A7.6,7.6,0,0,1,33,18l-3.69,1.53a4.6,4.6,0,0,0-2-2.18,6.35,6.35,0,0,0-3.17-.79,5.32,5.32,0,0,0-2.9.81,2.34,2.34,0,0,0-1.28,2q0,1.89,3.55,2.69l3.33.86Q33.36,24.53,33.35,29.52Z"/>
		</g>
	</g>
</svg>
);

export default SvgSaphir;
