import React, { useRef, useState, useEffect } from "react";

import Connexion from "./Connexion";
import Doc from "./Doc";
import Recap from "./Recap";
import Contact from "../onyx/Contact";
import Footer from "../section/FooterSection";

import IndicatorBar from "../../sidebars/IndicatorBar";

import ScrollSequence from "../scroll/ScrollSequence";
import Filter from "./Filter";


import LogoTm from "../../_svg/LogoTourmaline";

import images from "../../_resources/tmAccueil"
import images2 from "../../_resources/ccSynchronisation"
import images3 from "../../_resources/ccVirtualMeter"
import images4 from "../../_resources/ccAlertes"
import images5 from "../../_resources/ccMasks"

import ImageSelector from "../responsive/ImageSelector";

import { withTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const App = (props) => {
  const { t } = props
  const [visible, setVisible] = useState("");
  const [height, setHeight] = useState(0)
  const path = ["Users","hugo","Desktop","documentation","resources"]

  const tmAccueil = images("", "fr")

  const is1600 = useMediaQuery({ query: "(max-width: 1600px)" });
  const is1366 = useMediaQuery({ query: "(max-width: 1366px)" });
  const is1280 = useMediaQuery({ query: "(max-width: 1280px)" });

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });

  let widthC = 1920;
  let heightC = 1080;
  if(is1600){
    widthC = 1600
    heightC = 900
  }

  if(is1366){
    widthC = 1366
    heightC = 768
  }

  if(is1280){
    widthC = 1280
    heightC = 720
  }

  return (
    <div className={"tourmaline-container"}>
        <IndicatorBar isVisible={visible} parent="crystalcloud"/>
      <div className="svg-box" style={{marginTop: isMobile ? "52px" : "calc(5% + 104px)", zIndex:10}}>
        <LogoTm height="7em"/>
      </div>
      {isTablet ? (
        <ImageSelector src={images("", "fr")[images("", "fr").length - 1]} className="accueil"/>
      ) : (
      <ScrollSequence
        duration={8000}
        index={0}
        className="tourmaline-accueil"
        images={images("", "fr")}
        height={heightC}
        width={widthC}
        y={104}
        stretch zoom={0.7}
        fixed
      />
      )}
      <Connexion t={t}/> 
      {isTablet ? (
        <ImageSelector src={images2("", "fr")[images2("", "fr").length - 1]} children={<Filter setVisible={setVisible}/>} className="filter"/>
      ) : (
      <ScrollSequence
        duration={8000}
        index={1}
        className="crystalcloud-synchronisation"
        images={images2("", "fr")}
        height={heightC}
        width={widthC}
        y={0}
        stretch zoom={0.7}
        start={8000 + (window.innerHeight / 2)}
        style={{marginLeft: is1280 ? "0" : "10%"}}
        x={is1280 ? 0 : window.innerWidth/10}
        children={<Filter setVisible={setVisible}/>}
      />
      )}
      <Doc t={t}/>
      <Recap t={t}/>
      <Contact objet="Crystalcloud" t={t}/>
      <Footer t={t}/>
    </div>
  );
};

const tr = withTranslation()(App);
export default tr;
