import React from 'react';
import './responsiv.scss';
import ConsoAlarm from '../../_svg/Conso_alarm';
import ConsoData from '../../_svg/Conso_data'
import ConsoInterface from '../../_svg/Conso_interface';
import ConsoMeter from '../../_svg/Conso_meter';
import ConsoSuivi from '../../_svg/Conso_suivi';

import imgAbonne from '../../_resources/image/maconso/abonnes.png';
import imgAlarm from '../../_resources/image/maconso/alarmes.png';
import imgInterface from '../../_resources/image/maconso/interface.png';

import { useMatomo } from '@datapunt/matomo-tracker-react';
import { connect } from "react-redux";
import docActions from "../../redux/doc/doc.action";
import { withTranslation } from 'react-i18next';

const ResponsivMaconso = props => {
    const { dispatch, t } = props
    const { trackLink } = useMatomo()

    function downloadFile() {
        dispatch(docActions.getDoc("maconso", "fr"));
        trackLink({ href: 'maconso', linkType: 'download' })
    }

    return (
        <div className='intervention-responsiv' style={{ marginTop: "52px" }}>
            <div className='box100'>
                <ConsoInterface fill={"#D0D146"} />
                <h5>{t('maconso.interface')}</h5>
                <div className='span-container'>
                    <span>{t('maconso.interface.description')}</span>
                </div>
                <div className={"download-documentation"}>
                    <div className={"button-download"} itemProp="subjectOf">
                        <button style={{ borderColor: "#D0D146" }} onClick={downloadFile}>
                            {t("all.button.download")}
                        </button>
                    </div>
                </div>
                <div className="image-container">
                    <img src={imgInterface} />
                </div>
            </div>
            <div className='box100'>
                <ConsoSuivi fill={"#D0D146"} />
                <h5>{t('maconso.followed')}</h5>
                <div className='span-container'>
                    <span>{t('maconso.followed.description')}</span>
                </div>
                <div className='image-container'>
                    <img src={imgAbonne}></img>
                </div>
            </div>
            <div className='box100'>
                <ConsoMeter fill={"#D0D146"} />
                <h5>{t('maconso.subscribers')}</h5>
                <div className='span-container'>
                    <span>{t('maconso.subscribers.description')}</span>
                </div>
                <div className='image-container'>
                    <img src={imgAbonne}></img>
                </div>
            </div>
            <div className='box100'>
                <ConsoAlarm fill={"#D0D146"} />
                <h5>{t('maconso.alarms')}</h5>
                <div className='span-container'>
                    <span>{t('maconso.alarms.description')}</span>
                </div>
                <div className='image-container'>
                    <img src={imgAlarm}></img>
                </div>
            </div>
            <div className='box100'>
                <ConsoData fill={"#D0D146"} />
                <h5>{t('maconso.datas')}</h5>
                <div className='span-container'>
                    <span>{t('maconso.datas.description')}</span>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { indicator } = state;

    return {
        indicator,
    };
}

const connectedDoc = connect(mapStateToProps)(ResponsivMaconso);
const tr = withTranslation()(connectedDoc);
export default tr;