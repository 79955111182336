import React, { Component } from 'react';
import { connect } from "react-redux";
import DocumentationImg from '../../_resources/image/catalogue_dld.png';
import docActions from "../../redux/doc/doc.action"

class Doc extends Component<any, any> {

    constructor(props:any) {
        super(props);
        this.state={
            transition:0,
        }
    }

    downloadFile = () => {
        const { dispatch } = this.props
        //https://test.dioptase.local/resources/documentation/
        dispatch(docActions.getDoc("dld", "fr"))
      }

    render(){
        return (
            <div className={"documentation-container"} itemScope itemType="https://schema.org/thing">
                                <div className={"download-documentation"}>
                    <div className={"button-download"}>
                        <button itemProp="subjectOf" style={{borderColor:"#d8a202"}} onClick={this.downloadFile}>Télécharger notre documentation</button>
                    </div>
                </div>
                <div className={"catalogue"}>
                    <img itemProp="image" src={DocumentationImg} height={`${window.innerHeight / 2}px`} alt="doc"/>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state: any) {
    const { doc } = state;
  
    return {
      doc,
    };
  }
  
  const connectedDoc = connect(mapStateToProps)(Doc);
  export default connectedDoc;