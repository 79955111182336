import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import deviceAction from "../../redux/device/device.action";
import jobAction from "../../redux/job/job.action";
import './style.scss';
import Stuff from "./Stuff";
import Jobs from "./Job";

const Dashboard = props => {
    const { dispatch, device, job } = props;
    const [selected, setSelected] = useState("Materiels")
    useEffect(() => {
        dispatch(deviceAction.getAll());
        dispatch(jobAction.getAll());
        dispatch(jobAction.getAdvantages());
    }, [])

    const onglets = ["Materiels", "Emploies"]
    return (
        <div className="admin-dashboard-container">
            <div className="onglet">
                {onglets.map(el => (
                    <div className={`onglet-box ${el === selected ? "selected" : ""}`} onClick={() => setSelected(el)}>
                        <h3>{el}</h3>
                    </div>
                ))}
            </div>
            {selected === "Materiels" ? <Stuff stuff={device.devices} /> : <Jobs jobs={job.jobs} advantages={job.advantages} />}
        </div>
    )
}

const mapStateToProps = ({ device, job, admin, }) => {
    return {
        device,
        job,
        admin,
    }
}
const connectedDashboard = connect(mapStateToProps)(Dashboard)
export default connectedDashboard;