import React from 'react';

const SvgDiehl = (props: any) => (
<svg viewBox="0 0 120.66 19.96" {...props}>
	<path d="M6.12,4.96h10.86c2.62,0,3.64,.74,3.64,3.58v2.7c0,2.84-1.02,3.59-3.64,3.59H6.12V4.96ZM0,19.96H18.47c6.12,0,8.27-2.01,8.27-6.95V6.95c0-4.94-2.15-6.95-8.27-6.95H0V19.96Z"/>
	<rect x="32.21" y="0" width="6.12" height="19.96"/>
	<polygon points="43.62 19.96 65.81 19.96 65.81 15.16 49.74 15.16 49.74 11.97 64.63 11.97 64.63 7.83 49.74 7.83 49.74 4.63 65.45 4.63 65.45 0 43.62 0 43.62 19.96"/>
	<polygon points="70.13 19.96 76.25 19.96 76.25 12.41 89.16 12.41 89.16 19.96 95.27 19.96 95.27 0 89.16 0 89.16 7.11 76.25 7.11 76.25 0 70.13 0 70.13 19.96"/>
	<polygon points="100.78 19.96 120.66 19.96 120.66 14.83 106.9 14.83 106.9 0 100.78 0 100.78 19.96"/>
</svg>
);

export default SvgDiehl;
