import React from 'react';

const SvgWize = (props: any) => (
	<svg viewBox="0 0 114.26 43.27" {...props}>
		<polygon points="0 13.59 2.96 13.59 8.84 34.58 17.1 13.59 17.7 13.59 25.86 34.58 31.85 13.59 34.79 13.59 26.51 42.72 25.97 42.72 17.42 20.68 8.71 42.72 8.17 42.72 0 13.59"/>
		<path d="M39.5,21.18h2.74v21.54h-2.74V21.18Zm1.36-8.87c.62,0,1.16,.23,1.6,.67,.44,.45,.66,.99,.66,1.62s-.22,1.15-.66,1.6c-.44,.45-.97,.67-1.6,.67s-1.14-.22-1.58-.67c-.44-.45-.66-.98-.66-1.6s.22-1.17,.66-1.62c.44-.45,.97-.67,1.58-.67"/>
		<polygon points="46.2 21.18 61.85 21.18 49.79 40.26 61.46 40.26 61.46 42.72 45.07 42.72 57.12 23.61 46.2 23.61 46.2 21.18"/>
		<path d="M82.93,29.77c-.37-1.48-.9-2.66-1.6-3.54-.7-.88-1.62-1.6-2.77-2.14-1.15-.54-2.36-.81-3.63-.81-2.09,0-3.89,.68-5.39,2.04-1.1,.99-1.93,2.48-2.49,4.46h15.88Zm0,5.8l2.31,1.23c-.76,1.5-1.63,2.72-2.63,3.64-.99,.93-2.11,1.63-3.35,2.11-1.24,.48-2.65,.72-4.22,.72-3.48,0-6.19-1.15-8.15-3.46-1.96-2.3-2.94-4.91-2.94-7.81,0-2.73,.83-5.17,2.49-7.31,2.1-2.72,4.92-4.08,8.45-4.08s6.53,1.39,8.7,4.18c1.54,1.97,2.33,4.42,2.35,7.37h-19.18c.05,2.5,.84,4.55,2.37,6.15,1.53,1.6,3.42,2.4,5.67,2.4,1.09,0,2.14-.19,3.17-.57,1.03-.38,1.9-.89,2.62-1.52,.72-.63,1.5-1.65,2.33-3.06"/>
		<path d="M101.21,17.54c-2.55,0-4.63-2.09-4.63-4.67s2.07-4.68,4.63-4.68,4.63,2.09,4.63,4.68-2.07,4.67-4.63,4.67m0-17.54c-7.21,0-13.05,5.91-13.05,13.2,0,8.99,10.88,15.47,13.05,29.4,2.18-13.93,13.05-20.41,13.05-29.4,0-7.29-5.84-13.2-13.05-13.2"/>
	</svg>
);

export default SvgWize;
