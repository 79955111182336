import { authHeader, handleResponse } from '../../_helpers';

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(`/api/job/all`, requestOptions);
    return handleResponse(response);
}

async function getJobInfo(jobId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(`/api/job/${jobId}`, requestOptions);
    return handleResponse(response);
}

async function getAdvantages() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(`/api/job/advantages`, requestOptions);
    return handleResponse(response);
}

async function save(job) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(job)
    };
    let response = await fetch(`/api/job/save`, requestOptions);
    return handleResponse(response);
}

async function deleteJob(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    let response = await fetch(`/api/job/delete/${id}`, requestOptions);
    return handleResponse(response);
}

const jobService = {
    getAll,
    getJobInfo,
    getAdvantages,
    save,
    deleteJob,
}

export default jobService