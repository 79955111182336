import React from 'react';

const SvgAjouter = (props: any) => (
    <svg viewBox="0 0 30.45 59.15" {...props}>
	<g style={{isolation:"isolate"}}>
		<g id="Calque_2" data-name="Calque 2">
			<g id="txt">
				<g opacity={0.2}>
					<text style={{fontSize: "50px", fontFamily: "ProductSans-Bold, Product Sans", fontWeight: 700}} transform="translate(2 44.9)">+</text>
				</g>
				<text fill={props.fill} style={{fontSize: "50px", fontFamily: "ProductSans-Bold, Product Sans", fontWeight: 700}} transform="translate(0 42.9)">+</text>
			</g>
		</g>
	</g>
</svg>
);

export default SvgAjouter;
