const deviceConstants = {
    GET_ALL_REQUEST:'GET_ALL_DEVICE_REQUEST',
    GET_ALL_SUCCESS:'GET_ALL_DEVICE_SUCCESS',
    GET_ALL_FAILURE:'GET_ALL_DEVICE_FAILURE',

    SAVE_ALL_REQUEST:'SAVE_ALL_DEVICE_REQUEST',
    SAVE_ALL_SUCCESS:'SAVE_ALL_DEVICE_SUCCESS',
    SAVE_ALL_FAILURE:'SAVE_ALL_DEVICE_FAILURE',

    CLEAR: 'DEVICE_CLEAR',
}

export default deviceConstants