import React from 'react';

const SvgSupport = (props: any) => (
	<svg viewBox="0 0 24 14.88" {...props}>
		<path fill={props.fill}
			  d="m18.05,5.67c-.18-3.06-2.72-5.49-5.82-5.49h-.15c-3.11,0-5.65,2.44-5.82,5.51-.96.35-1.65,1.27-1.65,2.35v1.77c0,1.38,1.12,2.5,2.5,2.5h.63c.53,0,.95-.43.95-.95v-4.85c0-.53-.43-.95-.95-.95h-.53c.24-2.48,2.33-4.42,4.87-4.42h.15c2.54,0,4.63,1.94,4.87,4.42h-.48c-.53,0-.95.43-.95.95v4.85c0,.53.43.95.95.95h.12c-.34.51-.89.86-1.51.95-.2-.44-.64-.74-1.16-.74h-1.22c-.7,0-1.27.57-1.27,1.27s.57,1.27,1.27,1.27h1.22c.56,0,1.04-.36,1.21-.87,1.15-.14,2.12-.89,2.55-1.96,1.1-.26,1.92-1.25,1.92-2.43v-1.77c0-1.1-.71-2.03-1.7-2.36Zm-10.29.82v4.85s0,.02-.02.02h-.63c-.86,0-1.56-.7-1.56-1.56v-1.77c0-.86.7-1.56,1.56-1.56h.63s.02,0,.02.02Zm6.31,7.63h-1.22c-.19,0-.34-.15-.34-.34s.15-.34.34-.34h1.22c.19,0,.34.15.34.34s-.15.34-.34.34Zm4.74-4.32c0,.86-.7,1.56-1.56,1.56h-.63s-.02,0-.02-.02v-4.85s0-.02.02-.02h.63c.86,0,1.56.7,1.56,1.56v1.77Z"/>
	</svg>

);

export default SvgSupport;
