import docConstants from "./doc.constants";
import docService from "./doc.service"

function getDoc(name, lang) {
    function request(){
        return { type: docConstants.GET_DOC_REQUEST };
    }
    function success(doc) {
        return { type: docConstants.GET_DOC_SUCCESS, doc}
    }
    function failure(error){
        return {type: docConstants.GET_DOC_FAILURE, error}
    }
    return (dispatch) => {
        dispatch(request());
        docService
            .getDoc(name, lang)
            .then((blob) => {
                let url = window.URL.createObjectURL(blob.content);
                let a = document.createElement('a');
                a.href = url;
                a.download = blob.filename;
                a.click();
                dispatch(success(blob.content));
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };
}

function clear() {
    return {
        type: docConstants.CLEAR,
    };
}

const docAction = {
    getDoc,
    clear,
}

export default docAction;