import React from 'react';

const SvgZenner = (props: any) => (
	<svg viewBox="0 0 204.04 30.32" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<polygon fill={props.fill} points="0 7.37 15.2 7.37 0 22.72 0 30.32 29.86 30.32 29.86 22.95 15.2 22.95 30.4 7.37 30.4 0 0 0 0 7.37"/>
			<polygon fill={props.fill} points="33.97 30.32 64.21 30.32 64.21 22.95 44.53 22.95 44.53 18.31 64.21 18.31 64.21 11.47 44.53 11.47 44.53 6.84 64.21 6.84 64.21 0 33.97 0 33.97 30.32"/>
			<polygon fill={props.fill} points="135.78 30.32 166.03 30.32 166.03 22.95 146.35 22.95 146.35 18.31 166.03 18.31 166.03 11.47 146.35 11.47 146.35 6.84 166.03 6.84 166.03 0 135.78 0 135.78 30.32"/>
			<polygon fill={props.fill} points="86.7 11.12 78.97 0 67.86 0 67.86 30.32 78.97 30.32 78.97 19.22 86.7 30.32 98.08 30.32 98.08 0 86.7 0 86.7 11.12"/>
			<polygon fill={props.fill} points="120.7 11.12 112.97 0 101.86 0 101.86 30.32 112.97 30.32 112.97 19.22 120.7 30.32 132.08 30.32 132.08 0 120.7 0 120.7 11.12"/>
			<path fill={props.fill} d="M195,21s5.53-1.88,5.53-9.87S193.46,0,191.22,0H170.17V30.32H180.7V22.56h.88l7.52,7.76H204Zm-9.17-5.87h-4.7V7.37h4.35c1.06,0,3.53.85,3.53,4.14A3.61,3.61,0,0,1,185.81,15.16Z"/>
		</g>
	</g>
</svg>
);

export default SvgZenner;
