
const images = (folder, lang) => {
    const listImg = require(`/home/dioptase/documentation/resources/${lang}/saphirVideo4/index.js`)
    if(listImg){
        return listImg.default
    }
    else{
        return "not found"
    }
}
export default images;