import React from 'react';

const SvgContact = (props: any) => (
    <svg viewBox="0 0 17.12 13.04" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M15.61,0H1.5A1.51,1.51,0,0,0,0,1.5v10A1.5,1.5,0,0,0,1.5,13H15.61a1.51,1.51,0,0,0,1.51-1.5V1.5A1.51,1.51,0,0,0,15.61,0Zm-.2,1L8.59,7.82,1.72,1ZM1,11.33V1.71L5.83,6.5Zm.71.71L6.55,7.2,8.24,8.88a.5.5,0,0,0,.71,0L10.6,7.23,15.41,12Zm14.41-.71L11.31,6.52l4.81-4.81Z"/>
		</g>
	</g>
</svg>
);

export default SvgContact;
