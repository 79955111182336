import React from 'react';

const SvgAqua = (props: any) => (
	<svg viewBox="0 0 100.04 38.94" {...props}>
		<path fill={"#1d1d1b"} d="M50.77,26.61c-.51-.15-1.09-.33-2.36-.33-1.7,0-2.9,.41-3.88,1.33-1.3,1.23-1.5,2.86-1.5,3.72,0,1.52,.54,2.76,1.57,3.57,.83,.68,1.9,1.01,3.25,1.01,.97,0,2.11-.19,2.48-.25l.15-.02,.22-1.09-.14,.04c-1.32,.34-1.84,.43-2.57,.43-2.16,0-3.07-1.17-3.36-1.67-.32-.56-.5-1.28-.5-2.03,0-1.39,.54-2.57,1.53-3.31,.74-.56,1.65-.84,2.72-.84,.65,0,1.12,.07,2.37,.37l.12,.03-.05-.94-.06-.02Z"/>
		<path fill={"#1d1d1b"} d="M56.86,31.79c0,.19-.01,.38-.03,.57-.03,.59-.11,1.02-.27,1.37-.39,.88-1.21,1.42-2.16,1.42s-1.8-.54-2.13-1.41c-.14-.38-.16-.76-.16-1.22,0-1.37,.38-1.98,.71-2.35,.46-.53,1.04-.78,1.83-.78,1.33,0,1.84,.78,2.03,1.24,.18,.45,.18,.98,.18,1.15m-2.25-3.15c-.53,0-.88,.08-1.08,.15-.65,.18-1.09,.55-1.23,.67h0c-.5,.45-1.16,1.25-1.18,3.01-.01,1.11,.26,1.69,.47,2.04,.23,.38,.49,.63,.63,.74,.73,.56,1.73,.64,2.26,.64,1.99,0,2.78-1.33,3.08-2.13,.2-.53,.31-1.15,.31-1.83,0-1.04-.27-1.82-.82-2.39-.58-.6-1.4-.9-2.43-.9"/>
		<path fill={"#1d1d1b"} d="M64.79,30c-.06-.13-.19-.43-.49-.71-.44-.44-1.1-.66-1.96-.66s-1.6,.27-2.17,.79c-.12,.11-.22,.23-.3,.33v-.97h-.97v.09c-.01,.93-.04,1.88-.08,2.81-.04,1.11-.13,2.23-.22,3.32l-.06,.76h1.06v-.09c.01-.77,.03-1.56,.08-2.34,.02-.52,.07-1.07,.14-1.73v-.04c.06-.26,.09-.46,.19-.7,.04-.11,.17-.36,.36-.6,.43-.55,1.06-.85,1.82-.86,.53,0,.88,.18,1,.24,.63,.35,.69,.81,.75,1.6-.01,.27-.02,.54-.04,.81-.02,.54-.05,1.09-.08,1.64-.04,.66-.09,1.31-.16,2v.1h1.06l.21-4.94v-.09c-.03-.25-.05-.48-.14-.73"/>
		<path fill={"#1d1d1b"} d="M68.78,32.28l-1.4-1.1c-.32-.27-.59-.5-.59-.92,0-.12,.02-.24,.05-.31,.06-.16,.16-.25,.2-.28,.29-.22,.87-.23,.99-.23,.43,0,.96,.11,1.58,.34l.17,.06-.15-.98h-.06c-.78-.16-1.13-.21-1.54-.21-.25,0-.99,0-1.63,.46-.39,.27-.6,.67-.61,1.15,0,.66,.37,1.09,.53,1.28v.02c.23,.24,.54,.49,.67,.6l1.35,1.06c.33,.3,.58,.57,.58,.96,0,.25-.07,.45-.23,.61-.22,.23-.61,.36-1.09,.36-.38,0-.89-.1-.89-.1-.44-.1-.64-.17-1.01-.3l-.31-.11,.12,.95,.06,.02c.97,.27,1.33,.3,1.93,.3,.56,0,1.21-.04,1.75-.46,.56-.43,.67-.93,.67-1.28,0-.59-.32-1.04-.45-1.2-.18-.23-.46-.46-.7-.67"/>
		<path fill={"#1d1d1b"} d="M76.13,28.86c0,.84-.02,1.57-.05,2.24-.02,.49-.07,1.02-.14,1.65-.06,.34-.1,.51-.15,.65-.15,.41-.77,1.74-2.25,1.74-.36,0-1.23-.09-1.54-.92-.11-.29-.14-.68-.14-.75,.04-2.41,.07-3.25,.26-4.59v-.1h-1.11v.09c0,.27-.01,.54-.02,.81,0,.27,0,.54-.01,.8-.02,.55-.06,1.09-.1,1.66v.11c-.02,.3-.03,.6-.04,.9,0,.15,0,.3,0,.47,.06,.61,.12,.87,.27,1.15,.5,1.01,1.72,1.12,2.23,1.12,1.27,0,2.01-.61,2.28-.87,.07-.07,.15-.16,.24-.26v.99h.99v-.09c.01-.3,.02-.61,.03-.91,.03-.88,.05-1.8,.1-2.69,.06-1.01,.14-1.99,.25-3.17v-.1l-1.09-.02v.09Z"/>
		<path fill={"#1d1d1b"} d="M78.48,26.54c.01,.38,.02,.74,.02,1.1,0,.77-.05,1.56-.09,2.32l-.03,.48c-.01,.25-.02,.51-.04,.76-.04,.77-.07,1.56-.13,2.35-.05,.67-.12,1.34-.21,2.08v.1h1.1v-.09c.04-1.56,.08-3.18,.16-4.75,.07-1.39,.18-2.82,.34-4.37v-.1l-1.13-.02v.13Z"/>
		<path fill={"#1d1d1b"} d="M81.67,27.42l-.95,.19v1.16h-.9v.77h.84l-.23,4.71c0,.38,.02,.55,.1,.75,.09,.25,.24,.41,.34,.49,.42,.38,.95,.39,1.19,.39h.03c.41,0,.88-.07,1.08-.09l.13-.02,.14-.83-.25,.06c-.26,.07-.49,.12-.76,.12-.32,0-.57-.09-.74-.26-.3-.3-.29-.78-.29-1.01l.2-4.34h1.98v-.77h-1.95l.04-1.34Z"/>
		<polygon fill={"#1d1d1b"} points="84.49 27.5 85.54 27.5 85.6 26.43 84.49 26.43 84.49 27.5"/>
		<g>
			<path fill={"#1d1d1b"} d="M84.41,28.86c-.03,.82-.05,1.67-.09,2.49,0,.23-.01,.47-.03,.7-.03,.77-.05,1.56-.12,2.35-.02,.37-.07,.75-.14,1.23l-.02,.1h1.1v-.09c0-.27,.02-.54,.02-.81,.02-.78,.03-1.58,.07-2.37,.05-1.18,.14-2.36,.23-3.51v-.18l-1.03-.02v.09Z"/>
			<path fill={"#1d1d1b"} d="M92.46,30c-.06-.13-.19-.43-.49-.71-.44-.44-1.1-.66-1.96-.66s-1.6,.27-2.17,.79c-.12,.11-.22,.23-.3,.33v-.97h-.97v.09c-.01,.93-.04,1.88-.08,2.81-.04,1.11-.13,2.23-.22,3.32l-.06,.76h1.06v-.09c.01-.77,.03-1.56,.08-2.34,.02-.52,.07-1.07,.14-1.73v-.04c.06-.26,.09-.46,.19-.7,.04-.11,.17-.36,.36-.6,.43-.55,1.06-.85,1.82-.86,.53,0,.88,.18,1,.24,.63,.35,.69,.81,.75,1.6-.01,.27-.02,.54-.04,.81-.02,.54-.05,1.09-.08,1.64-.04,.66-.09,1.31-.16,2v.1h1.06l.21-4.94v-.09c-.03-.25-.05-.48-.14-.73"/>
			<path fill={"#1d1d1b"} d="M96.97,35.09c-.25,.06-.45,.06-.55,.06-.4,0-.79-.11-1.13-.31-.21-.13-.41-.31-.54-.52-.23-.35-.34-.85-.34-1.47v-.02c0-.89,.16-1.42,.23-1.61,.13-.36,.31-.63,.44-.79,.13-.16,.15-.18,.3-.32,.7-.63,1.63-.72,2.13-.72s1,.08,1.46,.17c0,2.02-.05,2.51-.18,3.65,0,.01,0,.04-.02,.06-.04,.17-.14,.56-.31,.84-.29,.45-.86,.83-1.49,.98m2.99-6.17c-1.15-.23-1.39-.24-2.59-.25-.95,.09-1.35,.17-1.78,.36-.33,.14-.82,.38-1.26,.91-.85,1-.95,2.37-.95,2.91,0,.34,0,1.25,.64,2.06,.24,.31,.49,.48,.58,.54,.61,.39,1.4,.44,1.8,.44,1.17,0,1.81-.48,2.08-.77,.12-.12,.22-.24,.3-.34l-.07,1.47c-.09,.48-.14,.67-.24,.87-.15,.28-.33,.44-.43,.53-.39,.34-.92,.5-1.62,.5-.17,0-.34-.01-.51-.02-.08,0-.16-.01-.24-.01-.77-.13-.98-.18-1.73-.41l-.12-.04,.05,.98,.07,.02c.8,.17,1.39,.27,2.14,.27,.35,0,.69-.02,1.04-.05h.09c.31-.05,1.26-.18,1.84-.92,.39-.48,.48-.98,.58-1.52,.04-.35,.06-.72,.09-1.07v-.08c.03-.42,.03-.84,.04-1.25,0-.41,.01-.83,.03-1.25,.04-1.36,.14-2.68,.23-3.78v-.08l-.07-.02Z"/>
			<path fill={"#1d1d1b"} d="M11.37,11.38h-3.48l1.72-4.85,1.76,4.85ZM12.11,.34h0c-.08-.21-.28-.34-.5-.34h-3.92c-.22,0-.42,.14-.5,.35L.03,19.17c-.06,.17-.04,.35,.06,.5,.1,.15,.27,.23,.44,.23h3.93c.23,0,.43-.14,.51-.36l1.39-3.92h6.59l1.47,3.93c.08,.21,.28,.35,.5,.35h4.03c.18,0,.34-.09,.44-.24,.1-.15,.12-.33,.06-.5L12.11,.34Z"/>
			<path fill={"#1d1d1b"} d="M72.56,11.38h-3.48l1.72-4.85,1.76,4.85Zm8.1,7.79h0L73.3,.34c-.08-.21-.28-.34-.5-.34h-3.92c-.22,0-.42,.14-.5,.35l-7.15,18.82c-.06,.17-.04,.35,.06,.5,.1,.15,.27,.23,.44,.23h3.93c.23,0,.43-.14,.51-.36l1.39-3.92h6.59l1.47,3.93c.08,.21,.28,.35,.5,.35h4.03c.18,0,.34-.09,.44-.24,.1-.15,.12-.33,.06-.5"/>
			<path fill={"#1d1d1b"} d="M58.76,0h-4.57c-.15,0-.27,.12-.27,.27V14.79h-6.91V.27C47.02,.12,46.9,0,46.75,0h-4.57c-.15,0-.27,.12-.27,.27V19.63c0,.15,.12,.27,.27,.27h16.58c.15,0,.27-.12,.27-.27V.27C59.03,.12,58.91,0,58.76,0"/>
			<path fill={"#1d1d1b"} d="M29.82,3.38c3.4,0,6.16,2.76,6.16,6.16s-2.76,6.16-6.16,6.16-6.16-2.76-6.16-6.16,2.76-6.16,6.16-6.16m.52,15.67c5.02-.27,9.01-4.42,9.01-9.51S35.08,.01,29.82,.01s-9.52,4.26-9.52,9.52c0,3.44,1.82,6.45,4.56,8.13-3.01-.06-4.5,1.25-4.5,1.25,0,0,2.12-1.52,9.56,2.23,7.44,3.75,10.1-3.48,10.1-3.48,0,0-3.42,3.94-9.68,1.39"/>
		</g>
		<rect fill={"#1d1d1b"} x="19.24" y="23.71" width="73.09" height="1.04"/>
	</svg>
);

export default SvgAqua;
