import React, { useEffect, useState } from "react";
import LogoDld from "../../_resources/image/dld_logo.png";
import LogoMbox from "../../_resources/image/mbox_logo.png";
import FooterSection from "./FooterSection";
import SvgProduits from "../../_svg/Produit";
import DldMockup from "../../_resources/image/dld_mockup.png";
import MboxMockup from "../../_resources/image/mbox_mockup.png";

import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ReactGA from 'react-ga4';

const listLogiciels = [
  {
    title: "MBox",
    svg: null,
    text: "Solution pour toute relève de compteur compatible WMBus",
  },
];

const Produits = (props: any) => {
  const { t } = props;
  const [hover, setHover] = useState("");

  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  })

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
  });
  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <>
      <div
        className={"logiciels-container produits responsiv"}
        style={{
          justifyContent: isTablet ? "unset" : "space-evenly",
          height: isTablet ? "auto" : 'calc(100vh - 285px)',
        }}
      >
        {listLogiciels.map((el: any, index: number) => (
          <div
            style={{
              display: isTablet ? "block" : "flex",
                height: isTablet ? "100%" : "50%",
              alignItems: "center",
            }}
          >
            {!isTablet && el.title === "DLD" && (
              <div
                className="video-container"
                style={{
                  position: "relative",
                  height: "100%",
                  left: 0,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={DldMockup}
                  style={{ marginTop: 0, width: "75%", height: "auto" }}
                  alt="dld"
                />
              </div>
            )}
            <div className="center-container">
              <div className={"section-container"}>
                {el.title === "DLD" ? (
                  <img src={LogoDld} width="30%" height="auto" alt="dld"/>
                ) : (
                  <img src={LogoMbox} width="30%" height="auto" alt="mbox"/>
                )}
                <div
                  className="text-container"
                  style={{ marginLeft: isTablet ? "0px" : "15px" }}
                >
                  <h5
                    style={{
                      color: el.title === "DLD" ? "#D8A202" : "#1F8491",
                    }}
                  >
                    {el.text}
                  </h5>
                  <div className={"button-download"}>
                    <a href={`/produits/${el.title.toLowerCase()}`}>
                      <button
                        style={{
                          borderColor:
                            el.title === hover
                              ? "white"
                              : el.title === "DLD"
                              ? "#D8A202"
                              : "#1F8491",
                          backgroundColor:
                            el.title === hover
                              ? el.title === "DLD"
                                ? "#D8A202"
                                : "#1F8491"
                              : "white",
                        }}
                        onMouseEnter={() => setHover(el.title)}
                        onMouseLeave={() => setHover("")}
                      >
                        <span>En savoir plus</span>
                        <SvgProduits
                          fill={
                            el.title === hover
                              ? "white"
                              : el.title === "DLD"
                              ? "#D8A202"
                              : "#1F8491"
                          }
                          height={"30px"}
                        />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {el.title === "MBox" && (
              <div
                className="video-container"
                style={{
                  position: "relative",
                  height: "100%",
                  left: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5%",
                }}
              >
                <img
                  src={MboxMockup}
                  height="auto"
                  className="img-mbox"
                  style={{ marginTop: 0, width: "80%", height: "auto" }}
                  alt="mbox"
                />
              </div>
            )}
            {isTablet && el.title === "DLD" && (
              <div
                className="video-container"
                style={{
                  position: "relative",
                  height: "100%",
                  left: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                  marginTop: "5%",
                }}
              >
                <img
                  src={DldMockup}
                  style={{ marginTop: 0, width: "75%", height: "auto" }}
                  alt="dld"
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <FooterSection t={t} />
    </>
  );
};

const tr = withTranslation()(Produits);
export default tr;
