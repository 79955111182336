import React, { Component } from "react";
import deviceActions from "../../redux/device/device.action";
import { connect } from "react-redux";
import _ from "lodash";
import { Button } from "reactstrap";

import Comparateur from "./Comparateur";
import Footer from "../section/FooterSection";

import SmartTab from "../../_svg/Tablette_Smartphone";
import TabletDessin from "../../_svg/TabletDessin";
import PhoneDessin from "../../_svg/PhoneDessin";
import Tablet from "../../_svg/Tablet";
import Smartphone from "../../_svg/Smartphone";

import Android from "../../_svg/Android";
import Onyx from "../../_svg/Onyx";
import Saphir from "../../_svg/Saphir";
import Logiciels from "../../_svg/Logiciels";
import device from "../../redux/device/device.reducer";

import { useMediaQuery } from "react-responsive";
import { withTranslation } from "react-i18next";

import ReactGA from "react-ga4";
import MetaTags from 'react-meta-tags';

function ResponsiveLegend(props) {
  const { code, callback } = props;

  const list = [
    {
      title: "Tablette & Smartphone",
      svg: <SmartTab height="3em" fill={code === "" ? "#b77830" : "black"} />,
      code: "",
    },
    {
      title: "Tablette",
      svg: (
        <TabletDessin height="3em" fill={code === "t" ? "#b77830" : "black"} />
      ),
      code: "t",
    },
    {
      title: "Smartphone",
      svg: (
        <PhoneDessin height="2em" fill={code === "s" ? "#b77830" : "black"} />
      ),
      code: "s",
    },
  ];
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <>
      {list.map((el) => (
        <div className="section" onClick={() => callback(el.code)}>
          {el.svg}
          {!isMobile && <h5>{el.title}</h5>}
        </div>
      ))}
    </>
  );
}

function DisplayImage(props) {
  const { index, el } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div
      className="section deux"
      style={{
        justifyContent: index % 2 === 0 ? "left" : "center",
        display: isMobile ? "none" : "flex",
      }}
    >
      <img
        src={require(`../../_resources/image/telephone/${el.code}.png`)}
        height="130%"
        style={{ padding: el.code === "samsung" ? "30px" : 0 }}
        alt="telephone"
      />
    </div>
  );
}

function DisplaySysteme() {
  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });
  return (
    <div
      className="systeme-container"
      style={{ display: isTablet ? "none" : "block" }}
    >
      <Android height="3em" fill="lightgrey" />
      <Saphir height="3em" fill="#CF7830" style={{ marginLeft: "20px" }} />
      <Onyx height="3em" fill="#b77830" style={{ marginLeft: "20px" }} />
    </div>
  );
}

class Materiels extends Component {
  constructor(Props) {
    super(Props);
    this.state = {
      deviceList: [],
      code: "",
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(deviceActions.getAll());
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  }

  static getDerivedStateFromProps(props, state) {
    const { device } = props;
    const copyState = _.cloneDeep(state);
    if (device && device.devices && device.devices.length > 0) {
      copyState.deviceList = device.devices;
    }
    return copyState;
  }

  updateState = (c) => {
    this.setState({
      code: c,
    });
  };

  deviceFilter = () => {
    const { code, deviceList } = this.state;
    switch (code) {
      case "t":
        return deviceList.filter((el) => !el.smart);
      case "s":
        return deviceList.filter((el) => el.smart);
      default:
        return deviceList;
    }
  };

  render() {
    const { code } = this.state;
    const { t } = this.props;
    const deviceList = this.deviceFilter();
    const content = {
      button: t("all.button.more"),
    };
    /**
     * <Helmet>
        <meta
      name="description"
      content="Dioptase: solution global de relève de compteurs à distance, logiciels de gestion de tournées & de parc compteurs. Solution de gestion de releves de compteurs."
    />
    <meta name="keywords" content="dioptase, crystalcloud, saphir, compteur d'eau, mbox, dld, telephone, mbox, dld, solution, releveur, releve, eau, innovation, gateway, IOT, radio, multi-protocole, synchronisation, sigfox, lora, mbus, interface, facturation, portail, tourmaline, application, relève walk-by, relève drive by, télé relève, WMBUS, DIEHL, ITRON, KAMSTRUP, ZENNER, WAVENIS, SENSUS, facturation, eau, eau froide, eau chaude" />
    <meta property="og:title" content="Matériels"/>
    <meta property="og:type" content="Matériels - nos téléphones"/>
    <meta property="og:url" content="https://www.dioptase.fr/materiels"/>
    <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
    <meta property="og:description" content="Liste de nos téléphones avec comparaison"/>
        </Helmet>
     */
    return (
      <div className="container-mat responsiv">
        <MetaTags>
          <title>Dioptase - Matériels</title>
          <meta
            name="description"
            content="Dioptase: solution global de relève de compteurs à distance, logiciels de gestion de tournées & de parc compteurs. Solution de gestion de releves de compteurs."
          />
          <meta name="keywords" content="dioptase, crystalcloud, saphir, compteur d'eau, mbox, dld, telephone, mbox, dld, solution, releveur, releve, eau, innovation, gateway, IOT, radio, multi-protocole, synchronisation, sigfox, lora, mbus, interface, facturation, portail, tourmaline, application, relève walk-by, relève drive by, télé relève, WMBUS, DIEHL, ITRON, KAMSTRUP, ZENNER, WAVENIS, SENSUS, facturation, eau, eau froide, eau chaude" />
          <meta property="og:title" content="Matériels" />
          <meta property="og:type" content="Matériels - nos téléphones" />
          <meta property="og:url" content="https://www.dioptase.fr/materiels" />
          <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
          <meta property="og:description" content="Liste de nos téléphones avec comparaison" />
        </MetaTags>
        <div className="legend-bar">
          <ResponsiveLegend code={code} callback={this.updateState} />
        </div>
        <div className="materiels-container">
          {deviceList.map((el, index) => (
            <div
              className="device-section"
              style={{
                zIndex: index,
                borderBottom:
                  index !== deviceList.length - 1 ? "1px solid #333" : "none",
              }}
            >
              {index % 2 === 0 && (
                <div className="section un">
                  <h2>{el.name}</h2>
                  <div className="svg-box">
                    <Tablet
                      height="5em"
                      fill={el.smart ? "lightgrey" : "black"}
                    />
                    <Smartphone
                      height="4em"
                      fill={el.smart ? "black" : "lightgrey"}
                      style={{ marginLeft: "20px" }}
                    />
                  </div>
                  <a href={`/materiels/${el.code}`}>
                    <Button>
                      {content.button}
                      <Logiciels height="1.5em" fill="#CF7830" />
                    </Button>
                  </a>
                </div>
              )}
              <DisplayImage index={index} el={el} />
              {index % 2 === 1 && (
                <div className="section un">
                  <h2>{el.name}</h2>
                  <div className="svg-box">
                    <Tablet
                      height="5em"
                      fill={el.smart ? "lightgrey" : "black"}
                    />
                    <Smartphone
                      height="4em"
                      fill={el.smart ? "black" : "lightgrey"}
                      style={{ marginLeft: "20px" }}
                    />
                  </div>
                  <a href={`/materiels/${el.code}`}>
                    <Button>
                      {content.button}
                      <Logiciels height="1.5em" fill="#CF7830" />
                    </Button>
                  </a>
                </div>
              )}
              {el.systeme === "android" && <DisplaySysteme />}
            </div>
          ))}
        </div>
        <Comparateur t={t} />
        <Footer t={t} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { device } = state;

  return {
    device,
  };
}

const connectedMateriels = connect(mapStateToProps)(Materiels);
const tr = withTranslation()(connectedMateriels);
export default tr;
