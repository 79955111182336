import React from "react";

const ImageSelector = (props) => {
    const { src, className, children, imgStyle, home } = props
    const img = new Image();
    img.onload = function (){
        img.src = src
        const image = document.getElementsByClassName('pre-load-img')[0]
        image.src = src
    } 
    

    return(
        <div className={`image-selector ${className} `} style={{position: "relative"}}>
                        {children}
            <img src={src} width="100%" height="auto" style={imgStyle} className="pre-load-img" alt="dioptase"/>
        </div>
    )
}

export default ImageSelector;