import React from 'react';

const SvgAgedi = (props: any) => (
<svg viewBox="0 0 112.46 46.23" {...props}>
	<g>
		<path fill={"#1d1d1b"} d="M12.93,15.03l-.4-4.06c-.09-1.03-.19-2.54-.29-3.69h-.06c-.54,1.15-1.11,2.61-1.66,3.69l-2.04,4.06h4.46Zm-5.77,3.06l-2.43,4.89H0L10.09,4.17h5.68l2.41,18.82h-4.64l-.42-4.89H7.15Z"/>
		<path fill={"#1d1d1b"} d="M36.64,22.12c-1.59,.48-4.09,1.05-6.71,1.05-2.83,0-5.19-.68-6.9-2.06-1.66-1.33-2.6-3.41-2.6-5.8,0-3.52,1.58-6.56,4.23-8.6,2.34-1.77,5.54-2.74,9.13-2.74,2.69,0,4.68,.48,5.48,.84l-1.32,3.39c-.93-.39-2.55-.77-4.39-.77-2.05,0-3.88,.53-5.26,1.5-1.8,1.3-3,3.52-3,6.09,0,3.16,2.06,4.74,5.32,4.74,1.03,0,1.74-.14,2.26-.33l.83-3.96h-3.22l.7-3.21h7.52l-2.06,9.85Z"/>
	</g>
	<polygon fill={"#1d1d1b"} points="53.64 15.01 46.3 15.01 45.34 19.56 53.6 19.56 52.89 22.99 40.2 22.99 44.12 4.17 56.37 4.17 55.63 7.59 47.84 7.59 46.99 11.62 54.37 11.62 53.64 15.01"/>
	<path fill={"#1d1d1b"} d="M61.2,19.75c.52,.07,1.16,.1,1.94,.1,2.4,0,4.52-.77,5.87-2.18,1.4-1.45,2.14-3.41,2.14-5.67,0-2.98-1.81-4.74-5.46-4.74-.77,0-1.42,.05-1.88,.13l-2.61,12.36Zm-1.25-15.3c1.78-.29,3.72-.41,5.72-.41,3.23,0,5.8,.66,7.55,2.03,1.68,1.26,2.73,3.11,2.73,5.88,0,3.65-1.68,6.79-4.24,8.65-2.42,1.75-5.44,2.51-9.93,2.51-2.48,0-4.63-.18-5.67-.36l3.84-18.3Z"/>
	<polygon fill={"#1d1d1b"} points="85.23 4.17 81.31 22.99 76.89 22.99 80.81 4.17 85.23 4.17"/>
	<polygon fill={"#1d1d1b"} points="86.13 0 85.48 2.38 81.06 2.38 81.71 0 86.13 0"/>
	<path fill={"#1d1d1b"} d="M75.95,35.88l5.1,2.62s24.15-4.16,31.4-11.42c0,0-9.28,4.4-36.51,8.8"/>
	<path fill={"#1d1d1b"} d="M69.05,43.61l5.1,2.62s24.15-4.16,31.4-11.42c0,0-9.28,4.4-36.51,8.8"/>
</svg>
);

export default SvgAgedi;
