import React from 'react';

const SvgAdeunis = (props: any) => (
	<svg viewBox="0 0 137.85 31.49" {...props}>
	<defs>
		<clipPath id="clippath">
			<path fill="none" d="M1.56,6.22l-.55,.32c-.37,.22-.64,.55-.76,.92v1.1c.04,.1,.08,.2,.14,.3,.47,.81,1.52,1.09,2.34,.62l5.69-3.26H1.56Zm15.78,0l4.84,2.77v10.63l-9.3,5.36-7.59-4.37,8.44-4.81c.82-.47,1.1-1.51,.63-2.32s-1.52-1.09-2.34-.63L1.01,19.14c-.37,.21-.64,.54-.77,.92v1.1c.13,.38,.4,.71,.76,.92l5.31,3.06h13.13l5.31-3.06c.53-.3,.85-.86,.85-1.47V8.02c0-.61-.33-1.17-.86-1.47l-.55-.32h-6.85Z"/>
		</clipPath>
	</defs>
	<path d="M12.02,.23L1,6.54c-.82,.47-1.1,1.51-.63,2.32,.47,.81,1.52,1.09,2.34,.62L12.87,3.66l9.3,5.33v10.64l-9.3,5.36-7.59-4.37,8.44-4.81c.82-.47,1.1-1.51,.63-2.32s-1.52-1.1-2.34-.63L1.01,19.14c-.53,.3-.86,.86-.86,1.47,0,.61,.32,1.17,.85,1.48l11.01,6.35c.27,.15,.56,.23,.86,.23s.59-.08,.86-.23l11.02-6.35c.53-.3,.85-.87,.85-1.47V8.01c0-.61-.33-1.17-.86-1.47L13.73,.23c-.26-.15-.56-.23-.86-.23s-.59,.08-.86,.23"/>
	<g clipPath='url(#clippath)'>
		<rect y="6.28" width="15.45" height="17.23"/>
	</g>
	<path d="M3.57,8.02c0,.94-.77,1.7-1.71,1.7s-1.71-.76-1.71-1.7,.77-1.7,1.71-1.7,1.71,.76,1.71,1.7"/>
	<path d="M14.59,14.33c0,.94-.77,1.7-1.71,1.7s-1.71-.76-1.71-1.7,.77-1.7,1.71-1.7,1.71,.76,1.71,1.7"/>
	<polygon points="36.46 30.55 36.37 30.94 35.58 27.92 35.06 27.92 34.36 30.55 34.27 30.92 34.19 30.55 33.52 27.95 33.51 27.92 33 27.92 33.95 31.42 33.96 31.45 34.56 31.45 35.25 28.95 35.31 28.61 35.38 28.96 36.04 31.42 36.05 31.45 36.69 31.45 37.6 27.92 37.1 27.92 36.46 30.55"/>
	<rect x="39.05" y="27.92" width=".47" height="3.53"/>
	<path d="M41.93,28.3h.16c.06-.01,.12-.02,.19-.02,.06,0,.12,0,.19,0,.12,0,.25,0,.43,0,.08,0,.16,.03,.23,.08,.12,.08,.17,.26,.17,.53,0,.33-.04,.56-.12,.69-.09,.14-.27,.21-.54,.21-.32,0-.55,0-.71,0v-1.49Zm1.22,2s-.07-.1-.11-.15c.25-.06,.44-.19,.55-.38,.12-.2,.18-.52,.18-.93,0-.33-.07-.57-.19-.71-.16-.17-.42-.26-.8-.26h-.05c-.33,0-.75,.02-1.24,.06h-.04v3.52h.47v-1.27h.29c.1,.02,.2,.02,.31,.02h0c.07,.07,.17,.21,.3,.41l.52,.81v.02h.57l-.64-.96-.13-.19Z"/>
	<polygon points="45.92 29.86 47.2 29.86 47.2 29.44 45.92 29.44 45.92 28.34 47.5 28.34 47.5 27.92 45.45 27.92 45.45 31.45 47.56 31.45 47.56 31.03 45.92 31.03 45.92 29.86"/>
	<polygon points="49.53 27.92 49.06 27.92 49.06 31.45 50.9 31.45 50.9 31.03 49.53 31.03 49.53 27.92"/>
	<polygon points="52.75 29.86 54.03 29.86 54.03 29.44 52.75 29.44 52.75 28.34 54.33 28.34 54.33 27.92 52.28 27.92 52.28 31.45 54.4 31.45 54.4 31.03 52.75 31.03 52.75 29.86"/>
	<path d="M57.31,29.52c-.12-.03-.3-.06-.53-.1-.23-.04-.39-.08-.48-.13-.12-.07-.18-.22-.18-.45,0-.2,.04-.34,.12-.42,.1-.09,.28-.14,.54-.14h.02c.24,0,.51,.06,.81,.19l.04,.02,.15-.43-.04-.02c-.25-.11-.58-.17-.97-.17h-.03c-.17,0-.32,.02-.46,.05-.43,.1-.65,.42-.65,.93v.02c0,.25,.05,.44,.15,.58,.05,.07,.11,.13,.18,.17,.07,.05,.15,.09,.25,.12,.1,.04,.29,.08,.55,.12,.35,.05,.49,.12,.54,.16,.04,.04,.07,.08,.08,.13,.01,.06,.02,.14,.02,.24v.09c0,.1-.01,.19-.03,.26-.06,.22-.26,.33-.63,.33-.32,0-.65-.09-.99-.26l-.04-.02-.19,.39,.03,.02c.16,.1,.37,.17,.61,.22,.1,.02,.2,.04,.3,.05,.1,0,.21,.01,.33,.01s.26-.02,.39-.05c.46-.11,.69-.45,.69-1.01v-.03c0-.36-.09-.62-.27-.75-.09-.06-.19-.11-.31-.14"/>
	<path d="M60.91,29.52c-.12-.03-.3-.06-.53-.1-.23-.04-.39-.08-.48-.13-.12-.07-.18-.22-.18-.45,0-.2,.04-.34,.12-.42,.1-.09,.28-.14,.54-.14h.02c.24,0,.51,.06,.8,.19l.04,.02,.15-.43-.04-.02c-.25-.11-.58-.17-.97-.17h-.03c-.17,0-.32,.02-.46,.05-.43,.1-.65,.42-.65,.93v.02c0,.25,.05,.44,.15,.58,.05,.07,.11,.13,.18,.17,.07,.05,.15,.09,.25,.12,.1,.04,.29,.08,.55,.12,.35,.05,.49,.12,.54,.16,.04,.04,.07,.08,.08,.13,.01,.06,.02,.14,.02,.24v.09c0,.1-.01,.19-.03,.26-.06,.22-.26,.33-.63,.33-.32,0-.65-.09-.99-.26l-.04-.02-.19,.39,.03,.02c.16,.1,.37,.17,.61,.22,.1,.02,.2,.04,.3,.05,.1,0,.21,.01,.33,.01s.26-.02,.39-.05c.46-.11,.69-.45,.69-1.01v-.03c0-.36-.09-.62-.27-.75-.09-.06-.19-.11-.31-.14"/>
	<path d="M67.03,28.84v.28c0,.34-.04,.56-.12,.67-.04,.06-.11,.11-.19,.13-.09,.03-.23,.04-.43,.04s-.42-.02-.66-.05v-1.61c.21-.02,.51-.02,.9-.02,.18,0,.31,.04,.39,.12,.08,.08,.12,.23,.12,.43m-.52-.96c-.43,0-.88,.02-1.32,.06h-.04v3.51h.47v-1.13c.25,.04,.45,.05,.6,.05s.26,0,.33,0c.46-.03,.75-.24,.87-.61,.05-.18,.08-.39,.08-.64v-.26c0-.33-.06-.57-.2-.71-.15-.17-.42-.26-.8-.26"/>
	<path d="M69.62,28.3h.16c.06-.01,.12-.02,.19-.02,.06,0,.12,0,.19,0,.12,0,.25,0,.43,0,.08,0,.16,.03,.23,.08,.12,.08,.17,.26,.17,.53,0,.33-.04,.56-.12,.69-.09,.14-.27,.21-.54,.21-.32,0-.55,0-.7,0v-1.49Zm1.22,2s-.07-.1-.11-.15c.25-.06,.44-.19,.55-.38,.12-.2,.18-.52,.18-.93,0-.33-.07-.57-.19-.71-.15-.17-.42-.26-.8-.26h-.05c-.33,0-.75,.02-1.24,.06h-.04v3.52h.47v-1.27h.28c.1,.02,.2,.02,.31,.02h0c.07,.07,.17,.21,.3,.41l.52,.81v.02h.57l-.64-.96-.13-.19Z"/>
	<path d="M74.3,28.29h.19c.35,0,.57,.12,.67,.35,.05,.12,.08,.26,.09,.43,.01,.17,.02,.38,.02,.6v.24c0,.46-.06,.78-.17,.93-.06,.08-.14,.14-.24,.17-.11,.03-.24,.05-.4,.05h-.14c-.29,0-.5-.08-.62-.25-.13-.17-.19-.48-.19-.91v-.24c0-.17,0-.35,0-.54,0-.2,.04-.37,.1-.52,.1-.22,.31-.33,.67-.33m1.28,.05c-.16-.31-.54-.47-1.11-.47h-.14c-.37,0-.65,.07-.85,.21-.1,.07-.18,.16-.24,.26-.06,.1-.1,.22-.14,.36-.03,.13-.05,.28-.06,.44-.01,.16-.02,.33-.02,.52s0,.35,.01,.5c.02,.33,.08,.6,.18,.8,.18,.35,.55,.53,1.1,.53h.14c.37,0,.66-.08,.86-.24,.1-.08,.18-.17,.23-.28,.06-.11,.1-.23,.13-.36,.03-.13,.05-.28,.06-.43,0-.16,.01-.33,.01-.51s0-.36,0-.52c-.01-.34-.07-.61-.17-.8"/>
	<path d="M79.28,30.91c-.13,.09-.33,.13-.6,.13h-.68v-2.71c.64-.05,1.05-.06,1.24,.03,.1,.04,.17,.1,.22,.18,.08,.13,.13,.38,.13,.75v.35c0,.39-.02,.68-.06,.85-.04,.2-.13,.35-.25,.43m.51-2.76c-.07-.07-.15-.13-.25-.17-.09-.04-.21-.07-.33-.08-.12-.02-.28-.02-.49-.02s-.48,.01-.82,.04l-.38,.03v3.5h1.09c.4,0,.72-.06,.93-.19,.21-.12,.35-.32,.43-.6,.07-.23,.1-.58,.1-1.03v-.35c0-.38-.04-.67-.12-.87-.04-.11-.1-.2-.17-.27"/>
	<path d="M83.98,29.8c0,.26-.01,.48-.03,.65-.02,.16-.06,.29-.12,.39-.06,.09-.13,.15-.24,.18-.11,.04-.27,.05-.48,.05s-.38-.04-.5-.11c-.06-.04-.11-.09-.15-.15-.04-.06-.07-.16-.1-.29-.03-.13-.05-.38-.05-.73v-1.88h-.47v1.9c0,.16,0,.3,0,.43,.01,.3,.06,.55,.16,.74,.17,.34,.56,.51,1.14,.51,.39,0,.69-.08,.89-.24,.1-.08,.17-.17,.23-.28,.05-.1,.09-.23,.12-.38,.03-.15,.04-.41,.04-.81v-1.87h-.47v1.88Z"/>
	<path d="M86.81,28.59c.06-.11,.14-.18,.24-.23,.11-.05,.26-.07,.46-.07s.45,.05,.74,.15h.04l.12-.42-.04-.02c-.05-.02-.15-.04-.3-.08-.15-.03-.35-.05-.59-.05-.38,0-.68,.08-.88,.24-.1,.08-.18,.17-.24,.28-.06,.11-.1,.24-.13,.37-.03,.14-.05,.29-.06,.45-.01,.16-.02,.34-.02,.52s0,.35,.02,.5c.02,.32,.1,.58,.21,.77,.19,.32,.57,.48,1.12,.48h.03c.13,0,.27-.01,.43-.04,.17-.02,.31-.06,.43-.12l.03-.02-.12-.39-.04,.02c-.24,.09-.49,.14-.73,.14h-.02c-.33,0-.57-.1-.69-.28-.11-.17-.17-.49-.17-.94v-.17c0-.55,.05-.92,.16-1.11"/>
	<polygon points="89.38 28.34 90.3 28.34 90.3 31.45 90.77 31.45 90.77 28.34 91.73 28.34 91.73 27.92 89.38 27.92 89.38 28.34"/>
	<path d="M94.52,29.52c-.12-.03-.3-.06-.53-.1-.23-.04-.39-.08-.48-.13-.12-.07-.18-.22-.18-.45,0-.2,.04-.34,.12-.42,.1-.09,.28-.14,.54-.14h.02c.24,0,.51,.06,.81,.19l.04,.02,.15-.43-.04-.02c-.25-.11-.58-.17-.97-.17h-.03c-.17,0-.32,.02-.46,.05-.43,.1-.65,.42-.65,.93v.02c0,.25,.05,.44,.15,.58,.05,.07,.11,.13,.18,.17,.07,.05,.15,.09,.25,.12,.1,.04,.29,.08,.55,.12,.35,.05,.49,.12,.54,.16,.04,.04,.07,.08,.08,.13,.01,.06,.02,.14,.02,.24v.09c0,.1-.01,.19-.03,.26-.06,.22-.26,.33-.63,.33-.32,0-.65-.09-.99-.26l-.04-.02-.19,.39,.03,.02c.16,.1,.37,.17,.61,.22,.1,.02,.2,.04,.3,.05,.1,0,.21,.01,.33,.01s.26-.02,.39-.05c.46-.11,.69-.45,.69-1.01v-.03c0-.36-.09-.62-.27-.75-.09-.06-.19-.11-.31-.14"/>
	<path d="M99.29,30.07c.04-.08,.09-.15,.17-.2,.07-.05,.22-.08,.42-.08h.69v1.02c-.24,.19-.52,.29-.84,.29-.2,0-.34-.05-.41-.15-.04-.05-.06-.12-.08-.21-.01-.09-.02-.21-.02-.35s.02-.25,.06-.33m2.34,.92c-.16,.08-.31,.11-.45,.11-.04,0-.07,0-.09-.02-.02,0-.03-.03-.05-.06,0-.02-.02-.09-.02-.31v-.92h.66v-.04s.02-.1,.02-.15,0-.11-.01-.15v-.04h-.67v-.59h-.34l-.1,.59h-.69c-.08,0-.15-.01-.22-.03-.22-.06-.33-.24-.33-.52,0-.25,.04-.42,.13-.5,.04-.04,.1-.07,.18-.08,.16-.03,.37-.03,.53,0,.22,.04,.4,.09,.54,.16l.04,.02,.15-.41-.04-.02c-.28-.11-.59-.17-.92-.17-.72,0-1.08,.32-1.08,.94,0,.24,.05,.44,.16,.58,.04,.06,.11,.12,.21,.17-.17,.08-.29,.18-.36,.29-.09,.15-.14,.37-.14,.68,0,.34,.09,.59,.26,.74,.15,.13,.38,.2,.67,.2,.36,0,.68-.1,.96-.3,.03,.08,.07,.15,.14,.2,.08,.07,.19,.1,.33,.1,.26,0,.48-.06,.67-.17l.03-.02-.14-.34-.04,.02Z"/>
	<path d="M107,29.52c-.12-.03-.3-.06-.53-.1-.23-.04-.39-.08-.48-.13-.12-.07-.18-.22-.18-.45,0-.2,.04-.34,.12-.42,.1-.09,.28-.14,.54-.14h.02c.24,0,.51,.06,.81,.19l.04,.02,.15-.43-.04-.02c-.25-.11-.58-.17-.97-.17h-.03c-.17,0-.32,.02-.46,.05-.43,.1-.65,.42-.65,.93v.02c0,.25,.05,.44,.15,.58,.05,.07,.11,.13,.18,.17,.07,.05,.15,.09,.25,.12,.1,.04,.29,.08,.55,.12,.35,.05,.49,.12,.54,.16,.04,.04,.07,.08,.08,.13,.01,.06,.02,.14,.02,.24v.09c0,.1-.01,.19-.03,.26-.06,.22-.26,.33-.63,.33-.32,0-.65-.09-.99-.26l-.04-.02-.19,.39,.03,.02c.16,.1,.37,.17,.61,.22,.1,.02,.2,.04,.3,.05,.1,0,.21,.01,.33,.01s.26-.02,.39-.05c.46-.11,.69-.45,.69-1.01v-.03c0-.36-.09-.62-.27-.75-.09-.06-.19-.11-.31-.14"/>
	<path d="M110.35,28.29h.19c.35,0,.57,.12,.67,.35,.05,.12,.08,.26,.09,.43,.01,.17,.02,.38,.02,.6v.24c0,.46-.06,.78-.17,.93-.06,.08-.14,.14-.24,.17-.11,.03-.24,.05-.4,.05h-.14c-.29,0-.5-.08-.62-.25-.13-.17-.19-.48-.19-.91v-.24c0-.17,0-.35,0-.54,0-.2,.04-.37,.1-.52,.1-.22,.31-.33,.67-.33m1.28,.05c-.16-.31-.54-.47-1.11-.47h-.14c-.37,0-.65,.07-.85,.21-.1,.07-.18,.16-.24,.26-.06,.1-.1,.22-.14,.36-.03,.13-.05,.28-.06,.44-.01,.16-.02,.33-.02,.52s0,.35,.01,.5c.02,.33,.08,.6,.18,.8,.18,.35,.55,.53,1.1,.53h.14c.37,0,.66-.08,.86-.24,.1-.08,.18-.17,.23-.28,.06-.11,.1-.23,.13-.36,.03-.13,.05-.28,.06-.43,0-.16,.01-.33,.01-.51s0-.36,0-.52c-.01-.34-.07-.61-.17-.8"/>
	<polygon points="114.04 27.92 113.56 27.92 113.56 31.45 115.41 31.45 115.41 31.03 114.04 31.03 114.04 27.92"/>
	<path d="M118.87,29.8c0,.26-.01,.48-.03,.65-.02,.16-.06,.29-.12,.39-.06,.09-.13,.15-.24,.18-.11,.04-.27,.05-.48,.05s-.38-.04-.5-.11c-.06-.04-.11-.09-.15-.15-.04-.06-.07-.16-.1-.29-.03-.13-.05-.38-.05-.73v-1.88h-.47v1.9c0,.16,0,.3,0,.43,.01,.3,.06,.55,.16,.74,.17,.34,.56,.51,1.14,.51,.39,0,.69-.08,.89-.24,.1-.08,.17-.17,.23-.28,.05-.1,.09-.23,.12-.38,.03-.15,.04-.41,.04-.81v-1.87h-.47v1.88Z"/>
	<polygon points="120.66 28.34 121.58 28.34 121.58 31.45 122.06 31.45 122.06 28.34 123.01 28.34 123.01 27.92 120.66 27.92 120.66 28.34"/>
	<rect x="124.44" y="27.92" width=".47" height="3.53"/>
	<path d="M127.99,28.29h.19c.35,0,.57,.12,.67,.35,.05,.12,.08,.26,.09,.43,.01,.17,.02,.38,.02,.6v.24c0,.46-.06,.78-.17,.93-.06,.08-.14,.14-.24,.17-.11,.03-.24,.05-.4,.05h-.14c-.29,0-.5-.08-.62-.25-.13-.17-.19-.48-.19-.91v-.24c0-.17,0-.35,0-.54,0-.2,.04-.37,.1-.52,.1-.22,.31-.33,.67-.33m1.28,.05c-.16-.31-.54-.47-1.11-.47h-.14c-.37,0-.65,.07-.85,.21-.1,.07-.18,.16-.24,.26-.06,.1-.1,.22-.14,.36-.03,.13-.05,.28-.06,.44-.01,.16-.02,.33-.02,.52s0,.35,.01,.5c.02,.33,.08,.6,.18,.8,.18,.35,.55,.53,1.1,.53h.14c.37,0,.66-.08,.86-.24,.1-.08,.18-.17,.23-.28,.06-.11,.1-.23,.13-.36,.03-.13,.05-.28,.06-.43,0-.16,.01-.33,.01-.51s0-.36,0-.52c-.01-.34-.07-.61-.17-.8"/>
	<polygon points="133.27 30.42 133.3 30.65 133.19 30.44 131.59 27.92 131.21 27.92 131.21 31.45 131.65 31.45 131.65 28.98 131.63 28.75 131.72 28.93 133.3 31.43 133.32 31.45 133.71 31.45 133.71 27.92 133.27 27.92 133.27 30.42"/>
	<path d="M137.32,29.66c-.09-.06-.19-.11-.31-.14-.12-.03-.3-.06-.53-.1-.23-.04-.39-.08-.48-.12-.12-.07-.18-.22-.18-.45,0-.2,.04-.34,.12-.42,.1-.1,.28-.14,.54-.14h.02c.24,0,.51,.06,.81,.19l.04,.02,.15-.42-.04-.02c-.25-.11-.58-.17-.97-.17h-.03c-.17,0-.32,.02-.46,.05-.43,.1-.65,.41-.65,.93v.02c0,.25,.05,.44,.15,.59,.05,.07,.11,.13,.18,.17,.07,.04,.15,.09,.25,.12,.1,.04,.29,.08,.55,.12,.35,.05,.49,.12,.54,.16,.04,.03,.07,.08,.08,.13,.02,.06,.02,.14,.02,.24v.09c0,.1-.01,.19-.03,.26-.06,.22-.26,.33-.63,.33-.32,0-.65-.09-.99-.26l-.04-.02-.18,.39,.03,.02c.16,.1,.37,.17,.61,.22,.1,.02,.2,.04,.3,.04,.1,0,.21,.01,.33,.01s.25-.02,.39-.05c.46-.11,.69-.45,.69-1v-.03c0-.37-.09-.62-.27-.75"/>
	<path d="M43.67,18.35c-1.3,1.29-4.03,1.45-4.35,1.45-1.74,0-3.36-.59-3.36-2.32,0-1.61-.17-3.18,2.55-3.18h5.15v.39h0v3.65Zm-4.07-12.08c-1.62,0-3.4,.3-4.31,.52-.43,.16-.67,.47-.67,.9l.08,.75c.12,.59,.47,.9,1.03,.9,1.47-.24,2.61-.37,3.87-.37,2.19,0,3.9,.68,4.05,2.72h-5.11c-4.4,0-5.42,2.15-5.42,5.6,0,4.55,3.71,5.06,6.05,5.06,.43,0,2.84-.2,4.5-1.14,0,.51,.36,.94,.87,.94h1.11c.51,0,.87-.43,.87-.94V11.92c0-4.08-2.65-5.65-6.91-5.65"/>
	<path d="M52.9,13.57v1.29c0,3.33,.83,4.78,3.87,4.78,2.68,0,3.99-1.57,3.99-2.23v-7.84c-.99-.43-2.29-.74-3.51-.74-3.39,0-4.34,1.53-4.34,4.74m10.69,7.48c0,.51-.36,.94-.87,.94h-1.11c-.51,0-.87-.43-.87-.94-.99,.82-2.13,1.14-3.75,1.14-4.7,0-6.95-1.8-6.95-7.56v-.67c0-5.64,1.42-7.84,7.22-7.84,1.22,0,2.53,.24,3.47,.55V1.98c0-.51,.36-.94,.87-.94h1.11c.51,0,.87,.43,.87,.94V21.06Z"/>
	<path d="M70.38,13.1h7.5v-.94c0-2.23-1.03-3.33-3.71-3.33-3.04,0-3.79,1.06-3.79,4.27m8.76,5.84c.43,0,.75,.24,.95,.71l.24,.71v.24c0,.35-.16,.59-.47,.78-1.07,.31-3,.82-5.21,.82-5.8,0-7.1-2.47-7.1-8.11v-.63c0-5.76,2.17-7.33,6.87-7.33,5.17,0,6.31,2.63,6.31,6.58v2.04c0,.51-.36,.94-.87,.94h-9.47c.2,2.55,1.18,3.96,4.18,3.96,1.58,0,3.04-.24,4.34-.67,.12,0,.12-.04,.24-.04"/>
	<path d="M89.32,22.19c-3.75,0-4.85-1.41-4.85-5.13V7.26c0-.51,.36-.94,.87-.94h1.1c.51,0,.87,.43,.87,.94v9.36c0,2.04,.32,3.02,2.17,3.02,1.54,0,3.95-.47,5.41-1.29V7.26c0-.51,.36-.94,.87-.94h1.1c.51,0,.87,.43,.87,.94v13.79c0,.51-.36,.94-.87,.94h-1.1c-.51,0-.87-.43-.87-.94-1.1,.63-3.31,1.14-5.56,1.14"/>
	<path d="M102.07,7.3c0-.59,.39-.98,.91-.98h.83c.59,0,.83,.47,.95,1.06,1.1-.63,3.27-1.25,5.52-1.25,3.75,0,4.85,1.41,4.85,5.13v9.8c0,.51-.36,.94-.87,.94h-1.1c-.51,0-.87-.43-.87-.94V11.85c0-2.04-.32-3.02-2.17-3.02-1.54,0-3.79,.55-5.21,1.29v10.93c0,.51-.36,.94-.87,.94h-1.1c-.51,0-.87-.43-.87-.94V7.3Z"/>
	<path d="M121.91,3.01c0,.51-.36,.94-.87,.94h-1.1c-.51,0-.87-.43-.87-.94V1.95c0-.51,.36-.94,.87-.94h1.1c.51,0,.87,.43,.87,.94v1.06Z"/>
	<path d="M121.91,21.06c0,.51-.36,.94-.87,.94h-1.1c-.51,0-.87-.43-.87-.94V7.26c0-.51,.36-.94,.87-.94h1.1c.51,0,.87,.43,.87,.94v13.79Z"/>
	<path d="M125.31,20.47c0-.12-.04-.24,.39-1.14,.16-.43,.43-.63,.87-.63,.16,0,.28,0,.36,.04,1.22,.51,2.92,.9,4.62,.9,1.89,0,3.47-.59,3.47-2.12,0-3.37-9.94,.24-9.94-7.09,0-3.88,3.59-4.31,6-4.31h.16c1.7,.04,3.75,.27,5.29,.63l.47,.12c.43,.12,.51,.35,.55,.67,0,.12,0,.24-.32,1.18-.12,.43-.47,.71-.83,.74-.12,0-.24,0-.36-.04-1.58-.43-3.87-.59-5.01-.59-1.42,0-3.12,.08-3.12,1.72,0,4.35,9.94,.35,9.94,6.97,0,4.08-3.83,4.66-6.27,4.66-2.01,0-4.34-.47-5.8-1.06-.32-.12-.47-.35-.47-.67"/>
</svg>
);

export default SvgAdeunis;
