import React from 'react';

const SvgLogoCrystalcloud = (props: any) => (
    <svg viewBox="0 0 208.34 62.14" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_2-2" data-name="Calque 2">
                <path fill={"#1d1d1b"}
                      d="M13.5,51.31a13,13,0,0,1-9.7-4,14.17,14.17,0,0,1-3.8-10,13.9,13.9,0,0,1,3.8-10,13,13,0,0,1,9.7-4,12.31,12.31,0,0,1,7,2A12.17,12.17,0,0,1,25,30.9l-4.5,1.88a7.4,7.4,0,0,0-7.34-4.93,7.62,7.62,0,0,0-5.84,2.73,9.82,9.82,0,0,0-2.41,6.75,9.82,9.82,0,0,0,2.41,6.75,7.62,7.62,0,0,0,5.84,2.73,7.72,7.72,0,0,0,7.61-4.93l4.39,1.88a11.68,11.68,0,0,1-4.53,5.54A12.79,12.79,0,0,1,13.5,51.31Z"/>
                <path fill={"#1d1d1b"}
                      d="M34.13,50.45H29.2V24.2h4.71v4.29h.22a7.08,7.08,0,0,1,3.08-3.56,8.48,8.48,0,0,1,4.58-1.48,9.18,9.18,0,0,1,3.64.65l-1.5,4.76A8.43,8.43,0,0,0,41,28.49a6.37,6.37,0,0,0-4.85,2.25A7.5,7.5,0,0,0,34.13,36Z"/>
                <path fill={"#1d1d1b"}
                      d="M72.64,24.2,56.2,62H51.11l6.11-13.24L46.39,24.2h5.36l7.82,18.86h.11L67.29,24.2Z"/>
                <path fill={"#1d1d1b"}
                      d="M95.41,43.17a7.11,7.11,0,0,1-3,5.78,11.8,11.8,0,0,1-7.55,2.36,12.05,12.05,0,0,1-7-2.06,11.41,11.41,0,0,1-4.28-5.44L78,41.94a8.18,8.18,0,0,0,2.81,3.67,6.89,6.89,0,0,0,4,1.31,7.15,7.15,0,0,0,3.94-1,2.9,2.9,0,0,0,1.58-2.41q0-2.52-3.86-3.7L82,38.67q-7.67-1.94-7.66-7.39a6.8,6.8,0,0,1,2.92-5.76A13.39,13.39,0,0,1,91.05,25,9,9,0,0,1,95,29.45l-4.39,1.83a5.48,5.48,0,0,0-2.44-2.6,7.56,7.56,0,0,0-3.77-.94,6.37,6.37,0,0,0-3.46,1,2.79,2.79,0,0,0-1.53,2.36q0,2.25,4.24,3.22l4,1Q95.42,37.22,95.41,43.17Z"/>
                <path fill={"#1d1d1b"}
                      d="M109.82,50.88a7.5,7.5,0,0,1-5.33-2,7.37,7.37,0,0,1-2.17-5.52V28.7h-4.6V24.2h4.6v-8h4.93v8h6.43v4.5h-6.43V41.78c0,1.75.34,2.93,1,3.56a3.34,3.34,0,0,0,2.3.94,5,5,0,0,0,1.16-.14,5.52,5.52,0,0,0,1-.35l1.55,4.4A13.3,13.3,0,0,1,109.82,50.88Z"/>
                <path fill={"#1d1d1b"}
                      d="M127.82,23.35c3.65,0,6.52,1,8.63,2.92a10.34,10.34,0,0,1,3.16,8V50.45H134.9V46.81h-.22a9.39,9.39,0,0,1-8.14,4.5,10.62,10.62,0,0,1-7.26-2.57,8.2,8.2,0,0,1-2.92-6.43,7.82,7.82,0,0,1,3.08-6.48,13,13,0,0,1,8.22-2.41A14.5,14.5,0,0,1,134.9,35V33.9a5.62,5.62,0,0,0-2-4.37,7,7,0,0,0-4.77-1.79,7.54,7.54,0,0,0-6.53,3.48l-4.34-2.73Q120.81,23.35,127.82,23.35Zm-6.37,19.07a3.92,3.92,0,0,0,1.63,3.21,6,6,0,0,0,3.83,1.29,7.9,7.9,0,0,0,5.55-2.3,7.25,7.25,0,0,0,2.44-5.42,10.16,10.16,0,0,0-6.43-1.82,8.32,8.32,0,0,0-5,1.45A4.29,4.29,0,0,0,121.45,42.42Z"/>
                <path fill={"#1d1d1b"} d="M150.11,12.1V50.45h-4.93V12.1Z"/>
                <path fill={"#2e3643"}
                      d="M188.87,0h0a19.47,19.47,0,0,1,19.47,19.47V38.94a0,0,0,0,1,0,0H188.87A19.47,19.47,0,0,1,169.4,19.47v0A19.47,19.47,0,0,1,188.87,0Z"
                      transform="translate(208.34 -169.4) rotate(90)"/>
                <path fill={"white"}
                      d="M187.66,11.37h-6.52a.63.63,0,0,0-.63.63v9.24a.63.63,0,0,0,.63.63h6.52a.63.63,0,0,0,.63-.63V12A.63.63,0,0,0,187.66,11.37Zm8.41,0h-6.51a.64.64,0,0,0-.64.63v3.8a.64.64,0,0,0,.64.63h6.51a.64.64,0,0,0,.64-.63V12A.64.64,0,0,0,196.07,11.37Zm-.63,3.8h-5.25V12.63h5.25Zm-7.78,7.34h-6.52a.63.63,0,0,0-.63.63v3.79a.64.64,0,0,0,.63.64h6.52a.64.64,0,0,0,.63-.64V23.14A.63.63,0,0,0,187.66,22.51ZM187,26.3h-5.26V23.77H187Zm9-9.24h-6.51a.64.64,0,0,0-.64.64v9.23a.64.64,0,0,0,.64.64h6.51a.64.64,0,0,0,.64-.64V17.7A.64.64,0,0,0,196.07,17.06Zm-.63,9.24h-5.25v-8h5.25Z"/>
                <path fill={"#2e3643"}
                      d="M160.48,41.8A3.77,3.77,0,0,1,161.86,43a3.38,3.38,0,0,1,.53,1.94,3.82,3.82,0,0,1-.26,1.42,3.27,3.27,0,0,1-.74,1.11,3.58,3.58,0,0,1-1.12.74,4,4,0,0,1-2.84,0,3.37,3.37,0,0,1-1.12-.74,3.25,3.25,0,0,1-.73-1.11,3.63,3.63,0,0,1-.27-1.42,3.43,3.43,0,0,1,.53-1.94,3.12,3.12,0,0,1,1.37-1.15l.68,1.63a1.72,1.72,0,0,0-.7.59,1.73,1.73,0,0,0-.1,1.56,1.51,1.51,0,0,0,.38.55,1.71,1.71,0,0,0,.59.38,2.47,2.47,0,0,0,1.58,0,1.86,1.86,0,0,0,.6-.38,1.8,1.8,0,0,0,.38-.55,1.59,1.59,0,0,0,.13-.66,1.65,1.65,0,0,0-.25-.94,1.72,1.72,0,0,0-.73-.62Z"/>
                <path fill={"#2e3643"} d="M162.17,40.72h-9.71V38.94h9.71Z"/>
                <path fill={"#2e3643"}
                      d="M155.31,34.34a3.63,3.63,0,0,1,.27-1.42,3.25,3.25,0,0,1,.73-1.11,3.2,3.2,0,0,1,1.12-.73,3.67,3.67,0,0,1,1.42-.27,3.72,3.72,0,0,1,1.43.27,3.34,3.34,0,0,1,1.85,1.84,4,4,0,0,1,0,2.84,3.34,3.34,0,0,1-1.85,1.84,3.72,3.72,0,0,1-1.43.27,3.67,3.67,0,0,1-1.42-.27,3.2,3.2,0,0,1-1.12-.73,3.25,3.25,0,0,1-.73-1.11A3.63,3.63,0,0,1,155.31,34.34Zm5.44,0a1.65,1.65,0,0,0-.13-.66,1.61,1.61,0,0,0-.37-.56,1.83,1.83,0,0,0-.6-.39,2.2,2.2,0,0,0-.8-.14,2.24,2.24,0,0,0-.8.14,2.07,2.07,0,0,0-.6.39,1.76,1.76,0,0,0-.37.56,1.72,1.72,0,0,0,.37,1.88,2.07,2.07,0,0,0,.6.39,2.24,2.24,0,0,0,.8.14,2.2,2.2,0,0,0,.8-.14,1.83,1.83,0,0,0,.6-.39,1.69,1.69,0,0,0,.5-1.22Z"/>
                <path fill={"#2e3643"}
                      d="M161.31,25.14v.11a2.28,2.28,0,0,1,.79.84,2.55,2.55,0,0,1,.29,1.21,2.27,2.27,0,0,1-.77,1.88,3.22,3.22,0,0,1-2.06.62h-4V28h3.81a1.53,1.53,0,0,0,1.06-.32,1.16,1.16,0,0,0,.35-.93,1.22,1.22,0,0,0-.16-.64,1.38,1.38,0,0,0-.42-.48,1.88,1.88,0,0,0-.62-.3,2.76,2.76,0,0,0-.77-.1h-3.25V23.47h6.64v1.67Z"/>
                <path fill={"#2e3643"}
                      d="M161.38,17.07v.11a1.94,1.94,0,0,1,.71.76,2.49,2.49,0,0,1,.3,1.27,3,3,0,0,1-.26,1.22,3.33,3.33,0,0,1-.74,1,3.47,3.47,0,0,1-1.11.69,3.71,3.71,0,0,1-1.43.26,3.66,3.66,0,0,1-1.42-.26,3.42,3.42,0,0,1-1.12-.69,3.3,3.3,0,0,1-.73-1,2.86,2.86,0,0,1-.27-1.22,2.49,2.49,0,0,1,.3-1.27,2,2,0,0,1,.72-.76v-.11l-1,.11h-2.92V15.4h9.71v1.67Zm-.63,1.78a1.67,1.67,0,0,0-.13-.67,1.65,1.65,0,0,0-.37-.57,1.83,1.83,0,0,0-.6-.39,2,2,0,0,0-.8-.15,2,2,0,0,0-.8.15,2.07,2.07,0,0,0-.6.39,1.81,1.81,0,0,0-.37.57,1.86,1.86,0,0,0-.13.67,1.71,1.71,0,0,0,.14.67,1.64,1.64,0,0,0,.38.56,1.92,1.92,0,0,0,.59.4,2.3,2.3,0,0,0,1.58,0,2.1,2.1,0,0,0,.6-.4,2,2,0,0,0,.38-.56A1.7,1.7,0,0,0,160.75,18.85Z"/>
                <path fill={"#bfbfbf"}
                      d="M112.28,61.05a1.64,1.64,0,0,1-.4.57,1.79,1.79,0,0,1-.62.35,2.45,2.45,0,0,1-.78.12h-2.62V55.62h2.52a2.09,2.09,0,0,1,1.43.47,1.7,1.7,0,0,1,.52,1.3,1.53,1.53,0,0,1-.08.49,1.66,1.66,0,0,1-.2.4,1.58,1.58,0,0,1-.28.3,1.09,1.09,0,0,1-.31.18,1.68,1.68,0,0,1,.36.21,1.52,1.52,0,0,1,.31.31,1.58,1.58,0,0,1,.22.43,1.82,1.82,0,0,1,.08.55A2,2,0,0,1,112.28,61.05Zm-1.22-4.31a1.18,1.18,0,0,0-.77-.24h-1.45v1.85h1.45a1.18,1.18,0,0,0,.77-.23,1,1,0,0,0,0-1.38Zm.11,2.77a1.07,1.07,0,0,0-.79-.28h-1.54v2h1.54a1.07,1.07,0,0,0,.79-.27,1,1,0,0,0,.28-.71A.92.92,0,0,0,111.17,59.51Z"/>
                <path fill={"#bfbfbf"} d="M116,59.43v2.66h-1V59.43l-1.93-3.81h1.07l1.35,2.84,1.36-2.84h1.07Z"/>
                <path fill={"#bfbfbf"}
                      d="M125.59,59.53c0,.24,0,.47-.06.7a3.19,3.19,0,0,1-.19.67,1.93,1.93,0,0,1-1.14,1,2.75,2.75,0,0,1-.93.15H121V55.62h2.27a2.75,2.75,0,0,1,.93.15,1.89,1.89,0,0,1,.74.46,2,2,0,0,1,.4.57,2.7,2.7,0,0,1,.19.63c0,.22,0,.45.06.68v1.42Zm-1-1.85a1.31,1.31,0,0,0-.3-.74,1.44,1.44,0,0,0-1.1-.44H122v4.7h1.19a1.43,1.43,0,0,0,1.1-.43,1.39,1.39,0,0,0,.3-.79c0-.33,0-.72,0-1.17S124.6,58,124.57,57.68Z"/>
                <path fill={"#bfbfbf"} d="M127,62.09V55.62h1v6.47Z"/>
                <path fill={"#bfbfbf"}
                      d="M134.12,59.69a4.73,4.73,0,0,1-.07.69,1.81,1.81,0,0,1-.18.57,1.66,1.66,0,0,1-.36.5,2.54,2.54,0,0,1-.76.51,2.44,2.44,0,0,1-.95.18,2.39,2.39,0,0,1-.94-.18,2.54,2.54,0,0,1-.76-.51,1.86,1.86,0,0,1-.36-.5,2.54,2.54,0,0,1-.19-.57,3.69,3.69,0,0,1-.06-.69c0-.25,0-.53,0-.84s0-.59,0-.84a3.51,3.51,0,0,1,.06-.68,2.39,2.39,0,0,1,.19-.57,1.72,1.72,0,0,1,.36-.5,2.4,2.4,0,0,1,.76-.52,2.58,2.58,0,0,1,.94-.17,2.63,2.63,0,0,1,.95.17,2.4,2.4,0,0,1,.76.52,1.55,1.55,0,0,1,.36.5,1.73,1.73,0,0,1,.18.57,4.45,4.45,0,0,1,.07.68c0,.25,0,.53,0,.84S134.12,59.44,134.12,59.69Zm-1-1.65c0-.21,0-.4,0-.54a1.55,1.55,0,0,0-.12-.38,1.05,1.05,0,0,0-.2-.27,1.17,1.17,0,0,0-.42-.29,1.38,1.38,0,0,0-1.08,0,1.17,1.17,0,0,0-.42.29,1.05,1.05,0,0,0-.2.27,1.22,1.22,0,0,0-.11.38,3.54,3.54,0,0,0-.06.54c0,.22,0,.49,0,.81s0,.6,0,.82a3.69,3.69,0,0,0,.06.54,1.16,1.16,0,0,0,.11.37,1.1,1.1,0,0,0,.2.28,1.17,1.17,0,0,0,.42.29,1.38,1.38,0,0,0,1.08,0,1.17,1.17,0,0,0,.42-.29,1.1,1.1,0,0,0,.2-.28,1.46,1.46,0,0,0,.12-.37c0-.15,0-.33,0-.54s0-.49,0-.82S133.14,58.26,133.13,58Z"/>
                <path fill={"#bfbfbf"}
                      d="M140,58.37a1.72,1.72,0,0,1-.41.62,2.08,2.08,0,0,1-.66.4,2.3,2.3,0,0,1-.85.15h-1.47v2.55h-1V55.62H138a2.3,2.3,0,0,1,.85.15,2.1,2.1,0,0,1,.66.41,1.64,1.64,0,0,1,.41.62,2.13,2.13,0,0,1,0,1.57Zm-1.15-1.59a1.21,1.21,0,0,0-.83-.28h-1.41v2.15H138a1.2,1.2,0,0,0,.83-.27,1,1,0,0,0,.32-.8A1,1,0,0,0,138.8,56.78Z"/>
                <path fill={"#bfbfbf"} d="M143.48,56.5v5.59h-1V56.5h-1.78v-.88h4.53v.88Z"/>
                <path fill={"#bfbfbf"}
                      d="M149.54,62.09l-.44-1.3h-2.56l-.44,1.3h-1l2.36-6.47h.79l2.37,6.47Zm-1.7-5-1,2.87h2Z"/>
                <path fill={"#bfbfbf"}
                      d="M155.37,61a1.77,1.77,0,0,1-.48.6,2.14,2.14,0,0,1-.74.37,3,3,0,0,1-.93.13,3.79,3.79,0,0,1-1.28-.19,2.68,2.68,0,0,1-1-.66l.65-.65a1.94,1.94,0,0,0,.76.49,2.75,2.75,0,0,0,.9.13,1.63,1.63,0,0,0,1-.26.87.87,0,0,0,.35-.73.84.84,0,0,0-.25-.67.75.75,0,0,0-.28-.17,2,2,0,0,0-.43-.1l-.77-.11a2.25,2.25,0,0,1-1.22-.52,1.61,1.61,0,0,1-.47-1.24,1.91,1.91,0,0,1,.15-.76,1.75,1.75,0,0,1,.42-.61,1.89,1.89,0,0,1,.68-.39,2.86,2.86,0,0,1,.91-.13,3.09,3.09,0,0,1,1.11.17,2.43,2.43,0,0,1,.89.56l-.62.62a1.67,1.67,0,0,0-.65-.4,2.64,2.64,0,0,0-.76-.1,1.26,1.26,0,0,0-.88.29,1,1,0,0,0-.3.72.88.88,0,0,0,.06.33.7.7,0,0,0,.19.27,1.37,1.37,0,0,0,.72.3l.74.11a3.13,3.13,0,0,1,.72.18,1.78,1.78,0,0,1,.48.3,1.37,1.37,0,0,1,.4.57,2,2,0,0,1,.13.75A1.88,1.88,0,0,1,155.37,61Z"/>
                <path fill={"#bfbfbf"} d="M156.86,62.09V55.62H161v.88h-3.15v1.89h2.69v.87h-2.69V61.2H161v.89Z"/>
            </g>
        </g>
    </svg>
);
export default SvgLogoCrystalcloud;
