import React, { useState, useEffect } from "react";

import Measure from "./Measure";
import Explication from "./Explication";
import Partenaire from "./Partenaire";
import TextComponent from "./TextComponent";
import Schema from "./Schema";
import Doc from "./Doc";

import Compatible from "../onyx/Compatible";
import Contact from "../onyx/Contact";
import Crystalcloud from "./VideoAccueil";
import Recap from "./Recap";

import FooterSection from "../section/FooterSection";
import ScrollSequence from "../scroll/ScrollSequence";

import images from "../../_resources/ccSynchro";
import Children from "./VideoAccueil";

import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import ImageSelector from "../responsive/ImageSelector";
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';

const App = (props) => {
  const { t } = props

  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  })

  const content = {
    description: t('produits.mbox.frames')
  }
  /**
   * <Helmet>
              <meta
        name="description"
        content="Module radio, solution pour toute relève de compteur compatible wmbus, pour relever vos compteurs d'aujourd'hui et de demain"
      />
      <meta name="keywords" content="Mbox, boîtier, autonome, trames, wireless, radio-relève, divre by, walk by, geolocalisation, 2G, 3G, batterie, format compact, antennes, bluetooth, développement d'applications, protocole SDI, protocole, crystalcloud, multi-fluides" />
      <meta property="og:title" content="MBox"/>
      <meta property="og:type" content="Produits - solution de relève compteurs"/>
      <meta property="og:url" content="https://www.dioptase.fr/produits/mbox"/>
      <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
      <meta property="og:description" content="Solution pour toute relève de compteur compatible WMBus"/>
                  </Helmet>
   */
  return (
    <div className={"mbox-container responsiv"}>
      <MetaTags>
      <title>MBox</title>
        <meta
          name="description"
          content="Module radio, solution pour toute relève de compteur compatible wmbus, pour relever vos compteurs d'aujourd'hui et de demain"
        />
        <meta name="keywords" content="Mbox, boîtier, autonome, trames, wireless, radio-relève, divre by, walk by, geolocalisation, 2G, 3G, batterie, format compact, antennes, bluetooth, développement d'applications, protocole SDI, protocole, crystalcloud, multi-fluides" />
        <meta property="og:title" content="MBox" />
        <meta property="og:type" content="Produits - solution de relève compteurs" />
        <meta property="og:url" content="https://www.dioptase.fr/produits/mbox" />
        <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
        <meta property="og:description" content="Solution pour toute relève de compteur compatible WMBus" />
      </MetaTags>
      <Measure t={t} />
      <Explication />
      <Partenaire t={t} />
      <TextComponent t={t} content={content} />
      <Schema />
      <ImageSelector src={images("", "fr")[images("", "fr").length - 1]} children={<Children t={t} />} className="filter" />
      <Recap t={t} />
      <Compatible section="Logiciels" parentColor="#1f8491" parent="mbox" t={t} />
      <Contact t={t} />
      <FooterSection t={t} />
    </div>
  );
};

const tr = withTranslation()(App);
export default tr;
