import React from "react";
import { Button } from "reactstrap";
import { withTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"

const Error404 = (props) => {
  const { t } = props
  const content = {
    big: "404",
    title: t('error.title'),
    description: t('error.description'),
    retry: t('error.retry'),
  };

  const isOver = useMediaQuery({ query: "(max-width: 1040px)" });

  return (
    <div className="error-container">
      <div className="wrapper">
        <div className="center">
        <h3>{content.title}</h3>
          <h1>{content.big}</h1>
          <div className="span">
            <span>{content.description}</span>
            <br />
            <span>{content.retry}</span>
          </div>
          <div className="btn-container">
            <a href="/">
  <Button className="primary">{t('all.home')}</Button>
            </a>
          </div>
        </div>
      </div>
      <div className="image-background not-found" style={{display: isOver ? "none" : "block"}}/>
    </div>
  );
};

const tr = withTranslation()(Error404);
export default tr;
