import React, { Component } from "react";
import { connect } from "react-redux";

import Batterie from "../../_svg/Batterie";
import Memoire from "../../_svg/Memoire";
import Bluetooth from "../../_svg/Earth";
import Dimension from "../../_svg/Dimension";
import Poids from "../../_svg/Poids";
import Resolution from "../../_svg/Smartphone";
import Photo from "../../_svg/AppareilPhoto";
import Ip from "../../_svg/Ip";
import Poubelle from "../../_svg/Poubelle";

import AddButton from "./Button";

import deviceAction from "../../redux/device/device.action";

import _ from "lodash";

import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const RowCalc = (props: any) => {
  const { el, index, caracteristiques } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 600px)"})
  return (
    <div
      className="row"
      style={{
        borderBottom:
          index === caracteristiques.length - 1 ? "none" : "1px solid grey",
      }}
    >
      {!isMobile && <div className="box">{el.svg}</div>}
      <div className="box">
        <h5>{el.title}</h5>
      </div>
    </div>
  );
};

class Calculator extends Component<any, any> {
  constructor(Props: any) {
    super(Props);
    this.state = {
      selected: [],
      open: false,
      index: 0,
      modalScroll: 0,
    };
  }

  handleClick = (index: number) => {
    this.setState({
      open: true,
      index,
    });
  };

  componentDidMount() {
    const { device, dispatch, initDevice } = this.props;
    const { selected } = this.state;
    if (device && !device.devices) {
      dispatch(deviceAction.getAll());
    }

    if (initDevice && !selected[0]) {
      selected[0] = initDevice;
      this.setState({
        selected,
      });
    }
  }

  handleSelect = (index: any, isSelected: any) => {
    const { selected, modalScroll } = this.state;
    const copySelected = _.cloneDeep(selected);
    copySelected[index - 1] = isSelected;
    this.setState({
      selected: copySelected,
    }, () => window.scrollTo(0, modalScroll));
  };

  caracDisplayer = (carac: any, index: any) => {
    const { t } = this.props;
    const { selected } = this.state;
    const element = selected[index];
    const second = index === 0 ? selected[1] : selected[0];
    switch (carac) {
      case t("materials.dimension"):
        return (
          <h4>{`${element.dimension.largeur}x${element.dimension.longueur}x-cm`}</h4>
        );
      case t("materials.weight"):
        return (
          <h4
            style={{
              color: second
                ? second.poids > element.poids
                  ? "#A9BF04"
                  : "#dc3545"
                : "#212529",
            }}
          >{`${element.poids}g`}</h4>
        );
      case t("materials.resolution"):
        return <h4>{`${element.resolution}"`}</h4>;
      case t("materials.bluetooth"):
        return <h4>{`${element.bluetooth}`}</h4>;
      case t("materials.waterproofing"):
        return <h4>{`${element.ip}`}</h4>;
      case t("materials.camera"):
        return (
          <h4
            style={{
              color: second
                ? second.pixel < element.pixel
                  ? "#A9BF04"
                  : "#dc3545"
                : "#212529",
            }}
          >{`${element.pixel} mp`}</h4>
        );
      case t("materials.memory"):
        return (
          <h4
            style={{
              color: second
                ? second.memoire < element.memoire
                  ? "#A9BF04"
                  : "#dc3545"
                : "#212529",
            }}
          >{`${element.memoire} go`}</h4>
        );
      case t("materials.battery"):
        return (
          <h4
            style={{
              color: second
                ? second.batterie < element.batterie
                  ? "#A9BF04"
                  : "#dc3545"
                : "#212529",
            }}
          >{`${element.batterie} mAh`}</h4>
        );
    }
  };

  removeDevice = (index: number) => {
    const { selected } = this.state;
    const { initDevice } = this.props;
    if ((!initDevice && index === 0) || index === 1) {
      const copySelected = _.cloneDeep(selected);
      copySelected[index] = undefined;
      this.setState({
        selected: copySelected,
      });
    }
  };

  render() {
    const { selected } = this.state;
    const { device, t, initDevice } = this.props;

    const caracteristiques = [
      {
        title: t("materials.dimension"),
        svg: <Dimension width="2em" fill="#b37830"/>,
      },
      {
        title: t("materials.weight"),
        svg: <Poids width="2em" fill="#b37830"/>,
      },
      {
        title: t("materials.resolution"),
        svg: <Resolution height="2em" fill="#b37830"/>,
      },
      {
        title: t("materials.bluetooth"),
        svg: <Bluetooth width="2em" fill="#b37830" />,
      },
      {
        title: t("materials.waterproofing"),
        svg: <Ip width="2em" fill="#b37830" />,
      },
      {
        title: t("materials.camera"),
        svg: <Photo width="2em" fill="#b37830" />,
      },
      {
        title: t("materials.memory"),
        svg: <Memoire width="2em" fill="#b37830"/>,
      },
      {
        title: t("materials.battery"),
        svg: <Batterie width="2em" fill="#b37830"/>,
      },
    ];

    console.log(initDevice, selected)

    return (
      <div className="calculator-container">
        <div className="header">
          <div className="section" />
          <div className="section device">
            {selected[0] && selected[0].code !== (initDevice ? initDevice.code : 'null') && (
              <div className="filter-card" onClick={() => this.removeDevice(0)}>
                <Poubelle height="5em" />
              </div>
            )}
            {selected[0] === undefined && device && device.devices && (
              <AddButton
                index={1}
                onClick={this.handleClick}
                devices={device.devices}
                handleSelect={this.handleSelect}
                setScroll={(scrollY: any) => this.setState({modalScroll: scrollY})}
                filtered={selected[1] ? selected[1] : undefined}
              />
            )}
            {selected[0] !== undefined && (
              <div className="device-card">
                <div className="section-card">
                  <h2>{selected[0].name}</h2>
                </div>
                <div className="section-card">
                  <img
                    src={require(`../../_resources/image/telephone/${selected[0].code}.png`)}
                    alt="telephone"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="section device">
            {selected[1] !== undefined && (
              <div className="filter-card" onClick={() => this.removeDevice(1)}>
                <Poubelle height="5em" />
              </div>
            )}
            {selected[1] === undefined && device && device.devices && (
              <AddButton
                index={2}
                onClick={this.handleClick}
                devices={device.devices}
                handleSelect={this.handleSelect}
                setScroll={() => this.setState({modalScroll: window.scrollY})}
                filtered={selected[0] ? selected[0] : undefined}
              />
            )}
            {selected[1] !== undefined && (
              <div className="device-card">
                <div className="section-card">
                  <h2>{selected[1].name}</h2>
                </div>
                <div className="section-card">
                  <img
                    src={require(`../../_resources/image/telephone/${selected[1].code}.png`)}
                    alt="telephone"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tab-container">
          <div className="column">
            {caracteristiques.map((el: any, index: number) => (
              <RowCalc el={el} index={index} caracteristiques={caracteristiques }/>
            ))}
          </div>
          <div className="column device">
            {caracteristiques.map((el: any, index: number) => (
              <div
                className="row"
                style={{
                  borderBottom:
                    index === caracteristiques.length - 1
                      ? "none"
                      : "1px solid grey",
                }}
              >
                {selected[0] && this.caracDisplayer(el.title, 0)}
              </div>
            ))}
          </div>
          <div className="column device">
            {caracteristiques.map((el: any, index: number) => (
              <div
                className="row"
                style={{
                  borderBottom:
                    index === caracteristiques.length - 1
                      ? "none"
                      : "1px solid grey",
                }}
              >
                {selected[1] && this.caracDisplayer(el.title, 1)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { device } = state;
  return {
    device,
  };
}

const connectedCalculator = connect(mapStateToProps)(Calculator);
const tr = withTranslation()(connectedCalculator);
export default tr;
