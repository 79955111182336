import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import './style.scss';

import Footer from '../../component/section/FooterSection';

const Recrut = (props) => {
    const { job, t } = props;
    const history = useHistory();
    return (
        <div className='recrut-container'>
            <div className='top-title'>
                <div className='clip-title'>
                    <h4>Dioptase</h4>
                    <h2>{t('job.title')}</h2>
                </div>
                <div className='clip-title-description'>
                    <div className='box'>
                        <h4>{t('job.description.1')}</h4>
                        <h4>{t('job.description.2')}</h4>
                    </div>
                </div>
            </div>
            <div className='jobs-wrapper'>
                {job.jobs && job.jobs.map(el => {
                    let description = el.profil.split(" ").splice(0, 40)
                    description.push("...")
                    description = description.join(" ")
                    console.log(el)
                    return (
                        <div className='card-j' onClick={() => history.push(`/recrutement/${el.id}`)}>
                            <div className='top'>
                                <h3>{el.title}</h3>
                                <div className='rect'>
                                    <h5>{el.type.contract}</h5>
                                </div>
                            </div>
                            <span>{el.description.length > 300 ? description : el.Description}</span>
                            <span style={{display: 'block', marginTop: '10px'}}><b>{t('job.salary')}: </b>{el.salary.max ? `De ${el.salary.min}€ à ${el.salary.max}€ par an` : `à partir de ${el.salary.min}€ par an`}</span>
                        </div>
                    )
                })}
            </div>
            <Footer t={t} />
        </div>
    )
}

const mapStateToProps = ({ job }) => {
    return {
        job
    }
}

const tr = withTranslation()(Recrut);
const connectedApp = connect(mapStateToProps)(tr);
export default connectedApp;
