import React from "react";

const Email = (props) => {
    return (
        <svg id="Calque_1" viewBox="0 0 80.79 55.23" {...props}>
            <path fill={props.fill} d="M68.27,37.28l-13.08-15.38,11.62-6.63c1.13-.65,1.53-2.09,.88-3.23-.65-1.13-2.09-1.53-3.23-.88-.97,.55-22.99,13.12-24.07,13.74-1.09-.62-23.11-13.19-24.07-13.74-1.14-.65-2.58-.25-3.23,.88-.65,1.14-.25,2.58,.88,3.23l11.62,6.63-13.09,15.38c-.85,.99-.73,2.49,.27,3.34,.99,.85,2.49,.73,3.33-.27l13.66-16.06,9.44,5.39c.73,.41,1.62,.41,2.35,0l9.44-5.39,13.66,16.06c.85,1,2.34,1.12,3.34,.27,.99-.85,1.11-2.34,.27-3.34" />
            <path fill={props.fill} d="M76.05,48.13c0,1.3-1.06,2.37-2.37,2.37H7.1c-1.3,0-2.37-1.06-2.37-2.37V7.1c0-1.31,1.06-2.37,2.37-2.37H73.69c1.3,0,2.37,1.06,2.37,2.37V48.13ZM73.69,0H7.1C3.19,0,0,3.19,0,7.1V48.13C0,52.04,3.19,55.23,7.1,55.23H73.69c3.92,0,7.1-3.19,7.1-7.1V7.1c0-3.92-3.19-7.1-7.1-7.1" />
        </svg>
    )
}

export default Email;