import React from 'react';

const SvgChangementCompteur = (props: any) => (
	<svg viewBox="0 0 24 13.46" {...props}>
		<path fill={props.fill}
			  d="m11.21,7.33c.26-.61.48-1.09.66-1.44.13-.26.26-.47.38-.65.12-.17.26-.35.43-.52.17-.16.36-.29.58-.38.22-.08.46-.12.73-.12h2.15v1.61c0,.07.03.14.08.19.05.05.12.08.19.08.08,0,.14-.03.19-.07l2.69-2.7c.05-.05.08-.12.08-.19s-.03-.14-.08-.19l-2.69-2.69c-.07-.06-.13-.08-.2-.08-.08,0-.14.02-.19.07-.05.05-.07.11-.07.19v1.61h-2.15c-.38,0-.74.05-1.08.14-.34.09-.64.21-.9.35-.26.14-.52.34-.76.58-.24.24-.45.47-.62.7-.17.23-.35.51-.54.84-.19.33-.34.63-.46.88-.11.25-.25.57-.42.93-.26.61-.48,1.09-.66,1.44-.13.26-.26.47-.38.65-.12.17-.26.35-.43.52-.17.17-.36.29-.58.38-.22.08-.46.12-.73.12h-1.89c-.08,0-.14.02-.19.07-.05.05-.07.11-.07.19v1.61c0,.08.03.14.08.19.05.05.11.07.19.07h1.89c.38,0,.74-.05,1.08-.14.34-.09.63-.21.9-.35.26-.14.51-.34.76-.58.24-.24.45-.47.62-.7.17-.23.35-.51.54-.84.19-.34.34-.63.46-.88.12-.24.25-.55.42-.92"/>
		<path fill={props.fill}
			  d="m4.56,4.22h1.89c.25,0,.47.04.69.12.21.08.38.18.53.3.14.11.28.27.43.47.14.2.26.38.34.53.08.16.19.36.31.61.43-1.02.81-1.78,1.15-2.29-.9-1.26-2.05-1.9-3.45-1.9h-1.89c-.08,0-.14.02-.19.07-.05.05-.07.11-.07.19v1.61c0,.08.02.14.07.19.05.05.11.08.19.08"/>
		<path fill={props.fill}
			  d="m16.61,7.81c-.07-.05-.13-.08-.2-.08-.08,0-.14.03-.19.07-.05.05-.07.11-.07.19v1.61h-2.15c-.25,0-.47-.04-.68-.12-.21-.08-.39-.18-.53-.29-.14-.12-.29-.27-.43-.48-.14-.2-.26-.38-.34-.53-.08-.16-.19-.36-.31-.61-.43,1.01-.81,1.78-1.14,2.3.15.22.31.42.47.58.16.17.32.32.46.45.15.13.31.24.5.33.18.1.35.17.49.24.14.07.32.12.53.16.21.04.39.07.54.09.15.02.35.03.6.04.25,0,.46,0,.62,0,.16,0,.39,0,.68,0,.3,0,.53,0,.71,0v1.61c0,.07.03.14.08.19.05.05.11.08.19.08.08,0,.14-.02.19-.07l2.69-2.69c.05-.05.07-.11.07-.19s-.03-.14-.08-.19l-2.68-2.68Z"/>
	</svg>
);

export default SvgChangementCompteur;
