import React from 'react';

const SvgTelemaintenance = (props: any) => (
<svg version="1.1" id="Calque_1" x="0px" y="0px" viewBox="0 0 216.1 162.6" {...props}>
<g>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M102.6,95.8c-9.7,1.4-19.4,3.4-29.1,4.8c-9.4,1.4-18.9,2.2-28.3,3.7c-4.5,0.7-9.2,1.3-13.7,2.4
		c-3.9,0.9-7.5,2.4-11.5,2.7c-1.6-7-2.5-14.3-3.2-21.4c-0.1-0.9-0.2-1.8-0.2-2.7c0,0.2,0.1,0.4,0.1,0.7c0,0.2,0.4,0.2,0.4-0.1
		c-0.8-10.7-2.7-21.3-4-32c-0.7-5.5-1.2-11-1.6-16.5c-0.4-5.4-0.3-10.8-0.7-16.1c0.1,0,0.1,0,0.1,0c0.5,0,1.1-0.2,1.4-0.3
		c2-0.3,4.2-0.1,6.2-0.1c5,0,10-1.1,15-1.6c11.1-1,22.2-1.4,33.3-2.1c10.8-0.7,21.7-1.3,32.5-1.8c9.5-0.4,19-0.3,28.2-2.6
		c-0.1,5.9,1.4,11.6,1.7,17.5c0.4,6.8,0,13.7,0.5,20.6c0.4,6.7,0.7,13.3,0.9,20c0.1,3.3,0.1,6.6,0.2,9.9c0,3.5,0.4,7.2,0.1,10.6
		C121.5,93.4,112.1,94.5,102.6,95.8 M131.9,91.3c0.2-2.6-0.1-5.4-0.1-8c0-3.6-0.1-7.2-0.1-10.8c-0.1-7-0.4-13.9-0.9-20.9
		c-0.5-6.9-0.3-13.8-0.5-20.6c-0.2-6.2-1.8-12.2-2.1-18.4c0,0,0,0,0,0c0.6-0.2,0.4-0.9-0.1-1c0,0,0-0.1,0-0.1c0-0.4-0.5-0.3-0.6,0
		c0,0.1,0,0.2,0,0.2c-9.2,2.5-18.9,2.3-28.3,2.7c-10.6,0.4-21.1,1-31.7,1.7c-10.6,0.7-21.1,1.1-31.7,2c-5.1,0.4-10.1,1-15.2,1.7
		c-1.8,0.2-3.7,0.1-5.5,0.1c-1,0-2,0.1-3,0.2c-0.2,0-1.7,0.5-1.7,0.5c0,0,0,0,0,0c-0.3-0.1-0.6,0.2-0.4,0.4c0.1,0.1,0.1,0.1,0.2,0.2
		c-0.5,5.1-0.1,10.4,0.2,15.5c0.3,5.5,0.9,11,1.5,16.5c0.9,8.2,1.9,16.5,3.2,24.7c0.3,3.4,0.4,6.8,0.7,10.2
		c0.6,7.1,1.6,14.4,3.2,21.4c-0.3,0-0.5,0-0.8,0c-0.5,0-0.4,0.7,0,0.7c0.3,0,0.7,0,1,0c0.1,0.1,0.3,0.1,0.5,0
		c3.6-0.2,7.1-1.4,10.5-2.4c4.8-1.3,9.9-1.9,14.8-2.7c9.7-1.6,19.5-2.3,29.1-3.8c9.7-1.4,19.4-3.4,29.1-4.8
		c9.2-1.3,18.5-2.3,27.6-4.2c0.2,0.1,0.5,0.1,0.6-0.1c0.1,0,0.2,0,0.4-0.1C132.7,92,132.5,91.2,131.9,91.3"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M102.6,95.8c-9.7,1.4-19.4,3.4-29.1,4.8c-9.4,1.4-18.9,2.2-28.3,3.7c-4.5,0.7-9.2,1.3-13.7,2.4
		c-3.9,0.9-7.5,2.4-11.5,2.7c-1.6-7-2.5-14.3-3.2-21.4c-0.1-0.9-0.2-1.8-0.2-2.7c0,0.2,0.1,0.4,0.1,0.7c0,0.2,0.4,0.2,0.4-0.1
		c-0.8-10.7-2.7-21.3-4-32c-0.7-5.5-1.2-11-1.6-16.5c-0.4-5.4-0.3-10.8-0.7-16.1c0.1,0,0.1,0,0.1,0c0.5,0,1.1-0.2,1.4-0.3
		c2-0.3,4.2-0.1,6.2-0.1c5,0,10-1.1,15-1.6c11.1-1,22.2-1.4,33.3-2.1c10.8-0.7,21.7-1.3,32.5-1.8c9.5-0.4,19-0.3,28.2-2.6
		c-0.1,5.9,1.4,11.6,1.7,17.5c0.4,6.8,0,13.7,0.5,20.6c0.4,6.7,0.7,13.3,0.9,20c0.1,3.3,0.1,6.6,0.2,9.9c0,3.5,0.4,7.2,0.1,10.6
		C121.5,93.4,112.1,94.5,102.6,95.8z M131.9,91.3c0.2-2.6-0.1-5.4-0.1-8c0-3.6-0.1-7.2-0.1-10.8c-0.1-7-0.4-13.9-0.9-20.9
		c-0.5-6.9-0.3-13.8-0.5-20.6c-0.2-6.2-1.8-12.2-2.1-18.4c0,0,0,0,0,0c0.6-0.2,0.4-0.9-0.1-1c0,0,0-0.1,0-0.1c0-0.4-0.5-0.3-0.6,0
		c0,0.1,0,0.2,0,0.2c-9.2,2.5-18.9,2.3-28.3,2.7c-10.6,0.4-21.1,1-31.7,1.7c-10.6,0.7-21.1,1.1-31.7,2c-5.1,0.4-10.1,1-15.2,1.7
		c-1.8,0.2-3.7,0.1-5.5,0.1c-1,0-2,0.1-3,0.2c-0.2,0-1.7,0.5-1.7,0.5c0,0,0,0,0,0c-0.3-0.1-0.6,0.2-0.4,0.4c0.1,0.1,0.1,0.1,0.2,0.2
		c-0.5,5.1-0.1,10.4,0.2,15.5c0.3,5.5,0.9,11,1.5,16.5c0.9,8.2,1.9,16.5,3.2,24.7c0.3,3.4,0.4,6.8,0.7,10.2
		c0.6,7.1,1.6,14.4,3.2,21.4c-0.3,0-0.5,0-0.8,0c-0.5,0-0.4,0.7,0,0.7c0.3,0,0.7,0,1,0c0.1,0.1,0.3,0.1,0.5,0
		c3.6-0.2,7.1-1.4,10.5-2.4c4.8-1.3,9.9-1.9,14.8-2.7c9.7-1.6,19.5-2.3,29.1-3.8c9.7-1.4,19.4-3.4,29.1-4.8
		c9.2-1.3,18.5-2.3,27.6-4.2c0.2,0.1,0.5,0.1,0.6-0.1c0.1,0,0.2,0,0.4-0.1C132.7,92,132.5,91.2,131.9,91.3z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M70.9,11.9c0-0.4-0.2-0.7-0.3-1.1c-0.3-0.7-0.4-1.5-0.5-2.2c0,0,0,0,0,0c1.4,0.1,2.8-0.1,4.2-0.1
		c0.5,0,2.5-0.3,2.8,0.3c0.3,0.7,0.3,1.4,0.5,2.1C75.4,11,73.2,11.6,70.9,11.9 M77,7.6c-2.2-0.3-4.8,0.2-7,0.6
		c-0.1,0-0.1,0.1-0.1,0.1c-0.2-0.1-0.4,0-0.4,0.2c-0.1,0.7,0.1,1.4,0.2,2.1c0.1,0.4,0.2,1,0.4,1.4c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0.2,0,0.2c0.1,0,0.3,0,0.4,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.3,0c2.4,0.2,4.9-0.3,7.2-0.8c0.2,0,0.3-0.1,0.3-0.3
		C78.7,10.1,78.5,7.8,77,7.6"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M70.9,11.9c0-0.4-0.2-0.7-0.3-1.1c-0.3-0.7-0.4-1.5-0.5-2.2c0,0,0,0,0,0c1.4,0.1,2.8-0.1,4.2-0.1
		c0.5,0,2.5-0.3,2.8,0.3c0.3,0.7,0.3,1.4,0.5,2.1C75.4,11,73.2,11.6,70.9,11.9z M77,7.6c-2.2-0.3-4.8,0.2-7,0.6
		c-0.1,0-0.1,0.1-0.1,0.1c-0.2-0.1-0.4,0-0.4,0.2c-0.1,0.7,0.1,1.4,0.2,2.1c0.1,0.4,0.2,1,0.4,1.4c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0.2,0,0.2c0.1,0,0.3,0,0.4,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.3,0c2.4,0.2,4.9-0.3,7.2-0.8c0.2,0,0.3-0.1,0.3-0.3
		C78.7,10.1,78.5,7.8,77,7.6z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M75.9,9.8c-0.3-0.3-0.6-0.6-0.9-0.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.1-0.1-0.2-0.1-0.4-0.1c0,0,0,0,0,0c0-0.1-0.2-0.2-0.3-0.1c0,0,0,0,0,0c-0.2-0.1-0.4-0.1-0.6-0.1C73.3,8.5,73,8.7,73,8.9
		c0,0.2,0,0.4,0,0.5c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0.1,0.2,0.2,0.4,0.5,0.4c0.1,0,0.2,0,0.3,0
		c0.4,0.3,1.2,0.2,1.6,0.1C76,10.5,76.1,10.1,75.9,9.8"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M75.9,9.8c-0.3-0.3-0.6-0.6-0.9-0.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.1-0.1-0.2-0.1-0.4-0.1c0,0,0,0,0,0c0-0.1-0.2-0.2-0.3-0.1c0,0,0,0,0,0c-0.2-0.1-0.4-0.1-0.6-0.1C73.3,8.5,73,8.7,73,8.9
		c0,0.2,0,0.4,0,0.5c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0.1,0.2,0.2,0.4,0.5,0.4c0.1,0,0.2,0,0.3,0
		c0.4,0.3,1.2,0.2,1.6,0.1C76,10.5,76.1,10.1,75.9,9.8z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M83.1,26.9c-5.1,3.2-8.2,9-12.2,13.3C65.4,46.1,59.7,52,54.8,58.5c-0.1,0.2,0.1,0.4,0.3,0.3
		c5-5.7,10-11.4,15.2-17c2.2-2.4,4.2-4.8,6.2-7.3c2-2.6,4.1-5.3,6.9-7.2C83.6,27.1,83.4,26.8,83.1,26.9"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M83.1,26.9c-5.1,3.2-8.2,9-12.2,13.3C65.4,46.1,59.7,52,54.8,58.5c-0.1,0.2,0.1,0.4,0.3,0.3
		c5-5.7,10-11.4,15.2-17c2.2-2.4,4.2-4.8,6.2-7.3c2-2.6,4.1-5.3,6.9-7.2C83.6,27.1,83.4,26.8,83.1,26.9z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M84,35.5c-6.9,3.2-11.4,9.7-16.5,15.1c-6,6.3-13.2,12.6-16.9,20.6c-0.1,0.2,0.2,0.5,0.4,0.3
		c2.4-3.4,4.8-6.9,7.5-10.1c2.7-3.2,5.6-6.2,8.6-9.2c5.4-5.5,10-12.6,17.1-16C84.6,35.9,84.4,35.3,84,35.5"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M84,35.5c-6.9,3.2-11.4,9.7-16.5,15.1c-6,6.3-13.2,12.6-16.9,20.6c-0.1,0.2,0.2,0.5,0.4,0.3
		c2.4-3.4,4.8-6.9,7.5-10.1c2.7-3.2,5.6-6.2,8.6-9.2c5.4-5.5,10-12.6,17.1-16C84.6,35.9,84.4,35.3,84,35.5z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M90.2,38.9c-1.4,2-3.5,3.3-5.5,4.7c-2.3,1.6-4.3,3.4-6.4,5.2c-4.2,3.6-8.2,7.5-12.4,11.1
		c-3.3,2.9-6.7,5.7-9.8,8.8c-2.7,2.7-5.9,6.2-6.7,10.1c-0.1,0.4,0.5,0.6,0.6,0.2c3.6-8.1,10.7-13.5,17.2-19.2c4.1-3.6,8-7.4,12.1-11
		c2.2-1.9,4.3-3.7,6.7-5.4c1.7-1.2,3.4-2.5,4.6-4.2C90.9,38.9,90.4,38.5,90.2,38.9"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M90.2,38.9c-1.4,2-3.5,3.3-5.5,4.7c-2.3,1.6-4.3,3.4-6.4,5.2c-4.2,3.6-8.2,7.5-12.4,11.1
		c-3.3,2.9-6.7,5.7-9.8,8.8c-2.7,2.7-5.9,6.2-6.7,10.1c-0.1,0.4,0.5,0.6,0.6,0.2c3.6-8.1,10.7-13.5,17.2-19.2c4.1-3.6,8-7.4,12.1-11
		c2.2-1.9,4.3-3.7,6.7-5.4c1.7-1.2,3.4-2.5,4.6-4.2C90.9,38.9,90.4,38.5,90.2,38.9z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M86.3,49.6c-0.2-0.2-0.3-0.2-0.6-0.2c-0.4,0-0.6,0.6-0.1,0.7c-1.1-0.1-3.5,2.3-4.3,2.8
		c-2.1,1.7-4.2,3.4-6.3,5.1c-2.7,2.2-5.5,4.5-8.2,6.6c-2.7,2.1-5.3,4.2-7.6,6.7c-1.4,1.5-3.4,3.9-3,6.1c0.1,0.4,0.6,0.3,0.7,0
		c1.9-6.1,7.9-10,12.7-13.7c3.1-2.4,6-5,9.1-7.6c1.9-1.5,3.7-3.3,5.7-4.6c0.6-0.4,1.9-0.8,2.1-1.7C86.5,49.8,86.4,49.6,86.3,49.6"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M86.3,49.6c-0.2-0.2-0.3-0.2-0.6-0.2c-0.4,0-0.6,0.6-0.1,0.7c-1.1-0.1-3.5,2.3-4.3,2.8
		c-2.1,1.7-4.2,3.4-6.3,5.1c-2.7,2.2-5.5,4.5-8.2,6.6c-2.7,2.1-5.3,4.2-7.6,6.7c-1.4,1.5-3.4,3.9-3,6.1c0.1,0.4,0.6,0.3,0.7,0
		c1.9-6.1,7.9-10,12.7-13.7c3.1-2.4,6-5,9.1-7.6c1.9-1.5,3.7-3.3,5.7-4.6c0.6-0.4,1.9-0.8,2.1-1.7C86.5,49.8,86.4,49.6,86.3,49.6z"
		/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M86,55.8C75,65.8,63,75.3,53.7,87c-0.2,0.3,0.1,0.6,0.4,0.3c10.6-10.6,21.6-20.8,32.5-31
		C86.9,55.9,86.4,55.5,86,55.8"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M86,55.8C75,65.8,63,75.3,53.7,87c-0.2,0.3,0.1,0.6,0.4,0.3c10.6-10.6,21.6-20.8,32.5-31
		C86.9,55.9,86.4,55.5,86,55.8z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M213.9,127c-0.1,0-0.2,0-0.3,0c0,0.4-0.1,0.8-0.5,1c-0.9,0.6-1.9,1-2.8,1.5c0.8-0.2,1.6-0.5,2.4-0.7
		c-0.6,0.4-1.2,0.7-1.5,0.8c-2.4,1.3-4.9,2.1-7.5,2.9c-6.9,2.3-13.9,3.9-20.9,5.6c-0.2,0-0.3,0.1-0.5,0.1c2.8-0.7,5.5-1.4,8.3-2.2
		c4.2-1.2,8.4-2.5,12.5-4.2c-1.6,0.4-3.3,0.8-4.9,1.3c-4.8,1.4-9.7,2.5-14.6,3.3c-4.7,1.4-9.5,2.6-14.4,3.6
		c-9.8,3.2-19.9,5.4-30,7.7c-3.6,0.8-7.1,1.8-10.7,2.8c4.4-0.8,8.8-1.7,13-2.9c0.2-0.1,0.2-0.2,0.2-0.3c1-0.1,2-0.2,3.1-0.3
		c-2.7,0.7-5.4,1.4-8.1,2.1c-4.6,1.1-9.1,2.1-13.7,3.1c0,0,0-0.1,0-0.1c-4.6,1.1-9.2,2-13.9,2.4c-2.2,0.6-4.4,1.1-6.7,1.6
		c-6,1.4-17.3,5-22.5,0c-0.1,0.1-0.1,0.1-0.1,0.2c-4-2.6-8.5-4.5-12.8-6.7c-2.9-1.4-5.7-3-8.5-4.5c-0.1-0.1-0.1-0.1-0.2-0.1
		c-0.2-0.1-0.5-0.3-0.7-0.4c0-0.2,0-0.4-0.1-0.7c0-0.4-0.6-0.4-0.7,0c0,0.1,0,0.2,0,0.2c-0.3-0.1-0.5-0.3-0.8-0.4
		c0-0.1,0-0.2-0.1-0.3c-0.1-0.4-0.6-0.4-0.6-0.1c-0.3-0.2-0.6-0.3-0.9-0.5c0-0.1,0-0.2,0-0.3c0-0.4-0.6-0.4-0.7,0
		c-1.2-0.6-2.3-1.2-3.5-1.8c0-0.2-0.3-0.3-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-2.9-1.4-5.9-2.5-8.8-3.7c0,0,0,0,0,0
		c-1-0.4-2.1-0.8-3.1-1.2c0-0.3-0.4-0.4-0.6-0.2c-0.5-0.2-1-0.4-1.5-0.7c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.3-0.6-0.2-0.6,0.1
		c0,0.2,0,0.3,0.1,0.5c-1-0.4-1.9-0.9-2.9-1.4c-2.9-1.5-5.6-3.3-8.4-5c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.2-0.1
		c-0.4-0.3-0.9-0.6-1.3-0.9c0-0.1,0-0.2-0.1-0.3c-0.1-0.3-0.4-0.3-0.6-0.1c-1.4-0.9-2.8-1.8-4.3-2.4c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0
		c0.3-0.1,0.6-0.2,0.8-0.4c2.7-0.4,5.3-1.2,7.9-2c0.3,0.2,0.7,0,0.8-0.2c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.5,0.9
		c0.1,0.1,0.3,0.1,0.3,0c0.2-0.3,0.3-0.7,0.2-1.1c0.4-0.1,0.9-0.2,1.3-0.3c0,0,0,0,0,0c0.8-0.1,1.7-0.3,2.5-0.5
		c-1.3,0.2-2.6,0.4-3.9,0.4c-0.4,0-0.7-0.2-0.9-0.5c0,0-0.1,0-0.1-0.1c-0.7-0.8-1.1-1.4-1.3-2.4c-0.2-1,0.8-1.5,1.5-1.3
		c0.1-0.1,0.3-0.2,0.5-0.3c1.4-0.4,2.9-0.8,4.3-1.1c4-1.4,8.9-2.3,12.1,0.2c0.5,0.3,0.4,0.9,0.1,1.3c0,0,0.1-0.1,0.2-0.1
		c0.1,0.1,0.2,0.3,0.3,0.4c-0.3,0-0.6,0.1-0.9,0.1c0,0-0.1,0.1-0.1,0.1c-1.8,0.6-3.6,1.2-5.4,1.8c2.3-0.3,4.8-0.4,7-0.9
		c0.2,0.2,0.6,0.1,0.7-0.2c0.7-0.2,1.4-0.5,2.1-0.8c0.1-0.1,0.1-0.2,0.1-0.2c3.3-1.9,7.9-1.9,11.5-2.6c6.4-1.2,12.8-2.2,19.3-3.3
		c12.5-2.2,25.5-2.5,37.8-5.8c0.1,0,0.2-0.1,0.3-0.2c3.1,0.2,6.3-0.1,9.4-0.7c1.8-0.3,3.5-0.7,5.3-1.2c0.9-0.3,2.1-0.6,2.9-1.3
		c1.6-0.3,3.3-0.9,4.7-1.1c3.8-0.6,8.4,1.6,11.9,2.8c8.5,2.9,17.1,5.3,25.5,8.4c8.5,3.1,16.5,7.2,24.9,10.5c2.1,0.8,4.4,1.2,6.6,1.9
		c1.5,0.4,4,1.1,5.5,2.3c0.1,0,0.1,0.1,0.2,0.1c0.2,0.2,0.4,0.4,0.6,0.6C213.9,126.9,213.9,126.9,213.9,127 M88.9,159.5
		c-0.3,0-0.4,0.6,0,0.6c0.5,0.1,1,0.1,1.5,0.1c-1.3,0.2-2.6,0.6-3.9,0.8c-0.3,0.1-0.7,0.1-1,0.1c0,0-0.1-0.1-0.1-0.1
		c-1.8-0.2-3.4-0.2-5.1-0.7c-1.2-0.4-2.4-0.9-3.7-1c0-0.1-0.1-0.1-0.2-0.2c-0.7-0.3-1.4-0.6-2-0.9c0-1.3-0.4-2.6-0.7-3.9
		c2.6,1.3,5.1,2.7,7.5,4.4c0.3,0.2,0.6,0.1,0.8-0.1c1.1,0.5,2.4,0.7,3.7,0.8c2.4,0.1,6-0.1,8.2-1.3c2.1-0.2,4.2-0.5,6.3-0.9
		C96.4,158.3,92.8,159.3,88.9,159.5 M72.6,157.4c-0.1-0.7-0.3-1.3-0.5-2c-0.2-0.7-0.4-1.4-0.5-2.1c0.5,0.2,1,0.5,1.4,0.7
		c0,0,0,0.1,0,0.1c0.2,1.3,0.2,2.7,0.8,3.8C73.4,157.8,73,157.6,72.6,157.4 M70.7,156.5c0.1,0,0.2-0.1,0.2-0.2
		c0.1-0.7-0.4-1.3-0.6-2c-0.3-0.7-0.5-1.4-0.8-2.1c0.4,0.2,0.9,0.4,1.3,0.6c0.1,1.3,0.3,2.9,0.9,4C71.4,156.9,71,156.7,70.7,156.5
		 M68.7,155.6c0-0.8-0.2-1.6-0.4-2.4c-0.1-0.5-0.2-1.1-0.3-1.6c0.3,0.2,0.6,0.3,0.9,0.5c0,0.1-0.1,0.1,0,0.2
		c0.2,0.8,0.4,1.6,0.7,2.4c0.2,0.6,0.4,1.3,0.9,1.8C69.8,156.1,69.3,155.9,68.7,155.6 M65.9,154.3c-0.2-0.6-0.6-1.2-0.8-1.9
		c-0.3-0.8-0.4-1.7-0.6-2.6c0.1,0,0.2,0.1,0.3,0.1c0.8,0.4,1.6,0.8,2.3,1.2c0.1,0.6,0.2,1.2,0.3,1.9c0.1,0.8,0.3,1.5,0.6,2.2
		C67.3,155,66.6,154.7,65.9,154.3 M63,153.2c-0.1-1.5-0.7-3.3-1.5-4.6c-0.1-0.2-0.4,0-0.4,0.2c0.2,0.9,0.5,1.7,0.8,2.6
		c0.1,0.5,0.3,1.1,0.5,1.6c-0.6-0.2-1.1-0.4-1.7-0.6c0,0,0,0,0,0c0-1.6-0.5-3.2-0.6-4.8c0-0.1,0-0.1,0-0.1c1.3,0.7,2.6,1.4,3.8,2
		c0.1,1.3,0.2,3,0.7,4.3C64.1,153.6,63.5,153.4,63,153.2 M59.3,152c0-1.8-0.4-3.6-0.8-5.4c0.4,0.2,0.8,0.4,1.2,0.6
		c-0.2,0-0.3,0.1-0.3,0.3c0,1.5,0,3.2,0.7,4.6C59.8,152.2,59.6,152.1,59.3,152 M52.5,149.8c-0.4-0.2-0.9-0.4-1.3-0.6c0,0,0,0,0,0
		c0-2.3-0.7-4.5-1-6.8c0.5,0.3,1.1,0.5,1.6,0.8c-0.1,1.8,0.3,3.9,1,5.4c0.1,0.2,0.4,0.2,0.4-0.1c0-1-0.2-2-0.4-3.1
		c-0.1-0.6-0.2-1.3-0.2-1.9c0.4,0.2,0.7,0.4,1.1,0.6c0.1,1.2,0.2,2.5,0.5,3.6c0,0.2,0.3,0.2,0.3,0c0.2-1,0.1-2.1-0.1-3.2
		c0.3,0.1,0.5,0.3,0.8,0.4c0.1,1.6,0.4,3.3,0.9,4.8c0.1,0.2,0.4,0.2,0.4,0c0.1-1.4-0.1-2.8-0.4-4.3c0.2,0.1,0.3,0.2,0.5,0.2
		c0,1.9,0.1,3.9,0.9,5.5c0.1,0.2,0.3,0.1,0.3-0.1c0.1-1.6-0.1-3.3-0.3-5c0.1,0.1,0.2,0.1,0.3,0.2c0.1,1.9,0.3,3.9,1,5.6
		C56.7,151.3,54.5,150.6,52.5,149.8 M43.6,145.1C43.7,145.1,43.7,145,43.6,145.1c0-1-0.3-1.9-0.5-2.7c-0.3-1.2-0.4-2.3-0.6-3.5
		c0.4,0.2,0.9,0.4,1.3,0.5c-0.2,0-0.4,0.1-0.3,0.4c0.2,1.8,0.2,3.9,1.1,5.6c0.1,0.2,0.4,0.1,0.4-0.1c0-1.8-0.5-3.7-0.7-5.4
		c0-0.1-0.1-0.2-0.1-0.2c1,0.4,2,0.9,3,1.3c0.8,0.3,1.5,0.7,2.3,1.1c0.1,2.4,0.6,4.8,1.4,7C48.4,147.8,46,146.5,43.6,145.1
		 M36.4,141.2c-0.2-1.3-0.6-2.7-0.9-4c-0.1-0.5-0.1-0.9-0.2-1.4c0.4,0.2,0.8,0.3,1.2,0.5c0.1,1.7,0.4,3.5,1.3,4.8
		c0.1,0.2,0.4,0.1,0.4-0.1c-0.1-0.8-0.3-1.6-0.6-2.4c-0.2-0.6-0.2-1.3-0.3-1.9c0.4,0.2,0.8,0.3,1.2,0.5c0.1,1.6,0.3,3.4,1.2,4.6
		c0.1,0.1,0.3,0.1,0.3-0.1c0-0.8-0.2-1.6-0.4-2.4c-0.1-0.6-0.2-1.2-0.3-1.8c0.2,0.1,0.4,0.2,0.6,0.2c0.2,1.9,0.3,4,1.4,5.6
		c0.1,0.2,0.4,0,0.4-0.2c-0.3-1.7-0.6-3.4-0.9-5.1c0.4,0.1,0.7,0.3,1.1,0.4c0.1,1.8,0.2,4.4,1.2,6.1
		C40.8,143.5,38.7,142.3,36.4,141.2 M33.5,140.1c-1.5-0.5-3.1-1-4.7-1.6c-0.1-2-0.7-4.1-1.3-6c-0.1-0.4-0.6-0.2-0.6,0.2
		c0.5,1.8,0.7,3.7,1.1,5.6c-0.1,0-0.3-0.1-0.4-0.1c0,0,0-0.1,0-0.1c-0.2-2.4-0.9-4.9-1.6-7.2c1.2,0.8,2.4,1.5,3.7,2.3
		c0.3,0.2,0.6,0.3,0.9,0.5c0.1,1.9,0.9,4.1,1.9,5.6c0.1,0.2,0.3,0,0.3-0.1c-0.4-1.8-1.2-3.5-1.8-5.3c1.1,0.6,2.3,1.2,3.5,1.7
		c0.2,1.9,0.3,3.9,1.2,5.5C35.1,140.7,34.3,140.4,33.5,140.1 M26,137.5c0,0,0-0.1,0-0.1c-0.3-2.5-0.9-5-1.4-7.5c0,0,0,0,0-0.1
		c0.3,0.2,0.7,0.4,1,0.6c-0.1,0.1-0.1,0.1-0.1,0.2c0.6,2.4,0.8,5,1.8,7.3C26.9,137.9,26.4,137.7,26,137.5 M20.4,135.1
		c-1-0.6-2-1.2-2.9-2c0-2.4-0.5-4.9-1.3-7.1c-0.1-0.2-0.4-0.2-0.4,0.1c0.3,2.1,0.6,4.2,1,6.3c-0.2-0.2-0.4-0.4-0.5-0.6
		c0-0.1,0-0.1,0-0.2c-0.1-0.4-0.3-0.7-0.4-1.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.8-0.5-1.5-0.6-2.3
		c0.1-1.2,0.6-2.3,0.9-3.5c0,0,0.1-0.1,0.1-0.1c0.8,0.7,1.8,1.3,2.7,1.9c0,0.1,0,0.1,0,0.2c0.6,2.3,0.8,4.7,1.7,6.9
		c0.1,0.1,0.3,0.1,0.3-0.1c-0.3-2.2-0.7-4.4-1.3-6.6c0.5,0.3,1.1,0.7,1.6,1c0.4,2.7,0.7,5.6,1.7,8.1c0.1,0.2,0.4,0.2,0.4-0.1
		c-0.3-2.5-0.7-5-1.2-7.5c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4c0.1,2.3,0.5,4.7,0.9,7c0,0.2,0.3,0.3,0.4,0
		c0.3-2.1-0.1-4.4-0.6-6.6c0.3,0.2,0.6,0.4,0.9,0.6c-0.2,0-0.4,0.1-0.4,0.3c0.4,2.4,0.6,5.1,1.6,7.4C23.8,136.7,22.1,136,20.4,135.1
		 M22.7,117.8C22.7,117.8,22.7,117.8,22.7,117.8c-0.1,0.2-0.2,0.3-0.4,0.4c-0.9,0.4-1.7,0.6-2.6,0.8c-2,0.7-4.1,0.6-6-0.9
		c-0.1-0.1-0.1-0.1-0.1-0.2c0,0-0.1,0-0.1-0.1c-0.3-0.4-0.6-0.8-0.8-1.4c0-0.1-0.2-0.2-0.3-0.1c0,0,0-0.1,0.1-0.1
		c0.1-0.1,0-0.2-0.1-0.2c-0.1,0-0.2,0-0.2,0c-0.2-0.1-0.4-0.1-0.5,0.1c0,0,0,0,0,0c-0.3-2.7,0-5.5,0-8.2c0-3.5-0.8-7-1.3-10.4
		C9.3,90.7,8.9,83.8,8.4,77C8,72.1,7.6,67.2,7.1,62.3c-0.1-1-0.2-2.1-0.3-3.1c0-5.1-0.6-10.4-1.3-15.5c-0.1-1.6-0.2-3.2-0.4-4.7
		C4.9,35,4.6,31.1,4.2,27.2c-0.4-3-0.9-6.3-0.1-9.3c0.2,0.8,0.3,1.5,0.5,2.1c0.6,3.6,1.2,7.2,1.8,10.8c1.2,7.7,2.1,15.4,3.2,23.1
		c0,0.3,0.5,0.3,0.4,0c-0.3-7.2-1.5-14.4-2.7-21.5c-0.5-3.3-1.1-6.7-1.7-10C5.3,21,5,19.6,4.7,18.3c-0.1-0.5-0.3-1-0.4-1.6
		c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.7-0.1-1.4,0.1-2c0-0.1-0.2-0.2-0.2-0.1c-0.4,0.8-0.4,1.7-0.3,2.6
		c-1.1,2.4-0.9,5.4-0.8,8c0.2,2.8,0.5,5.7,0.8,8.5c-0.6-2.8-1.3-5.7-1.8-8.5c0-2.9,0.3-5.8,1.1-8.7C4.3,12,6.6,10,10.6,8.6
		C17.9,6,26.2,6.4,33.9,6.2C41,6.1,48.1,5.3,55.2,4.9c8.9-0.5,17.9-0.7,26.9-1C91,3.7,99.9,3.4,108.9,3c7.8-0.3,15.7-1.1,23.5-1.1
		c0,1,0.3,2.3,0.4,3.1c0.2,2.1,0.6,4.3,0.6,6.5c0.2,9.4,0.3,18.8,0.5,28.2c0.1,9.2,0.6,18.4,1.1,27.6c0.2,4.6,0.6,9.1,1.1,13.6
		c0.4,3.4,1.7,7.6,1.2,11.1c-0.4-0.1-0.9,0.1-0.9,0.6c-0.1,1.5-0.5,3-1.1,4.3c-0.2,0.1-0.4,0.2-0.6,0.3c-1-0.4-2.3-0.3-3.3-0.2
		c-1.5,0.2-3,0.6-4.5,0.8c-2,0.3-3.9,0.8-5.9,1.1c-1.9,0.3-3.9,0.4-5.9,0.7c-0.2,0-0.3,0.4,0,0.4c0.8,0.2,1.7,0.3,2.6,0.3
		c0.2-0.1,0.4-0.2,0.6-0.2c1,0,1.9,0,2.9-0.1c2.4-0.4,4.9-1,7.1-1.4c0.6-0.1,1.2-0.2,1.7-0.3c0.2,0,0.3-0.1,0.5-0.1
		c0.1,0,0.2,0,0.3,0c0.5-0.2,1-0.4,1.4-0.6c0.8-0.3,1.5,0.2,1.6,0.8c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.1
		c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0.1,0.4,0,0.8-0.5,1.1c-0.9,0.4-1.8,0.7-2.7,1c-0.3,0.1-0.6,0.2-0.9,0.3c-1.6,0.6-3.3,1-5,1.5
		c-0.3,0.1-0.6,0-0.8-0.1c-2,0.3-4,0.5-6,0.8c0-0.4-0.2-0.9-0.4-1.2c0,0,0,0,0,0c-0.4-0.2-0.5-0.6-0.5-0.9c-0.4-0.5-0.8-1-1.4-1.3
		c-0.3-0.1-0.6,0.2-0.4,0.5c0.4,0.6,0.9,1.2,1.2,1.9c0.1,0.3,0.2,0.7,0.3,1c-0.2-0.2-0.5-0.4-0.8-0.3c-6.4,1.8-13,2.6-19.6,3.4
		c-6.8,0.8-13.4,1.9-20.2,3c-6,1-12,2.1-18,3.2c-3.4,0.6-8.5,0.7-11.2,3.3c0,0,0,0,0,0c-0.5,0.1-0.9,0.1-1.4,0.2
		c-0.9-4.1-7.1-3.7-10.3-3.2c-3.5,0.6-7,1.8-10.4,2.8l-0.1,0c-0.2,0.3-0.5,0.7-0.8,1c0,0.1-0.1,0.1-0.1,0.2
		C23.4,117.7,23.1,117.8,22.7,117.8 M3.4,37c-0.4-2.4-0.8-4.7-1.1-7.1c0.7,3.8,1.6,7.5,2.1,11.3c0.1,0.9,0.2,1.8,0.3,2.7
		C4.3,41.6,3.8,39.3,3.4,37 M215.5,127.5c0-0.1,0-0.2,0-0.3c-0.1-0.4-0.4-0.6-0.7-0.7c-0.9-1.1-2.4-1.8-3.6-2.3
		c-2.5-1.1-5.3-1.7-7.9-2.5c-3.8-1-7.4-2.8-10.9-4.4c-4.5-2-8.9-4-13.5-5.8c-8.5-3.3-17.3-5.9-26-8.7c-4.1-1.3-8.1-3-12.4-3.5
		c-0.8-0.1-1.6,0-2.3,0.2c-0.3,0.2-0.6,0.4-1,0.6c-0.3,0.1-0.6,0.1-0.9-0.1c-0.2,0.1-0.5,0.1-0.7,0.2c0.1-0.2,0.2-0.5,0.2-0.7
		c0,0,0.1-0.1,0.1-0.1c-0.1-0.4,0-1,0.4-1.3c0-0.2,0.1-0.4,0.3-0.6c1.3-1.3,1.8-3.1,2-4.9c0,0,0,0,0,0c0.5-2.9-0.3-5.8-0.6-8.6
		c-0.6-4.5-1.1-9-1.3-13.5c-0.6-9.4-1-18.9-1.3-28.3c-0.3-9.1-0.4-18.3-0.6-27.4c-0.1-2.7-0.2-5.3-0.6-7.9c-0.2-1.2-0.3-2.3-0.5-3.5
		c-0.1-0.6-0.5-1.6-0.3-2.2c0-0.2-0.1-0.3-0.3-0.3c-0.1-0.1-0.3-0.2-0.5-0.2c-5.9,0-11.8,0.5-17.7,0.8c-7,0.4-14,0.6-21,0.8
		c-14,0.4-28,0.8-42,1.5C46.2,4,40.5,4.6,34.9,4.7C31.3,4.9,27.6,4.9,23.9,5C17.5,5.4,8.7,5.7,4,10.8C2,12.9,1.5,16.3,1,19
		c-0.8,4.5-0.5,9.1,0.1,13.6c0.6,4.3,1.6,8.5,2.4,12.8c1,5,1.5,10.1,2,15.2c1,9.7,1.7,19.3,2.7,29c0.5,4.9,1.2,9.7,2,14.5
		c0.7,4.5,0.1,9,1,13.5c0,0.2,0.2,0.2,0.3,0.1c0.3,0.5,0.9,0.9,1.3,1.2c0.9,1.2,2.3,1.6,4,1.7c0,0,0,0,0,0c1.8,0,3.7-0.5,5.3-1.1
		c0.5,0.1,0.9,0.3,1.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-2.2,0.6-5.1,1.6-6.9,3.2c-0.2,0-0.3,0-0.4,0.2c-0.7,0.9-1,1.9-1.1,2.9
		c-0.1,1.1,0,2.2,0.4,3.2c0.4,1.6,1.4,3.2,2.7,4.4c3.2,2.8,7.4,4.2,11.4,5.6c0,0.2,0.2,0.2,0.3,0.1c0.5,0.2,1.1,0.3,1.6,0.5
		c2.1,0.7,4.1,1.3,6.1,2.2c3.6,1.7,7.1,3.9,10.6,5.9c4.7,3.2,10.1,5.6,15.5,7.2c0.2,0.1,0.3-0.2,0.1-0.3c-1.8-1-3.7-1.8-5.6-2.6
		c1.8,0.5,3.5,1.1,5.3,1.6c4.7,1.5,8.7,4.8,13.6,5.8c0,0,0,0,0.1,0c2.3,0.8,5.4,2.3,7.9,1.9c1.5,0.2,3.2,0,4.7-0.4
		c2.4,0.3,4.9-0.5,7.3-1.3c2.6-0.6,5.2-1.3,7.8-1.9c6.6-1.7,13.1-3.4,19.7-4.9c4.2-1,8.6-1.7,12.8-2.7c6.7-1.6,13.4-3.4,20-5.1
		c5.5-1.4,11-2.4,16.5-3.6c9.6-2,19.1-4.1,28.5-6.9c3.6-1.1,7.3-2.3,10.7-4c1.1-0.6,3.1-1.6,3.6-3.1
		C215.6,127.9,215.6,127.7,215.5,127.5"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M213.9,127c-0.1,0-0.2,0-0.3,0c0,0.4-0.1,0.8-0.5,1c-0.9,0.6-1.9,1-2.8,1.5c0.8-0.2,1.6-0.5,2.4-0.7
		c-0.6,0.4-1.2,0.7-1.5,0.8c-2.4,1.3-4.9,2.1-7.5,2.9c-6.9,2.3-13.9,3.9-20.9,5.6c-0.2,0-0.3,0.1-0.5,0.1c2.8-0.7,5.5-1.4,8.3-2.2
		c4.2-1.2,8.4-2.5,12.5-4.2c-1.6,0.4-3.3,0.8-4.9,1.3c-4.8,1.4-9.7,2.5-14.6,3.3c-4.7,1.4-9.5,2.6-14.4,3.6
		c-9.8,3.2-19.9,5.4-30,7.7c-3.6,0.8-7.1,1.8-10.7,2.8c4.4-0.8,8.8-1.7,13-2.9c0.2-0.1,0.2-0.2,0.2-0.3c1-0.1,2-0.2,3.1-0.3
		c-2.7,0.7-5.4,1.4-8.1,2.1c-4.6,1.1-9.1,2.1-13.7,3.1c0,0,0-0.1,0-0.1c-4.6,1.1-9.2,2-13.9,2.4c-2.2,0.6-4.4,1.1-6.7,1.6
		c-6,1.4-17.3,5-22.5,0c-0.1,0.1-0.1,0.1-0.1,0.2c-4-2.6-8.5-4.5-12.8-6.7c-2.9-1.4-5.7-3-8.5-4.5c-0.1-0.1-0.1-0.1-0.2-0.1
		c-0.2-0.1-0.5-0.3-0.7-0.4c0-0.2,0-0.4-0.1-0.7c0-0.4-0.6-0.4-0.7,0c0,0.1,0,0.2,0,0.2c-0.3-0.1-0.5-0.3-0.8-0.4
		c0-0.1,0-0.2-0.1-0.3c-0.1-0.4-0.6-0.4-0.6-0.1c-0.3-0.2-0.6-0.3-0.9-0.5c0-0.1,0-0.2,0-0.3c0-0.4-0.6-0.4-0.7,0
		c-1.2-0.6-2.3-1.2-3.5-1.8c0-0.2-0.3-0.3-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-2.9-1.4-5.9-2.5-8.8-3.7c0,0,0,0,0,0
		c-1-0.4-2.1-0.8-3.1-1.2c0-0.3-0.4-0.4-0.6-0.2c-0.5-0.2-1-0.4-1.5-0.7c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.3-0.6-0.2-0.6,0.1
		c0,0.2,0,0.3,0.1,0.5c-1-0.4-1.9-0.9-2.9-1.4c-2.9-1.5-5.6-3.3-8.4-5c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.2-0.1
		c-0.4-0.3-0.9-0.6-1.3-0.9c0-0.1,0-0.2-0.1-0.3c-0.1-0.3-0.4-0.3-0.6-0.1c-1.4-0.9-2.8-1.8-4.3-2.4c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0
		c0.3-0.1,0.6-0.2,0.8-0.4c2.7-0.4,5.3-1.2,7.9-2c0.3,0.2,0.7,0,0.8-0.2c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.5,0.9
		c0.1,0.1,0.3,0.1,0.3,0c0.2-0.3,0.3-0.7,0.2-1.1c0.4-0.1,0.9-0.2,1.3-0.3c0,0,0,0,0,0c0.8-0.1,1.7-0.3,2.5-0.5
		c-1.3,0.2-2.6,0.4-3.9,0.4c-0.4,0-0.7-0.2-0.9-0.5c0,0-0.1,0-0.1-0.1c-0.7-0.8-1.1-1.4-1.3-2.4c-0.2-1,0.8-1.5,1.5-1.3
		c0.1-0.1,0.3-0.2,0.5-0.3c1.4-0.4,2.9-0.8,4.3-1.1c4-1.4,8.9-2.3,12.1,0.2c0.5,0.3,0.4,0.9,0.1,1.3c0,0,0.1-0.1,0.2-0.1
		c0.1,0.1,0.2,0.3,0.3,0.4c-0.3,0-0.6,0.1-0.9,0.1c0,0-0.1,0.1-0.1,0.1c-1.8,0.6-3.6,1.2-5.4,1.8c2.3-0.3,4.8-0.4,7-0.9
		c0.2,0.2,0.6,0.1,0.7-0.2c0.7-0.2,1.4-0.5,2.1-0.8c0.1-0.1,0.1-0.2,0.1-0.2c3.3-1.9,7.9-1.9,11.5-2.6c6.4-1.2,12.8-2.2,19.3-3.3
		c12.5-2.2,25.5-2.5,37.8-5.8c0.1,0,0.2-0.1,0.3-0.2c3.1,0.2,6.3-0.1,9.4-0.7c1.8-0.3,3.5-0.7,5.3-1.2c0.9-0.3,2.1-0.6,2.9-1.3
		c1.6-0.3,3.3-0.9,4.7-1.1c3.8-0.6,8.4,1.6,11.9,2.8c8.5,2.9,17.1,5.3,25.5,8.4c8.5,3.1,16.5,7.2,24.9,10.5c2.1,0.8,4.4,1.2,6.6,1.9
		c1.5,0.4,4,1.1,5.5,2.3c0.1,0,0.1,0.1,0.2,0.1c0.2,0.2,0.4,0.4,0.6,0.6C213.9,126.9,213.9,126.9,213.9,127z M88.9,159.5
		c-0.3,0-0.4,0.6,0,0.6c0.5,0.1,1,0.1,1.5,0.1c-1.3,0.2-2.6,0.6-3.9,0.8c-0.3,0.1-0.7,0.1-1,0.1c0,0-0.1-0.1-0.1-0.1
		c-1.8-0.2-3.4-0.2-5.1-0.7c-1.2-0.4-2.4-0.9-3.7-1c0-0.1-0.1-0.1-0.2-0.2c-0.7-0.3-1.4-0.6-2-0.9c0-1.3-0.4-2.6-0.7-3.9
		c2.6,1.3,5.1,2.7,7.5,4.4c0.3,0.2,0.6,0.1,0.8-0.1c1.1,0.5,2.4,0.7,3.7,0.8c2.4,0.1,6-0.1,8.2-1.3c2.1-0.2,4.2-0.5,6.3-0.9
		C96.4,158.3,92.8,159.3,88.9,159.5z M72.6,157.4c-0.1-0.7-0.3-1.3-0.5-2c-0.2-0.7-0.4-1.4-0.5-2.1c0.5,0.2,1,0.5,1.4,0.7
		c0,0,0,0.1,0,0.1c0.2,1.3,0.2,2.7,0.8,3.8C73.4,157.8,73,157.6,72.6,157.4z M70.7,156.5c0.1,0,0.2-0.1,0.2-0.2
		c0.1-0.7-0.4-1.3-0.6-2c-0.3-0.7-0.5-1.4-0.8-2.1c0.4,0.2,0.9,0.4,1.3,0.6c0.1,1.3,0.3,2.9,0.9,4C71.4,156.9,71,156.7,70.7,156.5z
		 M68.7,155.6c0-0.8-0.2-1.6-0.4-2.4c-0.1-0.5-0.2-1.1-0.3-1.6c0.3,0.2,0.6,0.3,0.9,0.5c0,0.1-0.1,0.1,0,0.2
		c0.2,0.8,0.4,1.6,0.7,2.4c0.2,0.6,0.4,1.3,0.9,1.8C69.8,156.1,69.3,155.9,68.7,155.6z M65.9,154.3c-0.2-0.6-0.6-1.2-0.8-1.9
		c-0.3-0.8-0.4-1.7-0.6-2.6c0.1,0,0.2,0.1,0.3,0.1c0.8,0.4,1.6,0.8,2.3,1.2c0.1,0.6,0.2,1.2,0.3,1.9c0.1,0.8,0.3,1.5,0.6,2.2
		C67.3,155,66.6,154.7,65.9,154.3z M63,153.2c-0.1-1.5-0.7-3.3-1.5-4.6c-0.1-0.2-0.4,0-0.4,0.2c0.2,0.9,0.5,1.7,0.8,2.6
		c0.1,0.5,0.3,1.1,0.5,1.6c-0.6-0.2-1.1-0.4-1.7-0.6c0,0,0,0,0,0c0-1.6-0.5-3.2-0.6-4.8c0-0.1,0-0.1,0-0.1c1.3,0.7,2.6,1.4,3.8,2
		c0.1,1.3,0.2,3,0.7,4.3C64.1,153.6,63.5,153.4,63,153.2z M59.3,152c0-1.8-0.4-3.6-0.8-5.4c0.4,0.2,0.8,0.4,1.2,0.6
		c-0.2,0-0.3,0.1-0.3,0.3c0,1.5,0,3.2,0.7,4.6C59.8,152.2,59.6,152.1,59.3,152z M52.5,149.8c-0.4-0.2-0.9-0.4-1.3-0.6c0,0,0,0,0,0
		c0-2.3-0.7-4.5-1-6.8c0.5,0.3,1.1,0.5,1.6,0.8c-0.1,1.8,0.3,3.9,1,5.4c0.1,0.2,0.4,0.2,0.4-0.1c0-1-0.2-2-0.4-3.1
		c-0.1-0.6-0.2-1.3-0.2-1.9c0.4,0.2,0.7,0.4,1.1,0.6c0.1,1.2,0.2,2.5,0.5,3.6c0,0.2,0.3,0.2,0.3,0c0.2-1,0.1-2.1-0.1-3.2
		c0.3,0.1,0.5,0.3,0.8,0.4c0.1,1.6,0.4,3.3,0.9,4.8c0.1,0.2,0.4,0.2,0.4,0c0.1-1.4-0.1-2.8-0.4-4.3c0.2,0.1,0.3,0.2,0.5,0.2
		c0,1.9,0.1,3.9,0.9,5.5c0.1,0.2,0.3,0.1,0.3-0.1c0.1-1.6-0.1-3.3-0.3-5c0.1,0.1,0.2,0.1,0.3,0.2c0.1,1.9,0.3,3.9,1,5.6
		C56.7,151.3,54.5,150.6,52.5,149.8z M43.6,145.1C43.7,145.1,43.7,145,43.6,145.1c0-1-0.3-1.9-0.5-2.7c-0.3-1.2-0.4-2.3-0.6-3.5
		c0.4,0.2,0.9,0.4,1.3,0.5c-0.2,0-0.4,0.1-0.3,0.4c0.2,1.8,0.2,3.9,1.1,5.6c0.1,0.2,0.4,0.1,0.4-0.1c0-1.8-0.5-3.7-0.7-5.4
		c0-0.1-0.1-0.2-0.1-0.2c1,0.4,2,0.9,3,1.3c0.8,0.3,1.5,0.7,2.3,1.1c0.1,2.4,0.6,4.8,1.4,7C48.4,147.8,46,146.5,43.6,145.1z
		 M36.4,141.2c-0.2-1.3-0.6-2.7-0.9-4c-0.1-0.5-0.1-0.9-0.2-1.4c0.4,0.2,0.8,0.3,1.2,0.5c0.1,1.7,0.4,3.5,1.3,4.8
		c0.1,0.2,0.4,0.1,0.4-0.1c-0.1-0.8-0.3-1.6-0.6-2.4c-0.2-0.6-0.2-1.3-0.3-1.9c0.4,0.2,0.8,0.3,1.2,0.5c0.1,1.6,0.3,3.4,1.2,4.6
		c0.1,0.1,0.3,0.1,0.3-0.1c0-0.8-0.2-1.6-0.4-2.4c-0.1-0.6-0.2-1.2-0.3-1.8c0.2,0.1,0.4,0.2,0.6,0.2c0.2,1.9,0.3,4,1.4,5.6
		c0.1,0.2,0.4,0,0.4-0.2c-0.3-1.7-0.6-3.4-0.9-5.1c0.4,0.1,0.7,0.3,1.1,0.4c0.1,1.8,0.2,4.4,1.2,6.1
		C40.8,143.5,38.7,142.3,36.4,141.2z M33.5,140.1c-1.5-0.5-3.1-1-4.7-1.6c-0.1-2-0.7-4.1-1.3-6c-0.1-0.4-0.6-0.2-0.6,0.2
		c0.5,1.8,0.7,3.7,1.1,5.6c-0.1,0-0.3-0.1-0.4-0.1c0,0,0-0.1,0-0.1c-0.2-2.4-0.9-4.9-1.6-7.2c1.2,0.8,2.4,1.5,3.7,2.3
		c0.3,0.2,0.6,0.3,0.9,0.5c0.1,1.9,0.9,4.1,1.9,5.6c0.1,0.2,0.3,0,0.3-0.1c-0.4-1.8-1.2-3.5-1.8-5.3c1.1,0.6,2.3,1.2,3.5,1.7
		c0.2,1.9,0.3,3.9,1.2,5.5C35.1,140.7,34.3,140.4,33.5,140.1z M26,137.5c0,0,0-0.1,0-0.1c-0.3-2.5-0.9-5-1.4-7.5c0,0,0,0,0-0.1
		c0.3,0.2,0.7,0.4,1,0.6c-0.1,0.1-0.1,0.1-0.1,0.2c0.6,2.4,0.8,5,1.8,7.3C26.9,137.9,26.4,137.7,26,137.5z M20.4,135.1
		c-1-0.6-2-1.2-2.9-2c0-2.4-0.5-4.9-1.3-7.1c-0.1-0.2-0.4-0.2-0.4,0.1c0.3,2.1,0.6,4.2,1,6.3c-0.2-0.2-0.4-0.4-0.5-0.6
		c0-0.1,0-0.1,0-0.2c-0.1-0.4-0.3-0.7-0.4-1.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.8-0.5-1.5-0.6-2.3
		c0.1-1.2,0.6-2.3,0.9-3.5c0,0,0.1-0.1,0.1-0.1c0.8,0.7,1.8,1.3,2.7,1.9c0,0.1,0,0.1,0,0.2c0.6,2.3,0.8,4.7,1.7,6.9
		c0.1,0.1,0.3,0.1,0.3-0.1c-0.3-2.2-0.7-4.4-1.3-6.6c0.5,0.3,1.1,0.7,1.6,1c0.4,2.7,0.7,5.6,1.7,8.1c0.1,0.2,0.4,0.2,0.4-0.1
		c-0.3-2.5-0.7-5-1.2-7.5c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4c0.1,2.3,0.5,4.7,0.9,7c0,0.2,0.3,0.3,0.4,0
		c0.3-2.1-0.1-4.4-0.6-6.6c0.3,0.2,0.6,0.4,0.9,0.6c-0.2,0-0.4,0.1-0.4,0.3c0.4,2.4,0.6,5.1,1.6,7.4C23.8,136.7,22.1,136,20.4,135.1
		z M22.7,117.8C22.7,117.8,22.7,117.8,22.7,117.8c-0.1,0.2-0.2,0.3-0.4,0.4c-0.9,0.4-1.7,0.6-2.6,0.8c-2,0.7-4.1,0.6-6-0.9
		c-0.1-0.1-0.1-0.1-0.1-0.2c0,0-0.1,0-0.1-0.1c-0.3-0.4-0.6-0.8-0.8-1.4c0-0.1-0.2-0.2-0.3-0.1c0,0,0-0.1,0.1-0.1
		c0.1-0.1,0-0.2-0.1-0.2c-0.1,0-0.2,0-0.2,0c-0.2-0.1-0.4-0.1-0.5,0.1c0,0,0,0,0,0c-0.3-2.7,0-5.5,0-8.2c0-3.5-0.8-7-1.3-10.4
		C9.3,90.7,8.9,83.8,8.4,77C8,72.1,7.6,67.2,7.1,62.3c-0.1-1-0.2-2.1-0.3-3.1c0-5.1-0.6-10.4-1.3-15.5c-0.1-1.6-0.2-3.2-0.4-4.7
		C4.9,35,4.6,31.1,4.2,27.2c-0.4-3-0.9-6.3-0.1-9.3c0.2,0.8,0.3,1.5,0.5,2.1c0.6,3.6,1.2,7.2,1.8,10.8c1.2,7.7,2.1,15.4,3.2,23.1
		c0,0.3,0.5,0.3,0.4,0c-0.3-7.2-1.5-14.4-2.7-21.5c-0.5-3.3-1.1-6.7-1.7-10C5.3,21,5,19.6,4.7,18.3c-0.1-0.5-0.3-1-0.4-1.6
		c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.7-0.1-1.4,0.1-2c0-0.1-0.2-0.2-0.2-0.1c-0.4,0.8-0.4,1.7-0.3,2.6
		c-1.1,2.4-0.9,5.4-0.8,8c0.2,2.8,0.5,5.7,0.8,8.5c-0.6-2.8-1.3-5.7-1.8-8.5c0-2.9,0.3-5.8,1.1-8.7C4.3,12,6.6,10,10.6,8.6
		C17.9,6,26.2,6.4,33.9,6.2C41,6.1,48.1,5.3,55.2,4.9c8.9-0.5,17.9-0.7,26.9-1C91,3.7,99.9,3.4,108.9,3c7.8-0.3,15.7-1.1,23.5-1.1
		c0,1,0.3,2.3,0.4,3.1c0.2,2.1,0.6,4.3,0.6,6.5c0.2,9.4,0.3,18.8,0.5,28.2c0.1,9.2,0.6,18.4,1.1,27.6c0.2,4.6,0.6,9.1,1.1,13.6
		c0.4,3.4,1.7,7.6,1.2,11.1c-0.4-0.1-0.9,0.1-0.9,0.6c-0.1,1.5-0.5,3-1.1,4.3c-0.2,0.1-0.4,0.2-0.6,0.3c-1-0.4-2.3-0.3-3.3-0.2
		c-1.5,0.2-3,0.6-4.5,0.8c-2,0.3-3.9,0.8-5.9,1.1c-1.9,0.3-3.9,0.4-5.9,0.7c-0.2,0-0.3,0.4,0,0.4c0.8,0.2,1.7,0.3,2.6,0.3
		c0.2-0.1,0.4-0.2,0.6-0.2c1,0,1.9,0,2.9-0.1c2.4-0.4,4.9-1,7.1-1.4c0.6-0.1,1.2-0.2,1.7-0.3c0.2,0,0.3-0.1,0.5-0.1
		c0.1,0,0.2,0,0.3,0c0.5-0.2,1-0.4,1.4-0.6c0.8-0.3,1.5,0.2,1.6,0.8c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.1
		c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0.1,0.4,0,0.8-0.5,1.1c-0.9,0.4-1.8,0.7-2.7,1c-0.3,0.1-0.6,0.2-0.9,0.3c-1.6,0.6-3.3,1-5,1.5
		c-0.3,0.1-0.6,0-0.8-0.1c-2,0.3-4,0.5-6,0.8c0-0.4-0.2-0.9-0.4-1.2c0,0,0,0,0,0c-0.4-0.2-0.5-0.6-0.5-0.9c-0.4-0.5-0.8-1-1.4-1.3
		c-0.3-0.1-0.6,0.2-0.4,0.5c0.4,0.6,0.9,1.2,1.2,1.9c0.1,0.3,0.2,0.7,0.3,1c-0.2-0.2-0.5-0.4-0.8-0.3c-6.4,1.8-13,2.6-19.6,3.4
		c-6.8,0.8-13.4,1.9-20.2,3c-6,1-12,2.1-18,3.2c-3.4,0.6-8.5,0.7-11.2,3.3c0,0,0,0,0,0c-0.5,0.1-0.9,0.1-1.4,0.2
		c-0.9-4.1-7.1-3.7-10.3-3.2c-3.5,0.6-7,1.8-10.4,2.8l-0.1,0c-0.2,0.3-0.5,0.7-0.8,1c0,0.1-0.1,0.1-0.1,0.2
		C23.4,117.7,23.1,117.8,22.7,117.8z M3.4,37c-0.4-2.4-0.8-4.7-1.1-7.1c0.7,3.8,1.6,7.5,2.1,11.3c0.1,0.9,0.2,1.8,0.3,2.7
		C4.3,41.6,3.8,39.3,3.4,37z M215.5,127.5c0-0.1,0-0.2,0-0.3c-0.1-0.4-0.4-0.6-0.7-0.7c-0.9-1.1-2.4-1.8-3.6-2.3
		c-2.5-1.1-5.3-1.7-7.9-2.5c-3.8-1-7.4-2.8-10.9-4.4c-4.5-2-8.9-4-13.5-5.8c-8.5-3.3-17.3-5.9-26-8.7c-4.1-1.3-8.1-3-12.4-3.5
		c-0.8-0.1-1.6,0-2.3,0.2c-0.3,0.2-0.6,0.4-1,0.6c-0.3,0.1-0.6,0.1-0.9-0.1c-0.2,0.1-0.5,0.1-0.7,0.2c0.1-0.2,0.2-0.5,0.2-0.7
		c0,0,0.1-0.1,0.1-0.1c-0.1-0.4,0-1,0.4-1.3c0-0.2,0.1-0.4,0.3-0.6c1.3-1.3,1.8-3.1,2-4.9c0,0,0,0,0,0c0.5-2.9-0.3-5.8-0.6-8.6
		c-0.6-4.5-1.1-9-1.3-13.5c-0.6-9.4-1-18.9-1.3-28.3c-0.3-9.1-0.4-18.3-0.6-27.4c-0.1-2.7-0.2-5.3-0.6-7.9c-0.2-1.2-0.3-2.3-0.5-3.5
		c-0.1-0.6-0.5-1.6-0.3-2.2c0-0.2-0.1-0.3-0.3-0.3c-0.1-0.1-0.3-0.2-0.5-0.2c-5.9,0-11.8,0.5-17.7,0.8c-7,0.4-14,0.6-21,0.8
		c-14,0.4-28,0.8-42,1.5C46.2,4,40.5,4.6,34.9,4.7C31.3,4.9,27.6,4.9,23.9,5C17.5,5.4,8.7,5.7,4,10.8C2,12.9,1.5,16.3,1,19
		c-0.8,4.5-0.5,9.1,0.1,13.6c0.6,4.3,1.6,8.5,2.4,12.8c1,5,1.5,10.1,2,15.2c1,9.7,1.7,19.3,2.7,29c0.5,4.9,1.2,9.7,2,14.5
		c0.7,4.5,0.1,9,1,13.5c0,0.2,0.2,0.2,0.3,0.1c0.3,0.5,0.9,0.9,1.3,1.2c0.9,1.2,2.3,1.6,4,1.7c0,0,0,0,0,0c1.8,0,3.7-0.5,5.3-1.1
		c0.5,0.1,0.9,0.3,1.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-2.2,0.6-5.1,1.6-6.9,3.2c-0.2,0-0.3,0-0.4,0.2c-0.7,0.9-1,1.9-1.1,2.9
		c-0.1,1.1,0,2.2,0.4,3.2c0.4,1.6,1.4,3.2,2.7,4.4c3.2,2.8,7.4,4.2,11.4,5.6c0,0.2,0.2,0.2,0.3,0.1c0.5,0.2,1.1,0.3,1.6,0.5
		c2.1,0.7,4.1,1.3,6.1,2.2c3.6,1.7,7.1,3.9,10.6,5.9c4.7,3.2,10.1,5.6,15.5,7.2c0.2,0.1,0.3-0.2,0.1-0.3c-1.8-1-3.7-1.8-5.6-2.6
		c1.8,0.5,3.5,1.1,5.3,1.6c4.7,1.5,8.7,4.8,13.6,5.8c0,0,0,0,0.1,0c2.3,0.8,5.4,2.3,7.9,1.9c1.5,0.2,3.2,0,4.7-0.4
		c2.4,0.3,4.9-0.5,7.3-1.3c2.6-0.6,5.2-1.3,7.8-1.9c6.6-1.7,13.1-3.4,19.7-4.9c4.2-1,8.6-1.7,12.8-2.7c6.7-1.6,13.4-3.4,20-5.1
		c5.5-1.4,11-2.4,16.5-3.6c9.6-2,19.1-4.1,28.5-6.9c3.6-1.1,7.3-2.3,10.7-4c1.1-0.6,3.1-1.6,3.6-3.1
		C215.6,127.9,215.6,127.7,215.5,127.5z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M21.5,121.7c1-0.4,2-0.6,2.9-0.8c-1.1,0.3-2.3,0.7-3.5,1C21.2,121.8,21.4,121.7,21.5,121.7"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M21.5,121.7c1-0.4,2-0.6,2.9-0.8c-1.1,0.3-2.3,0.7-3.5,1C21.2,121.8,21.4,121.7,21.5,121.7z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M125,113.7c1.7,0.8,3.5,1.7,5.4,2.6c-1.2,0.3-2.3,0.5-3.5,0.7c-0.9,0.1-1.8,0.3-2.7,0.4
		c-2.1-1-4.1-2.1-6.2-3.1c-1.7-0.9-3.5-1.8-5.3-2.6c0.5-0.1,1-0.2,1.6-0.3c1.3-0.3,2.7-0.5,4-0.7C120.6,111.5,122.8,112.6,125,113.7
		 M115.7,118.7c-0.7,0.1-1.4,0.3-2,0.4c-2.2-1-4.3-2.1-6.5-3.1c-1.5-0.7-3.1-1.5-4.6-2.3c3-0.6,6.1-1.3,9.1-1.9c2,1,4,2.1,6,3.1
		c1.6,0.9,3.3,1.8,4.9,2.6C120.3,117.9,118,118.2,115.7,118.7 M77.1,122.2c-2.1,0.5-4.2,1-6.3,1.6c-0.1,0-0.1,0-0.2,0
		c-1.1-0.5-2.2-1-3.4-1.3c-0.1,0-0.2,0.2-0.1,0.2c1,0.3,1.9,0.8,2.8,1.3c-2.2,0.6-4.3,1.3-6.5,1.7c-1.7-1.1-3.6-2-5.4-2.8
		c5.3-1.3,10.6-2.4,15.9-3.5c0,0,0,0.1,0,0.1C74.9,120.4,76,121.3,77.1,122.2 M139.4,105.3c-3.9,0.3-7.7,1.5-11.5,2.5
		c-3.1,0.8-6.2,1.3-9.3,1.8c-1.8-0.8-3.8-1.7-5.7-1.7c-0.1,0-0.1,0.1-0.1,0.2c1.2,0.8,2.6,1.4,4.1,1.9c-0.7,0.1-1.3,0.2-2,0.4
		c-1.1,0.2-2.2,0.5-3.3,0.7c-1.3-0.6-2.7-1.1-4-1.6c-0.2-0.1-0.3,0.2-0.1,0.3c1.1,0.5,2.1,1,3.2,1.5c-3.1,0.6-6.1,1.3-9.2,1.9
		c-0.8-0.3-1.6-0.6-2.5-0.6c-0.3,0-0.3,0.4,0,0.5c0.4,0.1,0.8,0.2,1.2,0.4c-3.7,0.8-7.4,1.5-11.1,2.2c-10.7,2-21.7,4-32.4,6.7
		c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.3,0.1-0.2,0.2c-6.5,1.7-12.9,3.7-19,6.2c-0.1,0,0,0.1,0,0.1c6.7-2.2,13.4-4,20.2-5.7
		c1.7,0.8,3.3,1.8,4.9,2.8c-0.5,0.1-1.1,0.2-1.6,0.2c-0.2,0-0.2,0.3,0,0.3c0.8,0,1.5,0,2.3-0.1c2.9,2,5.6,4.2,8.5,6.4
		c0.2,0.2,0.4-0.1,0.3-0.3c-2.1-2.5-4.7-4.5-7.4-6.3c1.8-0.3,3.5-0.8,5.3-1.3c0.4-0.1,0.9-0.2,1.3-0.4c3.9,2.3,7.2,5.7,11.2,7.8
		c0.1,0.1,0.3-0.1,0.2-0.3c-3.6-2.6-6.9-5.6-10.7-7.7c2.1-0.6,4.2-1.1,6.3-1.6c4.1,2.9,8.9,5,13.1,7.2c0.2,0.1,0.4-0.2,0.2-0.3
		c-2.4-1.8-5.1-3.2-7.8-4.6c-1.5-0.8-3-1.7-4.4-2.6c0.2-0.1,0.4-0.1,0.7-0.2c7.1-1.7,14.4-3,21.4-5.2c0.2-0.1,0.1-0.4-0.1-0.4
		c-6.7,1.2-13.4,2.9-20,4.4c-1,0.2-2,0.5-2.9,0.7c-1.3-0.8-2.5-1.7-3.7-2.6c4.7-1,9.3-1.9,14-2.7c4.3-0.8,8.6-1.7,12.9-2.6
		c0.6,0.3,1.1,0.6,1.7,1c1.6,1,3.4,1.7,5.1,2.6c1.2,0.6,2.4,1.3,3.6,1.9c-7.1,1.6-14.1,4-21.4,5.2c-0.2,0-0.2,0.3,0,0.3
		c4.1-0.7,8.1-1.4,12.2-2.3c3.4-0.8,6.9-1.7,10.4-2.5c0.7,0.4,1.5,0.7,2.2,1c-3.2,0.5-6.4,1.2-9.5,1.8c-5,1-9.8,2.4-14.6,4.2
		c-0.1,0-0.1,0.2,0.1,0.2c4.5-1.6,9.4-2.5,14.1-3.4c3.9-0.8,8-1.2,11.9-2.1c0.4,0.1,0.8,0.2,1.2,0.3c0.3,0.1,0.5-0.4,0.2-0.6
		c0,0-0.1,0-0.1-0.1c0.4-0.1,0.8-0.2,1.1-0.3c0.3-0.1,0.2-0.5-0.1-0.5c-0.7,0-1.5,0.1-2.2,0.2c-0.7-0.4-1.5-0.8-2.3-1.1
		c0.3-0.1,0.6-0.1,0.9-0.2c2.7-0.5,5.4-0.9,8.1-1.2c1.4,0.7,2.8,1.3,4.3,1.7c0.2,0.1,0.3-0.3,0.1-0.4c-0.9-0.5-1.9-1.1-2.9-1.6
		c0.7-0.1,1.4-0.2,2.2-0.3c1.4-0.2,2.9-0.5,4.4-0.8c1.8,0.7,3.6,1.3,5.5,1.6c0.3,0,0.5-0.4,0.2-0.5c-1.2-0.5-2.4-1-3.6-1.5
		c1.6-0.4,3.1-0.9,4.5-1.6c0.3-0.2,0.1-0.6-0.3-0.6c-2,0.4-4,1-6,1.5c-2.6-1-5.1-2.1-7.6-3.3c-1.6-0.8-3.2-1.5-4.8-2.2
		c2.2-0.4,4.5-0.7,6.7-1.2c4.3-1,8.5-2.1,12.8-3.2C139.8,105.8,139.7,105.3,139.4,105.3"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M125,113.7c1.7,0.8,3.5,1.7,5.4,2.6c-1.2,0.3-2.3,0.5-3.5,0.7c-0.9,0.1-1.8,0.3-2.7,0.4
		c-2.1-1-4.1-2.1-6.2-3.1c-1.7-0.9-3.5-1.8-5.3-2.6c0.5-0.1,1-0.2,1.6-0.3c1.3-0.3,2.7-0.5,4-0.7C120.6,111.5,122.8,112.6,125,113.7
		z M115.7,118.7c-0.7,0.1-1.4,0.3-2,0.4c-2.2-1-4.3-2.1-6.5-3.1c-1.5-0.7-3.1-1.5-4.6-2.3c3-0.6,6.1-1.3,9.1-1.9c2,1,4,2.1,6,3.1
		c1.6,0.9,3.3,1.8,4.9,2.6C120.3,117.9,118,118.2,115.7,118.7z M77.1,122.2c-2.1,0.5-4.2,1-6.3,1.6c-0.1,0-0.1,0-0.2,0
		c-1.1-0.5-2.2-1-3.4-1.3c-0.1,0-0.2,0.2-0.1,0.2c1,0.3,1.9,0.8,2.8,1.3c-2.2,0.6-4.3,1.3-6.5,1.7c-1.7-1.1-3.6-2-5.4-2.8
		c5.3-1.3,10.6-2.4,15.9-3.5c0,0,0,0.1,0,0.1C74.9,120.4,76,121.3,77.1,122.2z M139.4,105.3c-3.9,0.3-7.7,1.5-11.5,2.5
		c-3.1,0.8-6.2,1.3-9.3,1.8c-1.8-0.8-3.8-1.7-5.7-1.7c-0.1,0-0.1,0.1-0.1,0.2c1.2,0.8,2.6,1.4,4.1,1.9c-0.7,0.1-1.3,0.2-2,0.4
		c-1.1,0.2-2.2,0.5-3.3,0.7c-1.3-0.6-2.7-1.1-4-1.6c-0.2-0.1-0.3,0.2-0.1,0.3c1.1,0.5,2.1,1,3.2,1.5c-3.1,0.6-6.1,1.3-9.2,1.9
		c-0.8-0.3-1.6-0.6-2.5-0.6c-0.3,0-0.3,0.4,0,0.5c0.4,0.1,0.8,0.2,1.2,0.4c-3.7,0.8-7.4,1.5-11.1,2.2c-10.7,2-21.7,4-32.4,6.7
		c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.3,0.1-0.2,0.2c-6.5,1.7-12.9,3.7-19,6.2c-0.1,0,0,0.1,0,0.1c6.7-2.2,13.4-4,20.2-5.7
		c1.7,0.8,3.3,1.8,4.9,2.8c-0.5,0.1-1.1,0.2-1.6,0.2c-0.2,0-0.2,0.3,0,0.3c0.8,0,1.5,0,2.3-0.1c2.9,2,5.6,4.2,8.5,6.4
		c0.2,0.2,0.4-0.1,0.3-0.3c-2.1-2.5-4.7-4.5-7.4-6.3c1.8-0.3,3.5-0.8,5.3-1.3c0.4-0.1,0.9-0.2,1.3-0.4c3.9,2.3,7.2,5.7,11.2,7.8
		c0.1,0.1,0.3-0.1,0.2-0.3c-3.6-2.6-6.9-5.6-10.7-7.7c2.1-0.6,4.2-1.1,6.3-1.6c4.1,2.9,8.9,5,13.1,7.2c0.2,0.1,0.4-0.2,0.2-0.3
		c-2.4-1.8-5.1-3.2-7.8-4.6c-1.5-0.8-3-1.7-4.4-2.6c0.2-0.1,0.4-0.1,0.7-0.2c7.1-1.7,14.4-3,21.4-5.2c0.2-0.1,0.1-0.4-0.1-0.4
		c-6.7,1.2-13.4,2.9-20,4.4c-1,0.2-2,0.5-2.9,0.7c-1.3-0.8-2.5-1.7-3.7-2.6c4.7-1,9.3-1.9,14-2.7c4.3-0.8,8.6-1.7,12.9-2.6
		c0.6,0.3,1.1,0.6,1.7,1c1.6,1,3.4,1.7,5.1,2.6c1.2,0.6,2.4,1.3,3.6,1.9c-7.1,1.6-14.1,4-21.4,5.2c-0.2,0-0.2,0.3,0,0.3
		c4.1-0.7,8.1-1.4,12.2-2.3c3.4-0.8,6.9-1.7,10.4-2.5c0.7,0.4,1.5,0.7,2.2,1c-3.2,0.5-6.4,1.2-9.5,1.8c-5,1-9.8,2.4-14.6,4.2
		c-0.1,0-0.1,0.2,0.1,0.2c4.5-1.6,9.4-2.5,14.1-3.4c3.9-0.8,8-1.2,11.9-2.1c0.4,0.1,0.8,0.2,1.2,0.3c0.3,0.1,0.5-0.4,0.2-0.6
		c0,0-0.1,0-0.1-0.1c0.4-0.1,0.8-0.2,1.1-0.3c0.3-0.1,0.2-0.5-0.1-0.5c-0.7,0-1.5,0.1-2.2,0.2c-0.7-0.4-1.5-0.8-2.3-1.1
		c0.3-0.1,0.6-0.1,0.9-0.2c2.7-0.5,5.4-0.9,8.1-1.2c1.4,0.7,2.8,1.3,4.3,1.7c0.2,0.1,0.3-0.3,0.1-0.4c-0.9-0.5-1.9-1.1-2.9-1.6
		c0.7-0.1,1.4-0.2,2.2-0.3c1.4-0.2,2.9-0.5,4.4-0.8c1.8,0.7,3.6,1.3,5.5,1.6c0.3,0,0.5-0.4,0.2-0.5c-1.2-0.5-2.4-1-3.6-1.5
		c1.6-0.4,3.1-0.9,4.5-1.6c0.3-0.2,0.1-0.6-0.3-0.6c-2,0.4-4,1-6,1.5c-2.6-1-5.1-2.1-7.6-3.3c-1.6-0.8-3.2-1.5-4.8-2.2
		c2.2-0.4,4.5-0.7,6.7-1.2c4.3-1,8.5-2.1,12.8-3.2C139.8,105.8,139.7,105.3,139.4,105.3z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M139.1,139.5c-0.4-0.5-1.1-1-1.5-1.3c-0.6-0.4-1.2-0.8-1.9-1c1-0.2,2-0.4,2.9-0.6c3-0.6,6-1.3,9.2-1.5
		c0,0,0.1,0,0.1,0c0.7,0.4,1.5,0.7,2.3,1C146.4,137.2,142.8,138.4,139.1,139.5 M127.2,142.3c-0.8-0.4-1.9-0.4-2.7-0.6
		c-1.1-0.3-2.1-0.8-3.1-1.3c3.5-0.2,7-1.4,10.2-2.2c0.9-0.2,1.8-0.4,2.8-0.6c0,0,0,0.1,0.1,0.1c0.8,0.6,1.7,1,2.5,1.5
		c0.3,0.2,0.5,0.5,0.8,0.8C134.3,140.8,130.7,141.5,127.2,142.3 M119.8,139.6c-1.4-1.2-3.4-1.9-5-2.8c0.4-0.2,0.8-0.5,1.1-0.8
		c0.4,0,0.8,0.1,1.3,0.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.2,0.2,0.3,0.3,0.1c0.3-0.3,0.6-0.6,0.9-1c0.4,0,0.8,0,1.3,0
		c-0.5,0.5-0.9,1-1.3,1.6c-0.1,0.1,0.1,0.4,0.2,0.2c0.7-0.6,1.4-1.2,2.1-1.8c0.2,0,0.4,0,0.6,0c-0.5,0.6-1,1.2-1.4,1.8
		c-0.1,0.2,0.1,0.4,0.2,0.3c0.9-0.6,1.7-1.3,2.4-2.1c0.4,0,0.9,0,1.3,0c-0.2,0.3-0.5,0.6-0.7,1c-0.1,0.2,0.1,0.4,0.3,0.3
		c0.5-0.4,1-0.9,1.5-1.3c0.5,0,1-0.1,1.5-0.1c-0.2,0.3-0.4,0.6-0.6,1c-0.1,0.2,0.2,0.4,0.3,0.2c0.5-0.4,0.9-0.9,1.3-1.3
		c1.6-0.2,3.1-0.5,4.6-0.8c0.2,0,0.1-0.3-0.1-0.3c-0.6,0-1.2,0-1.7,0c0.5-0.4,0.9-0.8,1.4-1.3c0.7-0.1,1.5-0.1,2.2-0.2
		c-0.5,0.4-0.9,0.8-1.3,1.3c-0.1,0.1,0.1,0.4,0.2,0.3c0.7-0.5,1.4-1.1,2.1-1.7c0.1,0,0.3,0,0.4,0c0.2,0,0.2-0.3,0.1-0.3
		c0.1-0.1,0.3-0.3,0.4-0.4c0.4,0,0.7,0,1.1,0c-0.8,0.7-1.6,1.4-2.3,2.2c-0.1,0.1,0.1,0.3,0.2,0.2c1.1-0.7,2.1-1.6,3.1-2.5
		c0.6-0.1,1.3-0.1,1.9-0.3c0.2,0,0.2-0.4,0-0.4c-0.3-0.1-0.7-0.1-1-0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0-0.4-0.2-0.5
		c0-0.1-0.1-0.2-0.2-0.2c-0.3-0.1-0.6-0.1-0.9-0.2c0.1-0.2,0.3-0.3,0.4-0.5c0,0,0,0,0,0c0.4,0.2,0.9,0.3,1.3,0.5
		c2.4,1,4.6,2.4,6.8,3.7C136.8,135.2,128.5,138.5,119.8,139.6 M110.1,134.8c2.7-0.7,5.5-1.2,8.2-2c0,0,0,0,0,0c0,0-0.1,0-0.1,0
		c-0.7,0.4-1.4,1.2-2,1.8c-0.4,0.4-0.8,0.7-1.1,1.1h0c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.4,0.5-0.5,0.8
		C113.1,135.9,111.6,135.1,110.1,134.8 M118.6,132.7c0.3-0.1,0.7-0.2,1-0.3c0.5-0.1,1-0.3,1.5-0.5c-0.3,0.3-0.6,0.5-0.9,0.8
		c-1,0.9-2,1.8-2.7,2.9c-0.4,0-0.8,0-1.2,0c0.2-0.1,0.3-0.3,0.5-0.4c0.6-0.6,1.5-1.3,1.9-2c0-0.1,0.1-0.2,0-0.3c0.1,0.1,0,0.2,0.1,0
		c0-0.1,0-0.1,0-0.2C118.6,132.8,118.6,132.8,118.6,132.7 M128.6,128.8c-1.3,1.1-2.5,2.2-3.7,3.4c-0.3,0.3-0.6,0.5-0.9,0.8
		c-0.2,0-0.4,0-0.6,0c1.2-1.2,2.4-2.4,3.7-3.4c0.1-0.1,0-0.3-0.1-0.2c-1.5,1.2-3.1,2.4-4.6,3.7c-0.6,0-1.1,0.1-1.6,0.1
		c0.8-0.8,1.7-1.5,2.6-2.2C125,130.3,126.8,129.4,128.6,128.8 M131.7,128.2c-1.6,1-3.2,2.3-4.6,3.7c-0.3,0-0.6,0-0.9,0
		c1.1-1.1,2.2-2.2,3.3-3.3C130.2,128.4,130.9,128.3,131.7,128.2 M132.3,128.3c0.4,0.1,0.9,0.2,1.3,0.4c-0.6,0.4-1.2,0.9-1.8,1.4
		c-0.5,0-1.1,0-1.6,0c-0.1,0-0.1,0-0.2,0C130.8,129.4,131.5,128.9,132.3,128.3 M134.6,129c-0.4,0.4-0.8,0.7-1.2,1.1
		c-0.2,0-0.4,0-0.6,0c0.5-0.4,0.9-0.8,1.4-1.2C134.3,128.9,134.5,129,134.6,129 M132.9,131.6c0.1-0.2,0.3-0.3,0.5-0.5
		c0.7,0.1,1.4,0.1,2.1,0.2c0,0-0.1,0.1-0.1,0.1C134.5,131.4,133.7,131.5,132.9,131.6 M132.2,132.3c0.2,0,0.4,0,0.5,0
		c0.5,0,1.1,0,1.6,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.7,0-1.3-0.1-2-0.1C132.1,132.5,132.1,132.4,132.2,132.3 M131.9,130.9
		c0.2,0,0.4,0,0.7,0.1c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0-0.6,0-0.9,0.1C131.3,131.4,131.6,131.2,131.9,130.9 M129.8,131.7
		c-0.5,0-1,0-1.5,0.1c0.5-0.6,1-1.1,1.6-1.6c-0.1,0.2,0,0.6,0.3,0.6c0.2,0,0.4,0,0.6,0C130.5,131.2,130.2,131.4,129.8,131.7
		 M129.2,133.5c0.4,0,0.7,0,1.1-0.1c-0.4,0.4-0.7,0.8-1.1,1.3c-0.4,0-0.8,0-1.3,0.1C128.3,134.3,128.7,133.9,129.2,133.5
		 M127.5,132.7c0.1-0.1,0.2-0.2,0.3-0.3c0.4,0,0.9,0,1.3,0c-0.1,0.1-0.2,0.1-0.2,0.2C128.4,132.7,127.9,132.7,127.5,132.7
		 M126.7,133.6c0.4,0,0.8,0,1.2,0c-0.4,0.4-0.8,0.8-1.2,1.3c-0.4,0-0.8,0.1-1.2,0.1C125.9,134.5,126.3,134.1,126.7,133.6 M125.2,133
		c0.2-0.2,0.3-0.3,0.5-0.5c0.3,0,0.6,0,0.9,0c-0.1,0.1-0.3,0.3-0.4,0.4C125.8,132.9,125.5,132.9,125.2,133 M124.5,133.7
		c0.3,0,0.6,0,0.9,0c-0.4,0.5-0.8,1-1.2,1.5c-0.4,0-0.8,0.1-1.2,0.1C123.5,134.7,124,134.2,124.5,133.7 M119.9,135.5
		c-0.5,0-0.9,0.1-1.4,0.1c0.6-0.7,1.2-1.3,1.8-2c0.5,0,1,0,1.5,0C121.1,134.3,120.5,134.9,119.9,135.5 M122.8,133.7
		c0.2,0,0.3,0,0.5,0c-0.6,0.6-1.2,1.1-1.7,1.7c-0.2,0-0.4,0-0.6,0.1C121.6,134.8,122.2,134.3,122.8,133.7 M135.2,129.2
		c0.6,0.2,1.1,0.3,1.7,0.5c-0.2,0.2-0.4,0.4-0.5,0.6c-0.7-0.1-1.4-0.2-2.1-0.2C134.6,129.8,134.9,129.5,135.2,129.2 M152,135.9
		c-1.9-0.9-3.8-1.8-5.7-2.7c0,0,0,0-0.1,0c0,0,0,0,0,0c-2.4-1.6-5-2.8-7.7-3.9c-0.8-0.3-1.6-0.6-2.4-0.9c0,0,0.1-0.1,0.1-0.1
		c0.2-0.2-0.1-0.6-0.3-0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2-0.1-0.4-0.1-0.6-0.2c0-0.1-0.2-0.2-0.3-0.1c-0.5-0.1-1-0.3-1.5-0.3
		c0,0-0.1-0.1-0.2,0c0,0,0,0,0,0c-0.2,0-0.5-0.1-0.7-0.1c-0.1-0.2-0.3-0.4-0.5-0.3c-4.3,0.4-8,2.7-11.9,4.2
		c-3.9,1.5-8.4,1.3-11.9,3.5c-0.3,0.2-0.1,0.6,0.3,0.6c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0.1,0.1,0.1c1.9,0.8,3.7,1.5,5.5,2.5
		c1.7,0.9,3.7,2.6,5.6,2.8c0.2,0,0.4-0.1,0.5-0.3c0.1,0,0.2,0,0.3,0c0.8,1.1,2,1.7,3.3,2.2c1,0.4,2.7,1.1,3.7,0.6
		c3.6-0.1,7.4-1.3,10.8-2.2c4.3-1.2,8.6-2.6,12.9-3.8c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0.1,0,0.1,0C152.2,137,152.6,136.1,152,135.9"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M139.1,139.5c-0.4-0.5-1.1-1-1.5-1.3c-0.6-0.4-1.2-0.8-1.9-1c1-0.2,2-0.4,2.9-0.6c3-0.6,6-1.3,9.2-1.5
		c0,0,0.1,0,0.1,0c0.7,0.4,1.5,0.7,2.3,1C146.4,137.2,142.8,138.4,139.1,139.5z M127.2,142.3c-0.8-0.4-1.9-0.4-2.7-0.6
		c-1.1-0.3-2.1-0.8-3.1-1.3c3.5-0.2,7-1.4,10.2-2.2c0.9-0.2,1.8-0.4,2.8-0.6c0,0,0,0.1,0.1,0.1c0.8,0.6,1.7,1,2.5,1.5
		c0.3,0.2,0.5,0.5,0.8,0.8C134.3,140.8,130.7,141.5,127.2,142.3z M119.8,139.6c-1.4-1.2-3.4-1.9-5-2.8c0.4-0.2,0.8-0.5,1.1-0.8
		c0.4,0,0.8,0.1,1.3,0.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.2,0.2,0.3,0.3,0.1c0.3-0.3,0.6-0.6,0.9-1c0.4,0,0.8,0,1.3,0
		c-0.5,0.5-0.9,1-1.3,1.6c-0.1,0.1,0.1,0.4,0.2,0.2c0.7-0.6,1.4-1.2,2.1-1.8c0.2,0,0.4,0,0.6,0c-0.5,0.6-1,1.2-1.4,1.8
		c-0.1,0.2,0.1,0.4,0.2,0.3c0.9-0.6,1.7-1.3,2.4-2.1c0.4,0,0.9,0,1.3,0c-0.2,0.3-0.5,0.6-0.7,1c-0.1,0.2,0.1,0.4,0.3,0.3
		c0.5-0.4,1-0.9,1.5-1.3c0.5,0,1-0.1,1.5-0.1c-0.2,0.3-0.4,0.6-0.6,1c-0.1,0.2,0.2,0.4,0.3,0.2c0.5-0.4,0.9-0.9,1.3-1.3
		c1.6-0.2,3.1-0.5,4.6-0.8c0.2,0,0.1-0.3-0.1-0.3c-0.6,0-1.2,0-1.7,0c0.5-0.4,0.9-0.8,1.4-1.3c0.7-0.1,1.5-0.1,2.2-0.2
		c-0.5,0.4-0.9,0.8-1.3,1.3c-0.1,0.1,0.1,0.4,0.2,0.3c0.7-0.5,1.4-1.1,2.1-1.7c0.1,0,0.3,0,0.4,0c0.2,0,0.2-0.3,0.1-0.3
		c0.1-0.1,0.3-0.3,0.4-0.4c0.4,0,0.7,0,1.1,0c-0.8,0.7-1.6,1.4-2.3,2.2c-0.1,0.1,0.1,0.3,0.2,0.2c1.1-0.7,2.1-1.6,3.1-2.5
		c0.6-0.1,1.3-0.1,1.9-0.3c0.2,0,0.2-0.4,0-0.4c-0.3-0.1-0.7-0.1-1-0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0-0.4-0.2-0.5
		c0-0.1-0.1-0.2-0.2-0.2c-0.3-0.1-0.6-0.1-0.9-0.2c0.1-0.2,0.3-0.3,0.4-0.5c0,0,0,0,0,0c0.4,0.2,0.9,0.3,1.3,0.5
		c2.4,1,4.6,2.4,6.8,3.7C136.8,135.2,128.5,138.5,119.8,139.6z M110.1,134.8c2.7-0.7,5.5-1.2,8.2-2c0,0,0,0,0,0c0,0-0.1,0-0.1,0
		c-0.7,0.4-1.4,1.2-2,1.8c-0.4,0.4-0.8,0.7-1.1,1.1h0c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.4,0.5-0.5,0.8
		C113.1,135.9,111.6,135.1,110.1,134.8z M118.6,132.7c0.3-0.1,0.7-0.2,1-0.3c0.5-0.1,1-0.3,1.5-0.5c-0.3,0.3-0.6,0.5-0.9,0.8
		c-1,0.9-2,1.8-2.7,2.9c-0.4,0-0.8,0-1.2,0c0.2-0.1,0.3-0.3,0.5-0.4c0.6-0.6,1.5-1.3,1.9-2c0-0.1,0.1-0.2,0-0.3c0.1,0.1,0,0.2,0.1,0
		c0-0.1,0-0.1,0-0.2C118.6,132.8,118.6,132.8,118.6,132.7z M128.6,128.8c-1.3,1.1-2.5,2.2-3.7,3.4c-0.3,0.3-0.6,0.5-0.9,0.8
		c-0.2,0-0.4,0-0.6,0c1.2-1.2,2.4-2.4,3.7-3.4c0.1-0.1,0-0.3-0.1-0.2c-1.5,1.2-3.1,2.4-4.6,3.7c-0.6,0-1.1,0.1-1.6,0.1
		c0.8-0.8,1.7-1.5,2.6-2.2C125,130.3,126.8,129.4,128.6,128.8z M131.7,128.2c-1.6,1-3.2,2.3-4.6,3.7c-0.3,0-0.6,0-0.9,0
		c1.1-1.1,2.2-2.2,3.3-3.3C130.2,128.4,130.9,128.3,131.7,128.2z M132.3,128.3c0.4,0.1,0.9,0.2,1.3,0.4c-0.6,0.4-1.2,0.9-1.8,1.4
		c-0.5,0-1.1,0-1.6,0c-0.1,0-0.1,0-0.2,0C130.8,129.4,131.5,128.9,132.3,128.3z M134.6,129c-0.4,0.4-0.8,0.7-1.2,1.1
		c-0.2,0-0.4,0-0.6,0c0.5-0.4,0.9-0.8,1.4-1.2C134.3,128.9,134.5,129,134.6,129z M132.9,131.6c0.1-0.2,0.3-0.3,0.5-0.5
		c0.7,0.1,1.4,0.1,2.1,0.2c0,0-0.1,0.1-0.1,0.1C134.5,131.4,133.7,131.5,132.9,131.6z M132.2,132.3c0.2,0,0.4,0,0.5,0
		c0.5,0,1.1,0,1.6,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.7,0-1.3-0.1-2-0.1C132.1,132.5,132.1,132.4,132.2,132.3z M131.9,130.9
		c0.2,0,0.4,0,0.7,0.1c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0-0.6,0-0.9,0.1C131.3,131.4,131.6,131.2,131.9,130.9z M129.8,131.7
		c-0.5,0-1,0-1.5,0.1c0.5-0.6,1-1.1,1.6-1.6c-0.1,0.2,0,0.6,0.3,0.6c0.2,0,0.4,0,0.6,0C130.5,131.2,130.2,131.4,129.8,131.7z
		 M129.2,133.5c0.4,0,0.7,0,1.1-0.1c-0.4,0.4-0.7,0.8-1.1,1.3c-0.4,0-0.8,0-1.3,0.1C128.3,134.3,128.7,133.9,129.2,133.5z
		 M127.5,132.7c0.1-0.1,0.2-0.2,0.3-0.3c0.4,0,0.9,0,1.3,0c-0.1,0.1-0.2,0.1-0.2,0.2C128.4,132.7,127.9,132.7,127.5,132.7z
		 M126.7,133.6c0.4,0,0.8,0,1.2,0c-0.4,0.4-0.8,0.8-1.2,1.3c-0.4,0-0.8,0.1-1.2,0.1C125.9,134.5,126.3,134.1,126.7,133.6z
		 M125.2,133c0.2-0.2,0.3-0.3,0.5-0.5c0.3,0,0.6,0,0.9,0c-0.1,0.1-0.3,0.3-0.4,0.4C125.8,132.9,125.5,132.9,125.2,133z M124.5,133.7
		c0.3,0,0.6,0,0.9,0c-0.4,0.5-0.8,1-1.2,1.5c-0.4,0-0.8,0.1-1.2,0.1C123.5,134.7,124,134.2,124.5,133.7z M119.9,135.5
		c-0.5,0-0.9,0.1-1.4,0.1c0.6-0.7,1.2-1.3,1.8-2c0.5,0,1,0,1.5,0C121.1,134.3,120.5,134.9,119.9,135.5z M122.8,133.7
		c0.2,0,0.3,0,0.5,0c-0.6,0.6-1.2,1.1-1.7,1.7c-0.2,0-0.4,0-0.6,0.1C121.6,134.8,122.2,134.3,122.8,133.7z M135.2,129.2
		c0.6,0.2,1.1,0.3,1.7,0.5c-0.2,0.2-0.4,0.4-0.5,0.6c-0.7-0.1-1.4-0.2-2.1-0.2C134.6,129.8,134.9,129.5,135.2,129.2z M152,135.9
		c-1.9-0.9-3.8-1.8-5.7-2.7c0,0,0,0-0.1,0c0,0,0,0,0,0c-2.4-1.6-5-2.8-7.7-3.9c-0.8-0.3-1.6-0.6-2.4-0.9c0,0,0.1-0.1,0.1-0.1
		c0.2-0.2-0.1-0.6-0.3-0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2-0.1-0.4-0.1-0.6-0.2c0-0.1-0.2-0.2-0.3-0.1c-0.5-0.1-1-0.3-1.5-0.3
		c0,0-0.1-0.1-0.2,0c0,0,0,0,0,0c-0.2,0-0.5-0.1-0.7-0.1c-0.1-0.2-0.3-0.4-0.5-0.3c-4.3,0.4-8,2.7-11.9,4.2
		c-3.9,1.5-8.4,1.3-11.9,3.5c-0.3,0.2-0.1,0.6,0.3,0.6c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0.1,0.1,0.1c1.9,0.8,3.7,1.5,5.5,2.5
		c1.7,0.9,3.7,2.6,5.6,2.8c0.2,0,0.4-0.1,0.5-0.3c0.1,0,0.2,0,0.3,0c0.8,1.1,2,1.7,3.3,2.2c1,0.4,2.7,1.1,3.7,0.6
		c3.6-0.1,7.4-1.3,10.8-2.2c4.3-1.2,8.6-2.6,12.9-3.8c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0.1,0,0.1,0C152.2,137,152.6,136.1,152,135.9z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M17.4,126.2c-0.1-0.4-0.6-0.2-0.6,0.2c0.5,2.4,0.7,4.9,1.4,7.2c0.1,0.2,0.4,0.2,0.4,0
		C18.8,131.2,18.2,128.4,17.4,126.2"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M17.4,126.2c-0.1-0.4-0.6-0.2-0.6,0.2c0.5,2.4,0.7,4.9,1.4,7.2c0.1,0.2,0.4,0.2,0.4,0
		C18.8,131.2,18.2,128.4,17.4,126.2z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M47.5,144.4c-0.3-1-0.5-1.9-0.7-2.9c-0.1-0.3-0.5-0.2-0.5,0c-0.3,1.8,0.5,4.2,1.6,5.6c0.1,0.1,0.4,0.1,0.3-0.1
		C48.1,146,47.8,145.2,47.5,144.4"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M47.5,144.4c-0.3-1-0.5-1.9-0.7-2.9c-0.1-0.3-0.5-0.2-0.5,0c-0.3,1.8,0.5,4.2,1.6,5.6c0.1,0.1,0.4,0.1,0.3-0.1
		C48.1,146,47.8,145.2,47.5,144.4z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M75.9,156.5c0-0.3-0.5-0.3-0.5,0c0,0.5,0,1,0.3,1.4c0.1,0.1,0.3,0.1,0.3-0.1C75.9,157.4,75.9,157,75.9,156.5"
		/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M75.9,156.5c0-0.3-0.5-0.3-0.5,0c0,0.5,0,1,0.3,1.4c0.1,0.1,0.3,0.1,0.3-0.1C75.9,157.4,75.9,157,75.9,156.5z"
		/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M77.3,158.5c0,0.3,0.1,0.6,0.2,0.9c0,0.1,0.2,0.1,0.2,0c0.1-0.3,0.1-0.6,0.2-0.8
		C77.9,158.1,77.3,158.1,77.3,158.5"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M77.3,158.5c0,0.3,0.1,0.6,0.2,0.9c0,0.1,0.2,0.1,0.2,0c0.1-0.3,0.1-0.6,0.2-0.8
		C77.9,158.1,77.3,158.1,77.3,158.5z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M165.3,117c-3.4,0.8-6.9,1.2-10.4,1.8c-8.5,1.3-17.1,2.4-25.5,4c0-0.1-0.1-0.2-0.2-0.2
		c-3.6,0.1-7.2,1-10.7,1.8c-3.8,0.8-7.5,1.7-11.2,2.7c-2.6,0.6-5.2,1.3-7.8,2.2c-2.5,0.8-5.5,1.9-8.1,1.1c-0.2-0.1-0.2,0.2-0.1,0.3
		c0.8,0.5,1.8,0.7,2.8,0.6c-2,0.5-4,1.1-6,1.6c-9.3,2.6-18.2,5.8-27.7,7.1c0-0.3-0.2-0.6-0.5-0.6c-6.1,0-11.4-3.5-16.5-6.5
		c-3.8-2.3-7.8-4.2-11.7-6.3c-0.1-0.1-1.4-0.9-2.5-1.4c3.5-0.6,6.9-1.9,10.4-2.9c4.6-1.3,9.4-2.2,14.1-3c9.8-1.9,19.6-3.2,29.3-5.2
		c10-2,19.8-4.4,29.9-6.2c4.4-0.8,8.8-1.4,13.2-2.3c4.7-1,9.3-1.9,14.1-1.9c0.5,0,0.6-0.5,0.5-0.9c0.9,0.4,1.9,0.6,2.9,0.9
		c1.6,0.5,3.2,1.1,4.8,1.8c1.3,0.5,2.6,1.1,3.9,1.6c-0.6,0.1-1.2,0.2-1.8,0.4c-2-0.5-3.9-0.7-5.9-0.9c-0.3,0-0.4,0.4-0.1,0.4
		c1.5,0.2,2.9,0.5,4.3,0.9c-1.8,0.5-3.7,1.1-5.5,1.7c-1.5-0.8-3.1-1.3-4.9-1.4c-0.1,0-0.1,0.1,0,0.1c1.4,0.4,2.7,0.9,3.9,1.6
		c-1.2,0.4-2.3,0.8-3.5,1.2c-0.2,0.1-0.1,0.4,0.1,0.4c1.4-0.3,2.8-0.7,4.3-1.1c1.2,0.7,2.3,1.4,3.4,2.2c2.2,1.6,4.4,3.6,7,4.6
		c0.2,0.1,0.5-0.2,0.3-0.4c-2.2-1.9-4.8-3.7-7.2-5.3c-0.7-0.5-1.5-0.9-2.2-1.4c2-0.5,4-1.1,5.9-1.9c2.4,0.7,4.7,1.6,7,2.4
		c0.2,0.1,0.3-0.3,0.1-0.4c-1.8-1.1-3.7-1.9-5.8-2.5c0.3-0.1,0.7-0.3,1-0.4c0,0,0.1-0.1,0.1-0.1c1.3,0.6,2.7,1.1,4,1.7
		c5.1,2.1,11.3,2.4,15.7,5.8c0.1,0,0.1,0.1,0.2,0.1C170.4,116.1,167.4,116.5,165.3,117 M174.2,113.5c-0.3,0.3-0.6,0.6-0.9,0.9
		c0-0.1,0-0.3-0.2-0.4c-4.4-3.3-10.5-3.8-15.6-5.7c-3-1.1-5.9-2.5-8.8-3.6c-2.3-0.9-5.9-2.8-8.4-2.4c-0.2,0-0.2,0.2-0.2,0.3
		c-3.9-0.4-7.8,0.4-11.7,1.2c-4.7,1-9.4,1.7-14.2,2.5c-9.8,1.7-19.5,4-29.3,6.1c-10,2.1-20.1,3.6-30.2,5.5c-4.8,0.9-9.5,1.9-14.2,3
		c-4.7,1.1-9.3,2.9-14,3.9c-0.4,0.1-0.3,0.7,0.1,0.7c0.3,0,0.7-0.1,1-0.1c0.3,0.3,0.7,0.5,1,0.6c1.7,1.1,3.4,2.1,5.2,3.1
		c3.5,1.9,7,3.7,10.4,5.7c2.7,1.6,5.5,3.2,8.4,4.4c1.4,0.6,3,1.2,4.5,1.4c0,0.1,0.1,0.2,0.2,0.2c10.3,0.6,20.5-3.9,30.3-6.6
		c5.7-1.6,11.4-2.9,17.1-4.3c5.9-1.5,11.8-3,17.8-4.3c11.5-2.6,23.1-3.9,34.7-5.7c3.3-0.5,6.7-1,10-1.8c2.6-0.6,6-1.4,7.9-3.5
		C175.6,113.7,174.7,112.9,174.2,113.5"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M165.3,117c-3.4,0.8-6.9,1.2-10.4,1.8c-8.5,1.3-17.1,2.4-25.5,4c0-0.1-0.1-0.2-0.2-0.2
		c-3.6,0.1-7.2,1-10.7,1.8c-3.8,0.8-7.5,1.7-11.2,2.7c-2.6,0.6-5.2,1.3-7.8,2.2c-2.5,0.8-5.5,1.9-8.1,1.1c-0.2-0.1-0.2,0.2-0.1,0.3
		c0.8,0.5,1.8,0.7,2.8,0.6c-2,0.5-4,1.1-6,1.6c-9.3,2.6-18.2,5.8-27.7,7.1c0-0.3-0.2-0.6-0.5-0.6c-6.1,0-11.4-3.5-16.5-6.5
		c-3.8-2.3-7.8-4.2-11.7-6.3c-0.1-0.1-1.4-0.9-2.5-1.4c3.5-0.6,6.9-1.9,10.4-2.9c4.6-1.3,9.4-2.2,14.1-3c9.8-1.9,19.6-3.2,29.3-5.2
		c10-2,19.8-4.4,29.9-6.2c4.4-0.8,8.8-1.4,13.2-2.3c4.7-1,9.3-1.9,14.1-1.9c0.5,0,0.6-0.5,0.5-0.9c0.9,0.4,1.9,0.6,2.9,0.9
		c1.6,0.5,3.2,1.1,4.8,1.8c1.3,0.5,2.6,1.1,3.9,1.6c-0.6,0.1-1.2,0.2-1.8,0.4c-2-0.5-3.9-0.7-5.9-0.9c-0.3,0-0.4,0.4-0.1,0.4
		c1.5,0.2,2.9,0.5,4.3,0.9c-1.8,0.5-3.7,1.1-5.5,1.7c-1.5-0.8-3.1-1.3-4.9-1.4c-0.1,0-0.1,0.1,0,0.1c1.4,0.4,2.7,0.9,3.9,1.6
		c-1.2,0.4-2.3,0.8-3.5,1.2c-0.2,0.1-0.1,0.4,0.1,0.4c1.4-0.3,2.8-0.7,4.3-1.1c1.2,0.7,2.3,1.4,3.4,2.2c2.2,1.6,4.4,3.6,7,4.6
		c0.2,0.1,0.5-0.2,0.3-0.4c-2.2-1.9-4.8-3.7-7.2-5.3c-0.7-0.5-1.5-0.9-2.2-1.4c2-0.5,4-1.1,5.9-1.9c2.4,0.7,4.7,1.6,7,2.4
		c0.2,0.1,0.3-0.3,0.1-0.4c-1.8-1.1-3.7-1.9-5.8-2.5c0.3-0.1,0.7-0.3,1-0.4c0,0,0.1-0.1,0.1-0.1c1.3,0.6,2.7,1.1,4,1.7
		c5.1,2.1,11.3,2.4,15.7,5.8c0.1,0,0.1,0.1,0.2,0.1C170.4,116.1,167.4,116.5,165.3,117z M174.2,113.5c-0.3,0.3-0.6,0.6-0.9,0.9
		c0-0.1,0-0.3-0.2-0.4c-4.4-3.3-10.5-3.8-15.6-5.7c-3-1.1-5.9-2.5-8.8-3.6c-2.3-0.9-5.9-2.8-8.4-2.4c-0.2,0-0.2,0.2-0.2,0.3
		c-3.9-0.4-7.8,0.4-11.7,1.2c-4.7,1-9.4,1.7-14.2,2.5c-9.8,1.7-19.5,4-29.3,6.1c-10,2.1-20.1,3.6-30.2,5.5c-4.8,0.9-9.5,1.9-14.2,3
		c-4.7,1.1-9.3,2.9-14,3.9c-0.4,0.1-0.3,0.7,0.1,0.7c0.3,0,0.7-0.1,1-0.1c0.3,0.3,0.7,0.5,1,0.6c1.7,1.1,3.4,2.1,5.2,3.1
		c3.5,1.9,7,3.7,10.4,5.7c2.7,1.6,5.5,3.2,8.4,4.4c1.4,0.6,3,1.2,4.5,1.4c0,0.1,0.1,0.2,0.2,0.2c10.3,0.6,20.5-3.9,30.3-6.6
		c5.7-1.6,11.4-2.9,17.1-4.3c5.9-1.5,11.8-3,17.8-4.3c11.5-2.6,23.1-3.9,34.7-5.7c3.3-0.5,6.7-1,10-1.8c2.6-0.6,6-1.4,7.9-3.5
		C175.6,113.7,174.7,112.9,174.2,113.5z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M149.2,115.4c-2.6,0.2-5.2,0.5-7.8,0.5c-0.5,0-0.4,0.7,0,0.7c2.6,0,5.3-0.1,7.9-0.8
		C149.5,115.8,149.4,115.4,149.2,115.4"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M149.2,115.4c-2.6,0.2-5.2,0.5-7.8,0.5c-0.5,0-0.4,0.7,0,0.7c2.6,0,5.3-0.1,7.9-0.8
		C149.5,115.8,149.4,115.4,149.2,115.4z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M94.6,59.5C94.6,59.5,94.6,59.5,94.6,59.5C85.7,66,78,74.4,70.3,82.3c-0.1,0.1,0.1,0.3,0.2,0.2
		c8.1-7.6,16-15.3,24.4-22.5C95.2,59.7,94.9,59.3,94.6,59.5"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M94.6,59.5C94.6,59.5,94.6,59.5,94.6,59.5C85.7,66,78,74.4,70.3,82.3c-0.1,0.1,0.1,0.3,0.2,0.2
		c8.1-7.6,16-15.3,24.4-22.5C95.2,59.7,94.9,59.3,94.6,59.5z"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10}} d="M98.7,65.1C91,72.7,82.2,79.1,74.1,86.2c-0.1,0.1,0,0.2,0.1,0.1C82.4,79.4,91.1,72.8,98.7,65.1
		C98.8,65.2,98.7,65.1,98.7,65.1"/>
	<path style={{fill: "#CF782F", stroke: "#CF782F", strokeMiterlimit: 10, strokeWidth: 0.25}} d="M98.7,65.1C91,72.7,82.2,79.1,74.1,86.2c-0.1,0.1,0,0.2,0.1,0.1C82.4,79.4,91.1,72.8,98.7,65.1
		C98.8,65.2,98.7,65.1,98.7,65.1z"/>
</g>
</svg>

);

export default SvgTelemaintenance;
