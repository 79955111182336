import React from "react";

const ConsoSuivi = (props) => {
    return (
        <svg id="Calque_1" viewBox="0 0 73.7 67" {...props}>
            <g>
                <path fill={props.fill} d="M73.5,61.6c-1.9-2.2-4.9-3-7.5-3.8c-0.7-0.2-1.3,0.7-0.7,1.2c0.1,0.1,0.1,0.1,0.2,0.2c0,0.2-0.1,0.5-0.1,0.7
		c-0.8,0-1.7,0-2.5,0c0.2-0.7,0.1-1.4,0.1-2.2c0-1.4,0-2.8,0-4.2c0-2.7-0.1-5.4-0.1-8.1c0.1-0.1,0.1-0.1,0.2-0.2
		c0.1,0,0-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.1c0-0.6,0-1.2,0-1.9c-0.1-3.3-0.2-6.7-0.3-10c0-1.4-0.1-2.9-0.2-4.3
		c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0-0.3-0.1-0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1,0-0.2,0-0.3c-0.1-1.2-0.1-2.6-0.8-3.5
		c0-0.1-0.2-0.1-0.2,0c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1-0.1-0.3-0.2-0.5-0.2c-1.9,0-3.9,0.1-5.8,0.1c-1.6,0-3.8-0.1-5.5-0.7
		c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2-0.1-0.4-0.2-0.5-0.2c0,0-0.1,0,0,0c0.1,0.2,0.2,0.3,0.3,0.4
		c-2,2.2-1.6,5.8-1.3,8.5c0.4,3.5,0.9,6.8,0.9,10.3c0,3.3,0.1,6.5,0,9.8c0,2.7-0.5,5.3-0.6,7.9c-1.2,0-2.5,0.1-3.7,0.1
		c0.3-0.1,0.5-0.4,0.5-0.8c-0.1-4-0.6-8-1-11.9c-0.4-3.8-0.6-7.6-1.1-11.4c0,0,0,0,0,0c1.1-0.6,0.1-2.1-0.9-1.6c-1.9,0.9-4,1-6.1,1
		c-1.3,0-3.3,0-4.5-0.5c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0.1-0.2,0.1-0.2,0.3c-0.3,5.2-0.3,10.3-0.3,15.5
		c0,0.7-0.2,6.6,0.6,8.9c-2.1-0.1-4.2-0.1-6.4-0.1c1.1-1.1,0.8-3.9,0.8-5.1c0-2.4-0.1-4.8-0.3-7.1c0,0,0,0,0-0.1
		c0-0.2,0-0.5-0.1-0.7c0,0,0,0,0.1,0c0.9-0.4,0.5-1.4-0.3-1.7c-0.1-0.2-0.3-0.2-0.4,0c-0.1,0-0.2,0-0.3,0.1
		c-1.7,0.8-3.5,0.3-5.2,0.8c-0.5,0.1-1.3,0-1.8,0.3c0,0,0-0.1,0-0.1c0.2-0.6-0.7-0.9-1-0.4c-2.3,4.1-1.7,9.5-1.3,13.9
		c0,0.1,0,0.2,0.1,0.3c-2,0-4-0.1-6,0c-0.9,0-2.1-0.2-3.1,0c1.1-3.3,1.1-7,0.8-10.5c0-0.5-0.1-1-0.1-1.5c0.1,0.1,0.3,0.2,0.5,0.1
		c2.6-0.6,5.1-2.4,7.3-3.8c2.6-1.6,5.2-3.2,7.8-4.8c5.4-3.4,10.6-7,15.8-10.7C44.9,24.6,52,19.3,59,13.9c0.3,0.2,0.5,0.5,0.7,0.7
		c0.4,0.4,0.7,0.9,1.1,1.3c0.5,0.5,1.4-0.2,1-0.8c0,0,0-0.1-0.1-0.1c0.4,0.2,0.8,0.1,1.1-0.4c0.7-1.1,1.3-2.3,1.9-3.4
		c0.6-1,1.2-1.9,1.9-2.8C67,8.3,67.1,8,67,7.7c0.2-0.2,0.4-0.3,0.6-0.5c0.5-0.4,0.4-0.9,0-1.2c0.2-0.1,0.3-0.3,0.5-0.4
		c0.6-0.5,0.3-1.6-0.6-1.3c-1.3,0.4-2.7,1.2-4,2.1c-0.5,0.2-1,0.5-1.4,0.8c-1.9,1-3.9,2-5.9,2.6c-0.4,0.1-0.6,0.4-0.6,0.7
		c-0.1,0-0.1,0.2,0,0.2c0,0,0,0,0,0c0,0.4,0.3,0.8,0.9,0.8c0.1,0,0.2,0,0.3,0c0.2,0.2,0.4,0.3,0.6,0.5c-0.1,0.2-0.1,0.4,0.1,0.7
		C50.5,18,43.3,23.2,36,28.2c-5,3.5-10.1,6.8-15.2,10.2c-2.5,1.7-5.1,3.3-7.7,5c-2.2,1.4-5,2.7-6.8,4.7c0,0,0,0,0,0.1
		c-0.3-3.9-0.9-7.8-1-11.7C5.1,30.8,6,24.6,5.6,18.8c0-0.2,0-0.4,0-0.6c0-1.6,0.1-4-0.4-6c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.4,0.6-0.8
		c0-0.1-0.1-0.1-0.1-0.2c0,0.1,0.1,0.2,0.1,0.3c0.3,0.5,1.2,0.3,1.1-0.3C6.8,7.3,5.4,3.9,4.1,0.4c-0.2-0.6-1-0.6-1.2,0
		C2.7,0.8,2.6,1.1,2.5,1.5c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-1,2.9-2.2,6.1-2.1,9.2c-0.5,0.2-0.5,1.1,0.1,1.2c0.8,0.2,1.6,0.2,2.4,0.2
		c2.1,7.6,0.4,16,0.5,23.7c0,2.4,0.2,4.8,0.5,7.2c0,0.5,0,1,0,1.5c0.1,4.9,0,9.8,0.1,14.7c-0.1,0.5-0.3,0.9-0.5,1.3
		c-0.5,1.2,1,2.2,1.7,1.3c0.8,0.4,2,0.5,2.8,0.7c2.3,0.4,4.7,0.4,7.1,0.4c5.3,0.1,10.7,0,16,0.1c7,0.2,13.9,0.8,20.9,0.2
		c4.4-0.4,8.9-1.2,13.3-1.1c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0c-0.1,0.2-0.1,0.3,0,0.4c-0.1,0.5-0.1,1.1-0.2,1.6
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c-0.3,0.2-0.4,0.6-0.2,0.9c-0.1,0.6,0.7,0.8,1,0.2c0,0,0-0.1,0.1-0.1
		c0.1,0,0.1,0,0.2-0.1c0.2,0.2,0.5,0.3,0.8,0.2c1.1-0.6,2.2-1.3,3.1-2.1c0.8-0.3,1.5-0.7,2.2-1.2c0.7,0.1,1.2-0.2,1.4-0.6
		C73.7,62.6,73.9,62,73.5,61.6z M62.2,12.2c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0-0.1,0-0.2c0.1-0.2,0.3-0.4,0.4-0.5
		c0.2-0.2,0.4-0.3,0.7-0.5C62.7,11.4,62.4,11.8,62.2,12.2z M58.1,11.4c0,0,0,0.1,0,0.1c-0.1,0-0.1-0.1-0.2-0.1
		C57.9,11.4,58,11.4,58.1,11.4z M21.1,60.7c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.4-0.1-0.3c-0.1,0-0.1,0-0.2,0.1c1-1.1,2-2.1,2.6-3.4
		c0,0.3,0,0.5,0,0.8c0,0.5,0,1.8-0.3,2.3c-0.1,0.2-0.3,0.4-0.4,0.5C22.3,60.7,21.7,60.7,21.1,60.7z M21.3,48.4
		c-0.8,1.6-2.1,3-3.1,4.3c-0.8,1-2.1,2.6-1.3,3.9c0.1,0.2,0.4,0.1,0.4-0.1c0.2-3.2,3.3-5.2,4.3-8.1c0.8,0,1.5-0.2,2.3-0.4
		c0,0.5-0.1,1.1-0.1,1.6c-2.2,3.1-4.4,6.2-6.2,9.6c0,0.1-0.1,0.2,0,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.1
		c0.5-0.1,0.5-0.9,0.7-1.3c0.1-0.1,0-0.2-0.1-0.3c0.6-1,1.2-2,1.8-3.1c1-1.7,2.2-3.4,3.3-5.1c-0.1,1.3-0.1,2.5-0.1,3.8
		c0,0.8,0,1.6-0.1,2.4c-0.9,1.6-2.4,3-3.7,4.4c-1.2,0-2.3,0-3.5,0.1c0-0.1,0-0.2,0-0.3c-0.2-2.3-0.5-4.9-0.5-7.4
		c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.3-0.2,0.1-0.3c-0.3-0.2-0.2,0-0.1-0.2c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.6,0.4-1.1,0.7-1.6
		c0.5-0.8,1.1-1.7,1.9-2.4c0.2,0,0.4,0,0.6,0.1C20.2,48.4,20.8,48.4,21.3,48.4z M16.6,47.8c0.2,0.1,0.4,0.2,0.7,0.3
		c0.4,0,0.8,0.1,1.2,0.1c-1,0.8-2,1.9-2.5,3.1C16.1,50.1,16.2,48.9,16.6,47.8z M41.2,56.5c-0.6,2-1.9,3.4-3.4,4.7
		c-0.6,0-1.1-0.1-1.7-0.1c1.7-2.3,2.6-5.2,5.2-6.9c0.2-0.1,0-0.4-0.2-0.3c-2.9,1.3-4.5,4.4-6.1,7.1c-0.3,0-0.6,0-1-0.1
		c2.2-3.5,5.1-6.8,7.9-9.8c0.2,2.4,0.4,4.8,0.6,7.2c-0.2,0.6-0.5,1.2-0.7,1.8c-0.1,0.4-0.4,0.8-0.6,1.1c-0.8,0-1.7-0.1-2.5-0.1
		C40.1,59.9,41.2,58.4,41.2,56.5C41.3,56.4,41.2,56.4,41.2,56.5z M32.1,60.3c0-0.3,0-0.5,0-0.8c0.3-0.2,0.4-0.9,0.6-1.3
		c0-0.1,0-0.1,0-0.2c0.2-0.3,0.3-0.6,0.5-0.9c0.9-1.7,2.1-3.2,3.2-4.7c1.7-2.3,3.6-4.5,5.3-6.8c0,0.8,0.1,1.5,0.2,2.3
		c0.1,1,0.1,1.9,0.2,2.9c-2.1,1.9-3.9,4.1-5.7,6.3c-0.9,1.1-2.2,2.4-2.8,3.8c-0.4,0-0.8,0-1.3-0.1C32.1,60.7,32.1,60.4,32.1,60.3z
		 M31.2,37.1c0.8,0.6,1.7,0.8,2.7,1c-1,1.3-1.8,2.6-2.4,4.1C31.3,40.5,31.3,38.8,31.2,37.1z M42.4,60.6c0.1-0.3,0.2-0.5,0.3-0.8
		c0,0.2,0,0.5,0.1,0.7c0,0.4,0.3,0.7,0.6,0.8c-0.4,0-0.8,0-1.1,0C42.2,61,42.3,60.8,42.4,60.6z M41.6,37.6c-0.2,2.5-0.2,4.9-0.1,7.4
		c-3.4,3.9-7.2,7.8-9.6,12.3c0-0.6-0.1-1.2-0.1-1.8c0.2,0.1,0.4,0.2,0.5-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.5,0.8-0.9,1.3-1.4
		c0.1-0.1,0-0.4-0.2-0.3c0,0-0.1,0-0.1,0.1c1.4-2.3,2.7-4.6,4.1-6.9c1.6-2.7,3-5.6,3.8-8.7c0-0.1-0.1-0.1-0.1,0
		c-2,6.3-6.8,11-9.4,16.9c0-0.6,0-1.2-0.1-1.7c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0-0.2-0.1-0.2c2.7-4.5,5-9.3,7.2-14.1
		C40.1,38.1,40.9,37.9,41.6,37.6z M38.9,38.2c-2.6,4.3-4.8,8.8-7.2,13.1c0-0.4,0-0.7,0-1.1c0-0.5,0-1.1,0-1.6c0.1,0,0.1,0,0.2-0.1
		c1.6-3.3,3.9-6.5,4.7-10.2C37.3,38.3,38.1,38.3,38.9,38.2z M36.2,38.3c-0.8,2.2-2,4.2-3.1,6.2c-0.4,0.8-1.2,1.8-1.5,2.8
		c0-1.1-0.1-2.3-0.1-3.4c0.6-2,1.7-4,2.9-5.7c0.4,0,0.8,0.1,1.2,0.1C35.7,38.3,36,38.3,36.2,38.3z M61.3,58.6c0,0.5,0,0.9,0.2,1.3
		c-0.3,0-0.7,0-1,0.1C60.7,59.6,61,59.1,61.3,58.6z M58.8,26.7c-1.8,1.8-3.2,3.9-4.4,6.3c-1.3,2.6-2.8,5.4-3.9,8.2
		c-0.1,0.4,0.4,0.5,0.6,0.2c1.5-2.3,2.4-5.1,3.5-7.6c1.2-2.5,2.6-5,4.4-7.1c0.5,0,0.9,0,1.4,0c-0.3,1.1-0.3,2.5-0.2,3.8
		c-1.8,1.7-3.5,3.6-5,5.6c-1.6,2-4.2,5.3-3.8,8.1c0,0.2,0.3,0.1,0.3,0c0.1-2.9,2.2-5.6,3.8-7.8c1.4-1.9,2.9-3.8,4.7-5.4
		c0,0.7,0.1,1.4,0.1,2c0,1.1,0,2.2,0.1,3.4c-2,2.4-4,4.8-6,7.2c-1.1,1.3-2.1,2.8-3,4.3c-0.4,0.7-0.9,1.6-0.7,2.5
		c0,0.2,0.3,0.1,0.3,0c0-1.1,0.8-2.2,1.4-3.1c1-1.5,2.1-3,3.2-4.4c1.6-2.1,3.2-4.1,4.8-6.1c0.1,2.2,0.2,4.4,0.2,6.5
		c-1.6,2.2-3.2,4.4-5,6.5c-1.7,2.1-3.7,3.9-5.5,5.9c0,0,0,0,0-0.1c0.1-3.4,0.1-6.7,0-10.1c0-3.1-0.3-6.2-0.7-9.3
		c0-0.2-0.1-0.5-0.1-0.7c0.1,0,0.2-0.1,0.2-0.3c0.2-3.1,1.6-5.9,3.5-8.3c0,0,0.1,0,0.1,0C55,26.7,56.9,26.7,58.8,26.7z M49.1,25.1
		c0.5,0.4,1,0.7,1.5,0.9c-1.1,1.5-1.9,3.7-1.6,5.6c0,0.3,0.5,0.4,0.5,0.1c0.3-2,0.3-3.8,1.5-5.5c0.5,0.2,1.1,0.3,1.7,0.3
		c-1.7,2-3.1,4.3-3.6,6.9C48.8,30.6,48.5,27.8,49.1,25.1z M52.7,60.9c0-0.1-0.1-0.1-0.1-0.1c2.2-3.4,5.5-5.9,8.1-9
		c0.1-0.2-0.1-0.4-0.2-0.2c-3,3.2-6.6,5.7-8.9,9.5c-0.7,0.1-1.4,0.1-2.1,0.2c0.8-1.4,0.8-3.6,0.8-5.3c0,0,0,0,0,0
		c2.2-1.6,4-4,5.8-6.2c1.7-2.1,3.3-4.3,4.7-6.6c0,0.1,0,0.2,0,0.3c0,1.2,0.1,2.4,0.1,3.5c-3,2.8-5.7,6.1-8.4,9.2
		c-0.8,0.9-1.9,2-2.2,3.2c-0.1,0.5,0.3,0.7,0.7,0.5c0.1-0.1,0.1-0.3,0-0.4c0.1,0,1.5-2.1,1.6-2.2c0.9-1.1,1.8-2.1,2.6-3.2
		c1.9-2.2,3.7-4.5,5.7-6.7c0.1,1.8,0.2,3.7,0.2,5.5c0.1,1.4,0.1,2.8,0.1,4.3c0,0.3,0,0.5,0,0.8c-0.5,0.7-1.1,1.4-1.8,2.1
		c-0.6,0.1-1.2,0.1-1.9,0.2c1.1-1.3,2.1-2.9,2.2-4.6c0-0.1-0.1-0.1-0.2,0c-0.5,1.7-1.6,3.4-2.9,4.7c-1,0.1-2,0.2-3,0.4
		C53.4,60.8,53,60.9,52.7,60.9z M66.2,59.8c0.1,0,0.1,0.1,0.2,0.1c-0.1,0-0.1,0-0.2,0C66.2,59.9,66.2,59.8,66.2,59.8z"/>
                <path fill={props.fill} d="M49.9,38c0,0.2,0.3,0.1,0.3,0c1.3-3.4,3.1-6.9,5.6-9.6c0.1-0.2-0.1-0.4-0.2-0.2c-1.6,1.6-2.9,3.7-4.1,5.7
		C50.9,35,49.6,36.7,49.9,38z"/>
            </g>
        </svg>

    )
}

export default ConsoSuivi;