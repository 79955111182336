import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useMediaQuery } from "react-responsive";

import Ajouter from "../../_svg/Ajouter";
import Carousel from "react-multi-carousel";

const ModalExample = (props) => {
  const { className, onClick, index, devices, handleSelect, setScroll, filtered } = props;

  const [modal, setModal] = useState(false);
  const [hover, setHover] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const toggle = () => {
    setModal(!modal);
    handleSelect(index, selected);
  };

  function handleClick(e) {
    e.preventDefault();
    if (window.scrollY > 0) {
      setScroll(window.scrollY);
    }
    onClick(index);
    setModal(true)
  }

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const deviceList = filtered ? devices.filter(el => el.code !== filtered.code) : devices

  return (
    <div style={{ width: "fit-content", position: "relative" }}>
      <div
        className="ajouter"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
      >
        <Ajouter width="2em" fill={hover ? "white" : "#4d4d4d"} />
      </div>
      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <ModalHeader toggle={toggle}>Sélectionner un téléphone</ModalHeader>
        <ModalBody>
          <div className="selector-container">
            {isMobile && (
              <div className="carousel-container">
                {deviceList.map((el, index) => (
                    <div className="item-carousel">
                      <div
                          className={`box`}
                          id={el.code}
                          onClick={() => setSelected(el)}
                          style={{
                            border:
                              selected && selected.name === el.name
                                ? "1px solid #CF7830"
                                : "1px solid lightgrey",
                              backgroundColor: selected && (selected.code === el.code) ? "rgba(207, 120, 48, 0.35)" : "white",
                          }}
                        >
                          <h5>{el.name}</h5>
                          <img
                            src={require(`../../_resources/image/telephone/${el.code}.png`)}
                            alt="telephone"
                          />
                        </div>
                    </div>
                ))}
              </div>
            )}
            {!isMobile && (
              <>
                <div className="column">
                  {deviceList.map((el, index) => {
                    if (index % 2 === 0)
                      return (
                        <div
                          className={`box ${el.code}`}
                          id={el.code}
                          onClick={() => setSelected(el)}
                          style={{
                            border:
                              selected && selected.name === el.name
                                ? "1px solid #CF7830"
                                : "1px solid lightgrey",
                                backgroundColor: selected && (selected.code === el.code) ? "rgba(207, 120, 48, 0.35)" : "white",
                          }}
                        >
                          <h5>{el.name}</h5>
                          <img
                            src={require(`../../_resources/image/telephone/${el.code}.png`)}
                            alt="telephone"
                          />
                        </div>
                      );
                  })}
                </div>
                <div className="column">
                  {deviceList.map((el, index) => {
                    if (index % 2 === 1)
                      return (
                        <div
                          className={`box ${el.code}`}
                          id={el.code}
                          onClick={() => setSelected(el)}
                          style={{
                            border:
                              selected && selected.name === el.name
                                ? "1px solid #CF7830"
                                : "1px solid lightgrey",
                                backgroundColor: selected && (selected.code === el.code) ? "rgba(207, 120, 48, 0.35)" : "white",
                          }}
                        >
                          <h5>{el.name}</h5>
                          <img
                            src={require(`../../_resources/image/telephone/${el.code}.png`)}
                            alt="telephone"
                          />
                        </div>
                      );
                  })}
                </div>
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={toggle}
            style={{ backgroundColor: "#CF7830", borderColor: "#CF7830" }}
          >
            Valider
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExample;
