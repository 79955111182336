import React from "react";

const LogoIntervention = (props) => {
    return (
        <svg version="1.1" viewBox="0 0 338.8 77.6">
            <g id="Calque_1">
                <g>
                    <path fill={"#BFBFBF"} d="M242.4,64.4c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.3,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-0.9,0.2h-2.3v-5.7h2.2
			c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.8c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4
			c-0.1,0.1-0.2,0.2-0.4,0.2c0.2,0.1,0.4,0.3,0.6,0.5S242.4,64.1,242.4,64.4z M241.5,61.9c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.3-0.1-0.5-0.1h-1.3v1.6h1.3c0.3,0,0.5-0.1,0.7-0.2C241.4,62.4,241.5,62.2,241.5,61.9z M241.6,64.3
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1h-1.4v1.7h1.4c0.2,0,0.4,0,0.5-0.1
			c0.1-0.1,0.2-0.2,0.3-0.3C241.5,64.7,241.6,64.5,241.6,64.3z"/>
                    <path fill={"#BFBFBF"} d="M247.3,60.3l-1.7,3.3V66h-0.9v-2.3l-1.7-3.3h0.9l1.2,2.5l1.2-2.5H247.3z" />
                    <path fill={"#BFBFBF"} d="M254,63.1v0.3c0,0.3,0,0.6,0,0.8c0,0.2-0.1,0.5-0.2,0.7s-0.2,0.4-0.4,0.6c-0.4,0.4-0.8,0.5-1.5,0.5h-2v-5.7h2
			c0.6,0,1.1,0.2,1.5,0.5c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0,0.5,0,0.8V63.1z M253.2,63.1c0-0.3,0-0.6,0-0.8
			s0-0.3-0.1-0.5s-0.1-0.3-0.2-0.4c-0.2-0.3-0.6-0.4-1-0.4h-1v4.1h1c0.4,0,0.7-0.1,1-0.4c0.1-0.1,0.2-0.3,0.3-0.6
			C253.1,64,253.2,63.6,253.2,63.1z"/>
                    <path fill={"#BFBFBF"} d="M256.2,66h-0.9v-5.7h0.9V66z" />
                    <path fill={"#BFBFBF"} d="M261.5,63.2c0,0.4,0,0.8,0,1c0,0.2-0.1,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.4,0.4-0.9,0.6-1.5,0.6
			s-1.1-0.2-1.5-0.6c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.6,0-1c0-0.3,0-0.6,0-0.8c0-0.2,0-0.4,0.1-0.6
			c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.4-0.4,0.9-0.6,1.5-0.6s1.1,0.2,1.5,0.6c0.2,0.2,0.3,0.4,0.4,0.6
			c0.1,0.2,0.1,0.4,0.1,0.7C261.5,62.4,261.5,62.7,261.5,63.2z M260.6,63.2c0-0.6,0-1-0.1-1.2c0-0.2-0.1-0.4-0.3-0.6
			c-0.1-0.1-0.2-0.2-0.4-0.3s-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1s-0.3,0.1-0.4,0.3c-0.1,0.2-0.2,0.3-0.3,0.6s-0.1,0.6-0.1,1.2
			c0,0.3,0,0.5,0,0.7c0,0.2,0,0.3,0.1,0.5s0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.1
			c0.3,0,0.6-0.1,0.8-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.3,0.1-0.5C260.6,63.8,260.6,63.5,260.6,63.2z"/>
                    <path fill={"#BFBFBF"} d="M266.8,62c0,0.3-0.1,0.6-0.2,0.9s-0.4,0.5-0.6,0.6s-0.6,0.2-1,0.2h-1.3V66h-0.9v-5.7h2.2c0.3,0,0.5,0,0.7,0.1
			c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.3,0.4,0.5C266.7,61.6,266.8,61.8,266.8,62z M265.9,62c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3
			h-1.2V63h1.2c0.3,0,0.6-0.1,0.7-0.3C265.8,62.6,265.9,62.3,265.9,62z"/>
                    <path fill={"#BFBFBF"} d="M271.3,61.1h-1.6V66h-0.9v-4.9h-1.6v-0.8h4V61.1z" />
                    <path fill={"#BFBFBF"} d="M276.5,66h-0.9l-0.4-1.1h-2.2l-0.4,1.1h-0.9l2.1-5.7h0.7L276.5,66z M274.9,64.1l-0.9-2.5l-0.9,2.5H274.9z" />
                    <path fill={"#BFBFBF"} d="M280.8,64.4c0,0.3,0,0.5-0.1,0.7s-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.5,0.1-0.8,0.1
			c-0.4,0-0.8-0.1-1.1-0.2s-0.6-0.3-0.9-0.6l0.6-0.6c0.1,0.1,0.3,0.2,0.4,0.3s0.3,0.1,0.5,0.2s0.4,0.1,0.5,0.1
			c0.4,0,0.7-0.1,0.9-0.2c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.4-0.1l-0.7-0.1
			c-0.5-0.1-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.6-0.4-1.1c0-0.3,0.1-0.6,0.2-0.9s0.4-0.4,0.7-0.6s0.6-0.2,1-0.2c0.4,0,0.7,0.1,1,0.2
			c0.3,0.1,0.5,0.3,0.8,0.5l-0.6,0.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0.1
			s-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.1,0.3,0.2s0.2,0.1,0.4,0.1l0.6,0.1
			c0.3,0,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.3C280.6,63.5,280.8,63.9,280.8,64.4z"/>
                    <path fill={"#BFBFBF"} d="M285.6,66H282v-5.7h3.6v0.8h-2.8v1.7h2.4v0.8h-2.4v1.7h2.8V66z" />
                </g>
                <g>
                    <path fill={"#1D1D1B"} d="M29,24.8c0,0.8-0.3,1.6-0.9,2.2c-0.6,0.6-1.3,0.9-2.2,0.9s-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2
			s0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9s1.6,0.3,2.2,0.9C28.7,23.2,29,23.9,29,24.8z M28.1,32.7v23.1h-4.3V32.7H28.1z"/>
                    <path fill={"#1D1D1B"} d="M33.8,32.7h4.1v3.2h0.2c0.7-1.1,1.7-2.1,3-2.8c1.4-0.8,2.8-1.1,4.3-1.1c2.8,0,5,0.8,6.5,2.4
			c1.5,1.6,2.3,3.9,2.3,6.9v14.5h-4.3V41.6c-0.1-3.8-2-5.6-5.7-5.6c-1.7,0-3.2,0.7-4.3,2.1c-1.2,1.4-1.7,3.1-1.7,5v12.8h-4.3V32.7z"
                    />
                    <path fill={"#1D1D1B"} d="M67.2,56.1c-1.9,0-3.4-0.6-4.7-1.7c-1.2-1.2-1.9-2.8-1.9-4.8V36.7h-4v-4h4v-7.1H65v7.1h5.6v4H65v11.5
			c0,1.5,0.3,2.6,0.9,3.1c0.6,0.5,1.3,0.8,2,0.8c0.3,0,0.7,0,1-0.1c0.3-0.1,0.6-0.2,0.9-0.3l1.4,3.9C70.1,55.9,68.7,56.1,67.2,56.1z
			"/>
                    <path fill={"#1D1D1B"} d="M84.9,56.5c-3.4,0-6.2-1.2-8.4-3.5c-2.2-2.3-3.3-5.3-3.3-8.8c0-3.5,1.1-6.4,3.2-8.8c2.1-2.3,4.9-3.5,8.2-3.5
			c3.4,0,6.1,1.1,8.2,3.3c2,2.2,3,5.3,3,9.3l0,0.5H77.6c0.1,2.3,0.8,4.1,2.3,5.5c1.4,1.4,3.2,2.1,5.2,2.1c2.8,0,4.9-1.4,6.5-4.1
			l3.9,1.9c-1,1.9-2.5,3.5-4.3,4.6C89.3,56,87.2,56.5,84.9,56.5z M78,41.5h13.2c-0.1-1.6-0.8-2.9-2-4c-1.2-1.1-2.8-1.6-4.7-1.6
			c-1.6,0-3,0.5-4.2,1.5C79.1,38.4,78.3,39.8,78,41.5z"/>
                    <path fill={"#1D1D1B"} d="M104.1,55.8h-4.3V32.7h4.1v3.8h0.2c0.4-1.2,1.3-2.3,2.7-3.1c1.4-0.9,2.7-1.3,4-1.3c1.3,0,2.3,0.2,3.2,0.6
			l-1.3,4.2c-0.5-0.2-1.4-0.3-2.5-0.3c-1.6,0-3.1,0.7-4.3,2c-1.2,1.3-1.8,2.9-1.8,4.6V55.8z"/>
                    <path fill={"#1D1D1B"} d="M128.5,55.8h-4.3l-9.3-23.1h4.7l6.8,17.9h0.1l6.9-17.9h4.6L128.5,55.8z" />
                    <path fill={"#1D1D1B"} d="M150.4,56.5c-3.4,0-6.2-1.2-8.4-3.5c-2.2-2.3-3.3-5.3-3.3-8.8c0-3.5,1.1-6.4,3.2-8.8c2.1-2.3,4.9-3.5,8.2-3.5
			c3.4,0,6.1,1.1,8.2,3.3c2,2.2,3,5.3,3,9.3l0,0.5h-18.1c0.1,2.3,0.8,4.1,2.3,5.5c1.4,1.4,3.2,2.1,5.2,2.1c2.8,0,4.9-1.4,6.5-4.1
			l3.9,1.9c-1,1.9-2.5,3.5-4.3,4.6C154.8,56,152.7,56.5,150.4,56.5z M143.5,41.5h13.2c-0.1-1.6-0.8-2.9-2-4
			c-1.2-1.1-2.8-1.6-4.7-1.6c-1.6,0-3,0.5-4.2,1.5C144.6,38.4,143.9,39.8,143.5,41.5z"/>
                    <path fill={"#1D1D1B"} d="M165.3,32.7h4.1v3.2h0.2c0.7-1.1,1.7-2.1,3-2.8c1.4-0.8,2.8-1.1,4.3-1.1c2.8,0,5,0.8,6.5,2.4
			c1.5,1.6,2.3,3.9,2.3,6.9v14.5h-4.3V41.6c-0.1-3.8-2-5.6-5.7-5.6c-1.7,0-3.2,0.7-4.3,2.1c-1.2,1.4-1.7,3.1-1.7,5v12.8h-4.3V32.7z"
                    />
                    <path fill={"#1D1D1B"} d="M198.7,56.1c-1.9,0-3.4-0.6-4.7-1.7c-1.2-1.2-1.9-2.8-1.9-4.8V36.7h-4v-4h4v-7.1h4.3v7.1h5.6v4h-5.6v11.5
			c0,1.5,0.3,2.6,0.9,3.1c0.6,0.5,1.3,0.8,2,0.8c0.3,0,0.7,0,1-0.1c0.3-0.1,0.6-0.2,0.9-0.3l1.4,3.9
			C201.6,55.9,200.2,56.1,198.7,56.1z"/>
                    <path fill={"#1D1D1B"} d="M212.1,24.8c0,0.8-0.3,1.6-0.9,2.2c-0.6,0.6-1.3,0.9-2.2,0.9s-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2
			s0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9s1.6,0.3,2.2,0.9C211.8,23.2,212.1,23.9,212.1,24.8z M211.2,32.7v23.1h-4.3V32.7H211.2z"
                    />
                    <path fill={"#1D1D1B"} d="M215.3,44.2c0-3.5,1.1-6.5,3.3-8.8c2.3-2.3,5.1-3.5,8.5-3.5c3.4,0,6.2,1.2,8.5,3.5c2.3,2.3,3.4,5.3,3.4,8.8
			c0,3.6-1.1,6.5-3.4,8.8c-2.2,2.3-5.1,3.5-8.5,3.5c-3.4,0-6.3-1.2-8.5-3.5C216.4,50.7,215.3,47.8,215.3,44.2z M219.6,44.2
			c0,2.5,0.7,4.5,2.2,6c1.4,1.5,3.2,2.3,5.4,2.3c2.1,0,3.9-0.8,5.4-2.3c1.4-1.5,2.2-3.5,2.2-6c0-2.4-0.7-4.4-2.2-6
			c-1.5-1.6-3.3-2.4-5.4-2.4c-2.1,0-3.9,0.8-5.4,2.4C220.3,39.8,219.6,41.8,219.6,44.2z"/>
                    <path fill={"#1D1D1B"} d="M242.5,32.7h4.1v3.2h0.2c0.7-1.1,1.7-2.1,3-2.8c1.4-0.8,2.8-1.1,4.3-1.1c2.8,0,5,0.8,6.5,2.4
			c1.5,1.6,2.3,3.9,2.3,6.9v14.5h-4.3V41.6c-0.1-3.8-2-5.6-5.7-5.6c-1.7,0-3.2,0.7-4.3,2.1c-1.2,1.4-1.7,3.1-1.7,5v12.8h-4.3V32.7z"
                    />
                    <path fill={"#1D1D1B"} d="M285.4,49.4c0,2-0.9,3.7-2.6,5.1c-1.8,1.4-4,2.1-6.6,2.1c-2.3,0-4.4-0.6-6.1-1.8c-1.8-1.2-3-2.8-3.8-4.8
			l3.9-1.6c0.6,1.4,1.4,2.5,2.5,3.2c1.1,0.8,2.3,1.2,3.6,1.2c1.4,0,2.5-0.3,3.5-0.9c0.9-0.6,1.4-1.3,1.4-2.1c0-1.5-1.1-2.6-3.4-3.2
			l-4-1c-4.5-1.1-6.7-3.3-6.7-6.5c0-2.1,0.9-3.8,2.6-5.1c1.7-1.3,3.9-1.9,6.6-1.9c2,0,3.9,0.5,5.5,1.5s2.8,2.3,3.5,3.9l-3.9,1.6
			c-0.4-1-1.2-1.7-2.1-2.3c-1-0.5-2.1-0.8-3.3-0.8c-1.1,0-2.1,0.3-3,0.8c-0.9,0.6-1.3,1.3-1.3,2.1c0,1.3,1.2,2.3,3.7,2.8l3.5,0.9
			C283.1,43.6,285.4,45.9,285.4,49.4z"/>
                </g>
                <path fill={"#E86B8D"} d="M292.9,45.7V28.5c0-9.4,7.7-17.1,17.1-17.1h0c9.4,0,17.1,7.7,17.1,17.1v0c0,9.4-7.7,17.1-17.1,17.1H292.9z" />
                <g id="Calque_1_00000075867033343430881130000007784358191300627352_">
                    <g>
                        <g>
                            <g>
                                <path fill={"#FFFFFF"} d="M314.8,20.5h-1.6v-0.7c0-0.2-0.3-0.4-0.5-0.4h-1.3c-0.3-0.9-1.1-1.3-1.9-1.3c-0.9,0-1.6,0.5-1.9,1.3h-1.3
						c-0.2,0-0.5,0.1-0.5,0.4v0.7h-1.6c-1,0-1.8,0.8-1.8,1.8v12.5c0,1,0.9,1.7,1.8,1.7h10.7c1,0,1.8-0.7,1.8-1.7V22.3
						C316.6,21.3,315.8,20.5,314.8,20.5z M306.6,20.3h1.2c0.2,0,0.4-0.2,0.4-0.4c0.1-0.6,0.6-1,1.2-1c0.6,0,1.1,0.4,1.2,1
						c0,0.2,0.2,0.4,0.4,0.4h1.2V22h-5.7V20.3z M315.8,34.8c0,0.5-0.5,0.8-1,0.8h-10.7c-0.5,0-1-0.3-1-0.8V22.3
						c0.1-0.5,0.5-0.9,1-0.9h1.7v1.1c0,0.2,0.2,0.4,0.5,0.4h6.4c0.3,0,0.5-0.2,0.5-0.4v-1.1h1.6c0.5,0,0.9,0.4,1,0.9V34.8
						L315.8,34.8z"/>
                                <path fill={"#FFFFFF"} d="M307.8,27.9c-0.2-0.2-0.4-0.2-0.6,0l-1.4,1.3l-0.6-0.6c-0.2-0.2-0.4-0.2-0.6,0c-0.2,0.2-0.2,0.5,0,0.6
						l0.9,0.9c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.1l1.7-1.6C308,28.3,308,28,307.8,27.9C307.8,27.9,307.8,27.9,307.8,27.9
						z"/>
                                <path fill={"#FFFFFF"} d="M314,28.8h-5c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h5c0.2,0,0.4-0.2,0.4-0.4
						C314.5,29,314.3,28.8,314,28.8z"/>
                                <path fill={"#FFFFFF"} d="M307.8,24.4c-0.2-0.2-0.4-0.2-0.6,0l-1.4,1.3l-0.6-0.6c-0.2-0.2-0.4-0.2-0.6,0c-0.2,0.2-0.2,0.5,0,0.6
						l0.9,0.9c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.1l1.7-1.6C307.9,24.8,308,24.6,307.8,24.4
						C307.8,24.4,307.8,24.4,307.8,24.4z"/>
                                <path fill={"#FFFFFF"} d="M314,25.3h-5c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h5c0.2,0,0.4-0.2,0.4-0.4
						C314.5,25.5,314.3,25.3,314,25.3z"/>
                                <path fill={"#FFFFFF"} d="M307.8,31.3c-0.2-0.2-0.4-0.2-0.6,0l-1.4,1.3l-0.6-0.6c-0.2-0.2-0.4-0.2-0.6,0c-0.2,0.2-0.2,0.5,0,0.6
						l0.9,0.9c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.1l1.7-1.6C308,31.8,308,31.5,307.8,31.3
						C307.8,31.4,307.8,31.4,307.8,31.3z"/>
                                <path fill={"#FFFFFF"} d="M314,32.3h-5c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h5c0.2,0,0.4-0.2,0.4-0.4S314.3,32.3,314,32.3z" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default LogoIntervention;