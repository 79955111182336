import { authHeader, handleResponse } from '../../_helpers';

async function getDoc(name, lang) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(`/api/doc/${name}/${lang}`, requestOptions);
    return response.blob().then((blob) => ({ content: blob, filename: `${name}_${lang}.pdf` }));
}

const docService = {
    getDoc,
}

export default docService