import React from 'react';

const SvgAnalyse = (props: any) => (
    <svg viewBox="0 0 61.9 63.35" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M61.5,60.24l-.14-.06.09-.06s0-.1,0-.08l-.24.07c-2.65-1.07-5.66-.17-8.09-1.92-.12-.09-.25.06-.23.18a2.49,2.49,0,0,0,.27.81l-.08.94c-.93-.12-1.85-.23-2.72-.27-5.34-.27-10.91-.27-16.25,0-2.64.12-5.26.48-7.9.65a73.07,73.07,0,0,1-8.1-.26c-2.72-.13-5.45-.14-8.18-.15-1.77,0-3.67-.26-5.37.33a.67.67,0,0,0,.08-.33c0-3.83.06-7.65.07-11.48a.31.31,0,0,0,.51.13c4.46-4.18,9.16-8.08,13.68-12.19,1.17,1.85,3.05,4,5.1,4.49,2.5.62,3.89-3.33,4.83-4.85C30,34.24,30.84,32,31.88,30s2.27-5.2,4.26-6.44c1.45-.89,4.8,8.1,7.59,8,2.24-.11,3.4-3.68,4-5.34.57-1.5,1-3.06,1.42-4.6a6.68,6.68,0,0,0,1,0,.75.75,0,0,0,1.45.13,7,7,0,0,0,.26-2.18,26.46,26.46,0,0,0,.4-5.07c.09-.17.18-.35.26-.53a.26.26,0,0,0-.36-.36c0-.06,0-.12,0-.18a.2.2,0,0,0-.37-.05c0,.05,0,.1-.07.15a.53.53,0,0,0-.56.22c-1.42,2.23-3.35,4.13-4.08,6.74a.7.7,0,0,0,.49.86l.41.09c-.09.32-.19.64-.28,1-.43,1.49-2.46,9.58-5,7.59-2-1.61-3.21-7.7-6.31-7.66-1.35,0-2.17,1.33-2.87,2.31a47.79,47.79,0,0,0-3.67,6.65c-.89,1.83-1.74,3.66-2.74,5.43S24.82,41,22.42,38.86a15.14,15.14,0,0,1-3-3.43.55.55,0,0,0-.85-.11c-4.64,4.31-9.49,8.37-13.87,13a.3.3,0,0,0-.06.11c0-5.67,0-11.33-.19-17-.12-4.65-.25-9.33-.72-13.95-.23-2.33-.41-4.87-.75-7.36a8.41,8.41,0,0,1,1.46.15.51.51,0,0,0,.57-.74C4.21,7.92,3.28,6.28,2.53,4.58a21.83,21.83,0,0,1-.87-3.22A.47.47,0,0,0,1.36,1C1.31.71,1.26.41,1.22.11,1.2,0,1,0,1,.11A8.19,8.19,0,0,0,1,1a.53.53,0,0,0-.47.54A69.74,69.74,0,0,1,0,9.93a.59.59,0,0,0,.74.56,7.13,7.13,0,0,1,.84-.24c.21,2.42.51,4.85.63,7.2.22,4.65.56,9.29.7,14,.31,9.55.22,19.12.31,28.68a.67.67,0,0,0,.83.66c-.31.51.06,1.34.76,1.08,2-.76,4.75-.34,6.9-.34,2.74,0,5.48.05,8.22.2,5.4.28,10.59-.48,16-.65,5.1-.15,10.41-.26,15.49.06.5,0,1,.08,1.57.13L52.9,62a.23.23,0,0,0-.07.1.83.83,0,0,0-.11.4.81.81,0,0,0,.8.81c2.56.27,5.31-1,7.78-1.56A.83.83,0,0,0,61.5,60.24ZM2.86,9a.31.31,0,0,0,0-.1L3,9Zm51,51a.83.83,0,0,0-.22,0c0-.07,0-.14,0-.21Z"/>
		</g>
	</g>
</svg>
);

export default SvgAnalyse;
