import React from 'react';

const SvgTourmaline = (props: any) => (
<svg viewBox="0 0 579.9 122.2" {...props}>
	<g>
		<g>
			<path fill={"#BFBFBF"} d="M391.1,120c-0.2,0.4-0.5,0.8-0.8,1.1c-0.3,0.3-0.7,0.5-1.2,0.7c-0.5,0.2-1,0.2-1.5,0.2h-5.1v-12.7h4.9
			c1.2,0,2.1,0.3,2.8,0.9c0.7,0.6,1,1.5,1,2.6c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.3,0.4-0.5,0.6
			c-0.2,0.2-0.4,0.3-0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.3,0.5,0.4,0.8c0.1,0.3,0.2,0.7,0.2,1.1
			C391.4,119.1,391.3,119.6,391.1,120z M388.7,111.6c-0.4-0.3-0.9-0.5-1.5-0.5h-2.9v3.6h2.9c0.6,0,1.1-0.2,1.5-0.5s0.6-0.8,0.6-1.4
			C389.2,112.3,389,111.9,388.7,111.6z M388.9,117c-0.4-0.4-0.9-0.5-1.6-0.5h-3v3.9h3c0.7,0,1.2-0.2,1.6-0.5
			c0.4-0.4,0.5-0.8,0.5-1.4S389.2,117.4,388.9,117z"/>
			<path fill={"#BFBFBF"} d="M398.4,116.8v5.2h-1.9v-5.2l-3.8-7.5h2.1l2.7,5.6l2.7-5.6h2.1L398.4,116.8z"/>
			<path fill={"#BFBFBF"} d="M417.2,117c0,0.5,0,0.9-0.1,1.4c-0.1,0.5-0.2,0.9-0.4,1.3s-0.4,0.8-0.8,1.1c-0.4,0.4-0.9,0.7-1.4,0.9
			c-0.6,0.2-1.2,0.3-1.8,0.3h-4.5v-12.7h4.5c0.7,0,1.3,0.1,1.8,0.3s1,0.5,1.4,0.9c0.3,0.3,0.6,0.7,0.8,1.1s0.3,0.8,0.4,1.2
			s0.1,0.9,0.1,1.3c0,0.5,0,0.9,0,1.4S417.2,116.6,417.2,117z M415.2,113.4c-0.1-0.6-0.3-1.1-0.6-1.5c-0.5-0.6-1.2-0.9-2.2-0.9h-2.3
			v9.2h2.3c0.9,0,1.6-0.3,2.2-0.9c0.3-0.4,0.5-0.9,0.6-1.5s0.1-1.4,0.1-2.3S415.3,114,415.2,113.4z"/>
			<path fill={"#BFBFBF"} d="M420.1,122.1v-12.7h1.9v12.7H420.1z"/>
			<path fill={"#BFBFBF"} d="M434,117.4c0,0.5-0.1,0.9-0.1,1.3c-0.1,0.4-0.2,0.8-0.4,1.1c-0.2,0.3-0.4,0.7-0.7,1c-0.4,0.4-0.9,0.8-1.5,1
			c-0.6,0.2-1.2,0.3-1.9,0.3c-0.7,0-1.3-0.1-1.8-0.3c-0.6-0.2-1.1-0.6-1.5-1c-0.3-0.3-0.5-0.6-0.7-1c-0.2-0.3-0.3-0.7-0.4-1.1
			c-0.1-0.4-0.1-0.9-0.1-1.3c0-0.5,0-1,0-1.7c0-0.6,0-1.2,0-1.7c0-0.5,0.1-0.9,0.1-1.3c0.1-0.4,0.2-0.8,0.4-1.1
			c0.2-0.3,0.4-0.7,0.7-1c0.4-0.4,0.9-0.8,1.5-1c0.6-0.2,1.2-0.3,1.8-0.3c0.7,0,1.3,0.1,1.9,0.3c0.6,0.2,1.1,0.6,1.5,1
			c0.3,0.3,0.5,0.6,0.7,1c0.2,0.3,0.3,0.7,0.4,1.1c0.1,0.4,0.1,0.9,0.1,1.3c0,0.5,0,1,0,1.7C434,116.3,434,116.9,434,117.4z
			 M432.1,114.1c0-0.4-0.1-0.8-0.1-1.1c-0.1-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.2-0.5-0.4-0.8-0.6
			s-0.7-0.2-1.1-0.2c-0.4,0-0.7,0.1-1.1,0.2s-0.6,0.3-0.8,0.6c-0.2,0.2-0.3,0.3-0.4,0.5s-0.2,0.4-0.2,0.7c-0.1,0.3-0.1,0.6-0.1,1.1
			c0,0.4,0,1,0,1.6s0,1.2,0,1.6c0,0.4,0.1,0.8,0.1,1.1c0.1,0.3,0.1,0.5,0.2,0.7s0.2,0.4,0.4,0.5c0.2,0.2,0.5,0.4,0.8,0.6
			c0.3,0.1,0.7,0.2,1.1,0.2c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.8-0.6c0.2-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.2-0.7
			s0.1-0.6,0.1-1.1c0-0.4,0-1,0-1.6S432.1,114.5,432.1,114.1z"/>
			<path fill={"#BFBFBF"} d="M445.5,114.8c-0.2,0.5-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.3,0.8s-1.1,0.3-1.7,0.3h-2.9v5h-1.9v-12.7h4.8
			c0.6,0,1.2,0.1,1.7,0.3s0.9,0.5,1.3,0.8c0.4,0.3,0.6,0.7,0.8,1.2c0.2,0.5,0.3,1,0.3,1.5C445.8,113.8,445.7,114.3,445.5,114.8z
			 M443.2,111.6c-0.4-0.4-1-0.6-1.6-0.6h-2.8v4.2h2.8c0.7,0,1.2-0.2,1.6-0.5c0.4-0.4,0.6-0.9,0.6-1.6
			C443.8,112.5,443.6,112,443.2,111.6z"/>
			<path fill={"#BFBFBF"} d="M452.4,111.1v11h-1.9v-11H447v-1.7h8.9v1.7H452.4z"/>
			<path fill={"#BFBFBF"} d="M464.3,122.1l-0.9-2.6h-5l-0.9,2.6h-2.1l4.7-12.7h1.6l4.7,12.7H464.3z M461,112.3l-2,5.6h3.9L461,112.3z"/>
			<path fill={"#BFBFBF"} d="M475.8,120c-0.2,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.9,0.6-1.4,0.7c-0.6,0.2-1.2,0.2-1.8,0.2
			c-1,0-1.8-0.1-2.5-0.4s-1.4-0.7-2-1.3l1.3-1.3c0.5,0.5,1,0.8,1.5,1s1.1,0.3,1.8,0.3c0.8,0,1.5-0.2,1.9-0.5s0.7-0.8,0.7-1.4
			c0-0.6-0.2-1-0.5-1.3c-0.2-0.2-0.3-0.3-0.5-0.3c-0.2-0.1-0.5-0.1-0.9-0.2l-1.5-0.2c-1-0.1-1.8-0.5-2.4-1c-0.6-0.6-0.9-1.4-0.9-2.4
			c0-0.5,0.1-1.1,0.3-1.5s0.5-0.9,0.8-1.2c0.4-0.3,0.8-0.6,1.3-0.8c0.5-0.2,1.1-0.3,1.8-0.3c0.8,0,1.6,0.1,2.2,0.3s1.2,0.6,1.7,1.1
			l-1.2,1.2c-0.4-0.4-0.8-0.6-1.3-0.8s-1-0.2-1.5-0.2c-0.8,0-1.3,0.2-1.7,0.6c-0.4,0.4-0.6,0.8-0.6,1.4c0,0.2,0,0.5,0.1,0.7
			c0.1,0.2,0.2,0.4,0.4,0.5c0.3,0.3,0.8,0.5,1.4,0.6l1.4,0.2c0.6,0.1,1.1,0.2,1.4,0.4c0.4,0.1,0.7,0.3,0.9,0.6
			c0.3,0.3,0.6,0.7,0.8,1.1c0.2,0.4,0.3,0.9,0.3,1.5C476.1,119,476,119.6,475.8,120z"/>
			<path fill={"#BFBFBF"} d="M478.7,122.1v-12.7h8.1v1.7h-6.2v3.7h5.3v1.7h-5.3v3.8h6.2v1.7H478.7z"/>
		</g>
		<g>
			<path fill={"#1E1E1C"} d="M23.8,100c-4.2,0-7.7-1.3-10.5-3.9c-2.8-2.6-4.2-6.2-4.3-10.8V56.4H0v-8.8h9.1V31.8h9.7v15.8h12.6v8.8H18.7
			v25.7c0,3.4,0.7,5.8,2,7c1.3,1.2,2.8,1.8,4.5,1.8c0.8,0,1.5-0.1,2.3-0.3c0.7-0.2,1.4-0.4,2.1-0.7l3.1,8.6
			C30.1,99.6,27.2,100,23.8,100z"/>
			<path fill={"#1E1E1C"} d="M37.2,73.4c0-7.9,2.5-14.5,7.5-19.7c5.1-5.2,11.4-7.8,19.1-7.8c7.7,0,14,2.6,19,7.8
			c5.1,5.2,7.6,11.8,7.6,19.7c0,8-2.5,14.6-7.6,19.7c-5,5.2-11.3,7.8-19,7.8c-7.7,0-14-2.6-19.1-7.8C39.7,87.9,37.2,81.3,37.2,73.4z
			 M46.9,73.4c0,5.5,1.6,10,4.8,13.5c3.2,3.4,7.2,5.2,12,5.2c4.8,0,8.8-1.7,12-5.2c3.2-3.4,4.8-7.9,4.8-13.5c0-5.5-1.6-9.9-4.8-13.4
			c-3.3-3.5-7.3-5.3-12-5.3c-4.7,0-8.7,1.8-12,5.3C48.5,63.5,46.9,67.9,46.9,73.4z"/>
			<path fill={"#1E1E1C"} d="M143.9,99.2h-9.3V92h-0.4c-1.5,2.5-3.7,4.6-6.8,6.3c-3.1,1.7-6.2,2.5-9.5,2.5c-6.3,0-11.2-1.8-14.6-5.4
			c-3.4-3.6-5.1-8.8-5.1-15.4V47.6h9.7v31.8c0.2,8.4,4.5,12.6,12.7,12.6c3.9,0,7.1-1.6,9.7-4.7c2.6-3.1,3.9-6.9,3.9-11.2V47.6h9.7
			V99.2z"/>
			<path fill={"#1E1E1C"} d="M165.4,99.2h-9.7V47.6h9.3V56h0.4c1-2.7,3-5.1,6.1-7c3.1-1.9,6.1-2.9,9-2.9c2.8,0,5.2,0.4,7.2,1.3l-2.9,9.4
			c-1.2-0.5-3.1-0.7-5.7-0.7c-3.7,0-6.8,1.5-9.5,4.4c-2.7,2.9-4.1,6.4-4.1,10.3V99.2z"/>
			<path fill={"#1E1E1C"} d="M203.6,99.2h-9.7V47.6h9.3v7.2h0.4c1.5-2.5,3.7-4.6,6.8-6.3c3.1-1.7,6.1-2.5,9.1-2.5c3.8,0,7.1,0.9,10,2.6
			c2.9,1.8,5,4.2,6.3,7.3c4.3-6.6,10.2-9.9,17.8-9.9c6,0,10.6,1.8,13.8,5.5c3.2,3.7,4.8,8.8,4.8,15.6v32.2h-9.7V68.4
			c0-4.8-0.9-8.3-2.6-10.5c-1.8-2.1-4.7-3.2-8.8-3.2c-3.7,0-6.8,1.6-9.4,4.7c-2.5,3.2-3.8,6.9-3.8,11.2v28.5h-9.7V68.4
			c0-4.8-0.9-8.3-2.6-10.5c-1.8-2.1-4.7-3.2-8.8-3.2c-3.7,0-6.8,1.6-9.4,4.7s-3.8,6.9-3.8,11.2V99.2z"/>
			<path fill={"#1E1E1C"} d="M302.7,45.9c7.2,0,12.8,1.9,17,5.7c4.1,3.8,6.2,9.1,6.2,15.7v31.8h-9.3V92h-0.4c-4,5.9-9.3,8.8-16,8.8
			c-5.7,0-10.4-1.7-14.3-5.1c-3.8-3.4-5.7-7.6-5.7-12.6c0-5.3,2-9.6,6.1-12.7c4-3.2,9.4-4.7,16.2-4.7c5.8,0,10.5,1.1,14.2,3.2v-2.2
			c0-3.4-1.3-6.2-4-8.6c-2.7-2.4-5.8-3.5-9.4-3.5c-5.4,0-9.7,2.3-12.9,6.8l-8.5-5.4C286.6,49.3,293.5,45.9,302.7,45.9z M290.2,83.4
			c0,2.5,1.1,4.6,3.2,6.3c2.1,1.7,4.7,2.5,7.5,2.5c4.1,0,7.7-1.5,10.9-4.5c3.2-3,4.8-6.6,4.8-10.6c-3-2.4-7.2-3.6-12.6-3.6
			c-3.9,0-7.2,0.9-9.8,2.8C291.5,78.2,290.2,80.6,290.2,83.4z"/>
			<path fill={"#1E1E1C"} d="M346.5,23.8v75.4h-9.7V23.8H346.5z"/>
			<path fill={"#1E1E1C"} d="M370.9,29.8c0,1.9-0.7,3.5-2,4.8c-1.3,1.3-2.9,2-4.8,2c-1.9,0-3.5-0.7-4.8-2c-1.3-1.3-2-2.9-2-4.8
			c0-1.9,0.7-3.5,2-4.8c1.3-1.3,2.9-2,4.8-2c1.9,0,3.5,0.7,4.8,2C370.2,26.3,370.9,27.9,370.9,29.8z M368.9,47.6v51.6h-9.7V47.6
			H368.9z"/>
			<path fill={"#1E1E1C"} d="M381.5,47.6h9.3v7.2h0.4c1.5-2.5,3.7-4.6,6.8-6.3c3.1-1.7,6.2-2.5,9.5-2.5c6.3,0,11.2,1.8,14.6,5.4
			c3.4,3.6,5.1,8.8,5.1,15.4v32.4h-9.7V67.4c-0.2-8.4-4.5-12.6-12.7-12.6c-3.9,0-7.1,1.6-9.7,4.7c-2.6,3.1-3.9,6.9-3.9,11.2v28.5
			h-9.7V47.6z"/>
			<path fill={"#1E1E1C"} d="M461.8,100.9c-7.6,0-13.8-2.6-18.7-7.8c-4.9-5.2-7.4-11.8-7.4-19.7c0-7.9,2.4-14.4,7.2-19.6
			c4.8-5.2,10.9-7.8,18.3-7.8c7.7,0,13.7,2.5,18.3,7.4c4.5,5,6.8,11.9,6.8,20.8l-0.1,1.1h-40.6c0.1,5.1,1.8,9.1,5.1,12.2
			c3.2,3.1,7.1,4.6,11.6,4.6c6.2,0,11-3.1,14.5-9.3l8.6,4.2c-2.3,4.4-5.5,7.8-9.6,10.2S467,100.9,461.8,100.9z M446.3,67.2h29.6
			c-0.3-3.6-1.7-6.5-4.4-8.9c-2.6-2.4-6.2-3.5-10.6-3.5c-3.7,0-6.8,1.1-9.4,3.4C448.9,60.4,447.1,63.4,446.3,67.2z"/>
		</g>
		<path fill={"#3E9F92"} d="M503.4,76.6V38.3c0-21.1,17.1-38.3,38.3-38.3h0c21.1,0,38.3,17.1,38.3,38.3v0c0,21.1-17.1,38.3-38.3,38.3
		H503.4z"/>
		<path fill={"#FFFFFF"} d="M555.4,20.7h-29.8c-2.8,0-5.8,1.5-5.8,5.8v20c0,4.3,3,5.8,5.8,5.8h11V56h-1.8c-0.5,0-0.9,0.4-0.9,0.9
		c0,0.5,0.4,0.9,0.9,0.9h11.8c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2.2v-3.7h11c4.3,0,5.8-3,5.8-5.8v-20
		C561.2,22.2,558.2,20.7,555.4,20.7z M542.6,56h-4.2v-3.7h4.2V56z M559.4,46.5c0,1.2-0.4,4-4,4h-29.8c-1.2,0-4-0.4-4-4v-1.3h37.8
		V46.5z M559.4,43.5h-37.8V26.5c0-3.6,2.8-4,4-4h29.8c1.2,0,4,0.4,4,4V43.5z"/>
	</g>
</svg>

);

export default SvgTourmaline;
