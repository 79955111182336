import React from 'react';

const SvgLogiciel = (props: any) => (
    <svg viewBox="0 0 22.92 17.19" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M21,0H1.91A1.92,1.92,0,0,0,0,1.91V13.37a1.92,1.92,0,0,0,1.91,1.91H10v1H7.16a.48.48,0,0,0,0,1h8.59a.48.48,0,0,0,0-1H12.89v-1H21a1.92,1.92,0,0,0,1.91-1.91V1.91A1.92,1.92,0,0,0,21,0ZM11.93,16.23H11v-1h1Zm10-2.86a1,1,0,0,1-.95,1H1.91a1,1,0,0,1-1-1V1.91a1,1,0,0,1,1-1H21a1,1,0,0,1,.95,1Z"/>
			<path fill={props.fill} d="M11.1,3.82A3.91,3.91,0,1,0,15,7.69,3.9,3.9,0,0,0,11.1,3.82Zm.06,6.94a3,3,0,1,1,3-3.07A3,3,0,0,1,11.16,10.76Z"/>
			<path fill={props.fill} d="M12.43,7.06h-.65V6.41a.65.65,0,1,0-1.3,0v.65H9.82a.66.66,0,0,0,0,1.31h.65V9A.66.66,0,0,0,11.8,9V8.37h.65a.65.65,0,1,0,0-1.3Z"/>
		</g>
	</g>
</svg>
);

export default SvgLogiciel;
