import React from "react";

import i18next from 'i18next';

import SvgAfi from "../../_svg/Billing_afi.tsx";
import SvgAgedi from "../../_svg/Billing_agedi.tsx";
import SvgAqua from "../../_svg/Billing_aqua.tsx";
import SvgCegid from "../../_svg/Billing_cegid.tsx";
import SvgCerig from "../../_svg/Billing_cerig.tsx";
import SvgCfc from "../../_svg/Billing_cfc.tsx";
import SvgCiderp from "../../_svg/Billing_ciderp.tsx";
import SvgCosoluce from "../../_svg/Billing_cosoluce.tsx";
import SvgEigee from "../../_svg/Billing_eigee.tsx";
import SvgIncom from "../../_svg/Billing_incom.tsx";
import SvgJba from "../../_svg/Billing_jba.tsx";
import SvgJvs from "../../_svg/Billing_jvs.tsx";
import SvgModularis from "../../_svg/Billing_modularis.tsx";
import SvgProgema from "../../_svg/Billing_progema.tsx";
import SvgSegilog from "../../_svg/Billing_segilog.tsx";
import SvgSomei from "../../_svg/Billing_somei.tsx";
import SvgStart from "../../_svg/Billing_start.tsx";

import SvgAdeunis from "../../_svg/Manufacturer_adeunis.tsx";
import SvgAxioma from "../../_svg/Manufacturer_axioma.tsx";
import SvgDiehl from "../../_svg/Manufacturer_diehl.tsx";
import SvgEngelmann from "../../_svg/Manufacturer_engelmann.tsx";
import SvgHoneywell from "../../_svg/Manufacturer_honeywell.tsx";
import SvgIntegra from "../../_svg/Manufacturer_integra.tsx";
import SvgItron from "../../_svg/Manufacturer_itron.tsx";
import SvgKamstrup from "../../_svg/Manufacturer_kamstrup.tsx";
import SvgMaddalena from "../../_svg/Manufacturer_maddalena.tsx";
import SvgQundis from "../../_svg/Manufacturer_qundis.tsx";
import SvgSagemcom from "../../_svg/Manufacturer_sagemcom.tsx";
import SvgSensus from "../../_svg/Manufacturer_sensus.tsx";
import SvgSontex from "../../_svg/Manufacturer_sontex.tsx";
import SvgWavenis from "../../_svg/Manufacturer_wavenis.tsx";
import SvgWize from "../../_svg/Manufacturer_wize.tsx";
import SvgZenner from "../../_svg/Manufacturer_zenner.tsx";
import SvgBirdz from "../../_svg/Manufacturer_birdz";

import SvgKerlink from "../../_svg/Network_kerlink";
import SvgLiveObject from "../../_svg/Network_liveObject";
import SvgObjenious from "../../_svg/Network_objenious";
import SvgRequea from "../../_svg/Network_requea";

import iSaphir from "../../_resources/image/home/i_saphir.png";
import iMbox from "../../_resources/image/home/i_mbox.png";
import iDld from "../../_resources/image/home/i_dld.png";

import SvgSaphir from "../../_svg/SaphirLogo";
import MboxLogo from "../../_resources/image/mbox_logo.png";
import DldLogo from "../../_resources/image/dld_logo.png";

const billings = [
    {
        title: 'afi',
        svg: <SvgAfi height="2em" />
    },
    {
        title: 'agedi',
        svg: <SvgAgedi height="2em" />
    },
    {
        title: 'aqua',
        svg: <SvgAqua height="2em" />
    },
    {
        title: 'cegid',
        svg: <SvgCegid height="2em" />
    },
    {
        title: 'cerig',
        svg: <SvgCerig height="2em" />
    },
    {
        title: 'cfc',
        svg: <SvgCfc height="2em" />
    },
    {
        title: 'ciderp',
        svg: <SvgCiderp height="2em" />
    },
    {
        title: 'cosoluce',
        svg: <SvgCosoluce height="2em" />
    },
    {
        title: 'eigee',
        svg: <SvgEigee height="2em" />
    },
    {
        title: 'incom',
        svg: <SvgIncom height="2em" />
    },
    {
        title: 'jba',
        svg: <SvgJba height="2em" />
    },
    {
        title: 'jvs',
        svg: <SvgJvs height="2em" />
    },
    {
        title: 'modularis',
        svg: <SvgModularis height="2em" />
    },
    {
        title: 'progema',
        svg: <SvgProgema height="2em" />
    },
    {
        title: 'segilog',
        svg: <SvgSegilog height="2em" />
    },
    {
        title: 'somei',
        svg: <SvgSomei height="2em" />
    },
    {
        title: 'start',
        svg: <SvgStart height="2em" />
    },
]

const manufacturers = [
    {
        title: 'adeunis',
        svg: <SvgAdeunis width="130px" />,
    },
    {
        title: 'axioma',
        svg: <SvgAxioma width="130px" />,
    },
    {
        title: 'diehl',
        svg: <SvgDiehl width="130px" />,
    },
    {
        title: 'engelmann',
        svg: <SvgEngelmann width="180px" />,
    },
    {
        title: 'honeywell',
        svg: <SvgHoneywell width="220px" />,
    },
    {
        title: 'integra',
        svg: <SvgIntegra width="130px" />,
    },
    {
        title: 'itron',
        svg: <SvgItron width="100px" />,
    },
    {
        title: 'kamstrup',
        svg: <SvgKamstrup width="130px" />,
    },
    {
        title: 'maddalena',
        svg: <SvgMaddalena width="160px" />,
    },
    {
        title: 'qundis',
        svg: <SvgQundis width="130px" />,
    },
    {
        title: 'sagemcom',
        svg: <SvgSagemcom width="130px" />,
    },
    {
        title: 'sensus',
        svg: <SvgSensus width="200px" />,
    },
    {
        title: 'birdz',
        svg: <SvgBirdz width="130px" />,
    },
    {
        title: 'sontex',
        svg: <SvgSontex width="120px" />,
    },
    {
        title: 'wavenis',
        svg: <SvgWavenis width="130px" />,
    },
    {
        title: 'wize',
        svg: <SvgWize width="130px" />,
    },
    {
        title: 'zenner',
        svg: <SvgZenner width="130px" />,
    },
]

const networks = [
    {
        title: 'kerlink',
        svg: <SvgKerlink height="2em" />,
    },
    {
        title: 'Live Object',
        svg: <SvgLiveObject height="2em" />,
    },
    {
        title: 'objenious',
        svg: <SvgObjenious height="2em" />,
    },
    {
        title: 'requea',
        svg: <SvgRequea height="2em" />,
    },
]

const products = (t) => [
    {
        code: 'saphir',
        title: t('home.read.foot'),
        img: <img src={iSaphir} />,
        logo: <SvgSaphir height="2em" />,
        color: '#cf7830',
    },
    {
        code: 'mbox',
        title: t('home.read.car'),
        img: <img src={iMbox} />,
        logo: <img src={MboxLogo} />,
        color: '#208491'
    },
    {
        code: 'dld',
        title: t('home.remote.reading'),
        img: <img src={iDld} />,
        logo: <img src={DldLogo} />,
        color: '#d8a202',
    },
]

export { billings, manufacturers, networks, products }