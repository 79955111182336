import React from 'react';

const SvgSomei = (props: any) => (
<svg viewBox="0 0 118.79 51.89" {...props}>
	<g>
		<path d="M78.42,17.95c-5.67,0-9.42,1.49-10.72,2.09-1.1-1.28-2.71-2.09-4.51-2.09-6.97,0-11.05,2.25-11.22,2.35l-.65,.37v21.53h2.65V22.17c1.31-.56,4.53-1.69,9.21-1.69,1.84,0,3.34,1.6,3.34,3.56v18.15h2.67V22.17c1.31-.56,4.53-1.69,9.21-1.69,1.84,0,3.34,1.6,3.34,3.56v18.15h2.65V24.05c0-3.36-2.69-6.09-5.99-6.09Z"/>
		<path d="M115.69,32.41c0,2.09-8.4,7.44-11.68,7.44h-2.54c-5.91,0-8.56-3.56-8.56-7.08v-.36l18.89-1.09v-4.76c0-4.74-4.04-8.6-9.01-8.6h-2.42c-5.58,0-10.11,4.33-10.11,9.65v5.16c0,5.66,4.61,9.61,11.21,9.61h2.54c2.35,0,6.05-1.8,8.51-3.34,.92-.58,2.11-1.39,3.17-2.33v5.15h2.65V18.33h-2.65v14.07Zm-22.77-4.8c0-3.92,3.35-7.12,7.46-7.12h2.42c3.51,0,6.36,2.72,6.36,6.07v2.38l-16.24,.93v-2.26Z"/>
		<path d="M117.02,15.5c.98,0,1.78-.76,1.78-1.7s-.8-1.7-1.78-1.7-1.78,.76-1.78,1.7,.8,1.7,1.78,1.7Z"/>
		<path d="M14.2,26.1c-.75-.38-1.54-.69-2.36-.91-1.95-.54-3.77-1.18-4.96-1.61-2.42-.9-4.08-2.83-4.22-4.92,0-.11-.01-.23-.01-.34s0-.23,0-.34c.13-2.08,1.99-3.93,4.52-4.5,5.06-1.15,11.65,.07,11.72,.08l.51-2.48c-.29-.06-7.22-1.33-12.84-.06C2.85,11.86,.22,14.59,.01,17.83c-.01,.16-.01,.32-.01,.49s0,.34,.02,.5c.21,3.07,2.53,5.87,5.91,7.12,1.23,.46,3.13,1.12,5.18,1.68,.66,.18,1.3,.43,1.9,.74l.13,.06c2.43,.94,4.1,2.99,4.24,5.21,0,.12,.01,.24,.01,.36s0,.25-.01,.37c-.13,2.24-1.95,4.16-4.53,4.77-5.04,1.2-11.63-.07-11.69-.08l-.54,2.48c.2,.04,3.37,.65,7.14,.65,1.86,0,3.87-.15,5.73-.59,3.71-.88,6.34-3.73,6.54-7.08,0-.17,.01-.34,.01-.51s0-.35-.02-.52c-.21-3.17-2.49-6.06-5.82-7.37Z"/>
	</g>
	<path d="M44.29,15.12c-1.41,0-2.55-1.09-2.55-2.43s1.14-2.43,2.55-2.43,2.55,1.09,2.55,2.43-1.14,2.43-2.55,2.43Zm0-3.6c-.68,0-1.22,.52-1.22,1.17s.55,1.17,1.22,1.17,1.22-.52,1.22-1.17-.55-1.17-1.22-1.17Z"/>
	<path d="M35.9,50.09c-1.41,0-2.55-1.09-2.55-2.43s1.14-2.44,2.55-2.44,2.55,1.09,2.55,2.44-1.14,2.43-2.55,2.43Zm0-3.6c-.68,0-1.23,.52-1.23,1.17s.55,1.17,1.23,1.17,1.22-.52,1.22-1.17-.55-1.17-1.22-1.17Z"/>
	<path d="M35.9,17.95c-.4,0-.79,.02-1.18,.06-4.88-2.41-6.37-6.29-6.83-8.4,.74-.58,1.34-1.34,1.7-2.25,1.12-2.8-.36-5.94-3.29-7-2.94-1.07-6.22,.34-7.34,3.14-1.12,2.8,.36,5.94,3.29,7,1.06,.38,2.16,.44,3.19,.24,1.34,1.8,3.23,5.32,2.28,9.94-3.05,2.31-5.02,5.86-5.02,9.86,0,2.95,1.08,5.67,2.87,7.81-.75,3.14-2.86,4.85-3.94,5.55-.61-.3-1.3-.48-2.04-.48-2.45,0-4.44,1.9-4.44,4.23s1.99,4.23,4.44,4.23,4.44-1.9,4.44-4.23c0-1.13-.46-2.15-1.22-2.91,.72-1.02,2.49-3.03,5.75-3.76,2.1,1.34,4.62,2.13,7.33,2.13,7.27,0,13.19-5.64,13.19-12.58s-5.92-12.58-13.19-12.58Zm-13.05-8.94c-2.08-.75-3.12-2.97-2.33-4.95,.79-1.98,3.11-2.98,5.19-2.22,2.08,.75,3.12,2.97,2.33,4.95s-3.11,2.98-5.19,2.22Zm-3.25,41.34c-1.57,0-2.84-1.21-2.84-2.71s1.27-2.71,2.84-2.71,2.84,1.21,2.84,2.71-1.27,2.71-2.84,2.71Zm16.3-9.77c-5.81,0-10.53-4.51-10.53-10.05s4.72-10.05,10.53-10.05,10.53,4.51,10.53,10.05-4.72,10.05-10.53,10.05Z"/>
	<g>
		<path d="M48.86,45.84c.3-.04,.66-.08,1.05-.08,.71,0,1.22,.16,1.56,.46,.34,.3,.54,.72,.54,1.32s-.19,1.09-.55,1.42c-.36,.34-.95,.53-1.7,.53-.35,0-.65-.02-.9-.04v-3.61Zm.5,3.24c.13,.02,.31,.03,.5,.03,1.06,0,1.64-.57,1.64-1.56,0-.86-.51-1.41-1.56-1.41-.26,0-.45,.02-.58,.05v2.89Z"/>
		<path d="M52.93,48.23c.01,.65,.44,.91,.95,.91,.36,0,.58-.06,.76-.14l.09,.34c-.18,.08-.48,.16-.92,.16-.85,0-1.36-.53-1.36-1.33s.49-1.42,1.29-1.42c.9,0,1.14,.76,1.14,1.24,0,.1-.01,.17-.02,.22h-1.94Zm1.47-.34c0-.3-.13-.78-.7-.78-.51,0-.73,.45-.77,.78h1.46Zm.12-2.2l-.7,.78h-.36l.5-.78h.55Z"/>
		<path d="M55.7,46.83l.54,1.48c.09,.24,.17,.46,.22,.67h.02c.06-.22,.14-.44,.23-.67l.54-1.48h.52l-1.08,2.63h-.48l-1.05-2.63h.54Z"/>
		<path d="M58.47,48.23c.01,.65,.44,.91,.95,.91,.36,0,.58-.06,.76-.14l.09,.34c-.18,.08-.48,.16-.92,.16-.85,0-1.36-.53-1.36-1.33s.49-1.42,1.29-1.42c.9,0,1.14,.76,1.14,1.24,0,.1-.01,.17-.02,.22h-1.94Zm1.47-.34c0-.3-.13-.78-.7-.78-.51,0-.73,.45-.77,.78h1.46Z"/>
		<path d="M61.05,45.6h.5v3.86h-.5v-3.86Z"/>
		<path d="M64.89,48.12c0,.97-.71,1.4-1.37,1.4-.75,0-1.32-.52-1.32-1.35,0-.88,.6-1.4,1.37-1.4s1.33,.55,1.33,1.35Zm-2.19,.03c0,.58,.35,1.01,.84,1.01s.84-.43,.84-1.02c0-.45-.23-1.01-.83-1.01s-.85,.52-.85,1.02Z"/>
		<path d="M65.52,47.68c0-.34-.01-.61-.02-.86h.45l.02,.45h.01c.2-.32,.53-.51,.98-.51,.67,0,1.17,.54,1.17,1.34,0,.95-.6,1.41-1.25,1.41-.36,0-.68-.15-.85-.41h-.01v1.43h-.5v-2.85Zm.5,.7c0,.07,.01,.14,.02,.2,.09,.33,.39,.56,.75,.56,.53,0,.84-.41,.84-1.02,0-.53-.29-.98-.82-.98-.34,0-.66,.23-.76,.59-.02,.06-.03,.13-.03,.2v.45Z"/>
		<path d="M68.76,47.68c0-.34-.01-.61-.02-.86h.45l.02,.45h.01c.2-.32,.53-.51,.98-.51,.67,0,1.17,.54,1.17,1.34,0,.95-.6,1.41-1.25,1.41-.36,0-.68-.15-.85-.41h-.01v1.43h-.5v-2.85Zm.5,.7c0,.07,.01,.14,.02,.2,.09,.33,.39,.56,.75,.56,.53,0,.84-.41,.84-1.02,0-.53-.29-.98-.82-.98-.34,0-.66,.23-.76,.59-.02,.06-.03,.13-.03,.2v.45Z"/>
		<path d="M72.28,48.23c.01,.65,.44,.91,.95,.91,.36,0,.58-.06,.76-.14l.09,.34c-.18,.08-.48,.16-.92,.16-.85,0-1.36-.53-1.36-1.33s.49-1.42,1.29-1.42c.9,0,1.14,.76,1.14,1.24,0,.1-.01,.17-.02,.22h-1.94Zm1.47-.34c0-.3-.13-.78-.7-.78-.51,0-.73,.45-.77,.78h1.46Z"/>
		<path d="M77.16,48.74c0,.27,0,.51,.02,.72h-.44l-.03-.43h-.01c-.13,.21-.42,.49-.91,.49-.43,0-.95-.23-.95-1.15v-1.54h.5v1.46c0,.5,.16,.84,.62,.84,.34,0,.57-.22,.66-.44,.03-.07,.05-.16,.05-.24v-1.62h.5v1.91Z"/>
		<path d="M78,47.65c0-.31,0-.58-.02-.82h.44l.02,.52h.02c.13-.35,.43-.58,.76-.58,.06,0,.1,0,.14,.02v.45c-.05-.01-.1-.02-.17-.02-.35,0-.6,.26-.67,.61-.01,.06-.02,.14-.02,.22v1.4h-.5v-1.81Z"/>
		<path d="M83.45,45.6v3.18c0,.23,0,.5,.02,.68h-.45l-.02-.46h-.01c-.15,.29-.49,.52-.94,.52-.67,0-1.18-.54-1.18-1.34,0-.88,.56-1.41,1.24-1.41,.42,0,.71,.19,.83,.4h.01v-1.57h.5Zm-.5,2.3c0-.06,0-.14-.02-.2-.07-.3-.35-.55-.72-.55-.52,0-.83,.43-.83,1.02,0,.53,.27,.97,.82,.97,.34,0,.64-.21,.74-.57,.02-.07,.02-.13,.02-.21v-.46Z"/>
		<path d="M84.56,48.23c.01,.65,.44,.91,.95,.91,.36,0,.58-.06,.76-.14l.09,.34c-.18,.08-.48,.16-.92,.16-.85,0-1.36-.53-1.36-1.33s.49-1.42,1.29-1.42c.9,0,1.14,.76,1.14,1.24,0,.1-.01,.17-.02,.22h-1.94Zm1.47-.34c0-.3-.13-.78-.7-.78-.51,0-.73,.45-.77,.78h1.46Z"/>
		<path d="M88.34,47.68c0-.34-.01-.61-.02-.86h.45l.02,.45h.01c.2-.32,.53-.51,.98-.51,.67,0,1.17,.54,1.17,1.34,0,.95-.6,1.41-1.25,1.41-.36,0-.68-.15-.85-.41h-.01v1.43h-.5v-2.85Zm.5,.7c0,.07,.01,.14,.02,.2,.09,.33,.39,.56,.75,.56,.53,0,.84-.41,.84-1.02,0-.53-.29-.98-.82-.98-.34,0-.66,.23-.76,.59-.02,.06-.03,.13-.03,.2v.45Z"/>
		<path d="M91.86,48.23c.01,.65,.44,.91,.95,.91,.36,0,.58-.06,.76-.14l.09,.34c-.18,.08-.48,.16-.92,.16-.85,0-1.36-.53-1.36-1.33s.49-1.42,1.29-1.42c.9,0,1.14,.76,1.14,1.24,0,.1-.01,.17-.02,.22h-1.94Zm1.47-.34c0-.3-.13-.78-.7-.78-.51,0-.73,.45-.77,.78h1.46Z"/>
		<path d="M94.44,47.65c0-.31,0-.58-.02-.82h.44l.02,.52h.02c.13-.35,.43-.58,.76-.58,.06,0,.1,0,.14,.02v.45c-.05-.01-.1-.02-.17-.02-.35,0-.6,.26-.67,.61-.01,.06-.02,.14-.02,.22v1.4h-.5v-1.81Z"/>
		<path d="M96.52,49.46v-2.27h-.39v-.36h.39v-.12c0-.37,.09-.71,.32-.92,.19-.17,.44-.24,.67-.24,.18,0,.33,.04,.43,.08l-.07,.37c-.07-.03-.18-.06-.32-.06-.43,0-.54,.36-.54,.76v.14h.67v.36h-.67v2.27h-.5Z"/>
		<path d="M100.56,48.12c0,.97-.71,1.4-1.37,1.4-.75,0-1.32-.52-1.32-1.35,0-.88,.6-1.4,1.37-1.4s1.33,.55,1.33,1.35Zm-2.19,.03c0,.58,.35,1.01,.84,1.01s.84-.43,.84-1.02c0-.45-.23-1.01-.83-1.01s-.85,.52-.85,1.02Z"/>
		<path d="M101.19,47.65c0-.31,0-.58-.02-.82h.44l.02,.52h.02c.13-.35,.43-.58,.76-.58,.06,0,.1,0,.14,.02v.45c-.05-.01-.1-.02-.17-.02-.35,0-.6,.26-.67,.61-.01,.06-.02,.14-.02,.22v1.4h-.5v-1.81Z"/>
		<path d="M103.07,47.54c0-.27,0-.5-.02-.71h.44l.02,.42h.02c.15-.25,.41-.48,.87-.48,.38,0,.66,.22,.78,.53h.01c.09-.15,.19-.26,.31-.34,.17-.12,.35-.18,.61-.18,.36,0,.91,.23,.91,1.14v1.55h-.49v-1.49c0-.51-.19-.81-.6-.81-.29,0-.51,.2-.59,.44-.02,.06-.04,.15-.04,.24v1.63h-.49v-1.58c0-.42-.19-.72-.58-.72-.31,0-.54,.24-.62,.48-.03,.07-.04,.15-.04,.23v1.59h-.49v-1.92Z"/>
		<path d="M109.35,49.46l-.04-.33h-.02c-.15,.21-.45,.39-.84,.39-.56,0-.84-.38-.84-.76,0-.64,.59-.98,1.66-.98v-.05c0-.22-.06-.61-.63-.61-.26,0-.52,.08-.72,.2l-.11-.32c.23-.14,.56-.23,.91-.23,.84,0,1.05,.55,1.05,1.08v.98c0,.23,.01,.45,.05,.63h-.46Zm-.07-1.34c-.55-.01-1.17,.08-1.17,.59,0,.31,.22,.46,.47,.46,.36,0,.59-.22,.67-.44,.02-.05,.03-.1,.03-.15v-.46Z"/>
		<path d="M110.57,47.54c0-.27,0-.5-.02-.71h.44l.03,.44h.01c.14-.25,.46-.49,.91-.49,.38,0,.97,.22,.97,1.12v1.57h-.5v-1.52c0-.42-.17-.78-.64-.78-.33,0-.59,.22-.67,.49-.02,.06-.03,.14-.03,.22v1.58h-.5v-1.92Z"/>
		<path d="M115.7,49.36c-.13,.06-.42,.15-.79,.15-.83,0-1.37-.54-1.37-1.34s.58-1.4,1.48-1.4c.3,0,.56,.07,.7,.14l-.11,.37c-.12-.06-.31-.12-.58-.12-.63,0-.97,.45-.97,1,0,.61,.41,.98,.96,.98,.29,0,.47-.07,.62-.13l.09,.36Z"/>
		<path d="M116.53,48.23c.01,.65,.44,.91,.95,.91,.36,0,.58-.06,.76-.14l.09,.34c-.18,.08-.48,.16-.92,.16-.85,0-1.36-.53-1.36-1.33s.49-1.42,1.29-1.42c.9,0,1.14,.76,1.14,1.24,0,.1-.01,.17-.02,.22h-1.94Zm1.47-.34c0-.3-.13-.78-.7-.78-.51,0-.73,.45-.77,.78h1.46Z"/>
	</g>
</svg>
);

export default SvgSomei;
