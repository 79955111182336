import React from 'react';

const SvgZenner = (props: any) => (
<svg viewBox="0 0 125.55 18.66" {...props}>
	<polygon points="0 4.54 9.35 4.54 0 13.98 0 18.66 18.38 18.66 18.38 14.12 9.35 14.12 18.7 4.54 18.7 0 0 0 0 4.54"/>
	<polygon points="20.9 18.66 39.51 18.66 39.51 14.12 27.4 14.12 27.4 11.27 39.51 11.27 39.51 7.06 27.4 7.06 27.4 4.21 39.51 4.21 39.51 0 20.9 0 20.9 18.66"/>
	<polygon points="83.55 18.66 102.16 18.66 102.16 14.12 90.05 14.12 90.05 11.27 102.16 11.27 102.16 7.06 90.05 7.06 90.05 4.21 102.16 4.21 102.16 0 83.55 0 83.55 18.66"/>
	<polygon points="53.35 6.84 48.59 0 41.76 0 41.76 18.66 48.59 18.66 48.59 11.83 53.35 18.66 60.35 18.66 60.35 0 53.35 0 53.35 6.84"/>
	<polygon points="74.27 6.84 69.51 0 62.68 0 62.68 18.66 69.51 18.66 69.51 11.83 74.27 18.66 81.27 18.66 81.27 0 74.27 0 74.27 6.84"/>
	<path d="M114.33,9.33h-2.89V4.54h2.68c.65,0,2.17,.52,2.17,2.55s-1.95,2.25-1.95,2.25m5.64,3.61s3.4-1.16,3.4-6.08-4.34-6.87-5.72-6.87h-12.95V18.66h6.48v-4.77h.54l4.63,4.77h9.19l-5.57-5.71Z"/>
</svg>
);

export default SvgZenner;
