import React, { useState } from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
  } from "reactstrap";

const Menu = (props) => {
    const { content } = props
    return(
        <div className="center-menu" style={{justifyContent:"center", display:"flex", alignItems:"center"}}>
            <Nav navbar style={{width: "fit-content", height: "fit-content", flexDirection: "column", justifyContent: "space-between"}}>
            <NavItem className="menu-item li-0">
                <NavLink href="/">{content.accueil}</NavLink>
              </NavItem>
              <NavItem className="menu-item li-0">
                <NavLink href="/logiciels">{content.logiciels}</NavLink>
              </NavItem>
              <NavItem className="menu-item li-1">
                <NavLink href="/applications">{content.applications}</NavLink>
              </NavItem>
              <NavItem className="menu-item li-2">
                <NavLink href="/produits">{content.produits}</NavLink>
              </NavItem>
              <NavItem className="menu-item li-3">
                <NavLink href="/materiels">{content.materiels}</NavLink>
              </NavItem>
              <NavItem className="menu-item li-4">
                <NavLink href="/Support">{content.support}</NavLink>
              </NavItem>
              <NavItem className="menu-item li-5">
                <NavLink href="/Contact">{content.contact}</NavLink>
              </NavItem>
            </Nav>
        </div>
    )
}

const MiniMenu = (props) => {
    const { content, handleClick, clicked } = props

  return (
    <div className="mini-menu">
      <div class="hamburger" onClick={handleClick} style={{top:"2px"}}>
        <span class="hamburger__top-bun"></span>
        <span class="hamburger__bottom-bun"></span>
      </div>
      {clicked && <Menu content={content}/>}
    </div>
  );
};

export default MiniMenu;
