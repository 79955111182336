import React from 'react';

const SvgIntegra = (props: any) => (
    <svg viewBox="0 0 195.85 34.97" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M33.24,34.6h0a1.74,1.74,0,0,1-1.4-.91L19.16,11.77V33.48A1.15,1.15,0,0,1,18,34.6H14.53a1.17,1.17,0,0,1-1.17-1.12v-32A1.15,1.15,0,0,1,14.48.33H18a1.88,1.88,0,0,1,1.45.91l12.7,21.87V1.45A1.12,1.12,0,0,1,33.24.33h3.44a1.15,1.15,0,0,1,1.17,1.12v32a1.16,1.16,0,0,1-1.17,1.13H33.24"/>
			<path fill={props.fill} d="M68.85,5.36h-9.6V33.48a1.14,1.14,0,0,1-1.12,1.12H54.65a1.15,1.15,0,0,1-1.13-1.12V5.36H44a1.15,1.15,0,0,1-1.17-1.13V1.47A1.12,1.12,0,0,1,43.91.35H68.8A1.15,1.15,0,0,1,70,1.47V4.23a1.11,1.11,0,0,1-1.12,1.13h0"/>
			<path fill={props.fill} d="M1.12,34.6A1.14,1.14,0,0,1,0,33.48v-32A1.15,1.15,0,0,1,1.12.35H4.61A1.14,1.14,0,0,1,5.73,1.5v32a1.15,1.15,0,0,1-1.12,1.12H1.12"/>
			<path fill={props.fill} d="M96.19,34.6H76a1.16,1.16,0,0,1-1.13-1.12v-32A1.13,1.13,0,0,1,76,.33H96.15a1.15,1.15,0,0,1,1.17,1.12V4.21a1.14,1.14,0,0,1-1.13,1.12H80.66v9.24h12.7a1.16,1.16,0,0,1,1.17,1.15v2.76a1.2,1.2,0,0,1-1.12,1.12H80.66v10H96.15a1.16,1.16,0,0,1,1.17,1.14v2.76a1.23,1.23,0,0,1-1.13,1.15"/>
			<path fill={props.fill} d="M116.66,35h-2.81c-3.36,0-6.1-1-8-2.83S103,27.51,103,24.05V10.83a11,11,0,0,1,2.86-8C107.75,1,110.49,0,113.85,0h2.88c6.2,0,10,3.32,10.69,9.15a1.2,1.2,0,0,1-1.12,1.12h-3.49a1.13,1.13,0,0,1-1.12-1.08C121.2,6.25,119.61,5,116,5h-1.4c-4.19.07-5.73,1.66-5.8,5.85V24.07c.09,4.33,1.57,5.8,5.8,5.9H116c4.26-.1,5.73-1.57,5.8-5.87v-3H116A1.14,1.14,0,0,1,114.86,20V17.22A1.16,1.16,0,0,1,116,16.09h10.44a1.11,1.11,0,0,1,1.12,1.13v6.85c0,3.46-1,6.22-2.83,8.1s-4.63,2.8-8,2.8h0"/>
			<path fill={props.fill} d="M158.7,34.6h-4a1.63,1.63,0,0,1-1.4-1l-5.2-11.42c-.07,0-.16,0-.26,0h-7.2V33.48a1.11,1.11,0,0,1-1.12,1.12H136a1.17,1.17,0,0,1-1.12-1.26v-32a1.1,1.1,0,0,1,1.12-1H148.5c3.42,0,6.15,1,8,2.8s2.83,4.59,2.83,8h0v.16c0,4.77-1.89,8.24-5.42,9.78l5.56,12.12a.92.92,0,0,1,.14.49A.88.88,0,0,1,158.7,34.6ZM147.82,17.15c4.26-.1,5.73-1.57,5.8-5.83v-.11c-.09-4.29-1.56-5.76-5.75-5.85h-7.21V17.15Z"/>
			<path fill={props.fill} d="M190.68,34.6a1.51,1.51,0,0,1-1.43-1.22L180.34,8.61l-8.87,24.75a1.51,1.51,0,0,1-1.4,1.24h-3.93a1.22,1.22,0,0,1-1.24-1.19,1.62,1.62,0,0,1,.09-.59L177,1.47A1.58,1.58,0,0,1,178.35.3h4.07a1.51,1.51,0,0,1,1.4,1.2l11.89,31.27a1.47,1.47,0,0,1,.14.64,1.21,1.21,0,0,1-1.22,1.14h-3.95Z"/>
		</g>
	</g>
</svg>
);

export default SvgIntegra;
