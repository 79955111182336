import React, { Component } from "react";

import { Button } from "reactstrap";

import Android from "../../../_svg/Android";
import Onyx from "../../../_svg/Onyx";
import Saphir from "../../../_svg/Saphir";

export default class HeaderFiche extends Component<any>{
    render(){
        const { device, t, downloadFile } = this.props
        const content = {
          button: t('materials.download')
        }
        return(
            <div className="header-fiche">
                <div className="title">
                <h1>{device.name}</h1>
                </div>
                {device.systeme === "android" && (
                <div className="systeme-container">
                  <Android height="4em" fill="lightgrey" />
                  <Saphir height="4em" fill="#CF7830" style={{ marginLeft: "20px" }}/>
                  <Onyx
                    height="4em"
                    fill="#b77830"
                    style={{ marginLeft: "20px" }}
                  />
                </div>
              )}
              <Button onClick={() => downloadFile()}>{content.button}</Button>
              <div className="regle">
                    
              </div>
            </div>
        )
    }
}