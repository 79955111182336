import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import SvgLogoDioptase from "../_svg/LogoDioptase";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import MiniMenu from "./MiniMenu";

import LangSelector from "./LangSelector";

const SubMenu = (props) => (
  <ul className="submenu">
    {props.list.map((el, index) => (
      <li
        className={`submenu-item dropdown_item-${index} ${el.code || el.name.toLowerCase()}`}
      >
        <a
          href={`/${props.section}/${(el.code && el.code.toLowerCase()) ||
            (el.name && el.name.toLowerCase())
            }`}
        >
          {el.name}
        </a>
      </li>
    ))}
  </ul>
);
/*  { name: "Maconso.eco", color: "#D1D246", code: "maconso" } */
const logiciels = [
  { name: "Crystalcloud", color: "#31c6b3" },
  { name: "Intervention", color: "#E96F90", code: "intervention" },
];
const applications = [
  { name: "Saphir", color: "#CF7830" },
  { name: "Onyx", color: "#06D0E7" },
];
const produits = [
  { name: "Mbox", color: "#208491" },
];

const NavBarMenu = (props) => {
  const { device, job, t } = props;
  const [clicked, setClick] = useState(false);

  const content = {
    logiciels: t("all.software"),
    applications: t("all.applications"),
    produits: t("all.products"),
    materiels: t("all.materials"),
    support: t("all.support"),
    contact: t("all.contact"),
    accueil: t("all.home"),
    recrut: t("all.job.offer")
  };

  const activBurger = useMediaQuery({ query: "(max-width: 1040px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  console.log(window.location.pathname)
  function handleClick() {
    const hamburger = document.getElementsByClassName("hamburger")[0];
    const menu = document.getElementsByClassName("menu-header")[0];
    if (hamburger) {
      if (clicked) {
        hamburger.classList.remove("open");
        menu.classList.remove("open");
      } else {
        hamburger.classList.add("open");
        menu.classList.add("open");
      }
      setClick(!clicked);
    }
  }
  //<LangSelector />
  console.log(job)
  return (
    <div className="header">
      <Navbar className="sidebar-location-wrapper">
        <div className="menu-header" style={{ height: clicked ? "100vh" : "52px", alignItems: clicked ? "initial" : "center", justifyContent: "center", backgroundColor: clicked ? "black" : "#333" }}>
          <NavbarBrand href="/" className="logo-header" style={{ width: isMobile ? "fit-content" : "30%" }}>
            <a href="/">
              <SvgLogoDioptase fill="#fff" />
            </a>
          </NavbarBrand>
          {activBurger && <MiniMenu content={content} handleClick={handleClick} clicked={clicked} />}
          {!activBurger && (
            <Nav navbar>
              <NavItem className="menu-item">
                <NavLink href="/logiciels" style={{ color: window.location.pathname.includes("logiciels") ? "#b7803c" : "white" }}>{content.logiciels}</NavLink>
                <SubMenu list={logiciels} section="logiciels" />
              </NavItem>
              <NavItem className="menu-item">
                <NavLink href="/applications" style={{ color: window.location.pathname.includes("applications") ? "#b7803c" : "white" }}>{content.applications}</NavLink>
                <SubMenu list={applications} section="applications" />
              </NavItem>
              <NavItem className="menu-item">
                <NavLink href="/produits/mbox" style={{ color: window.location.pathname.includes("produits") ? "#b7803c" : "white" }}>{content.produits}</NavLink>
                <SubMenu list={produits} section="produits" />
              </NavItem>
              <NavItem className="menu-item">
                <NavLink href="/materiels" style={{ color: window.location.pathname.includes("materiels") ? "#b7803c" : "white" }}>{content.materiels}</NavLink>
                {device.devices && (
                  <SubMenu list={device.devices} section="materiels" />
                )}
              </NavItem>
              <NavItem className="menu-item">
                <NavLink href="/support" style={{ color: window.location.pathname.includes("Support") ? "#b7803c" : "white" }}>{content.support}</NavLink>
              </NavItem>
              <NavItem className="menu-item">
                <NavLink className="count-job" href="/recrutement" style={{ color: window.location.pathname.includes("recrutement") ? "#b7803c" : "white" }}>
                  {content.recrut}
                  {job.jobs && (
                    <div className="round">
                      <span className="span-count">
                        {job.jobs.length}
                      </span>
                    </div>
                  )}
                </NavLink>
              </NavItem>
              <NavItem className="menu-item">
                <NavLink href="/contact" style={{ color: window.location.pathname.includes("Contact") ? "#b7803c" : "white" }}>{content.contact}</NavLink>
              </NavItem>
            </Nav>
          )}
        </div>
      </Navbar>
    </div>
  );
};

function mapStateToProps(state) {
  const { device, job } = state;

  return {
    device,
    job,
  };
}

const connectedMenu = connect(mapStateToProps)(NavBarMenu);
const tr = withTranslation()(connectedMenu);
export default tr;
