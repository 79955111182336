import React from 'react';

const SvgOnyx = (props: any) => (
    <svg viewBox="0 0 48.41 48.41" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<circle fill="#43b9d4" cx="24.2" cy="24.2" r="24.2"/>
			<path fill="#fff" d="M12.87,24.62a11.66,11.66,0,0,1,3.2-8.41,10.86,10.86,0,0,1,8.13-3.33,10.74,10.74,0,0,1,8.1,3.33,11.56,11.56,0,0,1,3.23,8.41A11.48,11.48,0,0,1,32.3,33a10.77,10.77,0,0,1-8.1,3.32A10.89,10.89,0,0,1,16.07,33,11.67,11.67,0,0,1,12.87,24.62Zm4.14,0a8,8,0,0,0,2.07,5.75,6.75,6.75,0,0,0,5.12,2.21,6.76,6.76,0,0,0,5.13-2.21,8,8,0,0,0,2.07-5.75,8,8,0,0,0-2.07-5.71,7,7,0,0,0-10.25,0A8,8,0,0,0,17,24.62Z"/>
		</g>
	</g>
</svg>
);

export default SvgOnyx;
