import React from 'react';

const SvgItron = (props: any) => (
	<svg viewBox="0 0 95.46 37.08" {...props}>
		<path d="M65.66,18.34L81.84,0h-14.34l-4.92,18.03h.03c1.1,0,2.09,.1,3.05,.31Z"/>
		<path d="M63.64,27.54c-.57,1.98-2.62,3.54-4.67,3.54s-3.26-1.57-2.69-3.54c.53-1.98,2.58-3.54,4.64-3.54s3.26,1.57,2.73,3.54m2.02-9.2l-4.57,5.18,1.49-5.49c-6.23,.03-12.36,3.37-14.1,9.75-1.66,6.03,3.01,9.3,8.92,9.3s12.36-3.27,14.02-9.3c1.45-5.28-1.28-8.49-5.77-9.44m18.45,3.78c2.16-2.35,3.29-4.09,6.83-4.09,4.29,0,5.21,3.41,4.07,7.4l-3.05,11.21h-8.75l2.41-8.86c.32-1.06,.71-2.59-.85-2.59s-1.95,1.53-2.23,2.59l-2.44,8.86h-8.61l4.99-18.2h8.61l-.99,3.68Zm-36.9,3.17c-1.13-.07-1.91-.14-3.08,.03-2.55,.41-3.75,1.77-4.39,4.02l-2.02,7.29h-8.64l4.99-18.2h8.64l-1.31,3.68s2.44-4.09,7.86-4.09l-2.05,7.26Zm-19.55-6.68h3.12l-1.56,5.73h-3.01l-3.47,12.3H14.09l3.44-12.3h-2.27l1.56-5.73h2.2l1.7-6.03h8.64l-1.7,6.03ZM7.08,10.8H15.83l-7.08,25.83H0L7.08,10.8Z"/>
	</svg>
);

export default SvgItron;
