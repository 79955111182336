import React from 'react';

const SvgSagemcom = (props: any) => (
<svg viewBox="0 0 184 36.38" {...props}>
	<path d="M20.6,5.9s-1.46-3.42-4.49-4.9C13.08-.48,7.79-.03,5.98,.55,4.18,1.13,1.43,3.06,.72,5.47,0,7.88,.13,11.38,2.14,13.15c2,1.76,4.51,2.3,6.47,2.51,1.96,.22,5.7,.3,7.27,1.98,0,0,1.18,1.63,.19,3.48-.99,1.85-5.33,3.22-8.82,1.55,0,0-1.85-.94-2.95-3.22-.19-.4-.66-.59-1.08-.45l-2.63,.92c-.45,.16-.7,.64-.55,1.1,.33,1.03,1.04,2.78,2.33,3.92,1.91,1.7,3.68,2.77,8.17,2.73,4.49-.04,7.53-1.55,8.75-3.16,1.22-1.61,2.75-3.91,1.31-8.28-1.44-4.36-8.56-4.69-8.56-4.69,0,0-5.63-.24-6.54-2.26-.9-2.02,.17-3.72,1.83-4.41,1.66-.69,5.76-.68,7.27,.73,.86,.81,1.44,1.64,1.77,2.19,.23,.38,.69,.53,1.1,.37l2.66-1.1c.46-.19,.67-.72,.47-1.17"/>
	<path d="M53.94,23.09h-2.34c-1.78,0-3.22-1.44-3.22-3.21v-5.48c0-1.78,1.44-3.22,3.22-3.22h2.34c1.78,0,3.22,1.44,3.22,3.22v5.48c0,1.78-1.44,3.21-3.22,3.21m7.75-9.11c0-3.83-3.11-6.94-6.95-6.94h-4.14c-3.84,0-6.95,3.11-6.95,6.94v6.32c0,3.84,3.11,6.95,6.95,6.95h4.14c.82,0,1.6-.14,2.33-.4v2.16c0,1.78-1.44,3.22-3.22,3.22h-1.49c-.94,0-1.78-.4-2.37-1.04l-.16-.19-.7-.87c-.38-.47-1.06-.59-1.57-.26l-1.92,1.24c-.39,.25-.53,.75-.34,1.17,1.08,2.43,3.52,4.12,6.34,4.12h3.01c3.83,0,6.94-3.11,6.94-6.94v-8.06c.05-.35,.08-.71,.08-1.07v-6.32Z"/>
	<path d="M36.25,21.89c0,.66-.54,1.2-1.2,1.2h-2.37c-2.53,0-4.58-2.05-4.58-4.58v-2.8c0-2.5,2.03-4.53,4.53-4.53h1.45c1.2,0,2.18,.98,2.18,2.18v8.53Zm-.68-14.85h-4.19c-4.36,0-7.9,3.54-7.9,7.9v4.15c0,4.51,3.66,8.16,8.17,8.16l4.61-.4c0,.22,.18,.4,.4,.4h3.08c.61,0,1.1-.49,1.1-1.1V12.3c0-2.91-2.36-5.27-5.27-5.27"/>
	<path d="M77.51,15.25h-8.45v-1.04c0-1.84,1.49-3.33,3.33-3.33h1.79c1.84,0,3.33,1.49,3.33,3.33v1.04Zm-3.18-8.21h-2.06c-4.29,0-7.76,3.47-7.76,7.76v5.59c0,4,3.25,7.25,7.25,7.25h3.02c2.95,0,5.5-1.75,6.65-4.28,.21-.47,.03-1.02-.42-1.26l-1.83-1c-.45-.25-1.02-.1-1.29,.34-.86,1.4-2.41,2.33-4.17,2.33h-1.23c-1.89,0-3.42-1.53-3.42-3.42v-1.49h11.51c.84,0,1.52-.68,1.52-1.52v-2.55c0-4.28-3.47-7.76-7.76-7.76"/>
	<path d="M103.99,7.04h-1.85c-1.67,0-3.19,.63-4.34,1.66-1.19-1.04-2.74-1.67-4.45-1.67h-1.85c-3.59,0-6.49,2.91-6.49,6.49v12.87c0,.47,.38,.86,.86,.86h2.76c.47,0,.86-.38,.86-.86V14.23c0-1.49,1.2-2.69,2.69-2.69h.63c1.49,0,2.69,1.2,2.69,2.69v12.16c0,.47,.38,.86,.86,.86h.06s.06,0,.09,0h2.76c.15,0,.29-.04,.41-.1,.27-.14,.46-.43,.46-.76V14.04c.1-1.39,1.26-2.49,2.68-2.49h.63c1.49,0,2.69,1.2,2.69,2.69v12.16c0,.47,.38,.86,.86,.86h2.92c.47,0,.86-.38,.86-.86V13.81c0-3.74-3.03-6.77-6.77-6.77"/>
	<path d="M177.22,7.04h-1.85c-1.67,0-3.19,.63-4.34,1.66-1.19-1.04-2.74-1.67-4.45-1.67h-1.85c-3.59,0-6.49,2.91-6.49,6.49v12.87c0,.47,.38,.86,.86,.86h2.76c.47,0,.86-.38,.86-.86V14.23c0-1.49,1.2-2.69,2.69-2.69h.63c1.49,0,2.69,1.2,2.69,2.69v12.16c0,.47,.38,.86,.86,.86h.06s.06,0,.09,0h2.76c.15,0,.29-.04,.41-.1,.27-.14,.46-.43,.46-.76V14.04c.1-1.39,1.26-2.49,2.68-2.49h.63c1.49,0,2.69,1.2,2.69,2.69v12.16c0,.47,.38,.86,.86,.86h2.92c.47,0,.86-.38,.86-.86V13.81c0-3.74-3.03-6.77-6.77-6.77"/>
	<path d="M123.41,22.87c-3.19,0-5.75-2.7-5.53-5.93,.19-2.79,2.5-5.03,5.3-5.14,2.32-.09,4.34,1.24,5.25,3.2,.11,.23,.33,.38,.59,.38h3.38c.58,0,1.01-.56,.83-1.12-1.32-4.22-5.28-7.27-9.94-7.22-5.51,.06-10.05,4.53-10.18,10.05-.14,5.8,4.52,10.54,10.29,10.54,4.64,0,8.56-3.07,9.84-7.28,.17-.56-.25-1.12-.83-1.12h-3.37c-.26,0-.49,.16-.59,.39-.87,1.92-2.8,3.25-5.05,3.25"/>
	<path d="M140.23,17.35c0-3.19,2.7-5.75,5.93-5.53,2.79,.19,5.03,2.5,5.14,5.3,.09,2.32-1.24,4.34-3.2,5.25-.23,.11-.38,.34-.38,.59v3.38c0,.58,.56,1.01,1.12,.83,4.22-1.32,7.28-5.28,7.22-9.94-.06-5.51-4.53-10.05-10.05-10.18-5.8-.14-10.55,4.52-10.55,10.29,0,4.64,3.07,8.56,7.28,9.84,.56,.17,1.12-.25,1.12-.83v-3.37c0-.26-.16-.49-.39-.6-1.92-.87-3.25-2.8-3.25-5.05"/>
</svg>
);

export default SvgSagemcom;
