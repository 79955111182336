import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import SvgGSM from "../../_svg/SynchroGSM";
import VisibilitySensor from "react-visibility-sensor";
import { withTranslation } from 'react-i18next';

const App = (props) => {
  const ref = useRef();
  const { t } = props
  const content = {
    title: t('applications.saphir.gsm'),
    description: t('applications.saphir.gsm.description')
  }
  return (
              <div
                className={"svg-container"}
                id={"svg-GSM-saphir"}
                itemScope
                itemType="https://schema.org/WebPage"
              >
                <div>
                  <SvgGSM fill={"#b77830"}/>
                  <h3 itemProp="alternativeHeadline">{content.title}</h3>
                  <span itemProp="description">{content.description}</span>
                </div>
              </div>
  );
};

const tr = withTranslation()(App);
export default tr;
