import React from 'react';

const SvgMultiProtocole = (props: any) => (
	<svg viewBox="0 0 24 15.91" {...props}>
		<path fill={props.fill}
			  d="m17.25,9.3H6.75c-.36,0-.66.29-.66.63v5.34c0,.35.29.63.66.63h10.51c.36,0,.66-.28.66-.63v-5.34c0-.35-.29-.63-.66-.63"/>
		<path fill={props.fill}
			  d="m7.45,9.02h1.38c.13,0,.23-.1.23-.22v-.19c0-.12-.1-.22-.23-.22h-.02v-.42h.12c.07,0,.13-.06.13-.12v-.85c0-.07-.06-.12-.13-.12h-.12v-.18c0-.11-.09-.19-.2-.19h-.21v-3.84s.06-.09.06-.15V.22c0-.12-.1-.22-.22-.22h-.19c-.12,0-.23.1-.23.22v2.31c0,.06.02.11.06.15v3.84h-.21c-.11,0-.2.09-.2.19v.18h-.12c-.07,0-.13.05-.13.12v.85c0,.07.06.12.13.12h.12v.42h-.02c-.13,0-.23.1-.23.22v.19c0,.12.1.22.23.22"/>
	</svg>
);

export default SvgMultiProtocole;
