import React from 'react';
export default { 
translation: { 
"all.software":"Softwares", 
"all.applications":"Apps", 
"all.products":"Products", 
"all.materials":"Equipment", 
"all.support":"Support", 
"all.contact":"Contact", 
"all.button.download":"Upload our informative documents", 
"all.button.more":"Find out more", 
"all.compatible":"Compatible with", 
"all.home":"Home", 
"all.recap":"Recap", 
"all.home.title.1":"Global remote", 
"all.home.title.2":"meter reading solution", 
"all.home.title.3":"at distance", 
"all.home.title.application":"Meter reading application", 
"all.home.title.software":"Round management software & meter park", 
"all.home.title.01":"Walk by meter collection", 
"all.home.need":"You need", 
"all.home.saphir":"Saphir application", 
"all.home.equipped.dwellings":"Eqquiped homes with radio meters", 
"all.home.title.02":"Drive by meter collection", 
"all.home.equipped.antenna":"A vehicle with an antenna", 
"all.home.with.mbox":"And a Mbox", 
"all.home.title.03":"Remote reading", 
"all.home.dld.meter":"A DLD equipped meter", 
"all.home.equipped.meter":"or a meter equipped with", 
"all.home.crystalcloud":"Crystalcloud software", 
"all.home.low.debit":"Do you have a good connection ? Test the advanced version of the site !", 
"all.home.access":"To access", 
"all.scroll.down":" scroll down to discover our solutions", 
"all.job.offer":"Job offer", 
"contact.bymail":"Via e-mail", 
"contact.byphone":"Via telephone", 
"contact.name":"Last name", 
"contact.firstname":"First name", 
"contact.mail":"E-mail", 
"contact.object":"Suject", 
"contact.message":"Message", 
"contact.send":"Send", 
"contact.free.number":"a non-taxable number", 
"contact.error":"Please complete the missing fields", 
"contact.send.mail":"Your email has been sent", 
"contact.error.mail":"Invalid email address", 
"logiciels.maconso.description":" Consumption monitoring portal dedicated to subscribers", 
"logiciels.cc.description":"Management portal for meter groups and all commuting objects", 
"logiciels.cc.synchronisation.description":"Upload and Unload your routes remotely", 
"logiciels.cc.synchronisation":"Synchronization", 
"logiciels.cc.vm":"Virtual meter", 
"logiciels.cc.alertes":"Alerts", 
"logiciels.cc.mask":"Alarm masks", 
"logiciels.cc.mask.description":"Select then mask your alarms", 
"logiciels.cc.dashboard":"Dashboard", 
"logiciels.cc.dashboard.description":"Create data-based graphs", 
"logiciels.tm.cable":"Cable", 
"logiciels.tm.wifi":"WiFi", 
"logiciels.tm.internet":"Internet", 
"logiciels.tm.details":"Route details", 
"logiciels.tm.modul":"Route module", 
"logiciels.tm.monoposte":"Single station software", 
"logiciels.cc.vm.description":"Combine several physical meters into one and compare it (virtual meter) to the sector / general meter", 
"logiciels.cc.alertes.description":"In addition to the basic management of manufacturer alarms, create your own alerts in order to be notified by email.", 
"logiciels.maconso.incoming":"Your management portal is coming soon !", 
"applications.filter":"Filters", 
"applications.saphir.multi.protocol":"Multi-protocol", 
"applications.saphir.multi.protocol.description":"App compatible with multi-protocol radio meters", 
"applications.saphir.switch":"Change or add a radio", 
"applications.saphir.switch.description":"Saphir controls all the tools for managing on-site interventions", 
"applications.saphir.filter.description":"Use and combine personable filters directly from your device", 
"applications.saphir.concat":"Sequencing routes", 
"applications.saphir.concat.description":"Merge your routes uploaded on your device easily", 
"applications.saphir.geoguiding":"Geoguiding", 
"applications.saphir.geoguiding.description":"Let your device guide you to your meters", 
"applications.saphir.gsm.description":"Upload and unload your routes remotely", 
"applications.saphir.geolocation":"Geolocation", 
"applications.saphir.geolocation.description":"Locate and visualise the position of your meters from your device", 
"applications.saphir.support":"Remote support", 
"applications.saphir.support.description":"Our tech support team can remotely accompany you in handling your device", 
"applications.saphir.security":"Security", 
"applications.saphir.security.description":"Protect the access to certain app settings thanks to a password", 
"applications.onyx.filter.description":"Filter data thanks to 4 criteras: alarms, meter type, manufactuer and serial number", 
"applications.onyx.export":"Export", 
"applications.onyx.export.description":"You can export your data through CSV or toward a FTP server", 
"applications.saphir.gsm":"GSM synchronization", 
"applications.onyx.fluid":"multi-fluid", 
"applications.onyx.fluid.description":"Onyx measure unidirectional radio set up on meters for any type of energy.", 
"produits.how":"How", 
"produits.work":"it works", 
"produits.cc.synchronisation":"Management portal for meter groups and all commuting objects", 
"produits.dld.description":"Meter group management solutions", 
"produits.dld.installation":"DLD can be installed on one or two meters. It connects to our CrystalCloud server to send stored readings. The recording interval of the indexes is configurable as is the transmission frequency.", 
"produits.mbox.frames":"Read and send Wireless M-Bus and OMS raw frames of all devices around it. Specially designed for the mobile radio-relay (drive-by, walk-by), it transmits by GSM all the data to the processing system.", 
"produits.mbox.description":"All meter-reading solutions compatible with wMbus", 
"produits.mbox.raise":"Read all meters", 
"produits.mbox.compatible.wmbus":"Compatible with wMbus", 
"produits.mbox.raise.wmbus":"Read all the meters compatible with wMbus", 
"produits.mbox.plug":"Plug'n'play", 
"produits.mbox.plug.description":"Especially designed to mobile AMR meter reading (drive-by, walk-by), it is passed by GSM all data to treatment system.", 
"produits.mbox.raise.wmbus.description":"Allow to read and send Wireless M-Bus and OMS raw frame of all devices lying around it.", 
"materials.comparator":"Comparator", 
"materials.dimension":"Dimensions", 
"materials.weight":"Weight", 
"materials.resolution":"Resolution", 
"materials.bluetooth":"Bluetooth", 
"materials.waterproofing":"Sealing", 
"materials.camera":"Camera", 
"materials.memory":"Memory", 
"materials.battery":"Battery", 
"materials.download":"Download the datasheet", 
"materials.characteristics":"Characteristics", 
"materials.with.battery":"with longlife battery", 
"support.formation":"Training course", 
"support.remote.maintenance":"Remote maintenance", 
"support.remote":"Remotely", 
"support.on.site":"On site", 
"support.our":"In our", 
"support.offices":"offices", 
"support.hotline":"A hotline", 
"support.without.wait":"With no waiting time", 
"support.direct.number":"Direct number with no extra charges", 
"support.update":"Software", 
"support.software":"Updates", 
"support.formation.need":"On request we offer training packages adapted to your needs:", 
"support.download.quicksupport":"Download QuickSupport", 
"error.title":"error", 
"error.description":"The page you attempted to access was not found,", 
"error.retry":"please try again later.", 
"error.connexion":"Slow connection", 
"error.connexion.description":"Your connexion is too slow to make the most of optimal user experience of our website. Do you want to use a simplified version ?", 
"error.valid":"Confirm", 
"error.cancel":"Cancel", 
"sitemap.title":"Dioptase sitemap", 
"sitemap.about":"About", 
"sitemap.cc":"Crystalcloud", 
"home.crystalcloud.description":"Management and monitoring portal for multi-fluid meters with remote reading. Compatible with every billings, you can import and export your rounds from any computer. View the progress of your readings in real time with all its information: index, consumption, alerts, location, etc ...", 
"home.saphir.description":"Our Saphir application, intended for field agents: fountain engineer, technical agent, meter reader... Allows you to read the index of water meters manually or by using a radio. Compatible with all meter and radio manufacturers, read all your meters remotely with a single application!", 
"home.mbox.description":"The MBox allows you to read all your meters (WMBus compatible) in complete transparency. It installs simply and quickly in your vehicle. When the latter starts, the MBox turn on and immediately reads the counters and transmits them to Crystalcloud.", 
"home.compatible.billing":"Compatible with all billings", 
"home.compatible.manufacturer":"Compatible with any manufacturer", 
"home.read.foot":"Walk by read (radio or manual)", 
"home.read.car":"Car radio reading", 
"home.remote.reading":"Remote reading", 
"home.news":"news", 
"home.how":"How it works", 
"home.silex.api":"Silex or API", 
"job.title":"is recruting", 
"job.description.1":"Do you want to join a young and dynamic team?", 
"job.description.2":"Send us your application", 
"job.details":"Job details", 
"job.details.description":"Jo description", 
"job.profil":"Required profile", 
"job.advantage":"Advantages", 
"job.candidate":"Apply", 
"job.type":"Type of contract", 
"job.salary":"Salary", 
"job.share":"share offer", 
"intervention.multi.users":"Multi users", 
"intervention.multi.users.description":"You can have multiple user accounts with different accessibility levels.", 
"intervention.contract.abo":"Subscriber contract", 
"intervention.contract.abo.description":"Create your subscription contracts with your subscribers and co-subscribers with attachments: identity card, proof of address, etc.", 
"intervention.switch.meter":"Meter replacement", 
"intervention.switch.meter.description":"Simply create your meter change sheets.", 
"intervention.electronic.signature":"Electronic signature", 
"intervention.electronic.signature.description":" You can sign your agent but also your customers. With a simple gesture using a pen or your finger. The electronic signature makes your intervention official.", 
"intervention.termination.contract":"Termination of the contract", 
"intervention.termination.contract.description":"Simply terminate your contracts by retrieving information from your meter and your followers for immediate or future billing.", 
"intervention.email.description":"Once the intervention is created it is instantly shared by email in your services and to your subscribers.", 
"intervention.email":"Email", 
"intervention.description":"The interface, simple and intuitive, allows you to manage the replacement of your meters as well as the update of your subscription contracts.", 
"intervention.resume":"Intervention module allowing dematerialization and automation.", 
"maconso.interface":"Interface", 
"maconso.interface.description":" 2 planned environments, one for managers and the second for subscribers", 
"maconso.followed":"Consumption monitoring", 
"maconso.followed.description":"General consumption monitoring of all your accommodation/subscriber meters", 
"maconso.subscribers":"Subscribers", 
"maconso.subscribers.description":"Consumption details for each housing meters per subscriber", 
"maconso.alarms":"Alarms", 
"maconso.alarms.description":"Monitoring and personalized management of meters in alarm", 
"maconso.datas":"Datas", 
"maconso.datas.description":"Configurable datas frequency of provision (daily, monthly, etc.)", 
} 
}; 
