import React, { Component } from 'react';
import SchemaDld from "../../_resources/image/sch_DLD.png"

export default class Explication extends Component<any, any>{
    render(){
        return(
            <div className="explication-container" itemScope itemType="https://schema.org/Thing">
                <img itemProp="image" src={SchemaDld} width="95%" alt="dld"/>
            </div>
        )
    }
}