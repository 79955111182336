import React, { useState, useEffect } from "react";
import "./style.scss";
import "../../Responsive.scss";
import './responsiv.scss';
import LowDebitHome from "./LowDebitHome";
import SmartphoneHome from "./SmartphoneHome";
import BestDioptaseHome from "./BestDioptaseHome";

import ScrollSequence from "../scroll/ScrollSequence";
import images from "../../_resources/videoMain";
import Footer from "../section/FooterSection";
import ImageSelector from "../responsive/ImageSelector";
import fullScreen from "../../_resources/image/full_screen_accueil.jpg";
import fullScreen2 from "../../_resources/image/full_screen_accueil2.jpg";
import SvgExit from "../../_svg/Exit"
import SvgCroix from "../../_svg/Cross"

import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga4";
import { withTranslation } from "react-i18next";

import { Helmet } from "react-helmet-async";

const Dioptase = (props) => {
  const { t, i18n } = props;
  const [isTablet, setIsTablet] = useState(false);
  const [lowDebit, setLowDebit] = useState(true);
  const [init, setInit] = useState(false);
  const [cancelBand, setCancelBand] = useState(false);
  const tablet = useMediaQuery({ query: "(max-width: 1040px)" });
  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview("/");
  });
  
  document.getElementsByTagName("html")[0].lang = i18n.language

  if (!init) {
    setIsTablet(tablet);
    setInit(true);
  }

  const realWidth = 1920 - window.innerWidth;

  return (
    <div className={"crystalcloud-container home"} style={{ display: "grid" }}>
      {lowDebit && !cancelBand && !isTablet && (
        <div className="low-debit-bar">
          <div className="center">
            <span>{t("all.home.low.debit")}</span>
            <div onClick={() => setLowDebit(false)}>{t("all.home.access")}</div>
          </div>
          <div style={{width: "fit-content", marginRight: "5px", cursor: "pointer"}} onClick={() => setCancelBand(true)} >
            <SvgCroix height="1.3em" fill="black"/>
            </div>
        </div>
      )}
      {lowDebit ? (
        <BestDioptaseHome />
      ) : (
        <ScrollSequence
          className="dioptase-container"
          height={window.innerHeight}
          width={window.innerWidth}
          index={0}
          mBottom={0}
          images={images("", "fr")}
          duration={35000}
          home
          fixed
          stretch
          zoom={1 - 0.25 * (realWidth / 640)}
          y={0}
          style={{
            marginTop: "0",
            height: `${window.innerHeight}px`,
            zIndex: "0",
          }}
        />
      )}
      {!lowDebit && (
        <div className="first-version-return" onClick={() => {
          setLowDebit(true)
          setCancelBand(true)
          }}>
          <SvgExit height="2em" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default withTranslation()(Dioptase);
