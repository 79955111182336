import React from 'react';
export default { 
translation: { 
"all.software":"Logiciels", 
"all.applications":"Applications", 
"all.products":"Produits", 
"all.materials":"Matériels", 
"all.support":"Support", 
"all.contact":"Contact", 
"all.button.download":"Télécharger notre documentation", 
"all.button.more":"En savoir plus", 
"all.compatible":"Compatible avec", 
"all.home":"Accueil", 
"all.recap":"Récapitulatif", 
"all.home.title.1":"Solution globale", 
"all.home.title.2":"de relève de compteurs", 
"all.home.title.3":"à distance", 
"all.home.title.application":"Applications de relève de compteurs", 
"all.home.title.software":"Logiciels de gestion tournées & de parc compteurs", 
"all.home.title.01":"La relève walk by", 
"all.home.need":"Il vous faut", 
"all.home.saphir":"L'application saphir", 
"all.home.equipped.dwellings":"Des habitations équipées de compteurs radio", 
"all.home.title.02":"La relève drive by", 
"all.home.equipped.antenna":"Un véhicule équipé d'une antenne", 
"all.home.with.mbox":"Et d'une Mbox", 
"all.home.title.03":"La télérelève", 
"all.home.dld.meter":"Un compteur équipé DLD", 
"all.home.equipped.meter":"Ou un compteur équipé", 
"all.home.crystalcloud":"Le logiciel crystalcloud", 
"all.home.low.debit":"Vous avez une bonne connexion ? Testez la version avancée de notre site !", 
"all.home.access":"Accéder", 
"all.scroll.down":"faites défiler pour découvrir nos solutions", 
"all.job.offer":"Offre d'emploi", 
"contact.bymail":"Par mail", 
"contact.byphone":"Par téléphone", 
"contact.name":"Nom", 
"contact.firstname":"Prénom", 
"contact.mail":"Mail", 
"contact.object":"Objet", 
"contact.message":"Message", 
"contact.send":"Envoyer", 
"contact.free.number":"numéro non surtaxé", 
"contact.error":"Veuillez compléter le(s) champ(s) manquant(s)", 
"contact.send.mail":"Votre mail a été envoyé.", 
"contact.error.mail":"Adresse mail non valide.", 
"logiciels.maconso.description":"Portail de suivi de consommation dédié aux abonnés", 
"logiciels.cc.description":"Portail de gestion d'un parc compteurs et de tout objet communicant", 
"logiciels.cc.synchronisation.description":"Chargez / Déchargez vos tournées à distance", 
"logiciels.cc.synchronisation":"Synchronisation", 
"logiciels.cc.vm":"Compteur virtuel", 
"logiciels.cc.alertes":"Alertes", 
"logiciels.cc.mask":"Masque d'alarmes", 
"logiciels.cc.mask.description":"Sélectionner puis masquer vos alarmes.", 
"logiciels.cc.dashboard":"Tableau de bord", 
"logiciels.cc.dashboard.description":"Créer des graphiques basés sur vos données", 
"logiciels.tm.cable":"Câble", 
"logiciels.tm.wifi":"Wifi", 
"logiciels.tm.internet":"Internet", 
"logiciels.tm.details":"Détails tournées", 
"logiciels.tm.modul":"Module de tournée", 
"logiciels.tm.monoposte":"Logiciel monoposte", 
"logiciels.cc.vm.description":"Regroupez plusieurs compteurs physiques en un et comparez ce dernier (compteur virtuel) au compteur de sectorisation/général.", 
"logiciels.cc.alertes.description":"En plus de la gestion basique des alarmes constructeurs, créez vos propres alertes dans la perspective d’être averti par mail.", 
"logiciels.maconso.incoming":"Votre portail de gestion est bientôt disponible !", 
"applications.filter":"Filtres", 
"applications.saphir.multi.protocol":"Multi-protocoles", 
"applications.saphir.multi.protocol.description":"Application compatible avec les compteurs multi-protocoles radio.", 
"applications.saphir.switch":"Changement et ajout de compteur", 
"applications.saphir.switch.description":"SAPHIR pilote désormais l'ensemble de ces outils permettant de gérer des interventions sur site.", 
"applications.saphir.filter.description":"Utilisez et combinez un ensemble de filtres personnalisables directement sur votre appareil.", 
"applications.saphir.concat":"Concaténations des tournées", 
"applications.saphir.concat.description":"Fusionnez les tournées chargées sur votre appareil simplement.", 
"applications.saphir.geoguiding":"Géoguidage", 
"applications.saphir.geoguiding.description":"Laissez votre appareil vous guider vers vos compteurs.", 
"applications.saphir.gsm.description":"Chargez / Déchargez vos tounées à distance.", 
"applications.saphir.geolocation":"Géolocalisation", 
"applications.saphir.geolocation.description":"Localisez et visualisez sur votre appareil la position de vos compteurs.", 
"applications.saphir.support":"Support à distance", 
"applications.saphir.support.description":"Nos techniciens support peuvent vous accompagner à distance en prenant la main sur votre appareil.", 
"applications.saphir.security":"Sécurité", 
"applications.saphir.security.description":"Protégez par mot de passe l'accès à certains réglages de l'application.", 
"applications.onyx.filter.description":"Filtrer les données via 4 critères: alarmes, type de compteur, fabricant et numéro de série.", 
"applications.onyx.export":"Export", 
"applications.onyx.export.description":"Vous pouvez exporter vos données au format CSV ou vers un serveur FTP.", 
"applications.saphir.gsm":"Synchronisation GSM", 
"applications.onyx.fluid":"Multi-fluide", 
"applications.onyx.fluid.description":"Onyx relève les radios unidirectionnelles installées sur les compteurs de tout type d'énergie.", 
"produits.how":"Comment", 
"produits.work":"ça marche", 
"produits.cc.synchronisation":"Portail de gestion d'un parc compteurs et de tout objet communicant.", 
"produits.dld.description":"Solution pour tout gestionnaire de parc compteurs", 
"produits.dld.installation":"DLD peut être installé sur un ou deux compteurs. Il se connecte sur notre serveur CrystalCloud afin d’y envoyer les lectures mémorisées. L’intervalle d’enregistrement des indexes est paramétrable tout comme la fréquence d’émission.", 
"produits.mbox.frames":"Permet de lire et d’envoyer les trames brutes Wireless M-Bus et OMS de tous les appareils se trouvant autour de lui. Spécialement conçu pour la radio-relève mobile (drive-by, walk-by), il transmet par GSM l’ensemble des données au système de traitement.", 
"produits.mbox.description":"Solution pour toute releve de compteur compatible wMbus", 
"produits.mbox.raise":"Releve tous les compteurs", 
"produits.mbox.compatible.wmbus":"compatibles wMbus", 
"produits.mbox.raise.wmbus":"Releve tous les compteurs compatible wMbus", 
"produits.mbox.plug":"Plug'n play", 
"produits.mbox.plug.description":"Spécialement conçu pour la radio-relève mobile (drive-by, walk-by), il transmet par GSM l’ensemble des données au système de traitement.", 
"produits.mbox.raise.wmbus.description":"Permet de lire et d’envoyer les trames brutes Wireless M-Bus et OMS de tous les appareils se trouvant autour de lui.", 
"materials.comparator":"Comparateur", 
"materials.dimension":"Dimensions", 
"materials.weight":"Poids", 
"materials.resolution":"Résolution", 
"materials.bluetooth":"Bluetooth", 
"materials.waterproofing":"Etanchéité", 
"materials.camera":"Appareil photo", 
"materials.memory":"Mémoire", 
"materials.battery":"Batterie", 
"materials.download":"Télécharger la fiche technique", 
"materials.characteristics":"Caractéristiques", 
"materials.with.battery":"avec batterie longue durée", 
"support.formation":"Formation", 
"support.remote.maintenance":"Télémaintenance", 
"support.remote":"A distance", 
"support.on.site":"Sur site", 
"support.our":"Dans nos", 
"support.offices":"Bureaux", 
"support.hotline":"Une hotline", 
"support.without.wait":"Sans temps d'attente", 
"support.direct.number":"Numéro direct non-surtaxé", 
"support.update":"Mises à jour", 
"support.software":"de vos logiciels", 
"support.formation.need":"Sur demande, nous vous proposons des forfaits de formations adaptés à vos besoins :", 
"support.download.quicksupport":"Télécharger QuickSupport", 
"error.title":"erreur", 
"error.description":"La page que vous souhaitez consulter est en maintenance,", 
"error.retry":"merci de réessayer plus tard.", 
"error.connexion":"Connexion lente", 
"error.connexion.description":"Votre connexion ne vous permet pas de profiter d'une expérience optimale de notre site. Voulez-vous passer sur une version simplifiée ?", 
"error.valid":"Valider", 
"error.cancel":"Annuler", 
"sitemap.title":"Plan du site dioptase", 
"sitemap.about":"A propos", 
"sitemap.cc":"Crystalcloud", 
"home.crystalcloud.description":"Portail de gestion et de suivi de parcs compteurs multi-fluides en télérelève. Compatible toutes facturations, importez et exportez vos tournées à partir de n'importe quel ordinateur. Visualisez en temps réel l'état d'avancement de vos relèves avec toutes ses informations: index, consommation, alertes, localisation ...", 
"home.saphir.description":"Notre application Saphir, destinée aux agents de terrain: fontainier, agent technique, releveur... Vous permet une relève d'index de compteurs d'eau manuelle ou radio. Compatible avec tous les fabricants de compteurs et radio, relevez tous vos compteurs à distance avec une seule et même application !", 
"home.mbox.description":"La MBox vous permet de relever tous vos compteurs (compatible WMBus) en toute transparence. Elle s'installe simplement et rapidement dans votre véhicule. Au démarrage de ce dernier, la MBox se réveille et relève immédiatement les compteurs et les transmet à Crystalcloud.", 
"home.compatible.billing":"Compatible toutes facturations", 
"home.compatible.manufacturer":"Compatible tous fabricants", 
"home.read.foot":"Relève à pieds (radio ou manuelle)", 
"home.read.car":"Relève radio en voiture", 
"home.remote.reading":"Télérelève", 
"home.news":"Actualités", 
"home.how":"Comment ca marche", 
"home.silex.api":"Silex ou API", 
"job.title":"recrute", 
"job.description.1":"Vous souhaitez intégrer une équipe jeune et dynamique ? ", 
"job.description.2":"Envoyez nous votre candidature", 
"job.details":"Détails du poste", 
"job.details.description":"Description du poste", 
"job.profil":"Profil recherché", 
"job.advantage":"Avantages", 
"job.candidate":"Candidater", 
"job.type":"Type de contrat", 
"job.salary":"Salaire", 
"job.share":"Partager l'offre", 
"intervention.multi.users":"Multi-utilisateurs", 
"intervention.multi.users.description":"Vous pouvez disposer de plusieurs comptes utilisateurs avec des niveaux d'accessibilité différents.", 
"intervention.contract.abo":"Contrat d'abonnement", 
"intervention.contract.abo.description":"Créez vos contrats d'abonnement avec vos abonnés et co-abonnés avec des pièces jointes: carte d'identité, justificatif de domicile etc.", 
"intervention.switch.meter":"Remplacement de compteur", 
"intervention.switch.meter.description":"Créez simplement vos fiches de changement de compteur.", 
"intervention.electronic.signature":"Signature électronique", 
"intervention.electronic.signature.description":"Vous pouvez faire signer votre agent mais aussi vos clients. D'un simple geste à l'aide d'un stylet ou avec votre doigt. La signature électronique permet d'officialiser votre intervention.", 
"intervention.termination.contract":"Résiliation du contrat", 
"intervention.termination.contract.description":"Résiliez simplement vos contrats en récupérant les informations de votre compteur et de vos partants pour une facturation immédiate ou ultérieure.", 
"intervention.email.description":"Une fois l'intervention créée elle est instantanément partagée par mail dans vos services et à vos abonnés.", 
"intervention.email":"Email", 
"intervention.description":"L’interface, simple et intuitive, vous permet la gestion du remplacement de vos compteurs ainsi que la mise à jour de vos contrats d’abonnement. ", 
"intervention.resume":"Module d'interventions permettant la dématerialisation et l’automatisation.", 
"maconso.interface":"Interface", 
"maconso.interface.description":"2 environnements prévus, un pour les gestionnaires et le second pour les abonnés", 
"maconso.followed":"Suivi de conso", 
"maconso.followed.description":"Suivi de consommation générale de tous les compteurs de vos logements/abonnés", 
"maconso.subscribers":"Abonnés", 
"maconso.subscribers.description":"Détail de consommation de chacun des compteurs du logement et par abonné", 
"maconso.alarms":"Alarmes", 
"maconso.alarms.description":"Suivi et gestion personnalisé des compteurs en alarme", 
"maconso.datas":"Données", 
"maconso.datas.description":"Fréquence de mise à disposition des données paramétrables (journalière, mensuelle...)", 
} 
}; 
