import React from 'react';

const SvgDimension = (props: any) => (
	<svg viewBox="0 0 15 11.07" {...props}>
		<path fill={props.fill}
			  d="m12.71,8.33h-1.46V2.16l1.71-1.74c.07-.07.1-.16.1-.25,0-.1-.04-.18-.11-.25-.14-.14-.36-.14-.5,0l-1.81,1.83h-5.99V.17c0-.2-.16-.36-.35-.36s-.35.16-.35.36v1.58h-1.66c-.2,0-.36.16-.36.36s.16.35.36.35h1.66v6.22s0,0,0,0c0,.05,0,.09.03.14.02.04.04.08.08.11,0,0,0,0,0,0,.01.01.03.02.05.03.02.01.04.03.06.04.04.02.09.03.14.03h6.23v1.5c0,.2.16.36.36.36s.35-.16.35-.36v-1.5h1.46c.2,0,.35-.16.35-.35s-.16-.36-.35-.36Zm-8.05-.51V2.47h5.28l-5.28,5.35Zm5.88-4.94v5.45h-5.38l5.38-5.45Z"/>
	</svg>
);

export default SvgDimension;
