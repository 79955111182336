import React, { Component } from "react";
import { connect } from "react-redux";

import SvgLogiciels from "../../_svg/Logiciel";
import SvgApplications from "../../_svg/Logiciels";
import SvgProduits from "../../_svg/Produit";
import SvgMateriels from "../../_svg/Materiel";

import Materiels from "./content/Materiels";
import Logiciels from "./content/Logiciels";
import Applications from "./content/Applications";
import deviceAction from "../../redux/device/device.action";

class Compatibilite extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selected: props.section || "",
      hover: "",
    };
  }

  componentDidMount() {
    const { dispatch, device } = this.props;

    if (!device.devices) {
      dispatch(deviceAction.getAll());
    }
  }

  handleClick = (title: string) => {
    const { selected } = this.state;
      this.setState({
        selected: title,
      });
  };

  handleHover = (title: string) => {
    this.setState({
      hover: title,
    });
  };

  handleLeave = () => {
    this.setState({
      hover: "",
    });
  };

  displaySection = () => {
    const { device, parentColor, t } = this.props;
    const { selected } = this.state;
    console.log(selected)
    switch (selected) {
      case "Matériels":
        return <Materiels devices={device.devices} parentColor={parentColor} t={t}/>;
      case "Logiciels":
        return <Logiciels parentColor={parentColor} t={t}/>;
      case "Applications":
        return <Applications parentColor={parentColor} t={t}/>;
    }
  };

  filterMenu = () => {
    const { parent, parentColor, t } = this.props;
    const { selected, hover } = this.state
    console.log(selected, selected === "Matériels")
    const menu = (parentColor: string, selected: string, hover: string) => [
      {
        title: t('all.software'),
        svg: <SvgLogiciels height={(selected === "Logiciels" || hover === "Logiciels") ? "4em" : "1.5em"} fill={(selected === "Logiciels" || hover === "Logiciels") ? parentColor : "lightgrey"} />,
      },
      {
        title: t('all.applications'),
        svg: <SvgApplications height={(selected === "Applications" || hover === "Applications") ? "4em" : "1.5em"} fill={(selected === "Applications" || hover === "Applications") ? parentColor : "lightgrey"} />,
      },
      {
        title: t('all.products'),
        svg: <SvgProduits height={(selected === "Produits" || hover === "Produits") ? "4em" : "1.5em"} fill={(selected === "Produits" || hover === "Produits") ? parentColor : "lightgrey"} />,
      },
      {
        title: t('all.materials'),
        svg: <SvgMateriels height={(selected === "Matériels" || hover === "Matériels") ? "4em" : "1.5em"} fill={(selected === "Matériels" || hover === "Matériels") ? parentColor : "lightgrey"} />,
      },
    ];

    switch (parent) {
      case "DLD":
        return menu(parentColor, selected, hover).filter((el: any) => el.title === t('all.software'));
      case "saphir":
        return menu(parentColor, selected, hover).filter(
          (el: any) => el.title !== t('all.products') && el.title !== t('all.applications')
        );
      case "onyx":
        return menu(parentColor, selected, hover).filter(
          (el: any) => el.title === t('all.materials') || el.title === t('all.software')
        );
      case "mbox":
        return menu(parentColor, selected, hover).filter((el: any) => el.title === t('all.software'));
      case "FicheTechnique":
        return menu(parentColor, selected, hover).filter(
          (el: any) => el.title === t('all.applications')
        );
      case "crystalcloud":
        return menu(parentColor, selected, hover).filter(
          (el: any) => el.title === t('all.applications')
        );
      case "intervention":
        return menu(parentColor, selected, hover).filter((element: any) => element.title === t('all.materials'))
      default:
        return menu("#000", selected, hover);
    }
  };

  render() {
    const { t } = this.props
    const { hover, selected } = this.state;
    const filteredMenu = this.filterMenu();
    const content = {
      title: t('all.compatible')
    }
    console.log(selected)
    return (
      <div className="compatible-container">
        <div className="menu-compatible">
          <div className="text-container" style={{width: filteredMenu && filteredMenu.length > 1 ? "50%" : "100%"}}>
            <h2 style={{textAlign: "center"}}>{content.title}</h2>
          </div>
          {filteredMenu && filteredMenu.length > 1 && (
            <div className="sections">
              {filteredMenu.map((el: any, index: number) => (
                <div
                  className="section-svg"
                  onMouseEnter={() => this.handleHover(el.title)}
                  onClick={() => this.handleClick(el.title)}
                  onMouseLeave={this.handleLeave}
                  style={{borderRight: index === (filteredMenu.length - 1) ? "none" : "1px solid grey", width: `${100 / filteredMenu.length}%`}}
                >
                    <div className="svg-compatible">{el.svg}</div>
                  <span style={{fontSize: (selected === el.title || hover === el.title) ? "30px" : "16px", marginTop: (selected === el.title || hover === el.title) ? "10px" : "2px"}}>
                    <b>{el.title}</b>
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        {this.displaySection()}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { device } = state;
  return {
    device,
  };
}

const connectedComponent = connect(mapStateToProps)(Compatibilite);
export default connectedComponent;
