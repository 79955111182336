import React from "react";
import { withTranslation } from "react-i18next";
import fullScreen from "../../_resources/image/full_screen_accueil2.jpg";

const SmartphoneHome = (props: any) => {
  const { t } = props;
  return (
    <div className="home-low-responsive">
      <div
        className="smart-home-title"
        itemScope
        itemType="https://schema.org/WebPage"
      >
        <img src={fullScreen} />
      </div>
    </div>
  );
};

export default withTranslation()(SmartphoneHome);
