import React from 'react';

const SvgIntegra = (props: any) => (
	<svg viewBox="0 0 129.91 23.2" {...props}>
		<path d="M22.05,22.95h0c-.39,0-.67-.23-.93-.6L12.71,7.81v14.4c-.02,.39-.31,.73-.74,.74h-2.33c-.4-.02-.76-.31-.78-.74V.96c.02-.43,.36-.73,.74-.74h2.31c.42,.02,.76,.29,.96,.6l8.43,14.51V.96c0-.42,.34-.74,.74-.74h2.28c.37,0,.78,.28,.78,.74V22.19c-.02,.39-.33,.74-.78,.74h-2.28"/>
		<path d="M45.67,3.55h-6.36V22.21c-.02,.39-.31,.73-.74,.74h-2.31c-.4,0-.74-.34-.74-.74V3.55h-6.35c-.4,0-.78-.31-.78-.74V.98c0-.42,.34-.74,.74-.74h16.51c.4,0,.78,.31,.78,.74v1.83c.02,.43-.36,.74-.74,.74"/>
		<path d="M.74,22.95c-.4,0-.74-.34-.74-.74V.98C.02,.59,.31,.25,.74,.23H3.06c.4,0,.74,.34,.74,.76V22.22c-.02,.39-.31,.73-.74,.74H.74"/>
		<path d="M63.81,22.95h-13.38c-.39-.02-.73-.31-.74-.74V.96c0-.42,.34-.74,.74-.74h13.35c.37,0,.78,.28,.78,.74v1.83c-.02,.46-.39,.74-.74,.74h-10.3v6.13h8.43c.37,0,.78,.28,.78,.76v1.83c-.03,.4-.34,.71-.74,.74h-8.46v6.6h10.27c.37,0,.78,.29,.78,.76v1.83c-.03,.42-.34,.73-.74,.76"/>
		<path d="M77.38,23.2h-1.86c-2.23,0-4.05-.64-5.29-1.88-1.24-1.24-1.89-3.07-1.89-5.37V7.18c0-2.25,.65-4.07,1.89-5.31C71.47,.64,73.29,0,75.52,0h1.91c4.11-.02,6.66,2.2,7.09,6.07-.03,.4-.34,.71-.74,.74h-2.31c-.4,0-.74-.34-.74-.71-.33-1.95-1.38-2.76-3.79-2.79h-.93c-2.78,.05-3.8,1.1-3.85,3.88V15.97c.06,2.87,1.04,3.85,3.85,3.91h.93c2.82-.06,3.8-1.04,3.85-3.89v-1.99h-3.85c-.36,0-.74-.3-.74-.74v-1.83c.02-.43,.36-.73,.74-.74h6.92c.42,0,.74,.33,.74,.74v4.55c0,2.3-.64,4.13-1.88,5.37-1.26,1.23-3.07,1.86-5.34,1.86"/>
		<path d="M98.05,11.37c2.82-.06,3.8-1.04,3.85-3.86v-.08c-.06-2.84-1.04-3.82-3.82-3.88h-4.78v7.82h4.75Zm7.22,11.58h-2.65c-.36-.02-.74-.23-.93-.64l-3.45-7.57s-.11,.02-.17,.02h-4.78v7.45c0,.42-.33,.74-.74,.74h-2.31c-.43,0-.74-.37-.74-.84V.88c.02-.36,.33-.65,.74-.67h8.27c2.27,0,4.08,.64,5.32,1.86,1.24,1.24,1.88,3.04,1.88,5.32h0v.11c0,3.17-1.26,5.46-3.6,6.49l3.69,8.04c.06,.11,.09,.22,.09,.33,0,.4-.33,.59-.62,.59"/>
		<path d="M126.48,22.95c-.62-.02-.88-.53-.95-.81l-5.91-16.43-5.88,16.42c-.08,.29-.33,.82-.93,.82h-2.61c-.44,0-.82-.34-.82-.79,0-.14,.02-.26,.06-.39L117.37,.98c.09-.25,.33-.76,.93-.78h2.7c.57,.02,.85,.48,.93,.79l7.88,20.75c.06,.12,.09,.26,.09,.42-.02,.47-.42,.76-.81,.76h-2.62v.03Z"/>
	</svg>
);

export default SvgIntegra;
