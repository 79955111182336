import React from 'react';

const FlecheTelephone = (props: any) => (
    <svg viewBox="0 0 46.97 78.03" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M3.8,74.6A52.3,52.3,0,0,1,2.66,63,61.09,61.09,0,0,1,3.8,51.41,78,78,0,0,1,12,29.68c.91-1.72,2-3.35,3.06-5s2.28-3.17,3.57-4.63,2.62-2.89,3.95-4.31c.69-.69,1.42-1.33,2.14-2l1.08-1c.37-.32.76-.62,1.13-.93A77.47,77.47,0,0,1,46.93,0l0,.09A102.24,102.24,0,0,0,27.6,12.66c-.37.3-.75.6-1.11.92l-1.06,1-2.11,2c-1.34,1.38-2.74,2.7-4,4.19s-2.45,3-3.64,4.47S13.46,28.29,12.48,30a54.05,54.05,0,0,0-5.06,10.4,63.62,63.62,0,0,0-2.8,11.21,79.63,79.63,0,0,0-.72,23Z"/>
			<path fill={props.fill} d="M0,70.45a.94.94,0,0,1,.89-1.12c1.56,0,3.73-.82,4.88-.77S4.07,78.42,2.28,78C.91,77.72.43,72.56,0,70.45Z"/>
		</g>
	</g>
</svg>
);

export default FlecheTelephone;
