import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import './style.scss';

import jobAction from "../../redux/job/job.action";
import Mail from "../../_svg/Mail";
import FlecheMail from "../../_svg/Fleche_mail";
import Contact from "./Contact";

const WorkSheet = props => {
    const { job, dispatch, match, t } = props

    useEffect(() => {
        console.log(props)
        dispatch(jobAction.getJobInfo(parseInt(match.params.jobId)))
    }, [])

    const clipBoardUrl = (e) => {
        e.preventDefault()
        if (window.location.href) {
            navigator.clipboard.writeText(window.location.href).then(() => {
                alert("Url copié !")
            })
        } else {
            alert("Url corrompu")
        }
    }

    return (
        <div className="worksheet-container">
            {job.info && (
                <div className="left">
                    <div className="flex-box">
                        <h2>{job.info.title}</h2>
                        <div className="share-button-perso" onClick={(e) => clipBoardUrl(e)}>
                            <span><b>{t('job.share')}</b></span>
                        </div>
                    </div>
                    <div className="text-box">
                        <h4>{t('job.details')}</h4>
                        <span><b>{t('job.type')}: </b>{`${job.info.type.time} - ${job.info.type.contract}`}</span>
                        <span><b>{t('job.salary')}: </b>{job.info.salary.max ? `De ${job.info.salary.min}€ à ${job.info.salary.max}€ par an` : `à partir de ${job.info.salary.min}€ par an`}</span>
                    </div>
                    <div className="text-box">
                        <h4>{t('job.details.description')}</h4>
                        <span>{job.info.description}</span>
                    </div>
                    <div className="text-box">
                        <h4>{t('job.profil')}</h4>
                        <span>{job.info.profil}</span>
                    </div>
                    <div className="text-box">
                        <h4>{t('job.advantage')}</h4>
                        {job.info.advantages && job.info.advantages.map(el => (
                            <span><b>{el.title}</b></span>
                        ))}
                    </div>
                </div>
            )}
            <div className="right">
                <div className="svg-box">
                    <Mail height="60px" fill="#cf7830" />
                    <div className="flex-box">
                        <h3>Candidater</h3>
                        <FlecheMail height="40px" />
                    </div>
                </div>
                <div className="form-container">
                    <Contact />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ job }) => {
    return {
        job,
    }
}
const connectedApp = connect(mapStateToProps)(WorkSheet)
const tr = withTranslation()(connectedApp)
export default tr;