import React from 'react';

const SvgAfi = (props: any) => (
<svg viewBox="0 0 91.74 47.83" {...props}>
	<path fill={"#1d1d1b"} d="M27.95,27.24h-5.98l-2.38-6.19H8.7l-2.25,6.19H.62L11.23,0h5.81l10.91,27.24Zm-11-11.97l-2.87-7.72-2.81,7.72h5.68Z"/>
	<path fill={"#1d1d1b"} d="M26.08,27.24V0h18.67V6.02h-11.76v7.86h7.85v4.61h-7.85v8.76h-6.91Z"/>
	<path fill={"#1d1d1b"} d="M43.63,27.24V0h6.91V27.24h-6.91Z"/>
	<g>
		<path fill={"#1d1d1b"} d="M0,34.08l1.18-.12c.07,.4,.22,.69,.43,.88,.22,.19,.51,.28,.88,.28,.39,0,.69-.08,.89-.25,.2-.17,.3-.36,.3-.58,0-.14-.04-.26-.13-.36-.08-.1-.23-.19-.44-.26-.14-.05-.47-.14-.97-.26-.65-.16-1.11-.36-1.37-.6-.37-.33-.55-.74-.55-1.21,0-.31,.09-.59,.26-.86,.17-.27,.42-.47,.75-.61,.33-.14,.72-.21,1.19-.21,.76,0,1.33,.17,1.71,.5,.38,.33,.58,.77,.6,1.33l-1.22,.05c-.05-.31-.16-.53-.34-.67-.17-.14-.43-.2-.77-.2s-.63,.07-.83,.22c-.13,.09-.19,.22-.19,.37,0,.14,.06,.26,.18,.37,.15,.13,.53,.26,1.12,.4,.59,.14,1.03,.28,1.31,.43s.51,.35,.67,.61c.16,.26,.24,.58,.24,.96,0,.35-.1,.67-.29,.97-.19,.3-.46,.53-.81,.67-.35,.15-.79,.22-1.31,.22-.76,0-1.35-.18-1.76-.53s-.65-.87-.73-1.54Z"/>
		<path fill={"#1d1d1b"} d="M7.27,36.04v-6.03h4.47v1.02h-3.25v1.34h3.03v1.02h-3.03v1.64h3.37v1.02H7.27Z"/>
		<path fill={"#1d1d1b"} d="M14.24,36.04v-6.03h2.56c.64,0,1.11,.05,1.4,.16,.29,.11,.53,.3,.7,.58,.18,.28,.26,.59,.26,.95,0,.45-.13,.83-.4,1.12-.27,.29-.66,.48-1.19,.56,.26,.15,.48,.32,.65,.51,.17,.18,.4,.51,.69,.98l.74,1.18h-1.46l-.88-1.31c-.31-.47-.53-.76-.64-.89-.12-.12-.24-.21-.37-.25-.13-.05-.33-.07-.61-.07h-.25v2.52h-1.22Zm1.22-3.48h.9c.58,0,.95-.02,1.09-.07,.15-.05,.26-.13,.34-.25s.12-.27,.12-.45c0-.2-.05-.37-.16-.49-.11-.12-.26-.2-.46-.24-.1-.01-.39-.02-.89-.02h-.95v1.53Z"/>
		<path fill={"#1d1d1b"} d="M23.04,36.04l-2.15-6.03h1.32l1.53,4.46,1.48-4.46h1.29l-2.16,6.03h-1.3Z"/>
		<path fill={"#1d1d1b"} d="M28.43,36.04v-6.03h1.22v6.03h-1.22Z"/>
		<path fill={"#1d1d1b"} d="M36.01,33.83l1.18,.37c-.18,.66-.48,1.15-.9,1.47-.42,.32-.95,.48-1.6,.48-.8,0-1.46-.27-1.97-.82-.52-.55-.77-1.29-.77-2.24,0-1,.26-1.78,.78-2.34,.52-.55,1.2-.83,2.04-.83,.74,0,1.34,.22,1.8,.65,.27,.26,.48,.63,.62,1.11l-1.2,.29c-.07-.31-.22-.56-.45-.74-.23-.18-.5-.27-.82-.27-.45,0-.81,.16-1.09,.48-.28,.32-.42,.84-.42,1.56,0,.76,.14,1.3,.41,1.63,.27,.32,.63,.49,1.07,.49,.32,0,.6-.1,.83-.31,.23-.21,.4-.53,.5-.97Z"/>
		<path fill={"#1d1d1b"} d="M39.58,36.04v-6.03h4.47v1.02h-3.25v1.34h3.03v1.02h-3.03v1.64h3.37v1.02h-4.58Z"/>
		<path fill={"#1d1d1b"} d="M46.24,34.08l1.18-.12c.07,.4,.22,.69,.43,.88,.22,.19,.51,.28,.88,.28,.39,0,.69-.08,.89-.25,.2-.17,.3-.36,.3-.58,0-.14-.04-.26-.13-.36-.08-.1-.23-.19-.44-.26-.14-.05-.47-.14-.97-.26-.65-.16-1.11-.36-1.37-.6-.37-.33-.55-.74-.55-1.21,0-.31,.09-.59,.26-.86,.17-.27,.42-.47,.75-.61,.33-.14,.72-.21,1.19-.21,.76,0,1.33,.17,1.71,.5,.38,.33,.58,.77,.6,1.33l-1.22,.05c-.05-.31-.16-.53-.34-.67-.17-.14-.43-.2-.77-.2s-.63,.07-.83,.22c-.13,.09-.19,.22-.19,.37,0,.14,.06,.26,.18,.37,.15,.13,.53,.26,1.12,.4,.59,.14,1.03,.28,1.31,.43s.51,.35,.67,.61c.16,.26,.24,.58,.24,.96,0,.35-.1,.67-.29,.97-.19,.3-.46,.53-.81,.67-.35,.15-.79,.22-1.31,.22-.76,0-1.35-.18-1.76-.53s-.65-.87-.73-1.54Z"/>
	</g>
	<g>
		<path fill={"#1d1d1b"} d="M.16,43.3l.92-.08c.04,.37,.15,.67,.3,.91,.16,.24,.41,.43,.74,.57,.34,.15,.71,.22,1.13,.22,.37,0,.7-.06,.99-.17s.5-.26,.64-.46c.14-.19,.21-.4,.21-.63s-.07-.43-.2-.61c-.13-.17-.36-.32-.66-.43-.2-.08-.64-.2-1.31-.36-.68-.16-1.15-.32-1.42-.46-.35-.18-.61-.41-.79-.69s-.26-.58-.26-.92c0-.37,.11-.72,.32-1.04,.21-.32,.52-.57,.93-.74,.41-.17,.86-.25,1.35-.25,.55,0,1.03,.09,1.45,.26s.74,.43,.96,.78,.35,.73,.36,1.16l-.94,.07c-.05-.47-.22-.82-.51-1.06-.29-.24-.72-.36-1.28-.36s-1.02,.11-1.29,.32c-.27,.22-.4,.48-.4,.78,0,.26,.1,.48,.29,.65,.19,.17,.68,.35,1.47,.53,.79,.18,1.34,.34,1.63,.47,.43,.2,.75,.45,.95,.75,.2,.3,.31,.65,.31,1.05s-.11,.76-.34,1.11-.55,.62-.97,.81c-.42,.19-.89,.29-1.42,.29-.67,0-1.23-.1-1.68-.29-.45-.19-.8-.49-1.06-.88-.26-.39-.39-.83-.4-1.32Z"/>
		<path fill={"#1d1d1b"} d="M6.91,42.99c0-.99,.27-1.72,.82-2.2,.46-.4,1.02-.59,1.68-.59,.73,0,1.33,.24,1.8,.72,.47,.48,.7,1.15,.7,1.99,0,.69-.1,1.23-.31,1.62s-.51,.7-.9,.92c-.39,.22-.82,.33-1.29,.33-.75,0-1.35-.24-1.81-.72-.46-.48-.69-1.17-.69-2.07Zm.93,0c0,.68,.15,1.2,.45,1.54,.3,.34,.67,.51,1.13,.51s.82-.17,1.12-.51c.3-.34,.45-.86,.45-1.56,0-.66-.15-1.16-.45-1.5s-.67-.51-1.12-.51-.83,.17-1.13,.51c-.3,.34-.45,.85-.45,1.53Z"/>
		<path fill={"#1d1d1b"} d="M12.95,45.66v-7.37h.91v7.37h-.91Z"/>
		<path fill={"#1d1d1b"} d="M18.76,45.66v-.78c-.42,.6-.98,.91-1.69,.91-.32,0-.61-.06-.88-.18-.27-.12-.48-.27-.61-.45-.13-.18-.23-.41-.28-.67-.04-.18-.06-.46-.06-.84v-3.31h.91v2.96c0,.47,.02,.79,.06,.96,.06,.24,.18,.42,.36,.56,.18,.14,.41,.2,.68,.2s.53-.07,.76-.21c.24-.14,.41-.33,.51-.57,.1-.24,.15-.59,.15-1.04v-2.86h.91v5.34h-.81Z"/>
		<path fill={"#1d1d1b"} d="M22.96,44.85l.13,.8c-.25,.05-.48,.08-.68,.08-.33,0-.58-.05-.76-.16s-.31-.24-.38-.41-.11-.53-.11-1.07v-3.07h-.66v-.7h.66v-1.32l.9-.54v1.87h.91v.7h-.91v3.12c0,.26,.02,.42,.05,.5,.03,.07,.08,.13,.16,.18,.07,.04,.18,.07,.31,.07,.1,0,.23-.01,.4-.04Z"/>
		<path fill={"#1d1d1b"} d="M23.85,39.33v-1.04h.91v1.04h-.91Zm0,6.33v-5.34h.91v5.34h-.91Z"/>
		<path fill={"#1d1d1b"} d="M25.8,42.99c0-.99,.27-1.72,.82-2.2,.46-.4,1.02-.59,1.68-.59,.73,0,1.33,.24,1.8,.72,.47,.48,.7,1.15,.7,1.99,0,.69-.1,1.23-.31,1.62s-.51,.7-.9,.92c-.39,.22-.82,.33-1.29,.33-.75,0-1.35-.24-1.81-.72-.46-.48-.69-1.17-.69-2.07Zm.93,0c0,.68,.15,1.2,.45,1.54,.3,.34,.67,.51,1.13,.51s.82-.17,1.12-.51c.3-.34,.45-.86,.45-1.56,0-.66-.15-1.16-.45-1.5s-.67-.51-1.12-.51-.83,.17-1.13,.51c-.3,.34-.45,.85-.45,1.53Z"/>
		<path fill={"#1d1d1b"} d="M31.86,45.66v-5.34h.81v.76c.39-.59,.96-.88,1.7-.88,.32,0,.62,.06,.89,.17,.27,.12,.47,.27,.61,.45,.13,.19,.23,.41,.28,.67,.03,.17,.05,.46,.05,.88v3.28h-.91v-3.25c0-.37-.04-.64-.11-.83-.07-.18-.2-.33-.37-.44-.18-.11-.39-.16-.63-.16-.39,0-.72,.12-1,.37-.28,.24-.42,.71-.42,1.39v2.92h-.91Z"/>
		<path fill={"#1d1d1b"} d="M37.23,44.07l.89-.14c.05,.36,.19,.63,.42,.82,.23,.19,.55,.29,.96,.29s.72-.08,.93-.25c.2-.17,.3-.37,.3-.6,0-.2-.09-.37-.27-.48-.12-.08-.43-.18-.93-.31-.66-.17-1.12-.31-1.38-.44-.26-.12-.45-.29-.58-.51-.13-.22-.2-.46-.2-.72,0-.24,.05-.46,.16-.66,.11-.2,.26-.37,.45-.51,.14-.1,.33-.19,.58-.26,.24-.07,.5-.11,.78-.11,.42,0,.79,.06,1.1,.18,.32,.12,.55,.28,.7,.49,.15,.21,.25,.48,.31,.83l-.88,.12c-.04-.27-.16-.49-.35-.64-.19-.15-.47-.23-.82-.23-.42,0-.71,.07-.89,.21s-.27,.3-.27,.48c0,.12,.04,.22,.11,.32,.07,.1,.19,.18,.35,.24,.09,.03,.36,.11,.8,.23,.64,.17,1.09,.31,1.34,.42,.25,.11,.45,.27,.6,.48,.14,.21,.22,.47,.22,.77s-.09,.59-.26,.85c-.18,.27-.43,.47-.76,.62-.33,.15-.71,.22-1.13,.22-.69,0-1.22-.14-1.59-.43s-.6-.72-.7-1.28Z"/>
		<path fill={"#1d1d1b"} d="M49.07,45.66v-.67c-.34,.53-.84,.79-1.49,.79-.43,0-.82-.12-1.17-.35s-.63-.56-.83-.98c-.2-.42-.29-.9-.29-1.45s.09-1.02,.27-1.45c.18-.43,.44-.77,.8-1,.36-.23,.75-.35,1.19-.35,.32,0,.61,.07,.86,.2s.46,.31,.61,.53v-2.64h.9v7.37h-.84Zm-2.86-2.67c0,.68,.14,1.2,.43,1.53s.63,.51,1.02,.51,.73-.16,1.01-.49c.28-.32,.41-.82,.41-1.48,0-.73-.14-1.27-.42-1.61-.28-.34-.63-.51-1.04-.51s-.74,.16-1.01,.49c-.27,.33-.4,.85-.4,1.55Z"/>
		<path fill={"#1d1d1b"} d="M54.98,43.94l.94,.12c-.15,.55-.42,.97-.82,1.27s-.91,.45-1.53,.45c-.78,0-1.4-.24-1.86-.72s-.69-1.16-.69-2.02,.23-1.6,.69-2.09c.46-.5,1.06-.74,1.8-.74s1.3,.24,1.75,.73c.45,.49,.68,1.17,.68,2.05,0,.05,0,.13,0,.24h-3.98c.03,.59,.2,1.04,.5,1.35,.3,.31,.67,.47,1.12,.47,.33,0,.61-.09,.85-.26,.23-.17,.42-.45,.56-.83Zm-2.97-1.46h2.98c-.04-.45-.15-.79-.34-1.01-.29-.35-.66-.52-1.12-.52-.42,0-.77,.14-1.05,.42-.28,.28-.44,.65-.47,1.12Z"/>
		<path fill={"#1d1d1b"} d="M59.75,46.11l.88,.13c.04,.27,.14,.47,.31,.59,.22,.17,.53,.25,.92,.25,.42,0,.74-.08,.97-.25,.23-.17,.38-.4,.46-.7,.05-.18,.07-.57,.07-1.16-.4,.47-.89,.7-1.48,.7-.73,0-1.3-.26-1.7-.79-.4-.53-.6-1.16-.6-1.91,0-.51,.09-.98,.28-1.41,.18-.43,.45-.76,.8-1,.35-.23,.76-.35,1.23-.35,.63,0,1.15,.25,1.56,.76v-.64h.83v4.62c0,.83-.08,1.42-.25,1.77s-.44,.62-.8,.82-.82,.3-1.36,.3c-.64,0-1.15-.14-1.54-.43-.39-.29-.58-.72-.57-1.29Zm.75-3.21c0,.7,.14,1.21,.42,1.53,.28,.32,.63,.48,1.05,.48s.76-.16,1.05-.48,.42-.82,.42-1.51-.15-1.15-.44-1.48c-.29-.33-.64-.5-1.05-.5s-.74,.16-1.03,.49c-.28,.33-.42,.81-.42,1.46Z"/>
		<path fill={"#1d1d1b"} d="M69.3,43.94l.94,.12c-.15,.55-.42,.97-.82,1.27s-.91,.45-1.53,.45c-.78,0-1.4-.24-1.86-.72s-.69-1.16-.69-2.02,.23-1.6,.69-2.09c.46-.5,1.06-.74,1.8-.74s1.3,.24,1.75,.73c.45,.49,.68,1.17,.68,2.05,0,.05,0,.13,0,.24h-3.98c.03,.59,.2,1.04,.5,1.35,.3,.31,.67,.47,1.12,.47,.33,0,.61-.09,.85-.26,.23-.17,.42-.45,.56-.83Zm-2.97-1.46h2.98c-.04-.45-.15-.79-.34-1.01-.29-.35-.66-.52-1.12-.52-.42,0-.77,.14-1.05,.42-.28,.28-.44,.65-.47,1.12Z"/>
		<path fill={"#1d1d1b"} d="M71.01,44.07l.89-.14c.05,.36,.19,.63,.42,.82,.23,.19,.55,.29,.96,.29s.72-.08,.93-.25c.2-.17,.3-.37,.3-.6,0-.2-.09-.37-.27-.48-.12-.08-.43-.18-.93-.31-.66-.17-1.12-.31-1.38-.44-.26-.12-.45-.29-.58-.51-.13-.22-.2-.46-.2-.72,0-.24,.05-.46,.16-.66,.11-.2,.26-.37,.45-.51,.14-.1,.33-.19,.58-.26,.24-.07,.5-.11,.78-.11,.42,0,.79,.06,1.1,.18,.32,.12,.55,.28,.7,.49,.15,.21,.25,.48,.31,.83l-.88,.12c-.04-.27-.16-.49-.35-.64-.19-.15-.47-.23-.82-.23-.42,0-.71,.07-.89,.21s-.27,.3-.27,.48c0,.12,.04,.22,.11,.32,.07,.1,.19,.18,.35,.24,.09,.03,.36,.11,.8,.23,.64,.17,1.09,.31,1.34,.42,.25,.11,.45,.27,.6,.48,.14,.21,.22,.47,.22,.77s-.09,.59-.26,.85c-.18,.27-.43,.47-.76,.62-.33,.15-.71,.22-1.13,.22-.69,0-1.22-.14-1.59-.43s-.6-.72-.7-1.28Z"/>
		<path fill={"#1d1d1b"} d="M78.5,44.85l.13,.8c-.25,.05-.48,.08-.68,.08-.33,0-.58-.05-.76-.16s-.31-.24-.38-.41-.11-.53-.11-1.07v-3.07h-.66v-.7h.66v-1.32l.9-.54v1.87h.91v.7h-.91v3.12c0,.26,.02,.42,.05,.5,.03,.07,.08,.13,.16,.18,.07,.04,.18,.07,.31,.07,.1,0,.23-.01,.4-.04Z"/>
		<path fill={"#1d1d1b"} d="M79.39,39.33v-1.04h.91v1.04h-.91Zm0,6.33v-5.34h.91v5.34h-.91Z"/>
		<path fill={"#1d1d1b"} d="M81.33,42.99c0-.99,.27-1.72,.82-2.2,.46-.4,1.02-.59,1.68-.59,.73,0,1.33,.24,1.8,.72,.47,.48,.7,1.15,.7,1.99,0,.69-.1,1.23-.31,1.62s-.51,.7-.9,.92c-.39,.22-.82,.33-1.29,.33-.75,0-1.35-.24-1.81-.72-.46-.48-.69-1.17-.69-2.07Zm.93,0c0,.68,.15,1.2,.45,1.54,.3,.34,.67,.51,1.13,.51s.82-.17,1.12-.51c.3-.34,.45-.86,.45-1.56,0-.66-.15-1.16-.45-1.5s-.67-.51-1.12-.51-.83,.17-1.13,.51c-.3,.34-.45,.85-.45,1.53Z"/>
		<path fill={"#1d1d1b"} d="M87.4,45.66v-5.34h.81v.76c.39-.59,.96-.88,1.7-.88,.32,0,.62,.06,.89,.17,.27,.12,.47,.27,.61,.45,.13,.19,.23,.41,.28,.67,.03,.17,.05,.46,.05,.88v3.28h-.91v-3.25c0-.37-.04-.64-.11-.83-.07-.18-.2-.33-.37-.44-.18-.11-.39-.16-.63-.16-.39,0-.72,.12-1,.37-.28,.24-.42,.71-.42,1.39v2.92h-.91Z"/>
	</g>
	<path fill={"#1d1d1b"} d="M67.73,15.1l1.54,2.29c.11,.16,.3,.26,.49,.26h9.06c.5,0,.78-.58,.46-.97l-1.86-2.27c-.11-.14-.28-.22-.46-.22l-8.74-.02c-.48,0-.76,.53-.49,.92Z"/>
	<path fill={"#1d1d1b"} d="M70.81,19.72l1.54,2.29c.11,.16,.3,.26,.49,.26h9.06c.5,0,.78-.58,.46-.97l-1.86-2.27c-.11-.14-.28-.22-.46-.22l-8.74-.02c-.48,0-.76,.53-.49,.92Z"/>
	<path fill={"#1d1d1b"} d="M73.62,24.34l1.54,2.29c.11,.16,.3,.26,.49,.26h9.06c.5,0,.78-.58,.46-.97l-1.86-2.27c-.11-.14-.28-.22-.46-.22l-8.74-.02c-.48,0-.76,.53-.49,.92Z"/>
	<path fill={"#1d1d1b"} d="M77.16,29.41l3.06,5.53c.11,.2,.32,.32,.55,.32h9.13c.49,0,.79-.54,.53-.96l-3.47-5.51c-.11-.18-.31-.29-.53-.29l-8.73-.02c-.48,0-.78,.51-.55,.93Z"/>
	<path fill={"#1d1d1b"} d="M57.1,20.23l1.54,2.29c.11,.16,.3,.26,.49,.26h9.06c.5,0,.78-.58,.46-.97l-1.86-2.27c-.11-.14-.28-.22-.46-.22l-8.74-.02c-.48,0-.76,.53-.49,.92Z"/>
	<path fill={"#1d1d1b"} d="M60.17,24.85l1.54,2.29c.11,.16,.3,.26,.49,.26h9.06c.5,0,.78-.58,.46-.97l-1.86-2.27c-.11-.14-.28-.22-.46-.22l-8.74-.02c-.48,0-.76,.53-.49,.92Z"/>
	<path fill={"#1d1d1b"} d="M62.99,29.46l1.54,2.29c.11,.16,.3,.26,.49,.26h9.06c.5,0,.78-.58,.46-.97l-1.86-2.27c-.11-.14-.28-.22-.46-.22l-8.74-.02c-.48,0-.76,.53-.49,.92Z"/>
	<path fill={"#1d1d1b"} d="M54.28,15.1l1.54,2.29c.11,.16,.3,.26,.49,.26h9.06c.5,0,.78-.58,.46-.97l-1.86-2.27c-.11-.14-.28-.22-.46-.22l-8.74-.02c-.48,0-.76,.53-.49,.92Z"/>
	<path fill={"#1d1d1b"} d="M55.43,10.6l1.54,2.29c.11,.16,.3,.26,.49,.26h18.18c.5,0,.78-.58,.46-.97l-1.86-2.27c-.11-.14-.28-.22-.46-.22l-17.86-.02c-.48,0-.76,.53-.49,.92Z"/>
	<rect fill={"#1d1d1b"} x="56.67" y="2.9" width="14.04" height="5.24" rx="1.05" ry="1.05"/>
</svg>
);

export default SvgAfi;
