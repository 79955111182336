import React from 'react';

const SvgWavenis = (props: any) => (
<svg viewBox="0 0 108.89 62.75" {...props}>
	<path d="M37.5,5.5S59.52-2.62,79.79,.87c0,0,11.43,2.33,5.95,9.86,0,0-4.63,4.91-8.41,6.36,0,0,11.02-9.23-6.36-12.44-17.38-3.21-33.47,.85-33.47,.85"/>
	<path d="M65.72,14.19s.28,13.32-5.13,16.88l-6.17-9.6-6.17,9.6c-5.38-3.56-5.13-16.88-5.13-16.88h-5.64c-.25,18.99,12.34,25.82,12.34,25.82l4.6-8.66,4.6,8.66s12.6-6.83,12.34-25.82h-5.64Z"/>
	<path d="M15.05,48.96s.16,7.12-2.74,9.01l-3.27-5.13-3.28,5.13c-2.86-1.89-2.74-9.01-2.74-9.01H0c-.13,10.14,6.58,13.79,6.58,13.79l2.46-4.63,2.46,4.63s6.74-3.65,6.58-13.79h-3.02Z"/>
	<path d="M45.13,59.19c4.19-3.56,3.59-10.23,3.59-10.23h3.02c.13,10.14-6.58,13.79-6.58,13.79,0,0-6.74-3.65-6.58-13.79h3.02s-.41,6.46,3.53,10.23"/>
	<path d="M27.74,60.26c-2.52,0-4.57-2.05-4.57-4.57s2.05-4.57,4.57-4.57,4.57,2.05,4.57,4.57v-5.1c-1.23-1.1-2.8-1.76-4.57-1.76-3.78,0-6.86,3.09-6.86,6.87s3.09,6.86,6.86,6.86c1.76,0,3.37-.66,4.57-1.76v-5.1c.03,2.52-2.05,4.57-4.57,4.57"/>
	<path d="M32.34,48.96v1.64c1.42,1.26,2.3,3.09,2.3,5.1s-.88,3.84-2.3,5.1v1.95h2.49v-13.79h-2.49Z"/>
	<path d="M34.61,55.7c0-2.05-.88-3.84-2.3-5.1v10.2c1.42-1.26,2.3-3.09,2.3-5.1"/>
	<rect x="89.68" y="48.96" width="2.49" height="13.79"/>
	<path d="M62.32,51.1c2.05,0,3.81,1.35,4.38,3.21h-8.75c.6-1.86,2.33-3.21,4.38-3.21m4.53,5.32h2.3c.03-.25,.03-.47,.03-.72,0-.47-.06-.91-.13-1.35-.63-3.15-3.4-5.51-6.74-5.51-3.78,0-6.86,3.09-6.86,6.87s3.09,6.86,6.86,6.86c2.52,0,4.72-1.38,5.92-3.4h-2.93c-.79,.69-1.86,1.1-2.99,1.1-2.27,0-4.16-1.67-4.5-3.84h9.04Z"/>
	<path d="M84.39,51.79c-.38-.91-1.01-1.64-1.79-2.17-.82-.54-1.73-.79-2.77-.79s-1.95,.25-2.8,.69c-.5,.25-.95,.6-1.39,1.04v-1.42h-2.64v13.6h2.64v-5.13c0-1.61,.06-2.71,.22-3.31,.22-.85,.66-1.54,1.35-2.14,.69-.57,1.45-.85,2.3-.85,.72,0,1.32,.19,1.76,.5,.44,.35,.72,.88,.91,1.6,.06,.32,.16,1.04,.16,2.77v6.52h2.64v-6.99c-.03-1.76-.22-3.05-.6-3.94"/>
	<path d="M90.97,42.78c.83,0,1.51,.68,1.51,1.51s-.68,1.51-1.51,1.51-1.51-.68-1.51-1.51,.68-1.51,1.51-1.51"/>
	<path d="M27.74,60.26c-2.52,0-4.57-2.05-4.57-4.57s2.05-4.57,4.57-4.57,4.57,2.05,4.57,4.57c.03,2.52-2.05,4.57-4.57,4.57m4.6-9.7c-1.23-1.1-2.8-1.76-4.57-1.76-3.78,0-6.87,3.09-6.87,6.86s3.09,6.87,6.87,6.87c1.76,0,3.37-.66,4.57-1.76,1.42-1.26,2.3-3.09,2.3-5.1-.03-2.01-.91-3.84-2.3-5.1"/>
	<path d="M108.8,54.62h-11.24c.47-2.01,2.3-3.49,4.44-3.49,1.42,0,2.68,.63,3.49,1.64l1.76-1.45c-.22-.25-.44-.5-.69-.72-1.23-1.1-2.8-1.76-4.57-1.76-3.43,0-6.27,2.52-6.77,5.79-.06,.35-.1,.69-.1,1.07,0,.47,.06,.94,.16,1.39h11.08c-.6,1.86-2.33,3.18-4.35,3.18-1.45,0-2.74-.69-3.59-1.73l-1.76,1.45c1.26,1.57,3.18,2.58,5.35,2.58,1.76,0,3.37-.66,4.57-1.76,1.07-.94,1.83-2.23,2.14-3.72,.09-.44,.16-.91,.16-1.39,0-.38-.03-.72-.09-1.07"/>
</svg>
);

export default SvgWavenis;
