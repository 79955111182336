import React, { Component } from "react";
import Filtre from "../../_svg/Filtre";
import Export from "../../_svg/Export";

import ImgRecap from "../../_resources/image/telephone/onyx.png";

export default class Footer extends Component<any, any>{
    constructor(props: Readonly<any>) {
        super(props);
    }

    render() {
        const { t } = this.props
        const sections1 = [
            {
                title: t('applications.filter'),
                description: t('applications.onyx.filter.description'),
                svg: <Filtre height={"3em"} fill={"#06d0e7"}/>
            },
            {
                title: t('applications.onyx.export'),
                description: t('applications.onyx.export.description'),
                svg: <Export height={"3em"} fill={"#06d0e7"}/>
            },
        ]

        const content = {
            title: t('all.recap')
        }
        return (
            <div className={"footer onyx"}>
                <div className={"image-container"}>
                <img src={ImgRecap} alt="dioptase"/>
                    <h2 style={{color: "#06d0e7"}}>{content.title}</h2>
                </div>
                <div className={"footer-section"}>
                    {sections1.map((el:any) => (
                        <div className={"section"}>
                            {el.svg}
                            <h5>{el.title}</h5>
                            <span>{el.description}</span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}