import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';

import Recap from "./Recap";
import Compatible from "../onyx/Compatible";
import Contact from "../onyx/Contact";
import Footer from "../section/FooterSection";


import Dashboard from "./CcDashboard";
import Synchronisation from "./CcSynchronisation";
import VirtualMeter from "./CcVirtualMeter";
import Alert from "./CcAlertes";
import Mask from "./CcMasks";
import Filtre from "./CcFiltres";

import IndicatorBar from "../../sidebars/IndicatorBar";

import ScrollSequence from "../scroll/ScrollSequence";

import images from "../../_resources/ccDashboard";
import images2 from "../../_resources/ccSynchronisation";
import images3 from "../../_resources/ccVirtualMeter";
import images4 from "../../_resources/ccAlertes";
import images5 from "../../_resources/ccMasks";
import images6 from "../../_resources/ccFiltres";

import ImageSelector from "../responsive/ImageSelector";

import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import PopUp from "../responsive/PopUp";
import ReactGA from 'react-ga4';

import { Helmet } from 'react-helmet-async';

const App = (props) => {
  const { t, dispatch } = props;
  const [isTablet, setIsTablet] = useState(false);
  const [modal, setModal] = useState(false)
  const [init, setInit] = useState(false);
  const tablet = useMediaQuery({ query: "(max-width: 1040px)" })

  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  })

  if (!init) {
    setIsTablet(tablet)
    setInit(true)
  }

  function timeOutFunction() {
    toggle()
  }

  const toggle = () => {
    setModal(!modal)
  }

  const callBackModal = (result) => {
    if (result) {
      window.localStorage.setItem('lowDebit', true)
      setIsTablet(true)
      toggle()
    }
    else {
      window.localStorage.setItem('lowDebit', false)
      toggle()
    }
  }

  const localItem = window.localStorage.getItem('lowDebit')
  if (localItem === "true" && !isTablet) {
    setIsTablet(true)
  }

  const is1600 = useMediaQuery({ query: "(max-width: 1600px)" });
  const is1366 = useMediaQuery({ query: "(max-width: 1366px)" });
  return (
    <div className={"crystalcloud-container responsiv"} itemtype="https://schema.org/IndividualProduct">
      <MetaTags>
        <title>Crystalcloud</title>
        <meta
          name="description"
          content="Portail de gestion d'un parc de compteurs et de tout objet communicant pour relever vos compteurs d'aujourd'hui et de demain."
        />
        <meta name="keywords" content="Synchronisation, compteur virtuel, alertes, masques d'alarmes, portail web, évolutif, personnalisable, communication, multi-sites, gestion, site, radio, pdi, compteurs, informations, localisation, filtres, simplicité, flexibilité, statistiques, Chargez vos tournées à distance, regroupement compteurs,création d'alertes,  " />
        <meta property="og:title" content="Crystalcloud" />
        <meta property="og:url" content="https://www.dioptase.fr/logiciels/crystalcloud" />
        <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
        <meta property="og:description" content="Solution des gestion de compteurs" />
      </MetaTags>
      {modal && <PopUp callBack={callBackModal} />}

      <IndicatorBar parent="crystalcloud" />
      <Dashboard isTablet={isTablet} />
      {isTablet ? (
        <ImageSelector src={images("", "fr")[images("", "fr").length - 1]} className="accueil" />
      ) : (
        <ScrollSequence
          duration={4000}
          index={0}
          className="crystalcloud-accueil"
          images={images("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          y={is1366 ? 180 : is1600 ? 80 : 100}
          stretch
          mBottom={0}
          zoom={0.6}
          fixed
          accelerate={-4}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images2("", "fr")[images2("", "fr").length - 1]}
          children={<Synchronisation />}
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={1}
          className="crystalcloud-synchronisation"
          images={images2("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          y={52}
          stretch
          zoom={0.7}
          start={4000 + window.innerHeight - 156}
          children={<Synchronisation />}
          mBottom={0}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images3("", "fr")[images3("", "fr").length - 1]}
          children={<VirtualMeter />}
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={2}
          className="crystalcloud-vm"
          images={images3("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          y={52}
          stretch
          zoom={0.7}
          start={8000 + window.innerHeight}
          style={{ marginLeft: is1600 ? "-10%" : "-5%", height: `${window.innerHeight}px`, width: `${window.innerWidth}px` }}
          x={-window.innerWidth * (is1600 ? is1366 ? 0.05 : 0.10 : 0.15)}
          children={<VirtualMeter />}
          mBottom={0}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images4("", "fr")[images4("", "fr").length - 1]}
          children={<Alert />}
          imgStyle={{ marginLeft: '-15%' }}
        />
      ) : <ScrollSequence
        duration={4000}
        index={3}
        className="crystalcloud-alert"
        images={images4("", "fr")}
        height={window.innerHeight}
        width={window.innerWidth}
        y={52}
        stretch
        zoom={0.7}
        start={12000 + window.innerHeight}
        style={{ marginLeft: is1600 ? "-20%" : "-15%" }}
        x={-window.innerWidth * (is1600 ? is1366 ? 0.10 : 0.20 : 0.20)}
        children={<Alert />}
        mBottom={0}
        callBack={timeOutFunction}
      />}
      {isTablet ? (
        <ImageSelector
          src={images5("", "fr")[images5("", "fr").length - 1]}
          children={<Mask />}
          className={"mask"}
        />
      ) : <ScrollSequence
        duration={4000}
        index={4}
        className="crystalcloud-mask"
        images={images5("", "fr")}
        height={window.innerHeight}
        width={window.innerWidth}
        y={52}
        start={16000 + window.innerHeight}
        children={<Mask />}
        stretch
        zoom={0.8}
        style={{ height: `${window.innerHeight}px` }}
        x={0}
        mBottom={0}
        callBack={timeOutFunction}
      />}
      <Recap />
      <Compatible
        section={t('all.applications')}
        parentColor="#31c6b3"
        parent={"crystalcloud"}
        t={t}
      />
      <Contact objet="Crystalcloud" t={t} />
      <Footer t={t} />
    </div>
  );
};

const tr = withTranslation()(App);
export default tr;
