import React from "react";

const SvgExit = (props: any) => (
  <svg
    version="1.1"
    id="Calque_1"
    x="0px"
    y="0px"
    viewBox="0 0 288.3 352.2"
    {...props}
  >
    <g>
      <path
        fill={props.fill}
        d="M114.6,7.8c-3.1,5.2-3.2,10.7-0.2,16c3.2,5.6,8.2,8.2,14.6,8.2c37.3,0,74.5,0,111.8,0c9.4,0,15.5,6,15.5,15.4
		c0,85.8,0,171.5,0,257.3c0,9.4-6.1,15.4-15.5,15.4c-22.4,0-44.8,0-67.1,0c-15,0-30-0.1-45,0c-10.8,0.1-18.2,9.4-15.8,19.7
		c1.7,7.5,8.2,12.3,17.1,12.3c36.9,0,73.8,0,110.6,0c27.5,0,47.9-20.4,47.9-47.8c0-42.8,0-85.5,0-128.3c0-42.9,0-85.8,0-128.6
		c0-26.9-20.5-47.4-47.5-47.5c-15.9,0-31.8,0-47.6,0c-21.1,0-42.3,0-63.4,0C123.3-0.1,118,2.1,114.6,7.8z"
      />
      <path
        fill={props.fill}
        d="M77.2,214.3c-6.3-6.4-12.8-12.7-19.1-19.1c-0.8-0.8-1.6-1.7-3-3.3c2.2,0,3.5,0,4.8,0c39.4,0,78.8,0,118.1,0
		c5.1,0,10.3,0.1,15.4-0.1c7.4-0.3,13.7-6,14.7-13.2c1.1-7.8-3-15.2-10.2-17.6c-2.5-0.8-5.4-1-8.1-1c-43.3-0.1-86.5,0-129.8,0
		c-1.3,0-2.7,0-4.3,0c0.2-0.8,0.2-1,0.2-1c7-7,14-13.9,20.9-20.9c6.8-6.9,7.2-16.6,0.9-23.1c-6.5-6.7-16.4-6.6-23.4,0.3
		c-16.3,16.2-32.6,32.5-48.8,48.8c-7.3,7.3-7.3,17.1,0.2,24.7c16.1,16.4,32.6,32.4,49.1,48.5c6.7,6.6,16.5,6.4,22.8-0.1
		C83.9,230.8,83.7,221,77.2,214.3z"
      />
    </g>
  </svg>
);

export default SvgExit;
