import React from 'react';

const SvgCosoluce = (props: any) => (
<svg viewBox="0 0 120.18 38.62" {...props}>
	<g>
		<path fill="#1d1d1b" d="M46.19,15.08c-.94-.5-1.85-.87-2.72-1.11-.87-.24-1.76-.35-2.66-.35s-1.8,.17-2.6,.5c-.8,.33-1.51,.8-2.11,1.42-.61,.61-1.07,1.32-1.4,2.14-.32,.81-.49,1.69-.49,2.63s.17,1.85,.5,2.67c.33,.82,.8,1.52,1.4,2.11,.61,.59,1.32,1.05,2.15,1.37,.83,.33,1.73,.49,2.7,.49,.86,0,1.71-.12,2.56-.37,.85-.24,1.79-.64,2.82-1.18v1.22c-1.77,.94-3.56,1.42-5.36,1.42-1.14,0-2.19-.19-3.18-.58-.98-.38-1.84-.93-2.57-1.63-.73-.7-1.3-1.52-1.69-2.47-.4-.94-.6-1.96-.6-3.06s.21-2.16,.64-3.12,1.01-1.79,1.76-2.48c.74-.69,1.59-1.23,2.55-1.6,.95-.38,1.94-.56,2.98-.56,1.71,0,3.48,.42,5.31,1.26v1.28Z"/>
		<path fill="#1d1d1b" d="M56.48,21.23c-.19-.5-.46-.94-.82-1.32-.35-.38-.77-.67-1.26-.87-.49-.21-1-.31-1.55-.31-1.12,0-2.06,.39-2.81,1.17-.35,.37-.63,.81-.83,1.32s-.3,1.06-.3,1.65,.1,1.12,.3,1.63c.2,.51,.48,.95,.83,1.32,.75,.78,1.69,1.17,2.81,1.17,.56,0,1.08-.1,1.55-.31,.47-.21,.89-.49,1.25-.86s.64-.8,.83-1.31c.19-.5,.29-1.05,.29-1.64s-.1-1.14-.29-1.64m1.04,3.67c-.25,.64-.61,1.18-1.06,1.65-.46,.46-.99,.83-1.61,1.08-.61,.26-1.28,.39-2,.39s-1.38-.13-2-.39c-.63-.26-1.17-.62-1.63-1.08-.46-.46-.81-1.01-1.06-1.65-.25-.63-.38-1.31-.38-2.04s.12-1.4,.38-2.04c.25-.63,.61-1.19,1.07-1.66,.46-.47,1.01-.84,1.63-1.1,.62-.26,1.28-.39,1.99-.39s1.39,.13,2,.39c.61,.26,1.15,.62,1.61,1.1,.46,.47,.81,1.02,1.06,1.65,.25,.63,.38,1.31,.38,2.05s-.13,1.4-.38,2.04"/>
		<path fill="#1d1d1b" d="M65.69,19.68c-.56-.37-1.04-.63-1.45-.79-.41-.15-.83-.23-1.27-.23-.59,0-1.09,.16-1.51,.49-.41,.33-.62,.72-.62,1.2s.18,.86,.54,1.12c.36,.26,.93,.53,1.69,.81,.47,.19,.86,.36,1.16,.5,.3,.14,.59,.32,.86,.53,.27,.21,.49,.48,.65,.81s.24,.72,.24,1.17c0,.77-.31,1.42-.94,1.95-.63,.53-1.4,.8-2.31,.8-1,0-2-.3-2.99-.91v-1.04c.65,.32,1.21,.56,1.68,.7,.47,.14,.95,.21,1.44,.21,.61,0,1.11-.16,1.51-.49,.4-.33,.6-.74,.6-1.24s-.19-.87-.56-1.14c-.38-.27-.96-.56-1.74-.87-.47-.18-.86-.34-1.15-.48-.3-.14-.57-.32-.83-.53-.26-.21-.47-.47-.63-.77-.16-.3-.24-.65-.24-1.05,0-.77,.31-1.41,.93-1.94,.62-.52,1.38-.79,2.28-.79,.43,0,.85,.07,1.26,.2,.41,.13,.88,.35,1.39,.64v1.15Z"/>
		<path fill="#1d1d1b" d="M76.31,21.23c-.19-.5-.47-.94-.82-1.32s-.77-.67-1.26-.87c-.49-.21-1-.31-1.55-.31-1.12,0-2.06,.39-2.81,1.17-.35,.37-.63,.81-.83,1.32-.2,.51-.3,1.06-.3,1.65s.1,1.12,.3,1.63c.2,.51,.48,.95,.83,1.32,.75,.78,1.69,1.17,2.81,1.17,.56,0,1.08-.1,1.55-.31,.47-.21,.89-.49,1.25-.86,.36-.37,.64-.8,.83-1.31,.19-.5,.29-1.05,.29-1.64s-.1-1.14-.29-1.64m1.04,3.67c-.25,.64-.61,1.18-1.06,1.65-.46,.46-.99,.83-1.61,1.08-.61,.26-1.28,.39-2,.39s-1.38-.13-2-.39c-.63-.26-1.17-.62-1.63-1.08-.46-.46-.81-1.01-1.06-1.65-.25-.63-.38-1.31-.38-2.04s.12-1.4,.38-2.04c.25-.63,.61-1.19,1.07-1.66,.46-.47,1.01-.84,1.63-1.1,.62-.26,1.28-.39,1.99-.39s1.39,.13,2,.39c.61,.26,1.15,.62,1.61,1.1,.46,.47,.81,1.02,1.06,1.65,.25,.63,.38,1.31,.38,2.05s-.13,1.4-.38,2.04"/>
	</g>
	<rect fill="#1d1d1b" x="80.23" y="12.73" width="1.06" height="15.09"/>
	<path fill="#1d1d1b" d="M91.23,26.19h-.04c-.47,.66-.95,1.14-1.43,1.42s-1.09,.42-1.85,.42c-.46,0-.9-.07-1.33-.22-.43-.15-.82-.38-1.18-.69-.36-.31-.65-.72-.86-1.24-.21-.52-.32-1.13-.32-1.84v-6.15h1.06v6.13c0,.96,.26,1.69,.77,2.2,.52,.51,1.17,.76,1.95,.76,.74,0,1.34-.17,1.81-.51,.47-.34,.94-.88,1.42-1.62v-6.97h1.06v9.94h-1.06v-1.64Z"/>
	<path fill="#1d1d1b" d="M102.07,19.35c-.56-.24-1.03-.4-1.41-.49-.38-.09-.77-.13-1.18-.13-1.11,0-2.01,.38-2.7,1.15-.69,.77-1.04,1.77-1.04,3.01s.35,2.25,1.04,2.99c.69,.74,1.62,1.11,2.79,1.11,.46,0,.88-.04,1.27-.12,.39-.08,.86-.23,1.41-.45v1.11c-.58,.19-1.07,.32-1.47,.4s-.84,.11-1.29,.11c-1.46,0-2.64-.46-3.53-1.38-.89-.92-1.34-2.14-1.34-3.64,0-.77,.12-1.48,.35-2.14s.57-1.22,1-1.69,.94-.84,1.53-1.1c.59-.26,1.24-.39,1.95-.39,.43,0,.83,.04,1.22,.11,.38,.07,.86,.2,1.42,.38v1.17Z"/>
	<path fill="#1d1d1b" d="M111.5,21.63c-.37-1.93-1.42-2.9-3.14-2.9-.83,0-1.53,.26-2.12,.77-.59,.52-.98,1.22-1.17,2.12h6.44Zm-6.57,.86c0,.07,0,.16-.02,.27v.24c0,.56,.1,1.08,.31,1.57,.21,.49,.5,.91,.87,1.27s.81,.64,1.3,.84c.49,.2,.99,.3,1.5,.3,.66,0,1.29-.12,1.87-.35s1.19-.61,1.83-1.13v1.2c-1.15,.89-2.43,1.33-3.83,1.33-.71,0-1.37-.13-1.98-.39-.61-.26-1.14-.63-1.58-1.11-.44-.48-.79-1.04-1.03-1.69-.24-.65-.36-1.35-.36-2.1s.11-1.39,.34-2.01c.23-.62,.55-1.15,.95-1.61,.41-.45,.89-.8,1.45-1.05,.56-.25,1.17-.38,1.81-.38,.55,0,1.08,.1,1.6,.29,.52,.19,1,.49,1.42,.89,.42,.4,.76,.9,1.01,1.52,.25,.61,.38,1.32,.38,2.11h-7.84Z"/>
	<path fill="#1d1d1b" d="M117.95,15.19c-.2-.46-.52-.7-.96-.7-.34,0-.62,.12-.85,.36-.23,.24-.34,.56-.34,.96,0,.46,.1,.83,.31,1.11,.21,.28,.49,.42,.83,.42,.28,0,.5-.08,.67-.23,.17-.15,.3-.36,.39-.63l.38,.14c-.09,.32-.26,.59-.51,.79-.25,.21-.55,.31-.91,.31-.23,0-.44-.05-.64-.14-.2-.09-.37-.22-.51-.39-.14-.17-.26-.36-.34-.59-.08-.23-.12-.47-.12-.73,0-.24,.04-.46,.11-.67,.08-.21,.18-.4,.32-.56,.3-.34,.71-.52,1.21-.52,.3,0,.57,.08,.78,.23,.22,.15,.41,.39,.57,.72l-.41,.1Zm-2.07-1.89c-.34,.14-.64,.35-.91,.61-.26,.26-.46,.56-.61,.9-.14,.34-.22,.7-.22,1.08s.07,.74,.22,1.08c.14,.34,.35,.64,.61,.9s.56,.46,.91,.61c.34,.14,.7,.22,1.08,.22s.74-.07,1.08-.22c.34-.14,.64-.35,.91-.61,.26-.26,.46-.56,.61-.9,.14-.34,.22-.7,.22-1.08s-.07-.74-.22-1.08c-.14-.34-.35-.64-.61-.9-.26-.26-.56-.46-.91-.61-.34-.14-.7-.21-1.08-.21s-.74,.07-1.08,.21m2.32-.37c.39,.17,.73,.4,1.03,.69,.3,.3,.53,.64,.69,1.03,.17,.39,.25,.8,.25,1.24s-.08,.85-.25,1.24c-.17,.39-.4,.73-.69,1.03s-.64,.53-1.03,.69-.8,.25-1.24,.25-.85-.08-1.24-.25c-.39-.17-.73-.4-1.03-.69s-.53-.64-.69-1.03c-.17-.39-.25-.8-.25-1.24s.08-.85,.25-1.24c.17-.39,.4-.73,.69-1.03,.3-.3,.64-.53,1.03-.69,.39-.17,.8-.25,1.24-.25s.85,.08,1.24,.25"/>
	<path fill="#1d1d1b" d="M16.62,0l-1.25,5.76s-13.08-.31-6.54,10.59c0,0-11.37-4.05-5.92-11.06C2.91,5.3,7.58,.31,16.62,0"/>
	<path fill="#1d1d1b" d="M1.43,12.93s2.73,2.49,5.37,3.74c0,0-3.58,5.14,2.02,8.72,0,0-13.08-.78-7.4-12.46"/>
	<path fill="#1d1d1b" d="M12.05,21.48s10.02-1.07,8.15-10.26c0,0,12.31,7.94-.78,15.58,0,0-4.62,2.82-9.94,2.35l2.57-7.66Z"/>
	<path fill="#1d1d1b" d="M8.49,32.08s6.72,1.1,14.67-6.69c0,0,1.64,11.66-16.86,13.23l2.19-6.54Z"/>
	<path fill="#1d1d1b" d="M16.62,9.03s-1.56,7.01-4.36,6.54c-2.8-.47-4.28-1.87-3.23-4.05,1.05-2.18,5.72-1.71,5.72-1.71,0,0,1.05,.01,1.87-.78"/>
</svg>
);

export default SvgCosoluce;
