import React, { useEffect, useRef } from 'react';
import './style.scss';
import './animation.scss';
import './responsiv.scss';
import MultiUtilisateur from '../../_svg/MultiUtilisateur';
import ContratAbonnement from '../../_svg/ContratAbonnement';
import RemplacementCompteur from '../../_svg/RemplacementCompteur'
import SignatureElectronique from '../../_svg/SignatureElectronique';
import ResiliationContrat from '../../_svg/ResiliationContrat';
import Email from '../../_svg/Email';
import { withTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import imgAccueilIntervention from '../../_resources/image/intervention/accueil_intervention.png'
import imgContratAbonnement from '../../_resources/image/intervention/contrat_abonnement_intervention.png'
import imgRemplacementCompteur from '../../_resources/image/intervention/remplacement_compteur_intervention.png'
import imgSignatureElectronique from '../../_resources/image/intervention/signature_intervention.png'
import imgResiliationContrat from '../../_resources/image/intervention/resiliation_contrat_intervention.png'
import FooterSection from "../section/FooterSection";
import Compatible from '../onyx/Compatible';
import Contact from '../onyx/Contact';
import ResponsivIntervention from './ResponsivIntervention';
import IndicatorBar from "../../sidebars/IndicatorBar";

import { useMatomo } from '@datapunt/matomo-tracker-react';
import { connect } from "react-redux";
import docActions from "../../redux/doc/doc.action";

const Intervention = (props) => {
    const { dispatch, t } = props
    const { trackLink } = useMatomo()

    const first = useRef(0);
    const secondImg = useRef(0);
    const secondText = useRef(0);
    const thirdImg = useRef(0);
    const thirdText = useRef(0);
    const fourthImg = useRef(0);
    const fourthText = useRef(0);
    const fithImg = useRef(0)
    const fithText = useRef(0)
    const sixthText = useRef(0)

    const updateComponents = () => {
        console.log(window.scrollY)
        if(first.current){
            if (window.scrollY <= 800) {
                first.current.style.top = `${(window.scrollY / 3) * 0.375}px`
            }
            if (window.scrollY >= 400 && window.scrollY <= 800) {
                secondText.current.style.transform = `translateX(${50 - ((window.scrollY - 400) * (50 / 400))}%)`
                secondImg.current.style.transform = `translateX(${-50 + ((window.scrollY - 400) * (50 / 400))}%) translateY(${25 - ((window.scrollY - 400) * (25 / 400))}%)`
            }
            if (window.scrollY >= 1050 && window.scrollY <= 1550) {
                secondText.current.style.transform = `translateX(${0 + ((window.scrollY - 1050) * (50 / 500))}%)`
                secondImg.current.style.transform = `translateX(${0 - ((window.scrollY - 1050) * (75 / 500))}%) translateY(${0 + ((window.scrollY - 1050) * (10 / 500))}%)`
            }
            if (window.scrollY >= 1550 && window.scrollY <= 1850) {
                thirdText.current.style.opacity = 1 - ((window.scrollY - 1550) * (1 / 300))
            }
            if (window.scrollY >= 1500 && window.scrollY <= 2350) {
                thirdImg.current.style.transform = `translateY(${((window.scrollY - 1500) / 4)}px)`
            }
            if (window.scrollY >= 2200 && window.scrollY <= 2800) {
                fourthText.current.style.transform = `translateX(${-50 + ((window.scrollY - 2200) * (50 / 600))}%)`
            }
            if (window.scrollY >= 2400 && window.scrollY <= 3000) {
                fourthImg.current.style.transform = `translateX(${50 - ((window.scrollY - 2400) * (50 / 600))}%) translateY(${40 - ((window.scrollY - 2400) * (40 / 600))}%)`
            }
            if (window.scrollY >= 3150 && window.scrollY <= 3550) {
                fithText.current.style.transform = `translateX(${50 - ((window.scrollY - 3150) * (50 / 400))}%)`
                fithImg.current.style.transform = `translateX(${-50 + ((window.scrollY - 3150) * (50 / 400))}%)`
            }
            if (window.scrollY >= 3850 && window.scrollY <= 4150) {
                sixthText.current.style.opacity = 0 + ((window.scrollY - 3850) * (1 / 300))
                sixthText.current.style.transform = `translateY(${(-(window.scrollY - 3850) / 2)}px)`
            }
            if (window.scrollY > 800) {
                first.current.style.top = `100px`
                secondText.current.style.transform = `translateX(0%)`
                secondImg.current.style.transform = `translateX(0%)`
                if (window.scrollY > 1550) {
                    secondText.current.style.transform = `translateX(50%)`
                    secondImg.current.style.transform = `translateX(75%) translateY(10%)`
                    if (window.scrollY > 1850) {
                        thirdText.current.style.opacity = 0
                        if (window.scrollY > 2350) {
                            thirdImg.current.style.transform = `translateY(210px)`
                            if (window.scrollY > 2800) {
                                fourthText.current.style.transform = `translateX(0%)`
                                if (window.scrollY > 3000) {
                                    fourthImg.current.style.transform = `translateX(0%) translateY(0%)`
                                    if (window.scrollY > 3550) {
                                        fithText.current.style.transform = `translateX(0%)`
                                        fithImg.current.style.transform = `translateX(0%)`
                                        if (window.scrollY > 3850) {
                                            sixthText.current.style.opacity = 1
                                            sixthText.current.style.transform = `translateY(-150px)`
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        console.log('premiere fois')
        window.addEventListener('scroll', (e) => {
            e.preventDefault()
            if(first.current){
                if (window.scrollY <= 800) {
                    first.current.style.top = `${(window.scrollY / 3) * 0.375}px`
                }
                if (window.scrollY >= 400 && window.scrollY <= 800) {
                    secondText.current.style.transform = `translateX(${50 - ((window.scrollY - 400) * (50 / 400))}%)`
                    secondImg.current.style.transform = `translateX(${-50 + ((window.scrollY - 400) * (50 / 400))}%) translateY(${25 - ((window.scrollY - 400) * (25 / 400))}%)`
                }
                if (window.scrollY >= 1050 && window.scrollY <= 1550) {
                    secondText.current.style.transform = `translateX(${0 + ((window.scrollY - 1050) * (25 / 500))}%)`
                    secondImg.current.style.transform = `translateX(${0 - ((window.scrollY - 1050) * (40 / 500))}%) translateY(${0 + ((window.scrollY - 1050) * (10 / 500))}%)`
                }
                if (window.scrollY >= 1550 && window.scrollY <= 1850) {
                    thirdText.current.style.opacity = 1 - ((window.scrollY - 1550) * (1 / 300))
                }
                if (window.scrollY >= 1500 && window.scrollY <= 2350) {
                    thirdImg.current.style.transform = `translateY(${((window.scrollY - 1500) / 4)}px)`
                }
                if (window.scrollY >= 2200 && window.scrollY <= 2800) {
                    fourthText.current.style.transform = `translateX(${-50 + ((window.scrollY - 2200) * (50 / 600))}%)`
                }
                if (window.scrollY >= 2400 && window.scrollY <= 3000) {
                    fourthImg.current.style.transform = `translateX(${50 - ((window.scrollY - 2400) * (50 / 600))}%) translateY(${40 - ((window.scrollY - 2400) * (40 / 600))}%)`
                }
                if (window.scrollY >= 3150 && window.scrollY <= 3550) {
                    fithText.current.style.transform = `translateX(${50 - ((window.scrollY - 3150) * (50 / 400))}%)`
                    fithImg.current.style.transform = `translateX(${-50 + ((window.scrollY - 3150) * (50 / 400))}%)`
                }
                if (window.scrollY >= 3850 && window.scrollY <= 4150) {
                    sixthText.current.style.opacity = 0 + ((window.scrollY - 3850) * (1 / 300))
                    sixthText.current.style.transform = `translateY(${(-(window.scrollY - 3850) / 2)}px)`
                }   
            }
        })
        updateComponents()
    }, [])

    function downloadFile() {
        dispatch(docActions.getDoc("intervention", "fr"));
        trackLink({ href: 'intervention', linkType: 'download' })
    }

    const isTablet = useMediaQuery({ query: "(max-width: 1200px)" })

    return (
        <div className="intervention-container responsiv">
            <IndicatorBar parent="intervention" />
            {isTablet ? <ResponsivIntervention t={t}/> : (
                <>
                    <div className='box first-anim'>
                        <div className='b40 center'>
                            <MultiUtilisateur fill={"#e86b8d"}/>
                            <h5>{t('intervention.multi.users')}</h5>
                            <div className='span-container'>
                                <span>{t('intervention.multi.users.description')}</span>
                            </div>
                            <div className={"download-documentation"}>
                                <div className={"button-download"} itemProp="subjectOf">
                                    <button style={{ borderColor: "#e86b8d" }} onClick={downloadFile}>
                                        {t("all.button.download")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='b60' ref={first}>
                            <img src={imgAccueilIntervention} />
                        </div>
                    </div>
                    <div className='box second'>
                        <div className='b60' ref={secondImg}>
                            <img src={imgContratAbonnement}></img>
                        </div>
                        <div className='b40' ref={secondText}>
                            <ContratAbonnement fill={"#e86b8d"}/>
                            <h5>{t('intervention.contract.abo')}</h5>
                            <div className='span-container'>
                                <span>{t('intervention.contract.abo.description')}</span>
                            </div>
                        </div>
                    </div>
                    <div className='box third'>
                        <div className='b100'>
                            <div className='text-container' ref={thirdText}>
                                <RemplacementCompteur fill={"#e86b8d"}/>
                                <h5>{t('intervention.switch.meter')}</h5>
                                <div className='span-container'>
                                    <span>{t('intervention.switch.meter.description')}</span>
                                </div>
                            </div>
                            <div className='img-container' ref={thirdImg}>
                                <img src={imgRemplacementCompteur}></img>

                            </div>
                        </div>
                    </div>
                    <div className='box fourth'>
                        <div className='b40 right' ref={fourthText}>
                            <SignatureElectronique style={{ height: "70px" }} fill={"#e86b8d"}/>
                            <h5>{t('intervention.electronic.signature')}</h5>
                            <div className='span-container'>
                                <span>{t('intervention.electronic.signature.description')}</span>
                            </div>
                        </div>
                        <div className='b60' ref={fourthImg}>
                            <img src={imgSignatureElectronique}></img>
                        </div>
                    </div>
                    <div className='box fith'>
                        <div className='b60' ref={fithImg} >
                            <img src={imgResiliationContrat}></img>
                        </div>
                        <div className='b40' ref={fithText}>
                            <ResiliationContrat fill={"#e86b8d"}/>
                            <h5>{t('intervention.termination.contract')}</h5>
                            <div className='span-container'>
                                <span>{t('intervention.termination.contract.description')}</span>
                            </div>
                        </div>
                    </div>
                    <div className='box center sixth'>
                        <div className='b45' ref={sixthText}>
                            <Email fill={"#e86b8d"}/>
                            <h5>{t('intervention.email')}</h5>
                            <div className='span-container'>
                                <span>{t('intervention.email.description')}</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <Compatible
                section={t('all.materials')}
                parentColor="#E96F90"
                parent={"intervention"}
                t={t}
            />
            <Contact objet="Intervention" t={t} />
            <FooterSection />
        </div>
    );
}

function mapStateToProps(state) {
    const { indicator } = state;

    return {
        indicator,
    };
}

const connectedDoc = connect(mapStateToProps)(Intervention);
const tr = withTranslation()(connectedDoc);
export default tr;
