import React from 'react';

const SvgSynchroGSM = (props: any) => (
	<svg viewBox="0 0 24 14.25" {...props}>
		<path fill={props.fill}
			  d="m18.92,1.41c-.3-.06-.59.12-.65.42l-.4,1.85C16.61,1.53,14.29.18,11.75.18,7.83.18,4.65,3.36,4.65,7.28c0,.3.24.55.55.55s.55-.24.55-.55c0-3.31,2.7-6.01,6.01-6.01,2.16,0,4.13,1.15,5.2,2.99l-1.82-.39c-.29-.06-.59.12-.65.42-.06.29.12.59.42.65l3.13.67c.05.01.11.01.16.01.09,0,.18-.04.25-.09.12-.08.21-.2.24-.34l.67-3.13c.06-.29-.12-.59-.42-.65h0Zm0,0"/>
		<path fill={props.fill}
			  d="m18.37,6.56c-.3,0-.54.26-.53.56.05,1.6-.52,3.13-1.62,4.3-1.1,1.17-2.58,1.85-4.19,1.9-.07,0-.13,0-.2,0-2.09,0-4.02-1.09-5.11-2.85l1.9.51c.29.08.59-.09.67-.39.08-.29-.09-.59-.39-.67l-3.09-.83c-.14-.04-.29-.02-.41.05-.13.07-.22.19-.25.33l-.83,3.09c-.08.29.09.59.39.67.05.01.09.02.14.02.24,0,.46-.16.53-.41l.47-1.74c1.29,2.04,3.55,3.29,5.99,3.29.08,0,.16,0,.24,0,1.9-.06,3.65-.86,4.95-2.24,1.3-1.38,1.98-3.19,1.91-5.09-.01-.3-.26-.54-.56-.53h0Zm0,0"/>
	</svg>

);

export default SvgSynchroGSM;
