import React from 'react';

const FlecheMail = (props: any) => (
    <svg viewBox="0 0 68.33 59.09" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M65.16,56.29a79.86,79.86,0,0,0-8.24-21.51,64,64,0,0,0-6.34-9.66A53.55,53.55,0,0,0,42.39,17c-1.47-1.24-3.07-2.32-4.63-3.45s-3.21-2.11-4.91-3-3.4-1.78-5.11-2.65L25.09,6.68,23.77,6.1c-.44-.18-.9-.33-1.35-.5A102.63,102.63,0,0,0,0,.1V0A77.44,77.44,0,0,1,22.79,4.64c.45.16.92.32,1.37.5l1.34.57c.9.39,1.8.76,2.68,1.18,1.73.91,3.49,1.74,5.15,2.78s3.28,2.09,4.89,3.19,3.1,2.37,4.53,3.69A78.17,78.17,0,0,1,57.64,34.37,61.57,61.57,0,0,1,62.53,45a52.06,52.06,0,0,1,2.73,11.32Z"/>
			<path fill={props.fill} d="M67.47,51.11a.94.94,0,0,0-1.21-.76c-1.49.47-3.79.45-4.86.87S66.27,60,67.83,59C69,58.27,67.78,53.24,67.47,51.11Z"/>
		</g>
	</g>
</svg>
);

export default FlecheMail;
