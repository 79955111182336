import React from 'react';

const SvgBirdz = (props: any) => (
<svg viewBox="0 0 744.6 293.7" {...props}>
	<g>
		<path d="M734.2,87.1c-1.7,0-3.8,0.4-6.2,1.6c-2.3,1.1-21.5,11.1-40.2,20.8l-1.7,0.9l-34.2,17.4L631.5,92c-1.5-2.7-4.4-4.4-7.4-4.4
		c-4.7,0-8.5,3.8-8.5,8.5l-0.4,90.1c0,2,0.7,3.9,1.9,5.4c3,3.7,8.4,4.3,12.1,1.3l110.7-89C748.7,96.6,744.4,85.9,734.2,87.1z"/>
		<path d="M588.3,58.6c-2.2,0-4.3,1.2-5.4,3.2l-15.1,26.5l-56.1-28.6c-10.8-5.6-16.8,4.4-9,11l81.9,65.9c2.7,2.2,6.7,1.7,8.9-1
		c0.9-1.1,1.4-2.5,1.4-4l-0.3-66.7C594.6,61.4,591.8,58.6,588.3,58.6z"/>
		<path d="M561.9,8.7l56.2,45.2c1.9,1.5,4.6,1.2,6.1-0.7c0.6-0.8,1-1.7,0.9-2.7L625,4.7c0-2.4-2-4.3-4.3-4.3c-1.5,0-3,0.8-3.7,2.2
		l-10.4,18.2L568,1.1C560.6-2.7,556.5,4.1,561.9,8.7z"/>
		<path d="M174.4,158.2c-5.4-0.2-10,4.1-10.2,9.5v115.5c0,5.4,4.4,9.9,9.8,9.9c5.4,0,9.9-4.4,9.9-9.9V167.7
		C183.8,162.5,179.6,158.3,174.4,158.2z"/>
		<circle cx="174.2" cy="134.8" r="9.8"/>
		<path d="M446.1,105c-5.4-0.2-10,4.1-10.2,9.5c0,0.2,0,0.4,0,0.6v63.1c-26.4-26.5-69.2-26.6-95.6-0.2c-26.5,26.4-26.6,69.2-0.2,95.6
		c26.4,26.5,69.2,26.6,95.6,0.2c12.8-12.7,19.9-30,19.9-48v-0.4V115.1C455.8,109.7,451.5,105.2,446.1,105z M435.9,225.9
		c0,26.5-21.5,47.9-47.9,47.9c-26.5,0-48-21.5-48-47.9s21.5-48,47.9-48c26.3,0,47.7,21.2,48,47.6V225.9z"/>
		<path d="M67.8,158.2c-18.1,0-35.4,7.2-48.1,20v-63.1c0-5.4-4.4-9.9-9.8-9.9c-5.4,0-9.9,4.4-9.9,9.8v110.4c0,0.1,0,0.3,0,0.4
		c0,37.4,30.2,67.8,67.7,67.8c37.4,0,67.8-30.2,67.8-67.7C135.5,188.7,105.2,158.3,67.8,158.2z M66.5,272.7
		c-25.8-0.1-46.7-21-46.8-46.8v-0.4c0.1-26,21.2-46.9,47.2-46.8c26,0.1,46.9,21.2,46.8,47.2S92.5,272.8,66.5,272.7z"/>
		<path d="M312.9,164.3L312.9,164.3c-1.3-0.6-31.9-14-58.6,1.7c-5.3,3.1-10.1,6.8-14.5,11v-9.3c-0.2-5.4-4.7-9.7-10.2-9.5
		c-5.2,0.2-9.4,4.3-9.5,9.5v115.5c0.2,5.4,4.7,9.7,10.2,9.5c5.2-0.2,9.4-4.3,9.5-9.5v-58.8c-0.3-17.3,9.2-33.4,24.5-41.4
		c17.8-10.5,40.2-0.8,40.4-0.7c5,2.2,10.8,0,13-5C320.1,172.3,317.8,166.5,312.9,164.3z"/>
		<path d="M580.4,274.5h-72.9l79.3-98.2c0.6-0.8,1.1-1.8,1.4-2.8c1.8-5.1-0.9-10.7-6-12.5c-1.1-0.4-2.2-0.6-3.3-0.6h-0.8l-91.5,0.4
		c-4.8,0-8.6,3.9-8.7,8.6c0,3.1,1.7,6,4.4,7.5l36.4,20.7l-39.3,76.8c-1.4,2.6-2.2,5.6-2.2,8.6c0.1,5.6,4.7,10,10.3,9.9c0,0,0,0,0,0
		h92.8c5.1-0.2,9.2-4.5,9-9.6C589.2,278.7,585.3,274.7,580.4,274.5z"/>
	</g>
</svg>

);

export default SvgBirdz;
