import React, { Component } from "react";
import SvgChangement from "../../_svg/ChangementCompteur";
import SvgConcat from "../../_svg/Concat";
import SvgGeoguidage from "../../_svg/Geoguidage";
import SvgMultiProto from "../../_svg/MultiProtocole";
import SvgGeoloc from "../../_svg/Geolocalisation";
import SvgGSM from "../../_svg/SynchroGSM";
import ImgRecap from "../../_resources/image/telephone/saphir.png";
import SvgFiltre from "../../_svg/Filtre";
import Securite from "../../_svg/Securite";
import Support from "../../_svg/Support";

import { withTranslation } from "react-i18next"

import { useMediaQuery } from "react-responsive";

const Footer = (props: any) => {
  const { t } = props
  function svgDisplayer(title: string) {
    switch (title) {
      case t('applications.saphir.gsm'):
        return <SvgGSM height={"3em"} fill={"#b77830"} />;
      case t('applications.saphir.geolocation'):
        return <SvgGeoloc height={"3em"} fill={"#b77830"} />;
      case t('applications.saphir.multi.protocol'):
        return <SvgMultiProto height={"3em"} fill={"#b77830"} />;
      case t('applications.saphir.switch'):
        return <SvgChangement height={"3em"} fill={"#b77830"} />;
      case t('applications.filter'):
        return <SvgFiltre height={"3em"} fill={"#b77830"} />;
      case t('applications.saphir.geoguiding'):
        return <SvgGeoguidage height={"3em"} fill={"#b77830"} />;
      case t('applications.saphir.concat'):
        return <SvgConcat height={"3em"} fill={"#b77830"} />;
        case t('applications.saphir.security'):
          return <Securite height={"3em"} fill={"#b77830"} />
          case t('applications.saphir.support'):
          return <Support height={"3em"} fill={"#b77830"} />
      default:
        return <span>Svg inconnu</span>;
    }
  }

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const sections1 = [
    {
      title: t('applications.saphir.multi.protocol'),
      description: t('applications.saphir.multi.protocol.description'),
    },
    {
      title: t('applications.saphir.switch'),
      description: t('applications.saphir.switch.description'),
    },
    {
      title: t('applications.filter'),
      description: t('applications.saphir.filter.description'),
    },
  ];
  
  const extraSection = [
    {
      title: t('applications.filter'),
      description: t('applications.saphir.filter.description'),
    },
    {
      title: t('applications.saphir.concat'),
      description: t('applications.saphir.concat.description'),
    },
  ];
  
  const sections2 = [
    {
      title: t('applications.saphir.concat'),
      description: t('applications.saphir.concat.description'),
    },
    {
      title: t('applications.saphir.geoguiding'),
      description: t('applications.saphir.geoguiding.description'),
    },
    {
      title: t('applications.saphir.gsm'),
      description: t('applications.saphir.gsm.description'),
    }
  ];
  
  const extraSection2 = [
    {
      title: t('applications.saphir.gsm'),
      description: t('applications.saphir.gsm.description'),
    },
    {
      title: t('applications.saphir.geolocation'),
      description: t('applications.saphir.geolocation.description'),
    },
  ];
  
  const lastSection = [
    {
    title: t('applications.saphir.geolocation'),
    description: t('applications.saphir.geolocation.description'),
  },
  {
    title: t('applications.saphir.support'),
    description: t('applications.saphir.support.description'),
  },
  {
    title: t('applications.saphir.security'),
    description: t('applications.saphir.security.description'),
  },
];

const extraSection3 = [
  {
    title: t('applications.saphir.support'),
    description: t('applications.saphir.support.description'),
  },
  {
    title: t('applications.saphir.security'),
    description: t('applications.saphir.security.description'),
  },
];

  return (
    <div className={"footer saphir"} itemScope itemType="https://schema.org/CreativeWork">
      <div className={"image-container"}>
      <img src={ImgRecap} alt="saphir"/>
        <h2 itemProp="about">{t('all.recap')}</h2>
      </div>
      <div className={"footer-section"}>
        {sections1.map((el: any, index: number) => {
          if ((isMobile && index !== 2) || !isMobile) {
            return (
              <div className={"section"} itemProp="abstract">
                {svgDisplayer(el.title)}
                <h5>{el.title}</h5>
                <span>{el.description}</span>
              </div>
            );
          }
        })}
      </div>
      {isMobile && (
        <div className={"footer-section"}>
          {extraSection.map((el: any) => (
            <div className={"section"} itemProp="abstract">
              {svgDisplayer(el.title)}
              <h5>{el.title}</h5>
              <span>{el.description}</span>
            </div>
          ))}
        </div>
      )}
      <div className={"footer-section"}>
        {sections2.map((el: any, index: number) => {
          if ((isMobile && index !== 2) || !isMobile) {
            return (
              <div className={"section"} itemProp="abstract">
                {svgDisplayer(el.title)}
                <h5>{el.title}</h5>
                <span>{el.description}</span>
              </div>
            );
          }
        })}
      </div>
      {isMobile && (
        <div className={"footer-section"}>
          {extraSection2.map((el: any) => (
            <div className={"section"} itemProp="abstract">
              {svgDisplayer(el.title)}
              <h5>{el.title}</h5>
              <span>{el.description}</span>
            </div>
          ))}
        </div>
      )}
      {!isMobile && (
        <div className={"last-section"}>
          {lastSection.map((el: any) => (
          <div className={"section"} itemProp="abstract">
          {svgDisplayer(el.title)}
          <h5>{el.title}</h5>
          <span>{el.description}</span>
        </div>
          ))}
        </div>
      )}
            {isMobile && (
        <div className={"footer-section"}>
          {extraSection3.map((el: any) => (
            <div className={"section"} itemProp="abstract">
              {svgDisplayer(el.title)}
              <h5>{el.title}</h5>
              <span>{el.description}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
const tr = withTranslation()(Footer)
export default tr;
