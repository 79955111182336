import React from "react";

const ConsoAlarm = (props) => {
	return (
		<svg id="Calque_1" viewBox="0 0 57.2 80.7" {...props}>
			<g>
				<path fill={props.fill} d="M56.6,68.2c0.2-0.3,0.2-0.7-0.1-1c-0.8-0.8-2-1.2-2.9-1.9c-1-0.9-1.8-2-2.6-3.2c-0.8-1.2-1.4-2.4-1.9-3.7
		c-0.2-0.5-0.4-1-0.5-1.5c0.4-0.2,0.6-0.5,0.6-1.1C49.3,47,49,38,46.6,29.5C45,24.2,43.7,18.6,40.5,14c-2.6-3.8-7.1-6.6-12.1-5.6
		C28.5,6,29,3.2,31.2,1.8c0.9-0.6,1.3-0.1,1.5,0.7c0.5,1.9-1.2,3-1.9,4.6c-0.3,0.8,0.7,1.3,1.4,1c1.1-0.5,1.7-1.8,2.1-2.8
		c0.5-1.1,0.6-2,0.2-3.2c-0.4-1.4-1.6-2.5-3.2-2c-1.6,0.4-2.6,2-3.2,3.2c-0.7,1.5-1,3.5-0.1,5.1c0,0,0,0-0.1,0c-4.3,1-7.4,4.7-9.7,8
		c-3.9,5.8-6.2,12.6-7.5,19.2c-0.6,3-0.9,6-0.9,9.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c-0.5,4.7,1,9.2-1.1,13.7
		C6.7,62.9,3,66.5,0.1,70.3c-0.2,0.3-0.2,0.9,0.2,1.1c2.5,2.1,6.4,2.2,9.6,2.4c4.4,0.2,8.9,0.4,13.3,0.5c1.8,0,3.7,0,5.5-0.1
		c-0.4,0.6-0.7,1.2-0.8,2c-0.2,0.9,0,2.2,0.8,2.7c-0.8,0-1.4-0.3-1.9-0.6c0.1,0,0.3-0.1,0.2-0.2c-0.3-1.1-0.1-2.1,0.6-3.1
		c0.1-0.1-0.1-0.2-0.2-0.1c-0.8,1-1.1,2.1-0.8,3.3c0,0,0,0,0,0c-0.9-0.7-1.3-1.9-1.3-3.2c0-0.6-1-0.7-1.2-0.1
		c-0.8,3.1,1.3,5.7,4.8,5.9c3.3,0.3,7.3-2.5,6.3-5.7c-0.2-0.6-1.2-0.8-1.5-0.2c-0.1-0.4-0.3-0.7-0.5-1c2.2-0.2,4.4-0.5,6.5-0.9
		c5.5-0.9,11-2.1,16.6-2.8C57.5,70,57.4,68.5,56.6,68.2z M2.6,70.3c0.4,0.1,0.7,0.2,1.1,0.4c0.7,0.3,1.4,0.6,2,0.9
		c-1.2-0.2-2.3-0.5-3.2-1.1C2.4,70.5,2.5,70.4,2.6,70.3z M29.2,78.9c0,0,0.1-0.1,0.1-0.2c0-0.5-0.1-0.6-0.4-1.1
		c-0.2-0.6-0.2-1.3-0.1-1.9c0.1-0.5,0.3-1,0.4-1.5c0.5,0,1.1-0.1,1.6-0.1c-0.3,1.4-0.3,3.1,1,3.9c-0.6,0.5-1.3,0.8-2.3,0.9
		C29.4,78.9,29.3,78.9,29.2,78.9z M33.5,75.4c-0.4,0.8-0.8,1.5-1.3,2.1c-0.3-0.4-0.7-0.9-0.9-1.4c-0.3-0.7-0.3-1.4-0.2-2.2
		c0.7-0.1,1.3-0.1,2-0.2C33.3,74.3,33.3,74.9,33.5,75.4z M25,72.2c-4.4,0.1-8.8-0.2-13.1-0.3c-1.7,0-3.9,0.1-6-0.2c0,0,0,0,0,0
		c-0.7-0.4-1.4-0.8-2.2-1.2c-0.3-0.1-0.6-0.3-1-0.5c0.7-1,1.5-1.9,2.3-2.8c0.6,1.8,3.1,3,5.1,2.7c0.1,0,0.1-0.1,0-0.2
		c-1-0.2-1.9-0.5-2.7-1c-0.9-0.6-1.3-1.3-1.9-2.1c0.8-1.1,1.7-2.1,2.5-3.2c1,2,4.1,3.2,6.3,2.7c0.1,0,0.1-0.2,0-0.2
		c-1.4-0.1-2.5-0.5-3.6-1.2c-0.9-0.5-1.5-1.3-2.3-1.9c0.8-1.2,1.6-2.4,2.2-3.7c0.7-1.5,1-3.2,1-4.8c0.4,0.3,0.8,0.6,1.2,0.8
		c1.1,0.7,2.4,1,3.7,0.8c0.2,0,0.2-0.3,0-0.4C15.2,55.4,14,55,13,54.3c-0.6-0.4-1-1.1-1.5-1.6c-0.1-0.7-0.1-1.4-0.2-2.1
		c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.3,0,0.3-0.1c-0.4-1.1-0.6-2.3-0.6-3.6c0.4,0.7,0.8,1.4,1.3,1.9c1,1.3,2.4,2.4,4,3.1
		c0.2,0.1,0.4-0.2,0.2-0.3c-1.5-0.8-2.7-1.8-3.7-3.1c-0.9-1.2-1.1-2.6-1.8-3.8c0.1-1.2,0.2-2.3,0.4-3.5c0.4,1,1.4,1.9,2.2,2.6
		c0.9,0.8,2,1.4,3.1,1.8c0.3,0.1,0.4-0.3,0.1-0.4c-1.2-0.4-2.2-1.1-3.1-1.9c-0.8-0.7-1.3-1.7-2-2.4c-0.1-0.1-0.1-0.1-0.2-0.1
		c0.1-0.6,0.2-1.2,0.3-1.7c0.2-0.9,0.3-1.7,0.5-2.6c0.8,1.9,3.3,3.4,4.8,4.5c0.1,0,0.1,0,0.1-0.1c-1-0.8-1.9-1.5-2.8-2.3
		c-1-1-1.5-2-1.8-3.2c0.4-1.7,0.9-3.5,1.4-5.2c0.2,2.4,3.1,5,5.3,5.8c0.1,0,0.2-0.1,0.1-0.2c-2.5-1.3-4.9-4.2-4.8-6.9
		c0-0.1,0-0.1-0.1-0.1c0.5-1.6,1.2-3.2,1.8-4.8c0.6-1.4,1.5-3.1,2.6-4.9c0,1.9,0.3,3.8,1.7,5.3c0,0,0.1,0,0,0
		c-1.4-1.8-1.4-3.9-1.3-6.1c3.7-5.6,9.5-11.2,15.6-6.9c0.3,1.8-1.9,3.9-3.1,5.2c-0.2,0.2,0.1,0.4,0.4,0.3c1.7-0.9,3.4-3.2,3.3-5
		c0.1,0.1,0.3,0.2,0.4,0.4c0,0-0.1,0,0,0c2.9,4.1-3.9,8.4-6.8,10.7c-0.2,0.2,0.1,0.4,0.3,0.3c3.7-1.4,10.1-7.2,6.5-11
		c0.1,0.1,0.2,0.2,0.4,0.3c1.1,1.1,2.1,2.4,2.9,3.7c-0.3,1.5-0.8,3-1.6,4.4c-0.6,1.1-1.4,2.1-2.3,3c-1,1-2.2,1.6-3.3,2.4
		c-0.2,0.1,0,0.4,0.2,0.4c2.5-0.8,4.5-3.1,5.7-5.1c0.9-1.4,1.3-3,1.5-4.6c1.5,2.8,2.5,6,3.3,9c-0.4,1.3-1.2,2.5-2.2,3.6
		c-1.9,2.1-5,4.2-8.1,4.5c-0.1,0-0.1,0.2,0,0.2c3.5,0.1,6.7-2,8.9-4.3c0.6-0.7,1.3-1.5,1.8-2.4c0.1,0.5,0.2,0.9,0.4,1.4
		c0.1,0.5,0.2,0.9,0.4,1.4c-1.1,2-2,4-3.6,5.7c-2.1,2.4-4.6,3.7-7.9,4.4c-0.1,0-0.1,0.2,0,0.2c5.4,0.4,9.7-4,11.8-8.5
		c0.3,1.5,0.6,3,0.8,4.4c-0.1,0.4-0.3,0.8-0.5,1.2c-0.7,1.4-1.7,2.8-2.8,4c-1.1,1.2-2.4,2.2-3.8,3c-1.5,0.9-3.2,1.1-4.9,1.3
		c-0.2,0-0.2,0.3,0,0.3c3.4,1,7.2-1.3,9.4-3.5c1-1,2.1-2.4,3-3.8c0.2,1.4,0.3,2.8,0.4,4.2c-0.2-0.1-0.5-0.1-0.6,0.2
		c-0.5,1-0.5,2-1.1,3c-0.7,1.3-1.9,2.3-3.1,3.1c-2.2,1.5-4.8,2.2-7.3,2.9c-0.2,0.1-0.2,0.4,0.1,0.4c3.1,0.2,6.2-1.1,8.6-2.7
		c1.3-0.9,3-2.5,3.7-4.1c0.1,1.4,0.1,2.7,0.2,4.1c-0.2,0-0.4,0.1-0.5,0.3c-1.7,3.5-6.3,5.7-10.3,6.5c-0.3,0.1-0.2,0.4,0.1,0.4
		c3.8,0,8.5-2.1,10.7-5.1c0,0.7,0,1.4,0,2c-0.1,0-0.3,0.1-0.3,0.2c-0.6,1.6-2.5,2.5-4.1,3.1c-2.3,0.8-4.7,1.2-7.2,1.4
		c-2.5,0.2-5,0.2-7.4,0.1c-1.7-0.1-3.7-0.8-5.4-0.6c-0.4,0.1-0.7,0.6-0.4,1c1.1,1.5,4.1,1.5,5.8,1.6c2.8,0.2,5.6,0,8.4-0.4
		c3.4-0.5,9-1.6,10.6-4.8c0,0,0,0,0,0c0,0.7,0.4,1,1,1.2c0,0.2,0,0.4,0,0.7c-0.3,0.2-0.4,0.6-0.6,0.9c-0.4,0.6-1,1.1-1.6,1.5
		c-1.5,1.2-3.4,2-5.2,2.7c-2.7,1-6.9,2.1-9.7,0.6c-0.1-0.1-0.2,0.1-0.1,0.2c2.3,2.4,7.3,1,10.1,0.1c1.9-0.6,3.8-1.5,5.4-2.7
		c0.5-0.3,1.4-1.1,1.9-1.8c0.3,1.1,0.8,2.3,1.3,3.4c-1.2,0.5-2.1,1.8-3.2,2.5c-1.4,0.9-3,1.3-4.7,1.7c-3.3,0.7-6.7,0.8-10.1,0.9
		c-0.3,0-0.3,0.4,0,0.4c3.6,0.2,7.2,0.2,10.8-0.5c1.7-0.4,3.4-1,4.9-1.8c1-0.6,2.3-1.4,2.7-2.4c0.7,1.2,1.5,2.2,2.5,3.2
		c-4,3.2-10,1.9-14.7,3.9c-0.1,0,0,0.1,0,0.1c5.1-1.2,11.1,0,15.4-3.3c0.5,0.4,1.2,0.9,1.9,1.2c-4.5,0.6-9,1.5-13.5,2.3
		C35.8,71.6,30.5,72.1,25,72.2z"/>
				<path fill={props.fill} d="M29.6,29.9c5.7,0.6,12.3-4.6,10.6-10.1c-0.1-0.2-0.3-0.1-0.3,0c-0.4,5.1-4.1,10.1-10.3,9.7
		C29.4,29.5,29.4,29.8,29.6,29.9z"/>
				<path fill={props.fill} d="M16.4,60.8c-2-0.7-3.4-1.4-4.7-2.9c-0.2-0.2-0.4,0-0.4,0.2c0.4,2,3.2,2.9,5.1,2.9C16.5,61,16.5,60.8,16.4,60.8
		z"/>
				<path fill={props.fill} d="M17.2,23.6c0-0.2-0.3-0.2-0.3,0c-0.5,1.9,0.3,3.9,2.2,4.8c0,0,0.1,0,0,0C17.5,27.2,17.2,25.4,17.2,23.6z" />
			</g>
		</svg>
	)
}

export default ConsoAlarm;