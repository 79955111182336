import React from 'react';

const SvgStart = (props: any) => (
<svg viewBox="0 0 80.17 40.32" {...props}>
	<path d="M38.25,38.62h.97v-4.46h-.97v4.46Zm0-5.06h.97v-1.09h-.97v1.09Z"/>
	<g>
		<path d="M43.54,38.62h-.97v-2.28c0-.46-.02-.76-.05-.91-.04-.15-.1-.27-.2-.35-.1-.08-.21-.12-.34-.12-.16,0-.31,.05-.44,.16-.13,.11-.22,.25-.28,.44-.05,.18-.08,.53-.08,1.05v2.02h-.97v-4.46h.9v.65c.16-.25,.34-.44,.54-.57,.2-.13,.42-.19,.67-.19,.28,0,.52,.07,.72,.22,.19,.15,.33,.34,.4,.57,.07,.23,.11,.57,.11,1v2.77Z"/>
		<path d="M44.1,34.16h.54v-.34c0-.41,.04-.7,.12-.88,.08-.18,.2-.32,.37-.43,.17-.1,.38-.15,.64-.15,.27,0,.54,.05,.81,.15l-.13,.82c-.16-.04-.31-.07-.45-.07s-.24,.04-.3,.12c-.06,.08-.09,.23-.09,.46v.32h.72v.93h-.72v3.53h-.97v-3.53h-.54v-.93Z"/>
		<path d="M47.63,36.39c0,.45,.09,.79,.27,1.02,.18,.23,.39,.35,.64,.35s.45-.12,.63-.35c.17-.23,.26-.58,.26-1.03s-.09-.78-.27-1.02c-.18-.23-.39-.35-.64-.35s-.45,.12-.63,.35c-.17,.23-.26,.57-.26,1.02m-.99-.06c0-.44,.09-.84,.26-1.19,.17-.36,.4-.63,.68-.81,.28-.18,.6-.27,.95-.27,.59,0,1.05,.23,1.39,.69s.5,1,.5,1.62c0,.46-.09,.88-.26,1.25-.17,.37-.4,.65-.69,.83-.28,.19-.6,.28-.93,.28-.54,0-.99-.21-1.35-.62-.36-.42-.54-1.01-.54-1.77"/>
		<path d="M52.1,38.62h-.97v-4.46h.9v.63c.15-.3,.29-.49,.41-.59,.12-.1,.26-.15,.42-.15,.22,0,.43,.07,.63,.22l-.3,1.03c-.16-.13-.31-.19-.45-.19-.14,0-.25,.05-.36,.15-.1,.1-.18,.28-.22,.54-.05,.26-.07,.74-.07,1.44v1.38Z"/>
		<path d="M53.84,34.16h.89v.61c.16-.24,.33-.42,.52-.54,.19-.12,.4-.18,.62-.18,.24,0,.44,.06,.61,.18,.17,.12,.31,.3,.42,.53,.16-.24,.34-.42,.52-.53,.19-.12,.38-.18,.59-.18,.25,0,.46,.06,.64,.18,.18,.12,.32,.29,.41,.51,.09,.22,.14,.56,.14,1.02v2.85h-.97v-2.55c0-.45-.04-.74-.12-.89-.08-.15-.21-.22-.39-.22-.22,0-.39,.1-.52,.31-.13,.21-.2,.61-.2,1.21v2.14h-.97v-2.45c0-.48-.03-.8-.08-.94-.07-.18-.21-.28-.42-.28-.14,0-.27,.05-.39,.14-.12,.1-.21,.24-.26,.42-.05,.18-.08,.49-.08,.93v2.17h-.97v-4.46Z"/>
		<path d="M62.2,36.48c-.12,.05-.32,.11-.58,.18-.3,.08-.5,.17-.59,.26-.09,.1-.14,.22-.14,.37,0,.17,.05,.32,.15,.43,.1,.12,.23,.17,.38,.17,.14,0,.27-.05,.41-.14,.14-.09,.23-.21,.28-.33,.05-.13,.08-.36,.08-.7v-.24Zm-1.29-.96l-.88-.19c.11-.45,.28-.77,.53-.97s.6-.3,1.06-.3c.41,0,.72,.06,.94,.17,.21,.11,.37,.27,.46,.47,.09,.2,.14,.56,.14,1.08v1.38c0,.39,0,.67,.04,.86,.03,.19,.09,.39,.17,.6h-.96l-.13-.49c-.17,.2-.34,.34-.53,.44-.19,.1-.39,.15-.6,.15-.36,0-.65-.12-.87-.36-.23-.24-.34-.56-.34-.96,0-.25,.05-.47,.14-.66,.09-.19,.22-.34,.39-.45,.17-.11,.44-.21,.81-.3,.45-.1,.76-.2,.94-.29,0-.25-.02-.41-.05-.49-.04-.08-.1-.14-.19-.19-.09-.05-.23-.07-.4-.07s-.31,.04-.41,.12c-.1,.08-.18,.23-.24,.44"/>
		<path d="M65.78,34.16v.94h-.66v1.8c0,.39,0,.62,.03,.68,.04,.11,.12,.17,.24,.17,.09,0,.22-.04,.39-.11l.08,.92c-.22,.12-.48,.18-.76,.18-.25,0-.44-.06-.59-.18-.15-.12-.25-.29-.3-.51-.04-.16-.06-.49-.06-.99v-1.95h-.45v-.94h.45v-.89l.97-.69v1.57h.66Z"/>
	</g>
	<path d="M66.45,38.62h.97v-4.46h-.97v4.46Zm0-5.06h.97v-1.09h-.97v1.09Z"/>
	<g>
		<path d="M70.84,36.34c0-.46-.08-.8-.23-1.03-.15-.22-.34-.33-.57-.33s-.43,.11-.58,.34c-.16,.23-.24,.59-.24,1.09s.08,.84,.23,1.06c.15,.22,.34,.32,.56,.32s.42-.12,.58-.36c.17-.24,.25-.61,.25-1.09m-.04,3.98v-2.24c-.13,.21-.29,.37-.48,.48-.19,.11-.39,.17-.6,.17-.41,0-.76-.2-1.05-.61-.3-.41-.44-1-.44-1.77,0-.73,.15-1.29,.45-1.69,.3-.4,.67-.59,1.1-.59,.23,0,.44,.06,.62,.19,.18,.13,.34,.32,.48,.57v-.66h.89v6.15h-.97Z"/>
		<path d="M75.12,38.62v-.67c-.13,.24-.31,.43-.53,.57-.22,.14-.45,.2-.68,.2s-.46-.06-.64-.19c-.19-.13-.33-.31-.43-.54-.09-.23-.14-.57-.14-1.01v-2.82h.97v2.05c0,.59,.02,.96,.04,1.11,.03,.16,.09,.28,.2,.37,.1,.09,.22,.13,.36,.13,.16,0,.31-.06,.45-.17,.14-.11,.23-.26,.27-.44,.04-.18,.07-.57,.07-1.17v-1.88h.97v4.46h-.9Z"/>
		<path d="M79.21,36.01c0-.35-.08-.61-.22-.79-.14-.17-.31-.26-.5-.26-.2,0-.38,.09-.52,.27-.14,.19-.21,.44-.21,.78h1.45Zm-.05,1.2l.96,.2c-.13,.45-.33,.78-.6,1-.27,.22-.6,.33-.99,.33-.54,0-.96-.18-1.25-.54-.35-.42-.52-1.01-.52-1.77s.17-1.34,.52-1.79c.3-.38,.68-.57,1.15-.57,.52,0,.93,.19,1.23,.58,.34,.44,.51,1.1,.51,1.96v.13h-2.43c0,.35,.08,.63,.24,.82,.15,.19,.34,.29,.55,.29,.31,0,.52-.21,.62-.64"/>
		<path d="M25.96,29.15h-4.83V13.07h-3.85v-3.88h12.73v3.88h-4.05V29.15Zm-11.54-1.14c-1.07,1.08-2.6,1.62-4.59,1.62-2.09,0-3.65-.52-4.7-1.55-1.05-1.03-1.57-2.57-1.57-4.61,0-.13,0-.27,0-.41,0-.14,.02-.37,.05-.68h4.41v.58c0,1.1,.13,1.87,.4,2.32,.27,.45,.72,.67,1.35,.67,.51,0,.91-.17,1.2-.51,.29-.34,.44-.81,.44-1.39,0-.97-.72-1.9-2.15-2.79-.23-.16-.41-.27-.54-.35l-.23-.14c-1.93-1.26-3.14-2.21-3.61-2.85-.34-.48-.6-1.03-.77-1.63-.18-.6-.27-1.27-.27-1.98,0-1.74,.52-3.11,1.56-4.11,1.04-.99,2.48-1.49,4.31-1.49,1.99,0,3.51,.49,4.55,1.46,1.05,.98,1.57,2.39,1.57,4.24,0,.08,0,.19-.01,.32,0,.14-.01,.25-.01,.32h-4.32v-.25c0-.87-.14-1.53-.42-1.98-.28-.45-.69-.67-1.22-.67-.46,0-.82,.16-1.1,.47-.28,.32-.41,.73-.41,1.25,0,.85,.78,1.75,2.35,2.7,.29,.18,.5,.32,.66,.41,.12,.07,.3,.18,.52,.32,1.69,1.04,2.76,1.91,3.24,2.62,.29,.46,.52,.98,.67,1.56,.16,.58,.23,1.22,.23,1.9,0,2-.54,3.53-1.6,4.61m18.78-15.24v-3.19L16.6,0,0,9.59V28.76l16.6,9.59,15.92-9.19h-3l3.69-16.38Z"/>
	</g>
	<path d="M35.47,21.73l1.39-8.71,1.38,8.71h-2.77Zm-1.46-12.53l-.81,3.58v15.98l-.68,.39h1.85l.6-3.82h3.78l.6,3.82h4.81l-4.44-19.96h-5.72Z"/>
	<path d="M36.86,13.02l1.38,8.71-1.38-8.71Z"/>
	<g>
		<path d="M54.13,15.62c-.03,.2-.06,.38-.12,.54-.05,.16-.12,.31-.19,.44-.08,.13-.17,.24-.27,.34-.05,.05-.11,.09-.17,.14-.43,.3-1.08,.45-1.95,.45-.02,0-.03,0-.05,0-.04,0-.09,0-.14,0h-.09c-.07-.01-.12-.01-.18-.02s-.1,0-.14,0v-4.86s.09,0,.14,0,.11,0,.18,0c.03,0,.06,0,.09,0,.06,0,.1,0,.14,0,.02,0,.04,0,.05,0,.87,0,1.52,.14,1.95,.41,.06,.04,.12,.08,.17,.13,.1,.09,.19,.2,.27,.32,.08,.12,.14,.26,.19,.41,.05,.15,.09,.32,.12,.5,.03,.18,.04,.38,.04,.59,0,.23-.01,.45-.04,.64m4.75,9.31c0-.48-.02-.84-.03-1.09v-.06c-.08-2.39-.86-3.91-2.35-4.55,.87-.5,1.52-1.14,1.93-1.92,.41-.78,.62-1.74,.62-2.89,0-.94-.14-1.75-.43-2.44s-.73-1.26-1.32-1.72c-.5-.39-1.15-.66-1.95-.82-.8-.16-2.09-.24-3.87-.24h-5.42V29.15h4.76v-7.98h.99c.86,0,1.45,.23,1.78,.67,.33,.45,.49,1.28,.49,2.5,0,.14,0,.43-.03,.89-.02,.45-.03,.84-.03,1.16,0,.52,.03,1,.09,1.46,.06,.45,.15,.89,.27,1.3h5.63c-.73-.57-1.11-1.98-1.15-4.22"/>
		<path d="M54.01,16.16c-.05,.16-.12,.31-.19,.44,.08-.13,.14-.28,.19-.44"/>
		<path d="M58.62,23.47c.08,.12,.14,.26,.19,.41-.05-.15-.12-.29-.19-.41"/>
	</g>
	<polygon points="60.77 9.19 60.77 13.07 64.62 13.07 64.62 29.15 69.45 29.15 69.45 13.07 73.51 13.07 73.51 9.19 60.77 9.19"/>
</svg>
);

export default SvgStart;
