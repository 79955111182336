import React from 'react';

const SvgProduit = (props: any) => (
    <svg viewBox="0 0 22.59 22.59" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M6.88,16.33a.44.44,0,0,0-.44.44.44.44,0,0,0,.44.44.44.44,0,0,0,.44-.44A.43.43,0,0,0,6.88,16.33Z"/>
			<path fill={props.fill} d="M19.86,8.38l2.6-2.6a.42.42,0,0,0,.12-.41.46.46,0,0,0-.3-.32L16,3.1a4.42,4.42,0,0,0-8.31-.34L.31,5.05a.46.46,0,0,0-.3.32.45.45,0,0,0,.12.41L2.8,8.45,1.11,11.26a.46.46,0,0,0,0,.38.46.46,0,0,0,.27.26l2.44.82V19.5a.46.46,0,0,0,.29.42l7.07,2.65a.45.45,0,0,0,.28,0h0l7.94-2.65a.44.44,0,0,0,.3-.42v-7l2.6-.82a.46.46,0,0,0,.3-.32.44.44,0,0,0-.12-.42Zm-3.71-4c0-.11,0-.22,0-.33l5.18,1.6-2.2,2.2-3.6-1.2A4.41,4.41,0,0,0,16.15,4.41ZM8.4,3.26a3.53,3.53,0,0,1,6.87,1.15,3.54,3.54,0,0,1-3.53,3.53A3.53,3.53,0,0,1,8.21,4.41,3.47,3.47,0,0,1,8.4,3.26ZM1.27,5.68l6.1-1.91a4.6,4.6,0,0,0,0,.64,4.4,4.4,0,0,0,.49,2L3.47,7.88Zm.88,5.56,1.4-2.33,7.08,2.37L9.24,13.6Zm8.7,10.27L4.68,19.2V13c4.86,1.61,4.62,1.56,4.76,1.56a.42.42,0,0,0,.37-.21l1-1.74Zm.45-10.94L4.75,8.38,8.31,7.2a4.45,4.45,0,0,0,3.43,1.63A4.37,4.37,0,0,0,15,7.42l2.88,1Zm7.5,8.62-7.06,2.35V12.29l1.56,2a.45.45,0,0,0,.48.15l5-1.58Zm-5-5.73L12,11.25l7.08-2.36,2.2,2.2Z"/>
			<path fill={props.fill} d="M9.69,17.31l-1-.37a.44.44,0,0,0-.31.82l1,.37a.44.44,0,0,0,.31-.82Z"/>
			<path fill={props.fill} d="M9.69,19.19,7,18.2a.44.44,0,0,0-.57.26.43.43,0,0,0,.26.56l2.65,1a.4.4,0,0,0,.15,0,.43.43,0,0,0,.41-.29A.44.44,0,0,0,9.69,19.19Z"/>
			<path fill={props.fill} d="M14.26,2.78a.43.43,0,0,0-.63,0L11.3,5.11l-.57-.57a.45.45,0,1,0-.63.63l.88.88a.45.45,0,0,0,.63,0L14.26,3.4A.44.44,0,0,0,14.26,2.78Z"/>
		</g>
	</g>
</svg>
);

export default SvgProduit;
