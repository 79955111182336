import React from 'react';

const SvgKamstrup = (props: any) => (
    <svg viewBox="0 0 234.98 53.08" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M48.42,15.58l-.07-.08a8.56,8.56,0,0,0-6.92-3.15c-6.27,0-12.54,5.13-12.54,15.22,0,7.58,3.82,14.08,12,14.08a12.67,12.67,0,0,0,7.5-2.88v2.14H55a1.26,1.26,0,0,0,1.37-1.37V34.19h0V14.52A1.26,1.26,0,0,0,55,13.15H48.42Zm-.61,16.78A7.1,7.1,0,0,1,43,34.75c-4.33,0-5.24-4.22-5.24-7.75,0-5.3,2.62-7.58,5.41-7.58a7.73,7.73,0,0,1,4.61,1.94v11Z"/>
			<path fill={props.fill} d="M7.41,0a2.43,2.43,0,0,0-.63.06L1.37.8,0,1V40.9H7.06c1,0,1.48-.63,1.48-1.6V1.48C8.54.34,8.15,0,7.41,0Z"/>
			<path fill={props.fill} d="M28,38.91,19.59,25,27,14.7a1.29,1.29,0,0,0,.28-.8c0-.46-.34-.74-1-.74H18.83l-2.14,3.47L15.21,19,13.7,21.45c-.39.64-.79,1.28-1.19,1.92-.57.92-1.15,1.83-1.72,2.74.66,1.19,1.33,2.37,2,3.55.43.78.86,1.56,1.3,2.34s1,1.82,1.52,2.73L16.88,37h0L19,40.9h8.25c.46,0,1.2-.12,1.2-.74A2.51,2.51,0,0,0,28,38.91Z"/>
			<path fill={props.fill} d="M102.84,17.2a8.75,8.75,0,0,0-8.26-4.84,14.14,14.14,0,0,0-9.91,3.82,7.84,7.84,0,0,0-7.12-3.82,12.8,12.8,0,0,0-8.15,3.08l-.11-1.09c-.12-.85-.69-1.19-1.65-1.19H61.37V40.9h6.89c1.14,0,1.65-.46,1.65-1.65v-17a8.51,8.51,0,0,1,5.41-2.39c2.57,0,3.08,1.82,3.08,4.1V40.9h6.44c1.13,0,1.65-.46,1.65-1.65v-17a9.89,9.89,0,0,1,5.86-2.39c2.57,0,3.08,1.82,3.08,4.1V40.9h6.89c1.14,0,1.65-.46,1.65-1.65V26.09C104,22,103.86,19.48,102.84,17.2Z"/>
			<path fill={props.fill} d="M133.73,29.74c0,4.67.12,7,1.94,9.17,1.54,1.82,4.1,2.56,7.29,2.56A17.88,17.88,0,0,0,150.25,40c.63-.28.91-.57.91-1.19a1.84,1.84,0,0,0,0-.52l-.69-3.64c-.11-.51-.34-.8-.79-.8a2.61,2.61,0,0,0-.8.17,11.91,11.91,0,0,1-3.65.68c-2.5,0-2.9-1.82-2.9-4.78V18.8h6.21a1.24,1.24,0,0,0,1.36-1.31v-3.3c0-.86-.57-1.2-1.36-1.2h-6.16V6.33c0-1-.45-1.48-1.31-1.48h-.34l-5.12.68-1.83.24v24Z"/>
			<path fill={props.fill} d="M172.22,12.42a7.82,7.82,0,0,0-1.59-.23c-3.36,0-5.07,1.2-7.12,3.64l-.12-1.7c0-.86-.57-1.14-1.65-1.14h-6.26V40.73h6.94a1.4,1.4,0,0,0,1.6-1.6v-17a7.54,7.54,0,0,1,5.24-2.22,6.43,6.43,0,0,1,1.54.17h.28c.52,0,.8-.29.91-.91l.86-5.36a2,2,0,0,0,.05-.51v-.11A.77.77,0,0,0,172.22,12.42Z"/>
			<path fill={props.fill} d="M231.33,15.72a11.21,11.21,0,0,0-15.84-.4l-.11-1.14c-.11-.91-.68-1.19-1.76-1.19h-6V53.08h6.78c1.13,0,1.76-.45,1.76-1.76v-12a9.44,9.44,0,0,0,6.38,2.28,11.18,11.18,0,0,0,8.83-4A17.15,17.15,0,0,0,235,26.37C235,22.44,233.84,18.34,231.33,15.72Zm-6.89,17.09a4.82,4.82,0,0,1-3.82,1.88,7.15,7.15,0,0,1-4.5-1.83V21.25a7.53,7.53,0,0,1,4.67-2.06,4.53,4.53,0,0,1,4,2.06,10.21,10.21,0,0,1,1.31,5.63A9.27,9.27,0,0,1,224.44,32.81Z"/>
			<path fill={props.fill} d="M124.8,25l-5.87-2.33c-2-.75-2.21-1.51-2.21-2.21s.87-1.85,2.79-1.85A20.28,20.28,0,0,1,126,20h0l.13,0,.18.06.17.05.16.06.15,0,.12,0,.09,0,.11,0h.31l.15,0,.13-.05.11-.07a.28.28,0,0,0,.1-.09L128,20a.83.83,0,0,0,.07-.13,1,1,0,0,0,.07-.15c0-.05,0-.11,0-.17l0-.14s.05-.32.06-.4l.57-3.54a4.82,4.82,0,0,0,.09-.76c0-.46-.29-.75-.81-1A21.48,21.48,0,0,0,119,11.9c-5.18,0-11.1,2.49-11.1,8.71a8.62,8.62,0,0,0,5.92,8.13L119,30.66c2.15.82,2.73,1.4,2.73,2.5,0,1.28-1.28,2-3.14,2a21.24,21.24,0,0,1-7.46-1.71h0l-.15-.06-.16-.05-.18-.06-.17-.06-.18-.06-.16,0-.14,0-.13,0-.09,0-.11,0h-.31l-.14,0-.13.05-.12.06-.1.09a.44.44,0,0,0-.08.11.57.57,0,0,0-.08.13l-.06.14a1.54,1.54,0,0,0,0,.17l0,.14s-.25,1.58-.41,2.53h0l-.22,1.41a2.65,2.65,0,0,0-.09.68,1.05,1.05,0,0,0,.81,1,26.12,26.12,0,0,0,9.71,2.15c5.75,0,11.68-2.56,11.68-9.24A7.71,7.71,0,0,0,124.8,25Z"/>
			<path fill={props.fill} d="M200.92,13H194V31.61a9.59,9.59,0,0,1-5.81,2.23c-2.57,0-3.25-1.66-3.25-4V14.46c0-1.2-.57-1.48-1.65-1.48h-6.9V31.39c0,6.15,2.8,10.14,9,10.14a14.65,14.65,0,0,0,9.12-3.13h0v2.33h6.26c1.37,0,1.77-.45,1.77-1.88V14.46C202.57,13.26,202,13,200.92,13Z"/>
		</g>
	</g>
</svg>
);

export default SvgKamstrup;
