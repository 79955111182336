import React from "react";

const SignatureElectronique = (props) => {
    return (
        <svg id="Calque_1" viewBox="0 0 77.57 82.11" {...props}>
            <path fill={props.fill} d="M16.75,54.03c3.83,0,7.81,0,11.41-.03,0,0,.08-.38,.15-.62,.25-.98,.48-1.96,.65-2.96,.44-2.55,.85-5.11,1.31-7.88-5.63,2.65-9.91,6.49-13.52,11.49Zm-2.29,3.68c-.57,1.05-1.1,2.11-1.7,3.29l-1.46,3.95c-1.04,3.17-1.74,6.41-1.51,9.78,.06,.88,.28,1.77,.58,2.6,.44,1.19,1.19,1.56,2.41,1.17,.81-.26,1.63-.63,2.33-1.12,4.59-3.2,7.34-7.78,9.68-12.9l1.43-3.82c.36-.99,.67-2.01,.99-3l-12.74,.07Zm63.11-.21c-15.13,0-30.25,0-45.38,0-.23,0-.47-.04-.69,.02-.16,.04-.39,.17-.43,.3-.33,1.01-.62,2.03-.96,3.17l-1.67,4.32c-1.99,4.73-4.65,9.04-8.34,12.66-1.5,1.47-3.14,2.74-5.1,3.54-3.69,1.5-6.72,.08-7.87-3.74-.89-2.96-.66-5.96-.11-8.94,.26-1.44,.64-2.86,.98-4.38l1.39-3.74c.51-1.02,1-2.13,1.5-3.23h-.56c-3.44,0-6.88,0-10.32,0l.08-3.45c4.14-.07,8.67-.04,12.81-.02,0,0,.12-.18,.51-.76,4.25-6.42,9.72-11.42,16.9-14.42,.25-.11,.56-.46,.57-.71,.47-8.58,.32-17.12-1.57-25.55-.4-1.79-1.11-3.52-1.76-5.24-.25-.66-.68-1.28-1.11-1.85-1.73-2.32-3.88-2.68-6.26-1.03-2.2,1.53-3.77,3.66-5.21,5.87-3.79,5.81-6.62,12.11-9.24,18.5-.28,.69-.54,1.41-.86,2.08-.42,.87-1.34,1.2-2.2,.87-.83-.32-1.31-1.26-.97-2.14C4.92,21.4,8.33,13.21,13.78,6.07c1.43-1.87,3.06-3.55,5.09-4.79,3.56-2.17,7.19-1.55,9.8,1.7,1.81,2.25,2.83,4.89,3.61,7.63,1.52,5.38,2.09,10.9,2.24,16.46,.09,3.47,.02,6.94,.02,10.5,3.41-.32,6.4,.76,8.76,3.48,.45-1.13,.79-2.24,1.32-3.25,.76-1.45,1.51-2.96,2.54-4.21,2.52-3.08,6.03-3.03,8.74-.1,1.33,1.44,2.68,2.88,4.18,4.15,2,1.7,4.33,2.76,7.04,2.77,2.3,0,4.28-.85,6.1-2.17,1.26-.92,2.3-.93,2.96-.02,.68,.94,.37,1.91-.9,2.82-5.67,4.05-13.38,3.52-18.4-1.33-1.02-.98-1.92-2.08-2.84-3.16-2.11-2.46-3.42-2.38-5.08,.42-1.89,3.2-2.88,6.73-3.68,10.32-.14,.65-.22,1.31-.41,1.95-.33,1.14-1.16,1.69-2.1,1.46-1-.24-1.44-1.01-1.3-2.2,.11-.96,.37-1.97,.21-2.9-.53-3.09-3.99-5.24-7.04-4.49-.22,.05-.51,.32-.54,.53-.68,4.04-1.32,8.09-1.97,12.14,0,.05,.03,.11,.08,.26h1.08c14.55,0,29.76,.05,44.32-.04v3.51Z" />
        </svg>
    )
}

export default SignatureElectronique