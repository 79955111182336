import { authHeader, handleResponse } from '../../_helpers';

async function login(loginRequest) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(loginRequest),
    };
    let response = await fetch('/api/auth/signin', requestOptions);
    return handleResponse(response);
  }
  
  const adminService = {
    login,
  };
  
  export default adminService;