import React from 'react';

const Mail = (props: any) => (
	<svg viewBox="0 0 15 9.61" {...props}>
		<path fill={props.fill}
			  d="m12.96.96h-6.72V.22c0-.22-.18-.4-.4-.4h-2.73c-.22,0-.4.17-.4.4v.75h-.32c-.46,0-.84.37-.84.83v6.79c0,.46.38.84.84.84h10.56c.46,0,.84-.38.84-.84V1.79c0-.46-.38-.83-.84-.83ZM3.21.3h2.55v.66h-2.55V.3Zm10.11,8.28c0,.19-.16.36-.36.36H2.41c-.19,0-.36-.16-.36-.36V1.79c0-.19.16-.35.36-.35h10.56c.2,0,.36.16.36.35v6.79Z"/>
		<path fill={props.fill}
			  d="m5.89,2.23c-1.63,0-2.96,1.33-2.96,2.96s1.33,2.97,2.96,2.97,2.96-1.33,2.96-2.97-1.33-2.96-2.96-2.96Zm0,5.45c-1.37,0-2.48-1.12-2.48-2.49s1.11-2.48,2.48-2.48,2.48,1.11,2.48,2.48-1.11,2.49-2.48,2.49Z"/>
		<path fill={props.fill}
			  d="m12.38,2.09h-1.68c-.34,0-.61.28-.61.62v.19c0,.34.27.61.61.61h1.68c.34,0,.61-.27.61-.61v-.19c0-.34-.27-.62-.61-.62Zm.13.81c0,.07-.06.13-.13.13h-1.68c-.07,0-.13-.06-.13-.13v-.19c0-.07.06-.14.13-.14h1.68c.07,0,.13.06.13.14v.19Z"/>
		<path fill={props.fill}
			  d="m5.89,3.28c-1.05,0-1.91.86-1.91,1.91s.86,1.92,1.91,1.92,1.91-.86,1.91-1.92-.86-1.91-1.91-1.91Zm0,3.34c-.78,0-1.42-.64-1.42-1.43s.64-1.42,1.42-1.42,1.42.64,1.42,1.42-.64,1.43-1.42,1.43Z"/>
	</svg>

);

export default Mail;
