import indicatorConstants from './indicator.constants';

export default function indicator(state = {}, action) {
    switch (action.type) {
        case indicatorConstants.SET_INDICATOR_REQUEST:
            return {
                ...state,
            };
        case indicatorConstants.SET_INDICATOR_SUCCESS:
            return {
                section:action.indicator,
            };
        case indicatorConstants.SET_INDICATOR_FAILURE:
            return {
                error: "Impossible de modifier la section",
            };
        case indicatorConstants.CLEAR:
            return {};
        default:
            return state;
    }
}
