import React from "react";
import { Button } from "reactstrap";
import { withTranslation } from "react-i18next";

const PopUp = (props) => {
  const { t, callBack } = props;

  const container = {
    position: "fixed",
    height: "100%",
    width: "100%",
    backgroundColor: "#000000b6",
    zIndex: "666",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const modal = {
    width: "40%",
    backgroundColor: "white",
    border: "1px solid lightgrey",
    borderRadius: "15px",
    padding: "15px",
    marginTop: "10%",
  };

  const content = {
    margin: "30px 0",
  };

  const buttonGroup = {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    marginTop: "15px",
  };

  const separator = {
    width: "80%",
    border: "1px solid lightgrey",
    marginLeft: "10%",
  };

  return (
    <div style={container} className="popup">
      <div style={modal}>
        <div className="content" style={content}>
          <span>{t("error.connexion.description")}</span>
        </div>
        <div style={separator} />
        <div className="button-group" style={buttonGroup}>
          <Button
            style={{ marginRight: "15px" }}
            className="cancel"
            onClick={() => callBack(false)}
          >
            {t("error.cancel")}
          </Button>
          <Button className="valid" onClick={() => callBack(true)}>
            {t("error.valid")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const tr = withTranslation()(PopUp);
export default tr;
