import React from 'react';

const SvgEigee = (props: any) => (
	<svg viewBox="0 0 97.3 22.88" {...props}>
		<g>
			<path d="M25.36,22.87h-.48v-2.95h2.17c.15,0,.28,.02,.39,.07,.11,.04,.2,.1,.26,.18,.07,.07,.12,.16,.15,.25,.03,.1,.05,.19,.05,.29,0,.08-.01,.15-.03,.23-.02,.08-.05,.15-.1,.22-.05,.07-.11,.13-.18,.18-.08,.05-.17,.09-.28,.12,.1,.03,.19,.09,.24,.16,.06,.07,.11,.17,.15,.28l.36,.98h-.52l-.29-.82c-.03-.09-.07-.17-.11-.23-.04-.06-.09-.11-.14-.14-.05-.03-.11-.06-.17-.07-.06-.01-.12-.02-.19-.02h-1.28v1.28Zm0-2.51v.78h1.61c.08,0,.14-.01,.2-.03,.05-.02,.1-.05,.13-.09,.03-.04,.06-.08,.08-.12,.02-.05,.02-.09,.02-.14,0-.05,0-.1-.02-.14-.02-.05-.04-.09-.07-.13-.03-.04-.08-.07-.13-.09-.05-.02-.12-.03-.2-.03h-1.62Z"/>
			<path d="M32.18,20.35h-1.85c-.09,0-.17,.02-.26,.05-.08,.04-.16,.09-.23,.15s-.13,.14-.17,.23c-.05,.09-.07,.19-.08,.3h2.56v.45h-2.56c0,.13,.02,.25,.06,.36s.09,.2,.16,.28,.14,.14,.23,.18c.09,.04,.18,.06,.27,.06h1.87v.45h-1.94c-.14,0-.27-.03-.41-.09-.14-.06-.26-.15-.37-.26-.11-.11-.2-.25-.26-.4-.07-.15-.1-.33-.1-.52v-.42c0-.19,.03-.36,.1-.52,.07-.16,.16-.29,.27-.4s.24-.2,.37-.26c.14-.06,.28-.09,.42-.09h1.92v.45Z"/>
			<path d="M33.9,21.53c-.19,0-.36-.08-.5-.23-.14-.15-.21-.34-.21-.55v-.22c0-.22,.07-.4,.21-.55,.14-.15,.3-.23,.5-.23h2.1v.45h-2.1c-.08,0-.15,.03-.21,.1-.06,.07-.09,.14-.09,.24v.22c0,.09,.03,.17,.09,.24,.06,.07,.13,.1,.21,.1h1.4c.19,0,.36,.08,.5,.23,.14,.15,.21,.34,.21,.55v.22c0,.22-.07,.4-.21,.55-.14,.15-.3,.23-.5,.23h-2.1v-.45h2.1c.08,0,.15-.03,.21-.1,.06-.07,.09-.14,.09-.24v-.22c0-.09-.03-.17-.09-.24-.06-.07-.13-.1-.21-.1h-1.4Z"/>
			<path d="M37.68,21.53c-.19,0-.36-.08-.5-.23-.14-.15-.21-.34-.21-.55v-.22c0-.22,.07-.4,.21-.55,.14-.15,.3-.23,.5-.23h2.1v.45h-2.1c-.08,0-.15,.03-.21,.1-.06,.07-.09,.14-.09,.24v.22c0,.09,.03,.17,.09,.24,.06,.07,.13,.1,.21,.1h1.4c.19,0,.36,.08,.5,.23,.14,.15,.21,.34,.21,.55v.22c0,.22-.07,.4-.21,.55-.14,.15-.3,.23-.5,.23h-2.1v-.45h2.1c.08,0,.15-.03,.21-.1,.06-.07,.09-.14,.09-.24v-.22c0-.09-.03-.17-.09-.24-.06-.07-.13-.1-.21-.1h-1.4Z"/>
			<path d="M41.98,22.87c-.14,0-.27-.03-.41-.09-.14-.06-.26-.15-.36-.26-.11-.11-.19-.25-.26-.4-.07-.15-.1-.33-.1-.52v-.42c0-.19,.03-.36,.1-.52,.07-.16,.16-.29,.27-.4,.11-.11,.24-.2,.38-.26s.28-.09,.42-.09h.88c.14,0,.28,.03,.42,.09s.26,.15,.37,.26c.11,.11,.2,.24,.26,.4,.07,.16,.1,.33,.1,.52v.41c0,.19-.03,.37-.1,.52-.07,.16-.15,.29-.26,.41-.11,.11-.23,.2-.36,.26-.14,.06-.27,.09-.41,.09h-.94Zm1.58-1.64c0-.13-.02-.25-.06-.36-.04-.11-.1-.2-.16-.28-.07-.08-.15-.14-.24-.18-.09-.04-.18-.06-.28-.06h-.74c-.1,0-.19,.02-.28,.06s-.17,.1-.24,.18c-.07,.08-.13,.17-.17,.28-.04,.11-.06,.23-.06,.36v.31c0,.13,.02,.25,.06,.36,.04,.11,.1,.2,.16,.28,.07,.08,.15,.14,.23,.18s.18,.06,.27,.06h.78c.09,0,.18-.02,.27-.06s.16-.1,.23-.18c.07-.08,.12-.17,.16-.28,.04-.11,.06-.23,.06-.36v-.31Z"/>
			<path d="M48.4,19.91v1.68c0,.19-.03,.37-.1,.52-.07,.16-.15,.29-.26,.41-.11,.11-.23,.2-.36,.26-.14,.06-.27,.09-.41,.09h-.93c-.14,0-.27-.03-.41-.09-.14-.06-.26-.15-.36-.26-.11-.11-.19-.25-.26-.4-.07-.15-.1-.33-.1-.52v-1.69h.49v1.64c0,.13,.02,.25,.06,.36,.04,.11,.1,.2,.16,.28,.07,.08,.15,.14,.23,.18s.18,.06,.27,.06h.77c.09,0,.18-.02,.27-.06s.16-.1,.23-.18c.07-.08,.12-.17,.16-.28,.04-.11,.06-.23,.06-.36v-1.64h.49Z"/>
			<path d="M50.08,22.87h-.48v-2.95h2.17c.15,0,.28,.02,.39,.07,.11,.04,.2,.1,.26,.18,.07,.07,.12,.16,.15,.25,.03,.1,.05,.19,.05,.29,0,.08-.01,.15-.03,.23-.02,.08-.05,.15-.1,.22-.05,.07-.11,.13-.18,.18-.08,.05-.17,.09-.28,.12,.1,.03,.19,.09,.24,.16,.06,.07,.11,.17,.15,.28l.36,.98h-.52l-.29-.82c-.03-.09-.07-.17-.11-.23-.04-.06-.09-.11-.14-.14-.05-.03-.11-.06-.17-.07-.06-.01-.12-.02-.19-.02h-1.28v1.28Zm0-2.51v.78h1.61c.08,0,.14-.01,.2-.03,.05-.02,.1-.05,.13-.09,.03-.04,.06-.08,.08-.12,.02-.05,.02-.09,.02-.14,0-.05,0-.1-.02-.14-.02-.05-.04-.09-.07-.13-.03-.04-.08-.07-.13-.09-.05-.02-.12-.03-.2-.03h-1.62Z"/>
			<path d="M60.68,20.35h-1.85c-.09,0-.17,.02-.26,.05-.08,.04-.16,.09-.23,.15s-.13,.14-.17,.23c-.05,.09-.07,.19-.08,.3h2.56v.45h-2.56c0,.13,.02,.25,.06,.36s.09,.2,.16,.28,.14,.14,.23,.18c.09,.04,.18,.06,.27,.06h1.87v.45h-1.94c-.14,0-.27-.03-.41-.09-.14-.06-.26-.15-.37-.26-.11-.11-.2-.25-.26-.4-.07-.15-.1-.33-.1-.52v-.42c0-.19,.03-.36,.1-.52,.07-.16,.16-.29,.27-.4s.24-.2,.37-.26c.14-.06,.28-.09,.42-.09h1.92v.45Z"/>
			<path d="M54.13,21.54c0,.13,.02,.25,.06,.36s.09,.2,.16,.28,.14,.14,.23,.18c.09,.04,.18,.06,.27,.06h1.87v.45h-1.94c-.14,0-.27-.03-.41-.09-.14-.06-.26-.15-.37-.26-.11-.11-.2-.25-.26-.4-.07-.15-.1-.33-.1-.52v-.42c0-.19,.03-.36,.1-.52,.07-.16,.16-.29,.27-.4s.24-.2,.37-.26c.14-.06,.28-.09,.42-.09h1.92v.45h-1.85c-.09,0-.17,.02-.26,.05-.08,.04-.16,.09-.23,.15s-.13,.14-.17,.23c-.05,.09-.07,.19-.08,.3v.45Z"/>
			<path d="M62.4,21.53c-.19,0-.36-.08-.5-.23-.14-.15-.21-.34-.21-.55v-.22c0-.22,.07-.4,.21-.55,.14-.15,.3-.23,.5-.23h2.1v.45h-2.1c-.08,0-.15,.03-.21,.1-.06,.07-.09,.14-.09,.24v.22c0,.09,.03,.17,.09,.24,.06,.07,.13,.1,.21,.1h1.4c.19,0,.36,.08,.5,.23,.14,.15,.21,.34,.21,.55v.22c0,.22-.07,.4-.21,.55-.14,.15-.3,.23-.5,.23h-2.1v-.45h2.1c.08,0,.15-.03,.21-.1,.06-.07,.09-.14,.09-.24v-.22c0-.09-.03-.17-.09-.24-.06-.07-.13-.1-.21-.1h-1.4Z"/>
			<path d="M70.85,20.35h-1.85c-.09,0-.17,.02-.26,.05-.08,.04-.16,.09-.23,.15s-.13,.14-.17,.23c-.05,.09-.07,.19-.08,.3h2.56v.45h-2.56c0,.13,.02,.25,.06,.36s.09,.2,.16,.28,.14,.14,.23,.18c.09,.04,.18,.06,.27,.06h1.87v.45h-1.94c-.14,0-.27-.03-.41-.09-.14-.06-.26-.15-.37-.26-.11-.11-.2-.25-.26-.4-.07-.15-.1-.33-.1-.52v-.42c0-.19,.03-.36,.1-.52,.07-.16,.16-.29,.27-.4s.24-.2,.37-.26c.14-.06,.28-.09,.42-.09h1.92v.45Z"/>
			<path d="M72.48,20.6v2.27h-.48v-2.96h.43l2.18,2.25v-2.25h.49v2.96h-.45l-2.17-2.27Z"/>
			<path d="M79.36,20.35h-1.85c-.09,0-.17,.02-.26,.05-.08,.04-.16,.09-.23,.15s-.13,.14-.17,.23c-.05,.09-.07,.19-.08,.3h2.56v.45h-2.56c0,.13,.02,.25,.06,.36s.09,.2,.16,.28,.14,.14,.23,.18c.09,.04,.18,.06,.27,.06h1.87v.45h-1.94c-.14,0-.27-.03-.41-.09-.14-.06-.26-.15-.37-.26-.11-.11-.2-.25-.26-.4-.07-.15-.1-.33-.1-.52v-.42c0-.19,.03-.36,.1-.52,.07-.16,.16-.29,.27-.4s.24-.2,.37-.26c.14-.06,.28-.09,.42-.09h1.92v.45Z"/>
			<path d="M80.99,22.87h-.48v-2.95h2.17c.15,0,.28,.02,.39,.07,.11,.04,.2,.1,.26,.18,.07,.07,.12,.16,.15,.25,.03,.1,.05,.19,.05,.29,0,.08-.01,.15-.03,.23-.02,.08-.05,.15-.1,.22-.05,.07-.11,.13-.18,.18-.08,.05-.17,.09-.28,.12,.1,.03,.19,.09,.24,.16,.06,.07,.11,.17,.15,.28l.36,.98h-.52l-.29-.82c-.03-.09-.07-.17-.11-.23-.04-.06-.09-.11-.14-.14-.05-.03-.11-.06-.17-.07-.06-.01-.12-.02-.19-.02h-1.28v1.28Zm0-2.51v.78h1.61c.08,0,.14-.01,.2-.03,.05-.02,.1-.05,.13-.09,.03-.04,.06-.08,.08-.12,.02-.05,.02-.09,.02-.14,0-.05,0-.1-.02-.14-.02-.05-.04-.09-.07-.13-.03-.04-.08-.07-.13-.09-.05-.02-.12-.03-.2-.03h-1.62Z"/>
			<path d="M88.93,19.75h.4v3.12h-.4v-3.12Z"/>
			<path d="M93.48,20.35h-1.85c-.09,0-.17,.02-.26,.05-.08,.04-.16,.09-.23,.15s-.13,.14-.17,.23c-.05,.09-.07,.19-.08,.3h2.56v.45h-2.56c0,.13,.02,.25,.06,.36s.09,.2,.16,.28,.14,.14,.23,.18c.09,.04,.18,.06,.27,.06h1.87v.45h-1.94c-.14,0-.27-.03-.41-.09-.14-.06-.26-.15-.37-.26-.11-.11-.2-.25-.26-.4-.07-.15-.1-.33-.1-.52v-.42c0-.19,.03-.36,.1-.52,.07-.16,.16-.29,.27-.4s.24-.2,.37-.26c.14-.06,.28-.09,.42-.09h1.92v.45Z"/>
			<path d="M95.2,21.53c-.19,0-.36-.08-.5-.23-.14-.15-.21-.34-.21-.55v-.22c0-.22,.07-.4,.21-.55,.14-.15,.3-.23,.5-.23h2.1v.45h-2.1c-.08,0-.15,.03-.21,.1-.06,.07-.09,.14-.09,.24v.22c0,.09,.03,.17,.09,.24,.06,.07,.13,.1,.21,.1h1.4c.19,0,.36,.08,.5,.23,.14,.15,.21,.34,.21,.55v.22c0,.22-.07,.4-.21,.55-.14,.15-.3,.23-.5,.23h-2.1v-.45h2.1c.08,0,.15-.03,.21-.1,.06-.07,.09-.14,.09-.24v-.22c0-.09-.03-.17-.09-.24-.06-.07-.13-.1-.21-.1h-1.4Z"/>
			<path d="M88.22,21.12h-1.75v.45h1.21c0,.12-.02,.23-.07,.34-.02,.06-.05,.11-.08,.15-.03,.05-.06,.09-.1,.13-.07,.08-.16,.14-.26,.18-.07,.03-.15,.05-.23,.06h-1.03c-.1,0-.2-.02-.3-.06-.1-.04-.18-.1-.26-.18-.04-.04-.07-.08-.1-.13-.03-.05-.06-.1-.08-.15-.05-.11-.07-.23-.07-.36v-.32s0-.07,0-.1c0-.09,.03-.18,.07-.26,.05-.11,.11-.2,.19-.28,.08-.08,.17-.14,.27-.18,.08-.03,.15-.05,.24-.06,.02,0,.05,0,.07,0h1.52v-.45h-1.68c-.13,0-.26,.04-.39,.09-.15,.06-.29,.15-.42,.26-.03,.03-.07,.06-.1,.1-.08,.09-.15,.19-.2,.31-.08,.16-.11,.33-.11,.52v.42c0,.19,.04,.37,.11,.52,.01,.02,.03,.05,.04,.07,.07,.13,.15,.24,.25,.33,.12,.11,.26,.2,.41,.26,.15,.06,.3,.09,.46,.09h1.21c.13,0,.26-.04,.38-.09,.15-.06,.29-.15,.41-.26,.1-.1,.18-.21,.25-.33,.01-.02,.03-.05,.04-.07,.08-.16,.11-.33,.11-.52v-.03h0v-.34s0-.06,0-.09v-.02Z"/>
		</g>
		<path d="M22.67,4.84c0,2.61-2.12,4.73-4.73,4.73H3.18c0,.78,.13,1.49,.4,2.12,.27,.63,.62,1.18,1.06,1.64,.44,.46,.94,.82,1.51,1.06,.57,.25,1.16,.37,1.77,.37h12.2v2.62H7.44c-.89,0-1.78-.18-2.66-.54-.88-.36-1.68-.86-2.39-1.52s-1.28-1.44-1.73-2.35c-.44-.91-.66-1.93-.66-3.05v-2.46c0-1.11,.22-2.12,.67-3.04,.45-.92,1.04-1.7,1.77-2.35,.73-.65,1.54-1.16,2.44-1.52,.9-.37,1.81-.55,2.71-.55h10.34c2.61,0,4.73,2.12,4.73,4.73v.11Zm-2.44-.06c0-1.2-.97-2.17-2.17-2.17H8.08c-.57,0-1.13,.1-1.69,.31-.55,.21-1.06,.5-1.51,.87-.45,.38-.83,.83-1.12,1.37-.29,.54-.47,1.13-.52,1.78h14.82c1.2,0,2.17-.97,2.17-2.17h0Z"/>
		<g>
			<path d="M57.23,.55c-.9,.37-1.72,.87-2.44,1.52-.73,.65-1.32,1.44-1.77,2.35-.45,.92-.67,1.93-.67,3.04v2.46c0,1.12,.22,2.14,.66,3.05,.44,.91,1.02,1.69,1.73,2.35s1.51,1.17,2.39,1.52c.88,.36,1.77,.54,2.66,.54h12.68v-2.62h-12.2c-.61,0-1.19-.12-1.77-.37-.57-.25-1.07-.6-1.51-1.06-.44-.46-.79-1.01-1.06-1.64-.27-.63-.4-1.34-.4-2.12h16.72v-2.62h-16.66c.05-.65,.23-1.24,.52-1.78,.29-.54,.67-1,1.12-1.37,.45-.38,.96-.67,1.51-.87,.55-.21,1.11-.31,1.69-.31h12.04V0h-12.52c-.91,0-1.81,.18-2.71,.55Z"/>
			<path d="M80.8,3.81c.45-.38,.96-.67,1.51-.87,.55-.21,1.11-.31,1.69-.31h12.04V0h-12.52c-.91,0-1.81,.18-2.71,.55-.9,.37-1.72,.87-2.44,1.52-.73,.65-1.32,1.44-1.77,2.35-.45,.92-.67,1.93-.67,3.04v2.46c0,1.12,.22,2.14,.66,3.05,.44,.91,1.02,1.69,1.73,2.35s1.51,1.17,2.39,1.52c.88,.36,1.77,.54,2.66,.54h12.68v-2.62h-12.2c-.61,0-1.19-.12-1.77-.37-.57-.25-1.07-.6-1.51-1.06-.44-.46-.79-1.01-1.06-1.64-.27-.63-.4-1.34-.4-2.12h16.72v-2.62h-16.66c.05-.65,.23-1.24,.52-1.78,.29-.54,.67-1,1.12-1.37Z"/>
			<path d="M49.49,7.14h-10.22v2.62h7.05c-.02,.71-.14,1.37-.39,1.96-.14,.32-.3,.62-.48,.9-.17,.26-.36,.51-.58,.73-.44,.45-.94,.81-1.51,1.06-.44,.19-.88,.31-1.34,.35h-6c-.61,0-1.19-.12-1.76-.37-.57-.25-1.07-.6-1.51-1.06-.21-.22-.4-.47-.58-.73-.18-.28-.34-.58-.48-.9-.27-.63-.4-1.33-.4-2.1v-1.85c0-.2,.01-.4,.03-.59,.05-.54,.18-1.03,.38-1.49,.28-.62,.64-1.16,1.1-1.62,.45-.46,.97-.81,1.55-1.06,.44-.19,.9-.31,1.37-.35,.14-.01,.29-.02,.44-.02h8.86V.02h-9.77c-.76,.05-1.52,.22-2.28,.53-.9,.37-1.71,.87-2.44,1.52-.2,.18-.39,.37-.56,.57-.47,.53-.87,1.12-1.19,1.78-.45,.92-.67,1.93-.67,3.04v2.46c0,1.12,.22,2.14,.66,3.05,.07,.14,.15,.28,.22,.41,.4,.73,.89,1.38,1.48,1.94,.7,.66,1.49,1.17,2.37,1.52,.88,.36,1.77,.54,2.66,.54h7.05c.74-.05,1.49-.21,2.22-.51,.88-.36,1.67-.86,2.37-1.52,.59-.56,1.08-1.21,1.48-1.94,.08-.14,.15-.27,.22-.41,.44-.91,.66-1.93,.66-3.05v-.18h.02v-1.98c0-.18-.01-.35-.03-.52,0-.04,0-.08,0-.12Z"/>
		</g>
		<rect x="24.55" width="1.01" height="17.39"/>
		<rect y="20.35" width="21.22" height="2.52"/>
	</svg>
);

export default SvgEigee;
