import React, { useEffect, useState } from "react";
import _ from "lodash";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import deviceAction from "../../redux/device/device.action";
import { connect } from "react-redux";

const Stuff = props => {
    const { stuff, dispatch } = props;
    const [devices, setDevices] = useState(stuff || [])

    useEffect(() => {
        setDevices(stuff)
    }, [stuff])

    const save = () => {
        dispatch(deviceAction.saveAll(devices))
    }

    const whatType = (type) => {
        switch (type) {
            case 'bluetooth':
                return 'text';
            case 'code':
                return 'text';
            case 'ip':
                return 'text';
            case 'name':
                return 'text';
            case 'systeme':
                return 'text';
            case 'smart':
                return 'boolean';
            case 'dimension':
                return 'object'
            default:
                return 'number';
        }
    }

    const handleChange = (e, type, id, extra) => {
        const clone = _.cloneDeep(devices)
        const i = clone.findIndex(el => el.id === id)
        if (clone[i]) {
            if (whatType(type) === 'number') {
                clone[i][type] = parseFloat(e.target.value)
            } else {
                if (whatType(type) === 'number') {
                    clone[i][type] = parseInt(e.target.value)
                } else if(extra){
                    clone[i][type][extra] = parseFloat(e.target.value)
                }
            }
            setDevices(clone)
        }
    }

    return (
        <div className="stuff-container">
            {devices && devices.length > 0 && devices.map(device => (
                <div className="stuff-box">
                    {_.entries(device).map(el => {
                        return el[0] !== 'id' && (
                            <div className="box-i">
                                <div className="p2">
                                    {el[1].longueur ?
                                        (
                                            <div className="box-dim">
                                                <div className="dimension-box">
                                                    <TextField onChange={(e) => handleChange(e, el[0], device.id, 'longueur')} id="standard-basic" label={'longueur (cm)'} variant="standard" defaultValue={el[1].longueur} />
                                                    <TextField onChange={(e) => handleChange(e, el[0], device.id, 'largeur')} id="standard-basic" label={'largeur (cm)'} variant="standard" defaultValue={el[1].largeur} />
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            <TextField onChange={(e) => handleChange(e, el[0], device.id)} id="standard-basic" label={el[0]} variant="standard" defaultValue={typeof el[1] === "boolean" ? el[1] ? "Oui" : "Non" : el[1]} />
                                        )
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            ))}
            <div className="save">
                <Button variant="outlined" onClick={save}>Sauvegarder</Button>
            </div>
        </div>
    )
}

const mapStateToProps = ({device}) => {
    return {
        device
    }
}

const connectedStuff = connect(mapStateToProps)(Stuff)
export default connectedStuff;