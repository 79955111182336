import React from 'react';

const SvgDiehl = (props: any) => (
	<svg viewBox="0 0 180.94 29.93" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<g id="g2768">
				<path fill={props.fill} id="path2556" d="M0,29.93H27.7c9.18,0,12.4-3,12.4-10.41v-9.1C40.1,3,36.88,0,27.7,0H0V29.93ZM9.18,7.44H25.46c3.93,0,5.46,1.12,5.46,5.38v4.05c0,4.26-1.53,5.37-5.46,5.37H9.18V7.44Z"/>
				<polygon fill={props.fill} id="polygon2558" points="48.31 29.93 57.49 29.93 57.49 0 48.31 0 48.31 29.93"/>
				<polygon fill={props.fill} id="polygon2560" points="65.42 29.93 98.69 29.93 98.69 22.74 74.59 22.74 74.59 17.94 96.92 17.94 96.92 11.74 74.59 11.74 74.59 6.95 98.15 6.95 98.15 0 65.42 0 65.42 29.93"/>
				<polygon fill={props.fill} id="polygon2562" points="105.17 29.93 114.35 29.93 114.35 18.61 133.7 18.61 133.7 29.93 142.87 29.93 142.87 0 133.7 0 133.7 10.67 114.35 10.67 114.35 0 105.17 0 105.17 29.93"/>
				<polygon fill={props.fill} id="polygon2564" points="151.13 29.93 180.94 29.93 180.94 22.24 160.31 22.24 160.31 0 151.13 0 151.13 29.93"/>
			</g>
		</g>
	</g>
</svg>
);

export default SvgDiehl;
