import React, { Component } from "react";

import Footer from "../section/FooterSection";
import Calculator from "./Calculator";
import TopPage from "./TopPage";
import ReactGA from "react-ga4";

export default class ComparateurComponent extends Component<any, any> {
  componentDidMount() {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return (
      <div className="comparateur-component-container">
        <TopPage />
        <Calculator />
        <Footer />
      </div>
    );
  }
}
