import React, { Component } from "react";

import Loupe from "../../_resources/image/loupe.png";
import TxtComparer from "../../_resources/image/txt_comparer.png";

import { useMediaQuery } from "react-responsive";

const TopPage = (props: any) => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div
      className="top-container"
      style={{ height: isMobile ? "fit-content" : "50vh" }}
    >
      <div className="title">
        <img src={TxtComparer} alt="dioptase"/>
      </div>
      <div className="image-container">
        <img src={Loupe} alt="dioptase"/>
      </div>
    </div>
  );
};

export default TopPage;
