import React from "react";

const RemplacementCompteur = (props) => {
    return (
        <svg id="Calque_1" viewBox="0 0 59.26 53.19" {...props}>
            <path fill={props.fill} d="M8.24,28.31H29.03s0-.36,0-.36V.36s0-.36,0-.36h-13.03c-1.05,0-2.02,1.19-2.64,2.18-2.23,3.55-6.5,11.88-8.33,20.57-.08,.39-.11,.79-.09,1.19-.04,1.15,.33,2.31,1.03,3.18,.62,.77,1.43,1.19,2.27,1.19ZM26.89,2.64V25.83H8.3c-.19,0-.41-.19-.56-.37-.32-.39-.51-.94-.54-1.48,0-.11,.02-.22,.04-.33,.8-3.81,2.15-7.87,4-12.06,1.87-4.25,3.57-7.04,4.04-7.8,.48-.76,.82-1.06,.95-1.15h10.65Z" />
            <path fill={props.fill} d="M49,31.15l-.02,.12c.01,.08,.02,.16,.02,.24l-2,9.69h7.58s.04-.44,.04-.44c.03-.34,.34-.59,.67-.55,.32,.03,.55,.3,.55,.62v-.06c.04-.34,.34-.59,.68-.55,.32,.03,.55,.3,.55,.62v-.06c.04-.34,.34-.59,.68-.55,.34,.03,.59,.33,.55,.67l-.02,.16c.01,.05,.02,.11,.02,.16h.31c.35,0,.64,.29,.64,.64v.36s0,5.96,0,5.96v.36c0,.35-.29,.64-.64,.64h-1.08s-.04,.45-.04,.45c-.03,.32-.3,.56-.61,.56-.02,0-.04,0-.06,0-.32-.03-.55-.3-.55-.62v.06c-.04,.32-.31,.56-.62,.56-.02,0-.04,0-.06,0-.32-.03-.55-.3-.55-.62v.06c-.04,.32-.31,.56-.62,.56-.02,0-.04,0-.06,0-.34-.03-.59-.33-.55-.67l.02-.16c-.01-.06-.02-.11-.02-.17h-8.44s-.47,2.29-.47,2.29c0,.87-.71,1.58-1.58,1.58h-12.98v-.36s0-22.74,0-22.74v-.36h17.09c.87,0,1.58,.71,1.58,1.58Z" />
            <path fill={props.fill} d="M0,41.84v.36S0,48.16,0,48.16v.36c0,.21,.09,.42,.24,.57v.03c-.01,.08,0,.15,0,.22v.14c-.03,.21,.03,.42,.16,.58,.13,.16,.32,.26,.53,.28,.28,.03,.54-.09,.69-.29,.13,.16,.32,.27,.54,.29,.28,.03,.54-.09,.69-.29,.13,.16,.32,.27,.54,.29,.44,.05,.82-.28,.86-.71l.03-.3H13.94s.44,2.15,.44,2.15c0,.93,.78,1.71,1.74,1.71h12.91s0-.36,0-.36V29.76s0-.36,0-.36H12.01c-.96,0-1.75,.78-1.75,1.75l.02,.12c-.01,.08-.02,.16-.02,.24l1.96,9.52H5.08v-.13c.03-.21-.03-.42-.16-.58-.13-.16-.32-.26-.53-.28-.27-.03-.54,.09-.69,.29-.13-.16-.32-.27-.54-.29-.28-.03-.54,.09-.69,.29-.13-.16-.32-.27-.54-.29-.44-.05-.82,.28-.86,.71l-.03,.29h-.21C.37,41.02,0,41.39,0,41.84Zm26.61-9.88v18.68h-10.3s-.8-3.86-.8-3.86H1.98v-3.19H14.78s-.07-.36-.07-.36h.07l-2.32-11.27h14.15Z" />
            <path fill={props.fill} d="M45.92,2.1c1.97,3.14,6.41,11.46,8.31,20.46,.08,.36,.1,.74,.09,1.12,.08,2.17-1.31,4.45-3.13,4.45H30.33v-.36s0-27.25,0-27.25V.17h13.09c.98,0,1.91,.99,2.5,1.93Z" />
        </svg>
    )
}

export default RemplacementCompteur