import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import './style.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import _ from 'lodash'
import adminActions from "../../redux/admin/admin.action";
import JwtDecode from 'jwt-decode';
import { history } from "../../_helpers";
import moment from "moment";

const AdminLogin = props => {
    const { dispatch } = props
    const [loginRequest, setLoginRequest] = useState({
        username: null,
        password: null,
    })

    useEffect(() => {
        const token = localStorage.getItem('token')
        if(token){
            const decode = JwtDecode(token)
            console.log(decode.exp - new Date().getTime() / 1000, decode)
            if((decode.exp - new Date().getTime() / 1000) > 0){
                history.push('/admin/dashboard')
            }
        }
    }, [])

    const handleChange = (e, type) => {
        const copy = _.cloneDeep(loginRequest)
        copy[type] = e.target.value
        setLoginRequest(copy)
    }

    const send = (e) => {
        console.log(loginRequest)
        dispatch(adminActions.login(loginRequest))
    }

    return (
        <div className="admin-login-container">
            <div className="wrapper">
                <div className="title">
                    <h2>Dioptase Admin</h2>
                </div>
                <div className="login">
                    <div className="input-box">
                        <TextField onChange={(e) => handleChange(e, 'username')} id="standard-basic" label="Nom d'utilisateur" variant="standard" type="mail" />
                    </div>
                    <div className="input-box">
                        <TextField onChange={(e) => handleChange(e, 'password')} id="standard-basic" label="Mot de passe" variant="standard" type="password" />
                    </div>
                    <div className="login-button">
                        <Button variant="outlined" onClick={send}>Se connecter</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ authentication }) => {
    return {
        authentication
    }
}

const connectedAdmin = connect(mapStateToProps)(AdminLogin);
export default connectedAdmin;