import React from 'react';

const SvgSaphir = (props: any) => (
    <svg viewBox="0 0 187.9 77.6" {...props}>
        <g id="Calque_1">
		<g>
			<g>
				<path fill="#BFBFBF" d="M91.8,65.2c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.1h-2.3v-5.7h2.2
				c0.5,0,1,0.1,1.3	,0.4c0.3,0.3,0.5,0.7,0.5,1.1c0,0.2,0,0.3-0.1,0.4s-0.1,0.2-0.2,0.4c-0.1,0.1-0.2,0.2-0.2,0.3s-0.2,0.1-0.3,0.2
				c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.5C91.9,64.8,91.9,65,91.8,65.2z M90.7,61.4
				c-0.2-0.1-0.4-0.2-0.7-0.2h-1.3v1.6H90c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.6S90.9,61.6,90.7,61.4z M90.8,63.9
				c-0.2-0.2-0.4-0.2-0.7-0.2h-1.4v1.7h1.4c0.3,0,0.5-0.1,0.7-0.2c0.2-0.2,0.2-0.4,0.2-0.6S91,64,90.8,63.9z"/>
                <path fill="#BFBFBF" d="M95.1,63.8v2.3h-0.9v-2.3l-1.7-3.4h0.9l1.2,2.5l1.2-2.5h0.9L95.1,63.8z"/>
                <path fill="#BFBFBF" d="M103.5,63.9c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.4-0.2,0.6s-0.2,0.4-0.4,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
				c-0.3,0.1-0.5,0.1-0.8,0.1h-2v-5.7h2c0.3,0,0.6,0,0.8,0.1c0.3,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.1,0.4,0.2,0.6
				c0,0.2,0,0.4,0.1,0.6c0,0.2,0,0.4,0,0.6S103.5,63.7,103.5,63.9z M102.6,62.3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.3-0.6-0.4-1-0.4h-1
				v4.1h1c0.4,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.4,0.3-0.7c0-0.3,0-0.6,0-1S102.6,62.5,102.6,62.3z"/>
                <path fill="#BFBFBF" d="M104.8,66.2v-5.7h0.9v5.7H104.8z"/>
                <path fill="#BFBFBF" d="M111,64c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.2-0.4,0.3-0.7,0.5
				c-0.3,0.1-0.5,0.2-0.8,0.2s-0.6-0.1-0.8-0.2c-0.2-0.1-0.5-0.3-0.7-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
				c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.5,0-0.7c0-0.3,0-0.5,0-0.7c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.3-0.4
				c0.2-0.2,0.4-0.3,0.7-0.5c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.3,0.1,0.5,0.3,0.7,0.5c0.1,0.1,0.2,0.3,0.3,0.4
				c0.1,0.2,0.1,0.3,0.2,0.5c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.5,0,0.7C111,63.6,111,63.8,111,64z M110.2,62.6c0-0.2,0-0.3,0-0.5
				s-0.1-0.2-0.1-0.3s-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
				c-0.1,0.1-0.3,0.1-0.4,0.3c-0.1,0.1-0.1,0.2-0.2,0.2s-0.1,0.2-0.1,0.3s0,0.3,0,0.5c0,0.2,0,0.4,0,0.7s0,0.5,0,0.7
				c0,0.2,0,0.3,0,0.5c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.1
				c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.2,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0-0.5
				c0-0.2,0-0.4,0-0.7S110.2,62.8,110.2,62.6z"/>
                <path fill="#BFBFBF" d="M116.2,62.9c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.8,0.1h-1.3v2.2h-0.9
				v-5.7h2.2c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7
				C116.3,62.4,116.3,62.7,116.2,62.9z M115.2,61.5c-0.2-0.2-0.4-0.2-0.7-0.2h-1.2v1.9h1.2c0.3,0,0.5-0.1,0.7-0.2
				c0.2-0.2,0.3-0.4,0.3-0.7S115.3,61.6,115.2,61.5z"/>
                <path fill="#BFBFBF" d="M119.3,61.2v4.9h-0.9v-4.9h-1.6v-0.8h4v0.8H119.3z"/>
                <path fill="#BFBFBF" d="M124.6,66.2l-0.4-1.1H122l-0.4,1.1h-0.9l2.1-5.7h0.7l2.1,5.7H124.6z M123.1,61.8l-0.9,2.5h1.8L123.1,61.8z" />
                <path fill="#BFBFBF" d="M129.8,65.2c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.3-0.6,0.3c-0.3,0.1-0.5,0.1-0.8,0.1
				c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6l0.6-0.6c0.2,0.2,0.4,0.4,0.7,0.4s0.5,0.1,0.8,0.1c0.4,0,0.7-0.1,0.9-0.2
				c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.2-0.1-0.2-0.2s-0.2-0.1-0.4-0.1l-0.7-0.1c-0.5-0.1-0.8-0.2-1.1-0.5
				c-0.3-0.3-0.4-0.6-0.4-1.1c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.3,0.6-0.3c0.2-0.1,0.5-0.1,0.8-0.1
				c0.4,0,0.7,0.1,1,0.2s0.5,0.3,0.8,0.5l-0.6,0.5c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2-0.1-0.4-0.1-0.7-0.1c-0.3,0-0.6,0.1-0.8,0.3
				c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.4,0.2,0.6,0.3l0.6,0.1c0.3,0,0.5,0.1,0.6,0.2
				s0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.3,0.3,0.5s0.1,0.4,0.1,0.7C129.9,64.8,129.9,65,129.8,65.2z"/>
                <path fill="#BFBFBF" d="M131.1,66.2v-5.7h3.6v0.8h-2.8v1.7h2.4v0.8h-2.4v1.7h2.8v0.8H131.1z"/>
			</g>
            <g>
				<path fill="#1D1D1B" d="M30.7,49.5c0,2-0.9,3.7-2.6,5.1c-1.8,1.4-4,2.1-6.7,2.1c-2.3,0-4.4-0.6-6.1-1.8c-1.8-1.2-3-2.8-3.8-4.8
				l3.9-1.7c0.6,1.4,1.4,2.5,2.5,3.2c1.1,0.8,2.3,1.2,3.6,1.2c1.4,0,2.5-0.3,3.5-0.9c0.9-0.6,1.4-1.3,1.4-2.1c0-1.5-1.1-2.6-3.4-3.3
				l-4-1c-4.5-1.1-6.8-3.3-6.8-6.5c0-2.1,0.9-3.8,2.6-5.1c1.7-1.3,3.9-1.9,6.6-1.9c2,0,3.9,0.5,5.5,1.5s2.8,2.3,3.5,3.9L26.4,39
				c-0.4-1-1.2-1.7-2.1-2.3c-1-0.5-2.1-0.8-3.3-0.8c-1.1,0-2.1,0.3-3,0.9c-0.9,0.6-1.3,1.3-1.3,2.1c0,1.3,1.2,2.3,3.7,2.8l3.5,0.9
				C28.4,43.7,30.7,46,30.7,49.5z"/>
                <path fill="#1D1D1B" d="M43.5,32c3.2,0,5.7,0.9,7.6,2.6c1.9,1.7,2.8,4.1,2.8,7.1v14.3h-4.2v-3.2h-0.2c-1.8,2.6-4.2,4-7.2,4
				c-2.5,0-4.7-0.8-6.4-2.3s-2.6-3.4-2.6-5.7c0-2.4,0.9-4.3,2.7-5.7c1.8-1.4,4.2-2.1,7.2-2.1c2.6,0,4.7,0.5,6.4,1.4v-1
				c0-1.5-0.6-2.8-1.8-3.8c-1.2-1.1-2.6-1.6-4.2-1.6c-2.4,0-4.3,1-5.8,3.1l-3.8-2.4C36.2,33.5,39.3,32,43.5,32z M37.8,48.8
				c0,1.1,0.5,2.1,1.4,2.8c1,0.8,2.1,1.1,3.4,1.1c1.8,0,3.5-0.7,4.9-2c1.4-1.4,2.1-2.9,2.1-4.8c-1.4-1.1-3.2-1.6-5.7-1.6
				c-1.8,0-3.2,0.4-4.4,1.3S37.8,47.6,37.8,48.8z"/>
                <path fill="#1D1D1B" d="M70.8,56.7c-1.7,0-3.3-0.4-4.6-1.1c-1.4-0.7-2.5-1.7-3.2-2.9h-0.2l0.2,3.2v10.2h-4.3V32.8h4.2V36h0.2
				c0.8-1.2,1.8-2.2,3.2-2.9c1.4-0.7,3-1.1,4.6-1.1c3.1,0,5.6,1.2,7.7,3.6c2.2,2.4,3.3,5.3,3.3,8.7c0,3.4-1.1,6.3-3.3,8.7
				C76.4,55.5,73.8,56.7,70.8,56.7z M70.1,52.7c2.1,0,3.8-0.8,5.2-2.4c1.4-1.5,2.1-3.5,2.1-6c0-2.4-0.7-4.4-2.1-6
				c-1.4-1.6-3.2-2.4-5.2-2.4c-2.1,0-3.9,0.8-5.3,2.4c-1.4,1.6-2.1,3.6-2.1,6c0,2.5,0.7,4.5,2.1,6C66.2,51.9,68,52.7,70.1,52.7z"/>
                <path fill="#1D1D1B" d="M85.3,22.1h4.3v10.7L89.4,36h0.2c0.7-1.1,1.7-2.1,3-2.8c1.4-0.8,2.8-1.1,4.3-1.1c2.8,0,5,0.8,6.5,2.4
				c1.5,1.6,2.3,3.9,2.3,6.9v14.5h-4.3V42.2c0-4.2-1.8-6.2-5.5-6.2c-1.8,0-3.3,0.7-4.5,2.2c-1.2,1.5-1.8,3.2-1.8,5.1v12.6h-4.3V22.1
				z"/>
                <path fill="#1D1D1B" d="M116.5,24.8c0,0.8-0.3,1.6-0.9,2.2c-0.6,0.6-1.3,0.9-2.2,0.9s-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2
				s0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9s1.6,0.3,2.2,0.9C116.2,23.2,116.5,23.9,116.5,24.8z M115.6,32.8v23.1h-4.3V32.8H115.6z
				"/>
                <path fill="#1D1D1B" d="M125.6,55.9h-4.3V32.8h4.2v3.8h0.2c0.4-1.2,1.3-2.3,2.7-3.1c1.4-0.9,2.7-1.3,4-1.3c1.3,0,2.3,0.2,3.2,0.6
				l-1.3,4.2c-0.5-0.2-1.4-0.3-2.5-0.3c-1.6,0-3.1,0.7-4.3,2c-1.2,1.3-1.8,2.9-1.8,4.6V55.9z"/>
			</g>
            <path fill="#CF7830"
                  d="M142.1,45.8V28.6c0-9.5,7.7-17.2,17.2-17.2h0c9.5,0,17.2,7.7,17.2,17.2v0c0,9.5-7.7,17.2-17.2,17.2H142.1z"/>
            <g>
				<path fill="#FFFFFF" d="M164.2,37.3h-10.3c-0.4,0-0.8-0.3-0.8-0.8V24.1c0-0.2,0.1-0.4,0.3-0.6c0.1-0.1,0.2-0.2,0.4-0.2l8-2.5
				c0.2-0.1,0.5,0,0.7,0.1c0.2,0.1,0.3,0.4,0.3,0.6v1.8h1.5c0.4,0,0.8,0.3,0.8,0.8l0,12.5C165,37,164.6,37.3,164.2,37.3z
				 M161.9,21.3C161.9,21.3,161.9,21.3,161.9,21.3l-8.1,2.5c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.2v12.5
				c0,0.1,0.1,0.2,0.2,0.2h10.3c0.1,0,0.2-0.1,0.2-0.2l0-12.5l0,0c0-0.1-0.1-0.2-0.2-0.2h-1.8c-0.1,0-0.3-0.1-0.3-0.3v-2.1
				c0-0.1,0-0.1-0.1-0.2C162.1,21.3,162,21.3,161.9,21.3z M165,24.1L165,24.1L165,24.1z M161.4,23.9h-5.1c-0.1,0-0.2-0.1-0.3-0.2
				c0-0.1,0.1-0.3,0.2-0.3l5.1-1.4c0.1,0,0.2,0,0.2,0c0.1,0.1,0.1,0.1,0.1,0.2v1.4C161.7,23.7,161.6,23.9,161.4,23.9z"/>
			</g>
		</g>
	</g>
        <g id="marges_sécu" display={"none"}>
		<g display={"inline"}>
			<rect x="88.3" y="66.2" width="5.7" height="5.7"/>
            <rect x="88.3" y="71.9" width="5.7" height="5.7"/>
		</g>
            <g display={"inline"}>
			<rect x="156.4" width="5.7" height="5.7"/>
                <rect x="156.4" y="5.7" width="5.7" height="5.7"/>
		</g>
            <g display={"inline"}>
			<rect x="182.2" y="25.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 212.9505 -157.0763)" width="5.7"
                  height="5.7"/>
                <rect x="176.4" y="25.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 207.2275 -151.3534)"
                      width="5.7" height="5.7"/>
		</g>
            <g display={"inline"}>
			<rect x="5.7" y="45.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 56.5198 39.3509)" width="5.7"
                  height="5.7"/>
                <rect y="45.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 50.7968 45.0739)" width="5.7"
                      height="5.7"/>
		</g>
            <rect y="66.2" display={"inline"} opacity={0.5} fill={"#CF7830"} width="187.9" height="11.4"/>
            <rect x="-33.1" y="33.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 44.513 33.0665)" display={"inline"} opacity={0.5} fill={"#CF7830"}
                  width="77.6" height="11.4"/>
            <rect display={"inline"} opacity={0.5} fill={"#CF7830"} width="187.9" height="11.4"/>
            <rect x="143.4" y="33.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 220.9413 -143.3619)"
                  display={"inline"} opacity={0.5} fill={"#CF7830"} width="77.6" height="11.4"/>
	</g>
</svg>
);

export default SvgSaphir;
