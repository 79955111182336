import React from 'react';

const SvgSmartphone = (props: any) => (
    <svg viewBox="0 0 19.99 36.34" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="txt">
			<path fill={props.fill} d="M16.86,0h-14A2.87,2.87,0,0,0,0,3.09V33.4a2.76,2.76,0,0,0,2.82,2.94h14A3,3,0,0,0,20,33.4V3.09A3.15,3.15,0,0,0,16.86,0Zm1.42,33.4a1.31,1.31,0,0,1-1.42,1.23h-14A1.07,1.07,0,0,1,1.71,33.4V31.6H18.06a.78.78,0,0,0,.22,0ZM1.71,3.09c0-.69.34-1.38,1.11-1.38h14a1.42,1.42,0,0,1,1.42,1.38V4.34a.78.78,0,0,0-.22,0H1.71ZM17.21,6V29.9H2.57V6Z"/>
			<path fill={props.fill} d="M10,32.62a.49.49,0,1,0,.49.49A.49.49,0,0,0,10,32.62Z"/>
			<rect fill={props.fill} x="6.45" y="3" width="7.31" height="0.64" rx="0.29"/>
		</g>
	</g>
</svg>
);

export default SvgSmartphone;
