import React from 'react';

const SvgBatterie = (props: any) => (
    <svg viewBox="0 0 14.7 7.13" {...props}>
        <path fill={props.fill}
              d="m12.78,6.95H1.05c-.47,0-.86-.39-.86-.86V.68C.18.21.57-.18,1.05-.18h11.74c.47,0,.86.39.86.86v.66h.38c.47,0,.86.39.86.86v2.36c0,.47-.39.86-.86.86h-.38v.66c0,.47-.39.86-.86.86ZM1.05.39c-.16,0-.29.13-.29.29v5.41c0,.16.13.29.29.29h11.74c.16,0,.29-.13.29-.29v-1.23h.95c.16,0,.29-.13.29-.29v-2.36c0-.16-.13-.29-.29-.29h-.95V.68c0-.16-.13-.29-.29-.29H1.05Z"/>
        <rect fill={props.fill} x="1.25" y=".98" width="8.72" height="4.81"/>
    </svg>
);

export default SvgBatterie;
