import React from 'react';

const SvgDashboard = (props: any) => (
    <svg viewBox="0 0 24 15.12" {...props}>
        <rect fill={props.fill} x="5.01" y="10.59" width="5.78" height="4.71"/>
        <rect fill={props.fill} x="5.01" y=".18" width="5.78" height="8.49"/>
        <rect fill={props.fill} x="12.87" y=".18" width="5.78" height="4.71"
              transform="translate(31.52 5.06) rotate(180)"/>
        <rect fill={props.fill} x="12.87" y="6.81" width="5.78" height="8.49"
              transform="translate(31.52 22.11) rotate(180)"/>
    </svg>
);

export default SvgDashboard;
