import React from 'react';

const SvgHoneywell = (props: any) => (
	<svg viewBox="0 0 315 73.51" {...props}>
		<path d="M259.27,52.06c-.09-2.73-1.64-5.65-4.63-5.65s-4.54,2.92-4.63,5.65h9.27Zm-9.27,1.84c.09,2.85,2.04,5.53,4.88,5.53,3.52,0,4.45-2.52,5.5-2.52,.62,0,1.05,.22,1.05,1.08,0,.98-2.56,3.28-6.4,3.28-5.25,0-7.45-4.24-7.45-8.35s2.44-8.35,7.08-8.35,7.01,3.87,7.01,7.99c0,.58-.19,1.35-1.51,1.35h-10.16Z" />
		<path d="M266.55,54.84c0,.92-.4,1.41-1.11,1.41s-1.11-.49-1.11-1.41v-16.05c0-.92,.4-1.41,1.11-1.41s1.11,.49,1.11,1.41v16.05Z" />
		<path d="M280.43,47.08c0,.49-.18,1.07-1.11,1.07-.86,0-2.01-1.75-4.23-1.75-1.55,0-3.21,.74-3.21,2.3,0,.74,.4,1.5,1.51,1.93l3.37,1.35c2.22,.89,4.11,1.96,4.11,4.54,0,2.89-2.59,4.73-6.05,4.73s-5.93-2.06-5.93-3.1c0-.34,.49-1.07,1.05-1.07,1.05,0,2.07,2.33,4.97,2.33,2.13,0,3.55-1.26,3.55-2.79,0-1.44-1.08-2.03-2.29-2.52l-3.83-1.57c-1.67-.68-2.87-1.66-2.87-3.69,0-2.67,2.66-4.3,5.16-4.3,3.21,0,5.87,1.35,5.81,2.52" />
		<path d="M302.74,52.06c-.09-2.73-1.64-5.65-4.63-5.65s-4.54,2.92-4.63,5.65h9.27Zm-9.27,1.84c.09,2.85,2.04,5.53,4.88,5.53,3.52,0,4.45-2.52,5.5-2.52,.62,0,1.05,.22,1.05,1.08,0,.98-2.56,3.28-6.39,3.28-5.25,0-7.44-4.24-7.44-8.35s2.44-8.35,7.07-8.35,7.01,3.87,7.01,7.99c0,.58-.18,1.35-1.51,1.35h-10.16Z" />
		<path d="M309.84,54.84c0,.92-.4,1.41-1.11,1.41s-1.11-.49-1.11-1.41v-8.86c0-.92,.4-1.41,1.11-1.41s1.11,.49,1.11,1.41v1.53h.06c.8-1.81,2.22-2.95,3.61-2.95,1.05,0,1.48,.58,1.48,1.2s-.43,.95-1.51,1.2c-1.24,.28-3.65,1.32-3.65,4.94v2.94Z" />
		<path d="M284.54,55.87v-9.28h-1.45c-.74,0-.96-.55-.96-.92s.22-.92,.96-.92h1.45v-3.59c0-.95,.4-1.44,1.11-1.44s1.11,.49,1.11,1.44v3.59h1.45c.74,0,.96,.55,.96,.92s-.22,.92-.96,.92h-1.45v9.18" />
		<path d="M286.77,55.58c0,2.19,.19,3.4,1.12,3.56l1.08,.19c.79,.14,.95,.55,.84,1.18-.1,.58-.66,.97-1.91,.75-3.07-.54-3.34-2.16-3.37-5.39" />
		<path d="M251.01,72.97c0,.33-.2,.54-.51,.54s-.51-.21-.51-.54v-7.21c0-.33,.2-.54,.51-.54s.51,.2,.51,.54v7.21Z" />
		<path d="M252.35,67.82c0-.33,.2-.54,.51-.54s.51,.21,.51,.54v.24l.02,.02c.45-.57,1.08-.8,1.69-.8,.94,0,2.13,.6,2.13,2.31v3.38c0,.33-.2,.54-.51,.54s-.51-.21-.51-.54v-3.32c0-1.06-.75-1.5-1.42-1.5s-1.42,.44-1.42,1.5v3.32c0,.33-.2,.54-.51,.54s-.51-.21-.51-.54v-5.15Z" />
		<path d="M260.49,67.28c.84,0,2.04,.45,2.04,1.08,0,.22-.15,.41-.46,.41-.44,0-.61-.62-1.58-.62-.58,0-1.07,.25-1.07,.77,0,1.06,3.27,.95,3.27,2.71,0,1.27-1.1,1.88-2.23,1.88-1.29,0-2.3-.64-2.3-1.16,0-.27,.2-.46,.41-.46,.45,0,.82,.74,1.92,.74,.74,0,1.19-.41,1.19-.82,0-1.19-3.27-1.12-3.27-2.82,0-1.12,1.06-1.72,2.08-1.72" />
		<path d="M263.98,66.03c0-.33,.2-.54,.51-.54s.51,.21,.51,.54v1.32h.41c.33,0,.51,.16,.51,.43s-.17,.44-.51,.44h-.41v4.75c0,.33-.2,.54-.51,.54s-.51-.21-.51-.54v-4.75h-.41c-.33,0-.51-.16-.51-.44s.17-.43,.51-.43h.41v-1.32Z" />
		<path d="M266.65,67.82c0-.33,.2-.54,.51-.54s.51,.21,.51,.54v.51h.02c.23-.47,.8-1.05,1.38-1.05,.36,0,.57,.23,.57,.52s-.21,.47-.63,.55c-.6,.1-1.34,.55-1.34,1.73v2.9c0,.33-.2,.54-.51,.54s-.51-.21-.51-.54v-5.15Z" />
		<path d="M272.71,72.64c1.24,0,1.68-1.21,1.68-2.24s-.44-2.24-1.68-2.24-1.68,1.21-1.68,2.24,.44,2.24,1.68,2.24m0-5.36c1.73,0,2.7,1.5,2.7,3.11s-.97,3.11-2.7,3.11-2.7-1.5-2.7-3.11,.97-3.11,2.7-3.11" />
		<path d="M280.11,69.56c0-.87-.48-1.41-1.31-1.41s-1.3,.54-1.3,1.41v3.41c0,.33-.19,.54-.51,.54s-.51-.21-.51-.54v-5.15c0-.33,.19-.54,.51-.54s.51,.21,.51,.54v.18l.02,.02c.24-.31,.62-.74,1.54-.74,.6,0,1.22,.25,1.68,1.03,.45-.64,.99-1.03,1.88-1.03,.95,0,2.13,.6,2.13,2.31v3.38c0,.33-.2,.54-.51,.54s-.51-.21-.51-.54v-3.41c0-.87-.48-1.41-1.31-1.41s-1.3,.54-1.3,1.41v3.41c0,.33-.2,.54-.51,.54s-.51-.21-.51-.54v-3.41Z" />
		<path d="M290.09,70.03c0-.87-.51-1.88-1.62-1.88-1.25-.05-1.71,.95-1.74,1.88h3.36Zm-3.36,.76c0,1.04,.67,1.85,1.81,1.85,1.35,0,1.69-.82,2.07-.82,.18,0,.37,.16,.37,.45,0,.49-1.26,1.25-2.45,1.25-1.96,0-2.81-1.5-2.81-3.11s1.04-3.11,2.75-3.11,2.64,1.5,2.64,2.97c0,.36-.13,.54-.57,.54h-3.81Z" />
		<path d="M292.4,66.03c0-.33,.19-.54,.51-.54s.51,.21,.51,.54v1.32h.41c.33,0,.51,.16,.51,.43s-.17,.44-.51,.44h-.41v4.75c0,.33-.2,.54-.51,.54s-.51-.21-.51-.54v-4.75h-.41c-.33,0-.51-.16-.51-.44s.17-.43,.51-.43h.41v-1.32Z" />
		<path d="M215.01,23.99s.21-1.46-.19-2.69c0,0-.31-1.56-2.04-2.28-1.89-.79-3.36,1.62-3.49,2.67-.09,.72-.35,1.44,.29,3.31,.64,1.88,2.02,2.21,3.26,1.9,0,0-.15,2.34,1.06,3.82,1.21,1.48,3.48,1.39,4.02,1.31,0,0-.12,2.61,1.24,3.92,1.37,1.31,2.93,1.28,4.23,1.19,0,0-.32,2.57,1.76,3.85,1.81,1.11,3.91,.72,3.91,.72,0,0-.54,1.82,1.28,2.73,1.83,.91,4.68,.84,6.22-.91,0,0,1.27-1.55-.2-3.08-1.47-1.53-4.21-1.26-4.81-1.13,0,0,.7-2.9-1.86-4.3,0,0-1.31-.83-3.71-.55,0,0,.35-2.32-.95-3.74,0,0-1.38-1.93-4.82-1.35,0,0,.67-2.45-1.02-4.17,0,0-1.29-1.68-4.19-1.22" />
		<path d="M218.71,19.79s-2.34,2.09-4.63,0c0,0-1.88-1.78-.05-4.94,0,0,1.38-2.14,3.81-1.32,2.38,.8,2.34,4.07,1.48,5.5,0,0,2.33-1.48,4.42-.06,2.09,1.41,2.16,3.92,.84,5.51-1.33,1.6-4.43,2-6.05-.09,0,0-1.45-1.95,.18-4.58" />
		<path d="M229.86,30.56s-2.98,1.73-5.17-.5c-2.19-2.23-.81-4.8-.07-5.42,.74-.61,2.64-1.81,5.24-.12,0,0,2.54,2.09,.81,5.03,0,0,1.74-1.43,4.22-.11,2.48,1.33,2.67,3.85,1.18,5.39,0,0,2.73-1.95,5.15,.05,0,0,2.45,2.42-.3,4.83,0,0-3.08,2.25-5.53-.24,0,0-1.25-1.64,.11-3.79,0,0-2.15,1.65-5.09-.49,0,0-2.29-1.64-.55-4.62" />
		<path d="M219.79,9.25s-1.77,1.43-.94,3.6c0,0,.64,1.78,3.24,1.29,2.6-.49,2.72-2.74,2.39-3.84,0,0-.65-2-3.24-1.65,0,0-.98,.21-1.45,.6" />
		<path d="M224.7,14.27s-1.77,1.66-.44,3.76c1.41,2.23,3.69,1.25,4.16,1.12,.71-.21,2.6-2.11,1.2-4.45-1.35-2.28-4.2-1.15-4.92-.42" />
		<path d="M229.58,21.97s.3,2.86,3.29,2.91c2.99,.05,3.04-3.16,2.86-3.74-.18-.58-.41-2.18-2.86-2.46-2.46-.27-3.52,1.68-3.29,3.29" />
		<path d="M235.44,27.99s-.82-2.09,1.16-3.48c1.97-1.39,4.05-.1,4.42,.52,.37,.63,1.18,2.48-.18,4-1.37,1.52-4.5,1.23-5.39-1.05" />
		<path d="M241.62,30.3s-1.53,1.91-.13,3.77c1.39,1.86,4.47,.08,4.95-.97,.32-.71,.82-1.94-.34-3.23-1.18-1.31-3.4-.88-4.47,.43" />
		<path d="M245.91,27s-.08,2.31,1.82,2.58c1.89,.28,2.79-1.49,2.74-2.51-.05-1.02-.32-2.46-2.16-2.54-1.84-.08-2.34,1.52-2.39,2.46" />
		<path d="M242.17,24.46s2.24,.97,3.18-1.39c0,0,.92-2.8-1.32-3.53-2.24-.73-3.29,.97-3.42,1.65-.13,.68-.18,2.64,1.55,3.27" />
		<path d="M237.28,19.48s2.74,.19,2.82-2.47c.08-2.67-2.42-2.87-2.42-2.87,0,0-2.63-.36-2.84,2.27,0,0-.13,2.69,2.45,3.07" />
		<path d="M229.33,12.95s1.84,2.28,4.16,.38c0,0,2.29-2.74-.82-4.15,0,0-2.45-.84-3.45,1.18,0,0-.74,1.4,.11,2.59" />
		<path d="M224.04,8.3s1.37,1.48,3.61,.32c0,0,1.37-.73,1.08-2.36-.29-1.63-1.66-1.96-2.87-1.73-1.21,.24-3.09,1.67-1.82,3.77" />
		<path d="M228.25,3.27s0,1.39,2.08,1.28c2.08-.1,2.06-1.86,1.66-2.28-.4-.42-.56-.92-2.27-.65,0,0-1.42,.13-1.47,1.65" />
		<path d="M233.59,7.59s.34,2.04,2.55,1.6c0,0,2.16-.5,1.39-2.64,0,0-.55-1.41-2.45-.89,0,0-1.6,.53-1.5,1.94" />
		<path d="M239.44,12.64s.47,1.86,2.26,1.88c0,0,1.37-.03,1.58-1.35,.21-1.32-.79-2.08-.79-2.08,0,0-.97-.99-2.45-.18,0,0-.89,.65-.61,1.73" />
		<path d="M245.01,17.53s.13,1.44,1.39,2.07c0,0,.92,.37,1.79-.42,0,0,.89-.76,.26-2.33,0,0-.63-1.49-2.34-1.02,0,0-1.26,.34-1.1,1.7" />
		<path d="M251.21,21.01s-1.23,.16-1.11,1.87c0,0,.04,1.79,1.46,1.78,0,0,1.53,.14,1.53-2.16,0,0,.02-1.7-1.88-1.49" />
		<path d="M252.99,19.44s.18,1.4,.93,1.37c.75-.03,.61-1.46,.58-1.61-.03-.15-.17-1.11-.9-1.1,0,0-.74,.06-.61,1.34" />
		<path d="M248.46,13.58s-.35,1.21,.92,1.97c0,0,.69,.46,1.34-.17,0,0,.66-.86-.39-1.98,0,0-.39-.49-1.09-.45,0,0-.62,.01-.78,.64" />
		<path d="M243.16,9.42s.42,1.26,1.71,1.09c0,0,1.08-.05,.95-1.38,0,0-.11-.82-1.08-1.2-.97-.37-1.55,.58-1.58,.72-.03,.14-.09,.57,0,.78" />
		<path d="M237.66,4.77s.67,.95,1.88,.77c0,0,.99-.09,.75-1.28,0,0-.2-.76-1.11-1.03,0,0-1.19-.36-1.61,.48,0,0-.3,.51,.09,1.06" />
		<path d="M232.1,.63s-.06,.74,1.36,.77c0,0,1.27,.1,1.25-.65,0,0,.11-.8-1.45-.75,0,0-1.07-.08-1.17,.63" />
		<rect x="166.73" y="32.52" width="6.99" height="24.46" />
		<rect x="177.01" y="32.52" width="6.99" height="24.46" />
		<polygon points="14.9 42.02 9.25 42.02 9.25 32.52 0 32.52 0 56.98 9.25 56.98 9.25 44.85 14.9 44.85 14.9 56.98 24.16 56.98 24.16 32.52 14.9 32.52 14.9 42.02" />
		<path d="M58.33,41.61l-.57-1.62h-7.61v16.98h7.61v-10.21c0-2.22,1.44-3.44,2.67-3.44,1.03,0,2.36,.2,2.36,2.43v11.22h7.61v-11.02c0-6.17-7.04-8.59-12.08-4.35" />
		<path d="M136.46,49.85l-3.55-9.85h-5.19l-3.55,10.26-3.7-10.26h-10.18l-3.65,8.64-5.14-8.64h-8.69l10.23,17.18-1.34,3.23c-.46,1.01-1.57,1.06-1.79,.1,0-1.74-1.43-3.14-3.2-3.14s-3.2,1.41-3.2,3.14c.01,1.82,1.86,4.19,5.51,4.04,3.65-.15,5.19-3.13,5.71-4.4l8.45-20.1,6.09,16.91h5.55l3.39-9.75,3.49,9.75h5.24l5.91-16.98h-3.03l-3.39,9.85Z" />
		<path d="M39.88,53.08c0,1.42-1.17,2.58-2.62,2.58s-2.62-1.15-2.62-2.58v-8.89c0-1.42,1.17-2.58,2.62-2.58s2.62,1.15,2.62,2.58v8.89Zm-2.62-13.95c-5.82,0-10.54,4.26-10.54,9.5s4.72,9.51,10.54,9.51,10.54-4.25,10.54-9.51-4.72-9.5-10.54-9.5" />
		<path d="M80.59,44.19c0-1.42,1.17-2.58,2.62-2.58s2.62,1.15,2.62,2.58v3.39h-5.24v-3.39Zm13.08,5.81c.06-.4,.08-.95,.08-1.36,0-5.25-4.72-9.5-10.54-9.5s-10.54,4.25-10.54,9.5,4.72,9.51,10.54,9.51c4,0,7.48-2.01,9.26-4.97l-2.33-.85c-1.41,2.11-3.99,3.53-6.94,3.53-1.45,0-2.62-1.35-2.62-2.77v-3.08h13.08Z" />
		<path d="M151,44.19c0-1.42,1.17-2.58,2.62-2.58s2.62,1.15,2.62,2.58v3.39h-5.24v-3.39Zm13.07,5.81c.06-.4,.09-.95,.09-1.36,0-5.25-4.72-9.5-10.54-9.5s-10.54,4.25-10.54,9.5,4.72,9.51,10.54,9.51c4,0,7.48-2.01,9.27-4.97l-2.33-.85c-1.41,2.11-3.99,3.53-6.94,3.53-1.45,0-2.62-1.35-2.62-2.77v-3.08h13.07Z" />
	</svg>
);

export default SvgHoneywell;
