import deviceConstants from "./device.constants";
import deviceService from "./device.service"
import device from "../../models/classes/device"

function getAll() {
    function request(){
        return { type: deviceConstants.GET_ALL_REQUEST };
    }
    function success(devices) {
        return { type: deviceConstants.GET_ALL_SUCCESS, devices}
    }
    function failure(error){
        return {type: deviceConstants.GET_ALL_FAILURE, error}
    }
    return (dispatch) => {
        dispatch(request());
        deviceService
            .getAll()
            .then((devices) => {
                dispatch(success(devices));
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };
}

function saveAll(deviceList) {
    function request(){
        return { type: deviceConstants.SAVE_ALL_REQUEST };
    }
    function success(devices) {
        return { type: deviceConstants.SAVE_ALL_SUCCESS, devices}
    }
    function failure(error){
        return { type: deviceConstants.SAVE_ALL_FAILURE, error}
    }
    return (dispatch) => {
        dispatch(request());
        deviceService
            .saveAll(deviceList)
            .then((devices) => {
                dispatch(success(devices));
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };
}

function clear() {
    return {
        type: deviceConstants.CLEAR,
    };
}

const deviceAction = {
    getAll,
    saveAll,
    clear,
}

export default deviceAction;