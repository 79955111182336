import React from 'react';

const SvgModularis = (props: any) => (
	<svg viewBox="0 0 145 44.54" {...props}>
		<path fill={"#1d1d1b"} d="M48.46,40.02l-1.28-.85,.34-.43,1.27,.86-.33,.42Zm-.41,.76c-1.04,0-1.95,.9-1.95,1.92,0,.8,.55,1.34,1.36,1.34,.99,0,1.89-.94,1.89-1.97,0-.74-.55-1.29-1.3-1.29m.89,3.67l.13-.72c-.6,.61-1.03,.81-1.72,.81-1.1,0-1.83-.68-1.83-1.71,0-1.38,1.19-2.56,2.58-2.56,.66,0,1.14,.21,1.45,.82l.13-.72h.55l-.75,4.08h-.55Z"/>
		<polygon fill={"#1d1d1b"} points="53.04 44.44 52.49 44.44 53.51 38.93 54.06 38.93 53.04 44.44"/>
		<polygon fill={"#1d1d1b"} points="55.49 40.37 54.94 40.37 55.86 38.93 56.42 38.93 55.49 40.37"/>
		<g>
			<path fill={"#1d1d1b"} d="M59.05,40.01l-.18-.42,1.59-.86,.18,.43-1.59,.85Zm.17,.76c-.77,0-1.51,.54-1.78,1.31h3.12c-.04-.8-.55-1.31-1.34-1.31m-1.89,1.81c0,.09-.02,.16-.02,.21,0,.69,.59,1.24,1.33,1.24,.61,0,1.27-.39,1.59-.93h.57c-.48,.88-1.36,1.44-2.27,1.44-1.01,0-1.77-.74-1.77-1.74,0-1.34,1.19-2.53,2.53-2.53,1.04,0,1.77,.7,1.77,1.7,0,.18-.01,.32-.07,.62h-3.69Z"/>
			<path fill={"#1d1d1b"} d="M63.49,44.54c-1.11,0-1.87-.7-1.87-1.75,0-1.33,1.24-2.52,2.61-2.52,.93,0,1.62,.51,1.8,1.33h-.62c-.2-.52-.66-.83-1.27-.83-1.03,0-1.94,.91-1.94,1.93,0,.77,.57,1.33,1.36,1.33,.6,0,.92-.16,1.55-.79h.62c-.72,.98-1.42,1.3-2.24,1.3"/>
			<path fill={"#1d1d1b"} d="M68.93,40.78c-1.04,0-1.92,.9-1.92,1.97,0,.77,.54,1.29,1.33,1.29,1.04,0,1.86-.89,1.86-2.01,0-.72-.54-1.24-1.27-1.24m-.66,3.76c-1.08,0-1.83-.69-1.83-1.7,0-1.42,1.24-2.57,2.59-2.57,1.04,0,1.74,.69,1.74,1.7,0,1.41-1.21,2.57-2.5,2.57"/>
			<path fill={"#1d1d1b"} d="M74.72,44.44h-.55l.12-.63c-.39,.52-.81,.73-1.46,.73-.86,0-1.42-.49-1.42-1.25,0-.21,.03-.51,.07-.72l.41-2.2h.55l-.41,2.2c-.02,.1-.05,.39-.05,.5,0,.6,.33,.97,1,.97,.71,0,1.34-.42,1.56-1.57l.39-2.09h.55l-.76,4.08Z"/>
		</g>
		<polygon fill={"#1d1d1b"} points="77.34 40.86 76.68 44.44 76.12 44.44 76.79 40.86 75.95 40.86 76.04 40.37 76.88 40.37 77.14 38.94 77.7 38.94 77.43 40.37 78.39 40.37 78.3 40.86 77.34 40.86"/>
		<g>
			<path fill={"#1d1d1b"} d="M80.59,40.78c-.77,0-1.51,.54-1.78,1.31h3.12c-.04-.8-.55-1.31-1.34-1.31m-1.89,1.81c0,.09-.02,.16-.02,.21,0,.69,.59,1.24,1.33,1.24,.61,0,1.27-.39,1.59-.93h.57c-.48,.88-1.36,1.44-2.26,1.44-1.01,0-1.77-.74-1.77-1.74,0-1.34,1.19-2.53,2.53-2.53,1.04,0,1.77,.7,1.77,1.7,0,.18-.02,.32-.07,.62h-3.69Z"/>
			<path fill={"#1d1d1b"} d="M87.55,40.78c-1,0-1.91,.91-1.91,1.91,0,.8,.56,1.35,1.38,1.35,.98,0,1.91-.95,1.91-1.96,0-.74-.59-1.3-1.37-1.3m.94,3.67l.13-.74c-.53,.6-1.02,.83-1.72,.83-1.07,0-1.84-.72-1.84-1.71,0-1.36,1.21-2.56,2.61-2.56,.64,0,1.11,.24,1.45,.74l.39-2.08h.55l-1.02,5.51h-.55Z"/>
			<path fill={"#1d1d1b"} d="M92.61,40.78c-.77,0-1.51,.54-1.78,1.31h3.12c-.04-.8-.55-1.31-1.34-1.31m-1.89,1.81c0,.09-.02,.16-.02,.21,0,.69,.59,1.24,1.33,1.24,.61,0,1.27-.39,1.59-.93h.57c-.48,.88-1.36,1.44-2.27,1.44-1.01,0-1.77-.74-1.77-1.74,0-1.34,1.19-2.53,2.53-2.53,1.04,0,1.77,.7,1.77,1.7,0,.18-.01,.32-.07,.62h-3.69Z"/>
			<path fill={"#1d1d1b"} d="M95.77,44.54c-.67,0-1.09-.45-1.09-.97,0-.08,0-.15,.02-.33h.54c0,.65,.23,.79,.62,.79,.46,0,.84-.39,.84-.83,0-.31-.1-.42-.66-.66-.45-.19-.57-.26-.68-.39-.11-.13-.19-.35-.19-.54,0-.72,.68-1.34,1.4-1.34,.54,0,.95,.37,.95,.88,0,.06,0,.14,0,.22h-.56c0-.41-.15-.6-.48-.6-.37,0-.72,.33-.72,.69,0,.19,.1,.34,.28,.44,.1,.05,.22,.11,.71,.33,.38,.17,.54,.42,.54,.8,0,.84-.72,1.5-1.51,1.5"/>
			<path fill={"#1d1d1b"} d="M101.84,44.54c-1.11,0-1.87-.7-1.87-1.75,0-1.33,1.24-2.52,2.61-2.52,.93,0,1.62,.51,1.8,1.33h-.62c-.2-.52-.66-.83-1.27-.83-1.03,0-1.94,.91-1.94,1.93,0,.77,.57,1.33,1.36,1.33,.6,0,.92-.16,1.55-.79h.62c-.72,.98-1.42,1.3-2.24,1.3"/>
			<path fill={"#1d1d1b"} d="M107.28,40.78c-1.04,0-1.92,.9-1.92,1.97,0,.77,.54,1.29,1.33,1.29,1.04,0,1.86-.89,1.86-2.01,0-.72-.54-1.24-1.27-1.24m-.66,3.76c-1.08,0-1.83-.69-1.83-1.7,0-1.42,1.24-2.57,2.59-2.57,1.04,0,1.74,.69,1.74,1.7,0,1.41-1.21,2.57-2.5,2.57"/>
		</g>
		<polygon fill={"#1d1d1b"} points="110.03 44.44 109.48 44.44 110.5 38.93 111.05 38.93 110.03 44.44"/>
		<polygon fill={"#1d1d1b"} points="111.52 44.44 110.97 44.44 111.99 38.93 112.54 38.93 111.52 44.44"/>
		<g>
			<path fill={"#1d1d1b"} d="M115.09,40.78c-.77,0-1.51,.54-1.78,1.31h3.12c-.04-.8-.55-1.31-1.34-1.31m-1.89,1.81c0,.09-.02,.16-.02,.21,0,.69,.59,1.24,1.33,1.24,.61,0,1.27-.39,1.59-.93h.57c-.48,.88-1.36,1.44-2.27,1.44-1.01,0-1.77-.74-1.77-1.74,0-1.34,1.19-2.53,2.53-2.53,1.04,0,1.77,.7,1.77,1.7,0,.18-.01,.32-.07,.62h-3.69Z"/>
			<path fill={"#1d1d1b"} d="M119.36,44.54c-1.11,0-1.87-.7-1.87-1.75,0-1.33,1.24-2.52,2.61-2.52,.93,0,1.62,.51,1.8,1.33h-.62c-.2-.52-.66-.83-1.27-.83-1.03,0-1.94,.91-1.94,1.93,0,.77,.57,1.33,1.36,1.33,.6,0,.92-.16,1.55-.79h.62c-.72,.98-1.42,1.3-2.24,1.3"/>
		</g>
		<polygon fill={"#1d1d1b"} points="123.8 40.86 123.13 44.44 122.58 44.44 123.24 40.86 122.41 40.86 122.5 40.37 123.33 40.37 123.6 38.94 124.15 38.94 123.89 40.37 124.85 40.37 124.76 40.86 123.8 40.86"/>
		<path fill={"#1d1d1b"} d="M125.5,39.92l.17-.94h.55l-.17,.94h-.55Zm-.84,4.52l.75-4.08h.55l-.75,4.08h-.55Z"/>
		<polygon fill={"#1d1d1b"} points="128.03 44.44 127.45 44.44 126.49 40.37 127.04 40.37 127.84 43.79 129.94 40.37 130.49 40.37 128.03 44.44"/>
		<path fill={"#1d1d1b"} d="M131.11,39.92l.17-.94h.55l-.17,.94h-.55Zm-.84,4.52l.75-4.08h.55l-.75,4.08h-.55Z"/>
		<polygon fill={"#1d1d1b"} points="133.43 40.86 132.77 44.44 132.22 44.44 132.88 40.86 132.04 40.86 132.13 40.37 132.97 40.37 133.24 38.94 133.79 38.94 133.52 40.37 134.48 40.37 134.39 40.86 133.43 40.86"/>
		<g>
			<path fill={"#1d1d1b"} d="M136.52,40.01l-.18-.42,1.59-.86,.18,.43-1.59,.85Zm.17,.76c-.77,0-1.51,.54-1.78,1.31h3.12c-.04-.8-.55-1.31-1.34-1.31m-1.89,1.81c0,.09-.02,.16-.02,.21,0,.69,.59,1.24,1.33,1.24,.61,0,1.27-.39,1.59-.93h.57c-.48,.88-1.36,1.44-2.26,1.44-1.01,0-1.77-.74-1.77-1.74,0-1.34,1.19-2.53,2.53-2.53,1.04,0,1.77,.7,1.77,1.7,0,.18-.01,.32-.07,.62h-3.69Z"/>
			<path fill={"#1d1d1b"} d="M139.85,44.54c-.67,0-1.09-.45-1.09-.97,0-.08,0-.15,.02-.33h.54c0,.65,.23,.79,.62,.79,.46,0,.84-.39,.84-.83,0-.31-.1-.42-.66-.66-.45-.19-.57-.26-.68-.39-.11-.13-.19-.35-.19-.54,0-.72,.68-1.34,1.4-1.34,.54,0,.95,.37,.95,.88,0,.06,0,.14,0,.22h-.56c0-.41-.15-.6-.48-.6-.37,0-.72,.33-.72,.69,0,.19,.1,.34,.28,.44,.1,.05,.22,.11,.71,.33,.38,.17,.54,.42,.54,.8,0,.84-.72,1.5-1.51,1.5"/>
		</g>
		<polyline fill={"#1d1d1b"} points="37.2 17.51 40.68 26.81 42.8 26.81 46.25 17.51 46.31 17.51 46.31 36.27 48.81 36.27 48.81 11.26 45.34 11.26 41.79 20.73 41.74 20.72 38.14 11.26 37.18 11.26"/>
		<g>
			<path fill={"#1d1d1b"} d="M59.16,33.98c-2.86,0-5.07-2.42-5.07-5.2s2.25-5.24,5.07-5.24,5.07,2.35,5.07,5.24-2.21,5.2-5.07,5.2m0-13.03c-4.26,0-7.85,3.41-7.85,7.86s3.53,7.77,7.85,7.77,7.85-3.47,7.85-7.77-3.59-7.86-7.85-7.86"/>
			<path fill={"#1d1d1b"} d="M75.1,32.94c-1.18,.63-2.15,.73-3.65,.73h-.59v-9.82h.53c1.74,0,3.21,.2,4.36,1.26,.97,.85,1.6,2.23,1.6,3.69,0,1.6-.77,3.33-2.25,4.14m2.56-9.95c-.87-.75-2.48-1.75-6.17-1.75h-3.41v15.02h3.47c1.68,0,3.61-.2,4.99-.97,2.19-1.2,3.59-3.8,3.59-6.49,0-2.35-1.05-4.59-2.48-5.8"/>
			<path fill={"#1d1d1b"} d="M88.72,30.79c0,.75,0,3.19-2.6,3.19s-2.6-2.43-2.6-3.19v-9.54h-2.78v9.54c0,1.85,.45,2.98,.93,3.63,.65,.87,2.17,2.15,4.47,2.15,2.05,0,3.61-1.02,4.49-2.23,.71-.99,.87-2.33,.87-3.55v-9.54h-2.78v9.54Z"/>
		</g>
		<polygon fill={"#1d1d1b"} points="95.96 21.25 93.18 21.25 93.18 36.27 100.55 36.27 100.55 33.67 95.96 33.67 95.96 21.25"/>
		<path fill={"#1d1d1b"} d="M106.64,29.78l1.71-4.61h.04l1.71,4.61h-3.45Zm.65-8.52l-6.25,15.02h3.06l1.6-3.88h5.38l1.6,3.88h3.06l-6.29-15.02h-2.17Z"/>
		<path fill={"#1d1d1b"} d="M119.99,28.44v-4.59h1.79c2.17,0,2.68,1.44,2.68,2.27s-.39,2.31-2.68,2.31h-1.79Zm5.8,1.06c1.08-1.04,1.44-2.6,1.44-3.59,0-1.73-.91-2.94-1.77-3.63-.85-.69-1.93-1.02-3.65-1.02h-4.61v15.02h2.78v-5.74h.04l3.98,5.74h3.37l-4.08-5.54c.75-.18,1.6-.39,2.5-1.24"/>
		<rect fill={"#1d1d1b"} x="130.04" y="21.22" width="2.78" height="15.05"/>
		<rect fill={"#1d1d1b"} x="130.04" y="13.61" width="2.78" height="3.73"/>
		<path fill={"#1d1d1b"} d="M141.06,27.16c-1.52-.51-2.5-.79-2.5-2.09,0-.89,.67-1.52,1.54-1.52,.75,0,1.56,.35,1.68,1.68h2.84c-.14-3.06-2.7-4.28-4.44-4.28-2.54,0-4.4,1.89-4.4,4.28,0,3.17,2.41,3.88,3.57,4.32,1.87,.71,2.86,.99,2.86,2.54,0,1.14-.97,1.89-2.07,1.89-.85,0-1.85-.51-1.99-2.01h-2.92c.16,3.31,2.96,4.61,4.89,4.61,2.35,0,4.87-1.54,4.87-4.79s-2.7-4.22-3.94-4.63"/>
		<polygon fill={"#1d1d1b"} points="0 27.54 0 41.87 .16 41.91 .16 27.42 0 27.54"/>
		<polygon fill={"#1d1d1b"} points="0 21.02 0 25.86 .16 25.75 .16 20.9 0 21.02"/>
		<polygon fill={"#1d1d1b"} points="0 10.12 0 19.31 .16 19.19 .16 9.96 0 10.12"/>
		<polygon fill={"#1d1d1b"} points=".16 27.42 .16 41.92 1.78 42.34 1.78 26.29 .16 27.42"/>
		<polygon fill={"#1d1d1b"} points="1.78 17.99 1.78 8.31 .16 9.96 .16 19.19 1.78 17.99"/>
		<polygon fill={"#1d1d1b"} points="1.78 19.7 .16 20.9 .16 25.75 1.78 24.62 1.78 19.7"/>
		<polygon fill={"#1d1d1b"} points="2.83 25.56 2.83 42.61 4.46 43.03 4.46 24.43 2.83 25.56"/>
		<polygon fill={"#1d1d1b"} points="4.45 16.01 4.45 5.6 2.83 7.24 2.83 17.21 4.45 16.01"/>
		<polygon fill={"#1d1d1b"} points="2.83 18.92 2.83 23.88 4.46 22.75 4.46 17.72 2.83 18.92"/>
		<polygon fill={"#1d1d1b"} points="5.67 23.58 5.67 43.35 7.29 43.77 7.29 22.45 5.67 23.58"/>
		<polygon fill={"#1d1d1b"} points="5.67 16.82 5.67 21.9 7.29 20.77 7.29 15.62 5.67 16.82"/>
		<polygon fill={"#1d1d1b"} points="7.29 13.91 7.29 2.72 5.67 4.36 5.67 15.11 7.29 13.91"/>
		<polygon fill={"#1d1d1b"} points="9.96 20.59 8.34 21.72 8.34 44.04 9.96 44.47 9.96 20.59"/>
		<polygon fill={"#1d1d1b"} points="8.34 14.84 8.34 20.04 9.85 18.99 9.96 19.01 9.96 13.64 8.34 14.84"/>
		<polygon fill={"#1d1d1b"} points="9.84 12.02 9.96 12.05 9.96 0 8.34 1.65 8.34 13.13 9.84 12.02"/>
		<polygon fill={"#1d1d1b"} points="9.96 12.05 13.85 13 13.85 1.64 9.96 0 9.96 12.05"/>
		<polygon fill={"#1d1d1b"} points="10.15 20.46 9.96 20.59 9.96 44.47 13.85 43.27 13.85 21.23 10.15 20.46"/>
		<polygon fill={"#1d1d1b"} points="10.14 13.51 9.96 13.64 9.96 19.01 13.85 19.83 13.85 14.42 10.14 13.51"/>
		<polygon fill={"#1d1d1b"} points="16.12 21.71 16.12 42.58 20.01 41.39 20.01 22.52 16.12 21.71"/>
		<polygon fill={"#1d1d1b"} points="20.01 14.51 20.01 4.23 16.12 2.59 16.12 13.56 20.01 14.51"/>
		<polygon fill={"#1d1d1b"} points="16.12 14.97 16.12 20.3 20.01 21.12 20.01 15.92 16.12 14.97"/>
		<polygon fill={"#1d1d1b"} points="25.67 15.89 25.67 6.62 21.79 4.98 21.79 14.94 25.67 15.89"/>
		<polygon fill={"#1d1d1b"} points="21.79 22.9 21.79 40.84 25.68 39.65 25.68 23.71 21.79 22.9"/>
		<polygon fill={"#1d1d1b"} points="21.79 16.36 21.79 21.49 25.68 22.31 25.68 17.31 21.79 16.36"/>
		<polygon fill={"#1d1d1b"} points="27.38 22.67 31.26 23.48 31.26 18.68 27.38 17.72 27.38 22.67"/>
		<polygon fill={"#1d1d1b"} points="31.26 17.26 31.26 8.97 27.38 7.33 27.38 16.31 31.26 17.26"/>
		<polygon fill={"#1d1d1b"} points="27.38 24.07 27.38 39.13 31.26 37.93 31.26 24.89 27.38 24.07"/>
		<polygon fill={"#1d1d1b"} points="36.69 18.58 36.69 11.26 32.8 9.62 32.8 17.63 36.69 18.58"/>
		<polygon fill={"#1d1d1b"} points="32.8 37.46 36.69 36.27 36.69 26.03 32.8 25.21 32.8 37.46"/>
		<polygon fill={"#1d1d1b"} points="32.8 23.8 36.69 24.62 36.69 20 32.8 19.05 32.8 23.8"/>
	</svg>
);

export default SvgModularis;
