import React, {Component} from 'react';
import { NavbarMenu } from '../sidebars/index';

export default function BlankLayout(props) {
    const { children } = props;
    return (
            <div style={{height:"100%"}}>
                <div className="wrapper" style={{height:"100%"}}>
                    {children}
                </div>
            </div>
    );
}