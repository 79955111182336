import React from 'react';

const SvgQundis = (props: any) => (
<svg viewBox="0 0 142.58 28.48" {...props}>
	<path d="M38.02,26.38l-.6-1.65c-.12-.34-.18-.59-.26-.87h-.01c-.07,.29-.15,.54-.26,.86l-.61,1.66h1.74Zm-1.85,.29l-.61,1.73h-.36l1.79-4.94h.34l1.78,4.94h-.36l-.62-1.73h-1.96Z"/>
	<path d="M40.22,28.11c.2,.03,.49,.04,.8,.04,1.58,0,2.37-.89,2.37-2.32,0-1.25-.69-2.11-2.27-2.11-.38,0-.68,.04-.89,.07v4.32Zm-.34-4.58c.37-.06,.8-.1,1.26-.1,.9,0,1.58,.24,1.99,.67,.4,.41,.62,.96,.62,1.71s-.2,1.38-.65,1.88c-.44,.48-1.18,.75-2.13,.75-.38,0-.74,0-1.09-.04v-4.87Z"/>
	<path d="M45.72,28.4l-1.64-4.94h.37l.87,2.65c.24,.72,.44,1.34,.58,1.89h.02c.15-.56,.37-1.19,.63-1.89l.96-2.65h.37l-1.83,4.94h-.33Z"/>
	<path d="M50.4,26.38l-.6-1.65c-.12-.34-.18-.59-.26-.87h-.01c-.07,.29-.15,.54-.26,.86l-.61,1.66h1.74Zm-1.85,.29l-.61,1.73h-.36l1.79-4.94h.34l1.78,4.94h-.36l-.62-1.73h-1.96Z"/>
	<path d="M52.25,28.4v-4.94h.33l1.93,2.92c.4,.62,.68,1.08,.92,1.55h.01c-.05-.67-.06-1.24-.06-2.01v-2.46h.34v4.94h-.33l-1.88-2.87c-.37-.57-.7-1.09-.96-1.6h-.02c.04,.61,.04,1.15,.04,1.97v2.5h-.34Z"/>
	<path d="M60.27,28.22c-.25,.13-.73,.25-1.33,.25-1.2,0-2.34-.76-2.34-2.51,0-1.47,.95-2.58,2.5-2.58,.63,0,1,.14,1.15,.22l-.12,.29c-.25-.12-.61-.21-1.03-.21-1.33,0-2.15,.87-2.15,2.29,0,1.34,.77,2.21,2.09,2.21,.43,0,.84-.09,1.11-.23l.1,.28Z"/>
	<polygon points="63.43 25.94 61.45 25.94 61.45 28.11 63.66 28.11 63.66 28.4 61.1 28.4 61.1 23.46 63.54 23.46 63.54 23.75 61.45 23.75 61.45 25.65 63.43 25.65 63.43 25.94"/>
	<path d="M64.86,28.11c.2,.03,.49,.04,.8,.04,1.58,0,2.37-.89,2.37-2.32,0-1.25-.69-2.11-2.27-2.11-.38,0-.68,.04-.89,.07v4.32Zm-.34-4.58c.37-.06,.8-.1,1.26-.1,.9,0,1.58,.24,1.99,.67,.4,.41,.62,.96,.62,1.71s-.2,1.38-.65,1.88c-.44,.48-1.18,.75-2.13,.75-.38,0-.74,0-1.09-.04v-4.87Z"/>
	<path d="M75.03,26.05c-.05-.74-.11-1.65-.1-2.18h-.02c-.18,.54-.38,1.11-.66,1.84l-1.04,2.69h-.24l-.97-2.62c-.28-.77-.49-1.36-.64-1.91h-.02c0,.58-.04,1.44-.1,2.26l-.15,2.27h-.34l.36-4.94h.4l1.06,2.84c.23,.63,.4,1.13,.54,1.6h.02c.13-.45,.31-.94,.56-1.6l1.1-2.85h.42l.34,4.94h-.34l-.15-2.34Z"/>
	<polygon points="78.89 25.94 76.91 25.94 76.91 28.11 79.12 28.11 79.12 28.4 76.56 28.4 76.56 23.46 79 23.46 79 23.75 76.91 23.75 76.91 25.65 78.89 25.65 78.89 25.94"/>
	<path d="M82.41,26.38l-.6-1.65c-.12-.34-.18-.59-.26-.87h-.01c-.07,.29-.15,.54-.26,.86l-.61,1.66h1.74Zm-1.85,.29l-.61,1.73h-.36l1.79-4.94h.34l1.78,4.94h-.36l-.62-1.73h-1.96Z"/>
	<path d="M84.1,27.85c.28,.19,.67,.33,1.08,.33,.73,0,1.19-.42,1.19-1.03,0-.56-.29-.88-1-1.16-.8-.28-1.28-.69-1.28-1.36,0-.73,.6-1.25,1.44-1.25,.46,0,.81,.12,.98,.23l-.13,.29c-.13-.09-.45-.23-.87-.23-.8,0-1.07,.51-1.07,.9,0,.55,.31,.83,1.03,1.11,.81,.33,1.25,.7,1.25,1.44s-.51,1.36-1.57,1.36c-.43,0-.92-.14-1.18-.33l.13-.29Z"/>
	<path d="M88.01,23.46v2.98c0,1.25,.59,1.74,1.32,1.74,.81,0,1.38-.53,1.38-1.74v-2.98h.35v2.95c0,1.52-.82,2.07-1.75,2.07-.86,0-1.64-.51-1.64-2.01v-3h.34Z"/>
	<path d="M92.6,25.85h.78c.76,0,1.26-.43,1.26-1.08,0-.75-.56-1.06-1.33-1.06-.34,0-.59,.04-.71,.07v2.07Zm-.34-2.32c.3-.07,.73-.1,1.06-.1,.65,0,1.05,.14,1.33,.41,.21,.2,.34,.53,.34,.88,0,.67-.4,1.09-.97,1.29v.02c.4,.12,.65,.5,.76,1.05,.17,.77,.26,1.14,.37,1.33h-.37c-.07-.15-.18-.58-.31-1.2-.15-.71-.45-1.03-1.11-1.06h-.77v2.26h-.34v-4.87Z"/>
	<rect x="95.95" y="23.46" width=".34" height="4.94"/>
	<path d="M97.5,28.4v-4.94h.33l1.93,2.92c.4,.62,.68,1.08,.92,1.55h.01c-.05-.67-.06-1.24-.06-2.01v-2.46h.34v4.94h-.33l-1.88-2.87c-.37-.57-.7-1.09-.96-1.6h-.02c.04,.61,.04,1.15,.04,1.97v2.5h-.34Z"/>
	<path d="M105.71,28.18c-.25,.1-.76,.27-1.44,.27s-1.25-.17-1.75-.65c-.4-.4-.67-1.06-.67-1.85,0-1.37,.89-2.54,2.57-2.54,.53,0,.96,.13,1.15,.22l-.11,.29c-.26-.12-.59-.21-1.06-.21-1.36,0-2.2,.88-2.2,2.22s.82,2.22,2.13,2.22c.51,0,.85-.07,1.03-.17v-1.74h-1.15v-.29h1.5v2.23Z"/>
	<path d="M108.19,27.85c.28,.19,.67,.33,1.08,.33,.73,0,1.19-.42,1.19-1.03,0-.56-.29-.88-1-1.16-.8-.28-1.28-.69-1.28-1.36,0-.73,.6-1.25,1.44-1.25,.46,0,.81,.12,.98,.23l-.13,.29c-.13-.09-.45-.23-.87-.23-.8,0-1.07,.51-1.07,.9,0,.55,.31,.83,1.03,1.11,.81,.33,1.25,.7,1.25,1.44s-.51,1.36-1.57,1.36c-.43,0-.92-.14-1.18-.33l.13-.29Z"/>
	<path d="M111.79,25.97c0,1.11,.65,2.22,1.82,2.22s1.83-1.06,1.83-2.29c0-1.04-.57-2.23-1.81-2.23s-1.83,1.14-1.83,2.29m4-.09c0,1.74-1.06,2.6-2.21,2.6-1.22,0-2.15-.96-2.15-2.5,0-1.62,.97-2.6,2.21-2.6s2.15,.97,2.15,2.5"/>
	<polygon points="116.67 23.46 117.01 23.46 117.01 28.11 119.22 28.11 119.22 28.4 116.67 28.4 116.67 23.46"/>
	<path d="M120,23.46v2.98c0,1.25,.59,1.74,1.32,1.74,.81,0,1.38-.53,1.38-1.74v-2.98h.35v2.95c0,1.52-.82,2.07-1.75,2.07-.86,0-1.64-.51-1.64-2.01v-3h.34Z"/>
	<polygon points="125.17 23.75 123.57 23.75 123.57 23.46 127.14 23.46 127.14 23.75 125.53 23.75 125.53 28.4 125.17 28.4 125.17 23.75"/>
	<rect x="127.65" y="23.46" width=".34" height="4.94"/>
	<path d="M129.23,25.97c0,1.11,.65,2.22,1.82,2.22s1.83-1.06,1.83-2.29c0-1.04-.57-2.23-1.81-2.23s-1.83,1.14-1.83,2.29m4-.09c0,1.74-1.06,2.6-2.21,2.6-1.22,0-2.15-.96-2.15-2.5,0-1.62,.97-2.6,2.21-2.6s2.15,.97,2.15,2.5"/>
	<path d="M134.11,28.4v-4.94h.33l1.93,2.92c.4,.62,.68,1.08,.92,1.55h.01c-.05-.67-.06-1.24-.06-2.01v-2.46h.34v4.94h-.33l-1.88-2.87c-.37-.57-.7-1.09-.96-1.6h-.02c.04,.61,.04,1.15,.04,1.97v2.5h-.34Z"/>
	<path d="M138.63,27.85c.28,.19,.67,.33,1.08,.33,.73,0,1.19-.42,1.19-1.03,0-.56-.29-.88-1-1.16-.8-.28-1.28-.69-1.28-1.36,0-.73,.6-1.25,1.44-1.25,.46,0,.81,.12,.98,.23l-.13,.29c-.13-.09-.45-.23-.87-.23-.8,0-1.07,.51-1.07,.9,0,.55,.31,.83,1.03,1.11,.81,.33,1.25,.7,1.25,1.44s-.51,1.36-1.57,1.36c-.43,0-.92-.14-1.18-.33l.13-.29Z"/>
	<path d="M141.98,28.15c0-.19,.12-.33,.3-.33s.29,.14,.29,.33c0,.18-.12,.33-.3,.33s-.3-.15-.29-.33"/>
	<path d="M24.84,8.54c.06,.41,.09,.82,.09,1.24v1.64c0,2.67-1.19,5.06-3.07,6.68l8.71,10.31h1.88l-7.61-19.86Z"/>
	<path d="M42.45,.37V12.76c-.36,1.97-2,1.97-2,1.97-3.38,0-6.33-14.37-6.33-14.37h-7.13c4.82,20.29,13.02,18.87,13.02,18.87h2.44c3.88,0,7.02-3.14,7.02-7.02V.37h-7.02Z"/>
	<path d="M13.8,12.2c0,1.7-1.38,3.07-3.07,3.07s-3.07-1.38-3.07-3.07V6.95c0-1.7,1.38-3.07,3.07-3.07s3.07,1.38,3.07,3.07v5.25Zm3.18,5.82c2.6-1.53,4.34-4.35,4.34-7.58v-1.64C21.32,3.94,17.38,0,12.52,0h-3.72C3.94,0,0,3.94,0,8.8v1.64c0,4.86,3.94,8.8,8.8,8.8h2.51l2.89,4.56h8.09l-5.31-5.77Z"/>
	<polygon points="68.21 .37 68.21 13.36 64.55 .37 59.6 .37 58.31 .37 53.35 .37 53.35 19.26 59.6 19.26 59.6 4.96 63.62 19.26 68.21 19.26 69.87 19.26 74.15 19.26 74.15 .37 68.21 .37"/>
	<path d="M90.5,10.87c0,2.1-1.7,3.81-3.81,3.81h-1.77V4.5h1.77c2.1,0,3.81,1.7,3.81,3.8v2.56Zm-1.39-10.5h-10.73V19.23h5.45v.02h5.28c4.95,0,8.96-4.01,8.96-8.96v-.96c0-4.95-4.01-8.96-8.96-8.96"/>
	<rect x="101.07" y=".37" width="7.34" height="4.68"/>
	<rect x="101.07" y="7.24" width="7.34" height="12"/>
	<path d="M127.58,1.32v3.73c-6.3-1.33-8.64-.78-8.64,.54s3.83,1.98,3.83,1.98c6.21,2.25,5.91,4.89,5.91,5.66s-.4,4.83-5.99,6c-5.6,1.17-10.89-1.04-10.89-1.04v-4.41c7.58,2.25,10.3,1.71,9.87-.13-.33-1.43-4.52-2.07-4.52-2.07-3.5-.73-5.35-2.4-5.35-5.35,0-3.24,3.51-5.92,6.74-5.92,0,0,5.07-.36,9.04,1.01"/>
</svg>
);

export default SvgQundis;
