import React from 'react';

const SvgPhoneDessin = (props: any) => (
    <svg viewBox="0 0 26.02 48.1" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="txt">
			<path fill={props.fill} d="M25.79,5.19c-.54-2.7-1.85-4.95-4.9-5.12h0A27.38,27.38,0,0,0,16,.24c-2.6.1-5.19,0-7.79,0a10.45,10.45,0,0,0-5.14,1A5.11,5.11,0,0,0,.92,4.12a.08.08,0,0,0-.1,0C-.32,7,.14,10.33.05,13.33c-.12,4.08,0,8.14.16,12.23s.26,8.2.56,12.29c.13,1.81.4,3.65.36,5.46,0,1.18-.09,2.5.88,3.34a.29.29,0,0,0,.41-.07,3.59,3.59,0,0,0,1.42.88c5.64.15,12.57,1.52,18-.28a22.31,22.31,0,0,1-2.32,0c-2,0-4,0-6,.06-1.12,0-2.25,0-3.37.08s-2.39,0-3.58,0a.41.41,0,0,1-.35.11l-.53-.07-.45,0a6,6,0,0,1-1-.25,2.77,2.77,0,0,0,.3,0,3.4,3.4,0,0,1-1.77-.9.56.56,0,0,1,0,.08,4.13,4.13,0,0,1-.58-.6.13.13,0,0,0-.13-.05,4.11,4.11,0,0,1-.32-1.67c-.13-.74-.16-1.52-.25-2.32A130.74,130.74,0,0,1,.91,27.89L.82,25.52C.67,21.74.38,18,.47,14.19c.07-2.74-.31-5.83.21-8.6A10.07,10.07,0,0,0,1.5,3.83,4.54,4.54,0,0,1,3.84,1.39,11.64,11.64,0,0,1,9,.73l2.3,0C9.45.86,7.58,1,5.76,1.24,3.61,1.55,1,2.92,1.35,5.49a.07.07,0,0,0,.14,0c.41-1.36.69-2.38,2-3.17a8.24,8.24,0,0,1,3.68-.94c1.62-.14,3.25-.24,4.87-.32,1.79-.09,3.62,0,5.39-.25.08,0,.1-.14,0-.15a15,15,0,0,1,4.33.16c3.2,1.53,3.16,5.65,3.22,8.73.08,4.61.11,9.21.16,13.82,0,.37,0,.75,0,1.12,0,1.82,0,3.63,0,5.45,0,.88,0,1.75,0,2.63,0,.4,0,.8,0,1.2l0,.08a5.41,5.41,0,0,0,0,1.84c0,.63,0,1.26.06,1.9.13,1.56.29,3.11.29,4.67,0,.41,0,.88,0,1.37a12.72,12.72,0,0,1-.55,1.61,3.09,3.09,0,0,1-.55.82,4,4,0,0,0,.38-.24A12.42,12.42,0,0,0,26,39c-.13-5.36-.57-10.67-.52-16,0-4.33.44-8.63.55-13A26.58,26.58,0,0,0,25.79,5.19Z"/>
			<path fill={props.fill} d="M23.46,26.49a.09.09,0,0,0,0,0c0,.69-.07,1.37-.11,2l0,.1c0-.31,0-.63-.06-.95-.12-2.16,0-4.36,0-6.51s.14-4.35.13-6.53c0-2-.36-4-.17-6,0,0,0,0,0,0a.25.25,0,0,0-.18-.45c-3.4.25-6.77.49-10.19.54s-7.19-.23-10.73.09A.21.21,0,0,0,2,9.07a29.88,29.88,0,0,0-.48,7.38c0,2.69.16,5.37.32,8.05.33,5.45.68,10.89.78,16.35a.31.31,0,0,0,.39.3.28.28,0,0,0,.27.34c3.31-.07,6.61,0,9.92.07a22.23,22.23,0,0,0,3.26-.22c2.34.23,5,.44,7.05-.62a.15.15,0,0,0,.09-.1.29.29,0,0,0,.07-.19C23.6,35.77,23.45,31.14,23.46,26.49ZM18.07,41.13a29.62,29.62,0,0,1,4.2-.35A11.89,11.89,0,0,1,18.07,41.13Zm-4.55-.66-1.89.1c-2.77-.17-5.56-.25-8.31-.21-.1-5.16-.43-10.3-.81-15.45-.2-2.68-.36-5.36-.42-8,0-1.32,0-2.65,0-4s.21-2.51.17-3.76c3.37.21,6.8,0,10.17,0a79.25,79.25,0,0,0,10.21-.5.38.38,0,0,0,0,.15,1.74,1.74,0,0,1,.22-.05,26.28,26.28,0,0,0-.07,4.07c-.08,2.14-.14,4.29-.19,6.43a90,90,0,0,0,.13,12.4.17.17,0,0,0,.21.14c0,2.74.11,5.48.17,8.23C19.93,40.08,16.72,40.28,13.52,40.47Z"/>
			<path fill={props.fill} d="M20.32,1.09s0,0,0,0a3.62,3.62,0,0,1,3.33,2.62c0,.06.1,0,.1,0C23.79,2,21.81,1,20.32,1.09Z"/>
			<path fill={props.fill} d="M24.74,44a4,4,0,0,1-2,2.08,8.45,8.45,0,0,1-3.12.47,21.35,21.35,0,0,0-5.3.27s0,.07,0,.06c2.15-.37,4.42.12,6.6-.06,1.61-.14,3.87-.83,4-2.77C25,43.93,24.79,43.93,24.74,44Z"/>
			<path fill={props.fill} d="M14.15,15.64C12.2,18,10,20,8,22.22a.11.11,0,0,0,.14.16,33,33,0,0,0,6.23-6.59A.12.12,0,0,0,14.15,15.64Z"/>
			<path fill={props.fill} d="M15.54,17.19a69.59,69.59,0,0,0-6.95,7c-.07.09.05.18.13.11,2.46-2.19,4.6-4.71,7-6.94C15.86,17.24,15.66,17.07,15.54,17.19Z"/>
			<path fill={props.fill} d="M16.17,20.55c-2.72,2.32-5.34,4.8-7.94,7.26-.09.09,0,.23.14.16,2.9-2.07,5.3-4.81,8-7.12C16.55,20.7,16.35,20.4,16.17,20.55Z"/>
			<path fill={props.fill} d="M15.44,23.31c-2.28,2.25-4.79,4.32-7,6.65-.1.1.06.27.17.19a61.77,61.77,0,0,0,7.19-6.53C16,23.4,15.66,23.09,15.44,23.31Z"/>
			<path fill={props.fill} d="M14.56,27.68a50.77,50.77,0,0,0-4.78,3.74c-.09.08,0,.24.11.16,1.67-1.14,3.28-2.35,4.95-3.47A.26.26,0,0,0,14.56,27.68Z"/>
			<path fill={props.fill} d="M16.53,4.33l-.72.14c0-.18,0-.36-.06-.54a6.31,6.31,0,0,0-.15-1,.18.18,0,0,0-.06-.17.12.12,0,0,0-.2,0c-1.7.15-3.79-.08-5.4.59a1.16,1.16,0,0,0,0-.18c0-.06-.11-.05-.12,0a2,2,0,0,0,0,.26s-.05.08,0,.1A3,3,0,0,0,9.9,5.31h0s0,.1,0,.11A14.24,14.24,0,0,0,15.62,5a.08.08,0,0,0,.12,0h0l.85-.29C16.75,4.58,16.71,4.29,16.53,4.33ZM15.36,4c0,.11,0,.22,0,.32-.19-.18-.42-.34-.6-.51l.29-.33c.13-.15-.1-.34-.24-.2l-.28.31-.32-.33c.36,0,.72,0,1.08-.1C15.31,3.39,15.34,3.68,15.36,4Zm-1-.23c-.1.12-.21.23-.31.34a3.79,3.79,0,0,1-.34-.2c.13-.15.24-.32.36-.49C14.18,3.49,14.28,3.61,14.38,3.72Zm-.6-.47H14c-.12.17-.26.34-.39.51l-.18-.13C13.51,3.5,13.64,3.37,13.78,3.25ZM13.36,4c-.09.1-.17.21-.25.31l-.26-.12.32-.36Zm-.73-.69.31.3a5.29,5.29,0,0,0-.41.41l-.06,0c-.13-.06-.25-.13-.37-.2l.34-.47Zm-.35,1a.76.76,0,0,0-.08.11,3.11,3.11,0,0,0-.4-.22l.1-.14Zm-.69-.93.65,0-.33.35-.36-.2S11.58,3.43,11.59,3.4Zm-.13.35.26.19-.14.17L11.34,4Zm.31,1.34c-.58,0-1.16.1-1.73.19h0a3.22,3.22,0,0,0,0-1c0-.25,0-.51-.07-.76l1.08-.1s0,0,0,0l.16.11c-.06.11-.13.21-.19.31l-.37-.14c-.06,0-.1.06-.05.09L11,4V4a4.13,4.13,0,0,0-.58.91c0,.09.05.18.14.12a2.33,2.33,0,0,0,.75-.88l.21.11A4.63,4.63,0,0,0,11,5c0,.06.06.11.1.06a8.23,8.23,0,0,0,.59-.71l.4.21A3.76,3.76,0,0,0,11.77,5.09Zm1.68-.22a.06.06,0,0,0,0,0l-.15,0c-.41,0-.83.09-1.24.12.12-.11.22-.22.33-.34l.12.06c.06,0,.11,0,.06-.09a.24.24,0,0,0-.08-.06l.11-.13.3.14-.11.16c0,.05,0,.09.08.05s.1-.11.16-.16a2.57,2.57,0,0,0,.48.11Zm-.18-.46.23-.27a2.43,2.43,0,0,0,.32.24l-.2.24Zm.39.48c.14-.12.28-.24.41-.37a1.57,1.57,0,0,0,.63.18Zm1.18-.35a3.56,3.56,0,0,0-.48-.31L14.59,4a3.23,3.23,0,0,0,.71.62l-.45.09A.09.09,0,0,0,14.84,4.54Z"/>
			<path fill={props.fill} d="M20.51,2.05a1.51,1.51,0,0,0-1.76.79,2.37,2.37,0,0,0,.36,2.66c.91.84,2.47.08,3-.83A1.83,1.83,0,0,0,20.51,2.05Zm1.14,2.77a1.71,1.71,0,0,1-2.44.2,2.08,2.08,0,0,1-.14-1.94,1.15,1.15,0,0,1,.61-.65.67.67,0,0,1,.46,0l.07,0,.35.08.21,0a1.56,1.56,0,0,1,1,.61A1.32,1.32,0,0,1,21.65,4.82Z"/>
			<path fill={props.fill} d="M20.47,3.3a.27.27,0,0,0-.29,0,.4.4,0,0,0-.12.1h0a.25.25,0,0,0-.21.25v0h0c0,.05,0,.1,0,.15a.28.28,0,0,0,.06.29.24.24,0,0,0,.35.07h0A.32.32,0,0,0,20.64,4a1.12,1.12,0,0,0,.07-.48A.24.24,0,0,0,20.47,3.3Z"/>
			<path fill={props.fill} d="M16.4,44.22A3.63,3.63,0,0,1,16,43.1a.21.21,0,0,0-.21-.19C14,43,12,43,10.23,43.23a.07.07,0,0,0-.1.06c0,.23,0,.45,0,.68a1.07,1.07,0,0,0,.05.59l-.26.06a.15.15,0,0,0,0,.28l.35.07c1.74.67,4,.23,5.79-.06a.33.33,0,0,0,.31-.35.5.5,0,0,0,0-.12A.17.17,0,0,0,16.4,44.22Zm-2.46-.87a6,6,0,0,1,1.49,0,.35.35,0,0,0,0,.36l.13.46a9.75,9.75,0,0,0-2.59.06,23.63,23.63,0,0,0-2.65.31,1.06,1.06,0,0,0,0-.47l-.06-.7A16.09,16.09,0,0,0,13.94,43.35Z"/>
		</g>
	</g>
</svg>
);

export default SvgPhoneDessin;
