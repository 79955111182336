import React, { Fragment, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import MboxMockup from '../../_resources/image/home/mbox_mockup.png'
import CrystalcloudMockup from '../../_resources/image/home/localisation_mockup_cc.png'
import SaphirMockup from '../../_resources/image/home/saphir_mockup.png'
import server from '../../_resources/image/home/server.png'
import synchroCC from '../../_resources/image/synchro_cc.png'

import SvgLogoCrystalcloud from '../../_svg/LogoCrystalcloud';
import SvgFlecheBasPetite from '../../_svg/FlecheBasPetite';
import SvgFlecheBasGrande from '../../_svg/FlecheBasGrande';
import SvgFlecheDouble from '../../_svg/FlecheDouble';
import SvgFlecheCourbeGauche from '../../_svg/FlecheCourbeGauche';

import { billings, manufacturers, networks, products } from './tools';

const listActu = t => [
    {
        title: "crystalcloud",
        type: "logiciels",
        description: t('home.crystalcloud.description'),
        img: <img src={CrystalcloudMockup} />,
    },
    {
        title: "saphir",
        type: "applications",
        description: t('home.saphir.description'),
        img: <img src={SaphirMockup} />,
    },
    {
        title: "mbox",
        type: "produits",
        description: t('home.mbox.description'),
        img: <img src={MboxMockup} />,
    },
]

const BestDioptaseHome = props => {
    const { t } = props
    const billingRef = useRef(null);
    const history = useHistory();
    let gridDim = {}
    if (billingRef.current) {
        gridDim = billingRef.current.getBoundingClientRect()
    }

    console.log(products)
    return (
        <div className="dioptase-home-container">
            <div className="news-container">
                <h2 className='title-h'>{t('home.news')}</h2>
                <div className="actu-list-container">
                    {listActu(t).map((el, index) => (
                        <Fragment>
                            <div className="section-a">
                                {el.img}
                                <div className='description-container'>
                                    <span>{el.description}</span>
                                    <div className="button-container">
                                        <Button style={{ border: `1px solid #333}` }} onClick={() => history.push(`/${el.type}/${el.title}`)}>
                                            {t('all.button.more')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {index !== listActu(t).length - 1 && (
                                <div className="separator" style={{ left: `${(index + 1) * 33.33}%` }}>
                                    <div className="line" />
                                </div>
                            )}
                        </Fragment>
                    ))}
                </div>
            </div>
            <div className="news-band">
                <img src='https://www.dioptase.fr/sign_mail/dioptase_promo.png'/>
            </div>
            <div className="how-wrapper">
                <h2>{t('home.how')}</h2>
                <div className='how-content'>
                    <div className='flex-box'>
                        <div className="billing-container" ref={billingRef}>
                            <h3>{t('home.compatible.billing')}</h3>
                            <div className="billing-grid">
                                {billings.map(el => (
                                    <div className='billing-box'>
                                        {el.svg}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='double-fleche-c'>
                            <h4>{t('home.silex.api')}</h4>
                            <SvgFlecheDouble width="200px" height='auto' />
                            <SvgFlecheCourbeGauche width="200px" height='auto' className="svg-fleche-courbe" />
                        </div>
                        <div className='img-server'>
                                <img src={server} />
                                <h3>Serveur Dioptase</h3>
                        </div>
                    </div>
                    <div className='crystalcloud-wrapper'>
                        <div className='network-list'>
                            {networks.map(el => (
                                <div className='network-box'>
                                    {el.svg}
                                </div>
                            ))}
                        </div>
                        <div className='image-cc'>
                            <SvgLogoCrystalcloud height="6em" />
                            <img src={synchroCC} />
                            <div className='little-arrow'>
                                <SvgFlecheBasPetite height="6em"/>
                            </div>
                        </div>
                    </div>
                    <div className='manufacturer-container'>
                        <h3>{t('home.compatible.manufacturer')}</h3>
                        <div className='manufacturer-grid'>
                            {manufacturers.map(el => (
                                <div className='manufacturer-box'>
                                    {el.svg}
                                </div>
                            ))}
                        </div>
                        <div className='products-container'>
                            {products(t).map(el => (
                                <div className="product-section">
                                    <div className="svg-box">
                                        <SvgFlecheBasGrande height="250px" fill={el.color}/>
                                    </div>
                                    <div className='content-s'>
                                        <h3>{el.title}</h3>
                                        <div className='image-s' style={{marginTop: el.code === 'dld' ? "1.75rem" : "0"}}>
                                            {el.img}
                                        </div>
                                        <div className='logo-s'>
                                            {el.logo}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='background-city'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(BestDioptaseHome);