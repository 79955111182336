import adminConstants from './admin.constants';
export default function admin(state = {}, action) {
    switch (action.type) {
        case adminConstants.ADMIN_LOGIN_REQUEST:
            return {
                ...state,
            };
        case adminConstants.ADMIN_LOGIN_SUCCESS:
            console.log(action.login)
            return {
                ...state,
            };

        case adminConstants.ADMIN_LOGIN_FAILURE:
            return {
                error: "Nom d'utilisateur ou mdp inconnu",
                pending: false,
            };
        case adminConstants.CLEAR:
            return {};
        default:
            return state;
    }
}