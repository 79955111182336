import React from 'react';

const SvgSmartTab = (props: any) => (
	<svg viewBox="0 0 37 29.16" {...props}>
		<g id="Calque_2">
			<g id="txt">
				<path fill={props.fill} d="m21.73,26.84V1.96c0-1.18-.96-2.14-2.14-2.14h0s-17.24,0-17.24,0C1.17-.18.22.78.21,1.96v24.87c0,1.18.96,2.14,2.14,2.15h17.24c1.18,0,2.14-.96,2.14-2.15Zm-19.38,1.07c-.58,0-1.05-.48-1.05-1.06,0,0,0,0,0-.01V1.96c0-.58.47-1.04,1.05-1.05h17.24c.58,0,1.05.47,1.05,1.05h0v24.87c0,.58-.46,1.05-1.04,1.05,0,0,0,0,0,0H2.36v.02Z"/>
				<path fill={props.fill} d="m9.9,2.03s0-.05,0-.07c0-.02,0-.05,0-.07-.03-.06-.09-.11-.16-.11-.1-.02-.19.05-.2.15s.05.19.15.2c.02,0,.04,0,.06,0,.07,0,.13-.04.16-.1Z"/>
				<path fill={props.fill} d="m12.55,1.96c0-.1-.08-.18-.18-.18h-1.75c-.07,0-.13.04-.16.1,0,.02,0,.05,0,.07,0,.02,0,.05,0,.07.03.07.09.11.16.11h1.75c.1,0,.17-.08.18-.17Z"/>
				<path fill={props.fill} d="m11.43,27.01c0-.25-.2-.45-.45-.45s-.45.2-.45.45c0,.25.2.45.45.45h0c.25,0,.45-.2.45-.45Z"/>
				<path fill={props.fill} d="m20.14,25.58V3.26c0-.3-.24-.55-.55-.55H2.36c-.3,0-.55.24-.55.55h0v22.32c0,.3.24.55.55.55h17.24c.3,0,.55-.25.55-.55Zm-17.23-.55V3.79h16.14v21.24H2.91Z"/>
			</g>
		</g>
		<g id="Calque_2-2">
			<g id="txt-2">
				<path fill={props.fill} d="m35.61,5.47h-6.88c-.78,0-1.41.63-1.41,1.41,0,.04,0,.07,0,.11v14.89c-.05.75.52,1.39,1.26,1.44.04,0,.08,0,.12,0h6.88c.81.05,1.51-.57,1.56-1.38,0-.02,0-.04,0-.06V6.99c-.02-.84-.7-1.52-1.54-1.52Zm.7,16.41c-.02.35-.32.63-.68.61,0,0-.01,0-.02,0h-6.88c-.29.03-.54-.19-.57-.48,0-.04,0-.08,0-.13v-.88h8.03s.07,0,.11,0v.88Zm-8.14-14.89c0-.34.17-.68.55-.68h6.88c.38,0,.69.3.7.68v.61s-.07,0-.11,0h-8.01v-.61Zm7.61,1.43v11.74h-7.19v-11.74h7.19Z"/>
				<path fill={props.fill} d="m32.24,21.5c-.13,0-.24.11-.24.24,0,.13.11.24.24.24s.24-.11.24-.24-.11-.24-.24-.24h0Z"/>
				<rect fill={props.fill} x="30.5" y="6.95" width="3.59" height=".31" rx=".14" ry=".14"/>
			</g>
		</g>
	</svg>
);

export default SvgSmartTab;
