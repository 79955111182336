const jobConstants = {
    GET_ALL_REQUEST:'GETALL_JOB_REQUEST',
    GET_ALL_SUCCESS:'GETALL_JOB_SUCCESS',
    GET_ALL_FAILURE:'GETALL_JOB_FAILURE',

    GET_JOB_INFO_REQUEST:'GET_JOB_INFO_REQUEST',
    GET_JOB_INFO_SUCCESS:'GET_JOB_INFO_SUCCESS',
    GET_JOB_INFO_FAILURE:'GET_JOB_INFO_FAILURE',

    GET_ADVANTAGES_REQUEST:'GET_ADVANTAGES_REQUEST',
    GET_ADVANTAGES_SUCCESS:'GET_ADVANTAGES_SUCCESS',
    GET_ADVANTAGES_FAILURE:'GET_ADVANTAGES_FAILURE',

    SAVE_JOB_REQUEST:'SAVE_JOB_REQUEST',
    SAVE_JOB_SUCCESS:'SAVE_JOB_SUCCESS',
    SAVE_JOB_FAILURE:'SAVE_JOB_FAILURE',

    DELETE_JOB_REQUEST:'DELETE_JOB_REQUEST',
    DELETE_JOB_SUCCESS:'DELETE_JOB_SUCCESS',
    DELETE_JOB_FAILURE:'DELETE_JOB_FAILURE',

    CLEAR: 'JOB_CLEAR',
}

export default jobConstants