import React from 'react';

const SvgKerlink = (props: any) => (
<svg viewBox="0 0 91.56 34" {...props}>
	<polygon points="0 3.16 0 25.45 2.28 25.45 2.28 17.89 3.21 17.89 9.83 25.45 12.91 25.45 5.04 17.01 11.71 9.84 8.84 9.84 3.33 16.11 2.28 16.11 2.28 3.16 0 3.16"/>
	<g>
		<path d="M24.68,20.73h2.33l.02,.57c0,1.62-.49,2.76-1.48,3.4-.98,.65-2.71,.97-5.18,.97-2.87,0-4.75-.53-5.65-1.58-.9-1.05-1.35-3.26-1.35-6.62,0-3.11,.45-5.2,1.35-6.27,.9-1.07,2.67-1.6,5.3-1.6,2.87,0,4.75,.46,5.64,1.39,.89,.92,1.34,2.87,1.34,5.85v1.22H15.8c0,2.46,.26,4.03,.79,4.7,.53,.67,1.76,1.01,3.69,1.01,1.84,0,3.03-.16,3.58-.48,.55-.32,.83-1.02,.83-2.08v-.49Zm0-4.44l-.02-.73c0-1.69-.28-2.78-.83-3.29-.56-.51-1.75-.76-3.59-.76s-3.05,.3-3.6,.89c-.55,.59-.82,1.89-.82,3.89h8.85Z"/>
		<path d="M29.8,9.84h2.28l-.23,1.8,.05,.05c.9-1.47,2.39-2.2,4.47-2.2,2.87,0,4.31,1.48,4.31,4.44l-.02,1.08h-2.25l.05-.39c.03-.41,.05-.69,.05-.83,0-1.6-.87-2.4-2.61-2.4-2.54,0-3.82,1.57-3.82,4.72v9.36h-2.28V9.84Z"/>
	</g>
	<rect x="42.71" y="3.16" width="3.73" height="22.29"/>
	<rect x="51.69" y="9.84" width="3.73" height="15.61"/>
	<path d="M59.09,9.84h3.7l-.15,2.63,.08,.02c.73-1.88,2.36-2.83,4.89-2.83,3.68,0,5.53,1.72,5.53,5.16v10.63h-3.73V15.46l-.08-1.1c-.18-1.16-1.09-1.75-2.74-1.75-2.51,0-3.77,1.19-3.77,3.58v9.26h-3.73V9.84Z"/>
	<polygon points="80.53 3.16 80.53 15.91 81.5 15.91 85.7 9.84 90.04 9.84 84.61 17.19 91.15 25.45 86.52 25.45 81.45 18.53 80.53 18.53 80.53 25.45 76.8 25.45 76.8 3.16 80.53 3.16"/>
	<g>
		<path d="M53.73,.76c0,.24,.19,.43,.43,.43s.42-.19,.42-.43-.19-.43-.42-.43-.43,.19-.43,.43m-3.69,1.59c0,.24,.19,.43,.43,.43s.43-.19,.43-.43-.19-.43-.43-.43-.43,.19-.43,.43m.36,3.54c0,.24,.19,.43,.42,.43s.42-.19,.42-.43-.19-.42-.42-.42-.42,.19-.42,.42m5.71-1.16c0,.24,.19,.43,.43,.43s.43-.19,.43-.43-.19-.43-.43-.43-.43,.19-.43,.43m-5.62-.17h2.62v1.07h-1.34c-.14,0-.26,.12-.26,.26s.12,.26,.26,.26h1.34v1.01c0,.14,.12,.26,.26,.26s.26-.12,.26-.26v-2.86c0-.14-.12-.26-.26-.26h-2.61v-.78c0-.14-.12-.26-.26-.26s-.26,.12-.26,.26v1.03c0,.14,.11,.25,.25,.26m1.76-.89h2.06v3.13c0,.14,.12,.26,.26,.26s.26-.12,.26-.26v-1.82h.61c.14,0,.26-.12,.26-.26s-.12-.26-.26-.26h-.61v-1.05c0-.14-.12-.26-.26-.26h-2.06v-.59h1.62s.04,0,.06,0c.13-.02,.23-.12,.23-.26v-.62c0-.14-.12-.26-.26-.26s-.26,.12-.26,.26v.37h-1.64c-.14,0-.26,.12-.26,.26v1.11c0,.14,.12,.26,.26,.26m1.09,4.14c-1.47,0-2.8-.81-3.47-2.12-.98-1.91-.22-4.27,1.69-5.25C52.11,.14,52.71,0,53.32,0,54.8,0,56.13,.81,56.8,2.12c.47,.93,.56,1.98,.24,2.98-.32,.99-1,1.8-1.93,2.27-.56,.29-1.15,.43-1.77,.43"/>
		<path d="M.28,30.78c0,.98,.77,1.8,1.82,1.8,.69,0,1.3-.36,1.5-.86l-.46-.13c-.11,.32-.6,.59-1.05,.59-.72,0-1.32-.62-1.32-1.41s.57-1.38,1.31-1.38c.44,0,.82,.21,1.03,.56l.46-.14c-.26-.5-.79-.82-1.47-.82-1.07,0-1.81,.79-1.81,1.78"/>
		<path d="M4.53,30.81c0-.78,.59-1.4,1.31-1.4s1.31,.62,1.31,1.39-.6,1.39-1.32,1.39-1.3-.61-1.3-1.38m1.31,1.78c1.04,0,1.8-.82,1.8-1.78s-.76-1.8-1.8-1.8-1.8,.83-1.8,1.8,.74,1.78,1.8,1.78"/>
		<path d="M13.78,30.5c0-.9-.29-1.49-1.1-1.49-.6,0-1.07,.3-1.4,.89-.08-.52-.46-.89-1.07-.89s-1.08,.31-1.38,.84v-.78h-.43v3.45h.47v-2.11c.18-.59,.64-.99,1.19-.99s.79,.36,.79,1.17v1.93h.47v-2.11c.17-.58,.64-.99,1.19-.99s.79,.37,.79,1.17v1.93h.47v-2.02Z"/>
		<path d="M20.12,30.5c0-.9-.29-1.49-1.1-1.49-.6,0-1.07,.3-1.4,.89-.08-.52-.46-.89-1.07-.89s-1.08,.31-1.38,.84v-.78h-.43v3.45h.47v-2.11c.18-.59,.64-.99,1.19-.99s.79,.36,.79,1.17v1.93h.47v-2.11c.17-.58,.64-.99,1.18-.99s.8,.37,.8,1.17v1.93h.47v-2.02Z"/>
		<path d="M22.22,32.58c.63,0,1.21-.31,1.53-.85v.48c0,.19,.17,.33,.39,.33,.05,0,.1,0,.19-.02v-.4c-.12,0-.17-.06-.17-.19v-2.87h-.47v2.17c-.21,.58-.74,.95-1.3,.95s-.84-.39-.84-1.17v-1.95h-.47v2.03c0,.98,.39,1.49,1.15,1.49"/>
		<path d="M28.25,30.5c0-.91-.26-1.49-1.07-1.49-.64,0-1.24,.34-1.53,.84v-.78h-.43v3.45h.47v-2.11c.18-.57,.76-.99,1.32-.99,.53,0,.77,.36,.77,1.17v1.93h.47v-2.02Z"/>
	</g>
	<path d="M29.23,32.52h.47v-3.45h-.47v3.45Zm0-4.17h.47v-.66h-.47v.66Z"/>
	<g>
		<path d="M30.48,30.78c0,.98,.77,1.8,1.82,1.8,.69,0,1.3-.36,1.5-.86l-.46-.13c-.11,.32-.6,.59-1.05,.59-.72,0-1.32-.62-1.32-1.41s.57-1.38,1.31-1.38c.44,0,.83,.21,1.03,.56l.46-.14c-.26-.5-.79-.82-1.47-.82-1.07,0-1.81,.79-1.81,1.78"/>
		<path d="M35.6,32.24c-.6,0-.9-.39-.9-.75,0-.46,.46-.73,1.14-.73,.33,0,.68,.07,.99,.18v.56c0,.08-.05,.18-.12,.26-.24,.3-.69,.48-1.11,.48m-.12,.34c.52,0,1.09-.24,1.4-.64v.27c.03,.21,.21,.32,.4,.32,.05,0,.11,0,.19-.01v-.4c-.12,0-.17-.06-.17-.19v-1.56c0-.85-.56-1.37-1.43-1.37-.48,0-.92,.15-1.38,.45l.17,.3c.4-.26,.78-.41,1.16-.41,.62,0,1.01,.38,1.01,.99v.31c-.3-.11-.7-.18-1.06-.18-.9,0-1.52,.42-1.52,1.06,0,.57,.5,1.05,1.24,1.05"/>
		<path d="M39.89,31.99c-.06,.03-.28,.15-.55,.15-.23,0-.42-.12-.44-.37v-2.34h.83v-.36h-.83v-1.17h-.47v1.17h-.5v.36h.5v2.47c0,.44,.35,.66,.76,.66,.39,0,.71-.16,.82-.21l-.13-.36Z"/>
	</g>
	<path d="M40.57,32.52h.47v-3.45h-.47v3.45Zm0-4.17h.47v-.66h-.47v.66Z"/>
	<g>
		<path d="M42.3,30.81c0-.78,.59-1.4,1.31-1.4s1.31,.62,1.31,1.39-.6,1.39-1.32,1.39-1.3-.61-1.3-1.38m1.31,1.78c1.04,0,1.8-.82,1.8-1.78s-.76-1.8-1.8-1.8-1.8,.83-1.8,1.8,.74,1.78,1.8,1.78"/>
		<path d="M49.21,30.5c0-.91-.26-1.49-1.07-1.49-.64,0-1.24,.34-1.53,.84v-.78h-.43v3.45h.47v-2.11c.18-.57,.76-.99,1.32-.99,.53,0,.77,.36,.77,1.17v1.93h.47v-2.02Z"/>
	</g>
	<path d="M51.97,32.52h.47v-3.45h-.47v3.45Zm0-4.17h.47v-.66h-.47v.66Z"/>
	<g>
		<path d="M54.66,32.58c.85,0,1.41-.4,1.41-1.03,0-.59-.48-.76-1.41-.97-.64-.15-.9-.23-.9-.59,0-.45,.4-.64,.89-.64,.41,0,.82,.13,1.09,.4l.23-.26c-.28-.28-.75-.47-1.3-.47-.74,0-1.35,.35-1.35,1.05,0,.54,.37,.68,1.21,.88,.73,.17,1.09,.27,1.09,.66,0,.41-.38,.65-.96,.65-.48,0-.89-.16-1.3-.48l-.21,.3c.39,.34,.96,.52,1.52,.52"/>
		<path d="M58.84,30.61c.05-.73,.62-1.25,1.32-1.25s1.28,.52,1.34,1.25h-2.66Zm1.33,1.97c.69,0,1.31-.36,1.53-.86l-.41-.1c-.17,.37-.63,.62-1.11,.62-.69,0-1.27-.54-1.33-1.29h3.09s.01-.11,.01-.18c0-.95-.73-1.77-1.79-1.77s-1.81,.81-1.81,1.78,.76,1.8,1.82,1.8"/>
	</g>
	<polygon points="65.16 29.07 63.91 32.1 62.67 29.07 62.18 29.07 63.65 32.52 64.15 32.52 65.61 29.07 65.16 29.07"/>
	<g>
		<path d="M66.49,30.61c.05-.73,.62-1.25,1.32-1.25s1.28,.52,1.34,1.25h-2.66Zm1.33,1.97c.69,0,1.31-.36,1.53-.86l-.41-.1c-.17,.37-.63,.62-1.11,.62-.69,0-1.27-.54-1.33-1.29h3.08s.02-.11,.02-.18c0-.95-.73-1.77-1.79-1.77s-1.81,.81-1.81,1.78,.76,1.8,1.82,1.8"/>
		<path d="M72.11,29.05s-.13,0-.21,0c-.46,0-.9,.36-1.15,.85v-.83h-.45v3.45h.47v-2.15c.19-.56,.7-.89,1.33-.91v-.41Z"/>
		<path d="M72.95,33.98c.07,.01,.14,.02,.24,.02,.42,0,.64-.16,.78-.48l1.88-4.45h-.45l-1.23,3.03-1.34-3.03h-.49l1.57,3.45c-.37,.87-.45,1-.55,1.04-.05,.02-.17,.04-.26,.04-.05,0-.1,0-.14-.01v.4Z"/>
		<path d="M78.12,31.99c-.06,.03-.28,.15-.55,.15-.23,0-.42-.12-.44-.37v-2.34h.83v-.36h-.83v-1.17h-.47v1.17h-.5v.36h.5v2.47c0,.44,.35,.66,.76,.66,.39,0,.71-.16,.82-.21l-.13-.36Z"/>
		<path d="M81.83,30.5c0-.89-.31-1.49-1.14-1.49-.58,0-1.13,.32-1.42,.84v-2.17h-.47v4.84h.47v-2.11c.17-.56,.73-.99,1.26-.99s.83,.39,.83,1.17v1.93h.47v-2.02Z"/>
	</g>
	<path d="M82.81,32.52h.47v-3.45h-.47v3.45Zm0-4.17h.47v-.66h-.47v.66Z"/>
	<path d="M87.32,30.5c0-.91-.26-1.49-1.07-1.49-.64,0-1.24,.34-1.53,.84v-.78h-.43v3.45h.47v-2.11c.18-.57,.76-.99,1.32-.99,.53,0,.77,.36,.77,1.17v1.93h.47v-2.02Z"/>
	<path d="M89.89,32.19c-.76,0-1.34-.64-1.34-1.38,0-.68,.48-1.4,1.28-1.4,.52,0,1.05,.36,1.25,.86v1.07c-.08,.42-.63,.85-1.19,.85m-.13,.38c.52,0,1.04-.28,1.32-.72v.68c0,.74-.59,1.1-1.3,1.1-.53,0-1.04-.2-1.32-.64l-.29,.22c.32,.48,.79,.78,1.62,.78,.97,0,1.77-.5,1.77-1.46v-3.46h-.42v.72c-.32-.44-.77-.78-1.39-.78-.99,0-1.68,.83-1.68,1.8,0,.91,.7,1.76,1.69,1.76"/>
</svg>
);

export default SvgKerlink;
