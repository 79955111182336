import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import SvgCrystalcloud from "../../_svg/LogoCrystalcloud";
import VisibilitySensor from "react-visibility-sensor";

const App = (props) => {
  const { mbox, t } = props
  const ref = useRef();
  const content = {
    description: t('produits.cc.synchronisation')
  }
  return (
              <div className={"svg-container"} id={"svg-accueil-cc"} itemScope itemType="https://schema.org/WebPage">
                  <div className="onyx-export">
                      <SvgCrystalcloud style={{display: "block", marginBottom: "20px"}}/>
                      <span itemProp="description">
                        {content.description}
                      </span>
                  </div>
              </div>
  );
};

export default App;
