import React, { Component } from "react";

import Crystalcloud from "../../../_resources/image/synchro_cc.png";

import { Button } from "reactstrap";

import SvgLogiciel from "../../../_svg/Logiciel";
import SvgCrystalcloud from "../../../_svg/LogoCrystalcloud";

const logiciels = [
  {
    title: "Crystalcloud",
    image: <img src={Crystalcloud} alt="crystalcloud"/>,
    logo: <SvgCrystalcloud height="5em"/>,
  },
];

export default class Logiciels extends Component<any, any> {
  render() {
    const { parentColor, t } = this.props
    const content = {
      moreInfo: t('all.button.more'),
    };
    return (
      <div className="logiciels-container responsiv">
        <div className="vitrine">
          {logiciels.map((el: any) => (
            <div className="card">
              <div className="image-container">{el.image}</div>
              <div className="button-container">
                {el.logo}
                <Button style={{border: `1px solid ${parentColor}`}}>
                  <a href={`/logiciels/${el.title.toLowerCase()}`} style={{color: "black"}}>
                  {content.moreInfo}
                  <SvgLogiciel height="1.5em" fill={parentColor} />
                  </a>
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
