import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useMatomo } from '@datapunt/matomo-tracker-react'

import Header from "./FicheComponent/Header";
import Dimensions from "./FicheComponent/Dimensions";
import Caracteristiques from "./FicheComponent/Caracteristiques";
import Poid from "./Poid";
import Comparer from "./TopPage";

import Calculator from "./Calculator";
import Compatible from "../onyx/Compatible";
import Contact from "../onyx/Contact";
import Footer from "../section/FooterSection";

import { withTranslation } from "react-i18next";
import docActions from "../../redux/doc/doc.action";
import ReactGA from "react-ga4";

const FicheTechnique = (props: any) => {
  const { device, t, dispatch } = props;
  const [selected, setSelected]: any = useState(undefined)
  const { trackLink } = useMatomo()

  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
    if (!selected && device.devices) {
      const newDevice = device.devices.find(
        (el: any) => el.code === window.location.pathname.split("/")[2]
      );
      console.log(newDevice)
      setSelected(newDevice)
    }
  }, [])

  const setDevice = () => {
    if (!selected && device.devices) {
      const newDevice = device.devices.find(
        (el: any) => el.code === window.location.pathname.split("/")[2]
      );
      setSelected(newDevice)
    }
  }

  const downloadFile = () => {
    dispatch(docActions.getDoc(selected.code, "fr"))
    trackLink({ href: selected.name, linkType: 'download' })
  }
  if (!selected) {
    setDevice()
  }
  return (
    <div className="fiche-technique-container responsiv" style={{ marginTop: 0 }}>
      {selected && (
        <div className="component-container">
          <Header device={selected} t={t} downloadFile={downloadFile} />
          <Dimensions device={selected} />
          <Caracteristiques device={selected} t={t} />
          <Poid device={selected} t={t} />
          <Comparer />
          <Calculator initDevice={selected} />
          <Compatible section="Applications" parent="FicheTechnique" parentColor="#cf7830" t={t} />
          <Contact objet="Matériels" t={t} />
          <Footer t={t} />
        </div>
      )}
    </div>
  );

}

function mapStateToProps(state: any) {
  const { device } = state;

  return {
    device,
  };
}

const connectedMateriels = connect(mapStateToProps)(FicheTechnique);
const tr = withTranslation()(connectedMateriels);
export default tr;
