import React, { useRef } from "react";
import { connect } from "react-redux";
import SvgLogoCrystalcloud from "../../_svg/LogoCrystalcloud";
import docActions from "../../redux/doc/doc.action";
import {withTranslation} from "react-i18next";
import { useMatomo } from '@datapunt/matomo-tracker-react'

const App = (props) => {
  const { dispatch, t, isTablet } = props
  const { trackLink } = useMatomo()
  const ref = useRef();
  function downloadFile() {
    dispatch(docActions.getDoc("crystalcloud", "fr"));
    trackLink({ href: 'crytalcloud', linkType: 'download' })
  }

  const content = {
    button: t("all.button.download"),
  };
  return (
    <div className={"cc-alertes"} itemScope itemType="https://schema.org/WebPage">
        <div className="svg-box" style={{position: isTablet ? "relative" : "absolute", top: isTablet ? "0" : "120px"}}>
          <SvgLogoCrystalcloud height="7em" itemType="headline" />
          <div className={"download-documentation"}>
            <div className={"button-download"} itemProp="subjectOf">
              <button style={{ borderColor: "#31c3b6" }} onClick={downloadFile}>
                {content.button}
              </button>
            </div>
          </div>
        </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { indicator } = state;

  return {
    indicator,
  };
}

const connectedDoc = connect(mapStateToProps)(App);
const tr = withTranslation()(connectedDoc);
export default tr;
