import React from 'react';

const Envoyer = (props: any) => (
    <svg viewBox="0 0 50.03 43.19" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M49.14,3.17a60.34,60.34,0,0,1-2.48,14.47,70.28,70.28,0,0,0-2.7,10c-.32,1.85-.68,2-2.49,1.28-3.24-1.31-6.48-2.66-9.69-4.06A3.53,3.53,0,0,0,29,24.75C25.4,26,21.9,27.44,18.24,28.42c-1.5.41-2.2,0-2.49-1.54-.21-1.14-.23-2.32-.45-3.46-.78-3.93-2.33-7.69-2.39-11.61A28.2,28.2,0,0,1,4.19,5.13C3,3.81,3.47,2.6,5.28,2.45c6.28-.54,12.57-1,18.86-1.39Q30.92.61,37.72.33C41.78.17,45.84-.33,50,.34,50.09,1.46,49.23,2.14,49.14,3.17ZM30.36,6.68C24.94,8.56,19.51,10.38,13.85,12c.71,4.78,3.09,9.16,3.38,14.29l3.4-3a16.71,16.71,0,0,1,4.72-3.12A28.81,28.81,0,0,0,34.12,14c4-3.82,8-7.58,12.16-11.23.23-.2.57-.35.57-.83A126.47,126.47,0,0,0,30.36,6.68ZM26.14,21.32a111,111,0,0,1,16.27,6.61c1-8,4-15.42,4.36-23.44C40.07,10.14,34.24,16.83,26.14,21.32ZM10.21,7.79a4,4,0,0,1,.88.68c1.71,2.35,3.73,1.85,6.11,1.08,8.17-2.65,16.32-5.34,24.66-7.4a11.47,11.47,0,0,0,1.31-.5L5.12,3.58A46.64,46.64,0,0,0,10.21,7.79ZM28.7,23.67c-1.41-.48-2.54-.87-3.69-1.24a1.08,1.08,0,0,0-1.16.26c-1.3,1.19-2.62,2.35-4.15,3.72Z"/>
			<path fill={props.fill} d="M18.12,32.32a3.52,3.52,0,0,0-1.5,1.12,56.91,56.91,0,0,1-8.53,7.27c-.58.4-1.19.75-1.77,1.14-.35.24-1,.35-.75,1s.82.27,1.19.08a30.94,30.94,0,0,0,11.37-9.14c.24-.34.59-.66.36-1.13C18.42,32.49,18.23,32.41,18.12,32.32Z"/>
			<path fill={props.fill} d="M0,33.91c0,.39.22.63.61.53A2.78,2.78,0,0,0,1.47,34q4.59-3,9.17-5.93a5.86,5.86,0,0,0,1.91-1.83A.91.91,0,0,0,12.48,25c-.4-.34-.81-.16-1.14.14C8,28.14,4.14,30.59.49,33.27.26,33.44,0,33.56,0,33.91Z"/>
			<path fill={props.fill} d="M14.23,28.55a2.06,2.06,0,0,0-1.29.77c-2.62,2.47-5.08,5.11-7.87,7.38-.58.48-1.18.92-1.78,1.37-.28.2-.57.41-.37.79s.64.19,1,0a4.33,4.33,0,0,0,.71-.43A65.21,65.21,0,0,0,14.27,30c.28-.3.64-.59.51-1.08C14.71,28.65,14.48,28.57,14.23,28.55Z"/>
		</g>
	</g>
</svg>
);

export default Envoyer;
