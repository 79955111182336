import React from "react";
import { withTranslation } from "react-i18next";

import compteurPng from "../../_resources/image/home/compteur.png";
import telephonePng from "../../_resources/image/home/smartphone.png";

import antennePng from "../../_resources/image/home/antenne.png";
import mboxPng from "../../_resources/image/home/mbox.png";

import laptopPng from "../../_resources/image/home/laptop.png";
import dldPng from "../../_resources/image/home/dld.png";
import SvgLora from "../../_svg/Lora";
import SvgSigfox from "../../_svg/Sigfox";

const LowDebitHome = (props: any) => {
  const { t } = props;
  const content = {
    t1: `${t("all.home.title.application").split(' ')[0]} ${t("all.home.title.application").split(' ')[1]}`,
    t2: `${t("all.home.title.application").split(' ')[2]} ${t("all.home.title.application").split(' ')[3]} ${t("all.home.title.application").split(' ')[4]}`,
    t3: `${t("all.home.title.software").split(' ')[0]} ${t("all.home.title.software").split(' ')[1]}`,
    t4: `${t("all.home.title.software").split(' ')[2]} ${t("all.home.title.software").split(' ')[3]} ${t("all.home.title.software").split(' ')[4]} ${t("all.home.title.software").split(' ')[5]} ${t("all.home.title.software").split(' ')[6]} ${t("all.home.title.software").split(' ')[7]}`,
  }
  console.log(content)
  return (
    <div className="home-low-debit-container" itemScope itemType="https://schema.org/WepPage">
      <div className="section b1">
        <div
          className="home-title"
          itemScope
          itemType="https://schema.org/WebPage"
        >
          <h1 itemProp="headline">{t("all.home.title.1")}</h1>
          <br />
          <h1 itemProp="headline">{t("all.home.title.2")}</h1>
          <br />
          <h1 itemProp="headline" style={{fontWeight: 600}}>{t("all.home.title.3")}</h1>
          <br />
        </div>
      </div>
      <div className="section b2">
        <div className="p1">
          <h2 itemProp="headline">{content.t1}</h2>
          <h2 itemProp="headline" style={{fontWeight: 600}}>{content.t2}</h2>
        </div>
        <div className="p2">
          <h2 itemProp="headline">{content.t3}</h2>
          <h2 itemProp="headline" style={{fontWeight: 600}}>{content.t4}</h2>
        </div>
      </div>
      <div className="section b3" itemScope itemType="https://schema.org/WebContent">
        <div className="white-text">
          <ul>
            <li>
              <span>
                <span>Solution de relève manuelle et</span>&nbsp;radio relève multi-protocoles, Diehl Metering (Sappel), Itron, Coronis, Homerider, Honeywell (Elster), Sensus, Zenner, Kamstrup, Maddalena, Sontex, Apator, Bmeter...
              </span>
            </li>
            <li>Compatible smartphone, tablette et terminal durci Android (version minimum &nbsp;4.4)</li>
            <li>Synchronisation des tournées localement (USB)&nbsp;ou à distance avec 3G, 4G ou WiFi</li>
            <li>Guidage GPS avec géo-référencement</li>
            <li>Radio relève automatique pour compteurs monodirectionnels et bidirectionnels</li>
            <li>Gestion des alarme<span>s en temps réel</span></li>
            <li>Prise de photos dans fiche&nbsp;abonné</li>
            <li><span>Facturation : compatible</span> <span>Agedi, Cegid, Cerig, Cosoluce, e-GEE, Incom, JVS, Magnus, Segilog, Visa...</span></li>
        </ul>
        </div>
        <div className="p1">
          <h1>01</h1>
          <h2 itemProp="alternativeHeadline">{t("all.home.title.01")}</h2>
        </div>
        <div className="p2">
          <div className="center">
            <h3>{t("all.home.need")}</h3>
            <div className="c1">
              <div className="span-center">
                <span itemProp="abstract">{t("all.home.saphir")}</span>
              </div>
              <img src={telephonePng} alt="smartphone"/>
            </div>
            <div className="c2">
              <div className="span-center">
                <span itemProp="abstract">{t("all.home.equipped.dwellings")}</span>
              </div>
              <img itemProp="image" src={compteurPng} alt="Compteur" />
            </div>
          </div>
        </div>
      </div>
      <div className="section b4">
        <div className="p1">
        <div className="center">
            <h3>{t("all.home.need")}</h3>
            <div className="c1">
              <div className="span-center">
                <span itemProp="abstract">{t("all.home.equipped.antenna")}</span>
              </div>
              <img itemProp="image" src={antennePng} alt="antenne" />
            </div>
            <div className="c2">
              <div className="span-center">
                <span itemProp="abstract">{t("all.home.with.mbox")}</span>
              </div>
              <img itemProp="image" src={mboxPng} alt="Mbox"/>
            </div>
          </div>
        </div>
        <div className="p2">
          <h1>02</h1>
          <h2 itemProp="alternativeHeadline">{t("all.home.title.02")}</h2>
        </div>
      </div>
      <div className="section b5">
      <div className="p1">
          <h1>03</h1>
          <h2 itemProp="alternativeHeadline">{t("all.home.title.03")}</h2>
        </div>
        <div className="p2">
          <div className="center">
          <h3>{t("all.home.need")}</h3>
          <div className="c1">
  <span itemProp="abstract">{t('all.home.dld.meter')}</span>
  <img src={dldPng} alt="Dld" />
          </div>
          <div className="c2">
          <span itemProp="abstract">{t('all.home.equipped.meter')}</span>
          <div className="svg-group">
            <SvgLora height="2em" />
            <SvgSigfox height="2em" />
          </div>
          </div>
          <div className="c3">
          <span itemProp="abstract">{t('all.home.crystalcloud')}</span>
          <img itemProp="image"src={laptopPng} alt="Ordinateur" />
          </div>
          </div>
      </div>
      </div>
    </div>
  );
};

export default withTranslation()(LowDebitHome);
