import React from 'react';

const SvgIp = (props: any) => (
	<svg viewBox="0 0 15 8.59" {...props}>
		<path fill={props.fill} d="m3.03-.18h1.62v8.59h-1.62V-.18Z"/>
		<path fill={props.fill}
			  d="m6.38,8.41V-.18h3.02c.41,0,.79.07,1.15.2.36.13.67.32.93.56.26.24.47.53.62.86s.23.7.23,1.1-.08.78-.23,1.11-.36.62-.62.86c-.26.24-.57.43-.93.56-.36.13-.74.2-1.15.2h-1.4v3.16h-1.62Zm3.05-4.69c.42,0,.74-.12.96-.36.22-.24.32-.52.32-.83,0-.15-.03-.3-.08-.44-.05-.14-.13-.27-.24-.38-.11-.11-.24-.19-.4-.26-.16-.06-.35-.1-.56-.1h-1.43v2.36h1.43Z"/>
	</svg>
);

export default SvgIp;