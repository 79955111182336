import React from "react";

const SvgExport = (props: any) => (
    <svg viewBox="0 0 24 14.4" {...props}>
        <path fill={props.fill}
              d="m10.75,8.54c.2.2.52.2.73,0l6.87-6.94v2.4c0,.29.23.52.52.52s.52-.23.52-.52V.34c0-.29-.23-.52-.52-.52h-3.66c-.29,0-.52.23-.52.52s.23.52.52.52h2.41l-6.89,6.95c-.19.21-.19.53.01.73Z"/>
        <path fill={props.fill}
              d="m17.03,7.69c-.29,0-.52.23-.52.52v4.02c0,.52-.43.95-.95.95H6.97c-.52,0-.95-.43-.95-.95V3.64c0-.52.43-.95.95-.95h4.02c.29,0,.52-.23.52-.52s-.23-.52-.52-.52h-4.02c-1.09,0-1.99.89-1.99,1.99v8.6c0,1.1.89,1.99,1.99,1.99h8.6c1.09,0,1.99-.89,1.99-1.99v-4.02c0-.29-.23-.52-.52-.52Z"/>
    </svg>
);

export default SvgExport;
