import React from 'react';

const SvgAndroid = (props: any) => (
    <svg viewBox="0 0 40.26 47.91" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M2.92,15.55A2.93,2.93,0,0,0,0,18.48H0V30.76H0a2.93,2.93,0,1,0,5.85,0h0V18.48h0A2.93,2.93,0,0,0,2.92,15.55Z"/>
			<path fill={props.fill} d="M40.26,18.48a2.93,2.93,0,1,0-5.85,0h0V30.76h0a2.93,2.93,0,1,0,5.85,0h0V18.48Z"/>
			<path fill={props.fill} d="M7,16.06H6.9V35.34a2.93,2.93,0,0,0,2.92,2.93h2.47V45h0a2.93,2.93,0,0,0,5.85,0h0V38.27h4V45A2.93,2.93,0,0,0,28,45V38.27h2.46a2.94,2.94,0,0,0,2.93-2.93V16.06H7Z"/>
			<path fill={props.fill} d="M33.34,15V13.63a11.58,11.58,0,0,0-.18-1.18,11.92,11.92,0,0,0-6.4-8l2-3.73a.47.47,0,0,0-.19-.64A.47.47,0,0,0,28,.25L25.9,4A14.79,14.79,0,0,0,14.37,4l-2-3.79a.48.48,0,0,0-.65-.19.47.47,0,0,0-.19.64l2,3.75A12,12,0,0,0,7.25,12a10.26,10.26,0,0,0-.33,2.61c0,.13,0,.27,0,.4ZM26.15,7.87a1.25,1.25,0,1,1-1.24,1.25A1.25,1.25,0,0,1,26.15,7.87Zm-12,0a1.25,1.25,0,1,1-1.24,1.25A1.24,1.24,0,0,1,14.11,7.87Z"/>
		</g>
	</g>
</svg>
);

export default SvgAndroid;
