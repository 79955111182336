import jobConstants from "./job.constants";
import jobService from "./job.service"

function getAll() {
    function request(){
        return { type: jobConstants.GET_ALL_REQUEST };
    }
    function success(jobs) {
        return { type: jobConstants.GET_ALL_SUCCESS, jobs}
    }
    function failure(error){
        return {type: jobConstants.GET_ALL_FAILURE, error}
    }
    return (dispatch) => {
        dispatch(request());
        jobService
            .getAll()
            .then((jobs) => {
                dispatch(success(jobs));
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };
}

function getJobInfo(jobId) {
    function request(){
        return { type: jobConstants.GET_JOB_INFO_REQUEST };
    }
    function success(info) {
        return { type: jobConstants.GET_JOB_INFO_SUCCESS, info}
    }
    function failure(error){
        return {type: jobConstants.GET_JOB_INFO_FAILURE, error}
    }
    return (dispatch) => {
        dispatch(request());
        jobService
            .getJobInfo(jobId)
            .then((info) => {
                dispatch(success(info));
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };
}

function getAdvantages() {
    function request(){
        return { type: jobConstants.GET_ADVANTAGES_REQUEST };
    }
    function success(advantages) {
        return { type: jobConstants.GET_ADVANTAGES_SUCCESS, advantages}
    }
    function failure(error){
        return {type: jobConstants.GET_ADVANTAGES_FAILURE, error}
    }
    return (dispatch) => {
        dispatch(request());
        jobService
            .getAdvantages()
            .then((info) => {
                dispatch(success(info));
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };
}

function save(job) {
    function request(){
        return { type: jobConstants.SAVE_JOB_REQUEST };
    }
    function success(job) {
        return { type: jobConstants.SAVE_JOB_SUCCESS, job}
    }
    function failure(error){
        return {type: jobConstants.SAVE_JOB_FAILURE, error}
    }
    return (dispatch) => {
        dispatch(request());
        jobService
            .save(job)
            .then((info) => {
                dispatch(success(info));
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };
}

function deleteJob(id) {
    function request(){
        return { type: jobConstants.DELETE_JOB_REQUEST };
    }
    function success(id) {
        return { type: jobConstants.DELETE_JOB_SUCCESS, id}
    }
    function failure(error){
        return {type: jobConstants.DELETE_JOB_FAILURE, error}
    }
    return (dispatch) => {
        dispatch(request());
        jobService
            .deleteJob(id)
            .then((info) => {
                dispatch(success(info));
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };
}

function clear() {
    return {
        type: jobConstants.CLEAR,
    };
}

const jobAction = {
    getAll,
    getJobInfo,
    getAdvantages,
    save,
    deleteJob,
    clear,
}

export default jobAction;