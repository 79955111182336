import React, { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import ScrollBar from "./ScrollBar"

const App = (props) => {
  const { duration, images, index, className, height, width, stretch, style, zoom, mBottom, start, x, y, accelerate, precipitate, children, fixed, home, geoloc, security, canvasStyle, backgroundIndex } = props;
  const [init, setInit] = useState(false);
  let previousImg = 0

  const currentFrame = index => images[index]
  async function preloadImages(){
    let loaded = 0;
    for (let i = 1; i < images.length - 1; i++) {
      if(i%2 === 0){
        const img = new Image();
        img.src = currentFrame(i);
        img.onload = () => {
          loaded++
        }
      }
    }
  };

  useEffect(() => {
    const coef = (duration - window.innerHeight) / images.length;
    const canvas = document.getElementById(`canvas-${index}`);
    const ctx = canvas.getContext("2d");

    if(fixed){
      const container = document.getElementById(`container-${index}`)
      container.style.top = `${y || 0}px`
      if(window.scrollY === 0){
        container.style.position = "fixed"
      }
    }
    let img = new Image();
    img.onload = function () {
      if(stretch) {
        ctx.drawImage(img, (canvas.width / 2) - ((img.width * zoom) / 2), (canvas.height / 2) - ((img.height * zoom) / 2),
        img.width * zoom,
        img.height * zoom)
      }
      else{
        ctx.drawImage(img, 0, 0);
      }
    };
    if(window.scrollY >= start){
      img.src = images[Math.round((window.scrollY - (start - (window.innerHeight / 2) || 0)) / coef)];
    }
    if(!init){
      img.src = images[0]
      setInit(true)
      preloadImages()
    }
  });
  const coef = (duration -  window.innerHeight*(accelerate ? accelerate : home ? 2 : 1)) / images.length;

  function scrollFunction(){
    const canvas = document.getElementById(`canvas-${index}`);

    if(canvas){
      const ctx = canvas.getContext("2d");
  
      const hero = document.getElementById(`hero-${index}`).getBoundingClientRect();

      const container = document.getElementById(`container-${index}`)
      
      let number = Math.round((window.scrollY - (start - (precipitate ? window.innerHeight*5 : window.innerHeight*2) || 0)) / coef)

      if(hero.bottom > window.innerHeight){
        container.style.top = `${y || 0}px`
        container.style.removeProperty('position');
        container.style.removeProperty('bottom');
      }
      if (
        hero.bottom >= window.innerHeight && hero.top <= window.innerHeight
      ) {
        container.style.position = "fixed";
        if(x || y){
          container.style.marginTop = 0
          container.style.marginLeft = 0
          container.style.top = `${y || 0}px`
          container.style.left = `${x}px`
        }
      }
      if(hero.top >= 0 && index != 0){
          container.style.position = "absolute";
      }
      if(hero.bottom < window.innerHeight){
        container.style.bottom = mBottom ? `${mBottom}px` : 0;
        container.style.removeProperty('top');
        container.style.position = "absolute";
      }
      let img = new Image();
      img.onload = function () {
        ctx.clearRect(0,0, canvas.width, canvas.height)
        if(stretch) {
          ctx.drawImage(img, (canvas.width / 2) - ((img.width * zoom) / 2), (canvas.height / 2) - ((img.height * zoom) / 2),
          img.width * zoom,
          img.height * zoom)
        }
        else{
          ctx.drawImage(img, 0, 0);
        }
      };
      if(number < images.length && number >= 0 && previousImg !== number) {
        img.src = images[number];
        previousImg = number
        if(backgroundIndex){
          backgroundIndex(number)
        }
      }
      if(number > images.length){
        img.src = images[images.length - 1]
        if(backgroundIndex){
          backgroundIndex(10000)
        }
      }
      if(window.scrollY === 0){
        img.src = images[0]
      }
      if(index === 7 && geoloc){
        const doc = document.getElementById("svg-geoloc-saphir")
        if(number >= 60){
          doc.style.left = `${60 - 50*((number - 60) / 120)}%`
          doc.style.inset = `15% ${60 - 50*((number - 60) / 120)}%`
        }
        else{
          doc.style.left = `60%`
          doc.style.inset = `15% 60%`
        }
      }
      if(index === 9 && security){
        const doc = document.getElementById("svg-securite-saphir")
        if(number >= 80){
          doc.style.inset = `${30 - 40*((number - 80) / 60)}% 55%`
          doc.style.top = `${30 - 40*((number - 80) / 60)}%`
        }
        else{
          doc.style.inset = `30% 55%`
          doc.style.top = `30%`
        }
      }
    }
  }

  window.addEventListener("scroll", scrollFunction);

  return (
    <div
      className={className || "space"}
      style={{ height: `${duration - (window.innerHeight/2)}px`, position:"relative" }}
      id={`hero-${index}`}
    >
      {home && <ScrollBar start={start} precipitate={precipitate} coef={coef}/>}
      <div id={`container-${index}`} className="canvas-container" style={style || { width: `${width}px`, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <canvas style={canvasStyle} id={`canvas-${index}`} height={height || window.innerHeight} width={width || 1920}/>
        {children}
      </div>
    </div>
  );
};
export default App;
