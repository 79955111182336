import React, { useRef, useEffect } from 'react';
import SvgLogoMaConso from '../../_svg/LogoMaConso';
import { withTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";

import IndicatorBar from "../../sidebars/IndicatorBar";
import Footer from '../section/FooterSection';
import './style.scss';
import './animation.scss'
import './responsiv.scss'
import ConsoAlarm from '../../_svg/Conso_alarm';
import ConsoData from '../../_svg/Conso_data'
import ConsoInterface from '../../_svg/Conso_interface';
import ConsoMeter from '../../_svg/Conso_meter';
import ConsoSuivi from '../../_svg/Conso_suivi';

import imgAbonne from '../../_resources/image/maconso/abonnes.png';
import imgAlarm from '../../_resources/image/maconso/alarmes.png';
import imgInterface from '../../_resources/image/maconso/interface.png';
import imgInterface2 from '../../_resources/image/maconso/interface_2.png';
import imgSuivi from '../../_resources/image/maconso/suivi.png';

import FooterSection from "../section/FooterSection";
import Compatible from '../onyx/Compatible';
import Contact from '../onyx/Contact';
import ResponsivMaconso from './ResponsivMaconso';

import { useMatomo } from '@datapunt/matomo-tracker-react';
import { connect } from "react-redux";
import docActions from "../../redux/doc/doc.action";

const MaConso = props => {
    const { dispatch, t } = props
    const { trackLink } = useMatomo()

    const first = useRef(0);
    const firstBis = useRef(0);
    const secondImg = useRef(0);
    const secondText = useRef(0);
    const thirdImg = useRef(0);
    const thirdText = useRef(0);
    const fourthImg = useRef(0);
    const fourthText = useRef(0);
    const sixthText = useRef(0)

    const isTablet = useMediaQuery({ query: "(max-width: 1200px)" })
    const isLessThan1600 = useMediaQuery({ query: "(max-width: 1600px)" })

    const updateComponents = () => {
        console.log(window.scrollY)
        if (first.current) {
            if (window.scrollY <= 800) {
                first.current.style.top = `${(window.scrollY / 3) * 0.375}px`
                firstBis.current.style.top = `${(window.scrollY / 5) * 0.375}px`
            }
            if (window.scrollY >= 400 && window.scrollY <= 800) {
                secondText.current.style.transform = `translateX(${50 - ((window.scrollY - 400) * (75 / 400))}%) translateY(${0 - ((window.scrollY - 400) * (25 / 400))}%)`
                secondImg.current.style.transform = `translateX(${-50 + ((window.scrollY - 400) * (60 / 400))}%) translateY(${25 - ((window.scrollY - 400) * (25 / 400))}%)`
            }
            if (window.scrollY >= 1050 && window.scrollY <= 1550) {
                secondText.current.style.transform = `translateX(-25%) translateY(${-25 - ((window.scrollY - 1050) * (25 / 500))}%)`
                secondImg.current.style.transform = `translateX(10%) translateY(${0 + ((window.scrollY - 1050) * (10 / 500))}%)`
            }
            if (window.scrollY >= 1300 && window.scrollY <= 1700) {
                thirdText.current.style.transform = `translateX(${-50 + ((window.scrollY - 1300) * (50 / 400))}%)`
                thirdImg.current.style.transform = `translateX(${50 - ((window.scrollY - 1300) * (50 / 400))}%)`
            }
            if (window.scrollY >= 2400 && window.scrollY <= 2800) {
                fourthText.current.style.transform = `translateX(${50 - ((window.scrollY - 2400) * (50 / 400))}%)`
                fourthImg.current.style.transform = `translateX(${-50 + ((window.scrollY - 2400) * (50 / 400))}%) rotate(${-15 + ((window.scrollY - 2400) * (15 / 400))}deg)`
            }
            if (window.scrollY >= 4000 && window.scrollY <= 4300) {
                sixthText.current.style.opacity = 1 - ((window.scrollY - 4000) * (1 / 300))
            }

            if (window.scrollY > 800) {
                secondText.current.style.transform = `translateX(-25%) translateY(-25%)`
                secondImg.current.style.transform = `translateX(10%) translateY(0%)`
                if (window.scrollY > 1550) {
                    secondText.current.style.transform = `translateX(-25%) translateY(-50%)`
                    secondImg.current.style.transform = `translateX(10%) translateY(10%)`
                    if (window.scrollY > 1700) {
                        thirdText.current.style.transform = `translateX(0%)`
                        thirdImg.current.style.transform = `translateX(0%)`
                        if (window.scrollY > 2800) {
                            fourthText.current.style.transform = `translateX(0%)`
                            fourthImg.current.style.transform = `translateX(0%) rotate(0deg)`
                            if (window.scrollY > 4300) {
                                sixthText.current.style.opacity = 0
                            }
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        console.log('premiere fois')
        window.addEventListener('scroll', (e) => {
            e.preventDefault()
            console.log(window.scrollY)
            if (first.current) {
                if (window.scrollY <= 800) {
                    first.current.style.top = `${(window.scrollY / 3) * 0.375}px`
                    firstBis.current.style.top = `${(window.scrollY / 5) * 0.375}px`
                }
                if (window.scrollY >= 400 && window.scrollY <= 800) {
                    secondText.current.style.transform = `translateX(${50 - ((window.scrollY - 400) * (75 / 400))}%) translateY(${0 - ((window.scrollY - 400) * ((isLessThan1600 ? 35 : 25) / 400))}%)`
                    secondImg.current.style.transform = `translateX(${-50 + ((window.scrollY - 400) * (60 / 400))}%) translateY(${25 - ((window.scrollY - 400) * (25 / 400))}%)`
                }
                if (window.scrollY >= 1050 && window.scrollY <= 1550) {
                    secondText.current.style.transform = `translateX(-25%) translateY(${(isLessThan1600 ? -35 : -25) - ((window.scrollY - 1050) * ((isLessThan1600 ? 35 : 25) / 500))}%)`
                    secondImg.current.style.transform = `translateX(10%) translateY(${0 + ((window.scrollY - 1050) * (10 / 500))}%)`
                }
                if (window.scrollY >= 1300 && window.scrollY <= 1700) {
                    thirdText.current.style.transform = `translateX(${-50 + ((window.scrollY - 1300) * (50 / 400))}%)`
                    thirdImg.current.style.transform = `translateX(${50 - ((window.scrollY - 1300) * (50 / 400))}%)`
                }
                if (window.scrollY >= 2400 && window.scrollY <= 2800) {
                    fourthText.current.style.transform = `translateX(${50 - ((window.scrollY - 2400) * (50 / 400))}%)`
                    fourthImg.current.style.transform = `translateX(${-50 + ((window.scrollY - 2400) * (50 / 400))}%) rotate(${-15 + ((window.scrollY - 2400) * (15 / 400))}deg)`
                }
                if (window.scrollY >= 4000 && window.scrollY <= 4300) {
                    sixthText.current.style.opacity = 1 - ((window.scrollY - 4000) * (1 / 300))
                }
            }
        })
        updateComponents()
    }, [])

    function downloadFile() {
        dispatch(docActions.getDoc("maconso", "fr"));
        trackLink({ href: 'maconso', linkType: 'download' })
    }

    return (
        <div className="maconso-container responsiv">
            <IndicatorBar parent="maconso" />
            {isTablet ? <ResponsivMaconso t={t} /> : (
                <>
                    <div className='box first-anim'>
                        <div className='b35 left' ref={first}>
                            <img src={imgInterface2} />
                        </div>
                        <div className='b30 center'>
                            <ConsoInterface fill={"#D0D146"} />
                            <h5>{t('maconso.interface')}</h5>
                            <div className='span-container'>
                                <span>{t('maconso.interface.description')}</span>
                            </div>
                            <div className={"download-documentation"}>
                                <div className={"button-download"} itemProp="subjectOf">
                                    <button style={{ borderColor: "#D0D146" }} onClick={downloadFile}>
                                        {t("all.button.download")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='b35 right' ref={firstBis}>
                            <img src={imgInterface} />
                        </div>
                    </div>
                    <div className='box second'>
                        <div className='b60' ref={secondImg}>
                            <img src={imgSuivi}></img>
                        </div>
                        <div className='b40' ref={secondText}>
                            <ConsoSuivi fill={"#D0D146"} />
                            <h5>{t('maconso.followed')}</h5>
                            <div className='span-container'>
                                <span>{t('maconso.followed.description')}</span>
                            </div>
                        </div>
                    </div>
                    <div className='box third'>
                        <div className='b40 center' ref={thirdText}>
                            <ConsoMeter fill={"#D0D146"} />
                            <h5>{t('maconso.subscribers')}</h5>
                            <div className='span-container'>
                                <span>{t('maconso.subscribers.description')}</span>
                            </div>
                        </div>
                        <div className='b60' ref={thirdImg}>
                            <div className='img-container'>
                                <img src={imgAbonne}></img>
                            </div>
                        </div>
                    </div>
                    <div className='box fourth'>
                        <div className='b60' ref={fourthImg}>
                            <img src={imgAlarm}></img>
                        </div>
                        <div className='b40' ref={fourthText}>
                            <ConsoAlarm fill={"#D0D146"} />
                            <h5>{t('maconso.alarms')}</h5>
                            <div className='span-container'>
                                <span>{t('maconso.alarms.description')}</span>
                            </div>
                        </div>
                    </div>
                    <div className='box fith'>
                        <div className='b100' ref={sixthText}>
                            <ConsoData fill={"#D0D146"} />
                            <h5>{t('maconso.datas')}</h5>
                            <div className='span-container'>
                                <span>{t('maconso.datas.description')}</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <Contact objet="maconso.eco" t={t} />
            <FooterSection />
        </div>
    )
}

function mapStateToProps(state) {
    const { indicator } = state;

    return {
        indicator,
    };
}

const connectedDoc = connect(mapStateToProps)(MaConso);
const tr = withTranslation()(connectedDoc);
export default tr;