import React, { Component } from "react";
import Analyse from "../../_svg/Analyse";
import Surveillance from "../../_svg/Surveillance";

import ImgRecap from "../../_resources/image/dld_box_mockup.png";


const sections1 = [
    {
        title: "Surveillance compteur",
        description: "Vous pouvez consulter vos données de compteur sur Crystalcloud soit en ligne où que vous soyez via votre ordinateur, soit depuis votre téléphone mobile ou votre tablette.",
        svg: <Analyse height={"3em"} fill={"#d8a202"}/>
    },
    {
        title: "Analyse de consommation",
        description: "Il est également possible depuis le logiciel CrystalCloud de télécharger les données CSV dans Excel. Cela vous donne la liberté d’analyser les données ou de les transférer sur un autre système.",
        svg: <Surveillance height={"3em"} fill={"#d8a202"}/>
    },
]

export default class Recap extends Component<any, any>{
    constructor(props: Readonly<any>) {
        super(props);
    }

    render() {
        const { t } = this.props
        return (
            <div className={"footer dld"} itemScope itemType="https://schema.org/WebPage">
                <div className={"image-container"}>
                <img itemProp="alternativeHeadline" src={ImgRecap} alt="recapitulatif"/>
        <h2>{t('all.recap')}</h2>
                </div>
                <div className={"footer-section"}>
                    {sections1.map((el:any) => (
                        <div className={"section"} itemProp="abstract">
                            {el.svg}
                            <h5>{el.title}</h5>
                            <span>{el.description}</span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}