import React from 'react';

const SvgSensus = (props: any) => (
	<svg viewBox="0 0 255.43 44.92" {...props}>
		<path d="M156.17,34.65h-6.63c-.58,0-1.13-.03-1.71-.15-1.38-.28-2.13-1.04-2.39-2.43-.1-.51-.15-1.04-.15-1.57v-9.42c0-.48,.05-.94,.13-1.39,.3-1.65,1.15-2.46,2.81-2.63,.5-.05,.98-.08,1.48-.08h13.71c.33,0,.4,.08,.4,.4-.03,.94-.03,1.85,0,2.79,0,.3-.1,.36-.38,.36-4.55,0-9.09,0-13.64-.03-.38,0-.48,.1-.45,.48,.03,.86,.03,1.7,0,2.56,0,.33,.1,.38,.4,.38,4.55,0,9.09,0,13.64-.03,.35,0,.45,.1,.43,.46-.03,.94-.03,1.87,0,2.84,0,.3-.08,.35-.35,.35-4.55,0-9.09,0-13.64-.02-.38,0-.48,.1-.45,.48,.02,.86,.02,1.7,0,2.56,0,.3,.08,.36,.35,.36,4.55,0,9.09,0,13.64-.03,.38,0,.48,.08,.45,.46-.03,.99-.03,2,0,2.99,0,.28-.08,.33-.35,.33h-7.31Z" />
		<path d="M244.18,34.65h-7.28c-.35,0-.43-.1-.43-.43,.03-.99,.03-1.98,0-2.94,0-.33,.08-.4,.4-.4,4.67,0,9.32,0,13.99,.03,.38,0,.45-.1,.43-.46-.03-.86-.03-1.7,0-2.56,0-.3-.1-.38-.38-.38h-10.35c-.53,0-1.06-.05-1.58-.18-1.31-.25-2.11-1.04-2.31-2.35-.28-1.72-.28-3.47-.02-5.19,.25-1.75,1.15-2.53,2.91-2.71,.53-.05,1.06-.08,1.58-.08h13.44c.35,0,.43,.08,.43,.43-.03,.91-.03,1.82,0,2.76,0,.33-.1,.38-.4,.38-4.55,0-9.12,0-13.66-.03-.33,0-.45,.05-.43,.41,.02,.79,.02,1.57,0,2.35,0,.33,.1,.38,.38,.38h10.32c.53,0,1.03,.05,1.56,.15,1.41,.28,2.21,1.04,2.44,2.46,.3,1.85,.28,3.75,.03,5.6-.23,1.72-1.21,2.53-2.96,2.71-.65,.08-1.33,.08-1.98,.08-2.04-.03-4.07-.03-6.1-.03" />
		<path d="M130.61,34.65h-7.31c-.3,0-.35-.08-.35-.36,.03-1.01,.03-2.05,0-3.06,0-.3,.1-.36,.38-.36,4.67,0,9.34,0,14.02,.03,.33,0,.45-.05,.43-.4-.02-.89-.02-1.75,0-2.63,0-.28-.08-.33-.35-.33h-10.42c-.58,0-1.16-.08-1.73-.2-1.21-.28-1.93-1.06-2.14-2.28-.28-1.75-.28-3.52-.05-5.27,.23-1.7,1.15-2.51,2.89-2.69,.53-.05,1.03-.08,1.56-.08h13.51c.3,0,.4,.05,.38,.38-.03,.94-.03,1.85,0,2.79,0,.3-.08,.38-.38,.38-4.55,0-9.12,0-13.66-.03-.35,0-.45,.08-.45,.43,.03,.76,.03,1.55,0,2.3,0,.35,.1,.4,.4,.4h10.27c.53,0,1.03,.05,1.56,.15,1.43,.28,2.26,1.06,2.49,2.53,.25,1.8,.25,3.65,.02,5.44-.25,1.8-1.18,2.61-2.99,2.79-.53,.05-1.06,.08-1.58,.08-2.16-.03-4.32-.03-6.48-.03" />
		<path d="M198.7,34.65h-7.31c-.33,0-.38-.1-.38-.38,.03-.99,.03-2,0-2.99,0-.33,.08-.4,.4-.4,4.67,0,9.32,0,13.99,.03,.35,0,.45-.08,.45-.43-.02-.81-.02-1.62,0-2.43,.03-.38-.08-.51-.5-.51-3.42,.02-6.86,0-10.27,0-.55,0-1.1-.05-1.66-.18-1.26-.28-2.04-1.06-2.24-2.35-.28-1.75-.28-3.49-.02-5.24,.23-1.7,1.15-2.46,2.89-2.66,.53-.05,1.06-.08,1.58-.08h13.46c.33,0,.4,.08,.4,.4-.03,.94-.03,1.85,0,2.79,0,.3-.08,.35-.35,.35-4.55,0-9.12,0-13.66-.03-.38,0-.48,.08-.48,.46,.03,.73,.03,1.49,0,2.23,0,.35,.08,.46,.43,.46,3.24-.03,6.48,0,9.75,0,.68,0,1.36,.03,2.01,.15,1.53,.3,2.31,1.06,2.54,2.61,.25,1.8,.25,3.6,0,5.39-.25,1.77-1.18,2.58-2.96,2.76-1.06,.1-2.11,.08-3.14,.08-1.66-.03-3.29-.03-4.92-.03" />
		<path d="M167.83,26.79c.05-1.87-.08-4.2,.05-6.51,.03-.28,.05-.56,.1-.81,.33-1.49,1.18-2.23,2.81-2.41,.38-.05,.75-.08,1.13-.08h11.48c.5,0,.98,.05,1.48,.15,1.56,.28,2.34,1.14,2.56,2.74,.08,.56,.08,1.11,.08,1.67v12.71c0,.33-.1,.4-.4,.4-1.21-.02-2.41-.02-3.62,0-.33,0-.38-.1-.38-.38,0-4.36,0-8.71,.03-13.07,0-.33-.05-.46-.43-.46-3.34,.03-6.71,.03-10.05,0-.38,0-.43,.13-.43,.46,0,4.36,0,8.69,.03,13.04,0,.33-.05,.43-.4,.43-1.21-.03-2.39-.03-3.59,0-.33,0-.43-.05-.43-.43-.03-2.38-.03-4.71-.03-7.47" />
		<path d="M213.42,24.29v-6.94c0-.3,.08-.38,.38-.38,1.21,.03,2.39,.03,3.59,0,.35,0,.45,.08,.45,.43-.02,4.36,0,8.69-.02,13.04,0,.36,.08,.46,.43,.46,3.34-.03,6.71-.03,10.05,0,.33,0,.43-.05,.43-.43-.02-4.36,0-8.71-.02-13.07,0-.33,.08-.43,.43-.43,1.23,.03,2.44,.03,3.67,0,.25,0,.33,.05,.33,.33,0,4.51,0,9.02-.03,13.5,0,.46-.05,.91-.15,1.37-.25,1.34-1.08,2.08-2.39,2.33-.6,.13-1.23,.15-1.86,.15h-10.88c-.6,0-1.21-.05-1.81-.15-1.46-.28-2.26-1.11-2.49-2.58-.1-.61-.1-1.19-.1-1.8v-5.83Z" />
		<path d="M154.67,14.76h-8.69c-.38,0-.45-.1-.43-.46,.03-.86,.03-1.72,0-2.58,0-.33,.08-.43,.43-.43,4.35,.03,8.69,0,13.04,0h4.45c.28,0,.38,.05,.38,.35-.03,.91-.03,1.82,0,2.76,0,.3-.08,.36-.38,.36h-8.79Z" />
		<path d="M154.72,0h8.72c.33,0,.43,.08,.4,.4-.03,.91-.03,1.8,0,2.71,0,.25-.05,.35-.33,.35h-17.58c-.28,0-.35-.08-.35-.35,.02-.91,.02-1.82,0-2.74,0-.33,.1-.38,.4-.38h8.74Z" />
		<path d="M154.67,9.12h-8.72c-.3,0-.4-.08-.4-.38,.03-.91,.03-1.8,0-2.71,0-.28,.05-.38,.35-.38h17.61c.25,0,.33,.08,.33,.33-.03,.94-.03,1.85,0,2.78,0,.3-.08,.36-.38,.36h-8.79Z" />
		<path d="M103.56,31.27v3.59h-2.02c-3.42,0-5.09-1.78-5.09-5.77v-6.26c0-1.95-.61-3.97-3.08-3.97s-3.26,2.02-3.26,3.97v12.04h-4.31v-12.04c0-1.95-.86-3.97-3.39-3.97s-3.34,2.02-3.34,3.97v12.04h-4.29v-12.17c0-4.76,3.06-7.7,7.61-7.7,2.3,0,4.23,.82,5.56,2.25,1.34-1.43,3.23-2.25,5.54-2.25,4.21,0,7.21,2.71,7.21,7.7v6.46c0,1.47,.68,2.12,1.93,2.12h.94Zm-33.48,.53c-2.37,2-4.93,3.36-8.47,3.36-6.44,0-10.23-4.18-10.23-10.09s4.08-10.09,9.85-10.09,9.65,4.65,9.65,10.42c0,.73-.06,1.45-.06,1.45h-14.99c.56,2.84,2.29,4.61,5.78,4.61,2.45,0,4.17-.98,5.7-2.38l2.77,2.72Zm-14.26-8.35h10.64c-.5-2.91-2.4-4.8-5.23-4.8s-4.8,1.96-5.41,4.8m-13.54,5.48c0,4.19,1.72,5.93,5.28,5.93h1.94v-3.69h-.92c-1.31,0-2.04-.65-2.04-2.24V4.78h-4.27V28.93Zm-11.98,2.23h-.15c-1.09,0-1.7-1.63-2.78-4.57l-1.79-4.95c-1.68-4.6-3.39-6.68-7.28-6.68-3.27,0-4.95,1.97-6.98,4.72l-1.48,1.99-4.75-6.39H.01l7.32,9.6-2.86,3.83c-1.46,1.97-2.36,2.55-3.61,2.55H0v3.59H1.82c2.11,0,3.58-.72,4.83-2.48l3.13-4.39,5.03,6.88h5.12l-7.62-10.07,1.91-2.52c1.72-2.32,2.55-3.41,4.01-3.41,1.55,0,2.32,1.43,3.63,4.78l2.18,5.57c1.25,3.25,2.48,5.65,4.69,5.65h.28l-1.13,2.83c-.88,2.22-1.71,3.58-4.02,3.58h-1.01v3.65h1.47c3.42,0,5.86-1.69,7.45-6.11l8.45-23.52h-4.57l-5.34,15.87Z" />
	</svg>
);

export default SvgSensus;
