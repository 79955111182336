import React, { Component } from "react";

import Caterpilar from "../../../_resources/image/tel_cat.png";
import Nautiz from "../../../_resources/image/tel_nautiz-x6.png";
import Samsung from "../../../_resources/image/tel_samsung.png";
import Tdc from "../../../_resources/image/tel_TDC600.png";

import { Button } from "reactstrap";

import SvgLogiciel from "../../../_svg/Logiciels";

export default class Materiels extends Component<any, any> {
  render() {
    const { devices, parentColor, t } = this.props;
    const content = {
      moreInfo: t('all.button.more'),
    };
    return (
      <div className="materiels-container">
        <div className="vitrine">
          {devices && devices.map((el: any) => (
            <div className="card">
              <div className="image-container">
                <img
                  src={require(`../../../_resources/image/telephone/${el.code}.png`)}
                  alt="materiels"
                />
              </div>
              <div className="button-container">
                <a href={`/materiels/${el.code}`} style={{width: "100%", display:"flex", justifyContent: "center"}}>
                <Button style={{border: `1px solid ${parentColor}`}}>
                  {content.moreInfo}
                  <SvgLogiciel height="1.5em" fill={parentColor} />
                </Button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
