import React from 'react';

const SvgConcat = (props: any) => (
    <svg viewBox="0 0 27.95 43.8" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M23,.06,5.09,0a5,5,0,0,0-5,5v3L0,33.79l0,5a5,5,0,0,0,5,5l17.89,0a5,5,0,0,0,5-4.95l0-5L27.94,8V5A5,5,0,0,0,23,.06Zm2.86,38.78a3,3,0,0,1-3,3L5,41.76a3,3,0,0,1-3-3v-4l23.86.07Zm0-6L2,32.8,2.08,8.94,25.94,9ZM26,7,2.09,7V5a3,3,0,0,1,3-3L23,2a3,3,0,0,1,3,3Z"/>
			<path fill={props.fill} d="M14,12a9,9,0,1,0,8.92,9A9,9,0,0,0,14,12ZM14,27.87a7,7,0,1,1,7-6.94A7,7,0,0,1,14,27.87Z"/>
			<path fill={props.fill} d="M17,19.43H15.47V17.93a1.5,1.5,0,0,0-3,0v1.49H11a1.49,1.49,0,1,0,0,3h1.49v1.5a1.5,1.5,0,0,0,3,0V22.4H17a1.49,1.49,0,1,0,0-3Z"/>
			<circle fill={props.fill} cx="14.42" cy="38.31" r="1.49"/>
		</g>
	</g>
</svg>
)

export default SvgConcat;
