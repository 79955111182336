import { combineReducers } from 'redux';
import device from "./device/device.reducer";
import indicator from "./indicator/indicator.reducer";
import mail from "./mail/mail.reducer";
import debit from "./debit/debit.reducer";
import job from "./job/job.reducer";
import admin from "./admin/admin.reducer";

import action from "../models/classes/action"

const appReducer = combineReducers({
    device,
    indicator,
    mail,
    debit,
    job,
    admin,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
