import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import SvgSupport from "../../_svg/Support";
import VisibilitySensor from "react-visibility-sensor";
import { withTranslation } from 'react-i18next';

const App = (props) => {
  const ref = useRef();
  const { t } = props

  const content = {
    title: t('applications.saphir.support'),
    description: t('applications.saphir.support.description')
  }
  return (
              <div className={"svg-container"} id={"svg-support-saphir"} itemScope itemType="https://schema.org/WebPage">
                <div>
                  <SvgSupport fill={"#b77830"} />
                <h3 itemProp="alternativeTitle">{content.title}</h3>
                  <span itemProp="description">
                    {content.description}
                  </span>
                </div>
              </div>
  );
};

const tr = withTranslation()(App);
export default tr;