import React from 'react';

const SvgGeolocalisation = (props: any) => (
	<svg viewBox="0 0 24 13.43" {...props}>
		<polygon fill={props.fill}
				 points="9.25 6.3 9.25 6.3 9.25 7.44 9.25 7.44 9.25 7.44 9.93 7.44 9.93 6.3 9.93 6.3 9.25 6.3"/>
		<polygon fill={props.fill}
				 points="9.25 3.52 9.25 3.52 9.25 4.67 9.93 4.67 9.93 4.67 9.93 4.67 9.93 3.52 9.25 3.52 9.25 3.52"/>
		<path fill={props.fill}
			  d="m16.12,1.77c-.56-.47-1.28-.72-2.02-.72s-1.48.26-2.05.75l-2.41-1.5-.06-.04-4.53,2.23-.07.03v11.09l4.6-2.77,4.48,2.7,4.61-2.67V.18l-2.55,1.59Zm-2.35,9.4v1.41l-3.84-2.31v-1.67h-.67v1.67l-3.6,2.17V2.93l3.6-1.77v1.42h.67v-1.33l1.66,1.03c-.4.52-.61,1.14-.61,1.79,0,.28.04.55.11.81.03.1.06.2.11.31l.1.21,2.81,5.02,2.82-5.04.08-.18c.04-.1.08-.21.11-.32.08-.27.11-.54.11-.81,0-.66-.22-1.3-.63-1.82l1.41-.88v9.12l-3.56,2.07v-1.39h-.67Zm2.7-6.46c-.02.08-.05.16-.08.23l-2.29,4.12-2.21-3.95-.08-.17c-.03-.08-.06-.15-.08-.24-.06-.21-.09-.42-.09-.63,0-1.31,1.1-2.37,2.46-2.37s2.46,1.06,2.46,2.37c0,.22-.03.43-.09.64Z"/>
		<path fill={props.fill}
			  d="m14.2,2.6c-.67,0-1.22.55-1.22,1.22s.55,1.22,1.22,1.22,1.22-.55,1.22-1.22-.55-1.22-1.22-1.22Zm.63,1.22c0,.35-.28.63-.63.63s-.62-.28-.62-.63.28-.63.62-.63.63.28.63.63Z"/>
	</svg>

);

export default SvgGeolocalisation;
