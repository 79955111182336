import React, {useCallback} from "react";
import { useDropzone } from 'react-dropzone';

const DropLdm = (props) => {
  const { handleChange } = props
    const onDrop = useCallback(acceptedFiles => {
      handleChange(acceptedFiles, 'ldm')
      }, [])
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({onDrop});
    const files = acceptedFiles.map((file) => (
      <span key={file.path}>
        <b>
          {file.path} - {Math.round((file.size / 1024 / 1024) * 100) / 100} Mo
        </b>
      </span>
    ));
    return (
      <div {...getRootProps({ className: 'dropzone drag-box' })}>
      <input {...getInputProps()} />
      <span><b>{acceptedFiles.length > 0 ? files : 'Joindre Lettre de motivation - 12Mo maximum'}</b></span>
    </div>
    )
}

export default DropLdm;