import React from 'react';

const SvgJba = (props: any) => (
	<svg viewBox="0 0 68.73 52.13" {...props}>
		<path d="M51.89,17.92c-1-.18-1.99-.34-2.99-.47-1-.12-2-.23-3-.31-2-.16-4-.24-6-.24-2,0-3.99,.06-5.98,.19-.99,.07-1.99,.15-2.98,.26-.5,.06-.99,.11-1.49,.17-.49,.07-.99,.13-1.48,.22,3.98,.29,7.93,.53,11.85,.87,3.92,.34,7.84,.74,11.65,1.46,.22,.04,.43,.08,.65,.13v-2.22c-.08-.02-.16-.03-.24-.05Z"/>
		<path d="M39.83,18.6c-3.93-.33-7.88-.58-11.85-.87,.49-.09,.99-.15,1.48-.22,.49-.06,.99-.12,1.49-.17,.99-.11,1.98-.19,2.98-.26,1.99-.13,3.98-.2,5.98-.19,2,0,4,.08,6,.24,1,.08,2,.19,3,.31,1,.13,2,.29,2.99,.47,.08,.02,.16,.03,.24,.05V2.02c0-1.11-.91-2.02-2.02-2.02H2.02C.91,0,0,.91,0,2.02V50.12c0,1.11,.91,2.02,2.02,2.02H50.12c1.11,0,2.02-.91,2.02-2.02V20.19c-.22-.04-.43-.09-.65-.13-3.82-.71-7.73-1.12-11.65-1.46ZM4.02,49c-.2,.19-.43,.28-.71,.28-.22,0-.41-.06-.59-.17-.18-.11-.34-.31-.48-.58l.3-.18c.21,.39,.46,.59,.73,.59,.12,0,.23-.03,.33-.08,.1-.06,.18-.13,.24-.22,.05-.09,.08-.19,.08-.3,0-.12-.04-.24-.12-.35-.11-.16-.31-.35-.61-.57-.3-.22-.48-.38-.55-.48-.13-.17-.19-.35-.19-.54,0-.15,.04-.29,.11-.42s.18-.23,.31-.3c.13-.07,.28-.11,.44-.11,.17,0,.32,.04,.47,.12,.15,.08,.3,.23,.46,.46l-.29,.22c-.13-.18-.25-.29-.34-.35-.09-.06-.2-.08-.31-.08-.14,0-.26,.04-.35,.13-.09,.09-.14,.19-.14,.32,0,.08,.02,.15,.05,.22,.03,.07,.09,.15,.18,.24,.05,.04,.2,.16,.46,.35,.31,.23,.52,.43,.63,.6,.11,.18,.17,.35,.17,.53,0,.26-.1,.48-.29,.67Zm7.95-13.37c0,5.55-2.69,7.4-6.68,7.4-1.02,0-2.3-.18-3.09-.48l.45-2.97c.59,.2,1.38,.36,2.27,.36,2.09,0,3.36-.95,3.36-4.44v-12.93h3.68v13.05Zm8.16,13.11c-.36,.36-.8,.54-1.32,.54s-.97-.18-1.33-.54c-.36-.36-.54-.79-.54-1.31,0-.34,.08-.66,.25-.95,.16-.29,.39-.52,.67-.68,.28-.16,.59-.25,.92-.25,.54,0,.99,.18,1.35,.54,.36,.36,.54,.8,.54,1.32s-.18,.96-.54,1.32Zm7.85-7.7c-1.43,1.24-3.79,1.86-7.48,1.86-2.04,0-3.57-.14-4.52-.26V22.86c1.17-.24,3.3-.43,5.37-.43,2.7,0,4.39,.35,5.76,1.28,1.24,.77,2.08,2.06,2.08,3.79,0,1.85-1.15,3.54-3.35,4.34v.08c2.13,.54,4.07,2.21,4.07,4.98,0,1.79-.77,3.18-1.93,4.15Zm7.21,4.96h-1.42v1.11h1.42v.35h-1.42v1.74h-.35v-3.54h1.77v.35Zm8.33-8.98h-6.39l-1.72,5.69h-3.8l6.46-20.12h4.68l6.54,20.12h-3.95l-1.82-5.69Zm5.91,8.98h-.79v3.2h-.36v-3.2h-.79v-.35h1.94v.35Z"/>
		<path d="M40.3,25.5h-.07c-.29,1.18-.6,2.57-.96,3.77l-1.55,4.97h5.18l-1.59-4.96c-.39-1.25-.72-2.62-1.02-3.78Z"/>
		<path d="M21.46,33.47h-1.83v6.56c.48,.09,1.1,.1,1.94,.1,2.35,0,4.47-.88,4.47-3.37,0-2.34-2.06-3.3-4.58-3.3Z"/>
		<path d="M25.48,27.91c0-1.89-1.43-2.76-3.75-2.76-1.09,0-1.71,.07-2.1,.16v5.5h1.88c2.55,0,3.97-1.2,3.97-2.9Z"/>
		<path d="M19.56,46.12c-.24-.14-.49-.2-.76-.2s-.51,.07-.75,.2c-.23,.14-.42,.32-.55,.55-.13,.23-.2,.49-.2,.77,0,.42,.15,.78,.44,1.07,.29,.29,.64,.44,1.06,.44,.28,0,.53-.07,.76-.2,.23-.13,.42-.32,.55-.55,.13-.23,.2-.49,.2-.77s-.07-.54-.2-.76c-.13-.23-.32-.41-.55-.55Z"/>
		<path d="M62.87,26.65l.19-.39c.07-.13,.11-.28,.16-.42l.07-.22c.02-.07,.03-.16,.05-.23l.04-.24c.01-.08,.01-.17,.02-.25,.01-.16,0-.34-.02-.52-.02-.09-.04-.19-.06-.28-.01-.05-.03-.11-.05-.16,0-.03-.08-.17-.08-.17l-.21-.38c-.3-.5-.68-.94-1.07-1.33-.4-.4-.84-.72-1.28-1.03-.9-.61-1.85-1.07-2.81-1.46-.96-.39-1.95-.7-2.93-.98-.91-.24-1.82-.45-2.73-.63v2.22c.73,.15,1.46,.31,2.18,.49,.93,.23,1.85,.51,2.73,.83,.89,.31,1.74,.7,2.52,1.15,.78,.46,1.47,1.01,1.96,1.67l.17,.26v.02h0v-.02h0s0,0,.01,.02c0,.03,.02,.05,.03,.08,.01,.07,.03,.13,.03,.22,0,.04,0,.08,0,.12v.13s-.02,.09-.02,.13l-.03,.14c-.02,.09-.03,.19-.08,.28-.04,.1-.07,.19-.1,.29-.04,.1-.09,.19-.14,.29-.39,.78-1.01,1.51-1.67,2.2-.68,.67-1.42,1.32-2.19,1.93-.77,.62-1.58,1.18-2.38,1.78,.46-.19,.91-.4,1.37-.6,.11-.05,.23-.1,.34-.16l.33-.17,.67-.35,.34-.17c.11-.06,.22-.13,.32-.19l.65-.39c.11-.07,.22-.13,.32-.2l.31-.22,.62-.45c.8-.65,1.59-1.35,2.2-2.29,.07-.12,.15-.23,.22-.36Z"/>
		<polygon points="61.7 24.59 61.7 24.59 61.7 24.58 61.7 24.59"/>
		<path d="M68.43,22.99c-.2-.48-.48-.91-.79-1.29-.31-.37-.66-.7-1.02-.99-.73-.58-1.51-1.02-2.32-1.38-.81-.36-1.63-.64-2.47-.86-.84-.21-1.69-.38-2.56-.42,1.53,.8,3.03,1.56,4.41,2.45,.69,.44,1.34,.91,1.91,1.43,.57,.52,1.04,1.1,1.29,1.7,.13,.3,.2,.61,.2,.92,0,.31-.05,.63-.16,.96-.11,.33-.27,.65-.47,.97-.1,.16-.2,.32-.32,.47-.11,.16-.24,.31-.36,.47-.51,.61-1.1,1.19-1.71,1.76-.61,.57-1.26,1.12-1.9,1.7,.83-.27,1.62-.62,2.38-1.03,.76-.42,1.5-.91,2.18-1.51,.67-.6,1.3-1.32,1.7-2.26,.2-.46,.32-.99,.33-1.52,.01-.54-.11-1.08-.3-1.56Z"/>
	</svg>
);

export default SvgJba;
