import React, { Component } from "react";
import Compatible from "../../_svg/Compatible";
import PlugNPlay from "../../_svg/PlugNPlay";

import ImgRecap from "../../_resources/image/mbox_box_mockup.png";

export default class Recap extends Component<any, any>{
    constructor(props: Readonly<any>) {
        super(props);
    }

    render() {
        const { t } = this.props
        const sections1 = [
            {
                title: t('produits.mbox.plug'),
                description: t('produits.mbox.plug.description'),
                svg: <Compatible height={"3em"} fill={"#1f8491"}/>
            },
            {
                title: t('produits.mbox.raise.wmbus'),
                description: t('produits.mbox.raise.wmbus.description'),
                svg: <PlugNPlay height={"3em"} fill={"#1f8491"}/>
            },
        ]

        const content = {
            title: t('all.recap')
        }
        return (
            <div className={"footer mbox"}>
                <div className={"image-container"}>
                <img src={ImgRecap} alt="mbox"/>
                    <h2>{content.title}</h2>
                </div>
                <div className={"footer-section"}>
                    {sections1.map((el:any) => (
                        <div className={"section"}>
                            {el.svg}
                            <h5>{el.title}</h5>
                            <span>{el.description}</span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}