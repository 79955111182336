import React, { useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import SvgEarth from "../../_svg/Earth";
import { useMediaQuery } from "react-responsive";
import { withTranslation } from "react-i18next"

const App = (props) => {
  const { t, lowDebit } = props;
  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });

  useEffect(() => {
    const svg = document.getElementById("svg-earth-onyx");
    if (svg && !lowDebit && !isTablet) {
      svg.style.setProperty("left", `120%`, "important");
      svg.style.setProperty("inset", `20% 120%`, "important");
      svg.style.setProperty("top", "20%", "important");
    }
    if(lowDebit && !isTablet){
      svg.style.setProperty("left", `initial`, "important");
      svg.style.setProperty("inset", `initial`, "important");
      svg.style.setProperty("top", "initial", "important");
    }
  });
  window.addEventListener("scroll", () => {
    const svg = document.getElementById("svg-earth-onyx");
    if (svg && !lowDebit && !isTablet) {
      if (window.scrollY / 50 <= 60) {
        svg.style.setProperty(
          "left",
          `${120 - window.scrollY / 50}%`,
          "important"
        );
        svg.style.setProperty(
          "inset",
          `20% ${120 - window.scrollY / 50}%`,
          "important"
        );
        svg.style.position = "absolute";
      } 
    }
    else{
      if(svg){
        svg.style.setProperty("left", `initial`, "important");
        svg.style.setProperty("inset", `initial`, "important");
        svg.style.setProperty("top", "initial", "important");
        svg.style.position = "relative"; 
      }
    }
  });
  return (
    <div
      className={"svg-container"}
      id={isTablet ? "svg-earth-tablet" : "svg-earth-onyx"}
      itemScope
      itemType="https://schema.org/WebPage"
    >
      <>
        {!isTablet && (
          <div style={{ display: "flex", justifyContent: "left" }}>
            <SvgEarth width={"5em"} fill={"#06d0e7"} />
          </div>
        )}
<h3 itemProp="alternativeHeadline">{t('applications.onyx.fluid')}</h3>
        <span itemProp="description" style={{width: (lowDebit && !isTablet) ? "100%" : "90%"}}>
          {t('applications.onyx.fluid.description')}
        </span>
      </>
    </div>
  );
};

const tr = withTranslation()(App);
export default tr;
