import React, { Component } from "react";
import { Link } from "react-router-dom"
import SvgLogoDioptase from "../../_svg/LogoDioptase";
import SvgLogiciel from "../../_svg/Logiciel";
import SvgApplication from "../../_svg/Logiciels";
import SvgProduit from "../../_svg/Produit";
import SvgMateriel from "../../_svg/Materiel";
import SvgContact from "../../_svg/Contact";
import SvgSupport from "../../_svg/Casque";
import SvgLinkedin from '../../_svg/Linkedin';

import { withTranslation } from "react-i18next";
import moment from "moment";

class FooterSection extends Component<any, any> {
  render() {
    const { t } = this.props;
    const content = {
      logiciels: t("all.software"),
      applications: t("all.applications"),
      produits: t("all.products"),
      materiels: t("all.materials"),
      contact: t("all.contact"),
      support: t("all.support")
    };

    const currentYear = moment().year()

    return (
      <>
        <div className="footer-logiciels" itemScope itemType="https://schema.org/CreativeWork">
          <div style={{ display: "flex" }} itemProp="about">
            <div className="container left">
              <SvgLogoDioptase height="2em" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5%",
                }}
                className="adresse"
              >
                <span>2 rue du plat d'étain</span>
                <span>37000 tours</span>
              </div>
            </div>
            <div className="container right">
              <ul>
                <li>
                  <a href="/logiciels" itemProp="url">
                    <SvgLogiciel height="1em" />
                    {content.logiciels}
                  </a>
                </li>
                <li>
                  <a href="/applications" itemProp="url">
                    <SvgApplication height="1em" />
                    {content.applications}
                  </a>
                </li>
                <li>
                  <a href="/produits" itemProp="url">
                    <SvgProduit height="1em" />
                    {content.produits}
                  </a>
                </li>
                <li>
                  <a href="/materiels" itemProp="url">
                    <SvgMateriel height="1em" />
                    {content.materiels}
                  </a>
                </li>
                <li>
                  <a href="/support" itemProp="url">
                    <SvgSupport height="1em" />
                    {content.support}
                  </a>
                </li>
                <li>
                  <a href="/contact" itemProp="url">
                    <SvgContact height="1em" />
                    {content.contact}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="social-network">
          <div className="linkedin">
            <a href="https://www.linkedin.com/company/dioptase/" target="_blank">
              <SvgLinkedin />
            </a>
          </div>
        </div>
        <div className="copyright" itemProp="about">
              <span>Dioptase© 1994 - {currentYear}</span>
              <div className="separator" />
              <Link to="/sitemap">
                <span>Plan du site</span>
              </Link>
        </div>
      </>
    );
  }
}

const tr = withTranslation()(FooterSection);
export default tr;
