import React, { Component } from "react";
import { useMediaQuery } from "react-responsive";

import { Button } from "reactstrap";

import SmartTab from "../../_svg/Tablette_Smartphone";

import TxtMateriels from "../../_resources/image/txt_materiels.png";

const Comparateur = (props) => {
  const { t } = props;
  const content = {
    button: t("materials.comparator"),
  };
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div className="comparateur-container">
      {!isMobile && (
        <div className="section un">
          <img src={TxtMateriels} alt="materiels"/>
        </div>
      )}
      <div className="section deux">
        <div className="comparateur-btn">
          <SmartTab height="5em" />
          <a href="/materiels/comparateur">
            <Button>{content.button}</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Comparateur;
