import React from 'react';

const SvgCross = (props: any) => (
<svg viewBox="0 0 32 32" {...props}>
	<g id="cross">
		<line fill={props.fill} strokeLinejoin="round" strokeLinecap="round" stroke="#000" strokeWidth="2px" x1="7" x2="25" y1="7" y2="25"/>
		<line fill={props.fill} strokeLinejoin="round" strokeLinecap="round" stroke="#000" strokeWidth="2px" x1="7" x2="25" y1="25" y2="7"/>
	</g>
</svg>
);

export default SvgCross;
