import mailConstants from './mail.constants';
export default function mail(state = {}, action) {
    switch (action.type) {
        case mailConstants.SEND_MAIL_REQUEST:
            return {
                ...state,
                success: false,
                pending: true,
            };
        case mailConstants.SEND_MAIL_SUCCESS:
            return {
                mail:action.mail,
                success: true,
                pending: false,
            };

        case mailConstants.SEND_MAIL_FAILURE:
            return {
                error: "Impossible d'envoyer le mail",
                pending: false,
            };
        case mailConstants.CLEAR:
            return {};
        default:
            return state;
    }
}