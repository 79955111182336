import React from 'react';

const SvgConcat = (props: any) => (
	<svg viewBox="0 0 24 15.82" {...props}>
		<path fill={props.fill}
			  d="m14.8,7.08V2.89c0-.24-.2-.44-.45-.44h-1.56V.62c0-.14-.07-.27-.18-.35-.11-.09-.26-.11-.39-.07l-7.11,2.21c-.1.03-.17.08-.23.15-.1.08-.16.2-.16.34v10.93c0,.24.2.44.45.44h5.67c.83,1.05,2.12,1.73,3.58,1.73,2.5,0,4.52-2,4.52-4.46,0-2.33-1.82-4.25-4.13-4.45m-2.91-5.88v1.25h-4.49l4.49-1.25Zm2.52,14.49c-1.27,0-2.41-.56-3.18-1.43-.65-.73-1.04-1.69-1.04-2.73,0-2.3,1.89-4.16,4.22-4.16.13,0,.26,0,.39.02,2.14.19,3.83,1.98,3.83,4.15,0,2.29-1.89,4.16-4.22,4.16"/>
		<path fill={props.fill}
			  d="m16.44,9.83h-4.05s-.07.03-.07.07v.75s.04.07.07.07h4.05s.07-.04.07-.07v-.75s-.03-.07-.07-.07"/>
		<path fill={props.fill}
			  d="m16.44,11.08h-4.05s-.07.03-.07.07v.75s.04.07.07.07h4.05s.07-.04.07-.07v-.75s-.03-.07-.07-.07"/>
		<path fill={props.fill}
			  d="m16.44,12.34h-4.05s-.07.03-.07.07v.75s.04.07.07.07h4.05s.07-.04.07-.07v-.75s-.03-.07-.07-.07"/>
	</svg>
)

export default SvgConcat;
