import React from "react";

const ConsoData = (props) => {
	return (
		<svg id="Calque_1" viewBox="0 0 95 38.2" {...props}>
<g>
	<g>
		<path fill={props.fill} d="M2,23c0.7-0.6,1.4-1.1,2.1-1.8c0.6-0.6,1.3-1.3,2-1.9c1.4-1.2,2.8-2.4,4.2-3.5c2.9-2.2,6.1-4.2,9.4-5.9
			c3.1-1.7,6.4-3.2,9.9-4c3.2-0.7,6.8-0.9,9.7,0.9c4.8,3,4.1,9.7,6.2,14.3c1.1,2.4,3.2,4.3,5.9,4.8c3,0.5,6-0.5,8.7-1.8
			c3.3-1.6,6.4-3.8,9.5-5.9c3.3-2.2,6.5-4.6,9.6-7.1c0.8-0.6,1.5-1.3,2.3-1.9c0.3-0.2,0.3-0.7,0.2-1c-1.8-2.6-3.6-5.3-5.4-7.9
			c-0.3,0.4-0.6,0.8-1,1.2c6.3,2.2,12.6,4.3,18.8,6.4c-0.2-0.2-0.4-0.5-0.6-0.7c-0.8,5.6-1.4,11.2-2.4,16.7c0.5,0,1,0,1.5,0
			c-1.2-3.2-3.3-6-6.1-8c-0.3-0.2-0.8-0.1-1.1,0.1c-5.8,4.8-11.9,9.2-18.4,13.2c-3,1.8-6,3.7-9.2,5.1c-2.9,1.3-6.1,2.2-9.3,2.1
			c-2.9-0.1-6-1.2-8.1-3.2c-2.1-2-2.7-4.7-3.2-7.4c-0.4-2.4-0.8-5.1-2.3-7.1c-1.9-2.4-5-2.8-7.9-2.1c-3.6,0.9-7,2.8-10,4.8
			c-3.2,2.2-6.1,4.7-9,7.2c0.4,0,0.8,0,1.2,0c-2.6-2.2-5.2-4.4-7.9-6.6c-0.4-0.3-0.9-0.4-1.3,0c-0.3,0.3-0.4,1,0,1.3
			c2.6,2.2,5.3,4.3,7.9,6.5c0.4,0.3,0.8,0.4,1.2,0c5-4.4,10.3-9,16.7-11.1c2.6-0.9,5.9-1.3,7.7,1.2c1.4,2,1.6,4.7,2,7
			c0.5,2.8,1.4,5.4,3.5,7.4c2.3,2.2,5.3,3.3,8.4,3.7c6.6,0.7,12.8-2.9,18.2-6.1c7.3-4.4,14.2-9.2,20.8-14.5c-0.4,0-0.7,0.1-1.1,0.1
			c2.5,1.9,4.3,4.3,5.5,7.1c0.4,0.8,1.4,0.3,1.5-0.4c0.3-2.8,0.7-5.6,1.1-8.5c0.4-2.8,0.8-5.6,1.2-8.5c0-0.3-0.3-0.6-0.6-0.7
			C88.2,4.4,82,2.2,75.7,0c-0.7-0.2-1.4,0.6-1,1.2c1.8,2.6,3.6,5.3,5.5,7.9c0-0.3,0.1-0.7,0.2-1c-3.1,2.6-6.2,5-9.5,7.3
			c-3.2,2.3-6.4,4.5-9.8,6.4c-2.5,1.5-5.5,2.8-8.5,2.7c-2.8-0.1-4.8-1.9-5.8-4.4c-1-2.4-1.3-5-2-7.5c-0.7-2.7-1.9-5.3-4.3-7
			c-2.8-1.9-6.3-2.1-9.5-1.6C27.3,4.6,24,6,20.8,7.6c-3.3,1.6-6.4,3.5-9.4,5.7c-3,2.2-5.8,4.5-8.3,7.3c-0.6,0.6-1.1,1.4-1.6,2.1
			C1.2,22.9,1.7,23.3,2,23L2,23z"/>
	</g>
	<g>
		<path fill={props.fill} d="M6.8,19.9c0.6-0.7,1.2-1.5,1.8-2.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.9,1.3-1.8,2.7-2.7,4.1
			c-0.8,1.3-1.8,2.6-2.4,4c0,0.1,0.1,0.2,0.2,0.1c1.1-1.1,1.8-2.6,2.6-3.9c0.9-1.4,1.7-2.7,2.5-4.1c0.1-0.2-0.2-0.3-0.3-0.2
			C7.8,18.3,7.3,19.1,6.8,19.9C6.7,19.9,6.8,20,6.8,19.9L6.8,19.9z"/>
	</g>
	<g>
		<path fill={props.fill} d="M12.3,14.9c-1.2,1.9-2.3,3.9-3.4,5.8c-0.5,0.9-1.1,1.9-1.6,2.8c-0.5,0.9-1.2,1.9-1.5,2.9
			c0,0.1,0.1,0.2,0.2,0.1c0.7-0.8,1.2-1.8,1.7-2.8c0.5-0.9,1.1-1.9,1.6-2.8c1.1-2,2.2-3.9,3.2-5.9C12.7,14.9,12.4,14.8,12.3,14.9
			L12.3,14.9z"/>
	</g>
	<g>
		<path fill={props.fill} d="M17.7,11.7c0.3-0.3,0.6-0.7,1-1c-0.1-0.1-0.1-0.1-0.2-0.2c-1.6,2.8-3.1,5.6-4.8,8.2c-0.8,1.3-1.7,2.6-2.6,3.9
			c-0.9,1.3-2,2.6-2.9,3.9c-0.1,0.2,0.2,0.5,0.4,0.3c1.1-1.1,1.9-2.5,2.8-3.7c0.9-1.3,1.8-2.7,2.7-4c1.7-2.7,3.4-5.5,4.7-8.5
			c0.1-0.2-0.1-0.3-0.2-0.1C18.2,10.9,17.9,11.2,17.7,11.7C17.5,11.7,17.6,11.7,17.7,11.7L17.7,11.7z"/>
	</g>
	<g>
		<path fill={props.fill} d="M24.8,7.6c-1.3,2.7-2.8,5.2-4.5,7.7c-0.9,1.3-1.8,2.5-2.7,3.7c-0.9,1.1-2,2.1-2.8,3.3
			c-0.1,0.1,0.1,0.2,0.2,0.2c1.1-0.8,2-2.1,2.9-3.1c0.9-1.2,1.8-2.3,2.7-3.6c1.7-2.5,3.2-5.2,4.4-8C25.1,7.6,24.9,7.5,24.8,7.6
			L24.8,7.6z"/>
	</g>
	<g>
		<path fill={props.fill} d="M29.8,5.9c-1.3,2.2-2.7,4.3-4.3,6.3c-0.8,1-1.6,2.1-2.5,3.1c-0.8,0.9-1.8,1.8-2.5,2.7
			c-0.2,0.2,0.2,0.4,0.3,0.3c1-0.8,1.8-1.9,2.7-2.8c0.8-0.9,1.6-1.9,2.3-2.9c1.6-2.1,3-4.3,4.2-6.5C30.1,5.9,29.9,5.8,29.8,5.9
			L29.8,5.9z"/>
	</g>
	<g>
		<path fill={props.fill} d="M33.4,5.7c-1.1,2.1-2.3,4.2-3.8,6.1c-0.7,1-1.6,1.9-2.4,2.8c-0.4,0.4-0.8,0.8-1.2,1.2
			c-0.4,0.4-0.9,0.7-1.2,1.2c-0.1,0.1,0.1,0.3,0.2,0.2c0.5-0.3,0.9-0.7,1.3-1.1c0.5-0.4,0.9-0.9,1.3-1.3c0.8-0.8,1.5-1.7,2.2-2.7
			c1.5-2,2.8-4.1,3.8-6.4C33.7,5.7,33.4,5.5,33.4,5.7L33.4,5.7z"/>
	</g>
	<g>
		<path fill={props.fill} d="M37.8,6.6c-1.4,1.9-2.9,3.7-4.5,5.5c-0.8,0.9-1.5,1.7-2.3,2.6c-0.7,0.8-1.6,1.5-2.2,2.4
			c-0.1,0.1,0.1,0.3,0.2,0.2c1-0.6,1.7-1.6,2.5-2.4c0.8-0.9,1.5-1.7,2.3-2.6c1.5-1.8,2.9-3.6,4.3-5.5C38.2,6.6,37.9,6.4,37.8,6.6
			L37.8,6.6z"/>
	</g>
	<g>
		<path fill={props.fill} d="M39.8,9c-1.3,1.7-2.7,3.2-4.1,4.7c-1.4,1.4-3,2.7-4.6,4c-0.2,0.2,0.1,0.5,0.3,0.4c3.4-2.3,6.3-5.6,8.8-8.9
			C40.3,9,40,8.8,39.8,9L39.8,9z"/>
	</g>
	<g>
		<path fill={props.fill} d="M42.3,11.7c-1.3,1.3-2.7,2.6-4,3.9c-0.7,0.6-1.3,1.3-2,1.9c-0.6,0.6-1.3,1.2-1.9,1.9
			c-0.2,0.2,0.2,0.4,0.3,0.3c0.7-0.5,1.3-1.2,2-1.9c0.7-0.6,1.3-1.3,2-1.9c1.3-1.3,2.6-2.6,3.9-3.8C42.8,11.8,42.5,11.5,42.3,11.7
			L42.3,11.7z"/>
	</g>
	<g>
		<path fill={props.fill} d="M44.1,14.4c-1.7,1.1-3.4,2.3-4.9,3.7c-1.4,1.4-2.9,3-3.9,4.8c-0.1,0.2,0.2,0.5,0.3,0.3
			c1.3-1.5,2.4-3.1,3.8-4.6c1.4-1.5,3-2.7,4.8-3.9C44.5,14.6,44.3,14.2,44.1,14.4L44.1,14.4z"/>
	</g>
	<g>
		<path fill={props.fill} d="M45.4,18.1c-1.4,1-2.8,2-4.2,3.1c-1.3,1.1-2.7,2.2-3.8,3.6c-0.2,0.2,0.2,0.4,0.3,0.3c1.3-1,2.4-2.3,3.7-3.4
			c1.3-1.1,2.7-2.2,4.1-3.2C45.7,18.3,45.6,17.9,45.4,18.1L45.4,18.1z"/>
	</g>
	<g>
		<path fill={props.fill} d="M46.6,20.2c-1.8,1.4-3.5,2.9-5.2,4.3c-0.8,0.7-1.7,1.4-2.5,2.1c-0.8,0.7-1.8,1.3-2.5,2.2
			c-0.1,0.2,0.1,0.3,0.2,0.3c1-0.5,1.8-1.4,2.6-2.1c0.8-0.7,1.7-1.4,2.5-2.1c1.7-1.5,3.4-2.9,5.1-4.4C47.1,20.3,46.8,20,46.6,20.2
			L46.6,20.2z"/>
	</g>
	<g>
		<path fill={props.fill} d="M46.7,23.7c-1.5,1.3-3,2.5-4.4,3.8c-0.7,0.6-1.4,1.2-2.2,1.9c-0.7,0.6-1.6,1.3-2.2,2c-0.1,0.2,0,0.5,0.3,0.4
			c0.8-0.5,1.5-1.2,2.2-1.8c0.7-0.7,1.5-1.3,2.2-2c1.5-1.3,3-2.6,4.5-4C47.2,23.8,46.9,23.5,46.7,23.7L46.7,23.7z"/>
	</g>
	<g>
		<path fill={props.fill} d="M48.1,26.3c-1.5,1.1-3,2.3-4.6,3.4c-0.7,0.6-1.5,1.1-2.2,1.7c-0.8,0.6-1.6,1.1-2.3,1.8
			c-0.2,0.2,0,0.5,0.3,0.3c0.8-0.4,1.5-1.1,2.2-1.6c0.8-0.6,1.5-1.2,2.3-1.8c1.5-1.2,3-2.4,4.6-3.6C48.7,26.5,48.4,26.2,48.1,26.3
			L48.1,26.3z"/>
	</g>
	<g>
		<path fill={props.fill} d="M50.6,26.7c-1.6,1.3-3.1,2.6-4.7,4c-1.5,1.3-3.2,2.6-4.6,4c-0.2,0.2,0,0.5,0.2,0.4c0.3-0.1,0.5-0.2,0.8-0.3
			c0.1,0,0.1-0.3,0-0.3c-0.3,0-0.5,0-0.8,0c0.1,0.1,0.1,0.3,0.2,0.4c1.6-1.2,3.1-2.7,4.7-4c1.5-1.3,3.1-2.7,4.6-4.1
			C51.1,26.8,50.8,26.5,50.6,26.7L50.6,26.7z"/>
	</g>
	<g>
		<path fill={props.fill} d="M53.9,25.8c-1.4,1.7-2.8,3.4-4.4,5c-0.7,0.8-1.4,1.5-2.2,2.2c-0.4,0.4-0.8,0.8-1.2,1.2
			c-0.4,0.3-0.8,0.6-1,1.1c0,0.1,0,0.3,0.2,0.2c0.9-0.3,1.6-1.2,2.3-1.9c0.8-0.8,1.6-1.6,2.4-2.5c1.5-1.6,2.9-3.4,4.2-5.2
			C54.4,25.8,54.1,25.6,53.9,25.8L53.9,25.8z"/>
	</g>
	<g>
		<path fill={props.fill} d="M57.2,25.3c-1.5,2-3.1,3.9-4.7,5.7c-0.8,0.9-1.7,1.7-2.5,2.6c-0.8,0.8-1.8,1.5-2.4,2.4
			c-0.1,0.1,0.1,0.3,0.2,0.2c1.1-0.6,1.9-1.5,2.8-2.4c0.9-0.8,1.7-1.7,2.5-2.6c1.6-1.8,3.1-3.7,4.5-5.7
			C57.7,25.3,57.3,25.1,57.2,25.3L57.2,25.3z"/>
	</g>
	<g>
		<path fill={props.fill} d="M60.9,24.6c-1.6,1.9-3.1,3.7-4.7,5.6c-1.5,1.8-3.2,3.6-4.5,5.5c-0.2,0.2,0.2,0.5,0.4,0.3
			c0.8-0.8,1.5-1.8,2.3-2.7c0.8-0.9,1.6-1.9,2.3-2.8c1.5-1.9,3-3.7,4.6-5.6C61.4,24.7,61.1,24.3,60.9,24.6L60.9,24.6z"/>
	</g>
	<g>
		<path fill={props.fill} d="M63.2,22.9c-0.6,2.1-1.4,4.2-2.5,6.1c-0.6,1-1.2,1.9-1.9,2.7c-0.6,0.8-1.5,1.4-2,2.3
			c-0.1,0.1,0.1,0.3,0.2,0.2c0.9-0.5,1.7-1.4,2.3-2.3c0.7-0.9,1.3-1.8,1.9-2.7c1.1-1.9,1.9-4,2.4-6.2C63.6,22.8,63.2,22.7,63.2,22.9
			L63.2,22.9z"/>
	</g>
	<g>
		<path fill={props.fill} d="M68.5,18.5c-1.3,2.2-2.6,4.4-3.9,6.7c-0.6,1.1-1.2,2.2-1.8,3.3c-0.6,1.1-1.4,2.2-1.8,3.3
			c-0.1,0.2,0.2,0.3,0.3,0.2c0.8-1,1.3-2.2,1.9-3.3c0.6-1.1,1.2-2.2,1.8-3.3c1.3-2.3,2.5-4.5,3.7-6.8C68.8,18.5,68.6,18.4,68.5,18.5
			L68.5,18.5z"/>
	</g>
	<g>
		<path fill={props.fill} d="M71.2,16.6C70,18.7,69,20.9,67.9,23c-0.5,1-1,2.1-1.6,3.1c-0.5,1.1-1.1,2.1-1.5,3.2c-0.1,0.2,0.3,0.4,0.4,0.2
			c0.6-1,1.1-2.1,1.6-3.1c0.5-1.1,1.1-2.2,1.6-3.2c1-2.1,2.1-4.2,3.1-6.4C71.6,16.6,71.3,16.4,71.2,16.6L71.2,16.6z"/>
	</g>
	<g>
		<path fill={props.fill} d="M73.3,14.2c0-0.1,0-0.1,0.2,0c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c-0.2,0.6-0.4,1.1-0.6,1.7
			c-0.4,1.1-0.9,2.3-1.3,3.4c-0.8,2.2-1.8,4.5-2.4,6.8c-0.1,0.2,0.3,0.4,0.4,0.2c0.8-1.6,1.3-3.3,1.9-5c0.7-1.8,1.3-3.6,1.9-5.3
			c0.3-0.8,0.7-1.6,0.8-2.3c0.1-0.3,0-0.8-0.4-0.9c-0.5-0.1-0.8,0.7-0.3,0.7C73.4,14.4,73.4,14.2,73.3,14.2L73.3,14.2z"/>
	</g>
	<g>
		<path fill={props.fill} d="M75.1,12.7c-0.2,2.1-0.5,4.2-1.1,6.3c-0.2,1-0.5,1.9-0.9,2.9c-0.4,1-0.8,2-1.1,3c-0.1,0.2,0.2,0.3,0.3,0.1
			c1.1-1.8,1.7-4,2.2-6c0.5-2.1,0.8-4.2,0.9-6.3C75.4,12.5,75.1,12.5,75.1,12.7L75.1,12.7z"/>
	</g>
	<g>
		<path fill={props.fill} d="M78,10.8c-0.7,1.8-1.3,3.6-1.9,5.5c-0.3,0.9-0.6,1.7-0.9,2.6c-0.3,0.9-0.7,1.8-0.8,2.7c0,0.1,0.2,0.2,0.3,0.1
			c0.4-0.8,0.6-1.7,0.9-2.5c0.3-0.9,0.6-1.8,0.9-2.7c0.6-1.8,1.2-3.7,1.8-5.5C78.3,10.7,78.1,10.7,78,10.8L78,10.8z"/>
	</g>
	<g>
		<path fill={props.fill} d="M81.2,6.7c-0.5,2.6-1.1,5.1-1.8,7.6c-0.4,1.2-0.7,2.4-1.2,3.6c-0.5,1.3-1,2.5-1.4,3.8
			c-0.1,0.2,0.2,0.3,0.3,0.1c0.6-1.1,1-2.4,1.5-3.6c0.4-1.3,0.8-2.6,1.2-3.8c0.7-2.5,1.2-5.1,1.6-7.7C81.4,6.6,81.2,6.6,81.2,6.7
			L81.2,6.7z"/>
	</g>
	<g>
		<path fill={props.fill} d="M84.3,3.2c-0.7,2.7-1.5,5.4-2.5,8c-0.5,1.3-0.9,2.6-1.4,3.9c-0.5,1.3-1.2,2.6-1.5,3.9c0,0.1,0.1,0.2,0.2,0.1
			c0.8-1.1,1.2-2.5,1.7-3.7c0.5-1.3,1-2.6,1.5-4c0.9-2.7,1.7-5.4,2.3-8.1C84.6,3.1,84.3,3,84.3,3.2L84.3,3.2z"/>
	</g>
	<g>
		<path fill={props.fill} d="M86.9,4.9c-0.9,2.3-1.8,4.6-2.6,6.9c-0.4,1.2-0.7,2.3-1.1,3.5c-0.3,1.1-0.7,2.2-0.9,3.4
			c0,0.2,0.3,0.2,0.4,0.1c0.5-1.1,0.7-2.4,1-3.5c0.3-1.1,0.7-2.3,1-3.4c0.7-2.3,1.5-4.6,2.4-6.9C87.3,4.8,87,4.7,86.9,4.9L86.9,4.9z
			"/>
	</g>
	<g>
		<path fill={props.fill} d="M89.1,6.7c-0.5,2-0.9,4.1-1.4,6.1c-0.2,1-0.4,2-0.6,3c-0.2,1-0.5,2-0.6,3c0,0.3,0.4,0.3,0.4,0.1
			c0.3-1,0.5-2,0.7-2.9c0.2-1,0.4-2.1,0.6-3.1c0.4-2,0.8-4,1.2-6.1C89.5,6.6,89.1,6.5,89.1,6.7L89.1,6.7z"/>
	</g>
	<g>
		<path fill={props.fill} d="M91.6,7.1c0.1,2.3-0.1,4.5-0.7,6.7c-0.3,1.1-0.6,2.1-1,3.2c-0.4,1-1,2-1.3,3c-0.1,0.2,0.2,0.3,0.3,0.2
			c0.7-0.8,1.1-1.9,1.4-2.9c0.4-1.1,0.8-2.2,1-3.4c0.5-2.3,0.7-4.6,0.5-6.9C92,6.9,91.6,6.9,91.6,7.1L91.6,7.1z"/>
	</g>
	<g>
		<path fill={props.fill} d="M80.5,3.3c-0.2,0.9-0.3,1.9-0.5,2.8c-0.1,0.8-0.4,1.8-0.3,2.6c0,0.1,0.2,0.2,0.3,0.1c0.3-0.8,0.4-1.7,0.5-2.5
			c0.1-1,0.3-2,0.4-2.9C80.9,3.1,80.5,3,80.5,3.3L80.5,3.3z"/>
	</g>
	<g>
		<path fill={props.fill} d="M79.2,3.3c0.2,0.9,0,1.7-0.3,2.6C78.8,6,79,6.1,79.1,6c0.6-0.8,0.7-1.9,0.5-2.8C79.5,2.9,79.1,3,79.2,3.3
			L79.2,3.3z"/>
	</g>
</g>
</svg>
	)
}

export default ConsoData;