import React, { Component } from "react";
import { connect } from "react-redux";
import docActions from "../../redux/doc/doc.action"
import DocumentationImg from "../../_resources/image/catalogue_mbox.png";

class Doc extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      transition: 0,
    };
  }

  downloadFile = () => {
    const { dispatch } = this.props
    dispatch(docActions.getDoc("mbox", "fr"))
  }

  render() {
    const { noConnexion, t } = this.props;
    const content = {
      button: t('all.button.download')
    }
    return (
      <div className={"documentation-container"} style={{ zIndex: 1 }}>
        <div className={"catalogue"}>
          <img src={DocumentationImg} height={`${window.innerHeight / 2}px`} alt="documentation"/>
        </div>
        <div className={"download-documentation"}>
          <div className={"button-download"}>
    <button className="matomo_download" onClick={this.downloadFile}>{content.button}</button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { doc } = state;

  return {
    doc,
  };
}

const connectedDoc = connect(mapStateToProps)(Doc);
export default connectedDoc;
