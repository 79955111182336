import React from "react";

const ResiliationContrat = (props) => {
    return (
<svg id="Calque_1" viewBox="0 0 60.22 51.18" {...props}>
	<g>
		<path fill={props.fill} d="M6.63,41.34V9.44H2.81V45.16H28.99c-1.02-1.15-1.89-2.43-2.59-3.82H6.63Z"/>
		<path fill={props.fill} d="M28.99,45.16H2.81V9.44h3.82v31.89H26.4c-.45-.9-.83-1.84-1.13-2.81H9.44V2.81H28.44v7.38h7.38v6.53c.9-.36,1.84-.64,2.81-.85V6.44L32.18,0H6.63V6.63H0V47.97H31.99v-.11c-1.1-.79-2.11-1.69-3-2.71ZM31.25,3.04l4.33,4.33h-4.33V3.04Z"/>
		<rect fill={props.fill} x="13.95" y="14.16" width="17.49" height="2.61"/>
		<path fill={props.fill} d="M30.42,20.05H13.95v2.61h14.08c.71-.95,1.51-1.82,2.39-2.61Z"/>
		<path fill={props.fill} d="M13.95,25.95v2.61h11.2c.25-.9,.57-1.78,.95-2.61H13.95Z"/>
		<path fill={props.fill} d="M24.57,31.83H13.95v2.61h10.59c-.02-.37-.04-.74-.04-1.12,0-.5,.03-1,.07-1.49Z"/>
		<path fill={props.fill} d="M27.33,28.56h-2.18c-.29,1.05-.49,2.15-.58,3.27h2.1c.11-1.13,.33-2.22,.66-3.27Z"/>
		<path fill={props.fill} d="M42.36,15.47c-1.28,0-2.53,.14-3.73,.39v2.16c1.19-.29,2.43-.44,3.71-.44,8.7,0,15.74,7.05,15.74,15.74s-7.05,15.74-15.74,15.74c-3.96,0-7.58-1.47-10.35-3.88v2.67c2.92,2.09,6.5,3.32,10.37,3.32,9.86,0,17.86-7.99,17.86-17.86s-7.99-17.86-17.86-17.86Z"/>
		<path fill={props.fill} d="M30.76,22.67h-2.72c-.76,1.02-1.4,2.12-1.93,3.28h2.33c.63-1.19,1.42-2.3,2.33-3.28Z"/>
		<path fill={props.fill} d="M26.64,34.45h-2.1c.09,1.41,.34,2.78,.73,4.08h2.2c-.45-1.29-.74-2.66-.84-4.08Z"/>
		<path fill={props.fill} d="M31.44,20.05v1.92c1.27-1.22,2.75-2.23,4.38-2.98v-2.29c-2,.79-3.82,1.92-5.39,3.34h1.02Z"/>
		<path fill={props.fill} d="M26.4,41.34c.7,1.39,1.57,2.67,2.59,3.82h.19v-3.19c-.14-.21-.27-.42-.4-.64h-2.39Z"/>
		<path fill={props.fill} d="M27.48,38.53h-2.2c.3,.98,.68,1.91,1.13,2.81h2.39c-.52-.88-.96-1.83-1.31-2.81Z"/>
		<path fill={props.fill} d="M35.82,19c.9-.41,1.83-.74,2.81-.97v-2.16c-.97,.21-1.91,.49-2.81,.85v2.29Z"/>
		<path fill={props.fill} d="M29.18,41.97v3.19h-.19c.9,1.01,1.9,1.92,3,2.71v-2.67c-1.08-.94-2.02-2.02-2.81-3.22Z"/>
		<path fill={props.fill} d="M31.44,21.98v-1.92h-1.02c-.88,.79-1.68,1.67-2.39,2.61h2.72c.22-.24,.45-.47,.68-.69Z"/>
		<path fill={props.fill} d="M28.43,25.95h-2.33c-.38,.84-.7,1.71-.95,2.61h2.18c.29-.91,.66-1.78,1.1-2.61Z"/>
		<path fill={props.fill} d="M26.67,31.83h-2.1c-.04,.49-.07,.99-.07,1.49,0,.38,.02,.75,.04,1.12h2.1c-.03-.37-.04-.74-.04-1.12,0-.5,.03-1,.07-1.49Z"/>
	</g>
	<rect fill={props.fill} x="33.62" y="32.02" width="17.49" height="2.61" transform="translate(35.97 -20.19) rotate(45)"/>
	<rect fill={props.fill} x="33.62" y="32.02" width="17.49" height="2.61" transform="translate(95.88 26.94) rotate(135)"/>
</svg>
    )
}

export default ResiliationContrat;