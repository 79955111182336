import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { Input, UncontrolledTooltip } from "reactstrap";
import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import mailActions from "../../redux/mail/mail.action";
import Envoyer from "../../_svg/Envoyer";
import DropCv from "./DropCv";
import DropLdm from "./DropLdm";

import _ from "lodash";
import { useDropzone } from 'react-dropzone';

const Contact = (props) => {
  const { objet, t, dispatch, mail } = props;

  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [smail, setMail] = useState("");
  const [subject, setSubject] = useState(objet);
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState({
    cv: null,
    ldm: null,
  })
  const currentFiles = useRef(files)

  useEffect(() => {
    currentFiles.current = files
  }, [files])


  const [errors, setErrors] = useState([]);

  const content = {
    viaMail: t("contact.bymail"),
    send: t("contact.send"),
    viaPhone: t("contact.byphone"),
    telephoneNumber: "+33 (0)2 47 38 60 00",
    freeNumber: t("contact.free.number"),
  };

  const handleFileChange = (fileList, type) => {
    const copy = _.cloneDeep(currentFiles.current)
    console.log(fileList)
    if (fileList[0]) {
      if (type === 'cv') {
        copy.cv = fileList[0]
      } else {
        copy.ldm = fileList[0]
      }
      setFiles(copy)
    }
  }

  const formulaire = [
    t("contact.name"),
    t("contact.firstname"),
    t("contact.mail"),
    t("contact.object"),
    t("contact.message"),
  ];

  function sendMail() {
    const copyError = _.cloneDeep(errors);
    console.log(files)
    if (
      name.length > 0 &&
      firstname.length > 0 &&
      smail.length > 0 &&
      subject.length > 0 &&
      message.length > 0 &&
      files.cv && files.ldm
    ) {
      const newMail = {
        name,
        firstname,
        mail: smail,
        subject,
        message,
        files,
      };
      const formData = new FormData();

      formData.append(`files`, files.cv)
      formData.append(`files`, files.ldm)
      formData.append('name', name)
      formData.append('firstname', name)
      formData.append('mail', smail)
      formData.append('subject', subject)
      formData.append('message', message)

      var object = {};
      formData.forEach(function (value, key) {
        object[key] = value;
      });
      var json = JSON.stringify(object);
      console.log(json)
      dispatch(mailActions.sendWithAttachment(formData));
    }
    else {
      if (name && name.length === 0) {
        copyError.push(t("contact.name"));
      }
      if (firstname && firstname.length === 0) {
        copyError.push(t("contact.firstname"));
      }
      if (mail && mail.length === 0) {
        copyError.push(t("contact.mail"));
      }
      if (subject && subject.length === 0) {
        copyError.push(t("contact.object"));
      }
      if (message && message.length === 0) {
        copyError.push(t("contact.message"));
      }
      setErrors(copyError);
      if (name.length === 0 || firstname.length === 0 || smail.length === 0 || subject.length === 0 || message.length === 0) {
        window.alert(t("contact.error"));
      }
    }
  }

  function handleChange(e, type) {
    const copyError = _.cloneDeep(errors);
    switch (type) {
      case t("contact.name"):
        setName(e.target.value);
        break;
      case t("contact.firstname"):
        setFirstname(e.target.value);
        break;
      case t("contact.mail"):
        setMail(e.target.value);
        break;
      case t("contact.object"):
        setSubject(e.target.value);
        break;
      case t("contact.message"):
        setMessage(e.target.value);
        break;
    }
    if (type === t("contact.name") && e.target.value.length > 0) {
      copyError.filter((el) => el !== t("contact.name"));
    }
    if (type === t("contact.firstname") && e.target.value.length > 0) {
      copyError.filter((el) => el !== t("contact.firstname"));
    }
    if (type === t("contact.mail") && e.target.value.length > 0) {
      copyError.filter((el) => el !== t("contact.mail"));
    }
    if (type === t("contact.object") && e.target.value.length > 0) {
      copyError.filter((el) => el !== t("contact.object"));
    }
    if (type === t("contact.message") && e.target.value.length > 0) {
      copyError.filter((el) => el !== t("contact.message"));
    }
    setErrors(copyError);
  }

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });

  if (mail.success) {
    window.alert(`${t("contact.send.mail")}`);
    dispatch(mailActions.clear())
  }
  if (mail.error) {
    window.alert(`${t("contact.error.mail")}`)
    dispatch(mailActions.clear())
  }
  return (
    <div className="formulaire-container">
      {formulaire.map((el) => (
        <div
          style={{
            marginTop: el === t("contact.message") ? "5px" : "0",
            height:
              el === t("contact.message")
                ? isMobile
                  ? "fit-content"
                  : isTablet
                    ? "30%"
                    : "50%"
                : "auto",
          }}
        >
          {el === t("contact.message") ? (
            <div
              className="section"
              style={{
                borderBottom: "none",
                height: "100%",
              }}
            >
              <textarea
                className="form-control"
                placeholder={el}
                onChange={(e) => handleChange(e, el)}
                itemProp="subjectOf"
              />
            </div>
          ) : (
            <div
              className="section"
              style={{
                borderBottom: "1px solid lightgrey",
              }}
              itemProp="subjectOf"
            >
              <Input
                placeholder={el}
                invalid={errors.includes(el)}
                defaultValue={
                  el === t("contact.object") ? objet || "" : ""
                }
                style={{ backgroundColor: "white" }}
                disabled={objet ? el === t("contact.object") : false}
                onChange={(e) => handleChange(e, el)}
              />
            </div>
          )}
        </div>
      ))}
      <div className="file-drag">
        <DropCv handleChange={handleFileChange} />
        <DropLdm handleChange={handleFileChange} />
      </div>
      <div className="send-button" onClick={() => sendMail()}>
        <span><b>{t('contact.send')}</b></span>
      </div>
      <div className="triangle-container">
        <div className="triangle" />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { device, mail } = state;

  return {
    device,
    mail,
  };
}

const connectedContact = connect(mapStateToProps)(Contact);
const tr = withTranslation()(connectedContact);
export default tr;
