import React from 'react';

const SvgPoubelle = (props: any) => (
  <svg viewBox="0 0 17.13 17.11" {...props}>
    <g id="poubelle_bleu_svg__Calque_2" data-name="Calque 2">
      <g id="poubelle_bleu_svg__Calque_2-2" data-name="Calque 2">
        <path
          fill={props.fill}
          d="M.6 3.66h.67l1.55 12.92a.59.59 0 0 0 .6.53h10.29a.6.6 0 0 0 .6-.53l1.55-12.92h.66a.61.61 0 0 0 .61-.61.6.6 0 0 0-.61-.6H11.9V.6a.6.6 0 0 0-.61-.6H5.84a.6.6 0 0 0-.61.6v1.85H.6a.6.6 0 0 0-.6.6.61.61 0 0 0 .6.61zm5.84-2.45h4.25v1.24H6.44zm8.2 2.45l-1.46 12.26H4L2.49 3.66z"
        />
        <path
          fill={props.fill}
          d="M6.56 14.08a.6.6 0 0 0 .6-.61V6.24a.6.6 0 1 0-1.2 0v7.23a.6.6 0 0 0 .6.61zM10.57 14.08a.6.6 0 0 0 .6-.61V6.24a.6.6 0 0 0-.6-.61.61.61 0 0 0-.61.61v7.23a.62.62 0 0 0 .61.61z"
        />
      </g>
    </g>
  </svg>
);

export default SvgPoubelle;
