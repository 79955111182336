const mailConstants = {
    SEND_MAIL_REQUEST:'SEND_MAIL_REQUEST',
    SEND_MAIL_SUCCESS:'SEND_MAIL_SUCCESS',
    SEND_MAIL_FAILURE:'SEND_MAIL_FAILURE',

    SEND_MAIL_WITH_ATTACHMENT_REQUEST:'SEND_MAIL_WITH_ATTACHMENT_REQUEST',
    SEND_MAIL_WITH_ATTACHMENT_SUCCESS:'SEND_MAIL_WITH_ATTACHMENT_SUCCESS',
    SEND_MAIL_WITH_ATTACHMENT_FAILURE:'SEND_MAIL_WITH_ATTACHMENT_FAILURE',

    CLEAR: 'MAIL_CLEAR',
}

export default mailConstants