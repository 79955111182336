import React from 'react';

const SvgFleche = (props: any) => (
<svg version="1.1" id="Calque_1" viewBox="0 0 293.1 210.4" {...props}>
<g>
	<g>
		<path style={{fill: "none", stroke: "#CF782F", strokeWidth: 10, strokeLinecap: "round", strokeLinejoin: "round" }} d="M282.6,46.4c-0.8-0.2-1.6-0.4-2.4-0.6"/>
		<path style={{fill: "none", stroke: "#CF782F", strokeWidth: 10, strokeLinecap: "round", strokeLinejoin: "round", strokeDasharray: "4.9424,19.7694,4.9424,19.7694" }} d="M260.7,42c-11.1-1.9-24.5-3.6-39.3-4.5"/>
		<path style={{fill: "none", stroke: "#CF782F", strokeWidth: 10, strokeLinecap: "round", strokeLinejoin: "round", strokeDasharray: "4.9424,19.7694,4.9424,19.7694,4.9424,19.7694" }} d="M211.6,37C135,34.4,28.8,56.6,6.7,193.2"/>
		<path style={{fill: "none", stroke: "#CF782F", strokeWidth: 10, strokeLinecap: "round", strokeLinejoin: "round" }} d="M5.3,203c-0.1,0.8-0.2,1.7-0.3,2.5"/>
		<g>
			<polygon fill="#CF782F" points="258.5,0 250.3,5.8 278.7,46.1 236.4,71.5 241.6,80 293.1,49.1"/>
		</g>
	</g>
</g>
</svg>
);

export default SvgFleche;
