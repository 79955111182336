import jobConstants from './job.constants';
import action from "../../models/classes/action"
import state from "../../models/classes/state"
export default function job(state = {}, action) {
    switch (action.type) {
        case jobConstants.GET_ALL_REQUEST:
            return {
                ...state,
            };
        case jobConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                jobs: action.jobs,
            };

        case jobConstants.GET_ALL_FAILURE:
            return {
                error: "Impossible d'obtenir la liste des offres",
            };
        case jobConstants.GET_JOB_INFO_REQUEST:
            return {
                ...state,
            };
        case jobConstants.GET_JOB_INFO_SUCCESS:
            return {
                ...state,
                info: action.info,
            };

        case jobConstants.GET_JOB_INFO_FAILURE:
            return {
                error: "Impossible d'obtenir les informations sur cette offre",
            };
        case jobConstants.GET_ADVANTAGES_REQUEST:
            return {
                ...state,
            };
        case jobConstants.GET_ADVANTAGES_SUCCESS:
            return {
                ...state,
                advantages: action.advantages,
            };
        case jobConstants.GET_ADVANTAGES_FAILURE:
            return {
                error: "Impossible d'obtenir les informations sur cette offre",
            };

        case jobConstants.SAVE_JOB_REQUEST:
            return {
                ...state,
            };
        case jobConstants.SAVE_JOB_SUCCESS:
            if(state.jobs.find(el => el.id === action.job.id)){
                state.jobs = state.jobs.filter(el => el.id !== action.job.id)
            }
            state.jobs.push(action.job)
            return {
                ...state,
            };

        case jobConstants.SAVE_JOB_FAILURE:
            return {
                ...state,
                error: "Impossible de sauvegarder cette offre",
            };

        case jobConstants.DELETE_JOB_REQUEST:
            return {
                ...state,
            };
        case jobConstants.DELETE_JOB_SUCCESS:
            state.jobs = state.jobs.filter(el => el.id !== action.id)
            return {
                ...state,
            };

        case jobConstants.DELETE_JOB_FAILURE:
            return {
                ...state,
                error: "Impossible de supprimer cette offre",
            };

        case jobConstants.CLEAR:
            return {};
        default:
            return state;
    }
}
