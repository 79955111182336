import React from 'react';

const SvgItron = (props: any) => (
	<svg viewBox="0 0 116.18 46.89" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M79.91,23.19,99.6,0H82.15l-6,22.8h0a16.82,16.82,0,0,1,3.71.39Z"/>
			<path fill={props.fill} d="M8.62,13.66H19.27L10.65,46.33H0L8.62,13.66Zm25,9.87h3.79l-1.89,7.24H31.89L27.67,46.33H17.15l4.18-15.56H18.58l1.89-7.24h2.67l2.07-7.63H35.73ZM57.45,32a15.7,15.7,0,0,0-3.75,0c-3.1.52-4.57,2.24-5.34,5.09L45.9,46.33H35.38l6.08-23H52L50.38,28A11.38,11.38,0,0,1,60,22.8L57.45,32Zm44.91-4c2.63-3,4-5.17,8.32-5.17,5.21,0,6.33,4.31,5,9.35l-3.71,14.18H101.28l2.93-11.2c.39-1.34.87-3.28-1-3.28s-2.37,1.94-2.72,3.28l-3,11.2H87l6.08-23h10.47L102.36,28ZM79.91,23.19l-5.56,6.55,1.81-6.94c-7.59,0-15,4.27-17.16,12.33-2,7.63,3.67,11.76,10.86,11.76s15-4.13,17.07-11.76c1.77-6.68-1.55-10.74-7-11.94ZM77.45,34.82a6.26,6.26,0,0,1-5.69,4.49c-2.5,0-4-2-3.28-4.49a6.17,6.17,0,0,1,5.65-4.48,3.33,3.33,0,0,1,3.32,4.48Z"/>
		</g>
	</g>
</svg>
);

export default SvgItron;
