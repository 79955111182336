import React, { useEffect } from "react";
import ImageSupport from "../../_resources/image/kangoo_Mockup.png";
import SvgGeolocalisation from "../../_svg/Geolocalisation";
import SvgSupportSite from "../../_svg/SupportSite";
import SvgTelemaintenance from "../../_svg/Telemaintenance";
import { withTranslation } from "react-i18next";
import Footer from "../section/FooterSection";
import Fleche from "../../_svg/Fleche";

import { useMediaQuery } from "react-responsive";
import ReactGA from 'react-ga4';
import MetaTags from 'react-meta-tags';

const Support = (props) => {
  const { t } = props;

  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  })

  const content = {
    title: t("all.support"),
    description: t("support.formation.need"),
  };

  const sections = [
    {
      code: "site",
      title: (
        <h5>
          {t("support.formation")} <br /> {t("support.on.site")}{" "}
        </h5>
      ),
      svg: <SvgSupportSite height="7em" fill="#CF7830" />,
    },
    {
      code: "distance",
      title: (
        <h5>
          {t("support.formation")} <br /> {t("support.remote.maintenance")}{" "}
          <br /> {t("support.remote")}{" "}
        </h5>
      ),
      svg: <SvgTelemaintenance height="7em" fill="#CF7830" />,
    },
    {
      code: "distance",
      title: (
        <h5>
          {t("support.formation")} <br /> {t("support.our")} <br />{" "}
          {t("support.offices")}{" "}
        </h5>
      ),
      svg: <SvgGeolocalisation height="7em" fill="#CF7830" />,
    },
  ];

  let language = navigator.language.split('-')[0]

  const redirectTo = () => {
    switch (language) {
      case "fr":
        return "https://get.teamviewer.com/dioptase"
      case "es":
        return "https://get.teamviewer.com/dioptase-es"
      default:
        return "https://get.teamviewer.com/dioptase-en"
    }
  }

  const remoteMaintenance = (lang) => {
    if (lang === "en") {
      return (
        <h5 itemProp="description">
          {t("support.remote.maintenance")} <br /> {t("support.software")}{" "}
          <br /> {t("support.update")}{" "}
        </h5>
      );
    } else {
      return (
        <h5 itemProp="description">
          {" "}
          {t("support.remote.maintenance")} <br /> {t("support.update")} <br />{" "}
          {t("support.software")}
        </h5>
      );
    }
  };

  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });

  return (
    <div className="support-container responsiv" itemScope itemType="https://schema.org/WebPage">
      <MetaTags>
      <title>Dioptase - support</title>
        <meta
          name="description"
          content="Notre équipe du support est prêt à vous écouter du lundi au vendredi de 8h00 à 12h30 puis de 14h00 à 17h30"
        />
        <meta name="keywords" content="support, crystalcloud, tourmaline, licence, formation, télémaintenance, aide, synchronisation, réparation" />
        <meta property="og:title" content="Support" />
        <meta property="og:type" content="Support - formation à distance" />
        <meta property="og:url" content="https://www.dioptase.fr/Support" />
        <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
        <meta property="og:description" content="Une hotline sans temps d'attente, Télémaintenance, mises à jour de vos logiciels" />
      </MetaTags>
      <div className="image-container">
        <h1 itemProp="headline">{content.title}</h1>
        {!isTablet && (
          <h5 className="hotline" itemProp="description">
            <Fleche
              height="4em"
              style={{ transform: "rotateY(180deg)", diplay: "block" }}
            />
            {t("support.hotline")} <br /> {t("support.without.wait")} <br />{" "}
            {t("support.direct.number")}
            <div style={{ position: "relative", width: "234px", height: "60px", alignSelf: "center", marginTop: "10px" }}>
              <a href={redirectTo()} style={{ textDecoration: "none" }}>
                <img src="https://static.teamviewer.com/resources/badges/teamviewer_badge_flat3.png" alt="Remote Access and Support over the Internet with TeamViewer" title="Remote Access and Support over the Internet with TeamViewer" border="0" width="234" height="60" />
                <span style={{ position: "absolute", top: "23px", left: "60px", display: "block", cursor: "pointer", color: "white", fontFamily: "Arial", fontSize: "12px", lineHeight: "1.2em", fontWeight: "bold", textAlign: "center", width: "169px" }}>
                  {t('support.download.quicksupport')}
                </span>
              </a>
            </div>
          </h5>
        )}
        {!isTablet && (
          <div className="remote-maintenance">
            {!isTablet && <Fleche height="10em" />}
            {remoteMaintenance(language)}
          </div>
        )}
      </div>
      <div className="span-container">
        <span itemProp="description">{content.description}</span>
      </div>
      <div className="section-container">
        {sections.map((el) => (
          <div className="section">
            <div className="svg-box">{el.svg}</div>
            <div itemProp="alternativeHeadline" className="title">{el.title}</div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

const tr = withTranslation()(Support);
export default tr;
