import React from 'react';

const SvgTablet = (props: any) => (
    <svg viewBox="0 0 43.81 59.41" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="txt">
			<path fill={props.fill} d="M43.81,55V4.36A4.36,4.36,0,0,0,39.45,0H4.36A4.37,4.37,0,0,0,0,4.36V55a4.37,4.37,0,0,0,4.36,4.37H39.45A4.37,4.37,0,0,0,43.81,55ZM4.36,57.18A2.14,2.14,0,0,1,2.23,55V4.36A2.14,2.14,0,0,1,4.36,2.23H39.45a2.13,2.13,0,0,1,2.13,2.13V55a2.13,2.13,0,0,1-2.13,2.14H4.36Z"/>
			<path fill={props.fill} d="M19.72,4.5a.32.32,0,0,0,0-.14.35.35,0,0,0,0-.14A.37.37,0,0,0,19.39,4a.36.36,0,1,0,0,.71A.36.36,0,0,0,19.72,4.5Z"/>
			<path fill={props.fill} d="M25.11,4.36A.36.36,0,0,0,24.75,4H21.18a.36.36,0,0,0-.33.21.35.35,0,0,0,0,.14.32.32,0,0,0,0,.14.35.35,0,0,0,.33.22h3.57A.36.36,0,0,0,25.11,4.36Z"/>
			<path fill={props.fill} d="M22.83,55.36a.92.92,0,1,0-.92.92A.92.92,0,0,0,22.83,55.36Z"/>
			<path fill={props.fill} d="M40.56,52.44V7a1.11,1.11,0,0,0-1.11-1.11H4.36A1.11,1.11,0,0,0,3.25,7V52.44a1.12,1.12,0,0,0,1.11,1.12H39.45A1.12,1.12,0,0,0,40.56,52.44ZM5.48,51.33V8.08H38.33V51.33Z"/>
		</g>
	</g>
</svg>
);

export default SvgTablet;
