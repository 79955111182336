import React, { Component } from "react";

export default class TextComponent extends Component<any, any>{
    render(){
        const { t, content } = this.props
        const content2 = {
            how: t('produits.how'),
            work: t('produits.work'),
            description: content.description
        }
        return(
            <div className="how-container" itemScope itemType="https://schema.org/Thing">
                <div className="text-container">
                    <h2 itemProp="description">{content2.how}</h2>
                    <h1 itemProp="description">{content2.work}</h1>
                </div>
                <div className="description">
                    <span itemProp="description">{content2.description}</span>
                </div>
            </div>
        )
    }
}