import React from 'react';

const SvgAxioma = (props: any) => (
	<svg viewBox="0 0 147.49 32.02" {...props}>
		<path d="M9.13,18.67l2.5-5.73,2.52,5.73h-5.02ZM14.08,7.34l-1.77-4.03c-.09-.19-.28-.32-.49-.32h-.38c-.21,0-.41,.13-.49,.32l-1.76,4.04L.05,28.27c-.15,.36,.11,.75,.49,.75h3.73c.21,0,.41-.13,.49-.32l2.41-5.53h8.96l2.43,5.54c.09,.2,.28,.32,.49,.32h3.73c.39,0,.65-.4,.49-.75L14.08,7.34Z" />
		<path d="M133.3,18.67l2.5-5.73,2.52,5.73h-5.02Zm4.95-11.33l-1.77-4.03c-.09-.19-.28-.32-.49-.32h-.38c-.21,0-.41,.13-.49,.32l-1.76,4.04-9.13,20.92c-.16,.36,.1,.75,.49,.75h3.73c.21,0,.41-.13,.49-.32l2.41-5.53h8.96l2.43,5.54c.09,.2,.28,.32,.49,.32h3.73c.39,0,.65-.4,.49-.75l-9.2-20.92Z" />
		<path d="M45.84,2.99h-4.03c-.18,0-.36,.1-.46,.25l-5.51,8.76L30.33,3.24c-.1-.16-.27-.25-.46-.25h-4.03c-.42,0-.68,.47-.45,.82l7.8,12.4-7.54,11.98c-.23,.36,.03,.82,.46,.82h4.03c.18,0,.36-.09,.46-.25l5.25-8.34,5.25,8.34c.1,.16,.27,.25,.45,.25h4.03c.42,0,.68-.47,.46-.82l-7.54-11.98,7.8-12.4c.23-.36-.03-.82-.46-.82" />
		<path d="M50.57,3.53V28.48c0,.3,.24,.54,.54,.54h3.41c.3,0,.54-.24,.54-.54V3.53c0-.3-.24-.54-.54-.54h-3.41c-.3,0-.54,.24-.54,.54" />
		<path d="M115.11,7.73l-1.47-4.37c-.07-.22-.28-.37-.51-.37h-.43c-.21,0-.4,.12-.49,.31l-1.93,4.16-5.82,12.51-5.9-12.7-1.85-3.97c-.09-.19-.28-.31-.49-.31h-.58c-.23,0-.44,.15-.51,.37l-1.41,4.19-6.98,20.77c-.12,.35,.14,.71,.51,.71h3.6c.23,0,.44-.15,.51-.37l5.11-15.2,5.5,11.84,1.59,3.41c.09,.19,.28,.31,.49,.31h.8c.21,0,.4-.12,.49-.31l1.59-3.41,5.42-11.65,5.05,15.01c.07,.22,.28,.37,.51,.37h3.6c.37,0,.63-.36,.51-.71l-6.92-20.59Z" />
		<path d="M75.08,7.8l-8.68,13.79-2.68,4.26-2,3.17-1.37,2.17c-.23,.36,.03,.82,.46,.82h4.03c.18,0,.36-.1,.46-.25l1.73-2.74,.34-.54,2.59-4.12,8.73-13.87c-.94-1.23-2.19-2.17-3.62-2.68" />
		<path d="M84.32,0h-4.03c-.18,0-.36,.1-.46,.25l-1.72,2.74-.25,.4c1.34,.67,2.56,1.59,3.6,2.7l1.95-3.11,1.36-2.16c.23-.36-.03-.82-.46-.82" />
		<path d="M81.46,6.1c-1.04-1.11-2.26-2.03-3.6-2.7-1.63-.82-3.44-1.27-5.35-1.27-7.04,0-12.74,6.16-12.74,13.76,0,3.93,1.52,7.46,3.97,9.97l2.68-4.26c-1.1-1.5-1.76-3.41-1.76-5.48,0-4.83,3.56-8.75,7.96-8.75,.87,0,1.7,.16,2.48,.44,1.42,.51,2.67,1.45,3.62,2.68,1.17,1.52,1.87,3.48,1.87,5.63,0,4.83-3.57,8.75-7.96,8.75-.93,0-1.81-.18-2.63-.5l-2.59,4.12c1.57,.74,3.3,1.16,5.12,1.16,7.04,0,12.74-6.16,12.74-13.76,0-3.83-1.45-7.29-3.78-9.78" />
	</svg>
);

export default SvgAxioma;
