import React, { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const MyMapComponent = withScriptjs(withGoogleMap((props: any) =>
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 47.38476, lng: 0.67596 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 47.38438412129914, lng: 0.6761365983898324 }} />}
  </GoogleMap>
))

class SimpleMap extends Component<any> {

  render() {
    const { center, zoom } = this.props;
    return (
      <div style={{ height: "40vh", width: "100%" }}>
        <MyMapComponent 
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBSRYNpTYG6I-BWu_MSDpnkDyuKsJrafc4&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default SimpleMap;
