import React from 'react';

const SvgSupportSite = (props: any) => (
<svg version="1.1" id="Calque_1" viewBox="0 0 181.4 121.7" {...props}>
<g>
	<path fill="#CF782F" d="M20.5,23.2c-1-3.7-0.1-9.5-6.9-9.1c-1.7,1.1-3,2.9-4,5.2c-0.9,0.1-2.1-0.1-3.2-0.3C10.1,13,14.2,7.6,18.2,2.2
		c1.1,0.9,2.7,0.6,4.2,1.3c0,6.5-0.1,13.1-0.1,19.5C21.6,22.9,20.9,22.9,20.5,23.2 M23.9,25.5c1.3-3.9,0.8-8.2,0.8-12.1
		c0-4,0-8-0.7-11.7c-1.7-1.4-4.3-1.2-6-1.7C12.3,5.5,7.4,12.4,3.2,20.8c1.7,0.9,4.5,1.3,6.3,1.3c2-1.6,3.2-4.6,5.5-5.7
		c0.7,0.2,1.5,0.5,2.2,0.7c0.4,2.4-0.4,5.5,0.9,7.5C19.2,25.8,22.8,26.6,23.9,25.5"/>
	<path fill="#CF782F" d="M18.1,10.7c-0.4-0.1-0.8-0.2-1.2-0.4c0.5-0.5,0.8-1.4,1.3-2C18.4,9,18.2,9.9,18.1,10.7 M18.9,4.8
		c-1.5,1.8-3.7,4.1-4.3,6.7c1,1.5,3.5,0.8,4.7,1.7c1.3-2.6,0.6-5.5,0.5-7.9C19.2,5.3,19.4,4.9,18.9,4.8"/>
	<path fill="#CF782F" d="M69.4,36.2c-2.8-2.5-6.1-5.5-10.8-6.4c0-0.9,0.3-1.9,0.5-2.9c5.4,1.8,8.6,4.9,11.9,7.9
		C70.6,35.4,69.9,35.5,69.4,36.2 M74.1,35.7c0.6-2.3-4.3-4.1-5.6-6.3c-2.7-1.7-5.7-3.3-8.9-4.8C59,25,57.7,24,57.4,24.8
		c-1.4,1.8-1.5,5.1-1.6,7.3c5.3,1.4,9.5,3.7,13,6.5C70.2,37.4,73.6,37.7,74.1,35.7"/>
	<path fill="#CF782F" d="M40.9,30.9c-1-0.6-0.9-1.9-1.6-2.7c3.4-2,7.5-2.8,12-2.8c0.7,0.9-0.1,2.6,0.4,3.6
		C47.9,29.4,44.2,29.8,40.9,30.9 M36.7,26.3c0.7,2.7,1.7,5.2,3.3,7.4c4.2-1.2,8.3-2.5,13.7-1.3c0.4-3.4,0.9-6.9-0.6-9.3
		C46.7,22.4,41.7,24.4,36.7,26.3"/>
	<path fill="#CF782F" d="M74.5,40.8c1.6,3.7,2.1,7.9,2.2,12.4c-0.5,0.6-1.7,0-2.3,0.4c-0.6-4-0.4-8.4-2.2-11.8
		C72.9,41.3,73.8,41.3,74.5,40.8 M71,55.4c3.1,0.2,6.3,0.5,8.6-1c-0.6-5.9-0.8-12.1-3.8-16.9c-2.2,1-4.6,1.8-6.5,3.4
		C70.8,45.2,71.1,50.2,71,55.4"/>
	<path fill="#CF782F" d="M34.7,32.4c-3.5,1.5-7,3-10.7,4.2c-0.1-0.6-0.4-1.1-0.3-1.8c3.4-1.2,6.4-2.9,9.6-4.4
		C34.6,30.8,34,32,34.7,32.4 M20.7,33.7c0.4,2.1,0.8,4.1,2.4,5.5c5-1.2,9.3-3.7,14.5-4.6c-0.6-2.6-1.3-5.2-3.1-7.3
		C29.9,29.7,24.8,30.7,20.7,33.7"/>
	<path fill="#CF782F" d="M168.5,93c-0.4-1,0.7-2.8,0.6-3.9c0.6,0.2,1.5,0.3,1.7,0.8C170.9,91.5,169.6,92.8,168.5,93 M168.3,87.3
		c-0.7,1.9-2.7,5.6-1.6,7.1c3.1,1.8,7.2-2.2,5.7-5.6C171.6,88,169.6,86.8,168.3,87.3"/>
	<path fill="#CF782F" d="M163.4,97.3c1.9-6.9,3-13.4,5.1-20.3c2.6-0.2,7.7-0.2,9.4,2.5c0.5,3.8-3.5,3.7-4.9,5.9
		c0.1,2.4,4.9,2.4,5.8,4.4C178,99.2,170.3,98.4,163.4,97.3 M180.8,88.2c-1.1-0.9-1.8-1.9-3.3-2.6c1.6-0.7,2.9-3.1,3.1-5.6
		c-0.8-5.1-10.6-6.5-14.2-4.5c-1,6.7-5.6,15.9-6.2,23c7.9,3,17.6,4.2,20.8-3.9C181.7,92.1,181.5,90,180.8,88.2"/>
	<path fill="#CF782F" d="M171.7,79.9c0.7,0.1,0.7,0.5,1.1,0.8c-0.2,1.3-0.9,1.6-1.8,1.6C171.5,81.3,171.2,80.2,171.7,79.9 M174.8,79.1
		c-1.3-1-3-0.6-4.2-0.5c-0.6,1.2-1.5,3.4-1.5,4.7C171.8,84.9,175.3,82.7,174.8,79.1"/>
	<path fill="#CF782F" d="M5.9,39.7c-1.7-2.1-3.9-4-2-8c2.5-1.7,5.6-2.4,9.8-0.8C17,34.5,12.6,43.4,5.9,39.7 M14.9,28.6
		c-1-0.5-3-1.4-4.2-1.3c-2.2-0.5-3.7,0-5.5,0.2C0.1,29.3-2,38.9,2.4,42c2.9,1.5,6,1.2,8.8,1.7C16.7,42.3,20,32.1,14.9,28.6"/>
	<path fill="#CF782F" d="M10,36.4c-0.7,0.5-1.9,0.1-3.2-0.3c-0.1-0.8-0.3-1.5,0.2-2.6c1-0.5,2.8-0.3,3.7,0.7
		C10.8,34.9,10.5,35.9,10,36.4 M4.5,33.1c-0.5,1.6,0.5,3,0.7,4.6c0.8,0.7,2.2,1.2,3.6,1.6c2.2,0,4.5-2.5,4.1-5.4
		C11.7,30.7,5.6,29.7,4.5,33.1"/>
	<path fill="#CF782F" d="M74.7,73.3c-1.3-4-0.7-8.9-1-13.4c1,0,2,0.1,2.9-0.2c1.5,3.9,0.3,9.1,1.5,13.1C76.9,72.9,75.7,72.9,74.7,73.3
		 M79.5,57.4c-2.9,0.2-5.7,0.6-8.5,0.8c0,6,0.2,12,1.2,17.5c3.1-0.4,6.7,0.3,9.1-1.3C80.5,68.8,80.2,63,79.5,57.4"/>
	<path fill="#CF782F" d="M163.8,111.1c-1.1-0.3-1.7-0.8-1.9-1.5c0.5-1.7,2.8-0.9,3.2,0.3C164.7,110.5,164.3,110.8,163.8,111.1
		 M159.1,109.6c0.2,0.1,0.1,0.4,0,0.7c0.9,1.7,2.3,2.1,4.7,3c1.3-0.4,2.6-0.8,3.2-2.7C167.7,106.1,160,104.3,159.1,109.6"/>
	<path fill="#CF782F" d="M162.6,114.4c-3.2-0.7-4.2-2.7-4.5-4.9c1-3.3,2.9-4.9,6-4.4C171.1,105.5,167.4,114.9,162.6,114.4 M163.7,102.6
		c-4.5-0.6-8,3.6-8.3,9.1c1.3,2.1,2.5,4.8,5.1,5.7c5.6,2,11.9-5.8,9.3-11.6C168.2,104.6,166.4,102.6,163.7,102.6"/>
	<path fill="#CF782F" d="M76.1,80.4c1.2-0.1,2.3-0.3,3.3-0.7c1.2,3.7,2.1,7.5,3.5,11.1c-0.7,0.7-1.7,0.9-2.6,1.4
		C78.1,88.6,76.6,84.8,76.1,80.4 M81.6,77.4c-3.4-0.3-6,1.2-9,1.7c1.6,5.6,2.6,11.5,6,16.2c2.5-1.2,5.3-1.7,7.4-3.5
		C84,87.3,82.6,82.5,81.6,77.4"/>
	<path fill="#CF782F" d="M151,109.2c-4.9,0.1-9.7,0.1-13.4,2.5c-0.4-0.6-0.6-1.3-0.6-2.2c4.5-0.9,8.9-2.1,13.9-2.1
		C151.3,107.8,151.2,108.5,151,109.2 M151.9,104.7c-7-0.1-13.6,0.7-19,3.8c0.6,2.3,1.3,4.6,1.7,7.1c5.9-2.3,11.9-4.4,19.3-3.9
		c0.3-2.1,0.9-4.2,0.3-5.8C153.5,105.5,152.8,105.1,151.9,104.7"/>
	<path fill="#CF782F" d="M90.8,108.5c-3.3-2.9-5.2-6.4-7.3-9.9c0.4-1,1.5-0.6,2.1-1.3c2,3.2,4.1,6.4,6.9,9.1
		C92.1,107.5,91.3,107.8,90.8,108.5 M87.1,94.7c-2.6,0.5-4.4,2.5-7,3c2.2,5.5,6.2,10.2,11.1,14.4c1.5-2.2,3.4-3.6,4.9-5.9
		C92,103,89.3,99,87.1,94.7"/>
	<path fill="#CF782F" d="M115.3,118.2c0.4-1,0-1.5,0.4-2.5c4.5,0,8.1-1.5,12-2.8c0.9,0.6,0.6,1.9,0.8,2.9
		C124.4,117.2,120.5,119,115.3,118.2 M113.9,113.2c0,2.8-0.6,5.8-0.5,8.5c6.4-0.6,12.7-1.5,17.7-4.6c-0.8-2.3-0.8-5.1-1.6-7.4
		C124.6,111.4,119.6,113,113.9,113.2"/>
	<path fill="#CF782F" d="M109.6,115.1c-0.3,1-0.6,2-0.9,3c-4.8-0.5-9.5-2.9-12.9-5.2c0.5-1.1,1.2-1.9,1.9-2.7
		C100.4,112.4,105.2,114.3,109.6,115.1 M92.4,113.6c4.2,3.7,11,6.7,18.2,7.9c0.7-3,1-5.7,1.6-8.6c-5.2-0.7-11.3-2.6-14.8-5.5
		C95.8,109.6,94,111.4,92.4,113.6"/>
</g>
</svg>

);

export default SvgSupportSite;
