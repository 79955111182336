import React from 'react';

const SvgCerig = (props: any) => (
	<svg viewBox="0 0 92.81 29.43" {...props}>
		<g>
			<path d="M25.93,4.14c0-1.42,.48-2.46,1.39-3.23,.8-.64,1.55-.91,3.05-.91h8.07V.96h-8.05c-1.31,0-1.77,.13-2.46,.72-.69,.62-1.04,1.39-1.04,2.41V14.78c0,1.58,.53,2.57,1.74,3.18,.48,.24,.91,.32,2.09,.32h7.67v.96h-7.67c-1.95,0-2.51-.16-3.48-1.02-.96-.88-1.31-1.77-1.31-3.4V4.14Z"/>
			<path d="M41.33,4.38c0-2.35,1.31-3.93,3.66-4.3C45.26,.03,45.55,0,45.85,0h7.94V.96h-8.02c-.21,0-.45,.03-.67,.05-1.9,.32-2.83,1.47-2.83,3.37v4.87h11.5l-.03,.96h-11.44v4.68c0,2.08,1.34,3.39,3.47,3.39h7.97v.96h-8.07c-.29,0-.59-.03-.86-.08-2.22-.37-3.48-1.9-3.48-4.14V4.38Z"/>
			<path d="M60.83,10.13l5.89-.05c2.03,0,3.02-.88,3.29-2.59,.03-.16,.05-.32,.05-.45v-2.78c0-2.06-1.28-3.29-3.74-3.29h-8.1V19.25h-.96V0h9.06C69.37,0,71.03,1.58,71.03,4.33v2.59c0,2.54-1.68,4.12-4.28,4.12h-5.23l9.37,8.21h-1.66l-8.7-7.75s-.34-.28-.34-.71,.36-.65,.63-.65"/>
			<rect x="74.29" y="0" width=".96" height="19.25"/>
			<path d="M79.1,4.22C79.1,1.76,80.84,0,83.67,0h8.4V.96h-8.29c-2.41,0-3.72,1.23-3.72,3.26V14.97c0,1.52,.59,2.49,1.84,3.05,.45,.22,.77,.27,1.76,.27h5c.61,0,1.18-.16,1.66-.48,1.1-.69,1.52-1.5,1.52-2.73v-5.83h.96v5.67c0,1.82-.56,2.86-1.95,3.74-.61,.37-1.34,.59-2.19,.59h-5.03c-2.94,0-4.54-1.6-4.54-4.25V4.22Z"/>
			<path d="M14.85,.07c-2.54,0-4.58,2.04-4.58,4.58,0,5.01-.43,5.43-5.43,5.43C2.31,10.08,.26,12.13,.26,14.66s2.05,4.58,4.58,4.58,4.58-2.04,4.58-4.58c0-5,.42-5.43,5.43-5.43,2.54,0,4.58-2.04,4.58-4.58S17.39,.07,14.85,.07"/>
			<path d="M4.91,9.17c2.54,0,4.58-2.05,4.58-4.58S7.45,0,4.91,0,.33,2.05,.33,4.58s2.04,4.58,4.58,4.58"/>
			<path d="M14.85,10.08c-2.54,0-4.58,2.05-4.58,4.58s2.04,4.58,4.58,4.58,4.58-2.04,4.58-4.58-2.05-4.58-4.58-4.58"/>
		</g>
		<g>
			<path d="M3.94,27.73h-1.32L0,21.52H1.49l1.83,4.68,1.85-4.68h1.49l-2.72,6.21Z"/>
			<path d="M11.05,26.49c-.12,.28-.29,.52-.5,.71s-.47,.34-.76,.44c-.3,.1-.62,.15-.97,.15-.76,0-1.34-.21-1.76-.62-.42-.41-.62-1-.62-1.76,0-.35,.06-.67,.18-.96,.12-.29,.28-.53,.49-.72,.21-.2,.46-.35,.76-.45,.3-.11,.62-.16,.96-.16,.76,0,1.35,.21,1.77,.62,.43,.42,.64,1.01,.64,1.77,0,.37-.06,.69-.18,.97Zm-1.15-1.57c-.04-.17-.1-.31-.19-.43-.09-.12-.2-.22-.35-.29-.15-.07-.32-.11-.54-.11-.22,0-.41,.04-.55,.11s-.26,.17-.34,.3c-.08,.13-.14,.27-.18,.44s-.05,.35-.05,.55,.02,.37,.06,.53c.04,.16,.11,.3,.2,.42,.09,.12,.21,.21,.35,.28,.15,.07,.32,.1,.54,.1s.39-.04,.53-.11c.14-.07,.26-.17,.34-.29,.09-.12,.15-.27,.18-.44s.05-.35,.05-.53-.02-.37-.06-.53Z"/>
			<path d="M12.15,22.73v-1.21h1.25v1.21h-1.25Zm0,5v-4.54h1.25v4.54h-1.25Z"/>
			<path d="M17.04,25.24c0-.17,0-.33,0-.46,0-.13-.03-.25-.08-.34s-.13-.16-.24-.21c-.11-.05-.27-.07-.47-.07h-.36v3.57h-1.25v-4.54h1.85c.25,0,.49,.02,.74,.05,.25,.03,.46,.13,.66,.31,.09,.08,.16,.17,.2,.27s.09,.2,.11,.31,.04,.22,.05,.34c0,.11,0,.23,0,.34v.44h-1.2Z"/>
			<path d="M21.32,27.73v-6.21h1.25v6.21h-1.25Z"/>
			<path d="M28.11,26.49c-.12,.28-.29,.52-.5,.71s-.47,.34-.76,.44c-.3,.1-.62,.15-.97,.15-.76,0-1.34-.21-1.76-.62-.42-.41-.62-1-.62-1.76,0-.35,.06-.67,.18-.96,.12-.29,.28-.53,.49-.72,.21-.2,.46-.35,.76-.45,.3-.11,.62-.16,.96-.16,.76,0,1.35,.21,1.77,.62,.43,.42,.64,1.01,.64,1.77,0,.37-.06,.69-.18,.97Zm-1.15-1.57c-.04-.17-.1-.31-.19-.43-.09-.12-.2-.22-.35-.29-.15-.07-.32-.11-.54-.11-.22,0-.41,.04-.55,.11s-.26,.17-.34,.3c-.08,.13-.14,.27-.18,.44s-.05,.35-.05,.55,.02,.37,.06,.53c.04,.16,.11,.3,.2,.42,.09,.12,.21,.21,.35,.28,.15,.07,.32,.1,.54,.1s.39-.04,.53-.11c.14-.07,.26-.17,.34-.29,.09-.12,.15-.27,.18-.44s.05-.35,.05-.53-.02-.37-.06-.53Z"/>
			<path d="M29.22,22.73v-1.21h1.25v1.21h-1.25Zm0,5v-4.54h1.25v4.54h-1.25Z"/>
			<path d="M34.67,27.73v-2.93c0-.23-.05-.39-.15-.49-.1-.1-.26-.14-.49-.14h-1.08v3.57h-1.25v-4.54h2.49c.6,0,1.04,.14,1.32,.42,.28,.28,.42,.73,.42,1.33v2.79h-1.25Z"/>
			<path d="M37.44,29.01h-.57l.57-1.28h-.41v-1.21h1.13v1.08l-.72,1.41Z"/>
		</g>
		<g>
			<path d="M43.62,27.73c-.3,0-.57-.03-.81-.08s-.48-.17-.71-.35c-.3-.23-.52-.51-.64-.84-.12-.33-.19-.68-.19-1.05,0-.17,.02-.35,.05-.54s.09-.36,.16-.52,.17-.32,.28-.46,.25-.27,.4-.37c.2-.14,.42-.23,.66-.27,.24-.04,.48-.06,.71-.06h1.75v.97h-1.67c-.32,0-.55,.05-.7,.16-.15,.11-.24,.32-.26,.65h2.63v.97h-2.63c.01,.17,.05,.32,.1,.42,.06,.11,.13,.19,.23,.25s.21,.1,.34,.12,.27,.03,.43,.03h1.53v.97h-1.67Zm.53-5.04l-.57-.75-.61,.75h-.81l.95-1.18h.95l.94,1.18h-.85Z"/>
			<path d="M48.14,27.73c-.31,0-.59-.05-.84-.16-.25-.11-.43-.32-.55-.63-.05-.15-.08-.3-.09-.45-.01-.15-.02-.31-.02-.46v-1.86h-.79v-.97h.79v-1.68h1.25v1.68h1.16v.97h-1.16v1.65c0,.1,0,.22,0,.37s.04,.27,.1,.35c.06,.07,.13,.13,.21,.16,.09,.03,.18,.06,.28,.07,.1,0,.2,.01,.3,0,.1,0,.19,0,.26,0v.97h-.9Z"/>
			<path d="M52.32,25.24c0-.17,0-.33,0-.46,0-.13-.03-.25-.08-.34s-.13-.16-.24-.21c-.11-.05-.27-.07-.47-.07h-.36v3.57h-1.25v-4.54h1.85c.25,0,.49,.02,.74,.05,.25,.03,.46,.13,.66,.31,.09,.08,.16,.17,.2,.27s.09,.2,.11,.31,.04,.22,.05,.34c0,.11,0,.23,0,.34v.44h-1.2Z"/>
			<path d="M56.3,27.73c-.3,0-.57-.03-.81-.08s-.48-.17-.71-.35c-.3-.23-.52-.51-.64-.84-.12-.33-.19-.68-.19-1.05,0-.17,.02-.35,.05-.54s.09-.36,.16-.52,.17-.32,.28-.46,.25-.27,.4-.37c.2-.14,.42-.23,.66-.27,.24-.04,.48-.06,.71-.06h1.75v.97h-1.67c-.32,0-.55,.05-.7,.16-.15,.11-.24,.32-.26,.65h2.63v.97h-2.63c.01,.17,.05,.32,.1,.42,.06,.11,.13,.19,.23,.25s.21,.1,.34,.12,.27,.03,.43,.03h1.53v.97h-1.67Z"/>
			<path d="M65.64,26.67c-.17,.35-.44,.63-.81,.83-.19,.11-.38,.17-.57,.2s-.39,.04-.6,.04h-1.1v1.71h-1.25v-6.24h2.18c.15,0,.29,0,.43,0,.14,0,.27,.02,.4,.05,.13,.03,.26,.06,.38,.12,.12,.05,.25,.13,.37,.22,.3,.24,.51,.53,.63,.87,.12,.35,.18,.7,.18,1.08,0,.4-.09,.78-.26,1.14Zm-1.31-2.16c-.2-.23-.5-.34-.9-.34h-.86v2.6h.76c.44,0,.77-.11,.97-.34s.31-.55,.31-.99c0-.39-.1-.7-.29-.93Z"/>
			<path d="M69.23,25.24c0-.17,0-.33,0-.46,0-.13-.03-.25-.08-.34s-.13-.16-.24-.21c-.11-.05-.27-.07-.47-.07h-.36v3.57h-1.25v-4.54h1.85c.25,0,.49,.02,.74,.05,.25,.03,.46,.13,.66,.31,.09,.08,.16,.17,.2,.27s.09,.2,.11,.31,.04,.22,.05,.34c0,.11,0,.23,0,.34v.44h-1.2Z"/>
			<path d="M75.5,26.49c-.12,.28-.29,.52-.5,.71s-.47,.34-.76,.44c-.3,.1-.62,.15-.97,.15-.76,0-1.34-.21-1.76-.62-.42-.41-.62-1-.62-1.76,0-.35,.06-.67,.18-.96,.12-.29,.28-.53,.49-.72,.21-.2,.46-.35,.76-.45,.3-.11,.62-.16,.96-.16,.76,0,1.35,.21,1.77,.62,.43,.42,.64,1.01,.64,1.77,0,.37-.06,.69-.18,.97Zm-1.15-1.57c-.04-.17-.1-.31-.19-.43-.09-.12-.2-.22-.35-.29-.15-.07-.32-.11-.54-.11-.22,0-.41,.04-.55,.11s-.26,.17-.34,.3c-.08,.13-.14,.27-.18,.44s-.05,.35-.05,.55,.02,.37,.06,.53c.04,.16,.11,.3,.2,.42,.09,.12,.21,.21,.35,.28,.15,.07,.32,.1,.54,.1s.39-.04,.53-.11c.14-.07,.26-.17,.34-.29,.09-.12,.15-.27,.18-.44s.05-.35,.05-.53-.02-.37-.06-.53Z"/>
			<path d="M78.68,27.73c-.18,0-.35,0-.51-.02-.16-.01-.32-.04-.47-.08-.15-.04-.29-.11-.43-.19-.14-.08-.27-.19-.39-.34-.21-.24-.36-.49-.45-.76s-.13-.57-.13-.89c0-.34,.05-.66,.15-.99,.1-.32,.28-.6,.54-.83,.24-.2,.5-.32,.8-.37,.3-.05,.59-.07,.89-.07h1.71v.97h-1.49c-.47,0-.8,.1-1.01,.3s-.31,.54-.31,1c0,.24,.04,.46,.13,.66,.08,.2,.24,.36,.46,.47,.14,.07,.29,.12,.43,.13,.14,.02,.3,.02,.46,.02h1.34v.97h-1.71Z"/>
			<path d="M84.47,27.73v-2.58c0-.18,0-.33-.03-.46-.02-.12-.06-.23-.12-.31-.06-.08-.15-.14-.27-.17-.12-.03-.28-.05-.47-.05h-1.01v3.57h-1.25v-6.21h1.25v1.68h1.28c.31,0,.58,.03,.82,.08,.23,.06,.43,.15,.58,.28,.16,.13,.27,.31,.35,.53,.08,.22,.12,.5,.12,.82v2.82h-1.25Z"/>
			<path d="M88.93,27.73c-.3,0-.57-.03-.81-.08s-.48-.17-.71-.35c-.3-.23-.52-.51-.64-.84-.12-.33-.19-.68-.19-1.05,0-.17,.02-.35,.05-.54s.09-.36,.16-.52,.17-.32,.28-.46,.25-.27,.4-.37c.2-.14,.42-.23,.66-.27,.24-.04,.48-.06,.71-.06h1.75v.97h-1.67c-.32,0-.55,.05-.7,.16-.15,.11-.24,.32-.26,.65h2.63v.97h-2.63c.01,.17,.05,.32,.1,.42,.06,.11,.13,.19,.23,.25s.21,.1,.34,.12,.27,.03,.43,.03h1.53v.97h-1.67Z"/>
			<path d="M91.48,27.73v-1.21h1.14v1.21h-1.14Z"/>
		</g>
	</svg>
);

export default SvgCerig;
