import React from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { PublicRoute } from "./Route";
import { PrivateRoute } from "./PrivateRoute";
import { MainLayout, BlankLayout } from "../layouts";
import { history } from "../_helpers";
import { connect } from "react-redux";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

import DioptaseScroll from "../component/home/Dioptase";
import DioptaseVideo from "../component/home/DioptaseVideo";

import Applications from "../component/section/Applications";
import Produits from "../component/section/Produits";
import Contact from "../component/contact/ContactComponent";
import Logiciels from "../component/section/Logiciels";
import SiteMap from "../component/section/SiteMap";
import Recrut from '../component/recrut/Recrut';
import Intervention from "../component/intervention/Intervention";

import Saphir from "../component/saphir/Saphir";
import Dld from "../component/dld/Dld";
import Onyx from "../component/onyx/Onyx";
import Mbox from "../component/mbox/Mbox";
import Materiels from "../component/materiels/Materiels";
import Crystalcloud from "../component/crystalcloud/Crystalcloud";
import Tourmaline from "../component/tourmaline/Tourmaline";
import Support from "../component/support/Support";
import MaConso from "../component/maconso/MaConso";
import WorkSheet from "../component/recrut/WorkSheet";

import ComparateurComponent from "../component/materiels/ComparateurComponent";
import FicheTechnique from "../component/materiels/FicheTechnique";

import deviceAction from "../redux/device/device.action";
import jobAction from "../redux/job/job.action";

import ErrorBuilding from "../component/error/InBuilding";
import Error404 from "../component/error/Error404";
import Layout404 from "../component/error/Layout404";

import AdminLogin from '../component/admin/AdminLogin';
import Dashboard from "../component/admin/Dashboard";

import _ from "lodash";
import { HelmetProvider } from "react-helmet-async";
/*
              <PublicRoute
                exact
                path="/logiciels/maconso"
                layout={MainLayout}
                component={MaConso}
              />
*/

class App extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(deviceAction.getAll());
    dispatch(jobAction.getAll());
  }

  componentDidCatch(e) {
    console.log(e)
    history.push("/error");
  }
  //admin/dashboard
  render() {

    const instance = createInstance({
      urlBase: 'https://stats.dioptase.fr',
      siteId: 3,
      userId: 'UID76903202', // optional, default value: `undefined`.
      trackerUrl: 'https://stats.dioptase.fr/matomo.php', // optional, default value: `${urlBase}matomo.php`
      srcUrl: 'https://stats.dioptase.fr/matomo.js', // optional, default value: `${urlBase}matomo.js`
      disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
      heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10 // optional, default value: `15
      },
      linkTracking: false, // optional, default value: true
      configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST'
      }
    })

    return (
      <MatomoProvider value={instance}>
        <HelmetProvider>
          <Router history={history}>
            <Switch>
              <PublicRoute
                exact
                path="/admin"
                layout={BlankLayout}
                component={AdminLogin}
              />
              <PrivateRoute
                exact
                path="/admin/dashboard"
                layout={BlankLayout}
                component={Dashboard}
              />
              <PublicRoute
                exact
                path="/"
                layout={MainLayout}
                component={DioptaseScroll}
              />
              <PublicRoute
                exact
                path="/applications"
                layout={MainLayout}
                component={Applications}
              />
              <PublicRoute
                exact
                path="/logiciels"
                layout={MainLayout}
                component={Logiciels}
              />
              <PublicRoute
                exact
                path="/logiciels/intervention"
                layout={MainLayout}
                component={Intervention}
              />
              <PublicRoute
                exact
                path="/produits"
                layout={MainLayout}
                component={Produits}
              />
              <PublicRoute
                exact
                path="/recrutement"
                layout={MainLayout}
                component={Recrut}
              />
              <PublicRoute
                exact
                path="/recrutement/:jobId"
                layout={MainLayout}
                component={WorkSheet}
              />
              <PublicRoute
                exact
                path="/support"
                layout={MainLayout}
                component={Support}
              />
              <PublicRoute
                exact
                path="/contact"
                layout={MainLayout}
                component={Contact}
              />

              <PublicRoute
                exact
                path="/logiciels/crystalcloud"
                layout={MainLayout}
                component={Crystalcloud}
              />
              <PublicRoute
                exact
                path="/logiciels/tourmaline"
                layout={MainLayout}
                component={ErrorBuilding}
              />
              <PublicRoute
                exact
                path="/error"
                layout={MainLayout}
                component={Error404}
              />

              <PublicRoute
                exact
                path="/applications/saphir"
                layout={MainLayout}
                component={Saphir}
              />
              <PublicRoute
                exact
                path="/applications/onyx"
                layout={MainLayout}
                component={Onyx}
              />
              <PublicRoute
                exact
                path="/produits/mbox"
                layout={MainLayout}
                component={Mbox}
              />

              <PublicRoute
                exact
                path="/materiels"
                layout={MainLayout}
                component={Materiels}
              />
              <PublicRoute
                exact
                path="/materiels/comparateur"
                layout={MainLayout}
                component={ComparateurComponent}
              />

              <PublicRoute
                exact
                path={`/materiels/nautiz`}
                layout={MainLayout}
                component={FicheTechnique}
              />
              <PublicRoute
                exact
                path={`/materiels/s52`}
                layout={MainLayout}
                component={FicheTechnique}
              />
              <PublicRoute
                exact
                path={`/materiels/samsung`}
                layout={MainLayout}
                component={FicheTechnique}
              />
              <PublicRoute
                exact
                path={`/materiels/crosscall`}
                layout={MainLayout}
                component={FicheTechnique}
              />
              <PublicRoute
                exact
                path={`/materiels/crosscallx4`}
                layout={MainLayout}
                component={FicheTechnique}
              />
              <PublicRoute
                exact
                path={`/sitemap`}
                layout={MainLayout}
                component={SiteMap}
              />
              <Redirect to="/error" />
            </Switch>
          </Router>
        </HelmetProvider>
      </MatomoProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;

/*
<PublicRoute
                exact
                path="/produits/dld"
                layout={MainLayout}
                component={Dld}
              />
*/