import { authHeader, handleResponse } from '../../_helpers';

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let response = await fetch(`/api/device/all`, requestOptions);
    return handleResponse(response);
}

async function saveAll(deviceList) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(deviceList)
    };
    let response = await fetch(`/api/device/save/all`, requestOptions);
    return handleResponse(response);
}

const deviceService = {
    getAll,
    saveAll,
}

export default deviceService