import React from 'react';
import Error from './Error404';
import Error404 from './Error404';

type Props = any;
type State = {
    hasError: boolean;
};
export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidCatch(error: any, info: any) {
        this.setState({ hasError: true });
    }
    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        console.log(children, hasError)
        console.log(this.props)
        if (hasError) {
            return <Error404 />;
        }

        return children;
    }
}
