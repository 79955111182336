import React, { Component, useState } from "react";
import { connect } from "react-redux";

import Mail from "../../_svg/Mail";
import Telephone from "../../_svg/Telephone";
import Envoyer from "../../_svg/Envoyer";
import FlecheMail from "../../_svg/Fleche_mail";
import FlecheTelephone from "../../_svg/Fleche_telephone";

import ContactImg from "../../_resources/image/contact.png";
import ImgRecap from "../../_resources/image/telephone_contact.png";

import { Input, UncontrolledTooltip } from "reactstrap";
import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import mailActions from "../../redux/mail/mail.action";

import _ from "lodash";
import ReactTooltip from "react-tooltip";

const Contact = (props: any) => {
  const { objet, t, dispatch, mail } = props;

  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [smail, setMail] = useState("");
  const [subject, setSubject] = useState(objet);
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState<Array<String>>([]);

  const content = {
    viaMail: t("contact.bymail"),
    send: t("contact.send"),
    viaPhone: t("contact.byphone"),
    telephoneNumber: "+33 (0)2 47 38 60 00",
    freeNumber: t("contact.free.number"),
  };

  const formulaire = [
    t("contact.name"),
    t("contact.firstname"),
    t("contact.mail"),
    t("contact.object"),
    t("contact.message"),
  ];

  function sendMail() {
    const copyError = _.cloneDeep(errors);
    if (
      name.length > 0 &&
      firstname.length > 0 &&
      smail.length > 0 &&
      subject.length > 0 &&
      message.length > 0
    ) {
      const newMail = {
        name,
        firstname,
        mail: smail,
        subject,
        message,
      };
      dispatch(mailActions.send(newMail));
    }
    else{
      if (name && name.length === 0) {
        copyError.push(t("contact.name"));
      }
      if (firstname && firstname.length === 0) {
        copyError.push(t("contact.firstname"));
      }
      if (mail && mail.length === 0) {
        copyError.push(t("contact.mail"));
      }
      if (subject && subject.length === 0) {
        copyError.push(t("contact.object"));
      }
      if (message && message.length === 0) {
        copyError.push(t("contact.message"));
      }
      setErrors(copyError);
      if(name.length === 0 || firstname.length === 0 || smail.length === 0 || subject.length === 0 || message.length === 0){
        window.alert(t("contact.error"));
      }
    }
  }

  function handleChange(e: any, type: any) {
    const copyError = _.cloneDeep(errors);
    switch (type) {
      case t("contact.name"):
        setName(e.target.value);
        break;
      case t("contact.firstname"):
        setFirstname(e.target.value);
        break;
      case t("contact.mail"):
        setMail(e.target.value);
        break;
      case t("contact.object"):
        setSubject(e.target.value);
        break;
      case t("contact.message"):
        setMessage(e.target.value);
        break;
    }
    if (type === t("contact.name") && e.target.value.length > 0) {
      copyError.filter((el: any) => el !== t("contact.name"));
    }
    if (type === t("contact.firstname") && e.target.value.length > 0) {
      copyError.filter((el: any) => el !== t("contact.firstname"));
    }
    if (type === t("contact.mail") && e.target.value.length > 0) {
      copyError.filter((el: any) => el !== t("contact.mail"));
    }
    if (type === t("contact.object") && e.target.value.length > 0) {
      copyError.filter((el: any) => el !== t("contact.object"));
    }
    if (type === t("contact.message") && e.target.value.length > 0) {
      copyError.filter((el: any) => el !== t("contact.message"));
    }
    setErrors(copyError);
  }

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });

  if(mail.success){
    window.alert(`${t("contact.send.mail")}`);
    dispatch(mailActions.clear())
  }
  if(mail.error){
    window.alert(`${t("contact.error.mail")}`)
    dispatch(mailActions.clear())
  }

  return (
    <div>
      <div className="contact-container responsiv" itemScope itemType="https://schema.org/CreativeWork">
        <div className="top-container">
          {!isMobile && (
            <div className="mail-box">
              <div className="svg-box">
                <Mail height="4em" fill="#b77830" />
                <h3>{content.viaMail}</h3>
              </div>
              <FlecheMail height="7em" fill="#333" className="fleche-mail" />
            </div>
          )}
          <div className="image-container">
            <img itemProp="image" src={ContactImg} alt="contact"/>
          </div>
        </div>
        {isMobile && (
          <div className="telephone-number">
            <div className="text-container" itemProp="description">
              <h3>{content.telephoneNumber}</h3>
              <span>{content.freeNumber}</span>
            </div>
            <div className="triangle-container">
              <div className="triangle" />
            </div>
          </div>
        )}
        <div className="center-container">
          <div style={{ width: isTablet ? "100%" : "30%", padding: "50px 0" }}>
            <div className="formulaire-container">
              {formulaire.map((el: string) => (
                <div
                  style={{
                    marginTop: el === t("contact.message") ? "5px" : "0",
                    height:
                      el === t("contact.message")
                        ? isMobile
                          ? "fit-content"
                          : isTablet
                          ? "30%"
                          : "50%"
                        : "auto",
                  }}
                >
                  {el === t("contact.message") ? (
                    <div
                      className="section"
                      style={{
                        borderBottom: "none",
                        height: "100%",
                      }}
                    >
                      <textarea
                        className="form-control"
                        placeholder={el}
                        onChange={(e) => handleChange(e, el)}
                        itemProp="subjectOf"
                      />
                    </div>
                  ) : (
                    <div
                      className="section"
                      style={{
                        borderBottom: "1px solid lightgrey",
                      }}
                      itemProp="subjectOf"
                    >
                      <Input
                        placeholder={el}
                        invalid={errors.includes(el)}
                        defaultValue={
                          el === t("contact.object") ? objet || "" : ""
                        }
                        style={{backgroundColor: "white"}}
                        disabled={objet ? el === t("contact.object") : false}
                        onChange={(e: any) => handleChange(e, el)}
                      />
                    </div>
                  )}
                </div>
              ))}
              <div className="triangle-container">
                <div className="triangle" />
              </div>
              <div
                data-tip
                data-for="send-svg"
                className="svg-send"
                id="send-svg"
                onClick={() => mail.pending ? () => {} : sendMail()}
                itemProp="subjectOf"
              >
                <Envoyer height="4em" fill="#b77830" />
              </div>
              <ReactTooltip id="send-svg" type="dark">
                <span>{content.send}</span>
              </ReactTooltip>
            </div>
          </div>
          <div className="image-contact">
            <img src={ImgRecap} alt="contact"/>
          </div>
          <div className="device-box">
            <div
              style={{
                position: isTablet ? "relative" : "absolute",
                top: '45%',
                left: isTablet ? '0' : '-5%',
                display: isTablet ? "flex" : "block",
                justifyContent: isTablet ? "center" : "inherit",
              }}
            >
              {!isMobile && (
                <>
                  <div className="svg-box" itemProp="about">
                    <Telephone height="4em" fill="#b77830" />
                    <h3>{content.viaPhone}</h3>
                  </div>
                  <FlecheTelephone
                    height="7em"
                    fill="#333"
                    className="fleche-mail"
                  />
                </>
              )}
              {!isMobile && (
                <div className="telephone-number">
                  <div className="text-container" itemProp="description">
                    <a href="tel:0247386000" style={{textDecoration: "none", color: "black"}}><h3>{content.telephoneNumber}</h3></a>
                    <span>{content.freeNumber}</span>
                  </div>
                  <div className="triangle-container">
                    <div className="triangle" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { device, mail } = state;

  return {
    device,
    mail,
  };
}

const connectedContact = connect(mapStateToProps)(Contact);
const tr = withTranslation()(connectedContact);
export default tr;
