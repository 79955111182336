import React from 'react';

const SvgCompatible = (props: any) => (
	<svg viewBox="0 0 40 18.32" {...props}>
		<path fill={props.fill}
			  d="m16.9,12.14h0c.97.93,1.57,2.17,1.68,3.48h0s2.7,0,2.7,0c-.12-2-1-3.88-2.48-5.31-1.48-1.42-3.44-2.27-5.52-2.39v2.6c1.36.11,2.65.69,3.62,1.62m.97,4.16c-.16-.15-.27-.37-.29-.6-.1-1.08-.59-2.1-1.39-2.87-.8-.77-1.86-1.25-2.99-1.34-.52-.04-.92-.46-.92-.96v-2.6c0-.27.11-.52.32-.7.2-.18.47-.28.74-.26,2.33.13,4.52,1.08,6.17,2.67,1.65,1.59,2.64,3.7,2.78,5.94.02.26-.08.52-.27.72-.19.19-.46.3-.73.3h-2.71c-.28,0-.53-.11-.71-.28"/>
		<path fill={props.fill}
			  d="m13.57,15.35c-.21-.2-.49-.31-.79-.31-.61,0-1.11.48-1.11,1.07s.5,1.07,1.11,1.07c.3,0,.58-.11.79-.31.21-.2.33-.47.33-.75s-.12-.55-.33-.76m-2.28,2.2c-.38-.37-.62-.88-.62-1.44,0-1.12.95-2.04,2.12-2.04.57,0,1.1.21,1.5.6.4.38.62.9.62,1.44s-.22,1.05-.62,1.44c-.4.38-.93.6-1.5.6s-1.11-.23-1.5-.6"/>
		<path fill={props.fill}
			  d="m22.13,7.11c2.37,2.28,3.74,5.3,3.87,8.51h2.7s0,0,0,0c-.26-8.06-7.03-14.58-15.41-14.83v2.6c3.33.12,6.47,1.44,8.84,3.72m3.16,9.2c-.17-.17-.28-.39-.29-.65-.11-2.97-1.38-5.76-3.58-7.87-2.19-2.11-5.09-3.33-8.17-3.44-.54-.02-.97-.44-.97-.97V.79c0-.26.11-.51.3-.69.2-.19.46-.28.73-.27C22.23.09,29.43,7.01,29.7,15.58c0,.27-.09.52-.28.71-.19.19-.45.29-.72.29h-2.7c-.28,0-.53-.11-.71-.28"/>
	</svg>
);

export default SvgCompatible;
