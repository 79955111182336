import React, { useRef } from "react";
import { connect } from "react-redux";
import { Controller, Scene } from "react-scrollmagic";
import SvgFiltre from "../../_svg/Filtre";
import VisibilitySensor from "react-visibility-sensor";
import { withTranslation } from 'react-i18next';
import indicatorActions from "../../redux/indicator/indicator.action";

const App = (props) => {
  const { t, dispatch, indicator } = props
  const ref = useRef();
  const content = {
    title: t('applications.filter'),
    description: t('applications.saphir.filter.description')
  }
  return (
              <div className={"svg-container"} id={"svg-filtre-crystalcloud"}>
                  <div>
                    <SvgFiltre fill={"#31c6b3"}/>
                <h3>{content.title}</h3>
                    <span>
                      {content.description}
                    </span>
                  </div>
              </div>
  );
};

function mapStateToProps(state) {
  const { indicator } = state;

  return {
    indicator,
  };
}

const connectedCrystalcloud = connect(mapStateToProps)(App);
const tr = withTranslation()(connectedCrystalcloud);
export default tr;
