import React from 'react';

const SvgCegid = (props: any) => (
	<svg viewBox="0 0 67.82 27.65" {...props}>
		<path fill="#1d1d1b" d="M40.27,6.04v1.49h-.58l-.06-.06c-.98-1.19-2.47-1.85-4.01-1.79-3.61,0-6.43,3.27-6.43,7.45s2.89,7.48,6.62,7.48c2.15,0,3.15-.88,3.78-1.65l.06-.07h.58v2.89c0,1.31-1.06,2.37-2.37,2.38h-4.81v3.5h5.29c3.06,0,5.54-2.48,5.54-5.54V6.04h-3.63Zm-3.65,11.13c-2.13,0-3.68-1.7-3.68-4.04s1.55-3.98,3.68-3.98,3.68,1.68,3.68,3.98-1.58,4.04-3.68,4.04"/>
		<path fill="#1d1d1b" d="M20.09,8.92c-1.68-.07-3.16,1.12-3.45,2.78l-.03,.22h6.92l-.03-.22c-.26-1.66-1.72-2.86-3.4-2.79m.11,11.97c-4.27,0-7.37-3.14-7.37-7.45s3.12-7.5,7.26-7.5,7.09,3.06,7.09,7.45v.83h-10.58v.19c.03,1.95,1.44,3.26,3.54,3.26,1.5,0,2.69-.62,3.25-1.71l3.25,.74c-.93,2.64-3.28,4.16-6.45,4.16"/>
		<path fill="#1d1d1b" d="M48.95,4.3c-1.19-.02-2.13-1.01-2.11-2.19C46.87,.92,47.85-.02,49.04,0c1.16,.02,2.1,.97,2.11,2.13,.01,1.18-.94,2.15-2.12,2.16h-.07m-1.89,2.32h3.64v13.83h-3.64V6.62Z"/>
		<path fill="#1d1d1b" d="M60.5,9.46c-2.13,0-3.68,1.69-3.68,4.01s1.56,4,3.71,4,3.68-1.72,3.68-4.01-1.52-4.01-3.71-4.01m-.77,11.47c-3.71,0-6.62-3.28-6.62-7.48s2.81-7.45,6.4-7.45c1.54-.07,3.03,.59,4,1.78l.06,.07h.58V.16h3.66V20.56h-3.66v-1.36h-.59l-.06,.07c-.83,1.09-2.1,1.64-3.78,1.64"/>
		<path fill="#1d1d1b" d="M7.21,20.84c-4.18,0-7.21-3.08-7.21-7.33S3.18,6,7.57,6c1.46-.01,2.9,.33,4.2,.99l-1.19,2.94c-.83-.41-1.74-.62-2.66-.62-2.32,0-4.16,1.35-4.16,4.13s1.78,4.13,4.21,4.13c.92,0,1.82-.25,2.59-.74l1.25,2.6c-1.35,.94-2.96,1.43-4.61,1.42"/>
	</svg>
);

export default SvgCegid;
