import React, { Component } from "react";
import Poids from "../../_resources/image/poids.png";
import { useMediaQuery } from "react-responsive";

const Poid = (props: any) => {
  const { device, t } = props;
  console.log(device)
  const content = {
    title: t("materials.weight"),
    battery: t("materials.with.battery"),
  };
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });
  return (
    <div className="poid-container">
      <div className="image-container" style={{width: isTablet ? "40%" : "60%", display: "flex", justifyContent: "center", alignItems:"center"}}>
        <img
          src={require(`../../_resources/image/incline/${device.code}.png`)}
          style={{ width: isTablet ? "100%" : device.smart ? "60%" : "40%", display: "block" }}
          alt="telephone"
        />
      </div>
      <div className="text-container" style={{width: isTablet ? "60%" : "40%", paddingLeft: isTablet ? "30px" : "0"}}>
        <h3>{content.title}</h3>
        <h4>
          {device.poids}g {content.battery}
        </h4>
      </div>
    </div>
  );
};
export default Poid;
