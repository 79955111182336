import React from 'react';

const SvgLogoDioptase = (props: any) => (
    <svg viewBox="0 0 670.28 95.89" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="logos">
                <path fill={props.fill}
                      d="M490.87,94.46h15.35L468.06,22.24H452.94l4.63,8.86-33.8,63.36h14.72a20.75,20.75,0,0,1,4.84-3.61,65.39,65.39,0,0,1,8.86-3.27,99.93,99.93,0,0,1,11.65-2.66,77.66,77.66,0,0,1,12.94-1.08h3.77L473.93,71.1a59.08,59.08,0,0,0-15.41,2.1,67.8,67.8,0,0,0-10.49,4l-.37.11c7.52-14.17,17.13-32.41,17.13-32.41Z"/>
                <rect fill={props.fill} x="121.41" y="23.13" width="13.9" height="70.59"/>
                <path fill={props.fill}
                      d="M231.83,30.08a39.83,39.83,0,0,0-13.38-6.95A52.48,52.48,0,0,0,203,20.81a49.45,49.45,0,0,0-15.42,2.45,41.41,41.41,0,0,0-13.38,7.23,36.09,36.09,0,0,0-9.42,11.71,34.68,34.68,0,0,0-3.55,15.95,35.59,35.59,0,0,0,3.48,16A36.14,36.14,0,0,0,174,86a40.87,40.87,0,0,0,13.31,7.36,48.59,48.59,0,0,0,15.56,2.52,47.89,47.89,0,0,0,15.63-2.59,41,41,0,0,0,13.38-7.49,37.67,37.67,0,0,0,9.35-12,35.5,35.5,0,0,0,3.54-16.08,34.19,34.19,0,0,0-12.89-27.66Zm-4.27,38a22.57,22.57,0,0,1-6.12,7.42A27.76,27.76,0,0,1,212.88,80a31.19,31.19,0,0,1-9.78,1.57,36.58,36.58,0,0,1-10-1.37A25.82,25.82,0,0,1,184.47,76a21.63,21.63,0,0,1-6.11-7.22,21.85,21.85,0,0,1-2.31-10.36,21.16,21.16,0,0,1,8.36-17.58A25.11,25.11,0,0,1,193,36.62a36.45,36.45,0,0,1,10-1.37,37.94,37.94,0,0,1,9.85,1.3,25.57,25.57,0,0,1,8.63,4.09,20.42,20.42,0,0,1,8.43,17.23A21.67,21.67,0,0,1,227.56,68Z"/>
                <path fill={props.fill}
                      d="M331.93,29.19a20.14,20.14,0,0,0-7.29-4.5,27.11,27.11,0,0,0-9.33-1.56H270.48V93.71h13.9V72.32q2.31-1.23,4.22-2a18.56,18.56,0,0,1,4.3-1.23,52.57,52.57,0,0,1,5.58-.61q3.21-.21,8.11-.21h6.27q12,0,18.73-6.06t6.75-16.69a24,24,0,0,0-1.71-9.2A20.78,20.78,0,0,0,331.93,29.19ZM321,52.63a17.9,17.9,0,0,1-8.1,1.57h-6.27a99.16,99.16,0,0,0-11.86.68,26.36,26.36,0,0,0-10.35,3.4V37.16h28.48a29.1,29.1,0,0,1,4.15.28,9.89,9.89,0,0,1,3.55,1.15,6.77,6.77,0,0,1,2.52,2.52,8.38,8.38,0,0,1,.95,4.23C324,49.15,323,51.59,321,52.63Z"/>
                <polygon fill={props.fill}
                         points="351.96 37.16 377.58 37.16 377.58 93.71 391.62 93.71 391.62 37.16 418.19 37.16 418.19 23.13 351.96 23.13 351.96 37.16"/>
                <path fill={props.fill}
                      d="M581.45,55.7q-3.68-3-10.53-4.78a75,75,0,0,0-18-1.77,49.46,49.46,0,0,1-8.22-.54,14.94,14.94,0,0,1-4.62-1.43A4.3,4.3,0,0,1,538,45a8.41,8.41,0,0,1-.48-2.93,4.36,4.36,0,0,1,1.29-2.93,11,11,0,0,1,3.33-2.45,21,21,0,0,1,4.55-1.64,21.29,21.29,0,0,1,5.09-.61,46.41,46.41,0,0,1,11.47,1.36,23.54,23.54,0,0,1,9.43,4.77l9.4-10.9A46.21,46.21,0,0,0,567,22.58a64.5,64.5,0,0,0-15.13-1.77,40.94,40.94,0,0,0-11.17,1.5,30.21,30.21,0,0,0-9.27,4.29,22.33,22.33,0,0,0-6.33,6.75,16.55,16.55,0,0,0-2.39,8.72,20.23,20.23,0,0,0,2.18,9.74A17.79,17.79,0,0,0,531,58.35,26.38,26.38,0,0,0,540.14,62a58.25,58.25,0,0,0,11.72,1.09q11,0,16.42,1.5t5.39,6.27q0,5.73-6,8.58t-15.81,2.87a25.53,25.53,0,0,1-8.17-1.16,23.27,23.27,0,0,1-5.93-2.93,25.55,25.55,0,0,1-4.36-3.82c-1.23-1.36-2.38-2.59-3.48-3.68L519.84,80.9a42.26,42.26,0,0,0,14.24,11,40.62,40.62,0,0,0,17.78,4,60.4,60.4,0,0,0,14-1.56,37.41,37.41,0,0,0,11.38-4.64,22.51,22.51,0,0,0,7.56-7.76,21.86,21.86,0,0,0,2.72-11.11,27.43,27.43,0,0,0-1.22-8.18A14.49,14.49,0,0,0,581.45,55.7Z"/>
                <path fill={props.fill}
                      d="M626,79.54V68.37q2.31-1.23,4.22-2.05a19.21,19.21,0,0,1,4.23-1.22,49.64,49.64,0,0,1,5.52-.62c2.13-.13,4.84-.2,8.1-.2h10.36v-14H648.06a99.29,99.29,0,0,0-11.78.68A26.47,26.47,0,0,0,626,54.33V37.16h41.7v-14h-55.6V93.71h58.19V79.54Z"/>
                <path fill={props.fill}
                      d="M65.41,62.63a19.33,19.33,0,0,1-5.22,10.92Q54.57,79.2,44.28,79.2H30.15V40.79h-15V93.51H44.19A41.16,41.16,0,0,0,59,91a32.52,32.52,0,0,0,11.43-7.23,31.3,31.3,0,0,0,7.3-11.43,39.11,39.11,0,0,0,2.22-9.68H65.41Z"/>
                <polygon fill={props.fill} points="39.84 22.2 0 22.2 6.33 36.38 39.84 36.38 39.84 22.2 39.84 22.2"/>
                <path fill={props.fill}
                      d="M77.72,43.4A31.3,31.3,0,0,0,70.42,32,32.52,32.52,0,0,0,59,24.74a38.76,38.76,0,0,0-9.15-2.21V37a19.05,19.05,0,0,1,10.35,5.15,19.13,19.13,0,0,1,5.13,10.46H79.88A39.19,39.19,0,0,0,77.72,43.4Z"/>
                <path fill={props.fill}
                      d="M87.19,52.62h-2A35.38,35.38,0,0,0,49.85,17.28v-2A37.38,37.38,0,0,1,87.19,52.62Z"/>
                <path fill={props.fill}
                      d="M93.12,52.61h9.35A52.62,52.62,0,0,0,49.87,0V9.35A43.27,43.27,0,0,1,93.12,52.61Z"/>
            </g>
        </g>
    </svg>
);
export default SvgLogoDioptase;