import React from 'react';

const SvgSecurite = (props: any) => (
	<svg viewBox="0 0 24 14.46" {...props}>
		<path fill={props.fill} d="m13.08,12.07c0,.08-.02.16-.07.22s-.13.1-.22.1h-1.15c-.08,0-.16-.04-.21-.1-.06-.06-.09-.14-.07-.22l.18-1.6c-.51-.37-.61-1.07-.23-1.56.38-.49,1.1-.59,1.61-.22.51.37.61,1.07.23,1.57-.07.08-.14.16-.23.22l.17,1.6Zm1.15-7.93v2.33h.59v-2.33c0-1.56-1.31-2.83-2.92-2.83s-2.92,1.27-2.92,2.83v2.02h-.58v-2.02c0-1.88,1.57-3.4,3.5-3.4,1.93,0,3.5,1.52,3.5,3.4h0v2.17h.59v-2.17c0-2.19-1.84-3.96-4.09-3.96-2.26,0-4.09,1.77-4.09,3.96v2.02h-.58c-.16,0-.29.13-.29.28v7.08c0,.62.52,1.12,1.15,1.12h8.19c.64,0,1.15-.5,1.15-1.12v-7.08c0-.15-.12-.28-.28-.28h-7.59v-2.02c0-1.25,1.05-2.26,2.34-2.26,1.29,0,2.34,1.01,2.34,2.26Z"/>
	</svg>

);

export default SvgSecurite;
