import React, { Component, useState, useEffect } from "react";

import LogoCC from "../../_svg/LogoCrystalcloud";
import LogoMaConso from "../../_svg/LogoMaConso";

import FooterSection from "./FooterSection";
import SvgLogiciels from "../../_svg/Logiciel";
import CCMockup from "../../_resources/image/synchro_cc.png";
import MaConsoMockup from "../../_resources/image/logiciels_maconso.png";
import imgIntervention from '../../_resources/image/intervention/vitrine_intervention.png';
import LogoIntervention from "../../_svg/LogoIntervention";

import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { padding } from "@mui/system";


const ImgDisplayer = (props: any) => {
  const { src } = props;
  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div
      className="video-container"
      style={{
        width: isTablet ? "100%" : "70%",
        position: "relative",
        height: isTablet ? "50%" : "100%",
        left: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={src}
        style={isTablet ? {marginTop: 0, width: "100%", height: "auto"} : { marginTop: 0, width: "auto", height: "100%" }}
        alt="logiciels"
      />
    </div>
  );
};

const Logiciels = (props: any) => {
  const { t } = props;

  const [hover, setHover] = useState("")

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
  })

    const listLogiciels = [
      {
        title: "Crystalcloud",
        svg: <LogoCC height="4.2em" />,
        text: t("logiciels.cc.description"),
        color: "#31c6b3",
        url: "crystalcloud",
        img: <ImgDisplayer src={CCMockup}/>,
      },
      {
        title: "Intervention",
        svg: <LogoIntervention height="7em" />,
        text: t("intervention.resume"),
        color: "#E96F90",
        url: "intervention",
        img: <ImgDisplayer src={imgIntervention}/>,
      },
    ];

    /*
      {
        title: "maconso.eco",
        svg: <LogoMaConso height="7em" />,
        text: t("logiciels.maconso.description"),
        color: "#D0D146",
        url: "maconso",
        img: <ImgDisplayer src={MaConsoMockup}/>,
      },
    */

    const content = {
      buttonMore: t("all.button.more"),
    };

    const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

    return (
      <>
        <div className={"logiciels-container produits responsiv"} style={{justifyContent: isTablet ? "unset" : "space-evenly", height: isTablet ? "auto" : "100%"}}>
          {listLogiciels.map((el: any, index: number) => (
            <div
              style={{
                display: isTablet ? "block" : "flex",
                height: isTablet ? "100%" : "500px",
                alignItems: "center",
                borderBottom: index === 0 || index === 1 ? "3px solid grey" : "none",
                marginTop: (isTablet && el.title === "Tourmaline") ? "52px" : "0",
              }}
            >
              {el.title === "maconso.eco" && !isTablet && el.img}
              <div className="center-container" style={{width: isTablet ? "100%" : "50%"}}>
                <div
                  className={"section-container"}
                  style={{
                    width: isTablet ? "100%" : "80%",
                    textAlign: el.title === "Crystalcloud" ? "right" : "left",
                  }}
                >
                  <div
                    className="svg-box"
                    style={{
                      justifyContent:
                        isTablet ? "center" : el.title === "Crystalcloud" ? "flex-end" : "flex-start",
                    }}
                  >
                    {el.svg}
                  </div>
                  <div className="text-container" style={isTablet ? {display: "flex", flexDirection:"column", alignItems:"center", marginLeft: 0} : { marginLeft: 0 }}>
                    <h5
                      style={{
                        color: el.color,
                        textAlign: 'left'
                      }}
                    >
                      {el.text}
                    </h5>
                    <div
                      className={"button-download"}
                      style={{
                        marginLeft: 0,
                        float: el.title === "Crystalcloud" ? "right" : "left",
                      }}
                    >
                      <a href={`/logiciels/${el.url.toLowerCase()}`}>
                        <button
                          style={{
                            borderColor:
                              el.title === hover ? "white" : el.color,
                            backgroundColor:
                              el.title === hover ? el.color : "white",
                          }}
                          onMouseEnter={() =>
                            setHover(el.title)
                          }
                          onMouseLeave={() => setHover("")}
                        >
                          <span>{content.buttonMore}</span>
                          <SvgLogiciels
                            fill={el.title === hover ? "white" : el.color}
                            height={"30px"}
                          />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {(el.title === "Crystalcloud" || el.title === "Intervention") && el.img}
              {el.title === "maconso.eco" && isTablet && el.img}
            </div>
          ))}
        </div>
        <FooterSection t={t} />
      </>
    );
  }

const tr = withTranslation()(Logiciels);
export default tr;
