import React, { Component } from 'react';
import SchemaDld from '../../_resources/image/schema_DLD.png';

export default class Mecanisme extends Component<any, any>{
    render(){
        return(
            <div className={"mecanisme-container"} itemScope itemType="https://schema.org/Thing">
                <div className="container image">
                    <div >
                    <img itemProp="image" src={SchemaDld} height="100%" width="auto" alt="dld"/>
                    </div>
                </div>
            </div>
        )
    }
}