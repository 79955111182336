import React from "react";
import { Route } from "react-router-dom";
import Error  from "../component/error/Error404";

interface IDefaultProps {
  component: any;
  layout: any;
  path?: string;
  exact?: boolean;
  error?: boolean;
}

export const PublicRoute: React.FunctionComponent<IDefaultProps> = ({
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>{ 
      const { error } = rest
      return (
      <Layout>
        <Component {...props} />
      </Layout>
    )}}
  />
);
