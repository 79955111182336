import React from "react";
import SvgFiltre from "../../_svg/Filtre";
import VisibilitySensor from "react-visibility-sensor";
import { withTranslation } from 'react-i18next';

const App = (props) => {
  const { t } = props
  const content = {
    title: t('applications.filter'),
    description: t('applications.saphir.filter.description')
  }
  return (
              <div className={"svg-container"} id={"svg-filter-tm"}>
              <VisibilitySensor
                  onChange={(isVisible) => {
                    if (isVisible) {
                      props.setVisible("filter");
                    }
                  }}
                >
                <div>
                  <SvgFiltre fill={"#3e9f92"} />
                  <h3>{content.title}</h3>
                  <span>
                    {content.description}
                  </span>
                </div>
                </VisibilitySensor>
              </div>
  );
};

const tr = withTranslation()(App);
export default tr;