import React from 'react';

const SvgFlecheCourbeGauche = (props: any) => (
<svg viewBox="0 0 272.4 243" {...props}>
<path d="M103.9,67.9C60.7,109.5,34.8,164.6,30.2,224L7,195.3l-7,5.7L33.9,243l39.5-36.9l-6.1-6.6l-28.2,26.4
	c4.2-57.6,29.2-111.1,71.1-151.4C154,32.2,211.6,9,272.4,9V0C209.3,0,149.4,24.1,103.9,67.9z"/>
</svg>
);

export default SvgFlecheCourbeGauche;
