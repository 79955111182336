import React, {Component} from 'react';
import { NavbarMenu } from '../sidebars/index';

export default function MainLayout(props) {
    const { children } = props;
    return (
            <div style={{height:"100%"}}>
                <div className="wrapper" style={{height:"100%"}}>
                    <div className="menu-header">
                        <NavbarMenu />
                    </div>
                    <section className="container-fluid" style={{ padding:0}}>{children}</section>
                </div>
            </div>
    );
}