import React from "react";
import { Route } from "react-router-dom";
import JwtDecode from 'jwt-decode';
import history from '../_helpers/history';

interface IDefaultProps {
  component: any;
  layout: any;
  path?: string;
  exact?: boolean;
  error?: boolean;
}

export const PrivateRoute: React.FunctionComponent<IDefaultProps> = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const token = localStorage.getItem('token')
  if(token){
      const decode: any = JwtDecode(token)
      if((decode.exp - new Date().getTime() / 1000) < 0){
          history.push('/admin')
      }
  } else {
    history.push('/admin')
  }
  return <Route
    {...rest}
    render={(props) => {
      const { error } = rest
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
}
