import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import SvgSaphir from "../../_svg/SaphirLogo";
import Recap from "./Footer";
import BarIndicator from "../../sidebars/IndicatorBar";
import Footer from "../section/FooterSection";
import Compatible from "../onyx/Compatible";
import Contact from "../onyx/Contact";

import images from "../../_resources/saphirVideo1";
import images2 from "../../_resources/saphirVideo2";
import images3 from "../../_resources/saphirVideo3";
import images4 from "../../_resources/saphirVideo4";
import images5 from "../../_resources/saphirVideo5";
import images6 from "../../_resources/saphirVideo6";
import images7 from "../../_resources/saphirVideo7";
import images11 from "../../_resources/saphirVideo11";
import images13 from "../../_resources/saphirVideo13";
import images14 from "../../_resources/saphirVideo14";

import SaphirProtocole from "./SaphirSite";
import SaphirCompteur from "./SaphirCompteur";
import SaphirFiltre from "./SaphirFilter";
import SaphirConcat from "./SaphirConcat";
import SaphirGeoguidage from "./Geoguidage";
import SaphirGsm from "./SaphirGSM";
import SaphirGeoloc from "./Geolocalisation";
import SaphirSupport from "./Support";
import SaphirSecurite from "./Securite";

import ScrollSequence from "../scroll/ScrollSequence";
import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import ImageSelector from "../responsive/ImageSelector";
import PopUp from "../responsive/PopUp";

import ReactGA from 'react-ga4';
import docActions from "../../redux/doc/doc.action";
import {connect} from "react-redux";
import { useMatomo } from '@datapunt/matomo-tracker-react'

const App = (props) => {
  const { dispatch, t } = props;
  const { trackLink } = useMatomo()

  const tablet = useMediaQuery({ query: "(max-width: 1040px)" });

  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  })

  window.addEventListener("scroll", () => {
    const svg = document.getElementById("svg-saphir");
    if (svg && !tablet) {
      svg.style.marginTop = "100px";
    }
  });

  let isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const [modal, setModal] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [init, setInit] = useState(false);

  if (!init) {
    setIsTablet(tablet);
    setInit(true);
  }

  function timeOutFunction() {
    toggle();
  }

  const toggle = () => {
    setModal(!modal);
  };

  const callBackModal = (result) => {
    if (result) {
      window.localStorage.setItem('lowDebit', true)
      setIsTablet(true);
      toggle();
    } else {
      window.localStorage.setItem('lowDebit', false)
      toggle();
    }
  };

  const localItem = window.localStorage.getItem('lowDebit')
  if(localItem === "true" && !isTablet){
    setIsTablet(true)
  }

  function downloadFile() {
    trackLink({ href: 'Saphir', linkType: 'download' })
    dispatch(docActions.getDoc("saphir", "fr"));
  }

  const content = {
    button: t("all.button.download"),
  };

  return (
    <div className={"saphir-container responsiv"} itemScope itemType="https://schema.org/WebPage">
      <MetaTags>
            <title>Saphir</title>
            <meta
      name="description"
      content="Application android, solution pour toute relève de compteur, pour relever vos compteurs d'aujourd'hui et de demain"
    />
    <meta name="keywords" content="application, solution releve compteur, android, tableau de bord, graphique de consommation, compatible multi-facturation, recherche par filtres, relève radio propriétaire, gestion multi-tournées, relève radio wireless M-Bus, Appareil photo, relève manuelle, changement de compteur, gestion des alarmes radio, cartographie, compatibilité" />
    <meta property="og:title" content="Saphir"/>
    <meta property="og:type" content="Application - solution de releve compteurs"/>
    <meta property="og:url" content="https://www.dioptase.fr/applications/saphir"/>
    <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
    <meta property="og:description" content="Solution de relève de compteurs" />
          </MetaTags>
            {modal && <PopUp callBack={callBackModal}/>}
      <BarIndicator parent="saphir" />
      <div
        className={"svg-container"}
        id={"svg-saphir"}
        itemProp="headline"
      >
        <SvgSaphir />
        <div className={"download-documentation"}>
          <div className={"button-download"} itemProp="subjectOf">
            <button style={{ borderColor: "#31c3b6" }} onClick={downloadFile}>
              {content.button}
            </button>
          </div>
        </div>
      </div>
      <div className="video-container" style={{ display: "block" }}>
        <video
          width="60%"
          height="auto"
          autoPlay
          muted
          poster={images("", "fr")[images("", "fr").length / 2]}
          id="home-video"
          style={{ marginLeft: isMobile ? "0" : "20%" }}
          itemProp="video"
        >
          <source
            src={require("../../_resources/fullVideo/video_acceuil_saphir_site.mp4")}
            type="video/mp4"
          />
        </video>
      </div>
      {isTablet ? (
        <ImageSelector
          src={images2("", "fr")[images2("", "fr").length - 1]}
          children={<SaphirProtocole />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={1}
          className="saphir-dashboard"
          images={images2("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          style={{ marginTop: "0", width: `${window.innerWidth}px` }}
          stretch
          zoom={0.7}
          start={window.innerHeight}
          y={52}
          children={<SaphirProtocole />}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images3("", "fr")[images3("", "fr").length - 1]}
          children={<SaphirCompteur />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={2}
          className="saphir-changement"
          images={images3("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          style={{ marginTop: "0", width: `${window.innerWidth}px` }}
          stretch
          zoom={0.7}
          start={window.innerHeight + 4000}
          y={52}
          x={0}
          children={<SaphirCompteur setVisible={() => {}} />}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images4("", "fr")[images4("", "fr").length - 1]}
          children={<SaphirFiltre />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={3}
          className="saphir-filtre"
          images={images4("", "fr")}
          height={`${window.innerHeight}px`}
          width={`${window.innerWidth}px`}
          start={window.innerHeight + 8000}
          y={52}
          sTop
          stretch
          zoom={1}
          children={<SaphirFiltre setVisible={() => {}} />}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images5("", "fr")[50]}
          children={<SaphirConcat />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={4}
          className="saphir-concat"
          images={images5("", "fr")}
          height={`${window.innerHeight}px`}
          width={`${window.innerWidth}px`}
          start={window.innerHeight + 12000}
          y={52}
          children={<SaphirConcat />}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images6("", "fr")[images6("", "fr").length - 1]}
          children={<SaphirGeoguidage />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={5}
          className="saphir-geoguidage"
          images={images6("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          style={{
            marginTop: "0",
            marginLeft: `${(window.innerWidth - 1600) / 2}px`,
          }}
          stretch
          zoom={0.7}
          start={window.innerHeight + 16000}
          x={(window.innerWidth - 1600) / 2}
          y={52}
          children={<SaphirGeoguidage setVisible={() => {}} />}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images7("", "fr")[150]}
          children={<SaphirGsm />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={6}
          className="saphir-GSM"
          images={images7("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          style={{ marginTop: "0", width: `${window.innerWidth}px` }}
          start={window.innerHeight + 20000}
          y={0}
          stretch
          x={window.innerWidth * -0.15}
          zoom={1}
          children={<SaphirGsm />}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images11("", "fr")[images11("", "fr").length - 1]}
          children={<SaphirGeoloc />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={7}
          className="saphir-geoloc"
          images={images11("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          style={{ marginTop: "0", width: `${window.innerWidth}px` }}
          start={window.innerHeight + 24000}
          y={104}
          stretch
          zoom={1}
          geoloc
          children={<SaphirGeoloc />}
          accelerate={2}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images13("", "fr")[images13("", "fr").length - 1]}
          children={<SaphirSupport />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={8}
          className="saphir-support"
          images={images13("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          style={{ marginTop: "0", width: `${window.innerWidth}px` }}
          start={window.innerHeight + 28000}
          precipitate
          stretch
          zoom={0.7}
          y={52}
          children={<SaphirSupport setVisible={() => {}} />}
          callBack={timeOutFunction}
        />
      )}
      {isTablet ? (
        <ImageSelector
          src={images14("", "fr")[32]}
          children={<SaphirSecurite />}
          className="filter"
        />
      ) : (
        <ScrollSequence
          duration={4000}
          index={9}
          className="saphir-securite"
          images={images14("", "fr")}
          height={window.innerHeight}
          width={window.innerWidth}
          style={{ marginTop: "0", width: `${window.innerWidth}px` }}
          start={window.innerHeight + 32000}
          precipitate
          y={52}
          stretch
          zoom={1}
          security
          accelerate={0}
          children={<SaphirSecurite />}
          callBack={timeOutFunction}
        />
      )}
      <Recap t={t} />
      <Compatible
        parent="saphir"
        section={"Logiciels"}
        parentColor="#cf7830"
        t={t}
      />
      <Contact t={t} objet="Saphir" />
      <Footer t={t} />
    </div>
  );
};

function mapStateToProps(state) {
  const { indicator } = state;

  return {
    indicator,
  };
}

const connectedDoc = connect(mapStateToProps)(App);
const tr = withTranslation()(connectedDoc);
export default tr;
