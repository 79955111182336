import React, { useRef } from "react";
import { connect } from "react-redux";
import { Controller, Scene } from "react-scrollmagic";
import SvgSynchronisation from "../../_svg/SynchroGSM";
import VisibilitySensor from "react-visibility-sensor";
import { withTranslation } from 'react-i18next';
import indicatorActions from "../../redux/indicator/indicator.action";

const App = (props) => {
  const { t, dispatch, indicator } = props
  const ref = useRef();
  const content = {
    title: t('logiciels.cc.synchronisation'),
    description: t('logiciels.cc.synchronisation.description')
  }
  return (
              <div className={"svg-container"} id="svg-synchronisation" itemScope itemType="https://schema.org/WebPage">
                  <div>
                    <SvgSynchronisation fill={"#31c6b3"} height="2em"/>
                    <h3 itemProp="alternativeHeadline">{content.title}</h3>
                    <span itemProp="description">
                      {content.description}
                    </span>
                  </div>
              </div>
  );
};

function mapStateToProps(state) {
  const { indicator } = state;

  return {
    indicator,
  };
}

const connectedCrystalcloud = connect(mapStateToProps)(App);
const tr = withTranslation()(connectedCrystalcloud);
export default tr;
