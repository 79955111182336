import React from "react";

const ConsoMeter = (props) => {
	console.log(props.fill	)
	return (
		<svg id="Calque_1" viewBox="0 0 139.5 71.3" {...props}>
			<g>
				<path fill={props.fill} d="M104.3,28.9c-0.7-3.5-2.6-7-4.5-9.8c-1.4-2-3.9-6-6.7-7.5c-3.6-4.3-8.5-7.5-14-9.3C68.1-1.3,56.5-0.8,47.6,4.9
		c-0.2-0.1-0.4,0-0.3,0.2C43,8,39.3,12,36.9,17.4c-4.2,9.2-4.3,21.5,0.4,31c1.6,5.7,6.5,11.1,11,14.6c3,2.3,7.8,5.8,12.2,6.5
		c1,0.5,1.9,0.9,3,1.2c0.8,0.2,1.8,0.5,2.6,0.4c5.4,0.7,12.1-0.9,16.5-2.5c6.1-2.1,11.2-6.3,15.4-11.2c4-4.6,6.3-9.4,7.2-15.5
		C105.7,38.4,105.8,33,104.3,28.9z M103.3,40.7c-0.1,0.6-0.2,1.2-0.3,1.8c-1.3-1.1-2.9-1.6-4.5-2c0.2-1.2,0.4-2.3,0.5-3.5
		c0.8,0.2,1.5,0.5,2.3,0.8c0.8,0.3,1.4,0.8,2.1,1.3C103.4,39.7,103.4,40.2,103.3,40.7z M100.1,51c-0.1-0.1-0.3-0.2-0.5-0.3
		c-0.6-0.3-1.2-0.8-1.7-1.3c-0.4-0.4-0.8-0.8-1.3-1.2c-0.1-0.1-0.3-0.2-0.4-0.3c0.4-1,0.8-2,1.2-3c0.4,0.3,0.7,0.6,1.1,0.9
		c0.8,0.6,1.7,1.5,2.7,1.9c0.4,0.1,0.8-0.4,0.5-0.7c-0.6-0.7-1.7-1.1-2.5-1.5c-0.6-0.3-1.1-0.6-1.6-1c0.4-1.1,0.6-2.3,0.9-3.4
		c1.4,0.6,2.9,1.4,4.1,2.4c0.1,0.1,0.2,0.1,0.4,0.1C102.3,46.3,101.4,48.7,100.1,51z M96.3,56.4c-1.3-0.8-2.6-1.6-4-2.4
		c0.7-0.9,1.4-1.8,2-2.7c0.3,0.5,0.8,1,1.3,1.5c0.4,0.4,0.9,0.9,1.3,1.3c0.2,0.3,0.4,0.6,0.6,0.8C97.1,55.4,96.7,55.9,96.3,56.4z
		 M94.1,58.9c-1.2-0.9-2.5-1.9-3.8-2.6c0.6-0.6,1.2-1.2,1.7-1.9c1.2,1,2.4,1.9,3.7,2.7C95.2,57.7,94.6,58.3,94.1,58.9z M91.9,60.8
		c-1.2-1-2.6-1.9-4.1-2.4c0.7-0.5,1.3-1.1,2-1.7c1.1,1,2.3,2,3.5,2.9C92.8,60.1,92.4,60.5,91.9,60.8z M88.4,63.4
		c-0.7-0.7-1.6-1.2-2.3-1.6c-0.5-0.3-1-0.7-1.6-1c0.9-0.5,1.8-1.1,2.6-1.8c1.5,0.6,2.6,1.7,3.8,2.6C90.2,62.2,89.3,62.8,88.4,63.4z
		 M82.3,64.4c-0.7-0.5-1.4-0.9-2-1.5c1.2-0.5,2.4-1.1,3.5-1.7c0.5,0.4,1,0.7,1.5,1.1c0.7,0.5,1.3,1.2,2,1.8c-1,0.6-2.1,1.2-3.2,1.6
		C83.6,65.2,82.9,64.8,82.3,64.4z M96.8,52.7c-0.7-0.6-1.4-1.2-2.1-1.9c0.5-0.7,0.9-1.5,1.3-2.3c0,0.1,0.1,0.2,0.2,0.3
		c0.4,0.6,0.9,1.1,1.4,1.5c0.5,0.5,1.3,1.3,2.1,1.6c-0.4,0.7-0.9,1.4-1.4,2.1C97.8,53.5,97.2,53,96.8,52.7z M102.6,29.6
		c0.1,0.7,0.2,1.4,0.3,2c-1.1-0.7-2.4-1-3.6-1.1c0-0.7-0.1-1.4-0.1-2.1c0.3,0.1,0.7,0.1,1,0.2C101,28.9,101.7,29.4,102.6,29.6
		C102.5,29.6,102.6,29.6,102.6,29.6z M99.3,30.9c1.2,0.3,2.5,0.8,3.5,1.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0.7,0.2,1.4,0.3,2.1
		c-1.3-0.4-2.7-0.6-4-1C99.3,32.7,99.3,31.8,99.3,30.9z M102.4,28.7c-0.9-0.5-2.2-0.7-3.3-0.8c-0.1-0.6-0.1-1.2-0.2-1.8
		c0.9,0.5,1.8,1,2.6,1.5c0.5,0.3,0.9-0.4,0.5-0.7c-1-0.7-2.1-1.1-3.2-1.5c-0.1-0.7-0.2-1.3-0.3-2c0.5,0.3,0.9,0.6,1.4,0.9
		c0.5,0.3,1.1,0.8,1.8,0.8c0.1,0.1,0.2,0.3,0.2,0.4C102.1,26.6,102.3,27.7,102.4,28.7z M101.1,24.1c-0.2-0.1-0.4-0.1-0.6-0.2
		c-0.7-0.3-1.4-0.7-2.1-1c-0.1-0.3-0.2-0.7-0.2-1c0.3,0.1,0.7,0.2,1,0.4c0.3,0.1,0.6,0.2,0.9,0.4C100.4,23.1,100.7,23.6,101.1,24.1z
		 M99.1,36.6c0.1-0.9,0.1-1.7,0.2-2.6c1.3,0.6,2.8,1,4.1,1.6c0,0.7,0.1,1.4,0.1,2.1C102.2,37.1,100.4,36.8,99.1,36.6z M97.8,19.5
		c0.5,0.7,1.1,1.5,1.7,2.3c-0.2,0-0.3-0.1-0.4-0.1c-0.3-0.1-0.7-0.2-1-0.2c0-0.2-0.1-0.4-0.1-0.5c-0.2-0.8-0.5-1.6-0.8-2.4
		C97.3,18.8,97.6,19.2,97.8,19.5z M36.7,23.8c1.4-5.5,4.4-10.5,8.5-14.5c0.9,0.8,2.1,1.4,3.2,1.7c0.2,0,0.3-0.2,0.1-0.3
		c-1.1-0.4-2-1.1-2.9-1.7c0.8-0.7,1.6-1.4,2.5-2.1c0.1-0.1,0.2-0.1,0.3-0.2c0.7,0.7,1.5,1.4,2.3,2c0.1,0.1,0.3-0.1,0.2-0.2
		c-0.5-0.5-1-1.1-1.5-1.7c-0.1-0.2-0.3-0.3-0.4-0.5c6.7-4.6,14.6-4.9,22.4-3.7c8.6,1.3,16.4,4.8,21.3,12c-1.6-1.3-4.6-1.1-6.3-1.5
		c-0.1,0-0.2,0.1-0.1,0.2c1.1,0.4,2.2,0.7,3.3,1c0.7,0.2,1.4,0.4,2.1,0.7c0.6,0.3,1,0.8,1.5,1.1c0.1,0.1,0.3,0,0.4-0.1
		c0.1,0.2,0.2,0.3,0.3,0.4c1,1.8,1.8,3.7,2.4,5.7c-1-2-4-1.9-5.9-2.7c-0.1-0.1-0.2,0.2-0.1,0.2c1.9,0.9,5.5,0.7,6,3.3
		c0,0.1,0.1,0.1,0.2,0.1c1.9,7.9,0.6,16.8-2.2,24.1c-1,2.8-2.7,5.3-4.9,7.4c0-0.3,0-0.6,0-0.8c0-0.6,0-1.3,0-1.9
		c0-0.3-0.4-0.3-0.4,0c-0.1,0.6-0.1,1.3-0.2,1.9c0,0.5-0.1,1-0.1,1.5c-1.1,1-2.2,1.9-3.4,2.8c0-0.8,0-1.7,0-2.5c0-0.1-0.2-0.1-0.2,0
		l-0.3,2.5c0,0.1,0,0.3-0.1,0.4c-1.7,1.1-3.4,2-5.3,2.8c0.3-1.4,0.8-2.7,1.5-3.9c0.1-0.2-0.2-0.5-0.3-0.2c-0.7,1.4-1.2,2.8-1.6,4.3
		c-2.1,0.8-4.2,1.5-6.3,1.9c-5,1-9.8,0.4-14.4-1.1c0-0.1-0.1-0.1-0.2-0.2c-1.9-0.9-3.8-1.5-5.7-2.2c-1.6-0.8-3.3-1.7-4.8-2.6
		c0.3-1,0.5-1.9,0.9-2.8c0.1-0.2-0.2-0.2-0.2-0.1c-0.4,0.9-0.7,1.8-1,2.7c-0.6-0.3-1.2-0.7-1.7-1c-1.9-1.7-3.5-3.7-4.9-5.9
		c0.7-1.4,1.3-2.8,1.8-4.3c0-0.1-0.1-0.2-0.2-0.1c-0.5,1.5-1.1,2.8-1.8,4.2c-0.5-0.8-1-1.7-1.5-2.6c-0.4-0.7-0.7-1.5-1-2.3
		c1-1.7,2.2-3.3,2.9-5.1c0.5,1.1,1,2.1,1.6,3.1c1.2,1.9,2.2,4.2,3.5,6.2c-0.7,1.3-1.1,2.7-1.6,4.1c-0.1,0.2,0.2,0.3,0.3,0.1
		c0.5-1.3,0.9-2.6,1.5-3.9c0.5,0.7,1,1.4,1.5,2.1c2.4,2.7,5.4,3.9,8.8,5c0.3,0.1,0.5-0.3,0.2-0.4c-2.4-1.6-5.2-2.3-7.4-4.4
		c-2.2-2-2.7-5.1-4.4-7.4c-3.9-5.2-5.6-11.2-3.7-17.6c2.2-7.7,10.4-21.7,19.8-20.9c0.5,0,0.6-0.8,0-0.8c-6-0.7-11.2,3.9-15,9.4
		c0,0,0,0,0,0c-1.2-0.4-2.4-0.9-3.3-1.7c-0.3-0.2-0.6,0.2-0.3,0.4c1.1,0.8,2.3,1.2,3.6,1.4c-2.7,4-4.8,8.5-5.9,11.9
		c-1,3-1.1,6.2-0.5,9.2c-0.8,0.6-1.7,1.1-2.5,1.7c-0.3,0.2-0.8,0.5-1.1,0.9C35.2,33.5,35.5,28.6,36.7,23.8z M75.2,68.5
		c-1-1.3-2.2-2.5-3.5-3.4c1.3-0.2,2.7-0.4,4-0.7c0.7,1.3,1.7,2.5,2.9,3.4C77.5,68.1,76.4,68.3,75.2,68.5z M71.5,69
		c-0.5-0.7-1.1-1.5-1.6-2.1c-0.4-0.5-0.9-1.1-1.4-1.6c0.9,0,1.9-0.1,2.8-0.2c1.1,1,2,2.3,2.9,3.6C73.3,68.8,72.4,68.9,71.5,69z
		 M62.1,64.9c0.7,0.5,1.3,1.2,1.8,1.9c0.6,0.7,1.1,1.5,1.5,2.3c-0.5,0-1-0.1-1.5-0.1c-0.7-0.3-1.4-0.6-2.2-0.9c0,0,0,0,0,0
		c-0.8-0.8-1.4-1.7-2.2-2.5c-0.7-0.8-1.4-1.3-2.3-1.7C58.9,64.2,60.4,64.6,62.1,64.9C62.1,64.9,62.1,64.9,62.1,64.9z M56.7,65.9
		c-0.9-1-1.8-2.1-2.6-3.2c0.5,0.2,1,0.4,1.5,0.6c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.1,0.1,0,0.1c2,0.2,3.4,2.4,4.5,3.9
		C58.7,66.7,57.7,66.3,56.7,65.9z M52,63.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.5-0.8-1.1-1.1-1.7c0.4,0.2,0.8,0.3,1.3,0.5
		c0.5,0.2,1,0.5,1.6,0.7c0.5,0.9,1,1.8,1.6,2.6C54,64.5,53,63.9,52,63.3z M40.9,39.7c-0.8,1.7-2.2,3.1-3.1,4.7
		c-0.7-1.7-1.2-3.4-1.6-5.2c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.5,0.7-0.8,1.2-1.2c0.7-0.6,1.4-1.1,2-1.7C40,37.2,40.4,38.5,40.9,39.7z
		 M47.3,59.6c0.7,0.4,1.5,0.8,2.3,1.2c0.1,0,0.2,0.1,0.2,0.1c0.3,0.5,0.5,1,0.8,1.5c-0.5-0.3-0.9-0.7-1.4-1
		C48.6,60.8,48,60.2,47.3,59.6z M67.1,69.1C66.4,68,65.6,67,64.7,66c-0.2-0.2-0.5-0.6-0.9-0.9c1.3,0.1,2.5,0.2,3.8,0.2
		c0.5,0.8,1.1,1.6,1.6,2.4c0.3,0.4,0.5,0.9,0.8,1.4C69.1,69.1,68.1,69.1,67.1,69.1z M79.7,67.4c0,0-0.1-0.1-0.1-0.1
		c-1.3-0.8-2.5-1.8-3.5-3c1.2-0.3,2.5-0.7,3.7-1.2c0.4,0.5,0.9,1,1.4,1.5c0.5,0.5,1.1,1.2,1.7,1.7C81.8,66.7,80.8,67.1,79.7,67.4z"
				/>
				<path fill={props.fill} d="M57.1,48.2c-0.5,1.3-0.8,2.7-0.7,4.1c0,0.3,0.4,0.3,0.5,0C57.1,51.1,56.6,49.4,57.1,48.2z" />
				<path fill={props.fill} d="M71.3,6.3c25.3,2,28.6,50.7,1,50.4c-0.2,0-0.3,0.3-0.1,0.3c5.9,1.3,11.8-1.5,15.7-6c4.6-5.3,4.6-13.7,4.5-20.3
		c-0.1-8.2-4.1-16.5-10.5-21.3c0.4,0,0.9,0,1.3,0c0.8,0.1,1.5,0.4,2.3,0.6c0.3,0.1,0.5-0.3,0.2-0.4c-1.2-0.6-2.7-0.7-4.1-0.4
		c-3-2.2-6.5-3.6-10.4-3.9C70.7,5.2,70.7,6.2,71.3,6.3z"/>
				<path fill={props.fill} d="M40.4,16.4c1.2-0.6,2.3-0.6,3.4,0.1c0.2,0.1,0.4-0.2,0.2-0.3c-1.2-0.8-2.5-0.9-3.8-0.3
		C39.9,16.1,40.1,16.6,40.4,16.4z"/>
				<path fill={props.fill} d="M38.6,20.1c0.9,0,1.7,0.1,2.6,0.1c0.2,0,0.2-0.3,0-0.3c-0.9-0.1-1.7-0.1-2.6-0.2
		C38.4,19.7,38.4,20.1,38.6,20.1z"/>
				<path fill={props.fill} d="M38.3,24.3c0.5-0.4,1.2-0.4,1.8-0.3c0.2,0,0.2-0.3,0-0.3c-0.7,0-1.5-0.1-2,0.4C37.9,24.2,38.2,24.5,38.3,24.3z
		"/>
				<path fill={props.fill} d="M37.4,28.3c0.3-0.4,0.6-0.6,0.9-0.9c0.3-0.2,1-0.4,1.3-0.3c0.1,0,0.1-0.1,0-0.1c-0.2,0.1-0.5,0-0.7,0
		c-0.2,0-0.5,0.1-0.7,0.2c-0.4,0.2-0.8,0.5-1.1,0.8C37,28.3,37.3,28.5,37.4,28.3z"/>
				<path fill={props.fill} d="M96,31.2c0.1,0.2,0.3,0,0.3-0.2c-0.8-2-2-4-3.3-5.7c-0.2-0.2-0.5,0.1-0.3,0.3C93.9,27.3,94.7,29.5,96,31.2z" />
				<path fill={props.fill} d="M93.5,33.7c-0.1-0.2-0.5-0.1-0.4,0.1c0.4,1.3,0.7,2.6,0.9,4c0.2,1,0.4,2.4,1.1,3.2c0.1,0.1,0.4,0.1,0.4-0.2
		c-0.1-1.2-0.7-2.4-1.1-3.6C94.3,36,94,34.9,93.5,33.7z"/>
				<path fill={props.fill} d="M91.9,43.5c0.2,2.1,0.1,4.2,0.5,6.3c0,0.2,0.4,0.2,0.4,0C93.3,47.7,92.1,45.6,91.9,43.5
		C92,43.5,91.9,43.5,91.9,43.5z"/>
				<path fill={props.fill} d="M73.3,58.8c-0.2,0.5-0.4,0.9-0.6,1.3c-0.3,0.5-0.7,0.9-1,1.4c-0.1,0.2,0.2,0.5,0.4,0.3
		c0.8-0.7,1.3-1.9,1.6-2.9C73.8,58.7,73.4,58.5,73.3,58.8z"/>
				<path fill={props.fill} d="M65.7,58.8c-1.1,1-2.3,1.9-3.2,3c-0.2,0.2,0.1,0.5,0.3,0.3c1.2-0.8,2.2-1.9,3.3-2.9
		C66.3,58.9,65.9,58.6,65.7,58.8z"/>
				<path fill={props.fill} d="M53.1,37.5c0.2,0,0.4-0.1,0.5-0.4c0.8-3.8,0.8-7.6,0.8-11.4c0.1,0,0.2,0,0.2-0.1c0.2-0.1,0.2-0.4-0.1-0.4
		c-0.1,0-0.1,0-0.2,0c-0.2-0.4-0.8-0.4-0.9,0c-1.5,0.1-3.1,0-4.6,0c-0.2,0-0.4,0.1-0.4,0.3c-0.5,3.6,0.5,7.5-0.8,11
		c-0.1,0.2,0.1,0.6,0.3,0.6C49.6,37.6,51.3,37.5,53.1,37.5z M53.4,29.9c0,1-0.1,1.9-0.1,2.9c0,0.6,0,3.8-0.9,3.8
		c-1.3,0-2.5,0.1-3.7-0.1c1.1-3.2,0.3-6.8,0.6-10.2c1.4,0,2.8-0.1,4.2-0.3c0,0.8,0,1.6,0,2.5 M53.4,28.5c0,0.4,0,0.9,0,1.3"/>
				<path fill={props.fill} d="M55.1,25.5c-0.2,3.8-0.2,7.7,0,11.5c0,0.3,0.3,0.5,0.6,0.5c1.9-0.5,3.8,0.1,5.8-0.3c0.1,0,0.3-0.2,0.3-0.3
		c0.5-3.5-0.9-7.1,0.2-10.5c0.1-0.3-0.1-0.4-0.2-0.5c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0,0,0,0c-2.2-0.1-4.4-0.3-6.6-0.5
		C55.3,25,55.1,25.3,55.1,25.5z M61,26C61,26,61,26,61,26c-0.7,2.5-0.3,4.9-0.1,7.4c0.2,1.8-0.1,3.2-2.2,3c-1-0.1-1.8-0.1-2.7,0
		c0-0.2,0-0.5,0-0.7c0,0,0,0,0.1,0c1.1,0.1,2.3,0,3.5-0.1c0,0,0,0,0,0c-1.1,0-2.3-0.1-3.5-0.1c0,0,0,0-0.1,0c-0.1-3.2-0.1-6.4,0-9.6
		C57.6,26.1,59.5,26.4,61,26z"/>
				<path fill={props.fill} d="M62.8,25.6c1.2,3.4,0.3,7.4,0.3,10.9c0,0.2,0.1,0.3,0.3,0.4c1.9,0.3,3.8,0.2,5.7,0.2c0.2,0,0.4-0.2,0.4-0.4
		c0-2,0-3.9,0-5.9c0-1.7,0.3-3.4-0.3-5c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.1-0.2-0.1c-1.7,0.6-3.8-0.1-5.5-0.2
		C62.9,25.2,62.7,25.4,62.8,25.6z M68.3,26c0.8,2.2,0.3,5,0.3,7.3c0,0.4,0.3,3-0.3,3c-0.4,0-4.5,0.2-4.5-0.2c0-3.3,0.7-7-0.2-10.2
		c1.5,0.1,3.2,0.4,4.7,0C68.3,25.9,68.3,25.9,68.3,26z"/>
				<path fill={props.fill} d="M70.4,25.8c-0.5,0.9-0.2,2.7-0.2,3.6c0,2.4,0.2,4.8,0,7.2c0,0.3,0.3,0.5,0.6,0.4c1.8-0.3,3.5-0.3,5.3-0.3
		c0.2,0,0.4-0.1,0.5-0.4c0.9-3.4-0.3-6.8,0.5-10.2c0.1-0.4-0.3-0.7-0.7-0.6C74.9,25.3,71.2,24.3,70.4,25.8z M76.1,25.9
		c0,0,0,0.1,0,0.1c-0.5,2.2-0.3,4.4-0.2,6.6c0,0.6,0,1.3,0,1.9c-0.1,1.3-0.2,1.3-1.4,1.4c-1.1,0-2.3,0.1-3.4,0.3
		c0.2-2.9,0-5.8-0.1-8.6c0-1.1,0.1-1.5,1.3-1.6C73.7,25.8,74.9,25.9,76.1,25.9z"/>
				<path fill={props.fill} d="M78.4,25c-0.2,0-0.5,0.2-0.5,0.4c-0.2,1.6-0.8,3.2-0.9,4.9c-0.2,2,0.6,4,0.7,5.9c0,0.2,0.2,0.5,0.4,0.5
		c2.1,0.4,4.1,0.5,6.2,0.2c0.3,0,0.4-0.3,0.4-0.5c0.1-3.6-0.1-7.1-0.3-10.7c0-0.3-0.2-0.5-0.4-0.6c-0.8-0.2-1.3-0.2-1.8,0.1
		C80.9,25.4,79.6,25,78.4,25z M81.8,26.1c0.5,0,0.9,0.1,1.4,0.1c0,0.1,0,0.2,0,0.3c-1.2,0.4-2.4,0.1-3.6,0.2c-0.1,0-0.1,0.2,0,0.2
		c1.1,0.1,2.5,0.1,3.6-0.3c0.2,3.1,0.4,6.2,0.3,9.3c-1.6,0.2-3.2,0.2-4.8-0.1c-0.1-1.7-0.6-3.3-0.6-5c-0.1-1.6,0.4-3.2,0.7-4.7
		c0.9,0,1.9,0.1,2.8-0.1C81.7,26,81.7,26.1,81.8,26.1z"/>
				<path fill={props.fill} d="M79,27.9c1.3,0,2.5,0,3.8-0.1c0,0,0,0,0,0c-1.3,0-2.5,0-3.8-0.1C78.9,27.7,78.9,27.9,79,27.9z" />
				<path fill={props.fill} d="M83.2,29.2C83.2,29.2,83.2,29.1,83.2,29.2c-0.3,0-0.7,0-1,0C82.5,29.1,82.8,29.1,83.2,29.2z" />
				<path fill={props.fill} d="M79,29.2c1,0,2.1-0.1,3.1-0.1c-1,0-2.1-0.1-3.1-0.1C78.9,29,78.9,29.2,79,29.2z" />
				<path fill={props.fill} d="M79.5,30c-0.1,0-0.1,0.2,0,0.2c1.2,0.1,2.4-0.1,3.6-0.1c0,0,0,0,0,0C82,30.2,80.7,30,79.5,30z" />
				<path fill={props.fill} d="M79.4,31.2l3.5-0.1c0,0,0-0.1,0-0.1L79.4,31C79.3,31,79.3,31.2,79.4,31.2z" />
				<path fill={props.fill} d="M78.9,32.5l3.3,0c0.1,0,0.1-0.1,0-0.1l-3.3,0C78.8,32.4,78.8,32.5,78.9,32.5z" />
				<path fill={props.fill} d="M79.9,33.6c-0.1,0-0.1,0.2,0,0.2c1.1,0,2.3,0.1,3.3-0.4c0.1,0,0-0.1-0.1-0.1C82.1,33.8,81,33.7,79.9,33.6z" />
				<path fill={props.fill} d="M79.5,34.8c1.1,0,2.2,0,3.3-0.1c0,0,0,0,0,0c-1.1,0-2.2,0-3.3-0.1C79.4,34.6,79.4,34.8,79.5,34.8z" />
				<path fill={props.fill} d="M72.3,27.1c-0.1,0-0.1,0.1,0,0.1c1.1,0.1,2.3,0.1,3.3-0.3c0.1,0,0-0.2-0.1-0.1C74.5,27.2,73.4,27,72.3,27.1z"
				/>
				<path fill={props.fill} d="M71.8,28.2c1.2-0.4,2.4-0.3,3.6-0.3c0.1,0,0.1-0.2,0-0.2c-1.2,0-2.5-0.2-3.7,0.4
		C71.7,28.1,71.7,28.3,71.8,28.2z"/>
				<path fill={props.fill} d="M72,29.2c1,0,2-0.1,3,0.3c0,0,0,0,0,0c-0.9-0.4-2-0.4-3-0.4C71.9,29,71.9,29.2,72,29.2z" />
				<path fill={props.fill} d="M72,30.7c1.1-0.3,2.3-0.2,3.4-0.2c0.1,0,0.1-0.1,0-0.1c-1.2,0-2.3-0.2-3.5,0.1C71.8,30.6,71.9,30.7,72,30.7z"
				/>
				<path fill={props.fill} d="M72.1,31.9c1.2,0,2.4,0,3.6,0c0.1,0,0.1-0.1,0-0.1c-1.2,0-2.4,0-3.6,0C72,31.7,72,31.9,72.1,31.9z" />
				<path fill={props.fill} d="M72.4,33.2c1,0.2,2.1,0.3,3.1,0.3c0,0,0-0.1,0-0.1c-1,0-2.1-0.2-3.1-0.4C72.4,33,72.3,33.2,72.4,33.2z" />
				<path fill={props.fill} d="M72.1,34.5c1.1,0.2,2.1,0.4,3.1,0.3c0,0,0-0.1,0-0.1c-1.1,0.1-2.1-0.2-3.1-0.4C72.1,34.3,72,34.5,72.1,34.5z"
				/>
				<path fill={props.fill} d="M71.5,35.1c1,0.2,2,0.3,3,0.4c0.1,0,0.1-0.2,0-0.2c-1,0-2-0.2-2.9-0.3C71.4,35,71.4,35.1,71.5,35.1z" />
				<path fill={props.fill} d="M64.1,35.1c-0.1,0-0.1,0.2,0,0.2c0.8-0.1,1.6,0,2.4,0.1c0.5,0.1,1.1,0.1,1.6,0c0,0,0-0.1,0-0.1
		C66.7,35.7,65.3,34.6,64.1,35.1z"/>
				<path fill={props.fill} d="M52.4,35.6c-0.9,0-1.9,0-2.8,0c-0.1,0-0.1,0.2,0,0.2c0.9,0,1.9,0,2.8,0C52.5,35.8,52.5,35.6,52.4,35.6z" />
				<path fill={props.fill} d="M104.7,41.8c8.3-2.5,17.2-0.3,25.7-0.1c2.5,0.1,5.7,0.1,7.8-1.6c2.3-1.9,1.3-5.1,0.3-7.4
		c-0.3-0.7-1.4-0.3-1.3,0.4c-3.2-2.1-7-3.1-10.8-3.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.4-0.1-0.5,0.1c-1.1-0.1-2.2-0.2-3.3-0.3
		c-3-0.2-6.1-0.3-9.2-0.1c-1.5,0.1-3,0.1-4.4,0.4c-1.1,0.2-2.2,0.4-3.4,0.6c-0.7,0.1-1.4,0.5-1.3,1.3c0.1,3.2-0.6,6.3-0.6,9.4
		C103.3,41.5,104,42.1,104.7,41.8z M128.4,40.1c-7.6-0.4-15.3-2.2-22.9-0.7c0.2-1.7,0.5-3.3,0.7-5c0.4,0.8,0.9,1.5,1.5,2.2
		c0.1,0.1,0.2,0,0.2-0.1c-0.4-0.9-0.7-1.8-1.1-2.7c-0.1-0.2-0.2-0.5-0.3-0.8c0-0.1,0-0.2,0-0.3c0.7,0,1.4,0,2.1,0c0.1,0,0.3,0,0.4,0
		c0.7,0.8,1.4,1.5,2.2,2.2c0.1,0.1,0.2,0,0.1-0.1c-0.5-0.6-1-1.4-1.4-2.1c0.9,0,1.8-0.1,2.7-0.1c0.1,0,0.2,0,0.2,0
		c0.7,1,1.3,2.1,1.8,3.2c0,0.1,0.2,0,0.2-0.1c-0.4-1.1-0.8-2.2-1.4-3.2c0.9,0,1.8-0.1,2.7-0.1c0.1,0.2,0.3,0.4,0.4,0.6
		c0.6,1.1,1.1,2.3,1.5,3.6c0,0.1,0.2,0.1,0.2,0c-0.1-1.4-0.6-2.9-1.2-4.2c0.7,0,1.5,0,2.2-0.1c0.1,0.1,0.3,0.3,0.4,0.4
		c0.7,0.9,1.4,1.9,1.8,3c0.1,0.2,0.4,0.2,0.4,0c-0.1-1.2-0.4-2.4-1.1-3.4c0,0,0,0,0,0c0.3,0,0.6,0,0.9,0c0.9,0,1.8,0,2.7,0.1
		c1.3,1.3,2.5,2.7,3.3,4.2c0,0,0.1,0,0.1,0c-0.5-1.5-1.3-2.9-2.4-4.1c1,0,1.9,0.1,2.9,0.1c1.2,1.4,2.3,2.9,3.6,4.1
		c0.1,0.1,0.2,0,0.1-0.1c-1-1.2-2-2.6-3-3.9c0.4,0,0.9,0.1,1.3,0.1c0.7,0,1.4,0.1,2.1,0.2c1.4,1.5,2.9,2.9,4.1,4.6
		c0,0.1,0.2,0,0.1-0.1c-0.9-1.5-2-3-3.2-4.4c1.4,0.2,2.8,0.6,4.1,1.1c0.8,2,1.3,4.4-1.1,5.4C133.9,40.5,130.9,40.2,128.4,40.1z"/>
				<path fill={props.fill} d="M36.2,40.8c0.1-3.2-0.7-6.3-0.6-9.4c0-0.8-0.6-1.2-1.3-1.3c-1.1-0.1-2.2-0.4-3.4-0.6c-1.5-0.2-3-0.3-4.4-0.4
		c-3-0.2-6.1-0.1-9.2,0.1c-1.1,0.1-2.2,0.2-3.3,0.3c-0.1-0.2-0.3-0.2-0.5-0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-3.8,0.5-7.7,1.5-10.8,3.6
		c0-0.7-1-1.2-1.3-0.4c-1.1,2.3-2.1,5.5,0.3,7.4c2.1,1.7,5.3,1.6,7.8,1.6c8.5-0.2,17.4-2.4,25.7,0.1C35.5,42.1,36.2,41.5,36.2,40.8z
		 M3.2,39.5c-2.4-1-1.9-3.5-1.1-5.4c1.3-0.5,2.7-0.9,4.1-1.1C5,34.3,3.9,35.8,3,37.4c0,0.1,0.1,0.1,0.1,0.1c1.2-1.7,2.7-3.1,4.1-4.6
		c0.7-0.1,1.4-0.2,2.1-0.2c0.4,0,0.9-0.1,1.3-0.1c-1,1.3-1.9,2.7-3,3.9c-0.1,0.1,0,0.2,0.1,0.1c1.4-1.2,2.5-2.7,3.6-4.1
		c1-0.1,1.9-0.1,2.9-0.1c-1,1.2-1.9,2.6-2.4,4.1c0,0,0,0.1,0.1,0c0.8-1.5,2-3,3.3-4.2c0.9,0,1.8-0.1,2.7-0.1c0.3,0,0.6,0,0.9,0
		c0,0,0,0,0,0c-0.7,1-1,2.2-1.1,3.4c0,0.2,0.3,0.2,0.4,0c0.4-1.1,1-2.1,1.8-3c0.1-0.1,0.3-0.3,0.4-0.4c0.7,0,1.5,0,2.2,0.1
		c-0.7,1.3-1.1,2.8-1.2,4.2c0,0.1,0.2,0.1,0.2,0c0.3-1.2,0.8-2.5,1.5-3.6c0.1-0.2,0.3-0.4,0.4-0.6c0.9,0,1.8,0.1,2.7,0.1
		c-0.6,1-1,2.1-1.4,3.2c0,0.1,0.1,0.2,0.2,0.1c0.5-1.1,1.1-2.2,1.8-3.2c0.1,0,0.2,0,0.2,0c0.9,0,1.8,0.1,2.7,0.1
		c-0.4,0.7-0.9,1.5-1.4,2.1c-0.1,0.1,0,0.2,0.1,0.1c0.8-0.7,1.5-1.4,2.2-2.2c0.1,0,0.3,0,0.4,0c0.7,0,1.4,0,2.1,0c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.2,0.6-0.3,0.8c-0.4,0.9-0.7,1.8-1.1,2.7c-0.1,0.1,0.1,0.2,0.2,0.1c0.5-0.7,1-1.4,1.5-2.2c0.2,1.7,0.6,3.3,0.7,5
		c-7.5-1.6-15.3,0.3-22.9,0.7C8.6,40.2,5.6,40.5,3.2,39.5z"/>
			</g>
		</svg>
	)
}

export default ConsoMeter;