import React, { Component } from "react";
import { useMediaQuery } from "react-responsive";

const Dimensions = (props: any) => {
        const { device } = props
        const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
        const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });
        return(
            <div className="dimensions-container" style={{height: "fit-content"}}>
                <img src={require(`../../../_resources/image/fiche/${device.code}.png`)} style={{width: isMobile ? "100%" : "50%", height:"auto"}} alt="telephone"/>
            </div>
        )
    }
export default Dimensions;