import React from "react";

const MultiUtilisateur = (props) => {
    return (
        <svg id="Calque_1" viewBox="0 0 59.23 51.59" {...props}>
            <path fill={props.fill} d="M0,30.89c.09-.35,.17-.7,.27-1.05,.91-3.35,3.98-5.74,7.46-5.76,1.42,0,2.87-.06,4.27,.15,2.35,.37,4.13,1.71,5.35,3.76,.11,.18,.21,.36,.3,.53,.85-.69,1.65-1.41,2.52-2.02,2.27-1.58,4.82-2.4,7.59-2.41,1.96,0,3.94-.02,5.88,.23,2.91,.38,5.38,1.79,7.47,3.84,.13,.13,.27,.26,.45,.42,.65-1.28,1.5-2.33,2.65-3.12,1.31-.9,2.75-1.41,4.33-1.39,1.56,.02,3.15-.06,4.66,.24,3.5,.7,6,3.88,6.02,7.45,.02,4.18,.01,8.37,0,12.55,0,2.06-1.53,3.56-3.6,3.57-3.24,.01-6.48,0-9.72,0-.21,0-.42,0-.66,0-.06,.27-.1,.51-.17,.75-.48,1.7-2.02,2.94-3.79,2.94-7.77,.02-15.54,.02-23.31,0-1.9,0-3.46-1.34-3.88-3.2-.03-.15-.07-.3-.12-.49H4.21c-2.48,0-3.29-.53-4.21-2.78C0,40.37,0,35.63,0,30.89Zm29.58,17.24c3.78,0,7.55,0,11.33,0,.66,0,.85-.18,.85-.8,0-3.22,.04-6.44-.01-9.65-.08-4.74-3.68-9.06-8.36-9.88-1.73-.3-3.53-.25-5.29-.25-5.97,0-10.61,4.66-10.62,10.64,0,2.99,0,5.97,0,8.96,0,.87,.12,.98,1.01,.98,3.7,0,7.4,0,11.1,0Zm-15.58-3.71c0-.26,0-.47,0-.68,0-1.98-.07-3.97,.04-5.95,.07-1.3,.39-2.58,.59-3.87,.11-.69,.29-1.39,.29-2.09,0-2.08-1.47-3.86-3.53-4.18-1.09-.17-2.23-.11-3.34-.11-2.71,0-4.56,1.87-4.56,4.59,0,3.89,0,7.78,0,11.68,0,.2,0,.4,0,.61H14Zm41.72,0c.02-.12,.04-.2,.04-.27,0-4.14,.02-8.28-.01-12.43-.02-1.96-1.48-3.78-3.41-4.03-1.51-.19-3.07-.04-4.6-.03-.13,0-.26,.06-.39,.1-2.27,.75-3.56,3.03-2.87,5.78,.24,.97,.57,1.94,.62,2.92,.12,2.44,.1,4.89,.14,7.33,0,.2,0,.4,0,.62h10.49Z" />
            <path fill={props.fill} d="M39.68,10.05c0,5.56-4.53,10.08-10.09,10.07-5.53,0-10.04-4.53-10.04-10.06C19.55,4.51,24.07,0,29.63,0c5.53,0,10.04,4.52,10.04,10.05Zm-3.47,.02c0-3.62-2.95-6.59-6.57-6.6-3.62-.01-6.6,2.93-6.62,6.55-.02,3.64,2.96,6.63,6.6,6.63,3.62,0,6.58-2.97,6.59-6.58Z" />
            <path fill={props.fill} d="M15.61,13.78c0,3.5-2.88,6.35-6.38,6.34-3.49,0-6.35-2.88-6.34-6.38,0-3.5,2.88-6.35,6.38-6.34,3.5,0,6.35,2.88,6.34,6.38Zm-3.47-.01c0-1.6-1.3-2.9-2.9-2.9-1.57,0-2.87,1.28-2.88,2.85-.02,1.6,1.28,2.92,2.87,2.93,1.6,0,2.91-1.29,2.91-2.88Z" />
            <path fill={props.fill} d="M56.33,13.76c0,3.5-2.86,6.36-6.36,6.36-3.5,0-6.36-2.86-6.36-6.36,0-3.5,2.86-6.36,6.36-6.36,3.5,0,6.36,2.86,6.36,6.36Zm-3.47,.01c0-1.6-1.3-2.9-2.89-2.9-1.57,0-2.87,1.28-2.89,2.85-.02,1.6,1.27,2.92,2.87,2.93,1.6,.01,2.91-1.29,2.91-2.88Z" />
        </svg>
    )
}

export default MultiUtilisateur;