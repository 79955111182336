import React from 'react';
import './responsiv.scss';
import MultiUtilisateur from '../../_svg/MultiUtilisateur';
import ContratAbonnement from '../../_svg/ContratAbonnement';
import RemplacementCompteur from '../../_svg/RemplacementCompteur'
import SignatureElectronique from '../../_svg/SignatureElectronique';
import ResiliationContrat from '../../_svg/ResiliationContrat';
import Email from '../../_svg/Email';
import imgAccueilIntervention from '../../_resources/image/intervention/accueil_intervention.png'
import imgContratAbonnement from '../../_resources/image/intervention/contrat_abonnement_intervention.png'
import imgRemplacementCompteur from '../../_resources/image/intervention/remplacement_compteur_intervention.png'
import imgSignatureElectronique from '../../_resources/image/intervention/signature_intervention.png'
import imgResiliationContrat from '../../_resources/image/intervention/resiliation_contrat_intervention.png'

import { useMatomo } from '@datapunt/matomo-tracker-react';
import { connect } from "react-redux";
import docActions from "../../redux/doc/doc.action";
import { withTranslation } from 'react-i18next';

const ResponsivIntervention = props => {
    const { dispatch, t } = props
    const { trackLink } = useMatomo()

    function downloadFile() {
        dispatch(docActions.getDoc("intervention", "fr"));
        trackLink({ href: 'intervention', linkType: 'download' })
    }

    return (
        <div className='intervention-responsiv' style={{ marginTop: "52px" }}>
            <div className='box100'>
                <MultiUtilisateur fill={"#e86b8d"} />
                <h5>{t('intervention.multi.users')}</h5>
                <div className='span-container'>
                    <span>{t('intervention.multi.users.description')}</span>
                </div>
                <div className={"download-documentation"}>
                    <div className={"button-download"} itemProp="subjectOf">
                        <button style={{ borderColor: "#e86b8d" }} onClick={downloadFile}>
                            {t("all.button.download")}
                        </button>
                    </div>
                </div>
                <div className="image-container">
                    <img src={imgAccueilIntervention} />
                </div>
            </div>
            <div className='box100'>
                <ContratAbonnement fill={"#e86b8d"} />
                <h5>{t('intervention.contract.abo')}</h5>
                <div className='span-container'>
                    <span>{t('intervention.contract.abo.description')}</span>
                </div>
                <div className='image-container'>
                    <img src={imgContratAbonnement}></img>
                </div>
            </div>
            <div className='box100'>
                <RemplacementCompteur fill={"#e86b8d"} />
                <h5>{t('intervention.switch.meter')}</h5>
                <div className='span-container'>
                    <span>{t('intervention.switch.meter.description')}</span>
                </div>
                <div className='image-container'>
                    <img src={imgRemplacementCompteur}></img>
                </div>
            </div>
            <div className='box100'>
                <SignatureElectronique fill={"#e86b8d"} />
                <h5>{t('intervention.electronic.signature')}</h5>
                <div className='span-container'>
                    <span>{t('intervention.electronic.signature.description')}</span>
                </div>
                <div className='image-container'>
                    <img src={imgSignatureElectronique}></img>
                </div>
            </div>
            <div className='box100'>
                <ResiliationContrat fill={"#e86b8d"} />
                <h5>{t('intervention.termination.contract')}</h5>
                <div className='span-container'>
                    <span>{t('intervention.termination.contract.description')}</span>
                </div>
                <div className='image-container'>
                    <img src={imgResiliationContrat}></img>
                </div>
            </div>
            <div className='box100'>
                <Email fill={"#e86b8d"} />
                <h5>{t('intervention.email')}</h5>
                <div className='span-container'>
                    <span>{t('intervention.email.description')}</span>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { indicator } = state;

    return {
        indicator,
    };
}

const connectedDoc = connect(mapStateToProps)(ResponsivIntervention);
const tr = withTranslation()(connectedDoc);
export default tr;