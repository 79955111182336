import React from 'react';

const SvgEngelmann = (props: any) => (
<svg viewBox="0 0 161.13 31.48" {...props}>
	<path d="M24.11,17.47c-.16-2.54-2.26-4.42-5.32-4.42-3.24,0-5.25,2.06-5.47,4.42h10.79Zm-13.54,1.13c0-4.15,3.13-7.37,8.24-7.37,4.76,0,8.02,2.87,8.02,7.02v.88H13.28c.07,2.66,2.03,5.18,5.72,5.18,2.32,0,4.29-.95,4.91-2.58h2.57c-.67,2.6-3.69,4.4-7.57,4.4-5.38,0-8.35-3.47-8.35-7.53"/>
	<path d="M29.46,11.6h2.59v2.83l-.18-.21c1.03-1.98,3.44-2.98,5.74-2.98,3.78,0,6.32,1.92,6.32,5.49v9.05h-2.66v-8.77c0-2.73-1.72-3.93-4.24-3.93-2.7,0-4.92,1.82-4.92,4.43v8.27h-2.64V11.6Z"/>
	<path d="M58.62,18.67v-.36c0-2.84-1.95-5.25-5.23-5.25-3.44,0-5.36,2.56-5.36,5.49s1.95,5.42,5.29,5.42,5.3-2.63,5.3-5.29m-12.55,8.62h2.7c.34,1.5,2.35,2.37,4.76,2.37,3.11,0,5.09-1.53,5.09-3.98v-3.08l.24,.11c-1.09,2.06-3.33,3.05-5.88,3.05-4.58,0-7.64-3.06-7.64-7.16,0-4.3,3.28-7.37,7.8-7.37,2.79,0,5,1.2,5.85,3.26h-.29v-2.9h2.59v14.07c0,3.55-3.17,5.82-7.75,5.82-4.02,0-7.06-1.64-7.46-4.19"/>
	<path d="M76.08,17.47c-.16-2.54-2.26-4.42-5.32-4.42-3.24,0-5.25,2.06-5.47,4.42h10.79Zm-13.54,1.13c0-4.15,3.13-7.37,8.24-7.37,4.76,0,8.02,2.87,8.02,7.02v.88h-13.54c.07,2.66,2.03,5.18,5.72,5.18,2.32,0,4.29-.95,4.91-2.58h2.57c-.67,2.6-3.69,4.4-7.57,4.4-5.38,0-8.35-3.47-8.35-7.53"/>
	<rect x="81.11" y="7.09" width="2.64" height="18.68"/>
	<path d="M104.18,11.23c3.58,0,5.8,1.81,5.8,4.94v9.6h-2.47v-9.19c0-2.44-1.35-3.51-3.73-3.51-1.78,0-3.67,1.03-4.15,3.41l-.66-2.26c.94-1.96,2.94-2.99,5.21-2.99m-4.56,14.54h-2.47v-8.93c0-2.62-1.28-3.77-3.6-3.77-2.49,0-4.29,2.01-4.29,4.37v8.33h-2.46V11.6h2.4v2.85l-.22-.19c1.01-1.98,2.93-3.02,5.06-3.02,3.26,0,5.57,1.53,5.57,4.94v9.6Z"/>
	<path d="M123.82,21.29v-3.54c-1.18,1.07-2.73,1.38-5.34,1.87-2.63,.49-3.84,1.13-3.84,2.52,0,1.64,1.54,2.19,3.13,2.19,2.32,0,4.71-1.03,6.05-3.04m-11.88,1.03c0-2.84,3.02-3.77,6.21-4.28,3.24-.52,5.67-.88,5.67-2.56,0-1.39-1.77-2.42-4.24-2.42-2.34,0-4.25,1-4.4,2.49h-2.68c.11-2.51,3.15-4.32,7.19-4.32s6.77,1.62,6.77,4.47v7.48c0,.63,.31,.95,1.01,.95,.25,0,.45-.03,.65-.07v1.7c-.25,.08-.74,.19-1.32,.19-1.72,0-2.86-.96-2.73-3.05l.22,.11c-1.01,1.5-3.33,3.12-6.86,3.12-3.1,0-5.49-1.34-5.49-3.82"/>
	<path d="M129.57,11.6h2.59v2.83l-.18-.21c1.03-1.98,3.44-2.98,5.74-2.98,3.78,0,6.32,1.92,6.32,5.49v9.05h-2.66v-8.77c0-2.73-1.72-3.93-4.24-3.93-2.7,0-4.92,1.82-4.92,4.43v8.27h-2.64V11.6Z"/>
	<path d="M146.67,11.6h2.59v2.83l-.18-.21c1.03-1.98,3.44-2.98,5.74-2.98,3.78,0,6.32,1.92,6.32,5.49v9.05h-2.66v-8.77c0-2.73-1.72-3.93-4.24-3.93-2.7,0-4.92,1.82-4.92,4.43v8.27h-2.64V11.6Z"/>
	<path d="M15.97,29.24c-7.8,0-14.11-6.38-14-14.2,.11-7.64,6.42-13.83,14.06-13.8,4.54,.02,8.58,2.2,11.12,5.57,.06,.08,.18,.09,.25,.02,.06-.06,.06-.14,.02-.21C24.77,2.69,20.32,.09,15.26,0,6.95-.14,.06,6.57,0,14.88c-.06,8.33,6.67,15.09,14.99,15.09,2.23,0,4.35-.49,6.25-1.36,.09-.04,.13-.15,.09-.24s-.14-.13-.24-.1c-1.59,.62-3.31,.97-5.12,.97"/>
</svg>
);

export default SvgEngelmann;
