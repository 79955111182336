import React from 'react';

const SvgTournee = (props: any) => (
    <svg viewBox="0 0 76.18 101.55" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M73.27,97.32c-4.16.23-8.23.75-12.44.67-5.47-.1-10.94-.54-16.41-.84-5.72-.3-11.39,0-17.1.13-7.16.2-14.43-.27-21.56.32-1.69-6.42-1.95-13.11-2-19.68-.06-7.59.19-15.15-.19-22.75Q2.89,41.82,3,28.44c0-4.44.14-8.88.32-13.31A109.44,109.44,0,0,0,3.4,1.7c0-.34-.63-.4-.72-.06A68.22,68.22,0,0,0,.91,14.13q-.48,7-.72,14.06C-.13,37.36,0,46.51.4,55.68c.68,14.57-1.83,29.94,2.73,44.15.58,1.8,3.6,1.36,3.13-.44,7,1.5,14.44,1.21,21.53,1.19,5.79,0,11.52-.22,17.3.1s11.31.75,17,.8c3.83,0,9.67.62,12.33-2.48C75.09,98.19,74.16,97.27,73.27,97.32Z"/>
			<path fill={props.fill} d="M4.21,3.16h0a.07.07,0,0,0,0-.13c1.63,1.13,5.18.63,7.29.61,4.56,0,9.1-.14,13.65-.34a337.7,337.7,0,0,1,42.42.79,1.46,1.46,0,1,0,.25-2.9A281.1,281.1,0,0,0,25,.51c-4.68.28-9.33.67-14,1.15-1.79.18-3.56.39-5.33.7A3.18,3.18,0,0,0,4.13,3,.08.08,0,0,0,4.21,3.16Z"/>
			<path fill={props.fill} d="M75.19,43.34c.31-7.23.62-14.51.21-21.74A61.61,61.61,0,0,0,74,11.13a25.56,25.56,0,0,0-3.52-8.32c-.09-.15-.34-.05-.27.12,2.63,6,3.11,12.93,3.3,19.32.2,7.15-.41,14.28-.72,21.42-.75,17.42,1,34.86,1,52.29,0,1.46,2.41,1.68,2.41.21C76.17,78.56,74.43,60.94,75.19,43.34Z"/>
			<path fill={props.fill} d="M16.66,36.27a1.14,1.14,0,0,1-.09.26,3.3,3.3,0,0,1-.19.43l-.08.14-.17.24a4.87,4.87,0,0,1-.4.5l-.07.07-.15.15a4.54,4.54,0,0,1-.48.39,4.67,4.67,0,0,1-.43.3l-.13.07L14.2,39a4.87,4.87,0,0,1-.53.21l-.07,0-.28.06-.29,0H13a3.15,3.15,0,0,1-.53,0h0l-.25-.07L12,39.08,11.89,39a2.2,2.2,0,0,1-.25-.17l-.05,0,0,0a3.1,3.1,0,0,1-.45-.51L10.9,38h0l-.1-.15a6.18,6.18,0,0,1-.39-.77,9.3,9.3,0,0,1-.57-1.91c-.15-.73-1.43-.73-1.42.05,0,2.33.58,5.28,3.31,6.07a6.06,6.06,0,0,0,5.53-1.56c1.51-1.35,2.53-3.3,1.82-5.27-.89-2.5-4.36-3-6.73-3.11-.6,0-1.21,0-1.81,0-.3,0-.6.09-.9.14a1.6,1.6,0,0,0-1.32.91,1.13,1.13,0,0,0,.22,1.17c.51.62,1.29.5,2,.52.34,0,.68,0,1,0h.53a13.77,13.77,0,0,1,2.4.16l.3.06.31.08a5.34,5.34,0,0,1,.76.28l.08,0,.27.17.09.07.16.17.06.07.07.13,0,.11c0,.05,0,.1,0,.13a.78.78,0,0,1,0,.22,2.72,2.72,0,0,1,0,.49A0,0,0,0,1,16.66,36.27Z"/>
			<path fill={props.fill} d="M12.73,63a1.21,1.21,0,0,0,1,.66c2,0,3.91-.7,5.88-.62A1.28,1.28,0,0,0,21,62c.28-3.28.2-7-1.53-10s-5.49-3.42-8.43-1.8c-.05-.13-.09-.27-.14-.41-.23-.6-1.14-.24-1.07.32C10.39,54.53,10.6,58.94,12.73,63Zm-.58-10.54c1.2-.37,2.31-.93,3.6-.51,1.06.36,1.59,1.58,2,2.48a14.83,14.83,0,0,1,.77,6.25c-1.33.16-2.62.54-4,.66-1.43-2.78-2-5.83-2.77-8.82A1.32,1.32,0,0,0,12.15,52.48Z"/>
			<path fill={props.fill} d="M12.26,84.14A23.1,23.1,0,0,0,23.88,84a1.07,1.07,0,0,0,.82-1.51,19.9,19.9,0,0,1-2.12-10.63,1.17,1.17,0,0,0-1.37-1.18c-2.3.24-7,.64-9.08,2.53-.16-.82-.32-1.64-.47-2.46-.11-.55-1-.53-1.05,0-.2,4.12.77,8.18,1,12.29A1.05,1.05,0,0,0,12.26,84.14Zm4.27-10.37A24.28,24.28,0,0,1,20.21,73a22.18,22.18,0,0,0,1.86,9.2,23.13,23.13,0,0,1-8.46.12c-.26-2.35-.65-4.7-1.08-7C13.9,75.06,15.2,74.16,16.53,73.77Z"/>
			<path fill={props.fill} d="M27.43,77.21c2.68-1.4,5.89-1.14,8.83-.78,3.3.4,6.6.89,9.92,1.06s6.62-.74,9.9-1.16c4.46-.58,8.94-.77,13.4-1.39,1.87-.25,1.32-3.07-.55-2.73-4.41.82-8.76,1.64-13.21,2.33-3.74.57-7.26,1.54-11.08,1.36-5.38-.27-12.58-2.14-17.25,1.25C27.36,77.18,27.4,77.23,27.43,77.21Z"/>
			<path fill={props.fill} d="M34.83,55.69c3.71.42,7.36,1,11.05,1.58a61.52,61.52,0,0,0,10.75.92,7.75,7.75,0,0,0,4.85-1.35,7,7,0,0,0,1.82-2.5c.29-.52-.36-1.19-.93-1.05-1.24.29-1.44-.13-3.1-.16s-3.1.22-4.67.21c-3.75,0-7.47-.64-11.21-1-5.24-.45-14.19-1.7-17.33,3.56-.38.64.46,1.58,1.11,1C29.1,55.07,32.31,55.4,34.83,55.69Z"/>
			<path fill={props.fill} d="M68.16,33.49c-.76-.44-1.14-1-2.07-1.18a28.59,28.59,0,0,0-3.07-.2c-1.92-.06-3.85,0-5.77,0-3.6.12-7.17.57-10.74,1-3.33.39-6.53.89-9.89,1.12a39.51,39.51,0,0,0-11,2c-.61.22-.27,1.12.35,1,6.75-1.18,13.7-.61,20.51-.93,3.48-.16,7-.27,10.46-.36,1.92-.05,3.84,0,5.76,0,1,0,2,.13,3.08.12s1.38-.5,2.25-.83A1,1,0,0,0,68.16,33.49Z"/>
			<path fill={props.fill} d="M25.31,17.5c6.3-4.94,15.22-2.66,22.47-1a91.49,91.49,0,0,0,11,2.08c2.27.22,8,.57,8.77-2.23a1,1,0,0,0-.38-1.16c-1.21-.62-2.8-.24-4.1-.07A24,24,0,0,1,57.65,15c-4.15-.4-8.23-1.4-12.33-2.14-7.27-1.31-15.43-1.63-20.73,4C24.21,17.28,24.89,17.83,25.31,17.5Z"/>
			<path fill={props.fill} d="M8.4,19.33c0-.55-.86-.63-.9-.08a21.71,21.71,0,0,0,.64,6.31A.6.6,0,0,0,8.8,26a39.64,39.64,0,0,1,11.51,0,.73.73,0,0,0,.76-1c-1.23-3.24-1.54-6.69-2.15-10.06.54-1.07,1.14-2.11,1.76-3.15a8.2,8.2,0,0,0,.82-3.48c.12-.8-.89-1.13-1.48-.74a8,8,0,0,0-3,2.65c-.86,1.41-1.64,2.86-2.38,4.33A22.1,22.1,0,0,0,13.26,18c-.91-1.66-1.86-3.31-2.87-4.93a.68.68,0,0,0-1.28.38c.32,3.76,2,7.24,3.33,10.75l.09.2a26.24,26.24,0,0,0-3.41.42A19.33,19.33,0,0,1,8.4,19.33Zm7.45,4.33a25,25,0,0,1,1.81-6,42.47,42.47,0,0,0,1.61,6.84c-1.24-.08-2.48-.14-3.71-.15A1.67,1.67,0,0,0,15.85,23.66Z"/>
		</g>
	</g>
</svg>
);

export default SvgTournee;
