import React from 'react';

const SvgIncom = (props: any) => (
	<svg viewBox="0 0 70.81 43.19" {...props}>
		<path fill="#e4e4e4" d="M2.22,16.14s-1.57,.46-.92,4.05c.64,3.59,.74,6.82-.37,10.5-1,3.34-1.1,7.51-.76,7.51,.03,0,.07-.05,.12-.14,.46-1.01,1.93-2.49,2.76-3.04,.83-.55,2.03-1.38,2.03-1.38,1.39-1.38,.91-14.18,.91-14.18-2.12-.28-3.78-3.32-3.78-3.32"/>
		<path fill="#e4e4e4" d="M9.9,14.83c-.09,0-.27,.09-.4,.75-.18,.92-.46,2.21-1.47,2.95,0,0,2.49,4.14-.55,13.91,0,0-.74,1.84-.74,2.21,0,0,1.93-2.67,4.33-3.32,0,0,1.29-4.05,1.2-4.97,0,0,1.38,2.39,2.76,3.59,0,0,.55,1.57-.28,2.67,0,0,.5-.38,1.35-.38,.47,0,1.04,.12,1.69,.48,0,0,2.49-5.99,2.58-15.38,0,0-1.66-.74-2.3-1.75-.21-.34-.36-.45-.45-.45-.19,0-.19,.45-.19,.45,0,0,.37,8.34-1.29,11.58,0,0-2.4-2.19-3.22-5.69-.83-3.5-2.95-6.63-2.95-6.63,0,0-.02-.02-.06-.02"/>
		<path fill="#e4e4e4" d="M28.45,14.89c-2.3,0-4.9,1.45-6.14,4.66-1.93,4.97,.28,10.04,4.33,10.41,.25,.02,.49,.03,.72,.03,3.65,0,6.03-2.52,6.03-2.52,.95-1.1,.34-2.12,.34-2.12-1.3,1.68-3.35,2.5-5.19,2.5-2.2,0-4.11-1.16-4.11-3.42,0-4.14,2.21-5.53,3.87-6.72,.5-.36,1.03-.49,1.52-.49,1.13,0,2.07,.67,2.07,.67,.09,.04,.16,.06,.21,.06,.51,0-.39-1.81-.39-1.81-.82-.81-1.99-1.25-3.25-1.25"/>
		<path fill="#e4e4e4" d="M41.87,10.88s-.03,0-.04,0c0,0-6.72,3.78-7.55,9.49-.83,5.71,4.33,6.79,4.33,6.79,0,0,.48,.11,1.22,.11,1.96,0,5.73-.74,7.07-6.16,1.84-7.46-4.14-7.92-4.14-7.92,0,0-.46,.01-.78,.1,0,0,.14-.63-.22-.76,0,0-.14-.4,.31-.67,.44-.26,.47-.97-.2-.97m-3.34,9.04c.14,0,.32-.15,.54-.64,.49-1.1,2.31-4.55,4.19-4.55,.22,0,.43,.05,.65,.15,2.08,.97,.31,8.15-2.26,9.85-1.15,.76-2,1.16-2.62,1.16-.77,0-1.18-.61-1.35-1.9-.31-2.32,0-3.79,.55-4.41,0,0,.11,.33,.32,.33"/>
		<path fill="#e4e4e4" d="M59.92,8.39c-.18,0-.3,.97-.21,1.36,.12,.49,.61,6.34-1.09,8.88-1.7,2.54-1.58,3.15-1.58,3.15,0,0-3.65-2.43-5.23-8.51,0,0-.02,0-.07,0-.35,0-1.94-.1-2.48-1.82l-.61-.56s-.36,1.54,0,3.48c.36,1.94-.12,11.18-1.7,13.61,0,0,0,.89,.44,.89,.05,0,.1-.01,.17-.04,0,0,.73-2.31,2.79-2.55,0,0-.24-3.28,1.09-5.35h.03c.17,0,1.05,.1,2.04,2.07,1.06,2.12,2.35,3.9,3.42,3.9,.04,0,.07,0,.1,0,1.09-.12,.73-.15,1.34-2.14,.49-1.61,1.14-4.15,1.76-4.15,.14,0,.29,.14,.43,.46,.73,1.7,.36,2.67,.36,2.67,0,0,2.07,.58,2.8,2.17l.97-.35s-3.28-5.95-1.58-13.37c0,0,.49-.76-.36-1.29-.85-.53-2.43-1.38-2.67-2.23-.05-.18-.11-.26-.16-.26"/>
		<path d="M6.98,10.34c-2.86,0-5.17-2.31-5.17-5.17S4.12,0,6.98,0s5.17,2.31,5.17,5.17-2.31,5.17-5.17,5.17"/>
		<path d="M4.11,13.53s1.66,3.04,3.78,3.32c0,0,.47,12.8-.91,14.18,0,0-1.2,.83-2.03,1.38-.83,.55-2.3,2.03-2.76,3.04-.46,1.01-.46-3.68,.64-7.37,1.11-3.68,1.01-6.91,.37-10.5-.64-3.59,.92-4.05,.92-4.05"/>
		<path d="M9.92,15.93s2.49,4.14-.55,13.91c0,0-.74,1.84-.74,2.21,0,0,1.93-2.67,4.33-3.32,0,0,1.29-4.05,1.2-4.97,0,0,1.38,2.39,2.76,3.59,0,0,.55,1.57-.28,2.67,0,0,1.2-.92,3.04,.09,0,0,2.49-5.99,2.58-15.38,0,0-1.66-.74-2.3-1.75-.64-1.01-.64,0-.64,0,0,0,.37,8.34-1.29,11.58,0,0-2.4-2.19-3.22-5.69-.83-3.5-2.95-6.63-2.95-6.63,0,0-.28-.18-.46,.74-.18,.92-.46,2.21-1.47,2.95"/>
		<path d="M33.77,15.28s-1.93-1.38-3.59-.18c-1.66,1.2-3.87,2.58-3.87,6.72s6.45,4.6,9.3,.92c0,0,.61,1.01-.34,2.12,0,0-2.7,2.86-6.75,2.49-4.05-.37-6.26-5.43-4.33-10.41,1.93-4.97,7.09-5.71,9.39-3.41,0,0,1.11,2.21,.18,1.75"/>
		<path d="M43.53,22.14c-2.57,1.71-3.67,1.58-3.98-.74-.31-2.33,0-3.79,.55-4.41,0,0,.31,.92,.86-.31,.55-1.22,2.75-5.38,4.83-4.4,2.08,.97,.31,8.15-2.26,9.85m1.11-11.55s-.46,0-.78,.1c0,0,.14-.63-.22-.76,0,0-.14-.4,.31-.67,.45-.27,.47-1-.23-.97,0,0-6.72,3.78-7.55,9.49-.83,5.71,4.33,6.79,4.33,6.79,0,0,6.45,1.4,8.29-6.06,1.84-7.46-4.14-7.92-4.14-7.92"/>
		<path d="M50.54,8.28s-.36,1.54,0,3.48-.12,11.18-1.7,13.61c0,0,0,1.09,.61,.85,0,0,.73-2.31,2.8-2.55,0,0-.24-3.28,1.09-5.35,0,0,.97-.12,2.07,2.07,1.09,2.19,2.43,4.01,3.52,3.89,1.09-.12,.73-.16,1.34-2.14,.61-1.99,1.46-5.39,2.19-3.69,.73,1.7,.36,2.67,.36,2.67,0,0,2.07,.58,2.79,2.18l.97-.35s-3.28-5.95-1.58-13.37c0,0,.49-.76-.36-1.29-.85-.53-2.43-1.38-2.67-2.23-.24-.85-.49,.61-.36,1.09s.61,6.34-1.09,8.88c-1.7,2.54-1.58,3.15-1.58,3.15,0,0-3.65-2.43-5.22-8.51,0,0-1.94,.12-2.55-1.82l-.61-.56Z"/>
		<path d="M0,42.32s2.94-.65,3.44,.86c0,0,8.81-5.44,26.61-6.12,17.8-.68,34.89-3.83,40.75-9.8,0,0-14.85,6.82-30.09,7.38l-14.73,1.74S1.81,38.43,0,42.32"/>
	</svg>
);

export default SvgIncom;
