import React from 'react';

const SvgLora = (props: any) => (
    <svg viewBox="0 0 547.13 358.8" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<polygon fill={props.fill} points="0 241.39 0 76.28 41.13 76.28 41.13 206.26 105.45 206.26 105.45 241.39 0 241.39"/>
			<path fill={props.fill} d="M273.9,241.39V76.28h82.25c39.77,0,59.69,17.89,59.69,54.38a45.46,45.46,0,0,1-9.3,27.86c-6,7.95-14.59,13.25-24.54,16.58l37.81,66.29H374.05l-32.5-59H315.69v59H273.9M315,106.78v44.43h37.79c14.59,0,21.89-7.29,21.89-22.54,0-14.59-7.3-22.55-21.89-22.55H315Z"/>
			<path fill={props.fill} d="M543.82,150.55v65c0,11.28,1.32,19.91,3.31,25.85H514a38.27,38.27,0,0,1-2-12.6h-.66c-2.65,4.65-8,8.66-15.91,11.31-7.31,2.65-13.93,4.62-21.24,4.62-11.92,0-21.88-3.33-29.17-9.27-8-6.66-11.93-15.9-11.93-27.22,0-13.9,5.3-24.53,17.23-31.81,9.28-6,25.21-10.62,46.43-13.93l13.26-2v-10c0-8.61-6-13.26-17.23-13.26-6,0-10,1.32-13.27,4-2.65,2.67-4.66,6.64-6,12.6H439c.67-25.85,17.9-38.46,51.06-38.46,17.92,0,31.19,2.65,39.79,7.29q13.95,8,13.94,27.87m-34.48,39.14v-8.63L484.8,187.7c-11.28,3.31-17.24,8.61-17.24,15.91,0,9.28,6,13.94,17.24,13.94a24.41,24.41,0,0,0,17.9-7.31c4-4.65,6.64-11.28,6.64-20.55"/>
			<path fill={props.fill} d="M184.37,114.75c19.89,0,35.16,6,47.74,17.24Q250,148.89,250,179.73T232.11,227.5c-11.92,11.23-27.85,17.21-47.74,17.21s-35.15-6-47.74-17.21q-17.91-17-17.9-47.77T136.63,132c11.93-11.28,27.85-17.24,47.74-17.24m0,27.19c-9.93,0-17.24,3.31-22.54,10.6-4.66,6.64-6.64,15.26-6.64,26.53s2.65,19.89,6.64,26.52c5.3,7.3,12.61,10.6,22.54,10.6,19.89,0,29.18-12.58,29.18-38.44,0-10.62-2.65-19.23-7.29-25.21-4.65-6.63-12.6-10.6-21.89-10.6"/>
			<path fill={props.fill} d="M183,90.2a90.45,90.45,0,0,1,49.07,14.6l7.31-10A100.23,100.23,0,0,0,183.7,78.26c-19.89,0-38.46,5.32-53.7,15.26l7.29,10C149.88,94.84,165.8,90.2,183,90.2"/>
			<path fill={props.fill} d="M107.44,61.69,116.74,75a125.06,125.06,0,0,1,66.31-19.23c25.2,0,49.07,7.29,68.31,20.56L260.64,63C238.76,47.75,211.56,39.15,183,39.15c-27.86,0-53.7,8.6-75.6,22.54"/>
			<path fill={props.fill} d="M183,20.56a156.51,156.51,0,0,1,88.87,27.19l11.94-16.58A177.86,177.86,0,0,0,183,0c-36.47,0-70.31,10.62-98.15,29.18L96.82,45.76A158.53,158.53,0,0,1,183,20.56"/>
			<path fill={props.fill} d="M185,269.25A90.65,90.65,0,0,1,136,254.68l-6.63,10.64A100.1,100.1,0,0,0,185,281.85c19.9,0,38.47-5.29,53.72-15.25l-6.64-10c-13.24,8-29.84,12.6-47.08,12.6"/>
			<path fill={props.fill} d="M261.3,297.79,252,284.5a123.79,123.79,0,0,1-134.62-1.29l-9.29,13.25a138.07,138.07,0,0,0,78.25,23.84c27.18,0,53.06-8.59,74.94-22.51"/>
			<path fill={props.fill} d="M185,338.93a156.36,156.36,0,0,1-88.87-27.22L84.24,328.29A180.76,180.76,0,0,0,185,358.8c36.48,0,70.3-10.64,98.16-29.19L271.92,313A155.89,155.89,0,0,1,185,338.93"/>
		</g>
	</g>
</svg>
);

export default SvgLora;
