import React, { Component } from "react";

import SvgGraph from "../../_svg/Dashboard";
import SvgVm from "../../_svg/VirtualMeter";
import SvgAlerte from "../../_svg/Alert";
import SvgMask from "../../_svg/Mask";
import SvgGSM from "../../_svg/SynchroGSM";
import SvgFiltre from "../../_svg/Filtre";

import ImgRecap from "../../_resources/image/recap.png";

import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Footer = (props: any) => {
  const { t } = props;
  const content = {
    title: t("all.recap"),
  };

  const sections1 = [
    {
      title: t("logiciels.cc.dashboard"),
      description: t("logiciels.cc.dashboard.description"),
      svg: <SvgGraph height={"3em"} fill={"#31c6b3"} />,
    },
    {
      title: t("applications.saphir.gsm"),
      description: t("applications.saphir.gsm.description"),
      svg: <SvgGSM height={"3em"} fill={"#31c6b3"} />,
    },
    {
      title: t("logiciels.cc.vm"),
      description: t('logiciels.cc.vm.description'),
      svg: <SvgVm height={"3em"} fill={"#31c6b3"} />,
    },
  ];

  const sections2 = [
    {
      title: t("logiciels.cc.alertes"),
      description: t('logiciels.cc.vm.description'),
      svg: <SvgAlerte height={"3em"} fill={"#31c6b3"} />,
    },
    {
      title: t("logiciels.cc.mask"),
      description: t("logiciels.cc.mask.description"),
      svg: <SvgMask height={"3em"} fill={"#31c6b3"} />,
    },
    {},
  ];

  const extraSection = [
    {
      title: t("logiciels.cc.vm"),
      description: t('logiciels.cc.vm.description'),
      svg: <SvgVm height={"3em"} fill={"#31c6b3"} />,
    },
    {
      title: t("logiciels.cc.alertes"),
      description: t("logiciels.cc.alertes.description"),
      svg: <SvgAlerte height={"3em"} fill={"#31c6b3"} />,
    },
  ];
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div className={"footer crystalcloud"} itemScope itemType="https://schema.org/CreativeWork">
      <div className={"image-container"}>
      <img src={ImgRecap} alt="récapitulatif"/>
        <h2 itemProp="about">{content.title}</h2>
      </div>
      <div className={"footer-section"}>
        {sections1.map((el: any, index: number) => {
          if ((isMobile && index !== 2) || !isMobile) {
            return (
                <div className={"section"} itemProp="abstract">
                  {el.svg}
                  <h5>{el.title}</h5>
                  <span>{el.description}</span>
                </div>
              );
          }
        })}
      </div>
      {isMobile && (
        <div className={"footer-section"}>
          {extraSection.map((el: any) => (
            <div className={"section"} itemProp="abstract">
              {el.svg}
              <h5>{el.title}</h5>
              <span>{el.description}</span>
            </div>
          ))}
        </div>
      )}
      <div className={"footer-section"}>
        {sections2.map((el: any, index: number) => {
            if ((isMobile && index !== 0) || !isMobile) {
                return (
                    <div className={"section"} itemProp="abstract">
                      {el.svg}
                      <h5>{el.title}</h5>
                      <span>{el.description}</span>
                    </div>
                  );
              }
        })}
      </div>
    </div>
  );
};

const tr = withTranslation()(Footer);
export default tr;
