import React from 'react';

const SvgLogoOnyx = (props: any) => (
<svg version="1.1" x="0px" y="0px" viewBox="0 0 159.3 77.6" {...props}>
	<g id="Calque_1">
		<g>
			<path fill={"#BFBFBF"} d="M88.3,65.2c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.1h-2.3v-5.7h2.2
			c0.5,0,1,0.1,1.3,0.4s0.5,0.7,0.5,1.1c0,0.2,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.4s-0.2,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.2
			c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.5C88.4,64.8,88.4,65,88.3,65.2z
			 M87.2,61.4c-0.2-0.1-0.4-0.2-0.7-0.2h-1.3v1.6h1.3c0.3,0,0.5-0.1,0.7-0.2s0.3-0.3,0.3-0.6S87.4,61.6,87.2,61.4z M87.3,63.9
			c-0.2-0.2-0.4-0.2-0.7-0.2h-1.4v1.7h1.4c0.3,0,0.5-0.1,0.7-0.2s0.2-0.4,0.2-0.6S87.4,64,87.3,63.9z"/>
			<path fill={"#BFBFBF"} d="M91.5,63.8v2.3h-0.9v-2.3L89,60.5h0.9l1.2,2.5l1.2-2.5h0.9L91.5,63.8z"/>
			<path fill={"#BFBFBF"} d="M100,63.9c0,0.2,0,0.4-0.1,0.6s-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1h-2v-5.7h2c0.3,0,0.6,0,0.8,0.1s0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.1,0.4,0.2,0.6
			c0,0.2,0,0.4,0.1,0.6s0,0.4,0,0.6S100,63.7,100,63.9z M99.1,62.3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.3-0.6-0.4-1-0.4h-1v4.1h1
			c0.4,0,0.7-0.1,1-0.4c0.1-0.2,0.2-0.4,0.3-0.7c0-0.3,0-0.6,0-1C99.1,62.9,99.1,62.5,99.1,62.3z"/>
			<path fill={"#BFBFBF"} d="M101.3,66.2v-5.7h0.9v5.7H101.3z"/>
			<path fill={"#BFBFBF"} d="M107.5,64c0,0.2,0,0.4-0.1,0.6s-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.2-0.4,0.3-0.7,0.5
			s-0.5,0.2-0.8,0.2c-0.3,0-0.6-0.1-0.8-0.2s-0.5-0.3-0.7-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.5,0-0.7s0-0.5,0-0.7c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.3-0.4
			c0.2-0.2,0.4-0.3,0.7-0.5s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.5,0.3,0.7,0.5c0.1,0.1,0.2,0.3,0.3,0.4
			c0.1,0.2,0.1,0.3,0.2,0.5s0.1,0.4,0.1,0.6c0,0.2,0,0.5,0,0.7S107.5,63.8,107.5,64z M106.6,62.6c0-0.2,0-0.3,0-0.5
			c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1
			s-0.3,0.1-0.4,0.3c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.5s0,0.4,0,0.7s0,0.5,0,0.7s0,0.3,0,0.5
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1
			c0.1-0.1,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.2,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.7
			S106.6,62.8,106.6,62.6z"/>
			<path fill={"#BFBFBF"} d="M112.6,62.9c-0.1,0.2-0.2,0.4-0.4,0.5s-0.4,0.3-0.6,0.4s-0.5,0.1-0.8,0.1h-1.3v2.2h-0.9v-5.7h2.2
			c0.3,0,0.5,0,0.8,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7C112.8,62.4,112.7,62.7,112.6,62.9z
			 M111.6,61.5c-0.2-0.2-0.4-0.2-0.7-0.2h-1.2v1.9h1.2c0.3,0,0.5-0.1,0.7-0.2c0.2-0.2,0.3-0.4,0.3-0.7
			C111.9,61.9,111.8,61.6,111.6,61.5z"/>
			<path fill={"#BFBFBF"} d="M115.8,61.2v4.9h-0.9v-4.9h-1.6v-0.8h4v0.8H115.8z"/>
			<path fill={"#BFBFBF"} d="M121.1,66.2l-0.4-1.1h-2.2l-0.4,1.1h-0.9l2.1-5.7h0.7l2.1,5.7H121.1z M119.6,61.8l-0.9,2.5h1.8L119.6,61.8z"
			/>
			<path fill={"#BFBFBF"} d="M126.2,65.2c-0.1,0.2-0.2,0.4-0.4,0.5s-0.4,0.3-0.6,0.3c-0.3,0.1-0.5,0.1-0.8,0.1c-0.4,0-0.8-0.1-1.1-0.2
			c-0.3-0.1-0.6-0.3-0.9-0.6l0.6-0.6c0.2,0.2,0.4,0.4,0.7,0.4c0.2,0.1,0.5,0.1,0.8,0.1c0.4,0,0.7-0.1,0.9-0.2s0.3-0.4,0.3-0.6
			c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.4-0.1l-0.7-0.1c-0.5-0.1-0.8-0.2-1.1-0.5
			c-0.3-0.3-0.4-0.6-0.4-1.1c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.3,0.6-0.3c0.2-0.1,0.5-0.1,0.8-0.1
			c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.8,0.5l-0.6,0.5c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2-0.1-0.4-0.1-0.7-0.1
			c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.4,0.2,0.6,0.3l0.6,0.1
			c0.3,0,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.3,0.3,0.5s0.1,0.4,0.1,0.7C126.4,64.8,126.3,65,126.2,65.2z"/>
			<path fill={"#BFBFBF"} d="M127.5,66.2v-5.7h3.6v0.8h-2.8v1.7h2.4v0.8h-2.4v1.7h2.8v0.8H127.5z"/>
		</g>
		<g>
			<path fill={"#1D1D1B"} d="M11.7,44.3c0-3.6,1.1-6.5,3.4-8.8c2.3-2.3,5.1-3.5,8.5-3.5c3.4,0,6.3,1.2,8.5,3.5c2.3,2.3,3.4,5.3,3.4,8.8
			c0,3.6-1.1,6.5-3.4,8.8c-2.2,2.3-5.1,3.5-8.5,3.5c-3.4,0-6.3-1.2-8.5-3.5C12.8,50.8,11.7,47.9,11.7,44.3z M16,44.3
			c0,2.5,0.7,4.5,2.2,6c1.4,1.5,3.2,2.3,5.4,2.3c2.1,0,3.9-0.8,5.4-2.3c1.4-1.5,2.2-3.6,2.2-6c0-2.5-0.7-4.5-2.2-6
			c-1.5-1.6-3.3-2.4-5.4-2.4c-2.1,0-3.9,0.8-5.4,2.4C16.7,39.9,16,41.9,16,44.3z"/>
			<path fill={"#1D1D1B"} d="M39,32.8h4.2V36h0.2c0.7-1.1,1.7-2.1,3-2.8s2.8-1.1,4.3-1.1c2.8,0,5,0.8,6.5,2.4c1.5,1.6,2.3,3.9,2.3,6.9
			v14.5h-4.3V41.6c-0.1-3.8-2-5.7-5.7-5.7c-1.7,0-3.2,0.7-4.3,2.1c-1.2,1.4-1.7,3.1-1.7,5v12.8H39V32.8z"/>
			<path fill={"#1D1D1B"} d="M84.7,32.8L70.3,66.1h-4.5l5.4-11.7l-9.5-21.7h4.7l6.9,16.6h0.1L80,32.8H84.7z"/>
			<path fill={"#1D1D1B"} d="M98.6,44.2l8.3,11.8h-5.1L96,47.4l-5.6,8.5h-5.1l8.2-11.8l-8-11.4h4.9l5.7,8.2l5.4-8.2h5.1L98.6,44.2z"/>
		</g>
		<path fill={"#06D0E7"} d="M113.6,45.8V28.6c0-9.5,7.7-17.2,17.2-17.2h0c9.5,0,17.2,7.7,17.2,17.2v0c0,9.5-7.7,17.2-17.2,17.2H113.6z"/>
		<g>
			<path fill={"#FFFFFF"} d="M128.7,34.5c-0.1-0.1-0.2-0.3-0.2-0.5l0,0c-0.1-0.9-0.5-1.7-1.1-2.3c-0.6-0.6-1.5-1-2.3-1.1
			c-0.4,0-0.7-0.4-0.7-0.8v-2.1c0-0.2,0.1-0.4,0.2-0.6c0.2-0.1,0.4-0.2,0.6-0.2c1.8,0.1,3.5,0.9,4.8,2.2c1.3,1.3,2.1,3,2.2,4.8
			c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.4,0.2-0.6,0.2l-2.1,0C129,34.8,128.8,34.7,128.7,34.5z M127.9,31.2L127.9,31.2
			c0.8,0.8,1.2,1.8,1.3,2.8l0,0l0,0l2.1,0c-0.1-1.6-0.8-3.1-1.9-4.3c-1.2-1.2-2.7-1.8-4.3-1.9l0,2.1
			C126.2,29.9,127.2,30.4,127.9,31.2z"/>
			<path fill={"#FFFFFF"} d="M123.6,35.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.9,0.7-1.7,1.7-1.7c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2
			v0v0c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5C124.3,36,123.9,35.8,123.6,35.5z M125.4,33.8c-0.2-0.2-0.4-0.3-0.6-0.3
			c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.2,0,0.4-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6v0
			C125.6,34.1,125.5,33.9,125.4,33.8z"/>
			<path fill={"#FFFFFF"} d="M134.5,34.5c-0.1-0.1-0.2-0.3-0.2-0.5c-0.1-2.4-1.1-4.7-2.8-6.4c-1.7-1.7-4-2.7-6.4-2.8
			c-0.4,0-0.8-0.4-0.8-0.8V22c0-0.2,0.1-0.4,0.2-0.6c0.2-0.1,0.4-0.2,0.6-0.2c6.9,0.2,12.6,5.8,12.8,12.8l0,0c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.2-0.3,0.2-0.6,0.2H135C134.8,34.8,134.6,34.7,134.5,34.5z M132,27.1c1.8,1.8,2.9,4.3,3,6.9l2.1,0l0,0
			c-0.2-6.5-5.5-11.8-12-12l0,2.1C127.7,24.2,130.2,25.2,132,27.1z"/>
		</g>
	</g>
	<g id="marges_sécu" style={{display:"none"}}>
		<g style={{display: "inline"}}>
			<rect x="59.7" y="66.2" width="5.7" height="5.7"/>
			<rect x="59.7" y="71.9" width="5.7" height="5.7"/>
		</g>
		<g style={{display: "inline"}}>
			<rect x="127.9" width="5.7" height="5.7"/>
			<rect x="127.9" y="5.7" width="5.7" height="5.7"/>
		</g>
		<g style={{display: "inline"}}>
			<rect x="153.6" y="25.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 184.4163 -128.5422)" width="5.7" height="5.7"/>
			<rect x="147.9" y="25.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 178.6934 -122.8192)" width="5.7" height="5.7"/>
		</g>
		<rect y="66.2" style={{display: "inline", opacity: 0.5, fill: "#CF7830"}} width="159.3" height="11.4"/>
		<rect x="-33.1" y="33.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 44.5238 33.0773)" style={{display: "inline", opacity: 0.5, fill: "#CF7830"}} width="77.6" height="11.4"/>
		<rect style={{display: "inline", opacity: 0.5, fill: "#CF7830"}} width="159.3" height="11.4"/>
		<rect x="114.8" y="33.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 192.4072 -114.8277)" style={{display: "inline", opacity: 0.5, fill: "#CF7830"}} width="77.6" height="11.4"/>
	</g>
</svg>

);

export default SvgLogoOnyx;
