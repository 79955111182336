import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import SvgChangementCompteur from "../../_svg/ChangementCompteur";
import VisibilitySensor from "react-visibility-sensor";
import { withTranslation } from 'react-i18next';

const App = (props) => {
  const ref = useRef();
  const { t } = props
  const content = {
    title: t('applications.saphir.switch'),
    description: t('applications.saphir.switch.description')
  }
  return (
              <div className={"svg-container"} id={"svg-compteur-saphir"} itemScope itemType="https://schema.org/WebPage">
                <div>
                  <SvgChangementCompteur  fill={"#b77830"} width="6em"/>
                  <h3 itemProp="alternativeHeadline">{content.title}</h3>
                  <span itemProp="description">
                    {content.description}
                  </span>
                </div>
              </div>
  );
};

const tr = withTranslation()(App);
export default tr;
