import React from 'react';

const SvgCasque = (props: any) => (
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 14.2 14.2" {...props}>
<g>
	<path fill="#CF7830" d="M13,4.9h-0.4C12,2.1,9.7,0,7.1,0C4.4,0,2.1,2.1,1.5,4.9H1.3c-0.4,0-0.8,0.4-0.8,0.8V10c0,0.4,0.4,0.8,0.8,0.8
		h1.2c0.4,0,0.8-0.4,0.8-0.8V5.7c0-0.4-0.4-0.8-0.8-0.8H2.3c0.6-2.4,2.5-4.1,4.8-4.1c2.3,0,4.2,1.7,4.8,4.1h-0.1
		c-0.4,0-0.8,0.4-0.8,0.8V10c0,0.4,0.3,0.7,0.6,0.8c-0.4,0.8-1.4,1.9-3.7,2.2c-0.1-0.2-0.4-0.4-0.6-0.4H5.9c-0.4,0-0.7,0.3-0.7,0.7
		v0.2c0,0.4,0.3,0.7,0.7,0.7h1.3c0.3,0,0.6-0.2,0.7-0.5c2.8-0.3,3.9-1.9,4.4-2.9H13c0.4,0,0.8-0.4,0.8-0.8V5.7
		C13.8,5.2,13.4,4.9,13,4.9z"/>
</g>
</svg>
);

export default SvgCasque;
