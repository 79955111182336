import mailService from "./mail.service";
import mailConstants from "./mail.constants";

function send(mail) {
  function request() {
    return { type: mailConstants.SEND_MAIL_REQUEST};
  }
    function success(mail) {
      return { type: mailConstants.SEND_MAIL_SUCCESS, mail };
    }
    function failure(error) {
      return { type: mailConstants.SEND_MAIL_FAILURE, error };
    }
  
    return (dispatch) => {
      dispatch(request())
      mailService
        .send(mail)
        .then((mail) => {
          dispatch(success(mail));
        })
        .catch((error) => {
          dispatch(failure(error))
        });
    };
  }

  function sendWithAttachment(formData) {
    function request() {
      return { type: mailConstants.SEND_MAIL_WITH_ATTACHMENT_REQUEST};
    }
      function success(mail) {
        return { type: mailConstants.SEND_MAIL_WITH_ATTACHMENT_SUCCESS, mail };
      }
      function failure(error) {
        return { type: mailConstants.SEND_MAIL_WITH_ATTACHMENT_FAILURE, error };
      }
    
      return (dispatch) => {
        dispatch(request())
        mailService
          .sendWithAttachment(formData)
          .then((mail) => {
            dispatch(success(mail));
          })
          .catch((error) => {
            dispatch(failure(error))
          });
      };
    }

  function clear() {
    return {
        type: mailConstants.CLEAR,
    };
}

  const mailActions = {
    send,
    sendWithAttachment,
    clear
  };
  
  export default mailActions;