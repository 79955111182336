import React from 'react';

const SvgSontex = (props: any) => (
	<svg viewBox="0 0 155.98 62.75" {...props}>
		<path d="M23.21,47.1v12.53H14.96v-5.37c0-2.45-.46-4.88-1.4-7.13-1.03-2.47-2.81-5.62-5.84-8.84-2.93-3.11-4.78-6.19-5.93-8.66-1.19-2.55-1.79-5.33-1.79-8.15V11.86C0,5.31,5.2,0,11.61,0s11.6,5.31,11.6,11.86v12.53H14.96V12.7c0-1.86-1.48-3.37-3.3-3.37s-3.3,1.51-3.3,3.37v5.74c0,2.18,.51,4.33,1.49,6.26,1.14,2.23,3.22,5.36,6.54,9.68,6.83,8.9,6.82,12.71,6.82,12.71"/>
		<rect x="0" y="49.1" width="8.8" height="10.79"/>
		<path d="M67.91,15.94c-2.38,0-4.3,1.97-4.3,4.39v-3.77h-5.09V49.1h5.09V24.05c0-1.21,.96-2.19,2.14-2.19s2.14,.98,2.14,2.19v25.05h5.11V21.14c0-2.87-2.28-5.2-5.09-5.2"/>
		<polygon points="92.06 16.57 88.9 16.57 88.9 6.22 83.83 9.09 83.83 16.57 81.16 16.57 81.16 21.86 83.83 21.86 83.83 49.11 88.9 49.11 88.9 21.86 92.06 21.86 92.06 16.57"/>
		<path d="M109.96,33.42h-5.15v-10.22c0-1.45,1.15-2.63,2.58-2.63s2.58,1.18,2.58,2.63v10.22Zm-2.66-17.48h0c-3.95,0-7.16,3.27-7.16,7.31v25.86h4.67v-10.28l9.65,.02v-15.6c0-4.04-3.2-7.31-7.16-7.31"/>
		<rect x="109.96" y="43.1" width="4.49" height="6.01"/>
		<polygon points="133.58 36.72 133.58 36.72 138.48 16.73 134.08 16.73 131.01 29.56 128.32 16.57 122.58 16.57 128.01 35.36 124.23 49.27 129.09 49.27 130.55 42.64 131.97 49.11 136.84 49.11 133.73 37.13 133.58 36.72"/>
		<path d="M43.76,46.77c0,1.29-1.02,2.33-2.28,2.33s-2.28-1.04-2.28-2.33V23.38c0-1.29,1.02-2.33,2.28-2.33s2.28,1.04,2.28,2.33v23.39Zm-2.15-30.84c-3.95,0-7.16,3.27-7.16,7.31v23.14c0,.96,.18,1.88,.51,2.71h13.29c.33-.84,.51-1.76,.51-2.71V23.25c0-4.04-3.2-7.31-7.16-7.31"/>
		<path d="M34.94,56.39l.78,2.34c.09,.27,.18,.6,.24,.85h.03c.07-.25,.15-.58,.23-.86l.67-2.33h1.17l-1.08,2.98c-.59,1.63-.99,2.36-1.51,2.81-.43,.39-.87,.53-1.14,.57l-.25-.93c.19-.04,.42-.14,.64-.31,.2-.13,.44-.39,.59-.69,.04-.08,.07-.14,.07-.2,0-.04,0-.11-.06-.22l-1.58-4.01h1.2Z"/>
		<path d="M39.42,58.58c0,.84,.41,1.48,1.07,1.48,.62,0,1.05-.62,1.05-1.5,0-.68-.3-1.46-1.04-1.46s-1.08,.75-1.08,1.48m3.25-.04c0,1.61-1.12,2.33-2.21,2.33-1.22,0-2.16-.85-2.16-2.25s.93-2.32,2.23-2.32,2.14,.92,2.14,2.24"/>
		<path d="M47.43,59.45c0,.52,.02,.95,.04,1.31h-.96l-.05-.66h-.02c-.19,.31-.61,.76-1.38,.76s-1.51-.47-1.51-1.9v-2.57h1.09v2.38c0,.73,.23,1.19,.81,1.19,.43,0,.72-.31,.83-.59,.04-.1,.06-.22,.06-.34v-2.64h1.09v3.06Z"/>
		<path d="M48.61,57.8c0-.59,0-1.01-.04-1.41h.95l.04,.83h.04c.21-.62,.72-.93,1.18-.93,.11,0,.17,0,.26,.03v1.04c-.09-.02-.19-.04-.32-.04-.52,0-.88,.34-.97,.83-.02,.1-.04,.22-.04,.34v2.26h-1.09v-2.96Z"/>
		<rect x="53.6" y="54.39" width="1.1" height="6.37"/>
		<path d="M55.87,56.39h1.1v4.37h-1.1v-4.37Zm1.16-1.22c0,.33-.24,.59-.62,.59s-.6-.26-.6-.59,.25-.6,.61-.6,.6,.26,.61,.6"/>
		<path d="M58.14,57.7c0-.5,0-.92-.04-1.3h.96l.05,.66h.03c.19-.34,.66-.75,1.37-.75,.75,0,1.53,.49,1.53,1.88v2.59h-1.09v-2.47c0-.63-.23-1.1-.82-1.1-.43,0-.74,.31-.85,.65-.04,.1-.04,.23-.04,.36v2.57h-1.1v-3.07Z"/>
		<path d="M64.3,58.31h.02c.11-.17,.23-.36,.34-.51l1.05-1.41h1.31l-1.59,1.79,1.83,2.58h-1.34l-1.24-1.93-.37,.44v1.49h-1.09v-6.37h1.09v3.92Z"/>
		<path d="M70.8,55.15v1.25h1.04v.83h-1.04v1.93c0,.53,.14,.81,.56,.81,.19,0,.33-.03,.43-.05l.02,.84c-.16,.06-.44,.11-.79,.11-.4,0-.74-.13-.94-.36-.23-.25-.34-.65-.34-1.22v-2.06h-.62v-.83h.62v-.99l1.06-.26Z"/>
		<path d="M73.46,58.58c0,.84,.41,1.48,1.07,1.48,.62,0,1.05-.62,1.05-1.5,0-.68-.3-1.46-1.04-1.46s-1.08,.75-1.08,1.48m3.25-.04c0,1.61-1.12,2.33-2.21,2.33-1.22,0-2.16-.85-2.16-2.25s.93-2.32,2.23-2.32,2.14,.92,2.14,2.24"/>
		<path d="M79.44,56.39h1.1v4.37h-1.1v-4.37Zm1.16-1.22c0,.33-.24,.59-.62,.59s-.6-.26-.6-.59,.25-.6,.61-.6,.6,.26,.61,.6"/>
		<path d="M81.71,57.7c0-.5,0-.92-.04-1.3h.96l.05,.66h.03c.19-.34,.66-.75,1.37-.75,.75,0,1.53,.49,1.53,1.88v2.59h-1.09v-2.47c0-.63-.23-1.1-.82-1.1-.43,0-.74,.31-.85,.65-.04,.1-.04,.23-.04,.36v2.57h-1.1v-3.07Z"/>
		<path d="M86.78,57.7c0-.5,0-.92-.04-1.3h.96l.05,.66h.03c.19-.34,.66-.75,1.37-.75,.75,0,1.53,.49,1.53,1.88v2.59h-1.09v-2.47c0-.63-.23-1.1-.82-1.1-.43,0-.74,.31-.85,.65-.04,.1-.04,.23-.04,.36v2.57h-1.1v-3.07Z"/>
		<path d="M92.7,58.58c0,.84,.41,1.48,1.07,1.48,.62,0,1.05-.62,1.05-1.5,0-.68-.3-1.46-1.04-1.46s-1.08,.75-1.08,1.48m3.25-.04c0,1.61-1.12,2.33-2.21,2.33-1.22,0-2.16-.85-2.16-2.25s.93-2.32,2.23-2.32,2.14,.92,2.14,2.24"/>
		<path d="M97.46,56.39l.7,2.22c.12,.39,.21,.74,.29,1.1h.03c.08-.36,.18-.7,.29-1.1l.68-2.22h1.15l-1.65,4.37h-1.07l-1.6-4.37h1.18Z"/>
		<path d="M103.5,58.63c-.77-.02-1.51,.15-1.51,.82,0,.43,.27,.63,.62,.63,.43,0,.75-.29,.85-.6,.03-.08,.04-.17,.04-.24v-.6Zm.14,2.14l-.07-.48h-.03c-.26,.34-.72,.58-1.28,.58-.87,0-1.36-.64-1.36-1.3,0-1.1,.97-1.66,2.56-1.65v-.07c0-.29-.12-.76-.88-.76-.43,0-.87,.13-1.16,.32l-.21-.72c.32-.2,.88-.39,1.56-.39,1.38,0,1.78,.89,1.78,1.84v1.58c0,.39,.02,.78,.06,1.05h-.98Z"/>
		<path d="M106.91,55.15v1.25h1.04v.83h-1.04v1.93c0,.53,.14,.81,.56,.81,.19,0,.33-.03,.43-.05l.02,.84c-.16,.06-.44,.11-.79,.11-.4,0-.74-.13-.94-.36-.23-.25-.34-.65-.34-1.22v-2.06h-.62v-.83h.62v-.99l1.06-.26Z"/>
		<path d="M108.77,56.39h1.1v4.37h-1.1v-4.37Zm1.16-1.22c0,.33-.24,.59-.62,.59s-.6-.26-.6-.59,.25-.6,.61-.6,.6,.26,.61,.6"/>
		<path d="M111.73,56.39l.7,2.22c.12,.39,.21,.74,.29,1.1h.03c.08-.36,.18-.7,.29-1.1l.68-2.22h1.15l-1.65,4.37h-1.07l-1.6-4.37h1.18Z"/>
		<path d="M118.09,58.12c0-.4-.17-1.07-.89-1.07-.67,0-.96,.62-1,1.07h1.9Zm-1.9,.77c.03,.79,.64,1.13,1.33,1.13,.51,0,.87-.07,1.2-.2l.16,.76c-.37,.15-.89,.27-1.51,.27-1.4,0-2.22-.87-2.22-2.21,0-1.21,.73-2.35,2.11-2.35s1.86,1.17,1.86,2.13c0,.21-.02,.37-.04,.47h-2.89Z"/>
		<path d="M121.83,57.7c0-.5,0-.92-.04-1.3h.94l.04,.65h.03c.21-.34,.6-.74,1.33-.74,.57,0,1.01,.32,1.2,.81h.02c.15-.24,.33-.42,.53-.55,.24-.17,.51-.26,.87-.26,.72,0,1.44,.49,1.44,1.89v2.58h-1.06v-2.41c0-.73-.25-1.16-.77-1.16-.37,0-.65,.27-.76,.58-.03,.11-.05,.24-.05,.37v2.62h-1.06v-2.53c0-.61-.24-1.04-.74-1.04-.41,0-.68,.32-.78,.63-.04,.11-.06,.23-.06,.36v2.58h-1.06v-3.07Z"/>
		<path d="M132.01,58.12c0-.4-.17-1.07-.89-1.07-.67,0-.96,.62-1,1.07h1.9Zm-1.9,.77c.03,.79,.64,1.13,1.33,1.13,.51,0,.87-.07,1.2-.2l.16,.76c-.37,.15-.89,.27-1.51,.27-1.4,0-2.22-.87-2.22-2.21,0-1.21,.73-2.35,2.11-2.35s1.86,1.17,1.86,2.13c0,.21-.02,.37-.04,.47h-2.89Z"/>
		<path d="M135.17,55.15v1.25h1.04v.83h-1.04v1.93c0,.53,.14,.81,.56,.81,.19,0,.33-.03,.43-.05l.02,.84c-.16,.06-.44,.11-.79,.11-.4,0-.74-.13-.94-.36-.23-.25-.34-.65-.34-1.22v-2.06h-.62v-.83h.62v-.99l1.06-.26Z"/>
		<path d="M139.64,58.12c0-.4-.17-1.07-.89-1.07-.67,0-.96,.62-1,1.07h1.9Zm-1.9,.77c.03,.79,.64,1.13,1.33,1.13,.51,0,.87-.07,1.2-.2l.16,.76c-.37,.15-.89,.27-1.51,.27-1.4,0-2.22-.87-2.22-2.21,0-1.21,.73-2.35,2.11-2.35s1.86,1.17,1.86,2.13c0,.21-.02,.37-.04,.47h-2.89Z"/>
		<path d="M141.55,57.8c0-.59,0-1.01-.04-1.41h.95l.04,.83h.04c.21-.62,.72-.93,1.18-.93,.11,0,.17,0,.26,.03v1.04c-.09-.02-.19-.04-.32-.04-.52,0-.88,.34-.97,.83-.02,.1-.04,.22-.04,.34v2.26h-1.09v-2.96Z"/>
		<path d="M144.7,56.39h1.1v4.37h-1.1v-4.37Zm1.16-1.22c0,.33-.24,.59-.62,.59s-.6-.26-.6-.59,.25-.6,.61-.6,.6,.26,.61,.6"/>
		<path d="M146.97,57.7c0-.5,0-.92-.04-1.3h.96l.05,.66h.03c.19-.34,.66-.75,1.37-.75,.75,0,1.53,.49,1.53,1.88v2.59h-1.09v-2.47c0-.63-.23-1.1-.82-1.1-.43,0-.74,.31-.85,.65-.04,.1-.04,.23-.04,.36v2.57h-1.1v-3.07Z"/>
		<path d="M154.85,58.14c0-.1,0-.21-.04-.3-.11-.4-.43-.71-.89-.71-.61,0-1.06,.54-1.06,1.43,0,.74,.37,1.35,1.05,1.35,.41,0,.76-.27,.89-.66,.03-.12,.04-.27,.04-.39v-.71Zm1.13-1.75c-.02,.3-.04,.66-.04,1.26v2.48c0,.92-.19,1.57-.64,1.98-.45,.4-1.07,.53-1.66,.53-.55,0-1.13-.12-1.51-.35l.24-.83c.27,.16,.74,.33,1.26,.33,.71,0,1.24-.38,1.24-1.32v-.38h-.02c-.25,.39-.69,.65-1.26,.65-1.07,0-1.83-.9-1.83-2.14,0-1.44,.92-2.31,1.96-2.31,.66,0,1.04,.32,1.25,.68h.02l.04-.58h.95Z"/>
	</svg>
);

export default SvgSontex;
