import React, { Component, useState } from "react";
import { connect } from "react-redux";

import Earth from "../_svg/Earth";
import Graphique from "../_svg/Dashboard";
import Site from "../_svg/Sitescc";
import Telephone from "../_svg/MultiProtocole";
import Switch from "../_svg/ChangementCompteur";
import Filtre from "../_svg/Filtre";
import Concat from "../_svg/Concat";
import Geoguidage from "../_svg/Geoguidage";
import SynchroGSM from "../_svg/SynchroGSM";
import Geolocalisation from "../_svg/Geolocalisation";
import Support from "../_svg/Support";
import Securite from "../_svg/Securite";
import VirtualMeter from "../_svg/VirtualMeter";
import Alerte from "../_svg/Alert";
import Export from "../_svg/Export";
import Mask from "../_svg/Mask";

import ConsoAlarm from '../_svg/Conso_alarm';
import ConsoData from '../_svg/Conso_data'
import ConsoInterface from '../_svg/Conso_interface';
import ConsoMeter from '../_svg/Conso_meter';
import ConsoSuivi from '../_svg/Conso_suivi';

import MultiUtilisateur from '../_svg/MultiUtilisateur';
import ContratAbonnement from '../_svg/ContratAbonnement';
import RemplacementCompteur from '../_svg/RemplacementCompteur'
import SignatureElectronique from '../_svg/SignatureElectronique';
import ResiliationContrat from '../_svg/ResiliationContrat';

import { UncontrolledTooltip, Tooltip } from "reactstrap";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import Email from "../_svg/Email";

const svgList = (visible, t) => {
  return [
    {
      svg: (
        <div id="device" style={{ width: "fit-content" }}>
          <Telephone
            height={"1.5em"}
            fill={visible === "protocole" ? "#b77830" : "#333"}
          />
        </div>
      ),
      section: "protocole",
      id: "device",
      tooltip: t('applications.saphir.multi.protocol'),
      index: 1,
    },
    {
      svg: (
        <div id="switch" style={{ width: "fit-content" }}>
          <Switch
            height={"1.5em"}
            fill={visible === "switch" ? "#b77830" : "#333"}
          />
        </div>
      ),
      section: "switch",
      id: "switch",
      tooltip: t('applications.saphir.switch'),
      index: 2,
    },
    {
      svg: (
        <div id="filter" style={{ width: "fit-content" }}>
          <Filtre
            height={"1.5em"}
            fill={visible === "filter" ? "#b77830" : "#333"}
          />
        </div>
      ),
      section: "filtre",
      id: "filter",
      tooltip: t('applications.filter'),
      index: 3,
    },
    {
      svg: (
        <div id="concat" style={{ width: "fit-content" }}>
          <Concat
            height={"1.5em"}
            fill={visible === "concat" ? "#b77830" : "#333"}
          />
        </div>
      ),
      section: "concat",
      id: "concat",
      tooltip: t('applications.saphir.concat'),
      index: 4,
    },
    {
      svg: (
        <div id="geoguidage" style={{ width: "fit-content" }}>
          <Geoguidage
            height={"1.5em"}
            fill={visible === "geoguidage" ? "#b77830" : "#333"}
          />
        </div>
      ),
      section: "geoguidage",
      id: "geoguidage",
      tooltip: t('applications.saphir.geoguiding'),
      index: 5,
    },
    {
      svg: (
        <div id="GSM" style={{ width: "fit-content" }}>
          <SynchroGSM
            height={"1.5em"}
            fill={visible === "GSM" ? "#b77830" : "#333"}
          />
        </div>
      ),
      section: "synchro",
      id: "GSM",
      tooltip: t('applications.saphir.gsm'),
      index: 6,
    },
    {
      svg: (
        <div id="geolocalisation" style={{ width: "fit-content" }}>
          <Geolocalisation
            height={"1.5em"}
            fill={visible === "geolocalisation" ? "#b77830" : "#333"}
          />
        </div>
      ),
      section: "geolocalisation",
      id: "geolocalisation",
      tooltip: t('applications.saphir.geolocation'),
      index: 7,
    },
    {
      svg: (
        <div id="support" style={{ width: "fit-content" }}>
          <Support
            height={"1.5em"}
            fill={visible === "support" ? "#b77830" : "#333"}
          />
        </div>
      ),
      section: "support",
      id: "support",
      tooltip: t('applications.saphir.support'),
      index: 8,
    },
    {
      svg: (
        <div id="securite" style={{ width: "fit-content" }}>
          <Securite
            height={"1.5em"}
            fill={visible === "securite" ? "#b77830" : "#333"}
          />
        </div>
      ),
      section: "securite",
      id: "securite",
      tooltip: t('applications.saphir.security'),
      index: 9,
    },
  ];
};
const svgInterventionList = (visible, t) => {
  return [
    {
      svg: (
        <div id="earth" style={{ width: "fit-content" }}>
          <MultiUtilisateur
            height={"1.5em"}
            fill={visible === "earth" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "connexion",
      id: "earth",
      tooltip: t('intervention.multi.users'),
      index: 0,
    },
    {
      svg: (
        <div id="synchronisation" style={{ width: "fit-content" }}>
          <ContratAbonnement
            height={"1.5em"}
            fill={visible === "synchronisation" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "synchronisation",
      id: "synchronisation",
      tooltip: t('intervention.contract.abo'),
      index: 1,
    },
    {
      svg: (
        <div id="vm" style={{ width: "fit-content" }}>
          <RemplacementCompteur
            height={"1.5em"}
            fill={visible === "vm" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "virtualMeter",
      id: "vm",
      tooltip: t('intervention.switch.meter'),
      index: 2,
    },
    {
      svg: (
        <div id="alertes" style={{ width: "fit-content" }}>
          <SignatureElectronique
            height={"1.5em"}
            fill={visible === "alerte" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "alertes",
      id: "alertes",
      tooltip: t('intervention.electronic.signature'),
      index: 3,
    },
    {
      svg: (
        <div id="mask" style={{ width: "fit-content" }}>
          <ResiliationContrat
            height={"1.5em"}
            fill={visible === "mask" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "mask",
      id: "mask",
      tooltip: t('intervention.termination.contract'),
      index: 4,
    },
    {
      svg: (
        <div id="email" style={{ width: "fit-content" }}>
          <Email
            height={"1.5em"}
            fill={visible === "email" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "email",
      id: "email",
      tooltip: t('intervention.email'),
      index: 5,
    },
  ];
};
const svgMaconsoList = (visible, t) => {
  return [
    {
      svg: (
        <div id="earth" style={{ width: "fit-content" }}>
          <ConsoInterface
            height={"1.5em"}
            fill={visible === "earth" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "connexion",
      id: "earth",
      tooltip: t('maconso.interface'),
      index: 0,
    },
    {
      svg: (
        <div id="synchronisation" style={{ width: "fit-content" }}>
          <ConsoSuivi
            height={"1.5em"}
            fill={visible === "synchronisation" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "synchronisation",
      id: "synchronisation",
      tooltip: t('maconso.followed'),
      index: 1,
    },
    {
      svg: (
        <div id="vm" style={{ width: "fit-content" }}>
          <ConsoMeter
            height={"1.5em"}
            fill={visible === "vm" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "virtualMeter",
      id: "vm",
      tooltip: t('maconso.subscribers'),
      index: 2,
    },
    {
      svg: (
        <div id="alertes" style={{ width: "fit-content" }}>
          <ConsoAlarm
            height={"1.5em"}
            fill={visible === "alerte" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "alertes",
      id: "alertes",
      tooltip: t('maconso.alarms'),
      index: 3,
    },
    {
      svg: (
        <div id="mask" style={{ width: "fit-content" }}>
          <ConsoData
            height={"1.5em"}
            fill={visible === "mask" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "mask",
      id: "mask",
      tooltip: t('maconso.datas'),
      index: 4,
    },
  ];
};

const svgCrystalcloudList = (visible, t) => {
  return [
    {
      svg: (
        <div id="earth" style={{ width: "fit-content" }}>
          <Graphique
            height={"1.5em"}
            fill={visible === "earth" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "connexion",
      id: "earth",
      tooltip: t('logiciels.cc.dashboard'),
      index: 0,
    },
    {
      svg: (
        <div id="synchronisation" style={{ width: "fit-content" }}>
          <SynchroGSM
            height={"1.5em"}
            fill={visible === "synchronisation" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "synchronisation",
      id: "synchronisation",
      tooltip: t('logiciels.cc.synchronisation'),
      index: 1,
    },
    {
      svg: (
        <div id="vm" style={{ width: "fit-content" }}>
          <VirtualMeter
            height={"1.5em"}
            fill={visible === "vm" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "virtualMeter",
      id: "vm",
      tooltip: t('logiciels.cc.vm'),
      index: 2,
    },
    {
      svg: (
        <div id="alertes" style={{ width: "fit-content" }}>
          <Alerte
            height={"1.5em"}
            fill={visible === "alerte" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "alertes",
      id: "alertes",
      tooltip: t('logiciels.cc.alertes'),
      index: 3,
    },
    {
      svg: (
        <div id="mask" style={{ width: "fit-content" }}>
          <Mask
            height={"1.5em"}
            fill={visible === "mask" ? "#31c6b3" : "#333"}
          />
        </div>
      ),
      section: "mask",
      id: "mask",
      tooltip: t('logiciels.cc.mask'),
      index: 4,
    },
  ];
};

const svgOnyxList = (visible, t) => {
  return [
    {
      svg: (
        <div id="earth" style={{ width: "fit-content" }}>
          <Earth
            height={"1.5em"}
            fill={visible === "earth" ? "#268491" : "#333"}
          />
        </div>
      ),
      section: "connexion",
      id: "earth",
      tooltip: t('applications.onyx.fluid'),
      index: 0,
    },
    {
      svg: (
        <div id="filtre" style={{ width: "fit-content" }}>
          <Filtre
            height={"1.5em"}
            fill={visible === "filtre" ? "#268491" : "#333"}
          />
        </div>
      ),
      section: "filtre",
      id: "filtre",
      tooltip: t('applications.filter'),
      index: 1,
    },
    {
      svg: (
        <div id="export" style={{ width: "fit-content" }}>
          <Export
            height={"1.5em"}
            fill={visible === "export" ? "#268491" : "#333"}
          />
        </div>
      ),
      section: "export",
      id: "export",
      tooltip: t('applications.onyx.export'),
      index: 2,
    },
  ];
};

const IndicatorBar = (props) => {
  const { t } = props;
  function listSwitcher() {
    const { parent, isVisible, indicator } = props;
    switch (parent) {
      case "onyx":
        return svgOnyxList(indicator.section, t);
      case "maconso":
        return svgMaconsoList(indicator.section, t);
      case "intervention":
        return svgInterventionList(indicator.section, t);
      case "crystalcloud":
        return svgCrystalcloudList(indicator.section, t);
      case "saphir":
        return svgList(indicator.section, t);
    }
  }
  const array = listSwitcher() || [];
  const [tooltipOpen, setTooltipOpen] = useState("");
  const [event, setEvent] = useState({ clientX: 0 });

  const toggle = (el, e) => {
    if (e.type === "mouseover") {
      setTooltipOpen(el);
      setEvent(e);
    } else {
      setTooltipOpen("");
    }
  };
  return (
    <div className="indicatorBar saphir">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: 10,
        }}
        className="padding-bar"
      >
        {array.map((el) => (
          <>
            <div
              className="svg-indicator"
              data-tip
              data-for={el.id}
              style={{ width: `${100 / array.length}%` }}

            >
              {el.svg}
            </div>
            <ReactTooltip id={el.id} type="dark" place="bottom" effect="solid" className="react-tooltip-indicator">
              <span>{el.tooltip}</span>
            </ReactTooltip>
          </>
        ))}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { indicator } = state;

  return {
    indicator,
  };
}

const connectedIndicatorBar = connect(mapStateToProps)(IndicatorBar);
const tr = withTranslation()(connectedIndicatorBar);
export default tr;
