import React from 'react';

const SvgFiltre = (props: any) => (
	<svg viewBox="0 0 24 13.66" {...props}>
		<path fill={props.fill}
			  d="m18.12.18H5.55c-.25,0-.45.2-.45.45v2.25c0,.25.2.45.45.45h.56l4.15,4.87v5.17c0,.17.09.32.24.4.07.03.14.05.21.05.09,0,.17-.03.25-.08l2.25-1.5c.12-.08.2-.23.2-.38v-3.67l4.14-4.87h.56c.25,0,.46-.2.46-.45V.63c0-.25-.2-.45-.45-.45m-5.51,7.56c-.07.08-.11.19-.11.3v3.6l-1.34.89v-4.49c0-.11-.04-.21-.11-.3l-3.76-4.41h9.07l-3.75,4.41Zm5.05-5.32H6v-1.34h11.66v1.34Z"/>
	</svg>
);

export default SvgFiltre;
