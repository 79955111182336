import React from "react";
import ReactTooltip from "react-tooltip";

import Moniteur from "../../_svg/Moniteur";
import Stickman from "../../_svg/Stickman";
import Antenne from "../../_svg/Antenne";
import Camion from "../../_svg/Camion";
import SmartphoneHome from "../../_svg/SmartphoneHome";
import { useMediaQuery } from "react-responsive";

const ScrollBar = (props) => {
  const { start, precipitate, coef } = props;

  const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });

  window.addEventListener("scroll", () => {
    let number = Math.round(
      (window.scrollY -
        (start -
          (precipitate ? window.innerHeight * 5 : window.innerHeight * 2) ||
          0)) /
        coef
    );

    const one = document.getElementById("progress-1");
    const two = document.getElementById("progress-2");
    const three = document.getElementById("progress-3");
    const four = document.getElementById("progress-4");
    const five = document.getElementById("progress-5");

    const rOne = document.getElementById("round-1");
    const rTwo = document.getElementById("round-2");
    const rThree = document.getElementById("round-3");
    const rFour = document.getElementById("round-4");
    const rFive = document.getElementById("round-5");

    const general = document.getElementById("white-bar");
    if(general && (100 - 100 * (number / 789))){
        general.style.height = `${100 - 100 * (number / 789)}%`;
        if(number >= 788){
          general.style.height = `0%`;
        }
    }

    if (one && number >= 0 && number < 37) {
      one.style.height = `${100 * (number / 36)}%`;
    }
    if (two && number >= 37 && number < 84) {
      two.style.height = `${100 * ((number - 36) / 47)}%`;
    }
    if (three && number >= 84 && number < 169) {
      three.style.height = `${100 * ((number - 83) / 85)}%`;
    }
    if (four && number >= 169 && number < 301) {
      four.style.height = `${100 * ((number - 168) / 132)}%`;
    }
    if (five && number >= 301 && number < 549) {
      five.style.height = `${100 * ((number - 300) / 248)}%`;
    }

    if (rOne && number >= 37) {
      one.style.height = "100%";
      rOne.style.background = "#06d0e7";
    }
    if (rTwo && number >= 84) {
      two.style.height = "100%";
      rTwo.style.background = "#31c6b3";
    }
    if (rThree && number >= 169) {
      three.style.height = "100%";
      rThree.style.background = "#b77830";
    }
    if (rFour && number >= 301) {
      four.style.height = "100%";
      rFour.style.background = "#1F8491";
    }
    if (rFive && number >= 549) {
      five.style.height = "100%";
      rFive.style.background = "#D8A202";
    }

    if (one && number <= 0) {
      one.style.height = "0%";
      rOne.style.background = "transparent";
    }
    if (two && number < 37) {
      two.style.height = "0%";
      rTwo.style.background = "transparent";
    }
    if (three && number < 84) {
      three.style.height = "0%";
      rThree.style.background = "transparent";
    }
    if (four && number < 169) {
      four.style.height = "0%";
      rFour.style.background = "transparent";
    }
    if (five && number < 301) {
      five.style.height = "0%";
      rFive.style.background = "transparent";
    }

    if (rOne && number < 37) {
      rOne.style.background = "transparent";
    }
    if (rTwo && number < 84) {
      rTwo.style.background = "transparent";
    }
    if (rThree && number < 169) {
      rThree.style.background = "transparent";
    }
    if (rFour && number < 301) {
      rFour.style.background = "transparent";
    }
    if (rFive && number < 549) {
      rFive.style.background = "transparent";
    }
  });

  return (
    <div
      className="timeline-wrapper"
      style={{
        position: "fixed",
        top: "52px",
        width: "15px",
        borderRadius: "0 0 10px 10px",
        height: "calc(100% - 52px)",
        right: "0px",
        zIndex: 1111111,
      }}
    >
      {false && (
        <>
          <div className="bar 1">
            <div
              className="in-progress"
              id="progress-1"
              style={{
                background: "linear-gradient(to bottom, white, #06d0e7",
              }}
            />
          </div>
          <div
            data-tip
            data-for="round-1"
            className="round 1"
            id="round-1"
            style={{ borderColor: "#06d0e7" }}
          >
            <SmartphoneHome height="40px" fill="black" />
          </div>
          <div className="bar 2">
            <div
              className="in-progress"
              id="progress-2"
              style={{
                background: "linear-gradient(to bottom, #06d0e7, #31c6b3",
              }}
            />
          </div>
          <div
            data-tip
            data-for="round-2"
            className="round 2"
            id="round-2"
            style={{ borderColor: "#31c6b3" }}
          >
            <Moniteur height="30px" fill="black" />
          </div>
          <div className="bar 3">
            <div
              className="in-progress"
              id="progress-3"
              style={{
                background: "linear-gradient(to bottom, #31c6b3, #b77830",
              }}
            />
          </div>
          <div
            data-tip
            data-for="round-3"
            className="round 3"
            id="round-3"
            style={{ borderColor: "#b77830" }}
          >
            <Stickman height="40px" fill="black" />
          </div>
          <div className="bar 4">
            <div
              className="in-progress"
              id="progress-4"
              style={{
                background: "linear-gradient(to bottom, #b77830, #1F8491",
              }}
            />
          </div>
          <div
            data-tip
            data-for="round-4"
            className="round 4"
            id="round-4"
            style={{ borderColor: "#1F8491" }}
          >
            <Camion height="40px" fill="black" />
          </div>
          <div className="bar 5">
            <div
              className="in-progress"
              id="progress-5"
              style={{
                background: "linear-gradient(to bottom, #1F8491, #D8A202",
              }}
            />
          </div>
          <div
            data-tip
            data-for="round-5"
            className="round 5"
            id="round-5"
            style={{ borderColor: "#D8A202" }}
          >
            <Antenne height="40px" fill="black" />
          </div>
          <ReactTooltip id={"round-1"} type="dark" place="left">
            <span>Applications</span>
          </ReactTooltip>
          <ReactTooltip id={"round-2"} type="dark" place="left">
            <span>Logiciels</span>
          </ReactTooltip>
          <ReactTooltip id={"round-3"} type="dark" place="left">
            <span>Walk By</span>
          </ReactTooltip>
          <ReactTooltip id={"round-4"} type="dark" place="left">
            <span>Drive By</span>
          </ReactTooltip>
          <ReactTooltip id={"round-5"} type="dark" place="left">
            <span>Télé-relève</span>
          </ReactTooltip>
        </>
      )}
      <div className="fill-bar">
        <div
          className="in-progress"
          id="fill-progress"
          style={{
              height: "100%",
            background: "linear-gradient(to bottom, #b77830 33%, #1F8491 38%, #1F8491 63%, #D8A202 68%)",
          }}
        />
              <div id="white-bar" style={{backgroundColor: 'white', position: 'absolute', bottom: '0', right: '0', height: "100%"}}/>
      </div>
    </div>
  );
};

export default ScrollBar;
