import React, { Component } from 'react';
import SchemaMbox from "../../_resources/image/schema_MBOX.png"

export default class Explication extends Component<any, any>{
    render(){
        return(
            <div className="explication-container">
                <div className="image-container">
                    <img src={SchemaMbox} alt="mbox"/>
                </div>
            </div>
        )
    }
}