import React from 'react';

const SvgCiderp = (props: any) => (
<svg viewBox="0 0 126.48 37.77" {...props}>
	<path fill="#1c1c1c" d="M55.37,22.42c-.14,.83-.39,1.58-.75,2.23-.36,.65-.8,1.2-1.32,1.63-.52,.44-1.12,.77-1.79,.99-.67,.22-1.39,.34-2.16,.34-.59,0-1.14-.07-1.66-.2-.52-.13-1.02-.34-1.5-.62-.48-.28-.9-.6-1.27-.97-.52-.54-.9-1.11-1.13-1.72-.23-.61-.37-1.27-.43-1.98-.05-.71-.08-1.74-.08-3.07,0-1.03,.01-1.84,.04-2.44,.03-.6,.09-1.15,.19-1.65,.1-.5,.25-.97,.47-1.4,.22-.44,.53-.86,.94-1.28,.59-.59,1.25-1.03,2-1.34,.75-.3,1.56-.46,2.43-.46,1.58,0,2.9,.45,3.97,1.34,1.07,.89,1.75,2.18,2.04,3.84h-2.61c-.2-.89-.59-1.59-1.17-2.11-.58-.52-1.32-.78-2.23-.78-.49,0-.96,.09-1.4,.27-.43,.18-.8,.43-1.1,.75-.22,.25-.4,.49-.52,.74-.12,.25-.22,.58-.3,.99-.08,.41-.13,.88-.16,1.39-.03,.52-.04,1.22-.04,2.12s.01,1.59,.04,2.11c.03,.51,.08,.98,.16,1.39,.08,.42,.18,.74,.3,.99,.12,.24,.3,.5,.52,.77,.3,.32,.67,.56,1.1,.75,.44,.18,.9,.27,1.4,.27,.61,0,1.15-.12,1.61-.36,.46-.24,.85-.58,1.16-1.02,.31-.44,.53-.94,.66-1.51h2.59Z"/>
	<rect fill="#1c1c1c" x="59.47" y="10.6" width="2.58" height="16.87"/>
	<path fill="#1c1c1c" d="M76.59,18.92c0-.97-.02-1.72-.05-2.25-.03-.53-.11-1.02-.25-1.49-.13-.46-.34-.84-.61-1.14-.69-.76-1.65-1.14-2.87-1.14h-3.1v12.26h3.1c1.23,0,2.18-.38,2.87-1.13,.4-.44,.65-1.04,.75-1.79,.1-.75,.15-1.86,.15-3.32m2.56,0v.84c0,.91-.04,1.7-.1,2.38-.07,.68-.23,1.35-.48,2-.25,.65-.63,1.23-1.13,1.74-1.06,1.06-2.51,1.58-4.37,1.58h-5.93V10.6h5.93c1.85,0,3.31,.53,4.37,1.58,.5,.5,.88,1.06,1.13,1.69,.25,.62,.41,1.26,.48,1.91,.07,.65,.1,1.41,.1,2.28v.86Z"/>
	<polygon fill="#1c1c1c" points="84.05 27.47 84.05 10.6 94.29 10.6 94.29 11.74 85.33 11.74 85.33 18.39 92.96 18.39 92.96 19.53 85.33 19.53 85.33 26.33 94.29 26.33 94.29 27.47 84.05 27.47"/>
	<g>
		<path fill="#1c1c1c" d="M108.63,13.63c-.18-.43-.44-.78-.77-1.07-.33-.28-.73-.49-1.18-.63-.46-.13-.96-.2-1.49-.2h-4.97v6.82h4.97c.54,0,1.03-.07,1.49-.2s.85-.34,1.18-.63c.33-.28,.59-.64,.77-1.07s.27-.93,.27-1.52-.09-1.09-.27-1.52m.25,13.84l-4.03-7.77h-4.64v7.77h-1.28V10.6h6.42c.68,0,1.32,.1,1.91,.3s1.11,.49,1.54,.86c.43,.38,.77,.85,1.02,1.42,.24,.57,.37,1.22,.37,1.94,0,1.26-.35,2.29-1.05,3.07-.7,.78-1.65,1.28-2.83,1.48l4.08,7.79h-1.49Z"/>
		<path fill="#1c1c1c" d="M124.91,13.81c-.2-.47-.47-.85-.82-1.16-.35-.31-.76-.54-1.24-.69s-1.01-.23-1.6-.23h-4.76v7.44h4.76c.58,0,1.12-.07,1.6-.22s.9-.38,1.24-.69c.35-.31,.62-.69,.82-1.16s.3-1.02,.3-1.65-.1-1.18-.3-1.65m1.18,3.71c-.26,.6-.62,1.11-1.07,1.53-.45,.42-.99,.73-1.61,.95-.62,.21-1.3,.32-2.03,.32h-4.9v7.15h-1.28V10.6h6.18c.73,0,1.4,.11,2.03,.33,.62,.22,1.16,.54,1.61,.95,.45,.41,.8,.92,1.07,1.52,.26,.6,.39,1.29,.39,2.06s-.13,1.46-.39,2.06"/>
	</g>
	<polygon fill="#1c1c1c" points="21.58 21.64 21.58 16.13 16.81 13.38 12.04 16.13 12.04 21.64 16.81 24.39 21.58 21.64"/>
	<path fill="#1c1c1c" d="M30.26,26.65h0Zm-3.36-2.01v.07l-10.09,5.83-10.09-5.83V13.06l10.09-5.83,10.08,5.82,6.26-3.63c-.29-.49-.7-.92-1.22-1.21L18.48,.45c-1.04-.6-2.32-.6-3.36,0L1.68,8.21c-1.04,.6-1.68,1.71-1.68,2.91v15.53c0,1.2,.64,2.31,1.68,2.91l13.45,7.77c.52,.3,1.1,.45,1.68,.45s1.16-.15,1.68-.45l13.45-7.77c.56-.32,1-.8,1.29-1.35l-6.32-3.57Z"/>
</svg>
);

export default SvgCiderp;
