import React, { Component } from "react";

import LogoDld from "../../_resources/image/dld_logo.png";
import MockupDld from "../../_resources/image/dimensions_DLD.png";
import docActions from "../../redux/doc/doc.action";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import { useMatomo } from '@datapunt/matomo-tracker-react'

const Measure = (props: any) => {
    const { t, dispatch } = props
    const { trackLink } = useMatomo()

    const text = {
      description: t('produits.dld.description'),
    };
    function downloadFile() {
        dispatch(docActions.getDoc("dld", "fr"));
        trackLink({ href: 'DLD', linkType: "download" })
    }

    const content = {
        button: t("all.button.download"),
    };

    return (
      <div className="measure-container" itemScope itemType="https://schema.org/Thing">
        <div className="image-container">
          <img itemProp="image" src={LogoDld} alt="dld" />
            <div className={"download-documentation"}>
                <div className={"button-download"} itemProp="subjectOf">
                    <button style={{ borderColor: "#31c3b6" }} onClick={downloadFile}>
                        {content.button}
                    </button>
                </div>
            </div>
          <h4 itemProp="description">{text.description}</h4>
        </div>
        <div className="container">
          <div className="image-container">
            <img itemProp="image" src={MockupDld} alt="dld" />
          </div>
        </div>
      </div>
    );
  }

function mapStateToProps(state: any) {
    const { indicator } = state;

    return {
        indicator,
    };
}

const connectedDoc = connect(mapStateToProps)(Measure);
const tr = withTranslation()(connectedDoc);
export default tr;