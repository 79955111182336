import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import SvgExport from "../../_svg/Export";
import VisibilitySensor from "react-visibility-sensor";

const App = (props) => {
  const { t } = props
  const ref = useRef();
  const content = {
    title: t('applications.onyx.export'),
    description: t('applications.onyx.export.description')
  }
  return (
              <div className={"svg-container"} id={"svg-export-onyx"} itemScope itemType="https://schema.org/WebPage">
                  <div className="onyx-export">
                      <SvgExport fill="#06d0e7"/>
                <h3 itemProp="alternativeHeadline">{content.title}</h3>
                      <span itemProp="description">
                        {content.description}
                      </span>
                  </div>
              </div>
  );
};

export default App;
