import React, { Component } from "react";

import Saphir from "../../../_svg/SaphirLogo";
import Onyx from "../../../_svg/OnyxLogo";
import Logiciels from "../../../_svg/Logiciels";

import DeviceSaphir from "../../../_resources/image/telephone/saphir.png";
import DeviceOnyx from "../../../_resources/image/telephone/onyx.png";

import { Button } from "reactstrap";

export default class Applications extends Component<any, any> {
  render() {
    const { parentColor, t } = this.props
    const content = {
      buttonMore: t('all.button.more')
    }
    return (
      <div className="applications-container">
        <div className="section saphir">
          <div className="image-container">
            <img src={DeviceSaphir} style={{width: "100%", height:"auto"}} alt="saphir"/>
          </div>
          <div className="logo-container">
            <Saphir height="8em" className="svg-logo"/>
            <a href="/applications/saphir">
            <Button style={{border: `1px solid ${parentColor}`}}>
                {content.buttonMore}
                <Logiciels height="1.5em" fill={parentColor}/>
            </Button>
            </a>
          </div>
        </div>
        <div className="section onyx">
          <div className="image-container">
            <img src={DeviceOnyx} style={{width: "100%", height:"auto"}} alt="onyx"/>
          </div>
          <div className="logo-container">
          <Onyx height="8em" className="svg-logo"/>
          <a href="/applications/onyx">
            <Button style={{border: `1px solid ${parentColor}`}}>
            {content.buttonMore}
                <Logiciels height="1.5em" fill={parentColor}/>
            </Button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
