import React from 'react';

const SvgAlert = (props: any) => (
    <svg viewBox="0 0 24 16.48" {...props}>
        <path fill={props.fill}
              d="m20.08,16.48c-5.43,0-10.86,0-16.29,0-.07,0-.14,0-.22,0v-.97c.06,0,.12,0,.18,0,.56,0,1.12,0,1.68,0,.12,0,.16-.03.18-.16.14-.87.29-1.75.43-2.62.02-.12.07-.15.18-.14.24,0,.48,0,.74,0,0-.08,0-.14,0-.2,0-1.19,0-2.38,0-3.56.01-1.05.26-2.03.86-2.91.77-1.11,1.84-1.76,3.17-1.97,1.4-.22,2.7.06,3.84.92,1.01.76,1.56,1.8,1.76,3.03.06.4.08.8.09,1.2.01,1.09,0,2.18,0,3.27v.21c.26,0,.5,0,.74,0,.12,0,.16.02.19.15.14.88.29,1.75.43,2.62.02.12.06.15.17.15.62,0,1.25,0,1.87,0v.97Zm-7.77-3.9h3.39v-.2c0-1.17,0-2.33,0-3.5,0-.29-.02-.58-.08-.87-.26-1.41-1.03-2.43-2.39-2.92-1.42-.51-2.76-.32-3.94.64-.96.78-1.34,1.85-1.35,3.06-.01,1.2,0,2.4,0,3.6,0,.06,0,.11,0,.18h4.37Zm4.76,2.92c-.11-.65-.22-1.29-.32-1.92H6.89c-.11.64-.21,1.28-.32,1.92h10.51Z"/>
        <rect fill={props.fill} x="11.35" y="0" width=".94" height="1.44" rx=".45" ry=".45"/>
        <rect fill={props.fill} x="5.53" y="3.19" width=".92" height="1.38" rx=".45" ry=".45"
              transform="translate(-.99 5.37) rotate(-44.97)"/>
        <path fill={props.fill}
              d="m18.16,3.38h0c.18.18.18.46,0,.63l-.36.36c-.17.17-.46.17-.63,0h0c-.17-.17-.17-.46,0-.63l.35-.36c.17-.18.46-.18.63,0Z"/>
    </svg>
);

export default SvgAlert;
