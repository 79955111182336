import React from "react";

const ContratAbonnement = (props) => {
    return (
        <svg id="Calque_1" viewBox="0 0 45.88 56.98" {...props}>
            <path fill={props.fill} d="M0,7.88V56.98H38v-7.88s7.88,0,7.88,0V7.65S38.23,0,38.23,0H7.88V7.88s-7.88,0-7.88,0Zm42.27,.88h-5.15s0-5.15,0-5.15l5.15,5.15Zm.28,37H11.22s0-42.42,0-42.42h22.56s0,8.76,0,8.76h8.76s0,33.66,0,33.66ZM3.34,11.22H7.88s0,37.88,0,37.88h26.79s0,4.54,0,4.54H3.34s0-42.42,0-42.42Z" />
            <g>
                <rect fill={props.fill} x="16.57" y="16.82" width="20.77" height="3.11" />
                <rect fill={props.fill} x="16.57" y="23.82" width="20.77" height="3.11" />
                <rect fill={props.fill} x="16.57" y="30.82" width="20.77" height="3.11" />
                <rect fill={props.fill} x="16.57" y="37.81" width="20.77" height="3.11" />
            </g>
        </svg>
    )
}

export default ContratAbonnement