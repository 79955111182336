import React, { Component, useEffect } from "react";
import Contact from "../onyx/Contact";
import Footer from "../section/FooterSection";

import Map from "./GoogleMap";
import { withTranslation } from "react-i18next";

import MetaTags from 'react-meta-tags';

import ReactGA from 'react-ga4';

class ContactComponent extends Component {
    componentDidMount() {
        ReactGA.initialize("G-E9MYD3NSR4");
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        const { t } = this.props
        return (
            <div className="contact-component responsiv">
                <MetaTags>
                    <title>Dioptase - contact</title>
                    <meta
                        name="description"
                        content="Posez nous vos questions directement via notre site"
                    />
                    <meta name="keywords" content="support, crystalcloud, tourmaline, licence, formation, télémaintenance, aide, synchronisation, réparation" />
                    <meta property="og:title" content="Contact" />
                    <meta property="og:type" content="Contact - contactez nous par mail" />
                    <meta property="og:url" content="https://www.dioptase.fr/Contact" />
                    <meta property="og:image" content="https://www.dioptase.fr/preview.jpg" />
                    <meta property="og:description" content="Envoie de mail directement depuis le site web" />
                </MetaTags>
                <Contact t={t} />
                <Map />
                <Footer t={t} />
            </div>
        )
    }
}

const tr = withTranslation()(ContactComponent)
export default tr;