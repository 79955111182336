import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

import { withTranslation } from "react-i18next";

const SiteMap = (props: any) => {
    const { t } = props
    const content = {
        logiciels: t("all.software"),
        applications: t("all.applications"),
        produits: t("all.products"),
        materiels: t("all.materials"),
        about: t("sitemap.about"),
        contact: t("all.contact"),
        support: t("all.support"),
        comparator: t('materials.comparator'),
      };

    return(
        <div className="site-map-container responsiv">
            <div className="title">
                <h2>{t('sitemap.title')}</h2>
            </div>
            <div className="maps">
                <div className="section">
                    <h3>{content.logiciels}</h3>
                    <Link to="/logiciels/crystalcloud">
                        <span>Crystalcloud</span>
                    </Link>
                </div>
                <div className="section">
                    <h3>{content.applications}</h3>
                    <Link to="/applications/saphir">
                        <span>Saphir</span>
                    </Link>
                    <Link to="/applications/onyx">
                        <span>Onyx</span>
                    </Link>
                </div>
                <div className="section">
                    <h3>{content.produits}</h3>
                    <Link to="/produits/dld">
                        <span>DLD</span>
                    </Link>
                    <Link to="/produits/mbox">
                        <span>MBox</span>
                    </Link>
                </div>
                <div className="section">
                    <h3>{content.materiels}</h3>
                    <Link to="/materiels/comparateur">
                        <span>{t('materials.comparator')}</span>
                    </Link>
                    <Link to="/materiels/nautiz">
                        <span>Nautiz X6</span>
                    </Link>
                    <Link to="/materiels/s52">
                        <span>Caterpillar S52</span>
                    </Link>
                    <Link to="/materiels/samsung">
                        <span>Samsung Galaxy tab 2</span>
                    </Link>
                </div>
                <div className="section">
                    <h3>{content.about}</h3>
                    <Link to="/support">
                        <span>{content.support}</span>
                    </Link>
                    <Link to="/contact">
                        <span>{content.contact}</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(SiteMap);