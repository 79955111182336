import React, { Component } from "react";
import SchemaMbox from "../../_resources/image/sch_MBOX.png";
import Kangoo from "../../_resources/image/kangoo_Mockup.png";
import Fleche from "../../_svg/Fleche_mail";

export default class Schema extends Component<any, any>{
    render(){
        return(
            <div className="schema-container" style={{marginBottom: 0}}>
                <div className="image-container">
                    <img src={SchemaMbox} style={{width: "100%", height: "auto"}} alt="mbox"/>
                </div>
            </div>
        )
    }
}