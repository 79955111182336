import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import SvgFiltres from "../../_svg/Filtre";
import VisibilitySensor from "react-visibility-sensor";

const App = (props) => {
  const { t } = props
  const ref = useRef();
  const content = {
    title: t('applications.filter'),
    description: t('applications.onyx.filter.description')
  }
  return (
              <div className={"svg-container"} id={"svg-filtre-onyx"} itemScope itemType="https://schema.org/WebPage">
                  <div className="text-filtre">
                    <SvgFiltres fill="#06d0e7"/>
                    <h3 itemProp="alternativeHeadline">{content.title}</h3>
                    <span itemProp="description">
                      {content.description}
                    </span>
                  </div>
              </div>
  );
};

export default App;
