import React, { Component } from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'

import LogoMbox from "../../_resources/image/mbox_logo.png";
import MockupMbox from "../../_resources/image/dimensions_MBOX.png";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import docActions from "../../redux/doc/doc.action";

const Measure = (props: any) => {
    const { t, dispatch } = props
    const { trackLink } = useMatomo()
    const tablet = useMediaQuery({ query: "(max-width: 1040px)" })
    const text = {
      description: t('produits.mbox.description'),
    };

    function downloadFile() {
      dispatch(docActions.getDoc("mbox", "fr"));
      trackLink({ href: 'Mbox', linkType: 'download' })
    }

    const content = {
      button: t("all.button.download"),
    };

    return (
      <div className="measure-container">
        <div className="image-container measure">
          <img src={LogoMbox} />
          {tablet && (
            <div className={"download-documentation"}>
              <div className={"button-download"} itemProp="subjectOf">
                <button style={{ borderColor: "#31c3b6" }} onClick={downloadFile}>
                  {content.button}
                </button>
              </div>
            </div>
          )}
          <h4>{text.description}</h4>
          {!tablet && (
            <div className={"download-documentation"}>
              <div className={"button-download"} itemProp="subjectOf">
                <button className="matomo_download" style={{ borderColor: "#31c3b6" }} onClick={downloadFile}>
                  {content.button}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="container">
          <div className="image-container">
            <img src={MockupMbox} />
          </div>
        </div>
      </div>
    );

}

function mapStateToProps(state: any) {
  const { indicator } = state;

  return {
    indicator,
  };
}

const connectedDoc = connect(mapStateToProps)(Measure);
const tr = withTranslation()(connectedDoc);
export default tr;
