import React, { Component } from "react";
import DocumentationImg from "../../_resources/image/catalogue_tourmaline.png";
import { jsPDF } from "jspdf";
import docActions from "../../redux/doc/doc.action"
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";

const Doc = (props: any) => {
  const { t } = props

  function downloadFile(){
    const { dispatch } = props
    dispatch(docActions.getDoc("tourmaline", "fr"))
  }
    const content = {
      button: t('all.button.download'),
    }

    const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
    const isTablet = useMediaQuery({ query: "(max-width: 1040px)" });

    return (
      <div className={"documentation-container"} style={{marginTop: isTablet ? "15%" : "5%"}}>
                  <div className={"catalogue"}>
          <img src={DocumentationImg} style={{width: isMobile ? "125%" : "50%"}} alt="tourmaline"/>
        </div>
        <div className={"download-documentation"}>
          <div className={"button-download"}>
            <button style={{ borderColor: "#31c3b6" }} onClick={() => downloadFile()}>
              {content.button}
            </button>
          </div>
        </div>
      </div>
    );
  }

function mapStateToProps(state: any) {
  const { doc } = state;

  return {
    doc,
  };
}

const connectedDoc = connect(mapStateToProps)(Doc);
const tr = withTranslation()(connectedDoc);
export default tr;
