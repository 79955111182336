import React from 'react';

const SvgRequea = (props: any) => (
	<svg viewBox="0 0 113.24 24.72" {...props}>
		<path d="M6.65,6.57V22.68H0V.64H8.54c2.18-.13,4.34,.54,6.04,1.9,2.96,2.63,3.19,7.13,.52,10.05-.33,.36-.69,.68-1.09,.97l3.84,9.08h-6.99l-3.23-7.1v-5.63h.52c1.4,0,2.1-.6,2.1-1.77s-.81-1.57-2.43-1.57h-1.18Z"/>
		<path d="M32.01,9.22v4.91h-4.34c-.44-.03-.87,.05-1.26,.26-.26,.21-.4,.54-.36,.87-.05,.35,.09,.7,.37,.92,.49,.19,1.02,.26,1.55,.22h4.46v6.29h-5.22c-2.03,.14-4.05-.48-5.64-1.74-1.36-1.31-2.07-3.14-1.95-5V7.29c-.11-1.82,.56-3.6,1.86-4.9,1.42-1.24,3.29-1.86,5.18-1.74h5.78V6.97h-4.26c-1.15,0-1.72,.37-1.72,1.1-.06,.36,.1,.72,.41,.92,.46,.19,.97,.27,1.47,.22h3.65Z"/>
		<path d="M43.69,13.35h5.88v2.47c1.28-1.03,2.01-2.59,1.97-4.22,.02-1.33-.51-2.61-1.45-3.55-1.73-1.87-4.68-2.01-6.58-.31-.11,.1-.22,.2-.31,.31-.95,.94-1.48,2.22-1.47,3.55-.05,1.63,.67,3.19,1.97,4.21v-2.47Zm-.24,11.37v-1.92c-2.49-.64-4.68-2.1-6.23-4.12-1.56-2.03-2.39-4.52-2.35-7.07-.08-3.36,1.42-6.57,4.07-8.69,4.8-4.22,12.17-3.81,16.45,.92,1.9,2.09,2.95,4.8,2.96,7.6,.03,2.65-.83,5.23-2.44,7.35-1.51,1.99-3.67,3.41-6.12,4.02v1.92h-6.34Z"/>
		<path d="M77.23,.65V14.94c.29,4.28-3,7.98-7.35,8.26-.35,.02-.69,.02-1.04,0-2.16,.14-4.28-.67-5.79-2.2-1.34-1.46-2.01-3.56-2.01-6.31V.65h6.66V14.71c0,1.34,.49,2.02,1.46,2.02s1.41-.68,1.41-2.02V.65h6.65Z"/>
		<path d="M93.21,9.22v4.91h-4.34c-.44-.04-.87,.05-1.26,.26-.26,.21-.4,.54-.36,.87-.05,.35,.09,.7,.37,.92,.49,.19,1.02,.26,1.54,.22h4.46v6.29h-5.22c-2.02,.13-4.01-.49-5.6-1.74-1.36-1.31-2.07-3.14-1.95-5V7.3c-.11-1.82,.56-3.6,1.86-4.9,1.43-1.23,3.3-1.86,5.19-1.74h5.71V6.97h-4.24c-1.15,0-1.73,.37-1.73,1.1-.05,.36,.11,.71,.41,.92,.46,.2,.96,.29,1.47,.25l3.68-.02Z"/>
		<path d="M105.57,11.63v5.81h-1.92v5.24h-6.66V8.68c-.15-2.33,.65-4.61,2.23-6.35,1.71-1.58,4-2.39,6.34-2.25,2.62,0,4.56,.68,5.82,2.05,1.25,1.36,1.86,3.46,1.86,6.3v14.23h-6.56V8.89c.05-.61-.06-1.23-.31-1.79-.25-.35-.68-.54-1.11-.5-1.04,0-1.56,.76-1.56,2.28v2.76l1.87-.02Z"/>
	</svg>
);

export default SvgRequea;
