import adminService from "./admin.service";
import adminConstants from "./admin.constants";
import { history } from "../../_helpers";
function login(login) {
  function request() {
    return { type: adminConstants.ADMIN_LOGIN_REQUEST};
  }
    function success(login) {
      return { type: adminConstants.ADMIN_LOGIN_SUCCESS, login };
    }
    function failure(error) {
      return { type: adminConstants.ADMIN_LOGIN_FAILURE, error };
    }
  
    return (dispatch) => {
      dispatch(request())
      adminService
        .login(login)
        .then((login) => {
          dispatch(success(login));
          console.log(login)
          const token = localStorage.getItem('token')
          localStorage.setItem('token', login.token)
          history.push('/admin/dashboard')
        })
        .catch((error) => {
          dispatch(failure(error))
        });
    };
  }

  function clear() {
    return {
        type: adminConstants.CLEAR,
    };
}

  const adminActions = {
    login,
    clear
  };
  
  export default adminActions;