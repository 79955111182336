import React from 'react';

const SvgSensus = (props: any) => (
	<svg viewBox="0 0 178.73 46.41" {...props}>
	<g id="Calque_2" data-name="Calque 2">
		<g id="Calque_1-2" data-name="Calque 1">
			<path fill={props.fill} d="M44.87,46.34H35.92a10.58,10.58,0,0,1-2.3-.2,3.69,3.69,0,0,1-3.22-3.25,11.24,11.24,0,0,1-.2-2.1V28.18a11.38,11.38,0,0,1,.17-1.86,3.83,3.83,0,0,1,3.79-3.52,19.13,19.13,0,0,1,2-.1h18.5c.44,0,.54.1.54.54,0,1.25,0,2.47,0,3.72,0,.41-.14.48-.51.48-6.13,0-12.26,0-18.39,0-.51,0-.65.14-.61.65,0,1.15,0,2.27,0,3.42,0,.44.13.51.54.51,6.13,0,12.26,0,18.39,0,.48,0,.61.14.58.61,0,1.26,0,2.51,0,3.8,0,.4-.1.47-.47.47q-9.21,0-18.4,0c-.51,0-.64.13-.61.64,0,1.15,0,2.27,0,3.42,0,.41.1.48.48.48,6.13,0,12.26,0,18.39,0,.51,0,.64.1.61.61,0,1.32,0,2.68,0,4,0,.37-.1.44-.47.44Z"/>
			<path fill={props.fill} d="M163.56,46.34h-9.82c-.47,0-.58-.14-.58-.58,0-1.32,0-2.64,0-3.92,0-.45.11-.55.55-.55,6.3,0,12.56,0,18.86,0,.51,0,.61-.14.58-.61,0-1.15,0-2.27,0-3.42,0-.41-.13-.51-.51-.51H158.69a9.12,9.12,0,0,1-2.14-.24,3.56,3.56,0,0,1-3.11-3.15,22.43,22.43,0,0,1,0-6.94c.34-2.34,1.56-3.39,3.93-3.63.71-.07,1.42-.1,2.14-.1h18.12c.47,0,.57.1.57.58,0,1.22,0,2.43,0,3.69,0,.44-.13.51-.54.51-6.13,0-12.29,0-18.43,0-.44,0-.61.07-.57.54q0,1.57,0,3.15c0,.44.13.51.51.51h13.92a10.68,10.68,0,0,1,2.1.21,3.67,3.67,0,0,1,3.29,3.28,25.41,25.41,0,0,1,0,7.49c-.31,2.3-1.63,3.39-4,3.62a23.83,23.83,0,0,1-2.67.1C169.05,46.34,166.31,46.34,163.56,46.34Z"/>
			<path fill={props.fill} d="M10.38,46.34H.53c-.41,0-.48-.1-.48-.47,0-1.36,0-2.75,0-4.1,0-.41.14-.48.51-.48,6.3,0,12.6,0,18.9,0,.44,0,.61-.07.58-.54,0-1.19,0-2.34,0-3.53,0-.37-.1-.44-.48-.44h-14a11,11,0,0,1-2.34-.27,3.51,3.51,0,0,1-2.88-3,24.79,24.79,0,0,1-.07-7c.31-2.27,1.56-3.36,3.9-3.59.71-.07,1.39-.11,2.1-.11H24.44c.41,0,.54.07.51.51,0,1.26,0,2.47,0,3.73,0,.4-.1.51-.51.51-6.13,0-12.3,0-18.43,0-.47,0-.61.1-.61.58,0,1,0,2.06,0,3.08,0,.48.14.54.55.54H19.8a10.68,10.68,0,0,1,2.1.21,3.74,3.74,0,0,1,3.35,3.38,27.8,27.8,0,0,1,0,7.29C25,45,23.7,46,21.26,46.27c-.71.07-1.43.1-2.14.1C16.21,46.34,13.3,46.34,10.38,46.34Z"/>
			<path fill={props.fill} d="M102.22,46.34H92.36c-.44,0-.51-.14-.51-.51,0-1.32,0-2.67,0-4,0-.45.1-.55.54-.55,6.3,0,12.57,0,18.87,0,.48,0,.61-.1.61-.58,0-1.08,0-2.17,0-3.25,0-.51-.1-.68-.68-.68-4.6,0-9.24,0-13.85,0a10,10,0,0,1-2.24-.24,3.59,3.59,0,0,1-3-3.15,23.53,23.53,0,0,1,0-7c.31-2.27,1.56-3.28,3.9-3.55.71-.07,1.42-.11,2.13-.11h18.16c.44,0,.54.11.54.55,0,1.25,0,2.47,0,3.72,0,.41-.1.48-.47.48-6.13,0-12.3,0-18.43,0-.51,0-.64.1-.64.61,0,1,0,2,0,3,0,.48.1.61.57.61,4.37,0,8.74,0,13.15,0a14.37,14.37,0,0,1,2.71.21,3.74,3.74,0,0,1,3.42,3.49,25.61,25.61,0,0,1,0,7.21c-.34,2.37-1.59,3.46-4,3.69-1.42.14-2.84.1-4.23.1C106.62,46.34,104.42,46.34,102.22,46.34Z"/>
			<path fill={props.fill} d="M60.59,35.84c.06-2.51-.11-5.62.06-8.71,0-.37.07-.74.14-1.08.44-2,1.59-3,3.79-3.22a11.23,11.23,0,0,1,1.53-.1H81.59a10.43,10.43,0,0,1,2,.2A3.83,3.83,0,0,1,87,26.59a16.79,16.79,0,0,1,.1,2.24v17c0,.44-.13.54-.54.54-1.62,0-3.25,0-4.88,0-.44,0-.51-.13-.51-.5,0-5.83,0-11.66,0-17.48,0-.44-.07-.61-.58-.61-4.5,0-9,0-13.55,0-.5,0-.57.17-.57.61,0,5.82,0,11.62,0,17.44,0,.44-.07.58-.54.58-1.63,0-3.22,0-4.84,0-.45,0-.58-.07-.58-.58C60.59,42.65,60.59,39.53,60.59,35.84Z"/>
			<path fill={props.fill} d="M122.07,32.49V23.2c0-.4.1-.5.51-.5,1.62,0,3.21,0,4.84,0,.47,0,.61.1.61.57,0,5.83,0,11.62,0,17.45,0,.47.1.61.57.61,4.51,0,9.05,0,13.55,0,.44,0,.58-.07.58-.58,0-5.83,0-11.65,0-17.48,0-.44.11-.57.58-.57,1.66,0,3.29,0,4.95,0,.33,0,.44.06.44.44,0,6,0,12.06,0,18a8.77,8.77,0,0,1-.2,1.83,3.67,3.67,0,0,1-3.22,3.12,12.27,12.27,0,0,1-2.51.2H128a15.35,15.35,0,0,1-2.44-.2,3.8,3.8,0,0,1-3.36-3.46,14.86,14.86,0,0,1-.13-2.4Z"/>
			<path fill={props.fill} d="M42.84,19.75H31.11c-.5,0-.61-.14-.57-.61,0-1.15,0-2.3,0-3.46,0-.44.1-.57.57-.57,5.86,0,11.73,0,17.59,0h6c.37,0,.51.07.51.47,0,1.22,0,2.44,0,3.69,0,.41-.1.48-.51.48Z"/>
			<path fill={props.fill} d="M42.9,0H54.66c.44,0,.57.1.54.54,0,1.22,0,2.41,0,3.63,0,.34-.07.47-.44.47H31.05c-.38,0-.48-.1-.48-.47q.06-1.83,0-3.66c0-.44.14-.51.54-.51Z"/>
			<path fill={props.fill} d="M42.84,12.19H31.08c-.41,0-.54-.1-.54-.5,0-1.22,0-2.41,0-3.63,0-.37.07-.51.47-.51H54.76c.34,0,.44.11.44.44,0,1.26,0,2.48,0,3.73,0,.41-.1.47-.51.47Z"/>
		</g>
	</g>
</svg>
);

export default SvgSensus;
