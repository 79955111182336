import React, { useEffect, useState } from "react";
import { history } from "../../_helpers";
import { withTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import MultipleSelect from "./MultipleSelect";
import Ajouter from "../../_svg/Ajouter";
import SvgPoubelle from "../../_svg/Poubelle";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import jobAction from "../../redux/job/job.action";

const newJob = {
    id: null,
    title: "",
    type: {
        time: "",
        contract: ""
    },
    salary: {
        min: 0,
        max: 0
    },
    description: "",
    profil: "",
    advantages: []
}

const Jobs = props => {
    const { jobs, advantages, t, dispatch } = props;
    const [sJob, setSJob] = useState(null)

    useEffect(() => {
        if (jobs && jobs[0] && !sJob) {
            setSJob(jobs[0])
        }
    }, [jobs])

    const handleChange = (e, type, extra) => {
        const clone = _.cloneDeep(sJob)
        if (type === 'salary' && extra) {
            clone[type][extra] = parseFloat(e.target.value) || null
        } else {
            if (type === 'type' && extra) {
                clone[type][extra] = e.target.value
            } else {
                clone[type] = e.target.value
            }
        }
        setSJob(clone)
    }

    const handleAdvantages = (advantageList) => {
        const copy = _.cloneDeep(sJob)
        copy.advantages = advantageList.map(el => advantages.find(adv => el === adv.title));
        setSJob(copy)
    }

    const addJob = () => {
        console.log('test')
        setSJob(newJob)
    }

    const save = () => {
        console.log('save')
        dispatch(jobAction.save(sJob))
    }

    const deleteJob = (e, id) => {
        e.stopPropagation();
        dispatch(jobAction.deleteJob(id));
    }
    return (
        <div className="job-container">
            {sJob && (
            <div className="left">
            <div className="flex-box">
                <TextField className="title-input" onChange={(e) => handleChange(e, 'title')} label={'Titre'} variant="standard" value={sJob.title} />
            </div>
            <div className="text-box">
                <h4>{t('job.details')}</h4>
                <div className="box">
                    <span><b>{t('job.type')}: </b></span>
                    <TextField className="c100" onChange={(e) => handleChange(e, 'type', 'time')} label={'horaire'} variant="standard" value={sJob.type.time} />
                    <span> - </span>
                    <TextField className="c100" onChange={(e) => handleChange(e, 'type', 'contract')} label={'contrat'} variant="standard" value={sJob.type.contract} />
                </div>
                <div className="box" style={{ marginTop: '20px' }}>
                    <span><b>{t('job.salary')}: </b></span>
                    <TextField className="c100" onChange={(e) => handleChange(e, 'salary', 'min')} label={'min'} variant="standard" value={sJob.salary.min || ""} />
                    <span> - </span>
                    <TextField className="c100" onChange={(e) => handleChange(e, 'salary', 'max')} label={'max'} variant="standard" value={sJob.salary.max || ""} />
                </div>
            </div>
            <div className="text-box">
                <h4>{t('job.details.description')}</h4>
                <textarea rows="5" cols="100" onChange={(e) => handleChange(e, 'description')} value={sJob.description} />
            </div>
            <div className="text-box">
                <h4>{t('job.profil')}</h4>
                <textarea rows="5" cols="100" onChange={(e) => handleChange(e, 'profil')} value={sJob.profil} />
            </div>
            <div className="text-box">
                <h4>{t('job.advantage')}</h4>
                {advantages && <MultipleSelect options={advantages.map(el => el.title)} handleAdvantages={handleAdvantages} selectList={sJob.advantages.map(el => el.title)} />}
            </div>
            <div className="save">
                <Button variant="outlined" onClick={save}>Sauvegarder</Button>
            </div>
        </div>
            )}
            <div className='jobs-wrapper'>
                {jobs && jobs.map(el => {
                    let description = el.description.split(" ").splice(0, 40)
                    description.push("...")
                    description = description.join(" ")
                    return (
                        <div className='card-j' onClick={() => setSJob(el)}>
                            <div className='top'>
                                <h3>{el.title}</h3>
                                <div className="card-options">
                                    <div className='rect'>
                                        <h5>{el.type.contract.toUpperCase()}</h5>
                                    </div>
                                    <div className="delete-job" onClick={(e) => deleteJob(e, el.id)}>
                                        <SvgPoubelle height="15px"/>
                                    </div>
                                </div>
                            </div>
                            <span>{el.description.length > 300 ? description : el.description}</span>
                        </div>
                    )
                })}
                <div className="add-job" onClick={() => addJob()}>
                    <Ajouter width="25px" />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ job }) => {
    return {
        job
    }
}

const connectedJob = connect(mapStateToProps)(Jobs)
const tr = withTranslation()(connectedJob)
export default tr;