import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import tradFr from './TraductionFile/fr';
import tradEn from './TraductionFile/en';
import React from 'react';
// the translations
// (tip move them in a JSON file and import them)
export const resources = {
  en: tradEn,
  fr: tradFr,
};

let language = navigator.language.split('-')[0]

if(language !== "fr" && language !== "en")
{
  language = "en"
}
console.log(language)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    parseMissingKeyHandler: function(key) {
      return <div style={{ backgroundColor: 'red', color: 'greenyellow' }}>??????????</div>;
    },
  });

export default i18n;