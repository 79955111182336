import React, { Component } from "react";
import txtConnexion from "../../_resources/image/txt_connexion.png"

import Cable from "../../_svg/PlugNPlay";
import Wifi from "../../_svg/Wifi";
import Internet from "../../_svg/Earth";

export default class Connexion extends Component<any>{
    render(){
        const { t } = this.props
        const sections = [
            {
                title: t('logiciels.tm.cable'),
                svg: <Cable height="4em" fill="#3e9f92"/> 
            },
            {
                title: t('logiciels.tm.wifi'),
                svg: <Wifi height="4em" fill="#3e9f92"/> 
            },
            {
                title: t('logiciels.tm.internet'),
                svg: <Internet height="4em" fill="#3e9f92"/> 
            },
        ]
        return(
            <div className="connexion-container">
                <div className="top">
                    <div className="image-container">
                        <img src={txtConnexion} width="auto" height="80%" alt="tourmaline"/>
                    </div>
                    <div className="description">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur neque ante, auctor et nisl in, interdum rhoncus mauris.</span>
                    </div>
                </div>
                <div className="bottom">
                    {sections.map((el: any) => (
                        <div className="section">
                            {el.svg}
                            <h5>{el.title}</h5>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}