import React, { Component, useEffect } from "react";
import SvgLogiciels from "../../_svg/Logiciels";
import LogoSaphir from "../../_svg/SaphirLogo";
import LogoOnyx from "../../_resources/image/onyx_logo.png";
import FooterSection from "./FooterSection";

import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga4";

const listLogiciels = [
  {
    title: "Saphir",
    svg: null,
  },
  {
    title: "Onyx",
    svg: null,
  },
];

const Applications = (props: any) => {
  const { t } = props;

  useEffect(() => {
    ReactGA.initialize("G-E9MYD3NSR4");
    ReactGA.pageview(window.location.pathname);
  })

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
  });

  const content = {
    tourmaDesc: t("logiciels.tourmaline.description"),
    ccDesc: t("logiciiels.cc.description"),
    buttonMore: t("all.button.more"),
  };
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <>
      <div className={"logiciels-container applications"}>
        {listLogiciels.map((el: any) => (
          <div
            style={{
              display: "flex",
              height: isMobile ? "fit-content" : "400px",
              alignItems: "center",
              borderBottom: "1px solid #333",
              maxHeight: isMobile ? "fit-content" : "50%",
              overflow: "hidden",
              position: "relative",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            {!isMobile && el.title === "Onyx" && (
              <div
                className="video-container"
                style={{ left: el.title === "Onyx" ? 0 : "30%" }}
              >
                <video width="100%" height="auto" autoPlay muted>
                  <source
                    src={require("../../_resources/fullVideo/onyx.mp4")}
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
            <div
              className={"section-container"}
              style={{
                marginLeft: el.title === "Onyx" ? (isMobile ? 0 : "50%") : 0,
              }}
            >
              {el.title === "Saphir" ? (
                <LogoSaphir height="8em" />
              ) : (
                <img src={LogoOnyx} width="30%" height="auto" alt="onyx"/>
              )}
              <div className={"button-download"}>
                <a href={`/applications/${el.title.toLowerCase()}`}>
                  <button style={{borderColor: el.title === "Saphir" ? "#b77830" : "#06d0e7"}}>
                    <span>{content.buttonMore}</span>
                    <SvgLogiciels fill={el.title === "Saphir" ? "#b77830" : "#06d0e7"} height={"30px"} />
                  </button>
                </a>
              </div>
            </div>
            {el.title === "Saphir" && (
              <div className="video-container">
                <video width="100%" height="auto" autoPlay muted>
                  <source
                    src={require("../../_resources/fullVideo/video_acceuil_saphir_site.mp4")}
                    type="video/mp4"
                  />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            )}
            {isMobile && el.title === "Onyx" && (
              <div
                className="video-container"
                style={{ left: el.title === "Onyx" ? 0 : "30%" }}
              >
                <video width="100%" height="auto" autoPlay muted>
                  <source
                    src={require("../../_resources/fullVideo/onyx.mp4")}
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </div>
        ))}
      </div>
      <FooterSection t={t} />
    </>
  );
};

const tr = withTranslation()(Applications);
export default tr;
