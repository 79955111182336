import { authHeader, handleResponse } from '../../_helpers';

async function send(mail) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(mail),
    };
    let response = await fetch('/api/mail/send', requestOptions);
    return handleResponse(response);
  }

  async function sendWithAttachment(formData) {
    const requestOptions = {
      method: 'POST',
      headers: new Headers(),
      body: formData,
    };
    let response = await fetch('/api/mail/sendWithAttachment', requestOptions);
    return handleResponse(response);
  }

  
  const mailService = {
    send,
    sendWithAttachment,
  };
  
  export default mailService;