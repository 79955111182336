import React, { Component } from "react";

import SvgTournee from "../../_svg/Tournee";
import SvgMono from "../../_svg/VirtualMeter";
import SvgModul from "../../_svg/Module";
import SvgFiltre from "../../_svg/Filtre";

import ImgRecap from "../../_resources/image/recap.png";

import { withTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Footer = (props: any) => {
  const { t } = props;
  const content = {
    title: t("all.recap"),
  };

  const sections1 = [
    {
      title: t("applications.filter"),
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce viverra ut lorem sed lobortis. Nam eget nunc vel tortor malesuada dictum. Mauris mollis erat sit.",
      svg: <SvgFiltre height={"3em"} fill={"#3e9f92"} />,
    },
    {
      title: t("logiciels.tm.details"),
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce viverra ut lorem sed lobortis. Nam eget nunc vel tortor malesuada dictum. Mauris mollis erat sit.",
      svg: <SvgTournee height={"3em"} fill={"#3e9f92"} />,
    },
    {
      title: t("logiciels.tm.modul"),
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce viverra ut lorem sed lobortis. Nam eget nunc vel tortor malesuada dictum. Mauris mollis erat sit.",
      svg: <SvgModul height={"3em"} fill={"#3e9f92"} />,
    },
  ];

  const sections2 = [
    {
      title: t("logiciels.tm.monoposte"),
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      svg: <SvgMono height={"3em"} fill={"#3e9f92"} />,
    },
  ];

  const extraSection = [
    {
      title: t("logiciels.tm.modul"),
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce viverra ut lorem sed lobortis. Nam eget nunc vel tortor malesuada dictum. Mauris mollis erat sit.",
      svg: <SvgModul height={"3em"} fill={"#3e9f92"} />,
    },
    {
      title: t("logiciels.tm.monoposte"),
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      svg: <SvgMono height={"3em"} fill={"#3e9f92"} />,
    },
  ];

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <div className={"footer crystalcloud"}>
      <div className={"image-container"}>
        <h2>{content.title}</h2>
        <img src={ImgRecap} alt="tourmaline" />
      </div>
      <div className={"footer-section"}>
        {sections1.map((el: any, index: any) => {
          if ((isMobile && index !== 2) || !isMobile) {
            return (
              <div className={"section"} style={{ width: isMobile ? "50%" : "30%" }}>
                {el.svg}
                <h5>{el.title}</h5>
                <span>{el.description}</span>
              </div>
            );
          }
        })}
      </div>
      {isMobile && (
        <div className={"footer-section"}>
          {extraSection.map((el: any) => (
            <div className={"section"}>
              {el.svg}
              <h5>{el.title}</h5>
              <span>{el.description}</span>
            </div>
          ))}
        </div>
      )}
      {!isMobile && <div className={"footer-section"} style={{ justifyContent: "center" }}>
        {sections2.map((el: any, index: number) =>  (
              <div className={"section"} style={{ width: "30%" }}>
                {el.svg}
                <h5>{el.title}</h5>
                <span>{el.description}</span>
              </div>
            )
        )}
      </div>}
    </div>
  );
};

const tr = withTranslation()(Footer);
export default tr;
