import React from 'react';

const SvgProgema = (props: any) => (
<svg viewBox="0 0 125.14 28.44" {...props}>
	<path fill={"#1d1d1b"} d="M13.57,5.5c0,1.13-.92,2.05-2.06,2.05H6.65V3.06h4.86c1.14,0,2.06,.92,2.06,2.06v.38ZM12.5,0H0V10.92c0,2.31,1.88,4.18,4.2,4.18h2.72v-4.62h5.58c2.13,0,3.87-1.72,3.87-3.85V3.85c0-2.13-1.73-3.85-3.87-3.85"/>
	<path fill={"#1d1d1b"} d="M28.58,5.98h-5.99V3.06h5.99c.81,0,1.46,.65,1.46,1.46s-.65,1.46-1.46,1.46m1.6-5.43h-13.04V10.92c0,2.31,1.88,4.18,4.2,4.18h1.26v-6.74h6.27c.8,0,1.45,.65,1.45,1.45v4.82l2.38,.02v-5.4c0-.94-.76-1.7-1.71-1.7h-.82c1.4,0,2.53-1.13,2.53-2.52v-1.97c0-1.39-1.13-2.52-2.53-2.52"/>
	<path fill={"#1d1d1b"} d="M67.03,11s.43,.05,.79,.39c.56-1.02,.89-2.2,.89-3.45v-1.31h-7.85v2.09h4.99v1.81c0,.23-.04,.45-.12,.65,.39-.17,.83-.24,1.3-.19"/>
	<path fill={"#1d1d1b"} d="M64,12.39h-1.95c-2.54,0-4.6-2.05-4.6-4.58v-1.12c0-2.53,2.06-4.58,4.6-4.58h6.12V0h-9.44c-3.97,0-7.19,3.21-7.19,7.16v.78c0,1.05,.23,2.05,.64,2.96,.88-.07,1.88-.06,2.99,.11,.22,.03,.38,.24,.34,.46-.03,.22-.24,.37-.47,.34-.89-.13-1.71-.16-2.44-.12,1.27,2.05,3.54,3.41,6.13,3.41h2.78c.76,0,1.49-.12,2.17-.33,.11-.73,.34-1.69,.82-2.46-.16,.05-.33,.07-.51,.07"/>
	<path fill={"#1d1d1b"} d="M66.95,11.82c-.52-.05-.95,.07-1.31,.39-.59,.52-.9,1.43-1.06,2.22,1.11-.52,2.07-1.32,2.78-2.31-.01-.02-.02-.04-.04-.05-.16-.21-.37-.25-.37-.25"/>
	<path fill={"#1d1d1b"} d="M84.41,13.77v-1.72h-9.58V7.97h9.58v-1.72h-9.58V2.26h9.58V.54h-14.69V10.4c0,1.86,1.51,3.37,3.38,3.37h11.31Z"/>
	<path fill={"#1d1d1b"} d="M44.03,11.98c-2.43,0-4.41-2.09-4.41-4.67s1.97-4.67,4.41-4.67,4.4,2.09,4.4,4.67-1.97,4.67-4.4,4.67m3.88,1.05c-.13,0-.26-.06-.34-.18-.13-.19-.08-.44,.11-.57,.06-.04,.91-.61,2.4-1.02,.52-.99,.82-2.12,.82-3.32v-.78C50.9,3.21,47.68,0,43.71,0h-2.78C36.96,0,33.74,3.21,33.74,7.16v.78c0,3.96,3.22,7.16,7.19,7.16h2.78c2.29,0,4.33-1.07,5.65-2.73-.77,.3-1.2,.57-1.21,.58-.07,.05-.15,.07-.23,.07"/>
	<path fill={"#1d1d1b"} d="M100.01,13.36c.2,.04,.34,.21,.34,.4h1.74c.5,0,.91-.4,.91-.9V2.08c0-.85-.69-1.53-1.54-1.53h-3.48c-.43,0-.83,.18-1.12,.49l-2.39,2.55-2.68-2.6c-.29-.28-.67-.43-1.07-.43h-3.43c-.85,0-1.54,.69-1.54,1.53V12.64c0,.62,.51,1.13,1.13,1.13h.76c.63,0,1.13-.51,1.13-1.13V5.37l1.97,1.79c1.26,1.15,3.15,1.25,4.54,.26l2.01-1.44v6.88c0,.5,.41,.9,.91,.9h1.31s0-.05,.01-.07c.04-.22,.26-.37,.48-.33"/>
	<path fill={"#1d1d1b"} d="M109.44,4.4c0-.74,.6-1.34,1.35-1.34h4.6c.74,0,1.35,.6,1.35,1.34v1.57h-7.29v-1.57Zm-4.11,9.36h3.39c.45,0,.82-.36,.82-.81V7.83h7.29v5.01c0,.51,.42,.93,.93,.93h.79c.52,0,.93-.42,.93-.93V3.62c0-1.7-1.38-3.07-3.08-3.07h-7.9c-2.2,0-3.98,1.78-3.98,3.97V12.95c0,.45,.37,.81,.82,.81"/>
	<path fill={"#1d1d1b"} d="M51.23,11.02s-.05-.02-.07-.02c-.08,0-.14,.03-.21,.06-.31,.06-.61,.13-.88,.2-.21,.39-.45,.76-.72,1.11,.38-.15,.85-.3,1.39-.42v10.5h0c-1.59,.55-2.6,1.31-2.67,1.37-.18,.14-.21,.39-.07,.57,.08,.1,.2,.16,.32,.16,.09,0,.18-.03,.25-.09,.03-.03,3.31-2.52,7.47-1.41,.08,.12,.23,.19,.36,.21,.2,0,.37-.16,.4-.36,.18-1.51,1.07-5.79,2.78-5.56,.02,0,.04,0,.05,0,.09,.08,.61,.64,.55,3.81,0,.17-.02,1.65,.81,2.16,.14,.09,.34,.17,.6,.16,.24,0,.54-.07,.89-.26,.2-.11,.27-.36,.16-.55-.11-.2-.36-.27-.56-.16-.21,.11-.49,.23-.67,.12-.34-.21-.43-1.06-.42-1.44,.05-2.52-.24-3.98-.87-4.47-.2-.16-.39-.18-.51-.18-1.6-.15-2.51,1.67-3.03,3.39,0-1.66,.21-2.9,.21-2.92,.04-.22-.11-.43-.34-.47-.22-.04-.43,.11-.47,.34-.02,.11-.42,2.59-.1,5.32-1.63-.37-3.11-.26-4.34,.04V11.8c.33-.05,.68-.08,1.04-.1-.16-.25-.3-.52-.42-.8-.33,.03-.65,.07-.96,.12"/>
	<path fill={"#1d1d1b"} d="M67.49,13.69c-.04,.22,.11,.43,.33,.48,.22,.04,.44-.11,.48-.33,.18-1,.08-1.76-.31-2.27-.05-.07-.1-.12-.16-.18-.14,.25-.29,.49-.46,.72,.16,.24,.29,.7,.12,1.58"/>
	<path fill={"#1d1d1b"} d="M65.73,11.19c-.22,.1-.43,.23-.62,.4-.23,.2-.43,.45-.6,.72,.56-.16,1.02-.58,1.22-1.12"/>
	<path fill={"#1d1d1b"} d="M66.56,18.1c-.84-.12-1.55-.06-2.15,.08v-2.33s.02-.66,.18-1.43c-.29,.14-.59,.25-.89,.35-.09,.59-.1,1.03-.1,1.08v2.62c-1.09,.5-1.6,1.25-1.63,1.3-.12,.19-.07,.44,.12,.56,.07,.04,.15,.07,.22,.07,.13,0,.27-.07,.34-.19,.02-.03,.32-.46,.94-.83v4.75c0,.23,.18,.41,.41,.41s.41-.18,.41-.41v-5.09c.54-.16,1.2-.25,2.04-.14,.23,.04,.43-.13,.46-.35,.03-.22-.13-.43-.35-.46"/>
	<path fill={"#1d1d1b"} d="M70.91,21.36c-.7,1.21-2.01,1.95-2.59,1.69-.4-.17-.62-1.04-.04-2.94,.74-2.43,1.61-3.21,2.21-3.45,.17-.07,.34-.09,.48-.09,.28,0,.47,.1,.49,.11,.95,.6,.74,2.43-.55,4.68m.98-5.37h0s-.74-.45-1.67-.1c-1.13,.42-2.04,1.76-2.72,3.98-.83,2.71-.19,3.64,.5,3.93,.19,.08,.39,.12,.6,.12,1.09,0,2.38-1.01,3.03-2.16,1.57-2.74,1.67-4.9,.27-5.78"/>
	<path fill={"#1d1d1b"} d="M77.81,16.1c.23,0,.41-.18,.41-.41s-.18-.41-.41-.41c-1.05,0-1.94,.38-2.66,1.12-.52,.54-.9,1.23-1.18,1.96,.02-1.46,.05-2.64,.05-2.67,0-.23-.17-.41-.4-.42h-.01c-.22,0-.4,.17-.41,.4,0,.25-.16,6.11,0,7.87,.02,.21,.2,.37,.41,.37h.03c.22-.02,.39-.2,.38-.42,0-.05-.16-4.57,1.72-6.53,.57-.59,1.24-.87,2.07-.87"/>
	<path fill={"#1d1d1b"} d="M95.13,20.27c-.09,0-.18,.05-.24,.12-1.9,1.89-3.7,2.5-4.06,2.21-.15-.13-.21-.95,1.37-3.14,1.92-2.66,2.78-2.92,3.03-2.92,.03,0,.05,0,.06,0,.16,.04,.24,.27,.27,.38-.27,1.33-.41,2.45-.43,3.35m2.23,1.85c-.15,.11-.64,.38-.94,.19-.23-.15-.91-.97-.03-5.31,0-.05,.01-.09,0-.14-.05-.34-.29-.96-.9-1.11-.96-.24-2.21,.8-3.96,3.23-.95,1.32-1.47,2.35-1.57,3.11-.04,.02-.07,.03-.11,.06-.23,.19-.56,.39-.79,.28-.5-.23-.78-1.57-.83-2.46-.02-.58-.18-2.51-1.21-2.78-.68-.18-1.4,.4-2.27,1.81-.99,1.61-1.5,2.13-1.72,2.31,0-.04,0-.09,0-.12,.29-4.27-1.06-4.64-1.33-4.67-1.62-.19-2.55,1.65-3.08,3.39,0-1.66,.2-2.9,.21-2.92,.04-.22-.11-.43-.34-.47-.22-.04-.43,.11-.47,.34-.02,.12-.49,3.05,0,6.05,.03,.2,.21,.33,.41,.34,.2,0,.37-.16,.4-.36,.18-1.51,1.03-5.75,2.74-5.57,0,0,.88,.39,.64,3.81-.03,.42,.09,.91,.51,1.05,.54,.17,1.19-.24,2.73-2.75,.95-1.54,1.35-1.45,1.36-1.45,.27,.07,.56,1.05,.6,2.02,.02,.44,.18,2.66,1.31,3.16,.16,.07,.32,.11,.48,.11,.28,0,.58-.1,.89-.3,.05,.09,.12,.17,.2,.24,.21,.17,.48,.26,.79,.26,1.1,0,2.73-.97,4.07-2.22,.09,.88,.36,1.46,.81,1.74,.67,.42,1.49,.06,1.87-.22,.18-.13,.22-.39,.09-.57-.13-.18-.39-.22-.57-.09"/>
	<path fill={"#1d1d1b"} d="M110.29,20.35s-.03,.03-.05,.04c-1.9,1.89-3.71,2.5-4.06,2.21-.15-.13-.21-.95,1.37-3.14,1.92-2.66,2.78-2.92,3.03-2.92,.03,0,.05,0,.07,0,.16,.04,.24,.27,.27,.38-.26,1.28-.46,2.42-.63,3.43m2.12,6.42c-.13,.08-.27,.18-.43,.3-.32,.23-.91,.66-1.11,.54-.33-.21-.91-1.67,.88-10.6,0-.05,0-.09,0-.14-.05-.34-.29-.96-.9-1.11-.95-.23-2.21,.79-3.96,3.23-1.57,2.18-1.98,3.6-1.24,4.24,.85,.72,2.78-.18,4.43-1.57-.75,5.07-.28,6.25,.34,6.65,.17,.11,.34,.15,.52,.15,.53,0,1.08-.4,1.52-.72,.14-.1,.26-.19,.38-.26,.19-.12,.25-.37,.13-.56s-.37-.25-.56-.13"/>
	<path fill={"#1d1d1b"} d="M101.6,23.16c-.68,.38-.96,.19-1.05,.13-.73-.52-.87-2.63-.74-4.92,.59-.15,1.27-.24,1.96-.19,.23,.01,.42-.15,.44-.37,.02-.22-.15-.42-.37-.44-.69-.06-1.36,.01-1.96,.14,.11-1.3,.28-2.6,.48-3.66,0-.02,0-.05,0-.07h-.83c-.09,.49-.36,2.13-.5,3.96-.94,.3-1.59,.68-1.63,.71-.2,.11-.26,.36-.14,.56,.08,.13,.21,.2,.35,.2,.07,0,.14-.02,.21-.06,0,0,.47-.27,1.16-.52-.1,2.27,.07,4.61,1.1,5.34,.19,.14,.46,.26,.81,.26,.31,0,.68-.09,1.12-.34,.2-.11,.27-.36,.16-.56-.11-.2-.36-.27-.56-.15"/>
	<path fill={"#1d1d1b"} d="M104.57,23.16c-.28,.17-.66,.34-.88,.18-.8-.56-.49-4.04-.01-6.34,.05-.22-.1-.44-.32-.48-.22-.05-.44,.1-.49,.32-.21,1.01-1.19,6.1,.34,7.17,.2,.14,.43,.21,.68,.21,.33,0,.7-.12,1.1-.37,.19-.12,.25-.37,.13-.56s-.37-.25-.56-.13"/>
	<path fill={"#1d1d1b"} d="M123.02,15.89h.02c.35,0,.57,.39,.58,.39h0c.11,.38,.07,.73-.12,1.09-.61,1.13-2.52,1.83-3.08,2.02,1.36-3.09,2.26-3.49,2.6-3.5m2.03,4.22c-.14-.18-.4-.2-.58-.06-3.75,3.03-4.6,2.71-4.73,2.61-.33-.24-.09-1.42,.21-2.14,.05-.13,.1-.26,.15-.38,.1,.09,.25,.13,.39,.09,.11-.03,2.84-.84,3.73-2.47,.3-.55,.36-1.13,.16-1.78-.17-.35-.64-.97-1.39-.91-1.22,.05-2.46,1.73-3.81,5.15-.08,.21-.33,.87-.4,1.55-.57,.32-1,.42-1.2,.26-.64-.51-.13-3.28,.43-4.99,.07-.21-.05-.44-.26-.51-.21-.07-.45,.05-.52,.26-.54,1.65-2.33,6.01-4.09,6.32-.2,.03-.26-.03-.28-.06-.6-.72,.38-4.38,1.27-6.74,.08-.21-.03-.45-.24-.53-.21-.08-.45,.03-.53,.24-.39,1.03-2.25,6.21-1.13,7.55,.15,.18,.4,.37,.81,.37,.08,0,.16,0,.25-.02,1.25-.22,2.35-1.71,3.18-3.25-.02,.86,.13,1.62,.6,2,.42,.34,1.02,.32,1.76-.01,.07,.27,.2,.5,.42,.67,.2,.15,.44,.22,.73,.22,1.02,0,2.62-.92,5.01-2.85,.17-.14,.2-.4,.06-.57"/>
	<path fill={"#1d1d1b"} d="M103.6,14.11c.25,0,.45-.2,.45-.45s-.2-.45-.45-.45-.45,.2-.45,.45,.2,.45,.45,.45"/>
</svg>
);

export default SvgProgema;
