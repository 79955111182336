import React, { Component } from "react";

import Batterie from "../../../_svg/Batterie";
import Memoire from "../../../_svg/Memoire";
import Photo from "../../../_svg/AppareilPhoto";
import Ip from "../../../_svg/Ip";
import Bluetooth from "../../../_svg/Earth";
import Resolution from "../../../_svg/Smartphone";

import { useMediaQuery } from "react-responsive";

const Caracteristique = (props: any) => {
  const { t } = props;

  const list = [
    {
      title: t("materials.battery"),
      svg: <Batterie height="1.9em" fill={"#b7803c"}/>,
      props: "batterie",
    },
    {
      title: t("materials.memory"),
      svg: <Memoire height="2.5em" fill={"#b7803c"}/>,
      props: "memoire",
    },
    {
      title: t("materials.camera"),
      svg: <Photo height="2.5em" fill={"#b7803c"} />,
      props: "pixel",
    },
    {
      title: t("materials.waterproofing"),
      svg: <Ip height="2.5em" fill={"#b7803c"} />,
      props: "ip",
    },
    {
      title: t("materials.bluetooth"),
      svg: <Bluetooth height="2.5em" fill={"#b7803c"} />,
      props: "bluetooth",
    },
    {
      title: t("materials.resolution"),
      svg: <Resolution height="2.5em" fill={"#b7803c"}/>,
      props: "resolution",
    },
  ];

  const content = {
    title: t("materials.characteristics"),
  };

  function caracDisplayer(carac: any) {
    const { device } = props;
    const element = device;
    switch (carac) {
      case "Dimensions":
        return (
          <h4>{`${element.dimension.largeur}x${element.dimension.longueur}x-cm`}</h4>
        );
      case "Poids":
        return <h4>{`${element.poids}g`}</h4>;
      case "Résolution":
        return <h4>{`${element.resolution}"`}</h4>;
      case "Bluetooth":
        return <h4>{`${element.bluetooth}`}</h4>;
      case "Etanchéité":
        return <h4>{`${element.ip}`}</h4>;
      case "Appareil photo":
        return <h4>{`${element.pixel} mp`}</h4>;
      case "Mémoire":
        return <h4>{`${element.memoire} go`}</h4>;
      case "Batterie":
        return <h4 style={{marginTop: "10px"}}>{`${element.batterie} mAh`}</h4>;
    }
  }

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <div className="caracteristique-container">
      <div className="title">
        <h2>{content.title}</h2>
      </div>
      <div className="section-container">
        {!isMobile &&
          list.map((el: any) => (
            <div className="section">
              <h5>{el.title}</h5>
              {el.svg}
              {caracDisplayer(el.title)}
            </div>
          ))}
        {isMobile && (
          <div className="mobile-section-container">
            <div className="mobile-section">
              {list.map((el: any, index: any) => {
                if(index % 2 === 0){
                  return(
                    <div className="section">
                    <h5>{el.title}</h5>
                    {el.svg}
                    {caracDisplayer(el.title)}
                  </div>
                  )
                }
              })}
            </div>
            <div className="mobile-section">
              {list.map((el: any, index: any) => {
                if(index % 2 === 1){
                  return(
                    <div className="section">
                    <h5>{el.title}</h5>
                    {el.svg}
                    {caracDisplayer(el.title)}
                  </div>
                  )
                }
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Caracteristique;
